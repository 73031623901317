import dayjs from 'dayjs';
import { action, computed, observable } from 'mobx';

import { createPayPalTransaction } from 'client/api/mutations';
import {
  fetchPayPalStatus,
  fetchRestaurantOrderStatus
} from 'client/api/queries';
import OFFLINE_PAYMENT_ORDER_STATUSES from 'client/enums/offline_payment_order_statuses.enum';
import { PAYMENT_METHODS } from 'client/enums/payment_methods.enum';
import PAYMENT_TYPES from 'client/enums/payment_methods_structured.enum';
import PAYPAL_PAYMENT_STATUSES from 'client/enums/paypal_payment_statuses.enum';
import PAYPAL_TO_CREFOPAY_SETTINGS from 'client/enums/paypal_to_crefopay_settings.enum';
import STORAGE_KEYS from 'client/enums/storage_keys.enum';
import { OrderRequestModel } from 'client/models/orderRequest.model';
import { PaymentMethod } from 'client/models/payment_method.model';
import Storage from 'client/modules/storage';
import RootStore from 'client/stores';
import { generateUUID } from 'client/utils/functions';
import { delay } from 'client/utils/helpers';
import { getHungerAppData } from 'client/utils/native-apps-communication';
import { IS_CLIENT } from 'config';

import i18n from '../../../i18n';
import API from '../../modules/api/api';

import {
  ErrorType,
  OnlineOfflinePaymentResponse,
  OrderHungerData,
  PayPalInitType
} from './orderPaymentMethods.store.type';

/**
 * Order Payment Method Store class. Used to work with payment methods.
 * @constructor
 * @param {instance} api - {@link API} instance.
 * @param {instance} storage - {@link storage} instance.
 * @property {string} _localStorageKey - Observable loading status.
 * @property {object} err - Observable error object.
 * @property {array <object>} paymentMethods - Observable paymentMethods array.
 * @property {boolean} isOrderSent - Observable isOrderSent status.
 * @property {boolean} orderInProcess - Observable orderInProcess status.
 * @property {boolean} allowReceiveMarketingInfo - Observable bool for sending info when the user allows receiving marketing Info.
 *
 */

type PaymentMethodAPI = {
  code: string;
  id: number;
  isOnline: boolean;
  isUniq: boolean;
  localKey: string;
  name: string;
  setting: {
    minPrice: number;
    orderType: 'Pickup' | 'Delivery';
  }[];
  useAsCrefoPay?: PAYPAL_TO_CREFOPAY_SETTINGS;
};

class OrderPaymentMethodsStore {
  root: RootStore;

  api: any;

  storage: Storage;

  constructor(root: RootStore, state: any, api: API, storage: Storage) {
    Object.assign(this, state);

    this.root = root;

    this.api = api;

    this.storage = storage;

    if (state.paymentMethods && state.paymentMethods.length > 0) {
      this.preparePaymentMethods(state.paymentMethods);
    }
  }

  _localStorageKey = STORAGE_KEYS.PAYMENT_METHODS;

  _localStorageLastOrder = STORAGE_KEYS.LAST_ORDER;

  _localStorageOrderId = STORAGE_KEYS.ORDER_ID;

  CHECKING_TIMEOUT_DURATION = 120000;

  CHECKING_OFFLINE_PAYMENT_ORDER_STATUS_DURATION = 120000;

  TICK_CHECKOUT_TIME_OUT = 10000;

  CREFOPAY_ID = PAYMENT_METHODS.CREFOPAY_ID;

  PAYPAL_ID = PAYMENT_METHODS.PAYPAL_ID;

  PAYU_ID = PAYMENT_METHODS.PAYU_ID;

  PAYPAL_V2_ID = PAYMENT_METHODS.PAYPAL_V2_ID;

  timer: NodeJS.Timeout | undefined;

  hungerData?: OrderHungerData;

  @observable err: Partial<ErrorType> = {
    eTitle: '',
    eDescription: '',
    e: '',
    isCanceled: false
  };

  @observable paymentStatus = {
    shortDesc: '',
    statusDesc: '',
    fullDesc: '',
    contactData: false
  };

  @observable pendingPayPalPaymentStatus = false;

  @observable paymentMethods: PaymentMethod[] = [];

  @observable isOrderSent = false;

  @observable isPaymentProcessing = false;

  @observable isPaymentTimeOutStatus = false;

  @observable orderInProcess = false;

  @observable allowReceiveMarketingInfo = false;

  @observable orderId = generateUUID();

  @observable previousOrderId = undefined;

  @observable previousOrderRequest:
    | Partial<OrderRequestModel>
    | Record<string, unknown>
    | undefined;

  @observable isCheckingOfflinePaymentOrderStatus = false;

  /**
   * ContactLess option checkbox state
   * @type {boolean}
   */
  @observable isContactLessCheckboxChecked = false;

  @action changeOrderId() {
    this.orderId = generateUUID();
  }

  @action handleOrder() {
    const { restaurantStore, basketStore, analyticsStore } = this.root;
    const { branch } = restaurantStore;

    if (!basketStore.isAvailableToAdd)
      return console.warn('is not available to order');

    analyticsStore.sendPlaceOrder();

    this.setPreviousOrderRequestData(
      this.root.basketStore.dataToBackendRequest()
    );

    this.subscribeStorageToStore();

    this.storage.saveToStorage(
      this._localStorageOrderId,
      this.orderId,
      branch.branchId
    );

    if (
      this.isPayPalSelected &&
      (!this.replacePaypalToCrefopay || this.root.themesStore.isHunger)
    ) {
      return this.payViaPayPal({
        basket_data: this.root.basketStore.dataToBackendRequest(),
        params: this.root.basketStore.toPayPalRequest()
      });
    }

    if (
      this.payPal2Selected &&
      (!this.replacePaypalToCrefopay || this.root.themesStore.isHunger)
    ) {
      const order = this.root.basketStore.dataToBackendRequest();

      return this.payViaPayPal2(order);
    }

    if (!this.isOnlinePaymentSelected) return this.placeTheOrder();

    if (this.isOnlinePaymentSelected) return this.payOnline();
  }

  /**
   * Toggle contactless delivery option checkbox state
   */
  @action toggleContactLessDelivery() {
    this.isContactLessCheckboxChecked = !this.isContactLessCheckboxChecked;
  }

  /**
   * Method to place the order.
   * @return {promise} information about sent order.
   */
  @action placeTheOrder() {
    this.resetOrderPaymentInformation();

    this.setProcessingOrderStatus(true);

    const { isShellThemeActive, isMobile } = this.root.themesStore;

    if (IS_CLIENT && !isShellThemeActive) {
      isMobile
        ? this.root.categoryMenuStore.scrollContainerElement(true)
        : window.scrollTo(0, 0);
    }

    return this.api
      .placeTheOrder(new OrderRequestModel(this.root))
      .then((data: { success: boolean; order_id: string }) => {
        if (data.success) {
          const { analyticsStore } = this.root;

          analyticsStore.setTrackingTransactionId(data.order_id);

          this.setPaymentStatusToSuccessful();

          this.setOrderSentState(true);

          this.setCheckingOfflinePaymentOrderStatus(true);

          this.checkOfflinePaymentOrderStatus(
            (data.order_id as unknown) as number
          );
        } else {
          throw new Error((data as unknown) as string);
        }

        return true;
      })
      .catch((requestError: Error) => {
        try {
          const errObj = JSON.parse(requestError.message);

          let errorDesc = requestError;

          if (errObj.status) {
            this.setPaymentStatusToFailed();

            errorDesc = errObj.message;
          } else {
            this.setPaymentStatusToConnectionProblem();
          }

          this.handleOrderError({ e: errorDesc });
        } catch (err) {
          this.setPaymentStatusToConnectionProblem();

          this.handleOrderError({ e: requestError });
        }
      })
      .finally(() => {
        this.setProcessingOrderStatus(false);
      });
  }

  /**
   * Method to pay order with PayPal.
   * @param {object} order - order.
   * @throws {error} if order wasnt paid.
   * @return {promise} payment information.
   * @memberof OrderPaymentMethodsStore#
   * @method payViaPayPal
   */
  @action payViaPayPal(order: Partial<PayPalInitType>) {
    this.setProcessingOrderStatus(true);

    return this.api
      .payPallInit(order)
      .then((data: { response: string }) => {
        window.location.href = data.response;

        setTimeout(() => {
          this.setProcessingOrderStatus(false);
        }, 5000);

        return true;
      })
      .catch((requestError: Error) => {
        try {
          const errObj = JSON.parse(requestError.message);

          let errorDesc = requestError;

          if (errObj.status) {
            this.setPaymentStatusToFailed();

            errorDesc = errObj.message;
          } else {
            this.setPaymentStatusToConnectionProblem();
          }

          this.handleOrderError({ e: errorDesc });
        } catch (err) {
          this.setPaymentStatusToConnectionProblem();

          this.handleOrderError({ e: requestError });
        }
      });
  }

  /**
   * Method to pay order with PayPal.
   * @param {object} order - order.
   * @throws {error} if order wasnt paid.
   * @return {promise} payment information.
   * @memberof OrderPaymentMethodsStore#
   * @method payViaPayPal
   */
  @action
  async payViaPayPal2(orderRequest: OrderRequestModel) {
    try {
      this.setProcessingOrderStatus(true);

      const { data } = await createPayPalTransaction(orderRequest);

      this.storage.saveToStorage(
        'transactionId',
        data.transactionID,
        this._localStorageKey
      );

      window.location.href = data.redirectURL;

      setTimeout(() => {
        this.setProcessingOrderStatus(false);
      }, 5000);

      return true;
    } catch (error) {
      try {
        const errObj = JSON.parse(error.message);

        let errorDesc = error;

        if (errObj.status) {
          this.setPaymentStatusToFailed();

          errorDesc = errObj.message;
        } else {
          this.setPaymentStatusToConnectionProblem();
        }

        this.handleOrderError({ e: errorDesc });

        return false;
      } catch (err) {
        this.setPaymentStatusToConnectionProblem();

        this.handleOrderError({ e: error });

        return false;
      }
    }
  }

  /**
   * Method to pay order with PayCo.
   * @throws {error} if order wasnt paid.
   * @return {promise} payment information.
   * @memberof OrderPaymentMethodsStore#
   * @method payViaPayPal
   */
  @action payOnline() {
    this.setProcessingOrderStatus(true);

    return this.api
      .payOnline(new OrderRequestModel(this.root))
      .then((data: { redirectURL: string }) => {
        window.location.href = data.redirectURL;

        setTimeout(() => {
          this.setProcessingOrderStatus(false);
        }, 5000);

        return true;
      })
      .catch((requestError: Error) => {
        try {
          const errObj = JSON.parse(requestError.message);

          let errorDesc = requestError;

          if (errObj.status) {
            this.setPaymentStatusToFailed();

            errorDesc = errObj.message;
          } else {
            this.setPaymentStatusToConnectionProblem();
          }

          this.handleOrderError({ e: errorDesc });
        } catch (err) {
          this.setPaymentStatusToConnectionProblem();

          this.handleOrderError({ e: requestError });
        }
      });
  }

  /**
   * Method to load payment methods
   * @param paymentMethods array of payment methods
   */
  @action preparePaymentMethods(paymentMethods: PaymentMethodAPI[]) {
    const oldPaypalMethod = paymentMethods.find(
      (method) => method.id === PAYMENT_METHODS.PAYPAL_ID
    );

    const storePaymentMethods = paymentMethods.filter(
      (method) => method.id !== PAYMENT_METHODS.PAYPAL_V2_ID
    );

    if (oldPaypalMethod) {
      storePaymentMethods.push({
        ...oldPaypalMethod,
        id: 16,
        name: 'Paypal (a new implementation)',
        code: 'paypal',
        localKey: 'payPal',
        useAsCrefoPay: PAYPAL_TO_CREFOPAY_SETTINGS.DO_NOT_REPLACE_PAYPAL
      });
    }

    this.paymentMethods = storePaymentMethods
      .map((method) => new PaymentMethod(method))
      // Disable payu for non-polish restaurants and crefopay for hunger
      .filter(
        (method) =>
          !(
            (method.id === this.PAYU_ID &&
              !this.root.restaurantStore.isPolishRestaurant) ||
            (method.id === this.CREFOPAY_ID && this.root.themesStore?.isHunger)
          )
      );

    this.setActivePaymentMethod();
  }

  /**
   * Method to set active payment method.
   * @param id - id of the method.
   * @param shouldSubscribe - shouldSubscribe
   */
  @action
  async setActivePaymentMethod(
    id: number | null = null,
    shouldSubscribe = true
  ) {
    const { branch } = this.root.restaurantStore;

    if (id && this.paymentMethods.find((i) => i.id === id)) {
      this.paymentMethods.forEach((i) => {
        i.active = i.id === id;
      });
    } else {
      if (IS_CLIENT) {
        const [preselectedMethodId] = await this.storage.loadFromStorage(
          [this._localStorageKey],
          branch.branchId
        );

        const filteredPaymentMethodsRelatedPayPal = this.root.restaurantStore
          .restaurant.isPaypalUsed
          ? this.paymentMethodsFilteredByOrderAmount
          : this.paymentMethodsFilteredByOrderAmount.filter(
              (method) => method.id !== this.PAYPAL_ID
            );

        if (
          preselectedMethodId &&
          filteredPaymentMethodsRelatedPayPal.find(
            (i) => i.id === preselectedMethodId
          )
        ) {
          this.paymentMethods.forEach((i) => {
            i.active = i.id === preselectedMethodId;
          });
        } else {
          const preselectedMethod = filteredPaymentMethodsRelatedPayPal.find(
            (i) => i.id >= 0
          );

          if (preselectedMethod)
            this.paymentMethods.forEach((i) => {
              i.active = i.id === preselectedMethod.id;
            });
        }

        if (shouldSubscribe) this.subscribeStorageToStore();

        return preselectedMethodId;
      }

      if (this.paymentMethods.length) {
        this.paymentMethods[0].active = true;
      }
    }

    if (IS_CLIENT) {
      if (shouldSubscribe) this.subscribeStorageToStore();
    }
  }

  /**
   * Method to load and fill marketing checkbox value
   * @memberof OrderPaymentMethodsStore#
   * @method loadMarketingCheckbox
   */
  @action
  async loadMarketingCheckboxFromStorage() {
    const { branch } = this.root.restaurantStore;

    const [marketingCheckboxValue] = await this.storage.loadFromStorage(
      [STORAGE_KEYS.MARKETING_CHECKBOX],
      branch.branchId
    );

    this.setReceivingMarketingInfoStatus(marketingCheckboxValue);

    return marketingCheckboxValue;
  }

  /**
   * Method to save marketing checkbox value
   * @param {boolean} value - current checkbox value
   * @memberof OrderPaymentMethodsStore#
   * @method setMarketingCheckbox
   */
  @action setMarketingCheckbox(value: boolean) {
    const { branch } = this.root.restaurantStore;

    this.setReceivingMarketingInfoStatus(value);

    this.storage.saveToStorage(
      STORAGE_KEYS.MARKETING_CHECKBOX,
      value,
      branch.branchId
    );
  }

  /**
   * Method to reset order payment information to initial values.
   */
  @action resetOrderPaymentInformation() {
    this.err = {
      eTitle: '',
      e: '',
      eDescription: '',
      isCanceled: false
    };

    this.setOrderSentState(false);

    this.setProcessingOrderStatus(false);

    this.changeOrderId();
  }

  /**
   * Method to set order payment state.
   * @memberof OrderPaymentMethodsStore#
   * @param {boolean} state - current state
   */
  @action setOrderSentState(state: boolean) {
    this.isOrderSent = state;
  }

  /**
   * Method for checking status of online payment transaction
   */
  @action
  async checkPayPalTransactionStatus(cancelledByUser = false) {
    const { branchId } = this.root.restaurantStore.branch;

    if (cancelledByUser) {
      this.setPaymentStatusToCancelledByUserForCrefopay();

      this.handleOrderError({ e: true });

      await this.storage.deleteFromStorage(this._localStorageOrderId, branchId);

      return;
    }

    let statusTimer;

    if (!this.timer && !this.isPaymentTimeOutStatus) {
      this.timer = setTimeout(async () => {
        this.setPaymentTimeOutStatus(true);

        await this.storage.deleteFromStorage(
          this._localStorageOrderId,
          branchId
        );

        this.timer = undefined;

        this.clearTimer();

        statusTimer = undefined;

        if (!this.isOrderSent) {
          this.handleOrderError({ e: true });

          this.setPaymentStatusToTimeout();
        }
      }, this.CHECKING_TIMEOUT_DURATION);
    }

    if (
      this.root &&
      !this.pendingPayPalPaymentStatus &&
      !this.isPaymentTimeOutStatus &&
      this.previousOrderId
    ) {
      this.setPendingPayPalPaymentStatus(true);

      this.api
        .getPaypalTransactionStatus(branchId, this.previousOrderId)
        .then(async (data: OnlineOfflinePaymentResponse) => {
          if (data.payment_status === PAYPAL_PAYMENT_STATUSES.PENDING) {
            // Pending status
            this.setPaymentProcessingStatus(true);

            this.handleOrderError({ e: false });

            statusTimer = setTimeout(
              () => this.checkPayPalTransactionStatus(),
              this.TICK_CHECKOUT_TIME_OUT
            );
          } else if (data.payment_status > 0) {
            // Successful result of checking

            this.handleOrderError({ e: false });

            this.setPaymentProcessingStatus(false);

            this.root.basketStore.resetBasket();

            if (data.payment_status === PAYPAL_PAYMENT_STATUSES.SUCCESSFUL) {
              this.setPaymentStatusToSuccessful();

              statusTimer = setTimeout(
                () => this.checkPayPalTransactionStatus(),
                this.TICK_CHECKOUT_TIME_OUT
              );
            } else {
              this.setPaymentStatusToConfirmedByRestaurant();

              this.root.restaurantStore.setPreparationTime(data.prep_time);

              this.clearTimer();
            }

            this.setOrderSentState(true);
          } else {
            // Failed result of checking
            this.setPaymentProcessingStatus(false);

            let isCanceled = false;

            if (
              data.payment_status === PAYPAL_PAYMENT_STATUSES.CANCELLED_BY_USER
            ) {
              this.setPaymentStatusToCancelledByUserForCrefopay();
            } else if (
              data.payment_status ===
              PAYPAL_PAYMENT_STATUSES.CANCELLED_BY_RESTAURANT
            ) {
              this.setPaymentStatusToCancelledByRestaurant();

              isCanceled = true;
            } else {
              this.setPaymentStatusToFailed();
            }

            this.clearTimer();

            await this.storage.deleteFromStorage(
              this._localStorageOrderId,
              branchId
            );

            this.handleOrderError({ e: true, isCanceled });
          }

          this.setPendingPayPalPaymentStatus(false);
        })
        .catch(() => {
          // Check request didn't get response
          this.handleOrderError({ e: true });

          this.setPaymentStatusToUnknown();

          this.setPendingPayPalPaymentStatus(false);

          statusTimer = setTimeout(
            () => this.checkPayPalTransactionStatus(),
            this.TICK_CHECKOUT_TIME_OUT
          );
        });
    }
  }

  /**
   * Method for checking status of offline payment order
   */
  @action checkOfflinePaymentOrderStatus(orderId: number) {
    const { branchId } = this.root.restaurantStore.branch;

    if (!this.timer && this.isCheckingOfflinePaymentOrderStatus) {
      this.timer = setTimeout(() => {
        this.setCheckingOfflinePaymentOrderStatus(false);

        this.timer = undefined;
      }, this.CHECKING_OFFLINE_PAYMENT_ORDER_STATUS_DURATION);
    }

    this.handleOrderError({ e: false });

    this.isCheckingOfflinePaymentOrderStatus &&
      this.api
        .getOfflinePaymentOrderStatus(branchId, orderId)
        .then((responseData: OnlineOfflinePaymentResponse) => {
          if (
            responseData.order_status ===
            OFFLINE_PAYMENT_ORDER_STATUSES.RECEIVED_BY_SYSTEM
          ) {
            this.setPaymentStatusToSuccessful();

            if (
              window.navigator.onLine &&
              this.isCheckingOfflinePaymentOrderStatus
            ) {
              setTimeout(
                () => this.checkOfflinePaymentOrderStatus(orderId),
                this.TICK_CHECKOUT_TIME_OUT
              );
            }
          } else if (
            responseData.order_status >
            OFFLINE_PAYMENT_ORDER_STATUSES.RECEIVED_BY_SYSTEM
          ) {
            this.root.restaurantStore.setPreparationTime(
              responseData.prep_time
            );

            this.setPaymentStatusToConfirmedByRestaurant();

            this.clearTimer();
          } else {
            this.setPaymentStatusToCancelledByRestaurant();

            this.clearTimer();

            this.handleOrderError({ e: true, isCanceled: true });
          }
        })
        .catch((error: unknown) => {
          this.setOrderSentState(false);

          this.setPaymentStatusToUnknown();

          this.handleOrderError({ e: error });

          if (
            window.navigator.onLine &&
            this.isCheckingOfflinePaymentOrderStatus
          ) {
            setTimeout(
              () => this.checkOfflinePaymentOrderStatus(orderId),
              this.TICK_CHECKOUT_TIME_OUT
            );
          }
        });
  }

  /**
   * Method to set all variables to initial state
   */
  @action clearOrderInfo() {
    this.paymentStatus = {
      shortDesc: '',
      statusDesc: '',
      fullDesc: '',
      contactData: false
    };

    this.setPreviousOrderId(undefined);

    clearTimeout(this.timer as NodeJS.Timeout);

    this.setPendingPayPalPaymentStatus(false);

    this.setCheckingOfflinePaymentOrderStatus(false);

    this.setPaymentProcessingStatus(false);

    this.setPaymentTimeOutStatus(false);

    this.root.restaurantStore.setPreparationTime();
  }

  clearTimer() {
    clearTimeout(this.timer as NodeJS.Timeout);
  }

  /**
   * Method to set payment status to cancel by user (only for crefopay failed payment)
   */
  @action setPaymentStatusToCancelledByUserForCrefopay() {
    this.paymentStatus = {
      shortDesc: i18n.t('order_checkout:paymentCanceled'),
      statusDesc: i18n.t('order_checkout:cancelledByUser'),
      fullDesc: i18n.t('order_checkout:orderCanceledByUserDescription'),
      contactData: true
    };
  }

  /**
   * Method to set payment status to successful
   */
  @action setPaymentStatusToSuccessful() {
    this.paymentStatus = {
      shortDesc: `${i18n.t('order_payment_methods:thankYou')}!`,
      statusDesc: i18n.t('order_payment_methods:yourOrderWasSuccessful'),
      fullDesc: i18n.t('order_payment_methods:orderHasBeenSent', {
        email: this.root.deliveryAddressStore.address.email
      }),
      contactData: true
    };
  }

  /**
   * Method to set payment status to timeout status
   */
  @action setPaymentStatusToTimeout() {
    this.paymentStatus = {
      shortDesc: i18n.t('order_checkout:timeOut'),
      statusDesc: i18n.t('order_checkout:statusUnknownTimeout'),
      fullDesc: i18n.t('order_checkout:paymentStatusCouldntBeDetermined'),
      contactData: true
    };
  }

  /**
   * Method to set payment status to confirmed by restaurant status
   */
  @action setPaymentStatusToConfirmedByRestaurant() {
    this.paymentStatus = {
      shortDesc: `${i18n.t('order_payment_methods:thankYou')}!`,
      statusDesc: i18n.t('order_checkout:confirmedByRestaurant'),
      fullDesc: i18n.t('order_checkout:orderConfirmedBy', {
        name: this.root.restaurantStore.branch.branchName
      }),
      contactData: true
    };

    this.root.analyticsStore.sendConfirmedOrder();
  }

  /**
   * Method to set payment status to cancelled by restaurant status
   */
  @action setPaymentStatusToCancelledByRestaurant() {
    this.paymentStatus = {
      shortDesc: i18n.t('order_checkout:canceled'),
      statusDesc: i18n.t('order_checkout:canceledByRestaurant'),
      fullDesc: i18n.t('order_checkout:orderCanceledBy', {
        name: this.root.restaurantStore.branch.branchName
      }),
      contactData: true
    };

    this.root.analyticsStore.sendCanceledOrder();
  }

  /**
   * Method to set payment status to failed status
   */
  @action setPaymentStatusToFailed() {
    this.paymentStatus = {
      shortDesc: i18n.t('common:failed'),
      statusDesc: i18n.t('order_checkout:paymentTransactionFailed'),
      fullDesc: i18n.t('order_checkout:paymentProcessCouldntBeCompleted'),
      contactData: true
    };
  }

  /**
   * Method to set payment status to unknown
   */
  @action setPaymentStatusToUnknown() {
    this.paymentStatus = {
      shortDesc: i18n.t('common:noConnection'),
      statusDesc: i18n.t('order_checkout:statusUnknownNoConnection'),
      fullDesc: i18n.t('order_checkout:statusUnknownNoConnectionDescription'),
      contactData: true
    };
  }

  /**
   * Method to set payment status to connection problem
   */
  @action setPaymentStatusToConnectionProblem() {
    this.paymentStatus = {
      shortDesc: i18n.t('common:connectionProblem'),
      statusDesc: i18n.t('common:connectionProblem'),
      fullDesc: i18n.t('order_checkout:statusConnectionProblemDescription'),
      contactData: true
    };
  }

  /**
   * Method to handle errors if payment fails.
   * @param {object} err - error details
   */
  handleOrderError(err: Partial<ErrorType>) {
    this.err = err;

    this.setProcessingOrderStatus(false);
  }

  /**
   * Method for loading previous order data and filling previousOrderRequest store variable
   */
  @action
  async loadPreviousOrderFromStorage() {
    const { branch } = this.root.restaurantStore;

    const [lastOrder] = await this.storage.loadFromStorage(
      [this._localStorageLastOrder],
      branch.branchId
    );

    this.setPreviousOrderRequestData(lastOrder);
  }

  /**
   * Method for loading previous order id
   */
  @action
  async loadPreviousOrderIdFromStorage() {
    const { branch } = this.root.restaurantStore;

    const [orderId] = await this.storage.loadFromStorage(
      [this._localStorageOrderId],
      branch.branchId
    );

    this.setPreviousOrderId(orderId);

    this.root.analyticsStore.setTrackingTransactionId(orderId);
  }

  @action makeOrderSuccessful() {
    this.handleOrderError({ e: false });

    this.root.basketStore.resetBasket();

    this.setPaymentStatusToSuccessful();

    this.setOrderSentState(true);
  }

  @action makeOrderFailed() {
    this.handleOrderError({ e: true });

    this.setPaymentStatusToFailed();
  }

  /** Method to automatically save serialized products into storage */
  subscribeStorageToStore() {
    const { branch } = this.root.restaurantStore;

    this.activePaymentMethod &&
      this.storage.saveToStorage(
        this._localStorageKey,
        this.activePaymentMethod.id,
        branch.branchId
      );

    if (this.previousOrderRequest) {
      this.storage.saveToStorage(
        this._localStorageLastOrder,
        this.previousOrderRequest,
        branch.branchId
      );
    }
  }

  /**
   * Method to serialize every method in payment methods list.
   * @return {array} serialized array of payment methods
   */
  getToJS() {
    return this.paymentMethods.map((method) => method.getToJS());
  }

  /**
   * Method to change state of checking offline payment order
   */
  @action setCheckingOfflinePaymentOrderStatus(state = false) {
    this.isCheckingOfflinePaymentOrderStatus = state;
  }

  /**
   * Method to change pending status of payment in paypal system
   */
  @action setPendingPayPalPaymentStatus(state = false) {
    this.pendingPayPalPaymentStatus = state;
  }

  /**
   * Method to change status of processing payment in XORS system
   */
  @action setPaymentProcessingStatus(state = false) {
    this.isPaymentProcessing = state;
  }

  /**
   * Method to change time out status for waiting results of making order
   */
  @action setPaymentTimeOutStatus(state = false) {
    this.isPaymentTimeOutStatus = state;
  }

  /**
   * Method to change status of making order request
   */
  @action setProcessingOrderStatus(state = false) {
    this.orderInProcess = state;
  }

  /**
   * Method to allow receiving marketing info
   */
  @action setReceivingMarketingInfoStatus(state = false) {
    this.allowReceiveMarketingInfo = state;
  }

  /**
   * Method to change previous order id
   */
  @action setPreviousOrderId(state = undefined) {
    if (!state) {
      const { branchId } = this.root.restaurantStore.branch;

      this.storage.deleteFromStorage(this._localStorageOrderId, branchId);
    }

    this.previousOrderId = state;
  }

  /**
   * Method to update previous order request data for the backend
   */
  @action setPreviousOrderRequestData(state = undefined) {
    this.previousOrderRequest = state;
  }

  /**
   * Method to check if there is an error
   */
  @computed get isError() {
    return !!this.err.e;
  }

  @computed get isCanceledOrder() {
    return !!this.err.isCanceled;
  }

  /**
   * Method to check if PayPal selected
   */
  @computed get isPayPalSelected() {
    return this.activePaymentMethod?.id === this.PAYPAL_ID;
  }

  @computed get payPal2Selected() {
    return this.activePaymentMethod?.id === this.PAYPAL_V2_ID;
  }

  @computed get isOnlinePaymentSelected() {
    return this.activePaymentMethod?.isOnline;
  }

  /**
   * Method to check if store already have payment methods loaded.
   * @return check result
   */
  @computed get hasPaymentMethods() {
    return !!this.paymentMethods.length;
  }

  /**
   * Get active payment method.
   * @throws {error} if payment methods are not loaded.
   * @throws {error} if there is no active payment method.
   * @return {number} - active payment method.
   * @memberof OrderPaymentMethodsStore#
   * @method activePaymentMethod
   */
  @computed get activePaymentMethod() {
    const activePaymentMethod = this.paymentMethods.find(
      (method) => method.active
    );

    if (activePaymentMethod) {
      return activePaymentMethod;
    }

    if (this.paymentMethods.length) {
      return this.paymentMethods[0];
    }

    return undefined;
  }

  /**
   * Method to filter payment methods by cart amount
   * @return array of payment methods.
   */
  @computed get paymentMethodsFilteredByOrderAmount() {
    const isDelivery = this.root.deliveryAddressStore?.isDelivery;
    const totalPrice = this.root.basketStore?.finalPriceWithOffers;

    if (isDelivery) {
      return this.paymentMethods.filter(
        (method) => method.deliveryMinPrice <= totalPrice && method.delivery
      );
    }

    return this.paymentMethods.filter(
      (method) => method.pickupMinPrice <= totalPrice && method.pickup
    );
  }

  /**
   * Method to get array of payment methods names
   * @return {array} array of payment methods names
   * @memberof OrderPaymentMethodsStore#
   * @method namesOfPaymentMethods
   */
  @computed get namesOfPaymentMethods() {
    return this.paymentMethods.map((i) => PAYMENT_TYPES[i.id]);
  }

  /**
   * Is previous order available
   * @returns {boolean}
   */
  @computed get isPreviousOrderSet() {
    return !!this.previousOrderRequest;
  }

  /**
   * Is previous order delivery / pickup time chosen (not right now)
   * @returns {boolean}
   */
  @computed get isPreviousOrderTimeSet() {
    if (!this.isPreviousOrderSet) {
      return false;
    }

    return this.isPreviousOrderTypeDelivery
      ? !!this.previousOrderRequest?.delivery_time
      : !!this.previousOrderRequest?.selfcollect_time;
  }

  /**
   * Is previous order type is delivery
   * @returns {boolean}
   */
  @computed get isPreviousOrderTypeDelivery() {
    return (
      this.isPreviousOrderSet &&
      this.previousOrderRequest?.delivery_type === 'delivery'
    );
  }

  /**
   * True if active payment method is online and contactLess checkbox checked
   * @returns {boolean}
   */
  @computed get isContactLessMethodAvailable() {
    return this.isContactLessCheckboxChecked && this.isShowContactLessMessage;
  }

  /**
   * True if ContactLess Delivery/Pickup enabled in admin panel and appropriate method chosen
   */
  @computed get isShowContactLessMessage() {
    return this.root.deliveryAddressStore.isDelivery
      ? this.root.restaurantStore.branch.showContactLessDeliveryMessage
      : this.root.restaurantStore.branch.showContactLessPickupMessage;
  }

  /**
   * Contact less title depends on pickup/delivery method
   */
  @computed get contactLessTitle() {
    return this.root.deliveryAddressStore.isDelivery
      ? i18n.t('order_checkout:contactlessDeliveryTitle')
      : i18n.t('order_checkout:contactlessPickupTitle');
  }

  /**
   * Contact less description depends on pickup/delivery method
   */
  @computed get contactLessDescription() {
    return this.root.deliveryAddressStore.isDelivery
      ? i18n.t('order_checkout:contactlessDeliveryMessage')
      : i18n.t('order_checkout:contactlessPickupMessage');
  }

  @computed get complementaryStatusCheck() {
    const { isOrderStatusCheckAllowed } = this.root.restaurantStore.restaurant;

    return isOrderStatusCheckAllowed;
  }

  /**
   * Check for availability replacing Paypal to Crefopay
   */
  @computed get replacePaypalToCrefopay() {
    const { isShellThemeActive } = this.root.themesStore;

    return (
      this.activePaymentMethod?.useAsCrefoPay ===
        PAYPAL_TO_CREFOPAY_SETTINGS.REPLACE_PAYPAL_FOR_ALL ||
      (isShellThemeActive &&
        this.activePaymentMethod?.useAsCrefoPay ===
          PAYPAL_TO_CREFOPAY_SETTINGS.REPLACE_PAYPAL_FOR_SHELL) ||
      (!isShellThemeActive &&
        this.activePaymentMethod?.useAsCrefoPay ===
          PAYPAL_TO_CREFOPAY_SETTINGS.REPLACE_PAYPAL_FOR_WEB)
    );
  }

  /**
   * Method for checking status of online payment transaction
   */
  @action async checkTransactionStatus(canceled = false) {
    const { branchId } = this.root.restaurantStore.branch;

    if (!IS_CLIENT || this.isPaymentProcessing) {
      return;
    }

    this.setPaymentProcessingStatus(true);

    // user canceled the transaction
    if (canceled) {
      this.setPaymentStatusToCancelledByUserForCrefopay();

      this.handleOrderError({ e: true });

      this.setPaymentProcessingStatus(false);

      await this.storage.deleteFromStorage(this._localStorageOrderId, branchId);

      return;
    }

    let status = 'CREATED';

    const startTime = dayjs();
    const endTime = dayjs().add(10, 'minute');

    const [transactionId] = await this.storage.loadFromStorage(
      ['transactionId'],
      this._localStorageKey
    );

    this.root.analyticsStore.setTrackingTransactionId(transactionId);

    while (status === 'CREATED') {
      try {
        const delayTimeout = startTime.isAfter(startTime.add(2, 'minute'))
          ? 60
          : 10;

        const timeout = endTime.isBefore(dayjs());

        if (timeout) {
          throw new Error('Fetching PayPal status timed out');
        }

        const { data } = await fetchPayPalStatus(transactionId);

        if (data.status === 'CREATED') {
          await delay(delayTimeout * 1000);

          continue;
        }

        status = data.status;

        this.handleOrderError({ e: false });

        this.setOrderSentState(true);

        this.setPaymentStatusToSuccessful();

        this.checkRestaurantOrderConfirmation(transactionId);

        break;
      } catch (e) {
        status = 'FAILED';

        this.handleOrderError({ e: true });

        this.setPaymentStatusToFailed();
      }
    }

    this.setPaymentProcessingStatus(false);
  }

  @action async checkRestaurantOrderConfirmation(transactionId: string) {
    let status = 'WAITING';

    while (status === 'WAITING') {
      try {
        const startTime = dayjs();
        const endTime = dayjs().add(10, 'minute');

        const delayTimeout = startTime.isAfter(startTime.add(2, 'minute'))
          ? 60
          : 10;

        const timeout = endTime.isBefore(dayjs());

        if (timeout) {
          throw new Error('Fetching PayPal status timed out');
        }

        const data = await fetchRestaurantOrderStatus(transactionId);

        if (data.status === 'WAITING') {
          console.log(`delay ${delayTimeout} seconds before next retry`);

          await delay(delayTimeout * 1000);

          continue;
        }

        status = data.status;

        break;
      } catch (e) {
        console.error(e);

        break;
      }
    }
  }

  getHungerData() {
    getHungerAppData();
  }

  setHungerData(hungerData: OrderHungerData) {
    this.hungerData = hungerData;
  }
}

export default OrderPaymentMethodsStore;
