import classNames from 'classnames';
import debounce from 'lodash/debounce';
import flattenDeep from 'lodash/flattenDeep';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, {
  CSSProperties,
  Component,
  MouseEvent,
  ReactElement,
  ReactNode,
  createRef
} from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { VariableSizeList as List } from 'react-window';

import { CategoryModel } from 'client/models/category_menu.model';
import { OfferModel } from 'client/models/offer.model';
import { ModalsRouteType } from 'client/routes/ModalsRoute/ModalsRoute.type';
import openModal from 'client/utils/openModal';
import { IS_CLIENT } from 'config';

import images from '../../../../../../enums/images_enums/hermes_images.enum';
import PICTURE_MODES from '../../../../../../enums/product_images.enum';
import states from '../../../../../../enums/states.enum';
import { ProductModel } from '../../../../../../models/product.model';
import SmallLoader from '../../../loaders/small_loader';
import OfferCell from '../../../offer/offer_list/_item_cell/OffersCellHermesTheme';
import ProductListCategoryHeader from '../../../products_list/common/header/ProductListCategoryHeader';
import ProductCell from '../../../products_list/common/products-list/product-cell/ProductCell';
import { OpenProductModal } from '../../../products_list/desktop/props.interface';

import { ICategoryFullListProps } from './props.interface';
import { ICategoryFullListState } from './state.interface';
import './__category_full_list.scss';
import '../../../products_list/common/products-list/ProductList.scss';

interface IListRowElement {
  size: number;
  index: number;
  categoryId: number;
  header: boolean;
  customStyle: CSSProperties;
  /**
   * It used for rendering several items in one row of the virtual list
   * If you have only one item for virtual list item, set it to 0
   * If you have two items in the row, set it to 1, etc.
   */
  orderNumber: number;
}

/**
 * Component to render category header in products list
 */

@(withRouter as any)
@inject('store')
@observer
class CategoryFullList extends Component<
  ICategoryFullListProps,
  ICategoryFullListState
> {
  constructor(props: ICategoryFullListProps) {
    super(props);

    const isTablet =
      IS_CLIENT &&
      window.innerWidth >= this.props.store.themesStore.TABLET_WIDTH;

    const { branch } = this.props.store.restaurantStore;

    this.state = {
      reactWindowContainerHeight: 0,
      wholeProductListRendered: false,
      isFirstBigProductPicturesOnTablet:
        isTablet && branch && branch.showBigPictureVariant1
    };

    reaction(
      () => this.props.store.categoryMenuStore.categorySlideId,
      () => {
        const { categorySlideId } = this.props.store.categoryMenuStore;

        if (categorySlideId !== this.currentSlideId) {
          this.setCategorySlideId(categorySlideId);
        }
      }
    );

    reaction(
      () => this.props.store.themesStore.postHeaderBranchInfoMobileHeight,
      () => {
        if (this.props.store.themesStore.postHeaderBranchInfoMobileHeight) {
          this.toggleHeader();
        }
      }
    );

    this.changeSliderCategoryOnUserScroll = debounce(
      this.changeSliderCategoryOnUserScroll,
      30
    );

    this.onResize = debounce(this.onResize, 100);
  }

  ESTIMATED_CELL_SIZE = 80;

  SWIPER_HEIGHT = 60;

  listRef = createRef<
    {
      resetAfterIndex: (index: number, shouldForceUpdate?: boolean) => void;
      scrollTo: (scrollOffset: number) => void;
      scrollToItem: (index: number, align?: string) => void;
    } & ReactElement
  >();

  listRowElements: {
    [key: string]: IListRowElement;
  } = this.props.store.themesStore.virtualListSizes || {};

  listRowMap: {
    [key: string]: number;
  } = {};

  listWrapperRef = createRef<HTMLDivElement>();

  listInnerRef = createRef<HTMLDivElement>();

  mainProductsWrapperRef = createRef<HTMLDivElement>();

  productItems: ReactNode[] = [];

  // Manual scrolling - scrolling by 'scrollTo' or 'scrollToItem' methods
  manualScrolling = false;

  listIsScrolling = false;

  currentSlideId = 0;

  currentVisibleItem = 0;

  currentListOffset = 0;

  public componentDidMount() {
    const { productsStore } = this.props.store;

    this.listRowElements = {};

    productsStore.loadProductsByBranch();

    this.currentListOffset = this.props.store.themesStore.clickedProductTopOffset;

    this.setContainerHeight();

    const updateViewValue = this.isBigProductPictures1OnTablet();

    if (updateViewValue !== this.state.isFirstBigProductPicturesOnTablet) {
      this.setState({
        isFirstBigProductPicturesOnTablet: updateViewValue
      });
    }
  }

  public componentDidUpdate(
    prevProps: ICategoryFullListProps,
    prevState: ICategoryFullListState
  ) {
    if (
      this.listRef.current &&
      !prevState.wholeProductListRendered &&
      this.state.wholeProductListRendered
    ) {
      this.listRef.current.scrollTo(
        this.props.store.themesStore.clickedProductTopOffset
      );
    }

    this.listRef.current && this.listRef.current.resetAfterIndex(0);

    if (
      !this.state.wholeProductListRendered &&
      this.props.store.categoryMenuStore.wholeProductListLoaded
    ) {
      if (
        this.listRef.current &&
        this.listWrapperRef.current &&
        this.listInnerRef.current
      ) {
        window.addEventListener('resize', this.onResize);

        // It's for overlapping category menu above virtual list
        // It's done for simulating stopping of scrolling virtual list when a user taps on some category (changes for ios, but it works in chrome too)
        this.listInnerRef.current.style.position = 'relative';

        this.listWrapperRef.current.style.paddingTop = `${this.SWIPER_HEIGHT}px`;

        this.listWrapperRef.current.style.paddingBottom = '0';

        this.props.store.categoryMenuStore.scrollContainerElement(true);

        this.props.store.categoryMenuStore.scrollableContainerElement.addEventListener(
          'scroll',
          this.onScrollContainer,
          true
        );

        const rowsCount = Object.values(this.listRowElements)
          .sort((a, b) => a.index - b.index)
          .reduce((currentIndex, rowElement) => {
            if (!rowElement.orderNumber) {
              this.listRowMap[currentIndex.toString()] = rowElement.index;

              currentIndex++;
            }

            return currentIndex;
          }, 0);

        if (this.props.footerElement) {
          this.updateListRowElement({
            size: this.props.footerHeight || this.ESTIMATED_CELL_SIZE,
            index: this.productItems.length - 1,
            categoryId: 0,
            header: false,
            customStyle: {},
            orderNumber: 0,
            key: this.props.footerElement.key
          });

          this.listRowMap[rowsCount.toString()] = this.productItems.length - 1;
        }

        this.setState(
          {
            wholeProductListRendered: true
          },
          () => this.changeSliderCategoryOnUserScroll(this.currentVisibleItem)
        );
      }
    }
  }

  componentWillUnmount() {
    this.props.store.themesStore.setClickedProductTopOffset(
      Math.ceil(this.currentListOffset)
    );

    this.props.store.themesStore.setVirtualListSizes(this.listRowElements);

    this.props.store.categoryMenuStore.scrollableContainerElement.removeEventListener(
      'scroll',
      this.onScrollContainer
    );

    window.removeEventListener('resize', this.onResize);
  }

  isBigProductPictures1OnTablet = () => {
    const isTablet =
      IS_CLIENT &&
      window.innerWidth >= this.props.store.themesStore.TABLET_WIDTH;

    const { branch } = this.props.store.restaurantStore;

    return !!(isTablet && branch && branch.showBigPictureVariant1);
  };

  setContainerHeight = () => {
    const containerHeight = window.innerHeight - 41;

    if (containerHeight !== this.state.reactWindowContainerHeight) {
      this.setState({
        // 41 - Height of the top header and plus 1px for posibility to scroll common container for slider and virtual list
        reactWindowContainerHeight: containerHeight,
        isFirstBigProductPicturesOnTablet: this.isBigProductPictures1OnTablet()
      });
    }
  };

  /**
   * method for setting category slide id
   * @param {number} categorySlideId
   */
  private setCategorySlideId = (categoryId: number) => {
    const { categorySlideSource } = this.props.store.categoryMenuStore;

    if (categorySlideSource === 'menu') {
      this.props.store.themesStore.setClickedProductTopOffset();

      this.scrollToCategory(categoryId);
    }

    this.props.store.categoryMenuStore.setCategorySlide(
      categoryId,
      'categories'
    );

    this.currentSlideId = categoryId;
  };

  changeSliderCategoryOnUserScroll = (currentVisibleItem: number) => {
    if (!this.manualScrolling) {
      const nodeIndex = this.listRowMap[currentVisibleItem];

      const elementKey = Object.keys(this.listRowElements).find(
        (elementKey) => this.listRowElements[elementKey].index === nodeIndex
      );

      elementKey &&
        this.currentSlideId !== this.listRowElements[elementKey].categoryId &&
        this.setCategorySlideId(this.listRowElements[elementKey].categoryId);
    }
  };

  toggleHeader = () => {
    // Using DOM methods for speed. Transitions through MobX is very slow and causes 'jumps' for user
    const postHeader = document.getElementById('post-header-mobile');

    if (
      postHeader &&
      this.props.store.categoryMenuStore.scrollableContainerElement &&
      this.listInnerRef.current &&
      this.listWrapperRef.current
    ) {
      const {
        scrollTop
      } = this.props.store.categoryMenuStore.scrollableContainerElement;

      const { postHeaderBranchInfoMobileHeight } = this.props.store.themesStore;
      // 10 - for more responsibility of hiding block with restaurant information (logo, working hours, MBV and so on)
      // You can change it as you want but not less than 1
      const open = scrollTop <= 1 && this.currentListOffset <= 10;

      postHeader.style.height = open
        ? `${postHeaderBranchInfoMobileHeight}px`
        : '0';
    }
  };

  scrollToCategory(categoryId: number) {
    // All timeouts are for be sure that manual scrolling was completed
    // Unfortunately, react-window API doesn't have any callbacks for it now
    this.manualScrolling = true;

    const nodeIndex = parseInt(
      Object.keys(this.listRowMap).find(
        (index) =>
          this.listRowMap[index] ===
          this.listRowElements[this.createHeaderKey(categoryId)].index
      ) || '-1',
      10
    );

    this.listRef.current &&
      this.listRef.current.scrollToItem(nodeIndex, 'start');

    if (this.currentVisibleItem !== nodeIndex) {
      setTimeout(() => {
        this.listRef.current &&
          this.listRef.current.scrollToItem(nodeIndex, 'start');

        setTimeout(() => {
          this.manualScrolling = false;
        }, 0);
      }, 0);
    } else {
      setTimeout(() => {
        this.manualScrolling = false;
      }, 0);
    }
  }

  onResize = () => {
    this.setContainerHeight();
  };

  onVirtualItemsRendered = ({
    visibleStartIndex,
    overscanStartIndex
  }: {
    overscanStartIndex: number;
    overscanStopIndex: number;
    visibleStartIndex: number;
    visibleStopIndex: number;
  }) => {
    this.currentVisibleItem = visibleStartIndex;

    this.listRef.current?.resetAfterIndex(overscanStartIndex);
  };

  onScrollList = ({
    scrollDirection,
    scrollOffset
  }: {
    scrollDirection: 'forward' | 'backward';
    scrollOffset: number;
    scrollUpdateWasRequested: boolean;
  }) => {
    const { currentVisibleItem } = this;

    // Hack with 1px for backward scrolling - avoiding a bug of the library on ios caused by requestAnimationFrame inside codebase of the library
    // This bug make our virtual list to scroll up by very little space
    if (
      this.state.wholeProductListRendered &&
      scrollDirection === 'backward' &&
      this.currentListOffset - scrollOffset > 1
    ) {
      this.changeSliderCategoryOnUserScroll(currentVisibleItem);
    } else if (
      this.state.wholeProductListRendered &&
      scrollDirection === 'forward'
    ) {
      this.changeSliderCategoryOnUserScroll(currentVisibleItem);
    }

    this.currentListOffset = scrollOffset;

    this.toggleHeader();
  };

  onScrollContainer = () => {
    this.toggleHeader();
  };

  /**
   * Method to open modal with advanced product view
   */
  openProductModal: OpenProductModal = (event, product) => {
    event.stopPropagation();

    const { history } = this.props;

    const productUrl = openModal('productModal', {
      productId: `${product.id}`
    });

    history.push(productUrl);
  };

  /**
   * Method to add offer to basket
   * @param offer
   */
  private _onOfferClick = (offer: OfferModel) => {
    this.props.store.offerStore.addOffer(
      offer.id,
      offer.offerType,
      this.props.history
    );
  };

  /**
   * Handle add button click
   * @param product
   * @param event
   * @private
   */
  private _onAddProductClick = (event: MouseEvent, product: ProductModel) => {
    event.stopPropagation();

    if (!product.soldOut) {
      const { history, store } = this.props;
      const { categoryMenuStore, basketStore, productsStore } = store;

      categoryMenuStore.setContainerScrollPosition();

      basketStore.setLastProductClicked(product.id);

      productsStore.addProduct(product, history);
    }
  };

  /**
   * handler for click on halal icon
   * @param event
   */
  showHalalCertificate = (event: MouseEvent) => {
    event.stopPropagation();

    const certificateUrl = openModal(ModalsRouteType.CERTIFICATE_MODAL);

    this.props.history.push(certificateUrl);
  };

  // Key for identification products in virtual list
  createProductKey = (id: number) => `product-${id}`;

  // Key for identification headers of categories in virtual list
  createHeaderKey = (id: number) => `header-${id}`;

  getListItemsCount = (
    productItems: ReactNode[],
    categories: CategoryModel[]
  ) => {
    if (this.isBigProductPictures1OnTablet()) {
      let count = 0;

      categories.forEach((category: CategoryModel) => {
        const hasSubcategories = !!category.subCategories.length;

        if (hasSubcategories) {
          category.subCategories.forEach((subcategory: CategoryModel) => {
            count++; // Because of required header for category

            if (subcategory.id === -1) {
              count += this.props.store.offerStore.offers.length;
            } else {
              count += subcategory.someProductHasImage
                ? Math.round(subcategory.products.length / 2)
                : subcategory.products.length;
            }
          });
        } else {
          count++; // Because of required header for category

          if (category.id === -1) {
            count += this.props.store.offerStore.offers.length;
          } else {
            count += category.someProductHasImage
              ? Math.round(category.products.length / 2)
              : category.products.length;
          }
        }
      });

      if (this.props.footerElement) {
        count++;
      }

      return count;
    }

    return productItems.length;
  };

  updateListRowElement = (
    element: IListRowElement & { key: string },
    forceUpdate?: boolean
  ) => {
    const size = this.listRowElements[element.key]
      ? this.listRowElements[element.key].size
      : this.ESTIMATED_CELL_SIZE;

    this.listRowElements[element.key] = {
      size: element.size,
      index: element.index,
      categoryId: element.categoryId,
      header: element.header,
      customStyle: element.customStyle,
      orderNumber: element.orderNumber
    };

    this.listRef.current?.resetAfterIndex(element.index);

    if (forceUpdate && size !== element.size) {
      this.forceUpdate();
    }
  };

  /**
   * render categories list
   * @param {CategoryModel[]} categories
   * @return {Array}
   */
  private renderCategories(categories: CategoryModel[]): ReactNode[] {
    // Virtual list is based on indexes
    // All cells of it are recognised by indexes
    // So, we use it for correct calculating cell measures
    let index = -1;

    return flattenDeep(
      categories.map((category: CategoryModel) => {
        const hasSubcategories = !!category.subCategories.length;

        if (hasSubcategories) {
          return category.subCategories.map((subcategory: CategoryModel) => {
            index++;

            const oldIndex = index;

            index +=
              subcategory.id === -1
                ? this.props.store.offerStore.offers.length
                : subcategory.products.length;

            return this.createCategory(category, oldIndex, subcategory);
          });
        }

        index++;

        const oldIndex = index;

        index +=
          category.id === -1
            ? this.props.store.offerStore.offers.length
            : category.products.length;

        return this.createCategory(category, oldIndex);
      })
    );
  }

  /**
   * create category node
   * @param {CategoryModel} category
   * @param {boolean} isMobile
   * @param {boolean} isShellThemeActive
   * @param {CategoryModel} subcategory
   * @return {any}
   */
  private createCategory(
    category: CategoryModel,
    index: number,
    subcategory?: CategoryModel
  ) {
    let imageUrl;

    if (subcategory) {
      imageUrl = subcategory.hasCategoryImage
        ? subcategory.picurl_preview || subcategory.picurl
        : '';
    } else {
      imageUrl = category.hasCategoryImage
        ? category.picurl_preview || category.picurl
        : '';
    }

    const title = subcategory
      ? `${category.name} | ${subcategory.name}`
      : category.name;

    const description = subcategory
      ? subcategory.description
      : category.description;

    const id = subcategory ? subcategory.id : category.id;
    const key = this.createHeaderKey(id);

    !this.listRowElements[key] &&
      this.updateListRowElement({
        key,
        size: this.ESTIMATED_CELL_SIZE,
        index,
        categoryId: id,
        header: true,
        customStyle: {},
        orderNumber: 0
      });

    //! KEYS ARE VERY IMPORTANT FOR CORRECT WORKING OF VIRTUAL LIST, NOT ONLY FOR REACT
    return [
      <ProductListCategoryHeader
        key={key}
        image={imageUrl}
        title={title}
        description={description}
        bottomSpace={category.id === -1}
        loaderSrc={{
          url: images.greyBg
        }}
        setSize={
          (size: number) =>
            this.updateListRowElement({
              key,
              size,
              index,
              categoryId: id,
              header: true,
              customStyle: {},
              orderNumber: 0
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />,
      this.createProducts(subcategory || category, index + 1)
    ];
  }

  /**
   * create products and offers nodes
   * @param {CategoryModel} category
   * @return {null | any}
   */
  private createProducts(category: CategoryModel, index: number) {
    const { loadingAnimation } = this.props.store.restaurantStore.restaurant;

    const {
      isUseAdvancedProductView,
      currencyCode
    } = this.props.store.restaurantStore.branch;

    const { animateId: animatedProductId } = this.props.store.productsStore;
    const { animateId: animatedOfferId } = this.props.store.offerStore;
    const { isShopClosed } = this.props.store.restaurantStore;

    //  check for offer category
    if (category.id !== -1) {
      if (!category.loaded && category.loading) {
        return <SmallLoader loader={loadingAnimation} />;
      }

      const { branch } = this.props.store.restaurantStore;

      let pictureMode = PICTURE_MODES.NO_PICTURES;

      pictureMode =
        branch.showBigPictureVariant1 && category.someProductHasImage
          ? PICTURE_MODES.BIG_PICTURES_VARIANT_1
          : pictureMode;

      pictureMode =
        !pictureMode &&
        branch.showBigPictureVariant2 &&
        category.someProductHasImage
          ? PICTURE_MODES.BIG_PICTURES_VARIANT_2
          : pictureMode;

      pictureMode =
        !pictureMode && branch.showSmallPicture
          ? PICTURE_MODES.SMALL_PRODUCT_PICTURES
          : pictureMode;

      const basketProducts: {
        [key: string]: number;
      } = {};

      this.props.store.basketStore.products.forEach(
        (productItem: { count: number; product: ProductModel }) => {
          if (!basketProducts[productItem.product.id]) {
            basketProducts[productItem.product.id] = productItem.count;
          } else {
            basketProducts[productItem.product.id] += productItem.count;
          }
        },
        {}
      );

      const { id } = this.props.store.languagesStore.activeLanguage;
      const badgeStyle = this.props.store.themesStore.productBadgeStyle();

      const basketBounds = {
        ...this.props.store.basketStore.bounds[states.basket][states.basket],
        offsetY: this.currentListOffset
      };

      const { additivesProducts } = this.props.store.additivesStore;

      return category.products.map((product, productIndex) => {
        const key = this.createProductKey(product.id);

        // All padding are from desings, which can't be set in CSS file
        let customStyle: CSSProperties =
          pictureMode === PICTURE_MODES.BIG_PICTURES_VARIANT_1
            ? {
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: productIndex === 0 || productIndex === 1 ? 20 : 0
              }
            : {};

        customStyle =
          pictureMode === PICTURE_MODES.BIG_PICTURES_VARIANT_2
            ? {
                paddingTop: productIndex === 0 ? 20 : 0
              }
            : customStyle;

        const orderNumber =
          pictureMode === PICTURE_MODES.BIG_PICTURES_VARIANT_1 &&
          this.state.isFirstBigProductPicturesOnTablet
            ? productIndex % 2
            : 0;

        !this.listRowElements[key] &&
          this.updateListRowElement({
            key,
            size: this.ESTIMATED_CELL_SIZE,
            index: index + productIndex,
            categoryId: category.id,
            header: false,
            customStyle,
            orderNumber
          });

        //! KEYS ARE VERY IMPORTANT FOR CORRECT WORKING OF VIRTUAL LIST, NOT ONLY FOR REACT
        return (
          <ProductCell
            key={key}
            productLoadingColor={null}
            isShopClosed={isShopClosed}
            product={product}
            animatedId={animatedProductId}
            basketBounds={basketBounds}
            styles={{
              newBadgeStyle: badgeStyle
            }}
            isMobile
            additivesProducts={additivesProducts}
            hideProductArticles={branch.isHideArticleNumbers}
            onCellClick={
              isUseAdvancedProductView
                ? this.openProductModal
                : this._onAddProductClick
            }
            openProductModal={this.openProductModal}
            onButtonClick={this._onAddProductClick}
            activeLanguage={id}
            isUseAdvancedProductView={isUseAdvancedProductView}
            showHalalCertificate={this.showHalalCertificate}
            fullProductListDesign={false}
            picturesMode={pictureMode}
            isShell={false}
            basketProducts={basketProducts}
            currency={currencyCode}
            setSize={
              (size: number) =>
                this.updateListRowElement(
                  {
                    key,
                    size: customStyle.paddingTop
                      ? size + Number(customStyle.paddingTop)
                      : size,
                    index: index + productIndex,
                    categoryId: category.id,
                    header: false,
                    customStyle,
                    orderNumber
                  },
                  pictureMode === PICTURE_MODES.BIG_PICTURES_VARIANT_1 &&
                    !orderNumber
                )
              // eslint-disable-next-line react/jsx-curly-newline
            }
          />
        );
      });
    }

    const { offers } = this.props.store.offerStore;
    const { getLang } = this.props.store.restaurantStore.restaurant;
    const { currentOrderTypeHasPreorder } = this.props.store.openingHoursStore;

    return offers.map((offer: OfferModel, offerIndex: number) => {
      const key = `offer-${offer.id}`;

      !this.listRowElements[key] &&
        this.updateListRowElement({
          key,
          size: this.ESTIMATED_CELL_SIZE,
          index: index + offerIndex,
          categoryId: -1,
          header: false,
          customStyle: {},
          orderNumber: 0
        });

      //! KEYS ARE VERY IMPORTANT FOR CORRECT WORKING OF VIRTUAL LIST, NOT ONLY FOR REACT
      return (
        <OfferCell
          key={key}
          offer={offer}
          onOfferClick={this._onOfferClick}
          currentOrderTypeHasPreorder={currentOrderTypeHasPreorder}
          currency={currencyCode}
          animateId={animatedOfferId}
          offsetY={this.props.store.themesStore.clickedProductTopOffset}
          isShell={false}
          language={getLang}
          setSize={
            (size: number) =>
              this.updateListRowElement({
                key,
                size,
                index: index + offerIndex,
                categoryId: -1,
                header: false,
                customStyle: {},
                orderNumber: 0
              })
            // eslint-disable-next-line react/jsx-curly-newline
          }
        />
      );
    });
  }

  cellRenderer = ({
    index,
    style,
    isScrolling
  }: {
    index: number;
    style: CSSProperties;
    isScrolling: boolean;
  }) => {
    const elementKey = Object.keys(this.listRowElements).find(
      (elementKey) =>
        this.listRowMap[index] === this.listRowElements[elementKey].index
    );

    let customStyle = elementKey
      ? this.listRowElements[elementKey].customStyle
      : {};

    this.listIsScrolling = isScrolling;

    const categorySliderElement = document.getElementById('category-slider');

    if (categorySliderElement) {
      // Using DOM methods for speed. Transitions through MobX is very slow and causes 'jumps' for user
      // It's for overlapping category menu above virtual list
      // It's done for simulating stopping of scrolling virtual list when a user taps on some category (changes for ios, but it works in chrome too)
      categorySliderElement.style.pointerEvents = isScrolling ? 'none' : 'auto';
    }

    const children = this.productItems[this.listRowMap[index]]
      ? [this.productItems[this.listRowMap[index]]]
      : [];

    const { isFirstBigProductPicturesOnTablet } = this.state;

    if (isFirstBigProductPicturesOnTablet && elementKey) {
      const nextElementKey = Object.keys(this.listRowElements).find(
        (nextElementKey) =>
          this.listRowElements[nextElementKey].index ===
          this.listRowElements[elementKey].index + 1
      );

      const orderNumber = nextElementKey
        ? this.listRowElements[nextElementKey].orderNumber
        : 0;

      if (orderNumber) {
        children.push(this.productItems[this.listRowMap[index] + 1]);

        customStyle = {
          ...customStyle,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center'
        };
      }
    }

    return (
      <div
        style={{
          ...style,
          ...customStyle
        }}
      >
        {children}
      </div>
    );
  };

  public render() {
    const { excludeCategoryIds } = this.props;

    const categories = this.props.store.categoryMenuStore.categories.filter(
      (category: CategoryModel) => !excludeCategoryIds.includes(category.id)
    );

    const { wholeProductListLoaded } = this.props.store.categoryMenuStore;
    const { restaurant } = this.props.store.restaurantStore;

    const categoryNodes = wholeProductListLoaded
      ? this.renderCategories(categories)
      : [];

    this.productItems = flattenDeep(categoryNodes);

    this.props.footerElement &&
      this.productItems.push(this.props.footerElement);

    const { branch } = this.props.store.restaurantStore;

    let pictureMode = PICTURE_MODES.NO_PICTURES;

    pictureMode = branch.showBigPictureVariant1
      ? PICTURE_MODES.BIG_PICTURES_VARIANT_1
      : pictureMode;

    pictureMode =
      !pictureMode && branch.showBigPictureVariant2
        ? PICTURE_MODES.BIG_PICTURES_VARIANT_2
        : pictureMode;

    pictureMode =
      !pictureMode && branch.showSmallPicture
        ? PICTURE_MODES.SMALL_PRODUCT_PICTURES
        : pictureMode;

    const getSize = (index: number) => {
      const productItemIndex = this.listRowMap[index.toString()];

      const key = this.productItems[productItemIndex]
        ? (this.productItems[productItemIndex] as any).key
        : null;

      if (
        this.props.footerElement &&
        index === this.getListItemsCount(this.productItems, categories) - 1
      ) {
        return this.props.footerHeight;
      }

      if (key && pictureMode === PICTURE_MODES.BIG_PICTURES_VARIANT_1) {
        const nodeIndex = this.listRowElements[key].index;

        const nextElementKey = Object.keys(this.listRowElements).find(
          (nextElementKey) =>
            this.listRowElements[nextElementKey].index === nodeIndex + 1
        );

        if (
          nextElementKey &&
          this.listRowElements[nextElementKey].orderNumber
        ) {
          return (
            Math.max(
              this.listRowElements[key].size,
              this.listRowElements[nextElementKey].size
            ) || this.ESTIMATED_CELL_SIZE
          );
        }
      }

      return key && this.listRowElements[key]
        ? this.listRowElements[key].size
        : this.ESTIMATED_CELL_SIZE;
    };

    return (
      <>
        {wholeProductListLoaded ? (
          <div
            ref={this.mainProductsWrapperRef}
            id="main-products-wrapper"
            className="white-block"
          >
            <List
              ref={this.listRef}
              className={classNames({
                'products-list-container': true,
                'is-mobile': true,
                'big-picture-variant-1':
                  pictureMode === PICTURE_MODES.BIG_PICTURES_VARIANT_1,
                'big-picture-variant-2':
                  pictureMode === PICTURE_MODES.BIG_PICTURES_VARIANT_2
              })}
              outerRef={this.listWrapperRef}
              innerRef={this.listInnerRef}
              height={this.state.reactWindowContainerHeight}
              itemCount={this.getListItemsCount(this.productItems, categories)}
              itemSize={getSize}
              width="100%"
              onItemsRendered={this.onVirtualItemsRendered}
              onScroll={this.onScrollList}
              useIsScrolling
              overscanCount={6}
            >
              {this.cellRenderer}
            </List>
          </div>
        ) : (
          <SmallLoader loader={restaurant.loadingAnimation} />
        )}
      </>
    );
  }
}

export default withTranslation(['product_view', 'not_found'])(CategoryFullList);
