import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import DeliveryInfoRow from '../../../../components/delivery-info-row';
import HoursInfoRow from '../../../../components/hours-info-row';
import MbvInfoRow from '../../../../components/mbv-info-row';
import PAYMENT_IMAGES from '../../../../enums/images_enums/payment_methods_images.enum';
import PaymentImages from '../payment_methods/images-methods';
import PreparationTime from '../preparation_time/preparation_time';

import Phone from './Phone';
import './_customer.info.scss';
/**
 * Delivery info component is used to show info about delivery and its prices
 */
@inject('store')
@observer
class CustomerInfo extends Component {
  render() {
    const {
      openingHoursStore,
      basketStore,
      deliveryAddressStore,
      restaurantStore,
      analyticsStore,
      seoDataStore,
      maintenanceModeModalStore
    } = this.props.store;

    const {
      isOpenNow,
      isFirstHalfTime,
      isTodayOpeningsHasBreakTime,
      isTodayDeliveryHasBreakTime,
      currentOrderTypeHasPreorder
    } = openingHoursStore;

    const {
      isHolidayMode,
      areaCodes,
      getHideTel,
      getWebsitePhone,
      averageDeliveryTime,
      maxSelfcollectTime
    } = restaurantStore.branch;

    const holidayModeTime = restaurantStore.branch.secondsUntilOpenPickUp || 0;

    const isMaintenanceModeActive =
      maintenanceModeModalStore.maintenanceIsActive;

    const activeOrderType = deliveryAddressStore.activeOrderType?.value;
    const pickupDay = openingHoursStore.todayOpeningHours;
    const deliveryDay = openingHoursStore.todayDeliveryHours;
    const { mainSeoHeaderIsAvailable } = seoDataStore;

    return (
      <>
        {mainSeoHeaderIsAvailable ? (
          <h2 id="store-name" className="desktop-only">
            {this.props.store.restaurantStore.branch.branchName}
          </h2>
        ) : (
          <div id="store-name" className="desktop-only">
            {this.props.store.restaurantStore.branch.branchName}
          </div>
        )}
        <div className="delivery-info-component">
          <HoursInfoRow
            isOpenNow={isOpenNow}
            activeOrderType={activeOrderType}
            isMaintenanceModeActive={isMaintenanceModeActive}
            isHolidayMode={isHolidayMode}
            pickupDay={pickupDay}
            deliveryDay={deliveryDay}
            isTodayDeliveryHasBreakTime={isTodayDeliveryHasBreakTime}
            isTodayOpeningsHasBreakTime={isTodayOpeningsHasBreakTime}
            holidayModeTime={holidayModeTime}
            hasPreorder={currentOrderTypeHasPreorder}
            isFirstHalfTime={isFirstHalfTime}
          />

          {deliveryAddressStore.isDelivery && areaCodes.length ? (
            <DeliveryInfoRow
              locationChosen={restaurantStore.isLocationChosen}
              minDeliveryFee={restaurantStore.branch.minDeliveryFee}
              maxDeliveryFee={restaurantStore.branch.maxDeliveryFee}
              intlMinDeliveryFee={basketStore.intlMinDeliveryFee}
              intlMaxDeliveryFee={basketStore.intlMaxDeliveryFee}
              intlDeliveryFee={basketStore.intlDeliveryFee}
              deliveryFee={basketStore.deliveryFee}
              isDeliveryAvailable={openingHoursStore.isDeliveryAvailable}
              deliveryFreeFrom={basketStore.intlDeliveryFreeFrom}
            />
          ) : null}

          {(deliveryAddressStore.isDelivery && areaCodes.length) ||
          !deliveryAddressStore.isDelivery ? (
            <MbvInfoRow
              isDelivery={deliveryAddressStore.isDelivery}
              isLocationChosen={restaurantStore.isLocationChosen}
              mbvMinPrice={basketStore.mbwMinPrice}
              mbvMaxPrice={basketStore.mbwMaxPrice}
              minPrice={basketStore.intlMinPrice}
              t={this.props.t}
            />
          ) : null}

          {getWebsitePhone && !getHideTel && (
            <Phone
              phone={getWebsitePhone}
              onClick={() => analyticsStore.sendPhoneNumberTopBar()}
            />
          )}

          <PreparationTime
            isDelivery={deliveryAddressStore.isDelivery}
            averageDeliveryTime={averageDeliveryTime}
            averagePickUpTime={maxSelfcollectTime}
            isOpenNow={isOpenNow}
            t={this.props.t}
          />

          <PaymentImages
            methods={
              this.props.store.orderPaymentMethodsStore
                .paymentMethodsFilteredByOrderAmount
            }
            images={PAYMENT_IMAGES}
            hiddenDesktop
          />
        </div>
      </>
    );
  }
}

export default withTranslation(['delivery_info', 'opening_hours', 'common'])(
  CustomerInfo
);
