import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactSVG from 'react-svg';

import './styles.scss';

/**
 * Round button to display in buttons-bar on homepage
 */
export default class RoundShellButton extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    isCenter: PropTypes.bool,
    action: PropTypes.func,
    icon: PropTypes.string,
    link: PropTypes.string,
    style: PropTypes.object,
    image: PropTypes.string,
    className: PropTypes.string,
    svgClassName: PropTypes.string,
    dataTestId: PropTypes.string
  };

  static defaultProps = {
    label: 'button',
    action: () => {},
    disabled: false,
    isCenter: false,
    className: ''
  };

  /**
   * Method to handle button click
   * @private
   */
  _onClickHandler = (event) => {
    if (!this.props.disabled) {
      this.props.action?.(event);
    }
  };

  /**
   * Method to render link instead of button
   * used fot phone link
   * @returns {*}
   * @private
   */
  _renderLink = () => (
    <a
      href={this.props.link}
      onClick={this._onClickHandler}
      className={classNames({
        'shell-round-button': true,
        disabled: this.props.disabled,
        center: this.props.isCenter
      })}
      style={this.props.style}
      data-testid={this.props.dataTestId}
    >
      <div className={`icon mdi ${this.props.icon}`} style={this.props.style} />
      <div className="label">{this.props.label}</div>
    </a>
  );

  /**
   * Method to render button
   * @returns {*}
   * @private
   */
  _renderButton = () => (
    <button
      onClick={this._onClickHandler}
      disabled={this.props.disabled}
      className={classNames({
        'shell-round-button': true,
        disabled: this.props.disabled,
        center: this.props.isCenter,
        [this.props.className]: true
      })}
      data-testid={this.props.dataTestId}
    >
      {this.props.image &&
        (this.props.svgClassName ? (
          <ReactSVG
            src={this.props.image}
            beforeInjection={(svg) => {
              svg.setAttribute(
                'class',
                `${svg.getAttribute('class')} ${this.props.svgClassName}`
              );
            }}
          />
        ) : (
          <img src={this.props.image} alt="button background" />
        ))}
      {this.props.icon && (
        <div
          className={`icon mdi ${this.props.icon}`}
          style={this.props.style}
        />
      )}
      <div className="label">{this.props.label}</div>
    </button>
  );

  render() {
    return this.props.link ? this._renderLink() : this._renderButton();
  }
}
