export const checkForbiddenSymbols = (
  value: string,
  validateRule: RegExp
): boolean => {
  const forbiddenRule = `${validateRule}`
    .replace('[', '[^')
    .replace(/^\//, '')
    .replace(/\/$/, '');

  const forbiddenRegExp = new RegExp(forbiddenRule, 'g');
  const forbiddenSymbols: Array<string> | null = value.match(forbiddenRegExp);

  return !forbiddenSymbols;
};
