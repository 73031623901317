import classNames from 'classnames';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import './_payment-images.scss';

/**
 * Component to render gray list of payments methods
 */
export default class PaymentImages extends PureComponent {
  static propTypes = {
    methods: PropTypes.array.isRequired,
    images: PropTypes.array.isRequired,
    hiddenDesktop: PropTypes.bool
  };

  static defaultProps = {
    hiddenDesktop: false
  };

  render() {
    const allImages = this.props.methods.map(
      (m) => this.props.images.find((image) => image.id === m.id),
      'image'
    );

    const uniqImages = uniqBy(allImages, 'image');

    return (
      <div
        className={classNames({
          'paysystem-images': true,
          'tablet-and-phone': this.props.hiddenDesktop
        })}
      >
        {uniqImages.map((image) => {
          if (typeof image !== 'undefined') {
            return (
              <img
                src={image.image}
                key={image.id}
                title={`${image.title}`}
                alt={`logo ${image.title}`}
              />
            );
          }
        })}
      </div>
    );
  }
}
