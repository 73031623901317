// Imports
import React, { PureComponent } from 'react';
import { inject } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
// Utils
import { createHomepageHrefLink } from '../../../../../../../utils/functions';
import { generateLinkFor } from '../../../../../../../utils/routing';
// Config
import states from '../../../../../../../enums/states.enum';
import { ELEMENTS_TYPES } from '../../../../../../../enums/homepage_elements_types.enum';
// Styles
import './styles.scss';

/**
 * Component to render categories on mobile homepage
 */
@inject('store')
@withRouter
export default class HomepageCategoriesMobile extends PureComponent {
  /**
   * Method to render single category
   * @param category - Category object
   * @returns {*}
   * @private
   */
  _renderItem = (category) => {
    const linkAddress = category.parentCategoryId
      ? `${states.cat}/${category.parentCategoryId}/${category.category_id}`
      : `${states.cat}/${category.category_id}`;
    const link = generateLinkFor(linkAddress, this.props, {}, true);

    return (
      <div className="item-wrapper" key={category.category_id}>
        <Link to={link} className="item">
          <div className="img-wrapper">
            {category.picUrl && (
              <img src={category.picUrl} alt={category.name} />
            )}
          </div>
          <span className="title">{category.name}</span>
        </Link>
      </div>
    );
  };

  render() {
    return (
      <section
        className="mobile-homepage-section mobile-homepage-categories"
        id={createHomepageHrefLink([
          ELEMENTS_TYPES.CATEGORIES,
          this.props.index
        ])}
      >
        <h2>{this.props.title}</h2>
        <div className="list">
          {this.props.categories.map((category) => {
            return this._renderItem(category);
          })}
        </div>
      </section>
    );
  }
}
