import classnames from 'classnames';
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  memo,
  useCallback,
  useEffect,
  useRef
} from 'react';
import { useTranslation } from 'react-i18next';

import { useSearch } from 'client/hooks';
import logo from 'client/images/powered-by-google.webp';
import googleService, {
  AutocompletePrediction,
  loadGoogleMaps
} from 'client/services/googleService';
import Autocomplete from 'client/ui/Autocomplete';

import css from './StreetAutocomplete.module.scss';
import { StreetAutocompleteProps } from './StreetAutocomplete.type';

const StreetAutocomplete = ({
  className,
  city = '',
  country,
  zipcode = '',
  defaultValue = '',
  error,
  onSelect,
  placeholder,
  dataTestId
}: StreetAutocompleteProps) => {
  const { t } = useTranslation(['address_form']);
  const autocompleteRef = useRef<HTMLDivElement>(null);
  const selectedStreet = useRef('');

  const fetchGooglePlaces = async (input: string) => {
    const query = `${zipcode} ${city} ${input}`;

    const places =
      (await googleService.getPlacesPredictions(query, country)) ?? [];

    return places;
  };

  const {
    inputValue,
    options,
    handleInputChange,
    setInputValue,
    loader
  } = useSearch(fetchGooglePlaces);

  const getOptionLabel = useCallback((option: AutocompletePrediction) => {
    const { description } = option;
    const [street] = description.split(', ');

    return street;
  }, []);

  const getActiveOption = useCallback(() => false, []);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      selectedStreet.current = '';

      handleInputChange(event);
    },
    [handleInputChange]
  );

  const handleClick = useCallback(
    (option: AutocompletePrediction) => {
      const value = getOptionLabel(option);

      selectedStreet.current = value;
    },
    [getOptionLabel]
  );

  const handleBlur = useCallback<FocusEventHandler>(() => {
    const [prediction] = options;

    let newValue = '';

    if (selectedStreet.current) {
      newValue = selectedStreet.current;
    } else if (prediction) {
      newValue = getOptionLabel(prediction);
    }

    setInputValue(newValue);

    onSelect(newValue);
  }, [getOptionLabel, onSelect, options, setInputValue]);

  const handleFocus = useCallback(() => {
    selectedStreet.current = inputValue;
  }, [inputValue]);

  const googleLogo = (
    <div className={css.poweredByGoogle}>
      <img src={logo} />
    </div>
  );

  useEffect(() => {
    loadGoogleMaps(country);

    const changeEvent = { target: { value: defaultValue } };

    handleInputChange(changeEvent as any);
  }, []);

  const streetOptions = options.reduce<AutocompletePrediction[]>((acc, cv) => {
    const contains = acc.some(
      (value) => getOptionLabel(value) === getOptionLabel(cv)
    );

    if (!contains) {
      acc.push(cv);
    }

    return acc;
  }, []);

  return (
    <Autocomplete
      autocompleteRef={autocompleteRef}
      className={classnames(css.StreetAutocomplete, className)}
      dropdownFooter={googleLogo}
      error={error}
      getOptionLabel={getOptionLabel}
      noOptionLabel={t('address_form:enterPleaseStreetAddress')}
      getActiveOption={getActiveOption}
      placeholder={placeholder}
      onBlur={handleBlur}
      onClick={handleClick}
      onChange={handleChange}
      onFocus={handleFocus}
      options={streetOptions}
      suffix={loader}
      value={inputValue}
      dataTestId={dataTestId}
    />
  );
};

export { StreetAutocomplete };

export default memo(StreetAutocomplete);
