export function StructuredDataRestaurant(item) {
  return {
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      '@context': 'http://schema.org',
      '@type': 'Restaurant',
      menu: `${item.website}?utm_source=google&utm_medium=organic&utm_campaign=google_place_order_action`,
      address: {
        '@type': 'PostalAddress',
        addressLocality: item.city,
        postalCode: item.postalCode,
        streetAddress: item.streetAddress,
        addressCountry: {
          name: item.countryCode
        }
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: item.latitude,
        longitude: item.longitude
      },
      name: item.name,
      email: item.email,
      telephone: item.phone,
      faxNumber: item.fax,
      url: item.website,
      image: item.logo,
      logo: item.logo,
      paymentAccepted: item.paymentAccepted,
      currenciesAccepted: [item.currency],
      branchCode: item.branchCode,
      priceRange: '€',
      openingHoursSpecification: item.openingHours,
      potentialAction: {
        '@type': 'OrderAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: `${item.website}?utm_source=google&utm_medium=organic&utm_campaign=google_place_order_action`,
          inLanguage: item.language,
          actionPlatform: [
            'http://schema.org/DesktopWebPlatform',
            'http://schema.org/MobileWebPlatform'
          ]
        },
        deliveryMethod: [
          'http://purl.org/goodrelations/v1#DeliveryModeOwnFleet',
          'http://purl.org/goodrelations/v1#DeliveryModePickUp'
        ],
        priceSpecification: {
          '@type': 'DeliveryChargeSpecification',
          appliesToDeliveryMethod:
            'http://purl.org/goodrelations/v1#DeliveryModeOwnFleet',
          priceCurrency: item.currency,
          price: String(item.deliveryFee),
          eligibleTransactionVolume: {
            '@type': 'PriceSpecification',
            priceCurrency: item.currency,
            price: String(item.deliveryFee)
          }
        }
      }
    })
  };
}
