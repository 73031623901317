import isEqual from 'lodash/isEqual';
import React, { Component, ComponentType } from 'react';

export function withPropsDeepComparison(): <T extends Record<string, any>>(
  ChildComponent: ComponentType<T>
) => void {
  return <T extends { [key: string]: keyof T }>(
    ChildComponent: ComponentType<T>
  ) =>
    class extends Component<{ [key: string]: keyof T } & T> {
      shouldComponentUpdate(nextProps: T) {
        let needUpdate = false;

        Object.keys(this.props).some((key: string) => {
          const isPropsEqual = isEqual(this.props[key], nextProps[key]);

          needUpdate = !isPropsEqual;

          return !isPropsEqual;
        });

        return needUpdate;
      }

      render() {
        return <ChildComponent {...(this.props as T)} />;
      }
    };
}
