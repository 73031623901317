/**
 * @namespace
 * @property {object} defaults - States of the app
 * @property {string} defaults.app - Default state of the app
 * @property {object} defaults.queryParameters - URL parameters
 * @property {string} defaults.queryParameters.lang - App language. Available languages can be found in languages.enum.js
 */
export default {
  app: '',
  error: 'error',
  maintenanceMode: 'maintenance-mode',
  shopMoved: 'shop-moved',
  cat: 'cat',
  checkout: 'checkout',
  address: 'address',
  aggregator: 'aggregator',
  changeZip: 'change-zip',
  addressModal: 'address/modal',
  basket: 'basket',
  basketExtras: 'extras',
  basketDelete: 'delete',
  product: 'product',
  productExtras: 'extras',
  productCertificate: 'certificate',
  productView: 'view',
  contactForm: 'contact-form',
  imprint: 'imprint',
  privacyPolicy: 'privacy-policy',
  news: 'news',
  termsOfUse: 'terms-of-use',
  offer: 'offer',
  offerEdit: 'offer-edit',
  startPage: 'start',
  tableReservation: 'table-reservation',
  branchList: 'branch-list',
  allergensList: 'allergens-list',
  allergensListModal: 'allergens-list-modal',
  openingTimes: 'opening-times',
  deliveryInfo: 'delivery-info',
  homepagePreview: 'homepage-preview',
  kundeninformation: 'kundeninformation',

  queryParameters: {
    lang: 'lang',
    areaCode: 'area_code',
    sublocality: 'sublocality',
    streetName: 'street_name',
    streetNo: 'street_no',
    city: 'city',
    timeToDeliver: 'time_to_deliver',
    timeToSelfcollect: 'time_to_selfcollect',
    firstName: 'first_name',
    lastName: 'last_name',
    email: 'email',
    phone: 'phone',
    company: 'company',
    success: 'success',
    hunger_de: 'hunger_de',
    stylePreview: 'stylePreview',
    platform: 'platform',
    showAppUpdateButton: 'showAppUpdateButton',
    skippedBranch: 'skippedBranch',
    previewId: 'previewId',
    generateDemoBasket: 'generateDemoBasket',
    designId: 'designId',
    openNews: 'openNews',
    screenshotMode: 'screenshotMode',
    aggregator: 'aggregator',
    branchPostalCodes: 'br-postal-codes',
    orderType: 'orderType',
    googlePlaceId: 'place_id',
    longitude: 'longitude',
    latitude: 'latitude',
    basketData: 'basket_data',
    dynToken: 'dyn_token'
  }
};
