import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import { ShellModalsRouteType } from 'client/routes/ShellModalsRoute/ShellModalsRoute.type';
import openModal from 'client/utils/openModal';

import images from '../../../../../../enums/images_enums/hermes_images.enum';
import states from '../../../../../../enums/states.enum';
import LanguageSwitcherShell from '../../LanguageSwitcherShell';
import MenuItemHrefShell from '../menu-item-href-shell';
import MenuItemShell from '../menu-item-shell';

/**
 * Component to render side menu list for shell
 */
@inject('store')
@observer
@withRouter
class SidebarMenuListShell extends PureComponent {
  static propTypes = {
    isBranchListAllowed: PropTypes.bool.isRequired,
    isTableReservationAllowed: PropTypes.bool.isRequired,
    categoriesState: PropTypes.string.isRequired,
    closeSideMenuAction: PropTypes.func.isRequired,
    inverseFavoritesState: PropTypes.func,
    shareAction: PropTypes.func.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    showPhoneNumber: PropTypes.bool.isRequired,
    showPrivacyPolicy: PropTypes.bool,
    hideOrderSmartLogo: PropTypes.bool,
    isShellThemeActive: PropTypes.bool,
    isPortalAppThemeActive: PropTypes.bool,
    isAddedToFavorites: PropTypes.bool
  };

  /**
   * Method to render menu items
   * @returns {*}
   * @private
   */
  addToFavorites = () => {
    this.props.inverseFavoritesState();

    this.props.closeSideMenuAction();
  };

  handleLinkClick = (event) => {
    event.stopPropagation();
  };

  handlePhoneNumberClick = () => {
    const { closeSideMenuAction, store } = this.props;
    const { analyticsStore } = store;

    closeSideMenuAction();

    analyticsStore.sendPhoneNumberAppSidemenu();
  };

  _renderMenuItems = () => {
    const {
      isBranchListAllowed,
      isTableReservationAllowed,
      categoriesState,
      closeSideMenuAction,
      shareAction,
      phoneNumber,
      showPhoneNumber,
      showPrivacyPolicy,
      isShellThemeActive,
      isPortalAppThemeActive,
      store
    } = this.props;

    const { favoritesStore } = store;
    const allergensUrl = openModal(ShellModalsRouteType.ALLERGENS_MODAL);
    const contactFormUrl = openModal(ShellModalsRouteType.CONTACT_MODAL);
    const imprintUrl = openModal(ShellModalsRouteType.IMPRINT_MODAL);
    const privacyUrl = openModal(ShellModalsRouteType.PRIVACY_MODAL);

    const tableReservationUrl = openModal(
      ShellModalsRouteType.TABLE_RESERVATION
    );

    const termsUrl = openModal(ShellModalsRouteType.TERMS_MODAL);

    const favoritesLabel = favoritesStore.isAddedToFavorites
      ? this.props.t('common:removeFromFavorites')
      : this.props.t('common:addToFavorites');

    return (
      <>
        <MenuItemShell
          title={this.props.t('common:lblHomePage')}
          icon="fa-home"
          state={states.app}
          action={closeSideMenuAction}
          style={this.props.style}
          dataTestId="start-title-menu"
          dataTestIdIcon="start-icon-menu"
          dataTestIdLink="strat-menu"
        />

        {isShellThemeActive && !isPortalAppThemeActive && (
          <MenuItemShell
            title={this.props.t('common:lblShare')}
            icon="fa-share-alt"
            state={states.app}
            action={shareAction}
            style={this.props.style}
            dataTestId="share-title-menu"
            dataTestIdIcon="share-icon-menu"
            dataTestIdLink="share-menu"
          />
        )}

        {isPortalAppThemeActive && (
          <MenuItemShell
            title={favoritesLabel}
            icon="fa-star"
            state={states.app}
            action={this.addToFavorites}
            style={this.props.style}
          />
        )}

        <MenuItemShell
          title={this.props.t('common:lblMenu')}
          icon="fa-shopping-cart"
          state={categoriesState}
          action={closeSideMenuAction}
          style={this.props.style}
          dataTestId="menu-title-menu"
          dataTestIdIcon="menu-icon-menu"
          dataTestIdLink="menu-menu"
        />

        {isBranchListAllowed && !isPortalAppThemeActive && (
          <MenuItemShell
            title={this.props.t('opening_hours:lblBranchList')}
            icon="fa-map-marker"
            state={states.branchList}
            action={closeSideMenuAction}
            style={this.props.style}
            dataTestId="branches-title-menu"
            dataTestIdIcon="branches-icon-menu"
            dataTestIdLink="branches-menu"
          />
        )}

        {!isPortalAppThemeActive && (
          <MenuItemShell
            title={this.props.t('title:lblNews')}
            icon="fa-newspaper"
            state={states.news}
            action={closeSideMenuAction}
            style={this.props.style}
            dataTestId="news-title-menu"
            dataTestIdIcon="news-icon-menu"
            dataTestIdLink="news-menu"
          />
        )}

        <Link
          to={allergensUrl}
          onClick={this.handleLinkClick}
          replace
          data-testid="allergens-menu"
        >
          <div
            className="menu-item"
            onClick={closeSideMenuAction}
            style={this.props.style}
          >
            <i
              className="fas fa-info-circle"
              data-testid="allergens-icon-menu"
            />
            <div className="title" data-testid="allerg-title-menu">
              {this.props.t('title:allergensAdditives')}
            </div>
          </div>
        </Link>

        <MenuItemShell
          title={this.props.t('opening_hours:lblWorkingHours')}
          icon="fa-clock"
          state={states.openingTimes}
          action={closeSideMenuAction}
          style={this.props.style}
          dataTestId="worktime-title-menu"
          dataTestIdIcon="worktime-icon-menu"
          dataTestIdLink="worktime-menu"
        />

        <MenuItemShell
          title={this.props.t('delivery_info:lblDeliveryInfo')}
          icon="fa-motorcycle"
          state={states.deliveryInfo}
          action={closeSideMenuAction}
          style={this.props.style}
          dataTestId="delivinfo-title-menu"
          dataTestIdIcon="deliveinfo-icon-menu"
          dataTestIdLink="delivinfo-menu"
        />

        {phoneNumber && showPhoneNumber && (
          <MenuItemHrefShell
            title={this.props.t('common:lblCallUs')}
            icon="fa-phone"
            href={`tel:${phoneNumber}`}
            action={this.handlePhoneNumberClick}
            style={this.props.style}
            dataTestId="call-title-menu"
            dataTestIdIcon="call-icon-menu"
            dataTestIdLink="call-menu"
          />
        )}

        {isTableReservationAllowed && (
          <Link
            to={tableReservationUrl}
            onClick={this.handleLinkClick}
            replace
            data-testid="reserv-menu"
          >
            <div
              className="menu-item"
              onClick={closeSideMenuAction}
              style={this.props.style}
            >
              <i className="fas fa-list-alt" data-testid="reserv-icon-menu" />
              <div className="title" data-testid="reserv-title-menu">
                {this.props.t('footer:btnTableReservation')}
              </div>
            </div>
          </Link>
        )}

        <Link
          to={contactFormUrl}
          onClick={this.handleLinkClick}
          replace
          data-testid="contact-menu"
        >
          <div
            className="menu-item"
            onClick={closeSideMenuAction}
            style={this.props.style}
          >
            <i className="fas fa-envelope" data-testid="contact-icon-menu" />
            <div className="title" data-testid="contact-title-menu">
              {this.props.t('footer:contactUs')}
            </div>
          </div>
        </Link>

        <LanguageSwitcherShell
          className="menu-item"
          inlineStyle={this.props.style}
        />

        <Link
          to={termsUrl}
          onClick={this.handleLinkClick}
          replace
          data-testid="agb-menu"
        >
          <div
            className="menu-item"
            onClick={closeSideMenuAction}
            style={this.props.style}
          >
            <i className="fas fa-copyright" data-testid="agb-icon-menu" />
            <div className="title" data-testid="agb-title-menu">
              {this.props.t('footer:termsOfUse')}
            </div>
          </div>
        </Link>

        {!isPortalAppThemeActive && (
          <Link
            to={imprintUrl}
            onClick={this.handleLinkClick}
            replace
            data-testid="imprint-menu"
          >
            <div
              className="menu-item"
              onClick={closeSideMenuAction}
              style={this.props.style}
            >
              <i className="fas fa-handshake" data-testid="imprint-icon-menu" />
              <div className="title" data-testid="imprint-title-menu">
                {this.props.t('footer:legalInfo')}
              </div>
            </div>
          </Link>
        )}

        {isPortalAppThemeActive && (
          <MenuItemHrefShell
            title={this.props.t('footer:legalInfo')}
            icon="fa-handshake"
            href="https://hunger.de/imprint"
            action={closeSideMenuAction}
            style={this.props.style}
            dataTestId="imprint-title-menu"
            dataTestIdIcon="privacy-icon-menu"
            dataTestIdLink="privacy-menu"
          />
        )}

        {showPrivacyPolicy && (
          <Link to={privacyUrl} onClick={this.handleLinkClick} replace>
            <div
              className="menu-item"
              onClick={closeSideMenuAction}
              style={this.props.style}
            >
              <i className="fas fa-handshake" />
              <div className="title" data-testid="privacy-title-menu">
                {this.props.t('footer:dsvgoTitle')}
              </div>
            </div>
          </Link>
        )}
      </>
    );
  };

  render() {
    const appSmartLogo = this.props.isPortalAppThemeActive
      ? images.orderSmartWhiteLogo
      : images.orderSmartLogo;

    return (
      <div className="menu-list-wrapper">
        {this._renderMenuItems()}

        {!this.props.hideOrderSmartLogo && (
          <div className="menu-item menu-logo" style={this.props.style}>
            <img src={appSmartLogo} alt="logo" />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation([
  'footer',
  'order_payment_methods',
  'title',
  'common',
  'delivery_info'
])(SidebarMenuListShell);
