import dayjs from 'dayjs';
import { action, computed, observable } from 'mobx';

import STORAGE_KEYS from 'client/enums/storage_keys.enum';
import Storage from 'client/modules/storage';
import RootStore from 'client/stores';

/**
 * Class for maintenance modal store.
 * @constructor
 * @param {instance} api - {@link API} instance.
 * @param {instance} storage - {@link Storage} instance.
 * @property {string} lastUpdatedKey - Unique storage key to store data.
 * @property {date} lastUpdatedTime - Last updated maintenance mode
 * @property {object} data - Unique storage key to store data.
 * @property {boolean} loading - Observable loading status
 * @property {string} password - Observable password value
 * @property {boolean} invalidPasswordError - Observable password error status
 */
export default class MaintenanceModeModalStore {
  root: RootStore;

  api: any;

  storage: Storage;

  constructor(root, state, api, storage) {
    Object.assign(this, state);

    this.root = root;

    this.api = api;

    this.storage = storage;
  }

  lastUpdatedKey = STORAGE_KEYS.LAST_UPDATED_MAINTENANCE;

  lastUpdatedTime = '';

  @observable data = {
    maintenance: false,
    maintenance_title: '',
    maintenance_reason: '',
    maintenance_picture: ''
  };

  @observable loading = false;

  @observable password = '';

  @observable invalidPasswordError = false;

  @observable activated = false;

  /**
   * Method to change loading status
   * @param {boolean} flag - Loading status.
   */
  toggleLoading(flag) {
    this.loading = flag;
  }

  /**
   * Method to change password error status
   * @param {boolean} flag - Password error status.
   */
  toggleInvalidPasswordError(flag) {
    this.invalidPasswordError = flag;
  }

  /**
   * Method to handle password change event
   * @param {string} password - Password value.
   */
  @action handlePasswordChanged(password) {
    this.password = password;
  }

  @action activateMaintenance(flag) {
    this.activated = flag;
  }

  @computed get getPassword() {
    return this.password;
  }

  /**
   * Method to load last updated time from storage
   * @return {promise} time
   * @memberof MaintenanceModeModalStore#
   * @method loadLastUpdatedMaintenanceFromStorage
   */
  @action
  async loadLastUpdatedMaintenanceFromStorage() {
    const { firstBranchId } = this.root.restaurantStore.restaurant;

    const [lastUpdated] = await this.storage.loadFromStorage(
      [this.lastUpdatedKey],
      firstBranchId
    );

    this.lastUpdatedTime = dayjs(lastUpdated).format();

    return this.lastUpdatedTime;
  }

  /**
   * Method for remote password validation.
   * @param {string} token - validation token.
   * @return {promise} check result.
   * @memberof MaintenanceModeModalStore#
   * @method validatePasswordRemote
   */
  @action validatePasswordRemote(token) {
    const { firstBranchId } = this.root.restaurantStore.restaurant;

    this.toggleInvalidPasswordError(false);

    this.toggleLoading(true);

    return this.api
      .checkMaintenancePassword({ pwd: this.password, token })
      .then(() =>
        this.storage.saveToStorage(this.lastUpdatedKey, dayjs(), firstBranchId)
      )
      .then(() => this.activateMaintenance(true));
  }

  /**
   * Method to check if last enter was more than one day ago.
   * @return {boolean}
   * @memberof MaintenanceModeModalStore#
   * @method isLastEnteredMoreThanOneDay
   */
  @computed get isLastEnteredMoreThanOneDay() {
    return dayjs().diff(this.lastUpdatedTime, 'day') < 1;
  }

  /**
   * Method to check if maintenance mode ha been activated less day ago
   * @return {boolean} is active.
   * @memberof MaintenanceModeModalStore#
   * @method isMaintenanceActivateLessDayAgo
   */
  @computed get isMaintenanceActivateLessDayAgo() {
    return (
      this.root.restaurantStore.restaurant.hasMaintenance &&
      this.isLastEnteredMoreThanOneDay
    );
  }

  /**
   * Method to check if maintenance mode active.
   * @return {boolean} is active.
   * @memberof MaintenanceModeModalStore#
   */
  @computed get maintenanceIsActive() {
    if (this.root.themesStore.isShellThemeActive) {
      return this.root.restaurantStore.restaurant.hasMaintenanceApp;
    }

    return this.root.restaurantStore.restaurant.hasMaintenance;
  }
}
