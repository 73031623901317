export enum AnalyticsEvent {
  VIEW_SHOP = 'View Shop',
  ADD_TO_BASKET = 'Add to Basket',
  START_CHECKOUT = 'Start Checkout',
  PROVIDE_PERSONAL_INFORMATION = 'Provide Personal Information',
  PLACE_ORDER = 'Place Order',
  SUCCESSFUL_PAYMENT = 'Successful payment',
  FAILED_PAYMENT = 'Failed payment',
  GLOBAL_ERROR = 'Global error',
  TRACK_THANK_YOU_PAGE = 'Thank you page',
  PHONE_NUMBER_TOP_BAR = 'Phone Number Top Bar',
  PHONE_NUMBER_LEGAL_PAGE = 'Phone Number Legal Page',
  PHONE_NUMBER_SUCCESSFUL_PAYMENT = 'Phone Number Successful Payment',
  PHONE_NUMBER_FAILED_ORDER = 'Phone Number Failed Order',
  PHONE_NUMBER_APP_MAIN_PAGE = 'Phone Number App Main Page',
  PHONE_NUMBER_APP_SIDEMENU = 'Phone Number App Sidemenu',
  CONFIRMED_ORDER = 'Confirmed order',
  CANCELED_ORDER = 'Canceled order'
}
