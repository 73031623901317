import classNames from 'classnames';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import RootStore from 'client/stores';
import { OrderHungerData } from 'client/stores/orderPaymentMethods/orderPaymentMethods.store.type';
import { mapQueryParamsToObject } from 'client/utils/functions';
import { IS_CLIENT } from 'config';

import ShellRoute from '../../routes/shell-route';
import ClosedModal from '../../themes/theme-hermes/components/closed-store/modals/closed-modal';
import MigratedStoreModal from '../../themes/theme-hermes/theme-hermes-shell/components/migrated-store-modal/migrated-store-modal';
import { prepareDataInStores } from '../../utils/boot';

import AppWrapperDesktop from './_AppWrapper.desktop';
import AppWrapperMobile from './_AppWrapper.mobile';

import './AppWrapper.scss';

@inject('store')
@(withRouter as any)
@observer
export default class AppWrapperComponent extends Component<
  { store: RootStore } & RouteComponentProps
> {
  /**
   * Method to load data or clear data from stores and local storage
   */
  UNSAFE_componentWillMount = () => {
    if (IS_CLIENT) {
      const queryParams = mapQueryParamsToObject(
        this.props.history.location.search
      );

      this.props.store.restaurantStore
        .loadBuildVersion()
        .then((data) => {
          if (data !== BUILD_VERSION) {
            this.props.store.restaurantStore.forceClearStorage();

            this.props.store.restaurantStore.saveBuildVersion();
          }

          return data;
        })
        .then(() => {
          this.props.store.categoryMenuStore.setLoading(true);

          Promise.all([
            this.props.store.restaurantStore.loadAreaCode(),
            this.props.store.deliveryAddressStore.loadOrderTypeFromStorage(),
            this.props.store.deliveryAddressStore.loadAddressFromStorage(),
            this.props.store.orderPaymentMethodsStore.loadPreviousOrderFromStorage(),
            this.props.store.couponsStore.checkCouponCodeInStorage(),
            this.props.store.aggregatorStore.loadAggregatorSlugFromStorage(),
            this.props.store.aggregatorStore.loadAggregatorFromStorage(),
            this.props.store.orderPaymentMethodsStore.loadMarketingCheckboxFromStorage()
          ]).then(() => {
            if (
              !this.props.match.params.catId &&
              !this.props.match.params.subId
            ) {
              this.props.store.categoryMenuStore.loadPreviouslyActiveCategoryFromStorage();
            } else {
              this.props.store.categoryMenuStore.setLoading(false);
            }

            return prepareDataInStores(this.props.store, queryParams);
          });
        });

      if (!this.props.store.themesStore.isShellThemeActive) {
        this.props.store.restaurantStore.loadAppPictureImage();
      }
    }
  };

  componentDidMount() {
    const {
      deliveryAddressStore,
      restaurantStore,
      themesStore
    } = this.props.store;

    const { isHunger } = themesStore;

    if (IS_CLIENT && isHunger) {
      window.app = this;

      this.getFavoritesState();

      this.props.store.orderPaymentMethodsStore.getHungerData();
    }

    reaction(
      () => deliveryAddressStore.orderTypes,
      () => restaurantStore.setShowClosedModal()
    );
  }

  // Needs for receiving keyboard height from iOS application
  changeIosKeyboardHeight = (height) => {
    this.props.store.themesStore.setIOSKeyboardHeight(height);
  };

  // Needs for receiving favorites state from iOS application
  setFavoritesState = (branchId, state) => {
    // eslint-disable-next-line eqeqeq
    if (branchId == this.props.store.restaurantStore.branch.id) {
      this.props.store.favoritesStore.setInitialFavoritesState(state);
    }
  };

  // Needs for receiving hunger app data from native (ios/android) application
  setHungerAppData = (hungerData: OrderHungerData) => {
    this.props.store.orderPaymentMethodsStore.setHungerData(hungerData);
  };

  getFavoritesState = () => {
    const { branchId } = this.props.store.restaurantStore.branch;

    const {
      isPortalAppThemeActive,
      isPortalWebThemeActive
    } = this.props.store.themesStore;

    if ((isPortalAppThemeActive || isPortalWebThemeActive) && branchId) {
      this.props.store.favoritesStore.getFavoritesStateFromDevice(branchId);
    }
  };

  renderHunger = () => (
    <div
      id="app-wrapper"
      className={classNames({
        'mobile-view': this.props.store.themesStore.isMobile
      })}
    >
      <AppWrapperMobile />
      <ClosedModal show={this.props.store.restaurantStore.isShowClosedModal} />
    </div>
  );

  renderDefault = () => (
    <div
      id="app-wrapper"
      className={classNames({
        'mobile-view': this.props.store.themesStore.isMobile
      })}
    >
      <MediaQuery
        maxWidth={1023}
        values={{ width: this.props.store.themesStore.width }}
      >
        <AppWrapperMobile />
      </MediaQuery>
      <MediaQuery
        minWidth={1024}
        values={{ width: this.props.store.themesStore.width }}
      >
        <AppWrapperDesktop />
      </MediaQuery>
      <ClosedModal show={this.props.store.restaurantStore.isShowClosedModal} />
    </div>
  );

  /**
   * Render mobile/desktop layout
   */
  _renderData = () => {
    const { isHunger } = this.props.store.themesStore;

    return isHunger ? this.renderHunger() : this.renderDefault();
  };

  /**
   * Render shell layout
   */
  _renderShell = () => {
    const { restaurant } = this.props.store.restaurantStore;
    const { hasApps, branches } = restaurant;
    const isMigrated = branches?.some((b) => b.isMigrated);

    return (
      <div id="app-wrapper" className="app-wrapper-shell">
        <ShellRoute />
        <ClosedModal
          show={this.props.store.restaurantStore.isShowClosedModal}
          onlyHoliday
        />
        <MigratedStoreModal show={hasApps && isMigrated} />
      </div>
    );
  };

  render() {
    return this.props.store.themesStore.isShellThemeActive
      ? this._renderShell()
      : this._renderData();
  }
}
