import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import credentialsEnum from '../../../../../../enums/credentials.enum';

/**
 * Component to render restaurant info in legal page
 * @property {BranchModel} branch - current branch
 */
@inject('store')
@observer
class LegalRestaurantInfo extends Component {
  static propTypes = {
    branch: PropTypes.object.isRequired,
    restaurant: PropTypes.object,
    appsmartCredentials: PropTypes.object
  };

  /**
   * Method for render company info for imprint page
   * @param {BranchModel} branch - branch instance
   * @param {RestaurantModel} restaurant - restaurant instance
   * @returns {*}
   * @private
   */
  _renderCompanyInfo = (branch, restaurant) => {
    const branchName = branch.companyNameImprint
      ? branch.companyNameImprint
      : branch.branchName;
    const { analyticsStore } = this.props.store;

    return (
      <div className="imprint-company-info">
        <p className="branch-name">{branchName}</p>

        <p data-testid="imprint-address">
          {branch.getStreet || branch.getStreetNo ? (
            <>
              {branch.getStreet} {branch.getStreetNo}
              <br />
            </>
          ) : null}

          {branch.getCityCode || branch.getCity ? (
            <>
              {restaurant.getCountryPrefix
                ? `${restaurant.getCountryPrefix}–`
                : null}
              {branch.getCityCode} {branch.getCity}
            </>
          ) : null}
        </p>

        {branch.getWebsitePhone && !branch.getHideTel ? (
          <p>
            {this.props.t('address_form:phone')}:{' '}
            <a
              href={`tel:${branch.getWebsitePhone}`}
              data-testid="imprint-phone"
              onClick={() => analyticsStore.sendPhoneNumberLegalPage()}
            >
              {branch.getWebsitePhone}
            </a>
          </p>
        ) : null}

        {branch.getVatin ? (
          <p
            dangerouslySetInnerHTML={{
              __html: this.props.t('taxNumber', { tax: branch.getVatin })
            }}
          />
        ) : null}

        {branch.getRegistrationNumber ? (
          <p data-testid="imprint-reg-num">
            {this.props.t('registrationNumber', {
              number: branch.getRegistrationNumber
            })}
          </p>
        ) : null}
      </div>
    );
  };

  /**
   * Method for render company owner info for imprint page
   * @param {BranchModel} branch - branch instance
   * @returns {*}
   * @private
   */
  _renderOwnerInfo = (branch) => {
    const { showAppsmartEmailInImprint } = this.props.store.restaurantStore;

    const ownerEmail = showAppsmartEmailInImprint
      ? credentialsEnum.appsmart.restaurant.getOwneremail
      : branch.getOwneremail;

    const shopManagerEmail = showAppsmartEmailInImprint
      ? credentialsEnum.appsmart.restaurant.getOwneremail
      : branch.getShopmanagerEmail;

    return (
      <div className="imprint-owner-info">
        {branch.getOwnername ? (
          <p data-testid="imprint-owner">
            {this.props.t('owner', {
              owner: branch.getOwnername,
              interpolation: { escapeValue: false }
            })}
          </p>
        ) : null}

        {ownerEmail ? (
          <p data-testid="imprint-owner-mail">
            {this.props.t('address_form:emailAddress')}:&nbsp;
            <span className="email">
              <a href={`mailto:${ownerEmail}`}>{ownerEmail}</a>
            </span>
          </p>
        ) : null}

        {branch.getShopmanagerName &&
        branch.getShopmanagerName !== branch.getOwnername ? (
          <p data-testid="imprint-ceo">
            {this.props.t('managing', {
              name: branch.getShopmanagerName,
              interpolation: { escapeValue: false }
            })}
          </p>
        ) : null}

        {shopManagerEmail && ownerEmail !== shopManagerEmail ? (
          <p data-testid="imprint-ceo-mail">
            {this.props.t('address_form:emailAddress')}:&nbsp;
            <span className="email">
              <a href={`mailto:${shopManagerEmail}`}>{shopManagerEmail}</a>
            </span>
          </p>
        ) : null}
      </div>
    );
  };

  render() {
    const { branch, restaurant, appsmartCredentials } = this.props;

    return (
      <div className="legal-restaurant-info-container">
        {appsmartCredentials && (
          <>
            <div className="address-text-block">
              {this.props.t('common:thisIsCommonWebsite')}
            </div>
            <div className="address">
              {this._renderCompanyInfo(
                appsmartCredentials.branch,
                appsmartCredentials.restaurant
              )}
              {this._renderOwnerInfo(appsmartCredentials.branch)}
            </div>
            {restaurant && (
              <div className="address-text-block">
                {this.props.t('common:andWithoutArticle')}
              </div>
            )}
          </>
        )}
        {restaurant && (
          <div className="address">
            {this._renderCompanyInfo(branch, restaurant)}
            {this._renderOwnerInfo(branch)}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(['footer', 'common', 'address_form'])(
  LegalRestaurantInfo
);
