/* eslint-disable global-require */
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import MobileDetect from 'mobile-detect';
import { reaction } from 'mobx';
import { Provider } from 'mobx-react';
import React from 'react';
import cookies from 'react-cookies';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';

import {
  apiUrl,
  apiUrlVersion3,
  hungerRestaurantToken,
  sentryDSN,
  version
} from '../config';
import i18n from '../i18n';
import { matchRoute } from '../server/matchRoute';

import ScrollToTop from './components/scroll-to-top';
import * as COOKIES_TYPES from './enums/cookies.enum';
import DEVICES from './enums/device.enum';
import states from './enums/states.enum';
import { dayjsConfiguration } from './locales/dayjs.common';
import MainRoute from './main-route';
import { createStore } from './stores';
import { loadData, prepareDataInStores } from './utils/boot';
import {
  detectIOS,
  detectSafari,
  isBrowserSupported,
  mapQueryParamsToObject
} from './utils/functions';
import { getIsSlugSupported } from './utils/routing';

import './style.scss';

import './assets/fonts/fontawesome/scss/fontawesome.scss';
import './assets/fonts/fontawesome/scss/solid.scss';
import './assets/fonts/materialdesign/scss/materialdesignicons.scss';
import './assets/fonts/Roboto_Condensed/scss/robotocondensed.scss';

dayjs.extend(customParseFormat);

dayjs.extend(duration);

dayjs.extend(localeData);

dayjs.extend(weekday);

dayjs.extend(utc);

dayjs.extend(localizedFormat);

dayjs.extend(updateLocale);

@(withRouter as any)
class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      css: null,
      cssLoaded: false,
      dataLoaded: false,
      agent: new MobileDetect(navigator.userAgent),
      host: window.location.port
        ? `${window.location.hostname}:${window.location.port}`
        : window.location.hostname,
      location: this.props.location.pathname,
      store: createStore()
    };

    reaction(
      () => this.state.store.themesStore.templateLoaded,
      () => {
        // Load CSS if css not loaded or restaurant changed (for aggregator)
        if (
          this.state.store.themesStore.templateLoaded &&
          !this.state.store.themesStore.isTemplateAvailable &&
          !this.state.css &&
          !this.state.store.restaurantStore.isTemplatingPreviewModeActive
        ) {
          this._loadCss();
        }
      }
    );
  }

  UNSAFE_componentWillMount() {
    this.state.store.restaurantStore.setHostName(this.state.host);

    this.state.store.themesStore.setIsSafari(detectSafari(navigator.userAgent));

    this.state.store.themesStore.setIsIOS(detectIOS(navigator.platform));

    this.state.store.analyticsStore.init();

    if (this.state.agent.phone()) {
      this.state.store.themesStore.setDevice(DEVICES.MOBILE);
    } else {
      this.state.store.themesStore.setDevice(DEVICES.DESKTOP);
    }

    const params = mapQueryParamsToObject(this.props.history.location.search);

    if (params.platform) {
      cookies.save(COOKIES_TYPES.PLATFORM, params.platform, {
        path: '/'
      });

      this.state.store.themesStore.setCurrentThemeForPlatform(params.platform);
    } else {
      this.state.store.themesStore.setCurrentThemeForPlatform(
        cookies.load(COOKIES_TYPES.PLATFORM)
      );
    }

    const matchedRoute = matchRoute(this.state.location, this.state.host);

    this.state.store.restaurantStore.setSlug(matchedRoute.params.slug);

    this._loadData(matchedRoute);

    const languageCode = (window as any).initialLanguage;

    if (languageCode) {
      require(`dayjs/locale/${languageCode}.js`);

      dayjs.locale(languageCode);

      dayjs.updateLocale(languageCode, dayjsConfiguration());
    }
  }

  componentDidMount() {
    /**
     * Method to handle call refreshCssPreview from admin panel to reload template
     */
    window.refreshCssPreview = () => {
      const randomString = Math.random().toString(36).substring(2, 15);

      this.setState({ cssPreviewQuery: randomString });
    };

    document.addEventListener(
      'cssStylesLoaded',
      this.updateCSSLoadingStatus,
      false
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      'cssStylesLoaded',
      this.updateCSSLoadingStatus
    );
  }

  componentDidUpdate() {
    const isAggregator =
      this.state.store.aggregatorStore &&
      this.state.store.aggregatorStore.aggregator.isAggregator;

    if (isAggregator && this.state.css) {
      // Clear styles if aggregator page is opened
      this.setState({
        css: '',
        cssLoaded: false
      });
    }
  }

  // TODO: Refactor loading data
  /**
   * Load data for slug or domain from remote server
   * @param matchedRoute
   * @private
   */
  _loadData(matchedRoute) {
    this.state.store.setLoading(true);

    loadData(matchedRoute, this.state.store).then((data) => {
      const { themesStore } = this.state.store;
      const store = this.state.store.restaurantStore;
      const { browser } = this.state.store.themesStore;
      const params = mapQueryParamsToObject(this.props.history.location.search);

      prepareDataInStores(this.state.store, params);

      this.state.store.categoryMenuStore.setFirstCategoryActive();

      const languageCode = (window as any).initialLanguage;

      if (languageCode) {
        require(`dayjs/locale/${languageCode}.js`);

        dayjs.locale(languageCode);

        dayjs.updateLocale(languageCode, dayjsConfiguration());
      }

      this.state.store.themesStore
        .loadTemplate()
        .then(() => {
          if (this.state.store.themesStore.isTemplateAvailable) {
            this.setState({
              css: '',
              cssLoaded: false
            });
          } else {
            this._loadCss();
          }

          return Promise.resolve();
        })
        .catch((err) => {
          console.log('loadTemplate err');

          this._loadCss();

          Promise.reject(err);
        })
        .finally(() => {
          themesStore.setRootVars();
        });

      // Browser support
      if (!isBrowserSupported(this.state.agent, this.state.store)) {
        const prefix = getIsSlugSupported(store.hostName)
          ? `/${store.slug}`
          : '';

        const restaurantToken =
          this.state.store.themesStore.isHunger && hungerRestaurantToken
            ? hungerRestaurantToken
            : this.state.store.restaurantStore.restaurant.getToken;

        const url = `${prefix}/error?lang=${store.restaurant.getLang}&token=${restaurantToken}&browser=${browser}`;

        this.props.history.replace(url);
      }

      // Redirect to branch
      const branchByCode =
        store.restaurant.allAreaCodes &&
        store.restaurant.allAreaCodes.find((i) => i.zip === store.areaCode);

      const matchedBranchId = matchedRoute.params.branchId;

      if (
        branchByCode &&
        matchedBranchId &&
        matchedBranchId !== branchByCode.branchId
      ) {
        const prefix = getIsSlugSupported(store.hostName)
          ? `/${branchByCode.slug}`
          : '';

        const url = `${prefix}/${branchByCode.branchId}`;

        this.props.history.replace(url);
      }

      // Skip branch
      const isShellActive = this.state.store.themesStore.isShellThemeActive;

      if (matchedRoute.key === 'slug' && isShellActive) {
        const isOpenNews = this.state.store.newsStore.openNews;
        const isOpenBasket = this.state.store.basketStore.isDemoModeActive;

        const prefix = getIsSlugSupported(store.hostName)
          ? `/${matchedRoute.params.slug}`
          : '';

        const screenshotModeEnabled = !!params[
          states.queryParameters.screenshotMode
        ];

        let skip = isShellActive ? '?skippedBranch=true' : '';

        skip = screenshotModeEnabled
          ? `${
              skip
                ? `${skip}&${states.queryParameters.screenshotMode}=true`
                : `?${states.queryParameters.screenshotMode}=true`
            }`
          : skip;

        let url = `${prefix}/${store.restaurant.firstBranchId || ''}`;

        if (isOpenBasket) {
          // Open basket for taking screenshots
          url += `/${states.basket}${skip}`;
        } else if (isOpenNews) {
          // Open news for notifications
          url += `/${states.news}${skip}`;
        } else {
          url += skip;
        }

        this.props.history.replace(url);
      }

      if (data && data.maintenance) {
        const prefix = getIsSlugSupported(store.hostName)
          ? `/${matchedRoute.params.slug}`
          : '';

        const url = `${prefix}/maintenance-mode`;

        this.props.history.replace(url);
      } else if (data && !data.maintenance) {
        const prefix = getIsSlugSupported(store.hostName)
          ? `/${matchedRoute.params.slug}`
          : '';

        const url = `${prefix}/`;

        this.props.history.replace(url);
      }

      this.setState(
        {
          dataLoaded: true
        },
        this.state.store.setLoading(
          !this.state.dataLoaded && !this.state.cssLoaded
        )
      );
    });
  }

  /**
   * Load remote CSS style for this restaurant
   * @private
   */
  _loadCss = () => {
    const restaurantToken =
      this.state.store.themesStore.isHunger && hungerRestaurantToken
        ? hungerRestaurantToken
        : this.state.store.restaurantStore.restaurant.getToken;

    if (restaurantToken) {
      const {
        isTemplatingPreviewModeActive
      } = this.state.store.restaurantStore;

      const cssQuery = (this.state && this.state.cssPreviewQuery) || '';

      const styleLink = isTemplatingPreviewModeActive
        ? `${apiUrl}${apiUrlVersion3}/${restaurantToken}/get-css-preview?${cssQuery}`
        : `${apiUrl}${apiUrlVersion3}/${restaurantToken}/get-css`;

      this.setState({
        css: styleLink
      });
    }
  };

  onLoadCSS = () => {
    const event = new Event('cssStylesLoaded');

    document.dispatchEvent(event);
  };

  onErrorLoadingCSS = () => {
    const event = new Event('cssStylesLoaded');

    document.dispatchEvent(event);

    return null;
  };

  updateCSSLoadingStatus = () => {
    !this.state.cssLoaded &&
      this.setState(
        {
          cssLoaded: true
        },
        () => {
          this.state.store.setLoading(
            !this.state.dataLoaded && !this.state.cssLoaded
          );
        }
      );
  };

  render() {
    /*
      Because of React-helmet doesn't support onLoad/onError events in his children
      we need to use IIFE in link tag with custom DOM events to get info about loading status
    */
    const {
      templateLoaded,
      isTemplateAvailable
    } = this.state.store.themesStore;

    return (
      <Provider store={this.state.store}>
        <I18nextProvider
          i18n={i18n}
          initialI18nStore={window.initialI18nStore}
          initialLanguage={window.initialLanguage}
        >
          <ScrollToTop>
            <Helmet>
              {this.state.css && templateLoaded && !isTemplateAvailable ? (
                <link
                  rel="stylesheet"
                  type="text/css"
                  href={this.state.css}
                  onLoad={`(${this.onLoadCSS.toString()})()`}
                  onError={`(${this.onErrorLoadingCSS.toString()})()`}
                />
              ) : (
                this.onErrorLoadingCSS()
              )}
              <style type="text/css">
                {this.state.store.themesStore.commonCss()}
              </style>
            </Helmet>

            <MainRoute />
          </ScrollToTop>
        </I18nextProvider>
      </Provider>
    );
  }
}

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept();
}
