import { action, observable } from 'mobx';

import RootStore from 'client/stores';

/**
 * Feedback store class. Used to work with feedback in store.
 * @constructor
 * @param {instance} api - {@link API} instance.
 * @param {instance} storage - {@link storage} instance.
 */
export default class FeedbackStore {
  api: any;

  root: RootStore;

  constructor(root, state, api) {
    Object.assign(this, state);

    this.api = api;

    this.root = root;
  }

  @observable email = '';

  @observable message = '';

  @observable error = null;

  @observable success = false;

  @observable loading = false;

  /**
   * Method to send feedback
   * @memberof FeedbackStore#
   * @method sendFeedback
   */
  @action sendFeedback() {
    if (!this.loading) {
      this.loading = true;

      this.error = null;

      return this.api
        .sendFeedback(this.email, this.message)
        .then((data) => {
          this.email = '';

          this.message = '';

          this.success = data.success || false;

          this.loading = false;

          return data;
        })
        .catch((err) => {
          this.error = err.message;

          this.success = false;

          this.loading = false;

          throw err;
        });
    }

    console.info("You can't send feedback again");
  }

  @action clear() {
    this.error = null;

    this.success = false;

    this.loading = false;
  }
}
