import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import './newsCell.scss';

/**
 * Component to render one news
 */
export default class NewsCell extends PureComponent {
  static propTypes = {
    newsItem: PropTypes.object.isRequired,
    utcOffset: PropTypes.number
  };

  /**
   * Method to render news date
   * @param {NewsModel} newsItem - news object
   * @returns {null}
   */
  _renderDate(newsItem) {
    let time = dayjs.unix(newsItem.date);

    if (this.props.utcOffset) {
      // Use "subtract" because backend sends the time which has already considered german utc offset
      time = time.clone().subtract(this.props.utcOffset, 'minutes');
    }

    return newsItem.date ? (
      <div className="date" data-testid="news-date">
        {time.format('HH:mm DD-MM-YYYY')}
      </div>
    ) : null;
  }

  /**
   * Method to render news title
   * @param {NewsModel} newsItem - news object
   * @returns {null}
   */
  _renderTitle(newsItem) {
    return newsItem.title ? (
      <div className="title" data-testid="news-title">
        {newsItem.title}
      </div>
    ) : null;
  }

  /**
   * Method to render news image
   * @param {NewsModel} newsItem - news object
   * @returns {null}
   */
  _renderImage(newsItem) {
    return newsItem.image ? (
      <div className="image-wrapper">
        <img src={newsItem.image} data-testid="news-image" />
      </div>
    ) : null;
  }

  /**
   * Method to render news content
   * @param {NewsModel} newsItem - news object
   * @returns {null}
   */
  _renderContent(newsItem) {
    return newsItem.content ? (
      <div className="content" data-testid="news-text">
        {newsItem.content}
      </div>
    ) : null;
  }

  /**
   * Method to render news url
   * @param {NewsModel} newsItem - news object
   * @returns {null}
   */
  _renderURL(newsItem) {
    return newsItem.url ? <a href={newsItem.url}> {newsItem.url} </a> : null;
  }

  render() {
    const { newsItem } = this.props;

    return (
      <figure className="news-cell" data-testid="news-full-card">
        <header>
          {this._renderTitle(newsItem)}
          {this._renderDate(newsItem)}
        </header>
        <main>
          {this._renderImage(newsItem)}
          {this._renderContent(newsItem)}
        </main>
        {this._renderURL(newsItem)}
      </figure>
    );
  }
}
