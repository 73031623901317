import React from 'react';

import './_aggregatorStartPage.scss';
import ButtonOrder from '../buttons/ButtonOrder';
import { inject } from 'mobx-react';
import { generateLinkFor } from '../../../../utils/routing';

@inject('store')
export default class AggregatorShopStartPage extends React.Component {
  componentDidMount() {
    this.props.store.restaurantStore.setRestaurant({});
    this.props.store.themesStore.setDesignId(undefined);
  }

  _renderAggregatorLogoWrapper(logo, description) {
    return (
      <div className="logo-wrapper">
        <img src={logo} alt="background image" />
        <p>{description}</p>
      </div>
    );
  }

  _renderShopButtons(buttons) {
    return (
      <div className="buttons-wrapper">
        {buttons.map((button) => {
          const { slug } = button.restaurants;
          return (
            <ButtonOrder
              style={{
                backgroundColor: `${button.color1}`,
                color: button.color2 || '#ffffff'
              }}
              key={button.restaurants.id}
              label={button.title}
              onClick={this._handleShopButtonClick.bind(this, slug)}
            />
          );
        })}
      </div>
    );
  }

  _handleShopButtonClick(slug) {
    if (!slug) return;

    this.props.store.aggregatorStore.setAggregatorMode(false);
    const link = generateLinkFor('', {
      ...this.props,
      match: {
        ...this.props.match,
        params: {
          slug
        }
      }
    });
    this.props.history.push(link);
  }

  render() {
    const { aggregator } = this.props.store.aggregatorStore || {};

    return (
      <div id="aggregator-container">
        {this._renderAggregatorLogoWrapper(
          aggregator.logo,
          aggregator.description
        )}
        {this._renderShopButtons(aggregator.buttons || [])}
      </div>
    );
  }
}
