const storageKeys = {
  LANGUAGE: 'LANGUAGE',
  ADDRESS: 'ADDRESS',
  ORDER_TYPE: 'ORDER_TYPE',
  ORDER_ID: 'ORDER_ID',
  BRANCH_ID: 'BRANCH_ID',
  BASKET: 'BASKET',
  BASKET_LAST_UPDATED: 'BASKET_LAST_UPDATED',
  PAYMENT_METHODS: 'PAYMENT_METHODS',
  ACTIVE_CATEGORY: 'ACTIVE_CATEGORY ',
  LAST_UPDATED_ADDRESS: 'LAST_UPDATED_ADDRESS',
  LAST_UPDATED_MAINTENANCE: 'LAST_UPDATED_MAINTENANCE',
  AREA_CODE: 'AREA_CODE',
  COUPON_LAST_UPDATED: 'COUPON_LAST_UPDATED',
  COUPON: 'COUPON',
  MARKETING_CHECKBOX: 'MARKETING_CHECKBOX',
  THEME: 'THEME',
  BUILD_V: 'BUILD_VERSION',
  LAST_ORDER: 'LAST_ORDER',
  AGGREGATOR: 'AGGREGATOR',
  AGGREGATOR_SLUG: 'AGGREGATOR_SLUG',
  DELIVERY_PLACE_DATA: 'DELIVERY_PLACE_DATA'
};

export default storageKeys;
