import React, { memo } from 'react';
import Swiper, { ReactIdSwiperProps } from 'react-id-swiper';

import 'swiper/swiper.scss';

const SwiperSlider = (props: ReactIdSwiperProps) => {
  const { children } = props;

  return <Swiper {...props}>{children}</Swiper>;
};

export default memo(SwiperSlider);
