import classNames from 'classnames';
import React, { useContext } from 'react';

import { RootContext } from '../../stores';

import './PickUpAddressInfo.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

const PickUpAddressInfo = observer(() => {
  const { restaurantStore, themesStore } = useContext(RootContext);
  const { t } = useTranslation(['pickup_address_info']);
  const { branch, restaurant } = restaurantStore;

  return (
    <div
      className={classNames('pickup-address-info__container', {
        '--mobile': themesStore.isMobile
      })}
    >
      <div className="pickup-address-info__row">
        <div className="pickup-address-info__col">
          <div className="pickup-address-info__location">
            {t('pickup_address_info:pickUpAddressInfoTitle')}
          </div>
        </div>
        <div className="pickup-address-info__col">
          <div className="pickup-address-info__address">
            {branch.getStreet || branch.getStreetNo ? (
              <>
                {branch.getStreet} {branch.getStreetNo}
                <br />
              </>
            ) : null}
            {branch.getCityCode || branch.getCity ? (
              <>
                {restaurant.getCountryPrefix
                  ? `${restaurant.getCountryPrefix}–`
                  : null}
                {branch.getCityCode} {branch.getCity}
              </>
            ) : null}
          </div>
          {!themesStore.isMobile && (
            <div className="pickup-address-info__url">
              <a
                href={`https://www.google.com/maps/dir/current+location/${branch.lat},${branch.long}`}
                target="_blank"
                rel="noreferrer"
              >
                {t('pickup_address_info:pickUpAddressInfoGoogleMaps')}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default PickUpAddressInfo;
