import { createBrowserHistory, createMemoryHistory } from 'history';

import { MobileModalsRouteType } from 'client/routes/MobileModalsRoute/MobileModalsRoute.type';
import { ModalsRouteType } from 'client/routes/ModalsRoute/ModalsRoute.type';
import { ShellModalsRouteType } from 'client/routes/ShellModalsRoute/ShellModalsRoute.type';
import { IS_CLIENT } from 'config';

import { getUtmParamsFromSearch } from '../helpers';

type Modal =
  | `${ModalsRouteType}`
  | `${MobileModalsRouteType}`
  | `${ShellModalsRouteType}`;

const openModal = (modal: Modal, params?: Record<string, string>) => {
  const history = IS_CLIENT ? createBrowserHistory() : createMemoryHistory();
  const { pathname, search } = history.location;
  const utmParams = getUtmParamsFromSearch(search);
  const newSearch = new URLSearchParams({ modal, ...params, ...utmParams });

  return {
    search: newSearch.toString(),
    pathname
  };
};

export default openModal;
