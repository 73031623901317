import classnames from 'classnames';
import React, { memo, useCallback, useState } from 'react';

import Dropdown from 'client/ui/Dropdown';
import TextField from 'client/ui/TextField';

import css from './Autocomplete.module.scss';
import { AutocompleteProps } from './Autocomplete.type';

const Autocomplete = <T,>({
  autocompleteRef,
  className,
  defaultValue,
  disabled,
  dropdownFooter,
  error,
  getOptionLabel,
  getActiveOption,
  noOptionLabel,
  inputRef,
  label,
  name,
  onBlur,
  onChange,
  onClick,
  onFocus,
  options,
  pattern,
  placeholder,
  prefix,
  readOnly,
  suffix,
  value,
  dataTestId
}: AutocompleteProps<T>) => {
  const [isActive, setIsActive] = useState(false);
  const forbidKeys = ['ArrowUp'];

  const handleFocus = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (event) => {
      setIsActive(true);

      onFocus?.(event);
    },
    [onFocus]
  );

  const handleBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (event) => {
      setIsActive(false);

      onBlur?.(event);
    },
    [onBlur]
  );

  const valueByDefault = defaultValue
    ? getOptionLabel(defaultValue)
    : undefined;

  return (
    <div
      className={classnames(css.Autocomplete, className)}
      ref={autocompleteRef}
    >
      <TextField
        autoComplete="chrome-off"
        defaultValue={valueByDefault}
        disabled={disabled}
        error={error}
        forbidServiceKeys={forbidKeys}
        inputRef={inputRef}
        label={label}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={onChange}
        placeholder={placeholder}
        pattern={pattern}
        prefix={prefix}
        readOnly={readOnly}
        suffix={suffix}
        type="text"
        value={value}
        errorClassName={css.error}
        dataTestId={dataTestId}
      />
      {isActive && (
        <Dropdown
          className={css.dropdown}
          options={options}
          getOptionLabel={getOptionLabel}
          getActiveOption={getActiveOption}
          onClick={onClick}
          noOptionLabel={noOptionLabel}
          dropdownFooter={dropdownFooter}
        />
      )}
    </div>
  );
};

export { Autocomplete };

export default memo(Autocomplete) as typeof Autocomplete;
