import loadable from '@loadable/component';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Modals, ModalsRouteType } from './ModalsRoute.type';

const AddressModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/AddressModal'
    )
);

const AllergensModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/AllergensModal'
    )
);

const CertificateModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/CertificateModal'
    )
);

const DeleteConfirmationModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/DeleteConfirmationModal'
    )
);

const ImprintModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/ImprintModal'
    )
);

const ContactFormModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/ContactFormModal'
    )
);

const OfferModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/OfferModal'
    )
);

const ProductModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/ProductModal'
    )
);

const PrivacyModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/PrivacyModal'
    )
);

const ProductExtrasModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/ProductExtrasModal'
    )
);

const TableReservation = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/TableReservationFormModal'
    )
);

const TermsModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/TermsModal'
    )
);

const ChangeAddressModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/ChangeAddressModal'
    )
);

const CookieModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/CookieModal'
    )
);

const modals: Modals = {
  addressModal: AddressModal,
  allergensModal: AllergensModal,
  certificateModal: CertificateModal,
  contactModal: ContactFormModal,
  deleteConfirm: DeleteConfirmationModal,
  extrasModal: ProductExtrasModal,
  imprintModal: ImprintModal,
  privacyModal: PrivacyModal,
  productModal: ProductModal,
  offerModal: OfferModal,
  termsModal: TermsModal,
  changeAddressModal: ChangeAddressModal,
  tableReservation: TableReservation,
  cookieModal: CookieModal
};

const ModalsRoute = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const props: Record<string, any> = {};

  if (!params.has('modal')) {
    return null;
  }

  const modalType = params.get('modal') as ModalsRouteType;
  const Modal = modals[modalType];

  for (const [key, value] of params.entries()) {
    props[key] = value;
  }

  if (!Modal) {
    return null;
  }

  return <Modal {...props} />;
};

export default React.memo(ModalsRoute);
