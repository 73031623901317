import { action, observable } from 'mobx';

import { fetchBranchIdByCoords } from 'client/api/queries';
import googleService from 'client/services/googleService/googleService';
import { GeocoderAddressComponent } from 'client/services/googleService/googleService.type';
import RootStore from 'client/stores';
import { DeliveryAvailabilityCallback } from 'client/stores/deliveryAddress';
import { GeoInfo, PlacePrediction } from 'client/stores/thirdPartyService';
import { googlePlacesSupportedCountryCodes } from 'config';

class ThirdPartyServicesStore {
  root: RootStore;

  constructor(root: RootStore) {
    this.root = root;
  }

  @observable googlePlacesIsReady = false;

  @observable isFetchingPlaces = false;

  @observable placesPredictions: PlacePrediction[] = [];

  @observable noPlacesResultsFound = false;

  /**
   * Method to notification app about readiness of Google Places API
   */
  @action
  setGooglePlacesStatusToReady() {
    this.googlePlacesIsReady = true;
  }

  /**
   * Method to set fetching places status
   * @param {boolean} state - new state of fetching
   */
  @action
  setFetchingPlaces(state: boolean) {
    this.isFetchingPlaces = state;
  }

  /**
   * Method to clear places predictions
   */
  @action
  clearPlacesPredictions() {
    this.placesPredictions = [];

    this.noPlacesResultsFound = false;
  }

  /**
   * Method for fetching places by postal code
   * @param {string} value
   */
  @action
  getPlacesByPostalCode(value: string) {
    if (value) {
      const {
        deliveryAddressStore: {
          address: { city }
        },
        restaurantStore: { sublocality, areaCode }
      } = this.root;

      const postalCode = areaCode || '';
      const subLocality = city || sublocality || '';
      const input = `${postalCode} ${subLocality} ${value}`;

      this.getPlacesByText(input);
    }
  }

  /**
   * Method for fetching places by text
   */
  @action
  async getPlacesByText(
    value: string,
    country: string | string[] = googlePlacesSupportedCountryCodes
  ) {
    if (value) {
      this.setFetchingPlaces(true);

      try {
        const response = await googleService.getPlacesPredictions(
          value,
          country
        );

        const results =
          response &&
          response.map((prediction) => {
            const { types, place_id, description } = prediction;
            const [street, city] = description.split(', ');
            const value = `${city}, ${street}`;
            const isHouseAddress = googleService.verifyAddressType(types);
            const placeId = place_id;

            return {
              ...prediction,
              isHouseAddress,
              value,
              placeId
            };
          });

        // TODO: Check if results always exist. Optional chaining can be useless here
        if (!results?.length) {
          this.noPlacesResultsFound = true;
        }

        this.placesPredictions = results || [];
      } catch (e) {
        console.error('Error while fetching google autocomplete');

        console.error(e);

        this.placesPredictions = [];

        this.noPlacesResultsFound = true;
      } finally {
        this.setFetchingPlaces(false);
      }
    }
  }

  /**
   * Method to get geo information with coordinates by place id
   * @param value
   */
  @action
  async getGeoInfo(value: PlacePrediction): GeoInfo {
    const { placeId } = value;

    this.root.deliveryAddressStore.setCheckingThePlace(true);

    try {
      const [response] = await googleService.getPlaceInfo(placeId);

      if (!response) {
        return;
      }

      const { address_components, geometry } = response;
      const city = this.recognizeCity(address_components);

      const subLocality = address_components.find(({ types }) =>
        types.includes('sublocality')
      );

      const street = address_components.find(({ types }) =>
        types.includes('route')
      );

      const streetNo = address_components.find(({ types }) =>
        types.includes('street_number')
      );

      const postalCode = address_components.find(({ types }) =>
        types.includes('postal_code')
      );

      const lat = geometry.location.lat();
      const lng = geometry.location.lng();
      const isValid = !!city && !!street && !!streetNo && !!postalCode;

      if (isValid) {
        return {
          street,
          streetNo,
          city,
          subLocality,
          postalCode,
          lat,
          lng,
          addressId: placeId,
          address_components
        };
      }
    } catch (e) {
      console.error('Failed to fetch place info from Google');

      console.dir(e);
    }
  }

  @action
  async applyAddressFromQuery(geoInfo: GeoInfo, branchId: string) {
    await this.root.deliveryAddressStore.getDeliveryAvailability(
      geoInfo,
      branchId
    );

    const { city, street, streetNo, postalCode, addressId } = geoInfo;

    this.root.deliveryAddressStore.updateAddress({
      zip: postalCode,
      street,
      street_no: streetNo,
      city,
      address_id: addressId
    });

    this.root.deliveryAddressStore.saveAddressAndOrderType(branchId);
  }

  @action
  async getBranchForGpsRedirect(value: PlacePrediction) {
    try {
      const { slug } = this.root.restaurantStore;
      const { getBranches } = this.root.restaurantStore?.restaurant || {};
      const geoInfo = await this.getGeoInfo(value);

      if (!geoInfo) {
        throw new Error('geoInfo is not defined');
      }

      const { lat, lng } = geoInfo;
      const calculatedSlug = slug || getBranches?.[0].slug || '';
      const { branch } = await fetchBranchIdByCoords(calculatedSlug, lat, lng);
      const branchId = branch?.toString();

      if (!branchId) {
        throw new Error('Address is outside the delivery area');
      }

      this.root.deliveryAddressStore.saveAddress(geoInfo);

      this.root.deliveryAddressStore.getDeliveryAvailability(geoInfo, branchId);

      const {
        city,
        street,
        streetNo,
        postalCode,
        addressId,
        subLocality
      } = geoInfo;

      this.root.deliveryAddressStore.updateAddress({
        zip: postalCode.long_name,
        street: street.long_name,
        street_no: streetNo.long_name,
        city: subLocality
          ? `${subLocality.long_name}, ${city.long_name}`
          : city.long_name,
        address_id: addressId
      });

      this.root.deliveryAddressStore.saveAddressAndOrderType(branchId);

      geoInfo &&
        this.root.deliveryAddressStore.savePlaceData(branchId, geoInfo);

      return this.root.restaurantStore.getBranchById(branchId);
    } catch (e) {
      console.error(e);
    } finally {
      this.root.deliveryAddressStore.setCheckingThePlace(false);
    }
  }

  @action
  async checkDeliveryAvailabilityByBranch(
    value: PlacePrediction,
    saveResults?: boolean,
    callback?: DeliveryAvailabilityCallback
  ) {
    try {
      const geoData = await this.getGeoInfo(value);
      const { id } = this.root.restaurantStore.branch;

      if (!geoData || !id) {
        return;
      }

      this.root.deliveryAddressStore.getDeliveryAvailability(
        geoData,
        id,
        saveResults,
        callback
      );
    } catch (e) {}
  }

  recognizeCity = (addressComponents: GeocoderAddressComponent[]) => {
    const sortList = {
      locality: 0,
      sublocality: 1,
      administrative_area_level_1: 2,
      administrative_area_level_2: 3,
      administrative_area_level_3: 4,
      administrative_area_level_4: 5,
      administrative_area_level_5: 6
    };

    const regex = new RegExp(Object.keys(sortList).join('|'));

    const cityPredictions =
      addressComponents
        ?.filter((item) => item.types.some((type) => regex.test(type)))
        .sort(
          (firstPrediction, secondPrediction) =>
            sortList[
              firstPrediction.types.find((type) =>
                // eslint-disable-next-line no-prototype-builtins
                sortList.hasOwnProperty(type)
              )
            ] -
            sortList[
              secondPrediction.types.find((type) =>
                // eslint-disable-next-line no-prototype-builtins
                sortList.hasOwnProperty(type)
              )
            ]
        ) || [];

    return cityPredictions[0];
  };
}

export default ThirdPartyServicesStore;
