import { inject, observer } from 'mobx-react';
import React, { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import openModal from 'client/utils/openModal';
import { IS_CLIENT } from 'config';

import { SearchModalMobile } from '../../../../../components/Search';
import states from '../../../../../enums/states.enum';
import { ShellModalsRouteType } from '../../../../../routes/ShellModalsRoute/ShellModalsRoute.type';
import { filterPrice } from '../../../../../utils/functions';
import { goBack } from '../../../../../utils/native-apps-communication';
import { generateLinkFor } from '../../../../../utils/routing';
import DiscountPercentageMobile from '../../../components/discount_block';
import { ProductsShellWrapper } from '../../../components/products_list/shell';
import FooterShellButton from '../buttons-shell/footer-button';
import CategoryScrollableList from '../category-scrollable-list/category-scrollable-list';
import DeliveryAreaSwitch from '../delivery-area-switch';
import HeaderShell from '../header-shell';
import ShellLayout from '../shell-layout';

import CategoryListAltRow from './category-list-row';
import './styles.scss';

/**
 * Component for rendering shell categories list
 */
@inject('store')
@withRouter
@observer
class CategoryListAlt extends Component {
  constructor(props) {
    super(props);

    const { slug } = this.props.store.aggregatorStore;
    const { templateLoaded } = this.props.store.themesStore;

    if (slug && !templateLoaded) {
      this.props.store.themesStore.loadTemplate();
    }
  }

  containerRef = createRef();

  componentDidMount() {
    this.props.store.themesStore.setShellTitle(
      this.props.t('common:lblMenu'),
      this._backAction
    );
  }

  /**
   * Action to click back button
   */
  _backAction = () => {
    if (!this.props.location.prevPath) {
      const {
        customPageUrl,
        showBackButtonToAggregatorCustomPage
      } = this.props.store.aggregatorStore;

      if (IS_CLIENT && showBackButtonToAggregatorCustomPage) {
        window.location.href = customPageUrl;
      } else if (this.props.store.aggregatorStore.slug) {
        this.props.store.aggregatorStore.setAggregatorMode(true);

        this.props.history.goBack();
      } else if (this.props.store.themesStore.isPortalAppThemeActive) {
        goBack();
      } else {
        this.props.history.push(
          generateLinkFor(states.app, this.props, {}, true)
        );
      }
    } else {
      this.props.history.push(
        generateLinkFor(states.app, this.props, {}, true)
      );
    }
  };

  /**
   * Method to handle change order type button action
   * @param {string} type
   * @private
   */
  _changeOrderType = (type) => {
    this.props.store.categoryMenuStore.setContainerScrollPosition();

    this.props.store.deliveryAddressStore.changeOrderType(type);
  };

  /**
   * Method to handle area button action
   * @private
   */
  _areaButtonAction = (e) => {
    if (this.props.store.themesStore.isPortalAppThemeActive) {
      return;
    }

    const addressModalUrl = openModal(ShellModalsRouteType.ADDRESS_MODAL);

    this.props.history.push(addressModalUrl);
  };

  /**
   * Method to render single category
   * @returns {*}
   * @private
   */
  _renderCategory = (category) => {
    const categoryRowStyle = this.props.store.themesStore.categoryRowStyle();

    return (
      <CategoryListAltRow
        key={category.id}
        category={category}
        style={categoryRowStyle}
      />
    );
  };

  _renderListHeader = () => {
    const {
      getShellCategoriesBackgroundLink
    } = this.props.store.restaurantStore.restaurant;

    const { shellTemplateVarCategoryBackground } = this.props.store.themesStore;

    return (
      <div className="category-list-header">
        {shellTemplateVarCategoryBackground ||
        getShellCategoriesBackgroundLink ? (
          <img
            src={
              shellTemplateVarCategoryBackground ||
              getShellCategoriesBackgroundLink
            }
            alt="background image"
          />
        ) : null}
        <div
          className="category-list-title"
          style={this.props.store.themesStore.categoryNameStyle()}
          data-testid="cat-list-title"
        >
          {this.props.t('common:lblMenu')}
        </div>
      </div>
    );
  };

  /**
   * Method to render category list
   * @returns {*}
   * @private
   */
  _renderContent = () => {
    const {
      productsStore,
      deliveryAddressStore,
      restaurantStore,
      languagesStore,
      themesStore,
      categoryMenuStore
    } = this.props.store;

    const { categories } = categoryMenuStore;

    const {
      isDelivery,
      hasDelivery,
      hasPickup,
      address,
      fullStreetAddressExists,
      addressPostalCode
    } = deliveryAddressStore;

    const { useAdvancedDeliveryCalculationType } = restaurantStore;
    const { activeLanguage } = languagesStore;
    const { isShellCategoryHorizontalType, discountStyle } = themesStore;
    const { discountPercentage } = this.props.store.basketStore;

    const style = this.props.store.themesStore.isTemplateAvailable
      ? `
    .category-row-alt::after {
      background: ${
        this.props.store.themesStore.separatorStyle().borderBottomColor
      }
    }
  `
      : '';

    const showDiscountBlock = discountPercentage !== 0;

    const renderCategories = () => (
      <>
        {this._renderListHeader()}
        <style>{style}</style>
        <div
          className="category-list-content"
          style={this.props.store.themesStore.categoriesListStyle()}
        >
          {categories
            .filter((category) => category.id !== -2)
            .map((category) => this._renderCategory(category))}
        </div>
      </>
    );

    const renderSearchResults = () => <ProductsShellWrapper />;

    return (
      <>
        <div className="category-list-layout" ref={this.containerRef}>
          {!productsStore.isSearchEnabled && (
            <DeliveryAreaSwitch
              langKey={activeLanguage?.id}
              areaCode={addressPostalCode}
              currentAddress={
                fullStreetAddressExists ? address.city : undefined
              }
              isAddressNeeded={useAdvancedDeliveryCalculationType}
              isDelivery={isDelivery}
              changeOrderTypeAction={this._changeOrderType}
              areaButtonAction={this._areaButtonAction}
              isDeliveryEnabled={hasDelivery}
              isPickupEnabled={hasPickup}
              deliverySwitchStyle={this.props.store.themesStore.deliverySwitchStyle()}
              postalCodeStyle={this.props.store.themesStore.postalCodeStyle()}
            />
          )}

          {isShellCategoryHorizontalType && !productsStore.isSearchEnabled ? (
            <>
              {showDiscountBlock && (
                <DiscountPercentageMobile
                  discount={discountPercentage}
                  isShell
                  style={discountStyle()}
                />
              )}
              <CategoryScrollableList
                parentContainerNode={
                  this.containerRef.current
                    ? this.containerRef.current.parentNode
                    : null
                }
                showDiscountBlock={showDiscountBlock}
              />
            </>
          ) : (
            <div className="category-list-alt">
              {productsStore.isSearchEnabled
                ? renderSearchResults()
                : renderCategories()}
            </div>
          )}
        </div>
      </>
    );
  };

  _renderHeader = () => {
    const { shellBackButtonAction, shellTitle } = this.props.store.themesStore;

    return (
      <HeaderShell
        title={shellTitle}
        backAction={shellBackButtonAction}
        style={this.props.store.themesStore.headerStyle()}
        isPortalAppThemeActive={
          this.props.store.themesStore.isPortalAppThemeActive
        }
        branchName={this.props.store.restaurantStore.branch.branchName}
      />
    );
  };

  _renderContentFooter = () => {
    const { hasProducts } = this.props.store.basketStore;

    const label = hasProducts
      ? this.props.store.basketStore.getIntlPrice(
          this.props.store.basketStore.finalPriceWithOffers
        )
      : this.props.store.basketStore.getIntlPrice(filterPrice(0));

    const {
      hideBasketButtonHorizontalMenuShell
    } = this.props.store.themesStore;

    const footerElement = (
      <div className="footer-buttons-wrapper">
        <FooterShellButton
          label={label}
          link={generateLinkFor(states.basket, this.props, {}, true)}
          isCartButton
          animated={!!this.props.store.productsStore.animateId}
          buttonOrderStyle={this.props.store.themesStore.orderButtonStyle()}
        />
      </div>
    );

    return <div className="content-footer-wrapper">{footerElement}</div>;
  };

  render() {
    return (
      <ShellLayout
        shellHeader={this._renderHeader()}
        shellContent={this._renderContent()}
        contentFooter={this._renderContentFooter()}
      />
    );
  }
}

export default withTranslation(['common'])(CategoryListAlt);
