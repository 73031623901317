import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component, MouseEvent } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import Text from 'client/ui/Text';
import openModal from 'client/utils/openModal';
import { cantAddProductsToBasket } from 'client/utils/routing';
import { IS_CLIENT } from 'config';

import PICTURE_MODES from '../../../../../enums/product_images.enum';
import states from '../../../../../enums/states.enum';
import { ProductModel } from '../../../../../models/product.model';
import ProductListCategoryHeader from '../common/header/ProductListCategoryHeader';
import ProductEmptyList from '../common/product-empty-list';
import ProductList from '../common/products-list/ProductList';

import {
  IProductDesktopContainerProps,
  OpenProductModal
} from './props.interface';
import './ProductDesktopContainer.scss';

@(withRouter as any)
@inject('store')
@observer
class ProductDesktopContainer extends Component<IProductDesktopContainerProps> {
  componentDidMount() {
    if (!this.props.store.productsStore.products.length) {
      this.props.store.categoryMenuStore.activeCategory?.setLoaded(false);

      this.props.store.categoryMenuStore.setActiveCategory(
        this.props.store.categoryMenuStore.activeCategory?.id
      );
    }
  }

  /**
   * Method to open modal with advanced product view
   */
  openProductModal: OpenProductModal = (event, product) => {
    event.stopPropagation();

    const { history } = this.props;

    const productUrl = openModal('productModal', {
      productId: `${product.id}`
    });

    history.push(productUrl);
  };

  /**
   * Method to add product in the basket
   */
  addProductToBasket = (event: MouseEvent, product: ProductModel) => {
    event.stopPropagation();

    const { history } = this.props;

    if (!product.soldOut) {
      this.props.store.productsStore.addProduct(product, history);

      this.props.store.basketStore.setLastProductClicked(product.id);
    }
  };

  /**
   * Open modal for showing halal certificate
   */
  showHalalCertificate = (event: MouseEvent) => {
    event.stopPropagation();

    const certificateUrl = openModal('certificateModal');

    this.props.history.push(certificateUrl);
  };

  /**
   * For rendering component showing search results
   */
  renderSearchResults() {
    const {
      searchingRequest,
      searchProducts,
      animateId,
      isSearching
    } = this.props.store.productsStore;

    const {
      branch,
      restaurant,
      isShopClosed
    } = this.props.store.restaurantStore;

    const { id } = this.props.store.languagesStore.activeLanguage;
    const badgeStyle = this.props.store.themesStore.productBadgeStyle();

    const basketBounds = this.props.store.basketStore.bounds[states.product][
      this.props.store.basketStore.uniqStringLastAddedProduct
    ];

    const { currencyCode } = this.props.store.restaurantStore.branch;

    const title = this.props.t('product_view:searchResultsFor', {
      request: searchingRequest
    });

    const basketProducts: {
      [key: string]: number;
    } = {};

    this.props.store.basketStore.products.forEach(
      (productItem: { count: number; product: ProductModel }) => {
        if (!basketProducts[productItem.product.id]) {
          basketProducts[productItem.product.id] = productItem.count;
        } else {
          basketProducts[productItem.product.id] += productItem.count;
        }
      },
      {}
    );

    const pictureMode = branch.showNoPicture
      ? PICTURE_MODES.NO_PICTURES
      : PICTURE_MODES.SMALL_PRODUCT_PICTURES;

    const clearSearch = () => {
      const { productsStore } = this.props.store;

      productsStore.clearSearchingRequest();
    };

    return (
      <>
        <ProductListCategoryHeader title={title} bottomSpace />
        {searchProducts.length ? (
          <ProductList
            isShopClosed={isShopClosed}
            products={searchProducts}
            basketProducts={basketProducts}
            picturesMode={pictureMode}
            onButtonClick={this.addProductToBasket}
            onCellClick={
              branch.isUseAdvancedProductView
                ? this.openProductModal
                : this.addProductToBasket
            }
            openProductModal={this.openProductModal}
            showHalalCertificate={this.showHalalCertificate}
            activeLanguage={id}
            hideProductArticles={branch.isHideArticleNumbers}
            isUseAdvancedProductView={branch.isUseAdvancedProductView}
            styles={{
              newBadgeStyle: badgeStyle
            }}
            animatedId={animateId}
            basketBounds={basketBounds}
            loadingAnimation={restaurant.loadingAnimation.url}
            currency={currencyCode}
          />
        ) : (
          <ProductEmptyList
            message={this.props.t('product_view:noSearchResults')}
            buttonLabel={this.props.t('not_found:resetSearch')}
            onButtonClick={clearSearch}
            loadingAnimation={
              isSearching ? restaurant.loadingAnimation.url : null
            }
          />
        )}
      </>
    );
  }

  /**
   * For rendering product list
   */
  renderProductList() {
    const {
      additivesStore,
      basketStore,
      categoryMenuStore,
      productsStore,
      restaurantStore,
      themesStore,
      languagesStore
    } = this.props.store;

    const {
      activeSubcategory,
      activeCategory,
      hasSubcategories
    } = categoryMenuStore;

    const { sortedProductsList, animateId } = productsStore;
    const { branch, restaurant, isShopClosed } = restaurantStore;
    const badgeStyle = themesStore.productBadgeStyle();
    const { id } = languagesStore.activeLanguage;
    const { currencyCode } = restaurantStore.branch;

    const basketBounds = IS_CLIENT
      ? {
          ...this.props.store.basketStore.bounds[states.product][
            this.props.store.basketStore.uniqStringLastAddedProduct
          ],
          // eslint-disable-next-line no-restricted-globals
          offsetY: pageYOffset
        }
      : {};

    const basketProducts: {
      [key: string]: number;
    } = {};

    basketStore.products.forEach(
      (productItem: { count: number; product: ProductModel }) => {
        if (!basketProducts[productItem.product.id]) {
          basketProducts[productItem.product.id] = productItem.count;
        } else {
          basketProducts[productItem.product.id] += productItem.count;
        }
      },
      {}
    );

    const category = hasSubcategories ? activeSubcategory : activeCategory;

    const title = hasSubcategories
      ? `${activeCategory.name} | ${activeSubcategory.name}`
      : activeCategory.name;

    let pictureMode = PICTURE_MODES.NO_PICTURES;

    if (branch.showBigPictureVariant1) {
      pictureMode = PICTURE_MODES.BIG_PICTURES_VARIANT_1;
    }

    if (branch.showBigPictureVariant2) {
      pictureMode = PICTURE_MODES.BIG_PICTURES_VARIANT_2;
    }

    if (branch.showSmallPicture) {
      pictureMode = PICTURE_MODES.SMALL_PRODUCT_PICTURES;
    }

    if (!category?.someProductHasImage) {
      pictureMode = PICTURE_MODES.NO_PICTURES;
    }

    const additiveProducts = additivesStore.additivesCategories.reduce(
      (acc, cv) => {
        if (cv.name === category.name) {
          acc = [...acc, ...cv.products];
        }

        return acc;
      },
      []
    );

    const { previewProducts } = categoryMenuStore.activeCategory;
    const imageUrl = category.hasCategoryImage ? category.picurl : undefined;

    return (
      <>
        <ProductListCategoryHeader
          image={imageUrl}
          title={title}
          description={category.description}
        />
        <ProductList
          loading={activeCategory.loading}
          isShopClosed={isShopClosed}
          products={sortedProductsList}
          basketProducts={basketProducts}
          picturesMode={pictureMode}
          onButtonClick={this.addProductToBasket}
          onCellClick={
            branch.isUseAdvancedProductView
              ? this.openProductModal
              : this.addProductToBasket
          }
          openProductModal={this.openProductModal}
          showHalalCertificate={this.showHalalCertificate}
          activeLanguage={id}
          hideProductArticles={branch.isHideArticleNumbers}
          isUseAdvancedProductView={branch.isUseAdvancedProductView}
          styles={{
            newBadgeStyle: badgeStyle
          }}
          animatedId={basketBounds.top && basketBounds.left && animateId}
          basketBounds={basketBounds}
          loadingAnimation={restaurant.loadingAnimation.url}
          currency={currencyCode}
          productCount={additiveProducts.length}
          productsPreviews={previewProducts}
        />
      </>
    );
  }

  render() {
    const { t, store, history } = this.props;
    const { isSearchEnabled } = store.productsStore;
    const params = openModal('allergensModal');

    params.pathname = history.location.pathname;

    return (
      <div
        className={classnames(
          'product-desktop-main-container',
          cantAddProductsToBasket(this.props) && 'blocked'
        )}
      >
        {isSearchEnabled
          ? this.renderSearchResults()
          : this.renderProductList()}
        <div className="allergens-list">
          <Link to={params}>
            <Text>{t('food_warning_list:detailedListLink')}</Text>
          </Link>
        </div>
      </div>
    );
  }
}

export default withTranslation([
  'product_view',
  'not_found',
  'food_warning_list'
])(ProductDesktopContainer);
