import classnames from 'classnames';
import React, { memo, useCallback, useEffect, useState } from 'react';

import css from './Dropdown.module.scss';
import { DropdownProps } from './Dropdown.type';

const Dropdown = <T,>({
  className,
  dropdownFooter,
  getActiveOption,
  options = [],
  getOptionLabel,
  noOptionLabel = 'No Options',
  onClick
}: DropdownProps<T>) => {
  const [activeElement, setActiveElement] = useState(
    options.findIndex(getActiveOption)
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const max = options.length;

      // eslint-disable-next-line default-case
      switch (event.key) {
        case 'ArrowUp': {
          event.preventDefault();

          const nextElement = (max + activeElement - 1) % max;

          setActiveElement(nextElement);

          return;
        }
        case 'ArrowDown': {
          event.preventDefault();

          const nextElement = (max + activeElement + 1) % max;

          setActiveElement(nextElement);

          return;
        }

        case 'Enter': {
          const option = options[activeElement];

          onClick(option);
        }
      }
    },
    [activeElement, onClick, options]
  );

  const handleClick = (option: T) => () => {
    onClick(option);
  };

  const values = options?.map((option, index) => {
    const isActive = index === activeElement;
    const isSelected = getActiveOption(option);

    const classNames = classnames(
      isActive && css.active,
      isSelected && css.selected
    );

    return (
      <li
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        className={classNames}
        onClick={handleClick(option)}
        onMouseDown={handleClick(option)}
      >
        {getOptionLabel(option)}
      </li>
    );
  });

  if (!values.length) {
    values.push(<li key="noOptionLabel">{noOptionLabel}</li>);
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className={classnames(css.Dropdown, className)} data-testid="Dropdown">
      <ul>{values}</ul>
      {dropdownFooter}
    </div>
  );
};

export { Dropdown };

export default memo(Dropdown) as typeof Dropdown;
