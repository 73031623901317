import React, { KeyboardEvent, Ref, forwardRef, useCallback } from 'react';

import { FakeButtonProps } from './FakeButton.type';

const FakeButton = forwardRef(
  (
    { children, onClick, className, dataTestId, ...rest }: FakeButtonProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          // TODO Figure out a way to pass a proper event type
          onClick && onClick(event as any);
        }
      },
      [onClick]
    );

    return (
      <div
        className={className}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
        data-testid="fakeButton"
        ref={ref}
        data-testid={dataTestId}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

export default React.memo(FakeButton);
