import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

/**
 * Component to render menu item with href
 */
export default class MenuItemHrefShell extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    style: PropTypes.object,
    dataTestId: PropTypes.string,
    dataTestIdIcon: PropTypes.string,
    dataTestIdLink: PropTypes.string
  };

  static defaultProps = {};

  render() {
    const {
      title,
      icon,
      href,
      dataTestId,
      dataTestIdIcon,
      dataTestIdLink
    } = this.props;

    return (
      <a href={href} data-testid={dataTestIdLink}>
        <div
          className="menu-item"
          onClick={this.props.action}
          style={this.props.style}
        >
          <i className={`fas ${icon}`} data-testid={dataTestIdIcon} />
          <div className="title" data-testid={dataTestId}>
            {title}
          </div>
        </div>
      </a>
    );
  }
}
