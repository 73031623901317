import React from 'react';
import PropTypes from 'prop-types';

// CSS
import './_small-loader.scss';

//Assets
import images from '../../../../../enums/images_enums/hermes_images.enum';

/**
 * Small loader component is used to show loading animation in main block.
 */
const SmallLoader = (props) => {
  const {
    loader = {},
    wrapperStyle = {},
    loaderUrl = '',
    loaderAlign = 'center'
  } = props;
  return (
    <div
      id="small-loader"
      style={{
        ...wrapperStyle,
        alignItems: loaderAlign
      }}
    >
      <img
        alt="Loading animation"
        src={loader.url ? loader.url : loaderUrl || images.loading}
        width={loader.url ? loader.w : undefined}
        height={loader.url ? loader.h : undefined}
        className="chef"
      />
    </div>
  );
};

SmallLoader.propTypes = {
  loader: PropTypes.shape({
    url: PropTypes.string,
    w: PropTypes.string,
    h: PropTypes.string
  }),
  loaderAlign: PropTypes.string,
  wrapperStyle: PropTypes.object
};

export default SmallLoader;
