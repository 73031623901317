import { computed, observable, toJS } from 'mobx';

import RootStore from 'client/stores';

import { intlPrice } from '../utils/functions';

/**
 * Size model class. Used to work with sizes.
 * @constructor
 * @param {number} id - unique identifier.
 * @param {object} size - size object.
 * @property {number} id - Unique identifier.
 * @property {string} name - Name.
 * @property {string} description - Description.
 * @property {number} pos - Position of the model.
 * @property {number} delivery_price - Delivery price.
 * @property {number} self_collector_price - Pick up price.
 * @property {boolean} active - Observable active variable.
 */
export class SizeModel {
  root: RootStore;

  constructor(id, size, root) {
    size.id = parseInt(id, 0);

    Object.assign(this, size);

    this.root = root;

    this.delivery_price = size.delivery_price || size.deliveryPrice;

    this.self_collector_price = size.self_collector_price || size.pickupPrice;
  }

  id;

  name;

  description = '';

  pos = 0;

  delivery_price = 0;

  self_collector_price = 0;

  @observable active = false;

  /**
   * Method to serialize constance to pure JS object.
   * @return {object} - serialized constance.
   */
  getToJS() {
    return toJS({ ...this, root: {} });
  }

  /**
   * Method to get delivery price.
   * @return {number} serialized array of properties.
   * @memberof SizeModel#
   * @method price
   */
  @computed get price() {
    return this.isDelivery ? this.delivery_price : this.self_collector_price;
  }

  /**
   * Method to check if size is selected
   * @return {boolean} is size selected.
   * @memberof SizeModel#
   * @method isActive
   */
  @computed get isActive() {
    return this.active;
  }

  /**
   * Method to get possibility of delivery.
   * @return {boolean} possibility of delivery.
   * @memberof SizeModel#
   * @method isDelivery
   */
  @computed get isDelivery() {
    return this.root.deliveryAddressStore.isDelivery;
  }

  /**
   * Method to check validation of size
   * @return {boolean} true if size valid
   * @memberof SizeModel#
   * @method isValid
   */
  @computed get isValid() {
    return this.price > 0;
  }

  /**
   * Method to get localized price with current currency
   * @return {string} Localized price with currency
   * @memberof SizeModel#
   * @method intlPrice
   */
  @computed get intlPrice() {
    const language = this.root.restaurantStore.restaurant.getLang;
    const { currencyCode } = this.root.restaurantStore.branch;

    return intlPrice(this.price, language, currencyCode);
  }
}
