/**
 * @namespace
 * @property {object} defaults - Languages list with appropriate images
 */
module.exports = {
  de: {
    name: 'Deutsch',
    parent: 'de',
    icon: require('../../assets/images/languages/de.svg')
  },
  pl: {
    name: 'Polish',
    parent: 'pl',
    icon: require('../../assets/images/languages/pl.svg')
  },
  en: {
    name: 'English',
    parent: 'en',
    icon: require('../../assets/images/languages/en.svg')
  },
  gsw: {
    name: 'Switzerland',
    parent: 'de',
    icon: require('../../assets/images/languages/gsw.svg')
  }
};
