/* eslint-disable import/no-cycle */
import { AdditiveModelType } from 'client/models/additive.model.type';
import { toJS } from 'mobx';

import AdditiveProductModel from './additive_product.model';

/**
 * Additive / Allergen model
 * @constructor
 * @property {id} - Unique id of additive/allergen.
 * @property {string} - Name of additive/allergen.
 * @property {string} prefix - Prefix of additive/allergen.
 * @property {array} products - Products array of additive/allergen.
 */
export default class AdditiveModel {
  public id: number;

  public name: string;

  public prefix: string;

  public products: string[];

  constructor(additive: AdditiveModelType) {
    this.id = additive.id;

    this.name = additive.name || '';

    this.prefix = additive.prefix || '';

    this.products = additive.products || [];
  }

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  public getToJS() {
    return toJS({ ...this, root: {} });
  }
}
