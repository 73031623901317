import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import './PickupInfoMessage.scss';

/**
 * Component to render pickup info message
 */
class PickupInfoMessage extends React.PureComponent {
  static propTypes = {
    nearestPickupTime: PropTypes.object.isRequired
  };

  static defaultProps = {};

  render() {
    const { nearestPickupTime } = this.props;

    return (
      <div className="checkout-description" data-testid="checkout-time-basket">
        {this.props.t('basket:lblForTime')} &nbsp;
        {nearestPickupTime.format('DD.MM.YYYY')} &nbsp;
        {this.props.t('basket:lblApproximately')} &nbsp;
        {nearestPickupTime.format('HH:mm')}
      </div>
    );
  }
}

export default withTranslation(['basket'])(PickupInfoMessage);
