import { Icon } from 'antd';
import debounce from 'lodash/debounce';
import React, { useCallback, useState } from 'react';

type QueryFunc<T> = (name: string) => Promise<T[]>;

const useSearch = <T,>(queryFunc: QueryFunc<T>, delay = 300) => {
  const [options, setOptions] = useState<T[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(
    debounce(async (name: string) => {
      try {
        setIsLoading(true);

        const data = await queryFunc(name);

        setOptions(data);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }, delay),
    []
  );

  const handleInputChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      const { value } = event.target;

      setInputValue(value);

      if (value) {
        fetchData(value);
      }
    },
    [fetchData]
  );

  const loader = isLoading ? <Icon type="loading" spin /> : undefined;

  return { handleInputChange, setInputValue, options, inputValue, loader };
};

export { useSearch };
