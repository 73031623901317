import { useLocation } from 'react-router-dom';

function useQueryParams<
  Params extends { [K in keyof Params]?: string } = Record<string, string>
>(): Params;

function useQueryParams() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const params = {} as Record<string, string>;

  for (const [key, value] of searchParams) {
    params[key] = value;
  }

  return params;
}

export { useQueryParams };
