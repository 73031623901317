import { Icon } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';

// CSS
import './styles.scss';

// Enums
import images from '../../../../../../enums/images_enums/hermes_images.enum';

// Components
import BasketButton from '../basket-button/BasketButton';

/**
 * Button placed on the bottom of the screen
 */
class FooterShellButton extends PureComponent {
  static propTypes = {
    labelObject: PropTypes.object,
    label: PropTypes.string,
    action: PropTypes.func,
    link: PropTypes.object,
    disabled: PropTypes.bool,
    colored: PropTypes.bool,
    isCartButton: PropTypes.bool,
    smallText: PropTypes.bool,
    type: PropTypes.string,
    buttonOrderStyle: PropTypes.object,
    buttonStandardStyle: PropTypes.object,
    isSoldOut: PropTypes.bool,
    loading: PropTypes.bool,
    animated: PropTypes.bool,
    dataTestId: PropTypes.string
  };

  static defaultProps = {
    label: 'button',
    smallText: false,
    action: () => {},
    isCartButton: false,
    disabled: false,
    colored: false,
    type: 'button',
    isSoldOut: false,
    loading: false,
    animated: false
  };

  /**
   * Method to handle button click
   * @private
   */
  _onClickHandler = () => {
    if (!this.props.disabled) {
      this.props.action();
    }
  };

  /**
   * Method to render button with onClick action
   * @returns {*}
   * @private
   */
  _renderButton = () => {
    let customStyle = this.props.colored
      ? this.props.buttonOrderStyle
      : this.props.buttonStandardStyle;

    customStyle = this.props.disabled
      ? this.props.buttonDisabledStyle
      : customStyle;

    return (
      <button
        className={classNames({
          'shell-footer-button': true,
          disabled: this.props.disabled || this.props.isSoldOut,
          colored: !this.props.disabled && this.props.colored,
          'small-text': this.props.smallText
        })}
        style={customStyle}
        type={this.props.type}
        disabled={this.props.disabled}
        onClick={this._onClickHandler}
        data-testid={this.props.dataTestId}
      >
        {this.props.isSoldOut && (
          <ReactSVG
            className="shell-footer-button-icon-sold-out"
            src={images.iconSoldOut}
            beforeInjection={(svg) => {
              svg.setAttribute(
                'class',
                `${svg.getAttribute(
                  'class'
                )} shell-footer-button-icon-sold-out-image`
              );

              if (customStyle.color) {
                const pathElements = svg.getElementsByTagName('path');

                for (const elem of pathElements) {
                  elem.setAttribute('style', `fill: ${customStyle.color}`);
                }
              }
            }}
          />
        )}
        {this.props.labelObject ? this.props.labelObject : this.props.label}
      </button>
    );
  };

  /**
   * Method to render link instead of button
   * @returns {*}
   * @private
   */
  _renderLink = () =>
    this.props.isCartButton ? (
      this._renderCartButton()
    ) : (
      <Link
        className={classNames({
          'shell-footer-button': true,
          disabled: this.props.disabled,
          colored: !this.props.disabled && this.props.colored,
          'small-text': this.props.smallText
        })}
        to={this.props.link}
        data-testid={this.props.dataTestId}
      >
        {this.props.labelObject ? this.props.labelObject : this.props.label}
      </Link>
    );

  /**
   * Method to render specific cart type button
   * @returns {*}
   * @private
   */
  _renderCartButton = () => (
    <BasketButton
      link={this.props.link}
      buttonOrderStyle={this.props.buttonOrderStyle}
      label={this.props.label}
      animated={this.props.animated}
    />
  );

  loadingButton = () => {
    const customStyle = this.props.colored
      ? this.props.buttonOrderStyle
      : this.props.buttonStandardStyle;

    return (
      <button
        className="shell-footer-button disabled colored"
        style={customStyle}
        type={this.props.type}
        disabled
      >
        <Icon type="loading" style={{ fontSize: 24 }} spin />
      </button>
    );
  };

  render() {
    if (this.props.loading) {
      return this.loadingButton();
    }

    return this.props.link ? this._renderLink() : this._renderButton();
  }
}

export default withTranslation(['basket', 'common'])(FooterShellButton);
