import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootContext } from 'client/stores';

import GoogleAddressFC from './GoogleAddress';
import { GoogleAddressProps } from './GoogleAddress.type';

const GoogleAddress = (
  props: Omit<GoogleAddressProps, 'country' | 'language'>
) => {
  const { restaurantStore } = useContext(RootContext);
  const { i18n } = useTranslation(['address_form', 'validationErrors']);
  const country = restaurantStore.restaurant.countryCode?.toLowerCase();

  return (
    <GoogleAddressFC
      {...props}
      country={country}
      language={i18n.language as AppLang}
    />
  );
};

export default memo(GoogleAddress);
