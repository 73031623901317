import classnames from 'classnames';
import React from 'react';

import ImageSkeleton from 'client/ui/ImageSkeleton';
import Skeleton from 'client/ui/Skeleton';

import { ProductBigPictureVariant1LoaderProps as Props } from './ProductBigPictureVariant1Loader.type';

import './ProductBigPictureVariant1Loader.module.scss';

const ProductBigPictureVariant1Loader = ({
  className,
  backgroundColor
}: Props) => (
  <div className={classnames('Loader', 'loader', className)}>
    <div className="container">
      <div className="imageContainer">
        <ImageSkeleton backgroundColor={backgroundColor} />
      </div>
      <div className="textContainer">
        <Skeleton width="100%" height="auto">
          <rect x="0" y="0" rx="0" ry="0" width="185" height="27" />
          <rect x="0" y="33" rx="0" ry="0" width="305" height="35" />
        </Skeleton>
      </div>
    </div>
    <div className="pseudoButton" />
  </div>
);

export { ProductBigPictureVariant1Loader };

export default React.memo(ProductBigPictureVariant1Loader);
