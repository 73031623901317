import { UtmData } from './utm.store.type';

class UtmStore {
  utmSource?: string;

  utmMedium?: string;

  utmCampaign?: string;

  utmTerm?: string;

  utmContent?: string;

  setFields = (utmData: UtmData) => {
    const { utmCampaign, utmContent, utmMedium, utmSource, utmTerm } = utmData;

    if (utmSource) {
      this.utmSource = utmSource;
    }

    if (utmMedium) {
      this.utmMedium = utmMedium;
    }

    if (utmCampaign) {
      this.utmCampaign = utmCampaign;
    }

    if (utmTerm) {
      this.utmTerm = utmTerm;
    }

    if (utmContent) {
      this.utmContent = utmContent;
    }
  };

  clearUtmData = () => {
    this.utmSource = undefined;

    this.utmCampaign = undefined;

    this.utmContent = undefined;

    this.utmTerm = undefined;

    this.utmMedium = undefined;
  };
}

export default UtmStore;
