/**
 * Imprint view types
 */

enum IMPRINT_VIEW_TYPES {
  CUSTOMER = '0',
  APP_SMART_CREDENTIALS = '1',
  BOTH = '2',
  CUSTOMER_WITH_APP_SMART_EMAIL = '3'
}

export default IMPRINT_VIEW_TYPES;
