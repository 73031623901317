/* eslint-disable camelcase */
import React from 'react';
import ReactSVG from 'react-svg';

import hermes_images from '../../../../../../enums/images_enums/hermes_images.enum';
import SmallLoader from '../../../loaders/small_loader';
import './order-pending.scss';

interface IOrderPending {
  loadingAnimation: Record<string, any> | undefined;
  mainHeader: string;
  contentHeader: string;
  contentDescription: string;
  style?: React.CSSProperties;
}

const OrderPending = ({
  loadingAnimation,
  mainHeader,
  contentHeader,
  contentDescription,
  style
}: IOrderPending) => (
  <div id="order-payment-methods" className="order-pending">
    <div className="heading" style={style}>
      <ReactSVG
        className="wait-icon-wrapper"
        src={hermes_images.iconWait}
        beforeInjection={(svg) => {
          svg.setAttribute('class', `${svg.getAttribute('class')} icon-wait`);

          svg.setAttribute('style', 'width: 20px; height: 20px;');
        }}
      />
      {mainHeader}
    </div>
    <div className="order-payment-content">
      <div className="order-payment-content-header-text">{contentHeader}</div>
      <div className="order-payment-content-description-text">
        {contentDescription}
      </div>
      <SmallLoader
        loader={{
          ...loadingAnimation,
          w: '46',
          h: '46'
        }}
        loaderAlign="flex-start"
      />
    </div>
  </div>
);

export default OrderPending;
