import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

import './style.scss';

/**
 * Component to render Branch Name Block for mobile
 */
@inject('store')
@observer
class BranchNameMobile extends PureComponent {
  static propTypes = {
    isShowPhone: PropTypes.bool,
    phone: PropTypes.string
  };

  static defaultProps = {
    isShowPhone: true,
    phone: null
  };

  render() {
    const { store, isShowPhone, phone } = this.props;
    const { themesStore, analyticsStore } = store;
    const { isHunger } = themesStore;

    if (isShowPhone && phone) {
      return (
        <div id="phone-number-block" className="phone-number-mobile">
          <div className="phone">
            <a
              href={`tel:${this.props.phone}`}
              onClick={() => analyticsStore.sendPhoneNumberTopBar()}
            >
              <i className="mdi mdi-phone" />
              <span>{this.props.phone}</span>
            </a>
          </div>
        </div>
      );
    }

    if (!isHunger) {
      return (
        <div id="phone-number-block" className="phone-number-mobile">
          <div className="phone">{this.props.t('useOurApps')}</div>
        </div>
      );
    }

    return null;
  }
}

export default withTranslation(['title'])(BranchNameMobile);
