import { action, observable } from 'mobx';

import RootStore from 'client/stores';
import GoogleTagsManager from 'client/utils/analytics/googleTagsManager';

import { ProductModel } from '../../models/product.model';
import NativeAnalytics from '../../utils/analytics/nativeAnalytics';

/**
 * Class for sending events to analytics systems.
 * @constructor
 */
export default class AnalyticsStore {
  root: RootStore;

  googleTagsManager?: GoogleTagsManager;

  nativeAnalytics?: NativeAnalytics;

  constructor(root: RootStore, state: AnalyticsStore) {
    Object.assign(this, state);

    this.root = root;

    this.googleTagsManager = new GoogleTagsManager(this.root);

    if (
      this.root.themesStore.isPortalWebThemeActive ||
      this.root.themesStore.isPortalAppThemeActive
    ) {
      this.nativeAnalytics = new NativeAnalytics(this.root);
    }
  }

  @observable trackingTransactionId = '';

  @action setTrackingTransactionId(transactionId: string) {
    this.trackingTransactionId = transactionId;
  }

  init() {
    this.googleTagsManager?.init();
  }

  sendViewShop() {
    const branchName =
      this.root.restaurantStore.restaurant.getTitle || 'no branch name';

    this.googleTagsManager?.trackViewShop(branchName);

    this.nativeAnalytics?.trackViewShop(branchName);
  }

  sendAddToBasket(product: ProductModel) {
    this.googleTagsManager?.trackAddToBasket(product);

    this.nativeAnalytics?.trackAddToBasket(product);
  }

  sendStartCheckout() {
    this.googleTagsManager?.trackStartCheckout();

    this.nativeAnalytics?.trackStartCheckout();
  }

  sendProvidePersonalInformation() {
    this.googleTagsManager?.trackProvidePersonalInformation();

    this.nativeAnalytics?.trackProvidePersonalInformation();
  }

  sendPlaceOrder() {
    this.googleTagsManager?.trackPlaceOrder();

    this.nativeAnalytics?.trackPlaceOrder();
  }

  sendSuccessfulPayment() {
    this.googleTagsManager?.trackSuccessfulPayment();

    this.nativeAnalytics?.trackSuccessfulPayment();
  }

  sendFailedPayment() {
    this.googleTagsManager?.trackFailedPayment();

    this.nativeAnalytics?.trackFailedPayment();
  }

  sendGlobalError(link: string) {
    this.googleTagsManager?.trackGlobalError(link);

    this.nativeAnalytics?.trackGlobalError(link);
  }

  sendThankYouPage() {
    this.googleTagsManager?.trackThankYouPage(this.trackingTransactionId);
  }

  sendPhoneNumberTopBar() {
    this.googleTagsManager?.trackPhoneNumberTopBar();

    this.nativeAnalytics?.trackPhoneNumberTopBar();
  }

  sendPhoneNumberLegalPage() {
    this.googleTagsManager?.trackPhoneNumberLegalPage();

    this.nativeAnalytics?.trackPhoneNumberLegalPage();
  }

  sendPhoneNumberSuccessfulPayment() {
    this.googleTagsManager?.trackPhoneNumberSuccessfulPayment();

    this.nativeAnalytics?.trackPhoneNumberSuccessfulPayment();
  }

  sendPhoneNumberFailedOrder() {
    this.googleTagsManager?.trackPhoneNumberFailedOrder();

    this.nativeAnalytics?.trackPhoneNumberFailedOrder();
  }

  sendPhoneNumberAppMainPage() {
    this.googleTagsManager?.trackPhoneNumberAppMainPage();

    this.nativeAnalytics?.trackPhoneNumberAppMainPage();
  }

  sendPhoneNumberAppSidemenu() {
    this.googleTagsManager?.trackPhoneNumberAppSidemenu();

    this.nativeAnalytics?.trackPhoneNumberAppSidemenu();
  }

  sendConfirmedOrder() {
    this.googleTagsManager?.trackConfirmedOrder();

    this.nativeAnalytics?.trackConfirmedOrder();
  }

  sendCanceledOrder() {
    this.googleTagsManager?.trackCanceledOrder();

    this.nativeAnalytics?.trackCanceledOrder();
  }
}
