import AppWrapper from 'client/components/app_wrapper/AppWrapper';

import Loadable from '../components/AsyncLoading';
import states from '../enums/states.enum';
import AggregatorStart from '../themes/theme-hermes/components/aggregatorStart';
import HomepagePreview from '../themes/theme-hermes/components/homepage-preview';
import Start from '../themes/theme-hermes/components/start';
import { generateRoutePath } from '../utils/routing';

const AsyncMaintenance = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "maintenance", webpackPrefetch: true */ '../themes/theme-hermes/components/maintenance_mode'
    )
});

const AsyncMigrated = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "migrated", webpackPrefetch: true */ '../themes/theme-hermes/components/shop_moved'
    )
});

const AsyncError = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "error", webpackPrefetch: true */ '../themes/theme-hermes/components/error/error-page'
    )
});

export function mainRoute(hostName, isAggregator = false) {
  const aggregator = {
    isAggregator,
    isAggregatorStartPage: false
  };

  const routes = [
    {
      key: 'subcategory',
      path: `${generateRoutePath(':branchId/cat/:catId/:subId', hostName)}`,
      component: AppWrapper
    },
    {
      key: 'category',
      path: `${generateRoutePath(':branchId/cat/:catId', hostName)}`,
      component: AppWrapper
    },
    {
      key: states.cat,
      path: `${generateRoutePath(':branchId/cat', hostName)}`,
      component: AppWrapper
    },
    {
      key: states.homepagePreview,
      path: `${generateRoutePath(
        `:branchId/${states.homepagePreview}`,
        hostName
      )}`,
      component: HomepagePreview
    },
    {
      key: states.maintenanceMode,
      path: `${generateRoutePath(`${states.maintenanceMode}`, hostName)}`,
      component: AsyncMaintenance
    },
    {
      key: states.shopMoved,
      path: `${generateRoutePath(`${states.shopMoved}`, hostName)}`,
      component: AsyncMigrated
    },
    // Start page route - uses for presenting pages in branch selection page
    {
      key: states.startPage,
      path: `${generateRoutePath(states.startPage, hostName)}`,
      component: Start
    },
    {
      key: states.error,
      path: `${generateRoutePath(states.error, hostName)}`,
      component: AsyncError
    },
    {
      key: states.error,
      path: `${generateRoutePath(`:branchId/${states.error}`, hostName)}`,
      component: AsyncError
    },
    {
      key: 'branch',
      path: `${generateRoutePath(':branchId', hostName)}`,
      component: AppWrapper
    },
    {
      key: 'slug',
      path: `${generateRoutePath('', hostName, aggregator)}`,
      component: Start
    }
  ];

  if (isAggregator) {
    routes.push({
      key: 'aggregator',
      path: `${generateRoutePath('', hostName, {
        ...aggregator,
        isAggregatorStartPage: true
      })}`,
      component: AggregatorStart
    });
  }

  return routes;
}

export function routesForLoad(hostName, withAggregator) {
  return [].concat(mainRoute(hostName, !!withAggregator));
}
