import classNames from 'classnames';
import debounce from 'lodash/debounce';
import flattenDeep from 'lodash/flattenDeep';
import { inject, observer } from 'mobx-react';
import React, {
  CSSProperties,
  Component,
  MouseEvent,
  ReactElement,
  ReactNode,
  createRef
} from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { VariableSizeList as List } from 'react-window';

import { CategoryModel } from 'client/models/category_menu.model';
import { OfferModel } from 'client/models/offer.model';
import { ProductModel } from 'client/models/product.model';
import openModal from 'client/utils/openModal';

import SwiperSlider from '../../../../../components/SwiperSlider';
import images from '../../../../../enums/images_enums/hermes_images.enum';
import PICTURE_MODES from '../../../../../enums/product_images.enum';
import states from '../../../../../enums/states.enum';
import { generateLinkFor } from '../../../../../utils/routing';
import SmallLoader from '../../../components/loaders/small_loader';
import OfferCell from '../../../components/offer/offer_list/_item_cell/OffersCellHermesTheme';
import ProductCell from '../../../components/products_list/common/products-list/product-cell/ProductCell';
import { ProductsShellWrapper } from '../../../components/products_list/shell';

import CategoryHeader from './category-header/CategoryHeader';
import { ICategoryScrollableListProps } from './props.interface';
import './CategoryScrollableList.scss';
import '../../../components/products_list/common/cell/ProductsListHermesTheme.scss';

interface IElementSize {
  size: number;
  index: number;
  categoryId: number;
  header: boolean;
  customStyle: CSSProperties;
}

interface IState {
  scrollListenerInstalled: boolean;
  showScrollToTopButton: boolean;
  wholeProductListRendered: boolean;
  reactWindowContainerHeight: number;
  categoriesMap: {
    [key: string]: number;
  };
}

@inject('store')
@(withRouter as any)
@observer
class CategoryScrollableList extends Component<
  ICategoryScrollableListProps,
  IState
> {
  constructor(props: ICategoryScrollableListProps) {
    super(props);

    this.changeSliderCategoryOnUserScroll = debounce(
      this.changeSliderCategoryOnUserScroll,
      30
    );
  }

  state: IState = {
    scrollListenerInstalled: false,
    showScrollToTopButton: false,
    wholeProductListRendered: false,
    reactWindowContainerHeight: 0,
    categoriesMap: {}
  };

  headerSwiper: any = null;

  swiperActiveItemInScrollClass = 'category-menu-active';

  listRef = createRef<
    {
      resetAfterIndex: (index: number) => void;
      scrollTo: (scrollOffset: number) => void;
      scrollToItem: (index: number, align?: string) => void;
    } & ReactElement
  >();

  elementsSizes: {
    [key: string]: IElementSize;
  } = this.props.store.themesStore.virtualListSizes || {};

  listWrapperRef = createRef<HTMLDivElement>();

  listInnerRef = createRef<HTMLDivElement>();

  swiperWrapperRef = createRef<HTMLDivElement>();

  productItems: { key: string & ReactNode }[] = [];

  ESTIMATED_CELL_SIZE = 80;

  currentVisibleItem = 0;

  currentSlideId = 0;

  currentListOffset: number =
    this.props.store.themesStore.clickedProductTopOffset || 0;

  currentSlideIndex: number =
    this.props.store.themesStore.lastScrolledSliderElementIndex || 0;

  // Manual scrolling - scrolling by 'scrollTo' or 'scrollToItem' methods
  manualScrolling = false;

  swiperParams = {
    slidesPerView: 'auto',
    freeMode: true,
    slideToClickedSlide: true,
    scrollbar: {
      el: '.swiper-scrollbar',
      hide: false
    },
    initialSlide: this.currentSlideIndex
  };

  componentDidMount() {
    const { isShellCategoryHorizontalType } = this.props.store.themesStore;
    const { wholeProductListLoaded } = this.props.store.categoryMenuStore;

    if (isShellCategoryHorizontalType && !wholeProductListLoaded) {
      this.props.store.categoryMenuStore.fillProductsCategories();
    }

    // A block above for setup new templating styles for slider
    // It can't be done by correct way because of it needs to set inline styles depending on class
    //-----
    const templatingStyle = this.props.store.themesStore.categoriesListStyle();
    const node = document.createElement('style');

    node.setAttribute('type', 'text/css');

    node.setAttribute('id', 'category-scrollable-list-styles');

    node.innerHTML = `
      .category-menu-active .category-scrollable-list-header-item-category-title {
          color: ${templatingStyle.color} !important;
      }
      .swiper-scrollbar-drag {
        background: ${templatingStyle.color} !important;
      }
    `;

    document.getElementsByTagName('head')[0].appendChild(node);
    //-----

    let index = -1;

    const categoriesMap: { [key: string]: number } = {};

    this.props.store.categoryMenuStore.categories
      .filter((cat: any) => cat.id !== -2)
      .forEach((cat: any) => {
        if (cat.hasSubCategories) {
          cat.subCategories.forEach((subcat: any) => {
            index++;

            categoriesMap[subcat.id] = index;
          });
        } else {
          index++;

          categoriesMap[cat.id] = index;
        }
      });

    this.setState({
      categoriesMap
    });
  }

  componentDidUpdate() {
    this.listRef.current && this.listRef.current.resetAfterIndex(0);

    if (
      !this.state.wholeProductListRendered &&
      this.props.store.categoryMenuStore.wholeProductListLoaded
    ) {
      if (
        this.listRef.current &&
        this.listInnerRef.current &&
        this.listWrapperRef.current
      ) {
        this.props.store.categoryMenuStore.scrollContainerElement(true);

        const topHeader = document.getElementById('header-shell');

        const orderTypeSwitcherBlock = document.getElementById(
          'delivery-info-container'
        );

        const topHeight =
          (topHeader ? topHeader.offsetHeight : 0) +
          (orderTypeSwitcherBlock ? orderTypeSwitcherBlock.offsetHeight : 0);

        const swiperWrapperHeight = this.swiperWrapperRef.current
          ? this.swiperWrapperRef.current.getBoundingClientRect().height
          : 0;

        const swiperWrapperScrollBar = this.swiperWrapperRef.current
          ? this.swiperWrapperRef.current.getElementsByClassName(
              'swiper-scrollbar'
            )
          : null;

        const swiperWrapperScrollBarHeight = swiperWrapperScrollBar
          ? swiperWrapperScrollBar[0].getBoundingClientRect().height
          : 0;

        // 18 - $contentPaddingTop from ./CategoryScrollableList.scss
        const listWrapperTopOffset =
          swiperWrapperHeight - 18 + swiperWrapperScrollBarHeight;

        // It's for overlapping category menu above virtual list
        // It's done for simulating stopping of scrolling virtual list when a user taps on some category (changes for ios, but it works in chrome too)
        this.listInnerRef.current.style.position = 'relative';

        this.listWrapperRef.current.style.paddingTop = `${listWrapperTopOffset}px`;

        this.setState({
          reactWindowContainerHeight:
            window.innerHeight -
            topHeight -
            swiperWrapperHeight +
            swiperWrapperScrollBarHeight +
            listWrapperTopOffset +
            (this.props.showDiscountBlock ? 0 : 30),
          wholeProductListRendered: true
        });
      }
    }
  }

  componentWillUnmount() {
    const node = document.getElementById('category-scrollable-list-styles');

    node && document.getElementsByTagName('head')[0].removeChild(node);

    this.props.store.themesStore.setClickedProductTopOffset(
      this.currentListOffset
    );

    this.props.store.themesStore.saveScrollableListPaddingTop(
      parseFloat(this.listWrapperRef.current?.style.paddingTop || '') || 0
    );

    this.props.store.themesStore.setVirtualListSizes(this.elementsSizes);

    this.props.store.themesStore.setLastScrolledSliderElementIndex(
      this.currentSlideIndex
    );
  }

  changeActiveSlideClassname = () => {
    const previousActiveSlide = document.getElementsByClassName(
      this.swiperActiveItemInScrollClass
    );

    if (previousActiveSlide.length) {
      previousActiveSlide[0].classList.remove(
        this.swiperActiveItemInScrollClass
      );
    }

    const customActiveSlide = document.getElementById(
      `link-${this.currentSlideId}`
    );

    if (customActiveSlide) {
      customActiveSlide.classList.add(this.swiperActiveItemInScrollClass);
    }
  };

  /**
   * Scroll container with products to top
   */
  scrollContainerToTop = () => {
    this.listRef.current && this.listRef.current.scrollTo(0);
  };

  changeSliderCategoryOnUserScroll = (currentVisibleItem: number) => {
    if (!this.manualScrolling) {
      const elementKey = Object.keys(this.elementsSizes).find(
        (elementKey) =>
          this.elementsSizes[elementKey].index === currentVisibleItem
      );

      if (
        elementKey &&
        this.currentSlideId !== this.elementsSizes[elementKey].categoryId
      ) {
        this.currentSlideId = this.elementsSizes[elementKey].categoryId;

        const isSwiper = !!this.headerSwiper && !this.headerSwiper.destroyed;

        isSwiper &&
          this.headerSwiper.slideTo(
            this.state.categoriesMap[this.currentSlideId.toString()]
          );

        this.currentSlideIndex = this.state.categoriesMap[
          this.currentSlideId.toString()
        ];

        this.changeActiveSlideClassname();
      }
    }
  };

  onVirtualItemsRendered = ({
    visibleStartIndex
  }: {
    overscanStartIndex: number;
    overscanStopIndex: number;
    visibleStartIndex: number;
    visibleStopIndex: number;
  }) => {
    this.currentVisibleItem = visibleStartIndex;
  };

  onScrollList = ({
    scrollDirection,
    scrollOffset
  }: {
    scrollDirection: 'forward' | 'backward';
    scrollOffset: number;
    scrollUpdateWasRequested: boolean;
  }) => {
    if (
      scrollOffset > this.state.reactWindowContainerHeight &&
      !this.state.showScrollToTopButton
    ) {
      this.setState({
        showScrollToTopButton: true
      });
    } else if (
      scrollOffset <= this.state.reactWindowContainerHeight &&
      this.state.showScrollToTopButton
    ) {
      this.setState({
        showScrollToTopButton: false
      });
    }

    const { currentVisibleItem } = this;

    // Hack with 1px for backward scrolling - avoiding a bug of the library on ios caused by requestAnimationFrame inside codebase of the library
    // This bug make our virtual list to scroll up by very little space
    if (
      scrollDirection === 'backward' &&
      this.currentListOffset - scrollOffset > 1
    ) {
      this.changeSliderCategoryOnUserScroll(currentVisibleItem);
    } else if (scrollDirection === 'forward') {
      this.changeSliderCategoryOnUserScroll(currentVisibleItem);
    }

    this.currentListOffset = scrollOffset;
  };

  /**
   * Method to add product to basket
   * @param product
   */
  _onProductClick = (event: MouseEvent, product: ProductModel) => {
    event.stopPropagation();

    const { history } = this.props;

    const productUrl = openModal('productModal', {
      productId: `${product.id}`
    });

    history.push(productUrl);
  };

  /**
   * Handle add button click
   * @param product
   * @param event
   * @private
   */
  _onAddProductClick = (event: MouseEvent, product: ProductModel) => {
    event.stopPropagation();

    const { history, store } = this.props;
    const { basketStore, productsStore, categoryMenuStore } = store;

    if (!product.soldOut) {
      basketStore.setLastProductClicked(product.id);

      productsStore.addProduct(product, history);

      categoryMenuStore.setContainerScrollPosition();
    }
  };

  /**
   * Method to add offer to basket
   * @param offer
   */
  _onOfferClick = (offer: OfferModel) => {
    this.props.store.offerStore.addOffer(
      offer.id,
      offer.offerType,
      this.props.history
    );

    this.props.store.categoryMenuStore.setContainerScrollPosition();
  };

  onSwiperElementClick = (categoryId: number, index: number) => {
    this.props.store.themesStore.setClickedProductTopOffset();

    this.scrollToCategory(categoryId);

    this.currentSlideId = categoryId;

    this.changeActiveSlideClassname();

    this.currentSlideIndex = index;
  };

  scrollToCategory(categoryId: number) {
    // All timeouts are for be sure that manual scrolling was completed
    // Unfortunately, react-window API doesn't have any callbacks for it now
    this.manualScrolling = true;

    const firstProductKey = Object.keys(this.elementsSizes).find(
      (key) => this.elementsSizes[key].categoryId === categoryId
    );

    const index = firstProductKey
      ? this.elementsSizes[firstProductKey].index
      : null;

    this.listRef.current &&
      index !== null &&
      this.listRef.current.scrollToItem(index, 'start');

    if (index !== null && this.currentVisibleItem !== index) {
      setTimeout(() => {
        this.listRef.current &&
          this.listRef.current.scrollToItem(index, 'start');

        setTimeout(() => {
          this.manualScrolling = false;
        }, 0);
      }, 0);
    } else {
      setTimeout(() => {
        this.manualScrolling = false;
      }, 0);
    }
  }

  /**
   * handler for click on halal icon
   * @param event
   */
  showHalalCertificate = (event: MouseEvent) => {
    event.stopPropagation();

    this.props.history.push(
      generateLinkFor(states.productCertificate, this.props, {}, true)
    );
  };

  // Key for identification products in virtual list
  createProductKey = (id: number) => `product-${id}`;

  // Key for identification headers of categories in virtual list
  createHeaderKey = (id: number) => `header-${id}`;

  updateElementSize = (element: IElementSize & { key: string }) => {
    this.elementsSizes[element.key] = {
      size: element.size,
      index: element.index,
      categoryId: element.categoryId,
      header: element.header,
      customStyle: element.customStyle
    };

    this.listRef.current && this.listRef.current.resetAfterIndex(element.index);
  };

  renderTopListCategories = (categories: CategoryModel[]) => {
    const categoryItems: CategoryModel[] = [];

    categories.forEach((cat) => {
      if (cat.hasSubCategories) {
        categoryItems.push(...cat.subCategories);
      } else {
        categoryItems.push(cat);
      }
    });

    const {
      showMenuCategoryImagesAlwaysShell,
      hideMenuCategoryImagesShell
    } = this.props.store.themesStore;

    // 667 - height of "small" mobile devices
    const bigDevice =
      showMenuCategoryImagesAlwaysShell ||
      (!hideMenuCategoryImagesShell &&
        document.getElementsByTagName('html')[0].offsetHeight > 667);

    return categoryItems.map((cat, index) => (
      <div
        id={`link-${cat.id}`}
        key={`link-${cat.id}`}
        onClick={() => this.onSwiperElementClick(cat.id, index)}
      >
        <div className="category-scrollable-list-header-item-wrapper">
          {bigDevice &&
            (cat.picurl ? (
              <img
                src={cat.picurl}
                alt={cat.name}
                className="category-scrollable-list-header-item-category-image"
              />
            ) : (
              <div
                className={classNames({
                  'category-scrollable-list-header-item-category-image': true,
                  'no-image': true
                })}
              />
            ))}
          <div className="category-scrollable-list-header-item-category-title">
            {cat.name}
          </div>
        </div>
      </div>
    ));
  };

  renderCategoryBlock = (category: CategoryModel, index: number) => {
    const {
      isUseAdvancedProductView,
      currencyCode
    } = this.props.store.restaurantStore.branch;

    const {
      productPriceStyleButtonStyle,
      categoryHeaderHorizontalMenu,
      separatorStyle,
      scrollableListPaddingTop
    } = this.props.store.themesStore;

    const { animateId: animateProductId } = this.props.store.productsStore;
    const { animateId: animateOfferId } = this.props.store.offerStore;
    const { isShopClosed } = this.props.store.restaurantStore;
    //! KEYS ARE VERY IMPORTANT FOR CORRECT WORKING OF VIRTUAL LIST, NOT ONLY FOR REACT
    const headerKey = this.createHeaderKey(category.id);

    !this.elementsSizes[headerKey] &&
      this.updateElementSize({
        key: headerKey,
        size: this.ESTIMATED_CELL_SIZE,
        index,
        categoryId: category.id,
        header: true,
        customStyle: {}
      });

    const headerComponent = (
      <CategoryHeader
        key={headerKey}
        headerText={category.name}
        description={category.description}
        style={{
          headerStyle: {
            background: categoryHeaderHorizontalMenu().titleBackground
          },
          containerStyle: {
            ...separatorStyle(),
            color: categoryHeaderHorizontalMenu().color
          }
        }}
        setSize={
          (size: number) =>
            this.updateElementSize({
              key: headerKey,
              size,
              index,
              categoryId: category.id,
              header: true,
              customStyle: {}
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />
    );

    if (category.id !== -1) {
      const { branch } = this.props.store.restaurantStore;

      let pictureMode = PICTURE_MODES.NO_PICTURES;

      pictureMode =
        branch.showBigPictureVariant1 && category.someProductHasImage
          ? PICTURE_MODES.BIG_PICTURES_VARIANT_1
          : pictureMode;

      pictureMode =
        !pictureMode &&
        branch.showBigPictureVariant2 &&
        category.someProductHasImage
          ? PICTURE_MODES.BIG_PICTURES_VARIANT_2
          : pictureMode;

      pictureMode =
        !pictureMode && branch.showSmallPicture
          ? PICTURE_MODES.SMALL_PRODUCT_PICTURES
          : pictureMode;

      const basketProducts: {
        [key: string]: number;
      } = {};

      this.props.store.basketStore.products.forEach(
        (productItem: { count: number; product: ProductModel }) => {
          if (!basketProducts[productItem.product.id]) {
            basketProducts[productItem.product.id] = productItem.count;
          } else {
            basketProducts[productItem.product.id] += productItem.count;
          }
        },
        {}
      );

      const { id } = this.props.store.languagesStore.activeLanguage;
      const badgeStyle = this.props.store.themesStore.productBadgeStyle();

      const offsetTop = this.props.parentContainerNode
        ? this.props.parentContainerNode.getBoundingClientRect().top
        : 0;

      const basketBounds = {
        ...this.props.store.basketStore.bounds[states.basket][states.basket],
        offsetY: this.currentListOffset - scrollableListPaddingTop,
        top: this.props.store.basketStore.bounds[states.basket][states.basket]
          ? this.props.store.basketStore.bounds[states.basket][states.basket]
              .top - offsetTop
          : 0
      };

      const { additivesProducts } = this.props.store.additivesStore;
      const buttonStyle = this.props.store.themesStore.standardButtonStyle();
      const activeButtonStyle = this.props.store.themesStore.orderButtonStyle();
      const horizontalMenuProductStyle = this.props.store.themesStore.horizontalMenuProductStyle();

      return [
        headerComponent,
        ...category.products.map((product, productIndex) => {
          // All padding are from desings, which can't be set in CSS file
          const key = this.createProductKey(product.id);

          const customStyle: CSSProperties =
            index === 0 && productIndex === 0
              ? {
                  paddingTop: 30
                }
              : {};

          !this.elementsSizes[key] &&
            this.updateElementSize({
              key,
              size: this.ESTIMATED_CELL_SIZE,
              index: index + productIndex,
              header: false,
              categoryId: category.id,
              customStyle
            });

          //! KEYS ARE VERY IMPORTANT FOR CORRECT WORKING OF VIRTUAL LIST, NOT ONLY FOR REACT
          return (
            <ProductCell
              key={key}
              isShopClosed={isShopClosed}
              product={product}
              animatedId={animateProductId}
              basketBounds={basketBounds}
              styles={{
                newBadgeStyle: badgeStyle
              }}
              isMobile
              additivesProducts={additivesProducts}
              hideProductArticles={branch.isHideArticleNumbers}
              onCellClick={
                isUseAdvancedProductView
                  ? this._onProductClick
                  : this._onAddProductClick
              }
              onButtonClick={this._onAddProductClick}
              activeLanguage={id}
              isUseAdvancedProductView={isUseAdvancedProductView}
              showHalalCertificate={this.showHalalCertificate}
              fullProductListDesign
              picturesMode={pictureMode}
              isShell
              basketProducts={basketProducts}
              buttonStyle={buttonStyle}
              activeButtonStyle={activeButtonStyle}
              separatorStyle={separatorStyle()}
              horizontalMenuProductStyle={horizontalMenuProductStyle}
              currency={currencyCode}
              setSize={
                (size: number) =>
                  this.updateElementSize({
                    key,
                    size:
                      size +
                      (Number(customStyle.paddingTop) || 0) +
                      (Number(customStyle.paddingBottom) || 0),
                    index: index + productIndex,
                    header: false,
                    categoryId: category.id,
                    customStyle
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          );
        })
      ];
    }

    const { offers } = this.props.store.offerStore;
    const { getLang } = this.props.store.restaurantStore.restaurant;
    const { currentOrderTypeHasPreorder } = this.props.store.openingHoursStore;

    const offerCells: { key: string & ReactElement }[] = offers.map(
      (offer: OfferModel, offerIndex: number) => {
        const key = `offer-${offer.id}`;

        const customStyle =
          index === 0 && offerIndex === 0
            ? {
                paddingTop: 30
              }
            : {};

        !this.elementsSizes[key] &&
          this.updateElementSize({
            key,
            size: this.ESTIMATED_CELL_SIZE,
            index: index + offerIndex,
            header: false,
            categoryId: -1,
            customStyle
          });

        //! KEYS ARE VERY IMPORTANT FOR CORRECT WORKING OF VIRTUAL LIST, NOT ONLY FOR REACT
        return (
          <OfferCell
            key={key}
            offer={offer}
            onOfferClick={this._onOfferClick}
            currentOrderTypeHasPreorder={currentOrderTypeHasPreorder}
            currency={currencyCode}
            animateId={animateOfferId}
            isShell
            language={getLang}
            buttonProductPriceStyle={productPriceStyleButtonStyle()}
            separatorStyle={separatorStyle}
            setSize={
              (size: number) =>
                this.updateElementSize({
                  key,
                  size: size + (customStyle.paddingTop || 0),
                  index: index + offerIndex,
                  categoryId: -1,
                  header: false,
                  customStyle
                })
              // eslint-disable-next-line react/jsx-curly-newline
            }
          />
        );
      }
    );

    return [headerComponent, ...offerCells];
  };

  cellRenderer = ({
    index,
    style,
    isScrolling
  }: {
    index: number;
    style: CSSProperties;
    isScrolling: boolean;
  }) => {
    const elementKey = Object.keys(this.elementsSizes).find(
      (elementKey) => this.elementsSizes[elementKey].index === index
    );

    const customStyle = elementKey
      ? this.elementsSizes[elementKey].customStyle
      : {};

    // Using DOM methods for speed. Transitions through MobX is very slow and causes 'jumps' for user
    // It's for overlapping category menu above virtual list
    // It's done for simulating stopping of scrolling virtual list when a user taps on some category (changes for ios, but it works in chrome too)
    if (this.swiperWrapperRef.current) {
      this.swiperWrapperRef.current.style.pointerEvents = isScrolling
        ? 'none'
        : 'auto';
    }

    return (
      <div
        style={{
          ...style,
          ...customStyle
        }}
      >
        {this.productItems[index]}
      </div>
    );
  };

  transformCategoriesProducts = (categories: CategoryModel[]) => {
    const {
      restaurantStore,
      categoryMenuStore,
      themesStore,
      basketStore
    } = this.props.store;

    const { bounds } = basketStore;
    const { restaurant } = restaurantStore;
    const { clickedProductTopOffset = 0 } = themesStore;
    const { wholeProductListLoaded } = categoryMenuStore;
    const categoryItems: { key: string & ReactElement }[] = [];

    if (wholeProductListLoaded) {
      // Virtual list is based on indexes
      // All cells of it are recognised by indexes
      // So, we use it for correct calculating cell measures
      let index = -1;

      categories.forEach((cat) => {
        if (cat.hasSubCategories) {
          categoryItems.push(
            ...(cat.subCategories.map((subcat: CategoryModel) => {
              index++;

              const oldIndex = index;

              index +=
                cat.id === -1
                  ? this.props.store.offerStore.offers.length
                  : subcat.products.length;

              return this.renderCategoryBlock(subcat, oldIndex);
            }) as any)
          );
        } else {
          index++;

          const oldIndex = index;

          index +=
            cat.id === -1
              ? this.props.store.offerStore.offers.length
              : cat.products.length;

          categoryItems.push(this.renderCategoryBlock(cat, oldIndex) as any);
        }
      });

      this.productItems = flattenDeep(categoryItems);

      const getSize = (index: number) => {
        const { key } = this.productItems[index];

        let resultSize = this.elementsSizes[key]
          ? this.elementsSizes[key].size
          : this.ESTIMATED_CELL_SIZE;

        if (index + 1 === this.productItems.length) {
          resultSize += bounds[states.basket]?.[states.basket]?.height || 0;
        }

        return resultSize;
      };

      return (
        <List
          ref={this.listRef}
          outerRef={this.listWrapperRef}
          innerRef={this.listInnerRef}
          height={this.state.reactWindowContainerHeight}
          itemCount={this.productItems.length}
          itemSize={getSize}
          width="100%"
          onItemsRendered={this.onVirtualItemsRendered}
          onScroll={this.onScrollList}
          initialScrollOffset={clickedProductTopOffset}
          overscanCount={3}
          useIsScrolling
        >
          {this.cellRenderer}
        </List>
      );
    }

    return <SmallLoader loader={restaurant.loadingAnimation} />;
  };

  renderScrollToTopButton = () => {
    const templatingStyle = this.props.store.themesStore.headerStyle();

    const {
      hideBasketButtonHorizontalMenuShell
    } = this.props.store.themesStore;

    const buttonStyle = templatingStyle
      ? {
          background: templatingStyle.background,
          opacity: 0.5
        }
      : {};

    return (
      <div
        className={classNames({
          'category-scrollable-list-products-scroll-to-top-button': true,
          'bottom-position': hideBasketButtonHorizontalMenuShell,
          hidden: !this.state.showScrollToTopButton
        })}
        onClick={() => this.scrollContainerToTop()}
        style={buttonStyle}
      >
        <ReactSVG
          src={images.upIcon}
          beforeInjection={(svg) => {
            svg.setAttribute(
              'class',
              `${svg.getAttribute('class')} icon-up-arrow`
            );

            svg.setAttribute('style', 'width: 21px; height: 20px;');

            const arrowPath = svg.getElementsByTagName('path')[1];

            arrowPath &&
              arrowPath.setAttribute(
                'style',
                `fill: ${templatingStyle.color};`
              );
          }}
        />
      </div>
    );
  };

  render() {
    const { showDiscountBlock } = this.props;
    const { categoryMenuStore, productsStore, themesStore } = this.props.store;
    const { categories, wholeProductListLoaded } = categoryMenuStore;
    const { isSearchEnabled } = productsStore;
    const filteredCategories = categories.filter((cat: any) => cat.id !== -2);
    const templatingStyle = themesStore.wrapperStyle();

    if (isSearchEnabled) {
      return <ProductsShellWrapper />;
    }

    return (
      <div
        className={classNames('category-scrollable-list-container', {
          loading: !wholeProductListLoaded
        })}
      >
        <div
          ref={this.swiperWrapperRef}
          className="category-scrollable-list-swiper-wrapper"
          style={templatingStyle}
        >
          <SwiperSlider
            getSwiper={(swiper) => {
              this.headerSwiper = swiper;
            }}
            containerClass="category-scrollable-list-header-container"
            slideClass="category-scrollable-list-header-item"
            shouldSwiperUpdate
            {...this.swiperParams}
          >
            {this.renderTopListCategories(filteredCategories)}
          </SwiperSlider>
        </div>
        <div
          className={classNames('category-scrollable-list-products-container', {
            'discount-block': showDiscountBlock
          })}
        >
          {this.transformCategoriesProducts(filteredCategories)}
          {this.renderScrollToTopButton()}
        </div>
      </div>
    );
  }
}

export default withTranslation(['common'])(CategoryScrollableList);
