import axios from 'axios';

import {
  apiUrl,
  apiUrlVersion2,
  apiUrlVersion3_1,
  phpIntegrationUrl
} from 'config';

const api2Url = apiUrl + apiUrlVersion2;
const api3Url = apiUrl + apiUrlVersion3_1;
const javaDev = 'https://api.dev.ordersmart.app';

const api2 = axios.create({
  baseURL: api2Url
});

const api31 = axios.create({
  baseURL: api3Url
});

const apiPhpInt = axios.create({
  baseURL: phpIntegrationUrl
});

const apiJavaDev = axios.create({
  baseURL: javaDev
});

const setApiTokens = (token: string) => {
  api2.defaults.baseURL = `${api2Url}/${token}`;
};

export { api2, api31, apiPhpInt, apiJavaDev, setApiTokens };
