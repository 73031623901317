import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

/**
 * Wide button to display in buttons bar on homepage in case if there is only one button situation
 */
export default class WideHomepageButton extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    action: PropTypes.func,
    style: PropTypes.object
  };

  static defaultProps = {
    label: 'button',
    action: () => {},
    disabled: false
  };

  /**
   * Method to handle button click
   * @private
   */
  _onClickHandler = () => {
    if (!this.props.disabled) {
      this.props.action();
    }
  };

  _renderButton = () => {
    return (
      <button
        onClick={this._onClickHandler}
        disabled={this.props.disabled}
        className={classNames({
          'shell-wide-homepage-button': true,
          disabled: this.props.disabled
        })}
        style={this.props.style}
      >
        <div className={'icon mdi mdi-cart'}></div>
        <div className="label">{this.props.label}</div>
      </button>
    );
  };

  render() {
    return this._renderButton();
  }
}
