import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import AdditiveIconList from 'client/components/AdditiveIconList';
import images from 'client/enums/images_enums/hermes_images.enum';
import ProductStandardButton from 'client/themes/theme-hermes/components/buttons/ProductStandardButton/ProductStandardButton';
import ProductBadge from 'client/ui/ProductBadge';
import LazyImage from 'client/ui/ProductLazyImage';
import ProductNumber from 'client/ui/ProductNumber';
import Text from 'client/ui/Text';

import { ProductSmallPictureMobileProps } from './ProductSmallPictureMobile.type';

import 'client/components/ProductSmallPictureMobile/ProductSmallPictureMobile.scss';

const ProductSmallPictureMobile = ({
  productName,
  productNumber,
  productAdditives,
  productDescription,
  imageUrl,
  animated,
  animationTargetBounds,
  buttonStyle,
  disabled,
  isClosed,
  isShell,
  isSoldOut,
  mobileView,
  productCount,
  text,
  foodIcons,
  badgeTitle,
  imagesEnabled,
  showDiscountIcon,
  showMbvIcon,
  hideProductNumber,
  isNew = false,
  resizeCallback,
  onClick,
  onFoodIconClick,
  onProductInfoButtonClick
}: ProductSmallPictureMobileProps) => {
  const { t } = useTranslation(['product_view']);
  const showFoodIcons = !!foodIcons && !!Object.keys(foodIcons).length;

  const getBadge = () => {
    if (isNew) {
      return <ProductBadge className="badge" title={badgeTitle} />;
    }

    if (!imagesEnabled) {
      return null;
    }

    return <div className="badge" />;
  };

  const hideHeader = !isNew && !productNumber && !productAdditives;

  const handleButtonClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();

      onClick(event);
    },
    [onClick]
  );

  useEffect(() => {
    resizeCallback?.();
  }, [hideHeader]);

  return (
    <div className="ProductSmallPictureMobile" data-testid="product">
      {!hideHeader && (
        <div className="card-header">
          {getBadge()}
          {!hideProductNumber && productNumber && (
            <ProductNumber
              value={productNumber}
              className="product-title-number"
            />
          )}
        </div>
      )}
      <div className="card-content">
        {imagesEnabled && (
          <div className="product-image">
            <LazyImage
              url={imageUrl}
              altText={productName}
              dataTestId="product-image"
            />
          </div>
        )}
        <div className="product-description">
          <h4 data-testid="product-title">
            {showMbvIcon && (
              <ReactSVG
                src={images.iconWithoutMinOrder}
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    'class',
                    `${svg.getAttribute('class')} product-title-icon`
                  );

                  svg.setAttribute('style', 'width: 20px; height: 20px;');
                }}
              />
            )}
            {showDiscountIcon && (
              <ReactSVG
                src={images.iconWithoutDiscount}
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    'class',
                    `${svg.getAttribute('class')} product-title-icon`
                  );

                  svg.setAttribute('style', 'width: 20px; height: 20px;');
                }}
              />
            )}
            {productName}
          </h4>
          <button className="productInfo" onClick={onProductInfoButtonClick}>
            <Text data-testid="product-info">
              {t('product_view:productInfo')}
            </Text>
          </button>
          <div className="icons-desc">
            <div className="product-small-picture-button-wrapper">
              <ProductStandardButton
                onClick={handleButtonClick}
                text={text}
                animated={animated}
                productCount={productCount}
                animationTargetBounds={animationTargetBounds}
                isSoldOut={isSoldOut}
                disabled={disabled}
                mobileView={mobileView}
                isShell={isShell}
                isClosed={isClosed}
                buttonStyle={buttonStyle}
              />
            </div>
            {productDescription && (
              <p
                className="product-small-picture-description-block"
                data-testid="product-description"
              >
                {productDescription}
              </p>
            )}
            {foodIcons && showFoodIcons && (
              <div className="icons">
                <AdditiveIconList
                  additives={foodIcons}
                  onHalalClick={onFoodIconClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-unused-modules
export { ProductSmallPictureMobile };

export default React.memo(ProductSmallPictureMobile);
