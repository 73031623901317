import React, { memo } from 'react';

import useModal from 'client/hooks/useModal';
import LegalInfoDesktop from 'client/themes/theme-hermes/components/legal-info-page/desktop';
import Modal from 'client/ui/Modal';

import './PrivacyModal.scss';

const PrivacyModal = ({
  closeModal: _closeModal
}: {
  closeModal?: () => void;
}) => {
  const { closeModal } = useModal();

  return (
    <Modal onClose={closeModal} className="PrivacyModal">
      <div className="ant-modal">
        <div className="ant-modal-body">
          <LegalInfoDesktop
            pageName="privacy-policy"
            closeModal={_closeModal}
          />
        </div>
      </div>
    </Modal>
  );
};

export { PrivacyModal };

export default memo(PrivacyModal);
