import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import { ModalsRouteType } from 'client/routes/ModalsRoute/ModalsRoute.type';
import openModal from 'client/utils/openModal';

import './CheckoutTermsLink.scss';

@withRouter
class CheckoutTermsLink extends React.PureComponent {
  handleLinkClick = (event) => {
    event.stopPropagation();
  };

  render() {
    const { t } = this.props;

    return (
      <div className="terms-of-use">
        {t('order_payment_methods:onlineOrder')}
        &nbsp;
        {t('order_payment_methods:thereIs')}
        &nbsp;
        <span>
          <Link
            to={openModal(ModalsRouteType.TERMS_MODAL)}
            onClick={this.handleLinkClick}
            className="terms-link"
          >
            {t('order_payment_methods:basketTermsOfService')}
          </Link>
          &nbsp;
          {t('common:and')}
          &nbsp;
          <Link
            to={openModal(ModalsRouteType.PRIVACY_MODAL)}
            onClick={this.handleLinkClick}
            className="terms-link"
          >
            {t('footer:dsvgoTitle')}
          </Link>
          .
        </span>
      </div>
    );
  }
}

export default withTranslation(['order_payment_methods'])(CheckoutTermsLink);
