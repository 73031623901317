import { toJS } from 'mobx';

/**
 * Slide Link Type model
 */
export default class SliderLinkType {
  constructor(link) {
    Object.assign(this, link);
    this.type = link.type;
    this.value = link.value;
  }

  type = '';
  value = '';

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  getToJS() {
    return toJS(Object.assign({}, this, { root: {} }));
  }
}
