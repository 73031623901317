import { History } from 'history';
import { match } from 'react-router-dom';

import INativeNotification, {
  INativeNotificationContactData
} from 'client/models/native_notification.model';
import { IS_CLIENT } from 'config';

import handlers from '../enums/mobile_handlers.enum';
import { INativeAnalyticsEvent } from '../models/native_analytics_event';

/**
 * Go back in Hermes or go back in app depends on routes history stack
 */
export function goBackInHermes(
  history: History,
  routerMatch: match,
  isSlugSupported: string | undefined,
  backFunction: () => void = history.goBack
): void {
  const slug = isSlugSupported ? '/:slug/:branchId' : '/:branchId';
  const isStartPage = routerMatch.path === slug;

  const isModalOpen =
    IS_CLIENT && new URLSearchParams(window.location.search).get('modal');

  if (isStartPage && !isModalOpen) {
    goBack();
  } else {
    backFunction();
  }
}

/**
 * Send back action to native application
 */
export function goBack(): void {
  const message: INativeNotification = { name: handlers.BACK };

  sendNotificationToApple(message);

  sendNotificationToAndroid(message);
}

export function share(message: INativeNotification): void {
  sendNotificationToApple(message);

  if (message.data?.link) {
    sendShareNotificationToAndroid(message.data?.link);
  }
}

export function getFavoritesState(branchId: string) {
  const data: INativeNotification = {
    name: handlers.GET_FAVORITES,
    data: {
      branchId
    }
  };

  sendNotificationToApple(data);

  sendNotificationToAndroid(data);
}

export function setFavoritesState(branchId: string, state: boolean) {
  const data: INativeNotification = {
    name: handlers.SET_FAVORITES,
    data: {
      branchId,
      addToFavorites: state
    }
  };

  sendNotificationToApple(data);

  sendNotificationToAndroid(data);
}

export function setContactDataApp(data: INativeNotificationContactData) {
  const notificationData: INativeNotification = {
    name: handlers.SET_CONTACT_DATA,
    data
  };

  sendNotificationToApple(notificationData);

  sendNotificationToAndroid(notificationData);
}

export function getHungerAppData() {
  const data: INativeNotification = {
    name: handlers.GET_HUNGER_APP_DATA
  };

  sendNotificationToApple(data);

  sendNotificationToAndroid(data);
}

function sendNotificationToAndroid(message: INativeNotification): void {
  try {
    // NB! Keep this random name
    (window as any).shell_jdskfndhs.share(JSON.stringify(message));
  } catch (e) {
    console.info('Android not found');
  }
}

/**
 * Legacy method for shell apps
 * @param message
 */
function sendShareNotificationToAndroid(message: string) {
  try {
    (window as any).shell_jdskfndhs.share(message);
  } catch (e) {
    console.info('Android not found');
  }
}

function sendNotificationToApple(message: INativeNotification): void {
  try {
    (window as any).webkit.messageHandlers.notification.postMessage(message);
  } catch (e) {
    console.info('Webkit object not found');
  }
}

export function sendAnalyticsEvent(event: INativeAnalyticsEvent) {
  const data: INativeNotification = {
    name: handlers.ANALYTICS_EVENT,
    data: event
  };

  sendNotificationToApple(data);

  sendNotificationToAndroid(data);
}
