import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './styles.scss';

export default class BasketControlButtonShell extends Component {
  constructor(props) {
    super(props);
  }

  _clickHandler = () => {
    if (!this.props.disabled) {
      this.props.action();
    }
  };

  static propTypes = {
    disabled: PropTypes.bool,
    colored: PropTypes.bool,
    black: PropTypes.bool,
    mdiClass: PropTypes.string,
    label: PropTypes.string,
    action: PropTypes.func.isRequired,
    style: PropTypes.object,
    dataTestId: PropTypes.string
  };

  static defaultProps = {};

  render() {
    return (
      <button
        className={classNames(
          {
            'shell-basket-control-button': true,
            mdi: true,
            disabled: this.props.disabled,
            colored: this.props.colored,
            black: this.props.black
          },
          this.props.mdiClass
        )}
        onClick={this._clickHandler}
        style={this.props.style}
        data-testid={this.props.dataTestId}
      >
        {this.props.label}
      </button>
    );
  }
}
