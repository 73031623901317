import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import { filterPrice } from '../../../../utils/functions';
import ButtonShoppingCartAnimated from '../buttons/ButtonShoppingCartAnimated';

import { generateLinkFor } from '../../../../utils/routing';
import states from '../../../../enums/states.enum';

/**
 * Shopping cart panel component is used to show basket preview on mobile devices.
 * @name ReactShoppingCartPanelComponent
 */
@withRouter
@inject('store')
@observer
export default class ShoppingCartPanelComponent extends Component {
  /**
   * Action to open basket
   * @private
   */
  _openBasketAction = () => {
    this.props.store.productsStore.clearSearchingRequest();
    this.props.history.push(
      generateLinkFor(states.basket, this.props, {}, true)
    );
  };

  render() {
    if (!this.props.store.restaurantStore.branch.id) {
      return null;
    }
    const shoppingCartLabel = this.props.store.basketStore.hasProducts
      ? this.props.store.basketStore.getIntlPrice(
          this.props.store.basketStore.finalPriceWithOffers
        )
      : this.props.store.basketStore.getIntlPrice(filterPrice(0));

    return (
      <ButtonShoppingCartAnimated
        openBasketAction={this._openBasketAction}
        label={shoppingCartLabel}
      />
    );
  }
}
