import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import { MobileModalsRouteType } from 'client/routes/MobileModalsRoute/MobileModalsRoute.type';
import { getUtmParamsFromSearch } from 'client/utils/helpers';
import openModal from 'client/utils/openModal';

import states from '../../../../../../enums/states.enum';
import { generateLinkFor } from '../../../../../../utils/routing';
import LanguageSwitcherMobile from '../LanguageSwitcherMobile';

import './styles.scss';

/**
 * Component to render mobile navigation
 */
@withRouter
@inject('store')
@observer
class NavigationMenu extends PureComponent {
  static propTypes = {
    setDefaultCategory: PropTypes.func.isRequired,
    isTableReservationAvailable: PropTypes.bool.isRequired,
    isHideMarketingData: PropTypes.bool.isRequired,
    closeAction: PropTypes.func.isRequired
  };

  static defaultProps = {};

  /**
   * Action on home menu item
   * @private
   */
  _homeClick = () => {
    this.props.setDefaultCategory();

    const utmParams = getUtmParamsFromSearch(this.props.location.search);

    this.props.history.push(
      generateLinkFor(
        states.app,
        this.props,
        { modal: null, ...utmParams },
        true
      )
    );
  };

  /**
   * Action on menu item
   * @private
   */
  handleLinkClick = (event) => {
    event.stopPropagation();

    const { themesStore } = this.props.store;

    themesStore.setHeaderIsCollapsed(!themesStore.getHeaderIsCollapsed);
  };

  /**
   * Method to render menu item
   * @param {states} state - state to open
   * @param {string} title - title of menu
   * @param {boolean} withBranch - if new route has branch
   * @returns {*}
   * @private
   */
  _addMenuItemWithLink = (state, title, withBranch = true) => {
    const utmParams = getUtmParamsFromSearch(this.props.location.search);

    return (
      <Link
        className="menu-block-item mobile-title-menu-item"
        to={generateLinkFor(
          state,
          this.props,
          { modal: null, ...utmParams },
          withBranch
        )}
      >
        {title}
      </Link>
    );
  };

  render() {
    const { isHunger } = this.props.store.themesStore;
    const { isTableReservationAvailable, isHideMarketingData } = this.props;
    const { isHomepageAvailable } = this.props.store.restaurantStore.branch;
    const { showPrivacyPolicy } = this.props.store.restaurantStore;
    const allergensUrl = openModal(MobileModalsRouteType.ALLERGENS_MODAL);
    const contactFormUrl = openModal(MobileModalsRouteType.CONTACT_MODAL);
    const imprintUrl = openModal(MobileModalsRouteType.IMPRINT_MODAL);
    const privacyUrl = openModal(MobileModalsRouteType.PRIVACY_MODAL);

    const tableReservationUrl = openModal(
      MobileModalsRouteType.TABLE_RESERVATION
    );

    const termsUrl = openModal(MobileModalsRouteType.TERMS_MODAL);

    return (
      <div
        className="mobile-title-menu menu-expanded"
        onClick={this.props.closeAction}
        onTouchMove={(e) => {
          e.preventDefault();
        }}
      >
        <div className="mobile-title-menu-block">
          <div
            className="menu-block-item mobile-title-menu-item"
            onClick={this._homeClick}
          >
            {this.props.t('common:lblHomePage')}
          </div>
          {!isHunger &&
            this._addMenuItemWithLink(
              states.branchList,
              this.props.t('title:branchSelection')
            )}
          {isHomepageAvailable
            ? this._addMenuItemWithLink(
                states.kundeninformation,
                this.props.t('title:lblKundeninformation')
              )
            : null}
          {isTableReservationAvailable && (
            <Link
              onClick={this.handleLinkClick}
              className="menu-block-item mobile-title-menu-item"
              to={tableReservationUrl}
            >
              {this.props.t('footer:btnTableReservation')}
            </Link>
          )}
          <Link
            onClick={this.handleLinkClick}
            className="menu-block-item mobile-title-menu-item"
            to={allergensUrl}
          >
            {this.props.t('title:allergensAdditives')}
          </Link>
          {this._addMenuItemWithLink(
            states.openingTimes,
            this.props.t('opening_hours:lblWorkingHours')
          )}
        </div>

        <div className="mobile-title-menu-block">
          <LanguageSwitcherMobile className="menu-block-item mobile-title-menu-item" />
          {!isHunger && (
            <Link
              onClick={this.handleLinkClick}
              className="menu-block-item mobile-title-menu-item"
              to={termsUrl}
            >
              {this.props.t('order_payment_methods:termsOfService')}
            </Link>
          )}
          <Link
            onClick={this.handleLinkClick}
            className="menu-block-item mobile-title-menu-item"
            to={imprintUrl}
          >
            {this.props.t('footer:legalInfo')}
          </Link>
          <Link
            onClick={this.handleLinkClick}
            className="menu-block-item mobile-title-menu-item"
            to={contactFormUrl}
          >
            {this.props.t('footer:contactUs')}
          </Link>
          {showPrivacyPolicy ? (
            <Link
              onClick={this.handleLinkClick}
              className="menu-block-item mobile-title-menu-item"
              to={privacyUrl}
            >
              {this.props.t('footer:dsvgoTitle')}
            </Link>
          ) : null}
        </div>
        {!isHideMarketingData && !isHunger && (
          <div className="mobile-title-menu-block">
            <a
              className="menu-block-item mobile-title-menu-item"
              href="https://ordersmart.de"
              target="blank"
              onClick={this.props.closeAction}
            >
              {this.props.t('title:ordersmartShopsystem')}
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(['footer', 'order_payment_methods', 'title'])(
  NavigationMenu
);
