import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import AddressChangeConfirmationModal from 'client/components/AddressChangeConfirmationModal';
import { DeliveryPlFormData } from 'client/components/DeliveryFormPl';
import GoogleAddress from 'client/components/GoogleAddress';
import Input from 'client/components/Input';
import TextArea from 'client/components/TextArea';
import googleService, { GeocoderResult } from 'client/services/googleService';
import Button from 'client/ui/Button';
import ButtonGroup from 'client/ui/ButtonGroup';
import {
  createAdvancedDeliveryDeFormValidation,
  rules
} from 'client/utils/formValidation';
import getValidFormData from 'client/utils/getValidFormData';

import css from './AdvancedDeliveryDeForm.module.scss';
import {
  AdvancedDeliveryDeFormData,
  AdvancedDeliveryDeFormProps
} from './AdvancedDeliveryDeForm.type';

const AdvancedDeliveryDeForm = ({
  branchId,
  className,
  defaultValues,
  lang,
  onSubmit,
  isMobile,
  t
}: AdvancedDeliveryDeFormProps) => {
  const {
    register,
    handleSubmit,
    formState,
    getValues,
    setValue,
    reset
  } = useForm<AdvancedDeliveryDeFormData>({
    defaultValues,
    context: {
      lang,
      branchId
    },
    resolver: yupResolver(createAdvancedDeliveryDeFormValidation()),
    mode: 'onTouched'
  });

  const [googleAddressKey, setGoogleAddressKey] = useState(Math.random());
  const history = useHistory();
  const { errors } = formState;

  const getDefaultGoogleAddress = () => {
    const addressValue = googleService.getAddressFromGeoInfo(
      defaultValues.googleAddress
    );

    return `${addressValue.city}, ${addressValue.street} ${addressValue.streetNumber}`;
  };

  const handleGoogleChange = useCallback(
    (option?: GeocoderResult) => {
      setValue('googleAddress', option, {
        shouldValidate: true,
        shouldDirty: true
      });
    },
    [setValue]
  );

  const redirectTo = useCallback(
    (path: string) => {
      const { pathname } = history.location;
      const newPath = pathname.replace('address', path);

      history.replace(newPath);
    },
    [history]
  );

  const handleModalCancelClick = useCallback(() => {
    reset({ googleAddress: defaultValues.googleAddress });

    setGoogleAddressKey(Math.random());
  }, [defaultValues, reset]);

  const handleConfirmClick = useCallback(() => {
    const data = getValues();

    onSubmit(data);

    redirectTo('basket');
  }, [getValues, onSubmit, redirectTo]);

  const handleFormSubmit = (data: DeliveryPlFormData) => {
    onSubmit(data);

    redirectTo('checkout');
  };

  const handleCancelClick = useCallback(() => {
    const data = getValues();
    const validData = getValidFormData(data, errors, defaultValues);

    onSubmit(validData);

    redirectTo('basket');
  }, [defaultValues, errors, getValues, onSubmit, redirectTo]);

  const showModal =
    !formState.isValidating &&
    !(errors.googleAddress as any)?.message &&
    !!formState.dirtyFields.googleAddress;

  useEffect(() => {
    register('googleAddress');
  });

  // when the submit action is triggered
  // the validation function gets an undefined value
  // for googleAddress, however it is passed in defaultValue prop
  // the only solution I see is calling setValue with googleAddress
  // from defaultValues
  useEffect(() => {
    setValue('googleAddress', defaultValues.googleAddress);
  }, []);

  return (
    <>
      <form
        className={classnames(css.AdvancedDeliveryDeForm, className)}
        onSubmit={handleSubmit(handleFormSubmit)}
        data-testid="DeliveryFormDe"
      >
        <Input
          {...register('firstName')}
          autoComplete="given-name"
          placeholder={t('address_form:firstName')}
          error={errors.firstName?.message}
          pattern={rules.firstNameValidChars}
          dataTestId="name-input-basket"
        />
        <Input
          {...register('lastName')}
          autoComplete="family-name"
          placeholder={t('address_form:lastName')}
          error={errors.lastName?.message}
          pattern={rules.lastNameValidChars}
          dataTestId="sec-name-input-basket"
        />
        <Input
          {...register('company')}
          placeholder={t('address_form:company')}
          error={errors.company?.message}
          pattern={rules.companyValidChars}
          dataTestId="firm-input-basket"
        />
        <GoogleAddress
          key={googleAddressKey}
          onChange={handleGoogleChange}
          defaultValue={getDefaultGoogleAddress()}
          error={(errors.googleAddress as any)?.message}
          isValidating={formState.isValidating}
          placeholder={`${t('address_form:city')}, ${t(
            'address_form:street'
          )}, ${t('address_form:number')}`}
          dataTestId="google-select-basket"
        />
        <Input
          {...register('email')}
          autoComplete="email"
          placeholder={t('address_form:emailAddress')}
          error={errors.email?.message}
          pattern={rules.emailValidChars}
          dataTestId="email-input-basket"
        />
        <Input
          {...register('phone')}
          autoComplete="tel"
          placeholder={t('address_form:phone')}
          error={errors.phone?.message}
          pattern={rules.phoneValidChars}
          valuePattern={rules.phoneValidValue}
          inputMode={isMobile ? 'tel' : 'text'}
          dataTestId="phone-input-basket"
        />
        <TextArea
          {...register('comment')}
          placeholder={t('address_form:massage')}
          error={errors.comment?.message}
          pattern={rules.commentValidChars}
          dataTestId="comment-input-basket"
        />
        <ButtonGroup vertical>
          <Button onClick={handleCancelClick} dataTestId="back-btn-basket">
            {t('address_form:cmnBack')}
          </Button>
          <Button type="submit" variant="submit" dataTestId="basket-order-btn">
            {t('address_form:checkout')}
          </Button>
        </ButtonGroup>
      </form>
      {showModal && (
        <AddressChangeConfirmationModal
          onCancel={handleModalCancelClick}
          onConfirm={handleConfirmClick}
        />
      )}
    </>
  );
};

export { AdvancedDeliveryDeForm };

export default memo(AdvancedDeliveryDeForm);
