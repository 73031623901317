import L from 'react-loadable';

const Loadable = (opts) =>
  L({
    loading() {
      return null;
    },
    delay: 60,
    ...opts
  });

export default Loadable;
