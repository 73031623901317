import React, { useEffect, useRef } from 'react';

import { ClearIndicatorProps } from './ClearIndicator.props';

const ClearIndicator = ({
  onClick,
  onOutsideClick,
  style
}: ClearIndicatorProps) => {
  const ref = useRef<SVGSVGElement | null>(null);

  const handleClick = (event: MouseEvent | TouchEvent) => {
    const path = event.composedPath();

    if (!path.includes(ref.current as EventTarget)) {
      onOutsideClick();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <svg
      ref={ref}
      style={style}
      onClick={onClick}
      onTouchEnd={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                d="M2 2H16V16H2z"
                transform="translate(-803 -426) translate(425 413) translate(376 11)"
              />
              <path
                fill="#BFBFBF"
                fillRule="nonzero"
                d="M9.071 3.071c.237 0 .429.192.429.429v5.142h5.142c.237 0 .43.192.43.43 0 .236-.193.428-.43.428H9.5v5.142c0 .237-.191.43-.428.43s-.429-.193-.429-.43V9.5H3.5c-.237 0-.429-.191-.429-.428s.192-.429.429-.429h5.142V3.5c0-.237.192-.429.43-.429z"
                transform="translate(-803 -426) translate(425 413) translate(376 11) rotate(45 9.071 9.071)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default React.memo(ClearIndicator);
