import React from 'react';
import { useTranslation } from 'react-i18next';

import AdditiveIconListFC from './AdditiveIconList';
import { AdditiveIconListProps } from './AdditiveIconList.type';

type Props = Omit<AdditiveIconListProps, 't' | 'language'>;

const AdditiveListIconContainer = (props: Props) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language as AppLang;

  return <AdditiveIconListFC {...props} t={t} language={currentLanguage} />;
};

export default AdditiveListIconContainer;
