import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import states from 'client/enums/states.enum';

import {
  cantAddProductsToBasket,
  generateLinkFor
} from '../../../../../utils/routing';
import SmallLoader from '../../loaders/small_loader';
import ProductListCategoryHeader from '../../products_list/common/header';
import { ProductsShellWrapper } from '../../products_list/shell';

import HermesThemeOfferList from './_section/OffersListHermesTheme';

import './OffersList.scss';

/**
 * Products view component is used to show products list of active category on mobile devices.
 * @name ReactOfferListComponent
 */
@withRouter
@inject('store')
@observer
export default class OfferListComponent extends Component {
  /**
   * Method to add offer to basket
   * @param offer
   */
  onOfferClick = (offer) => {
    const { store, history } = this.props;
    const { offerStore, deliveryAddressStore, themesStore } = store;
    const isShell = themesStore.isShellThemeActive;

    const shouldRedirect =
      isShell &&
      !deliveryAddressStore.branchSelectedStatus &&
      !deliveryAddressStore.isDelivery;

    if (shouldRedirect) {
      history.push(generateLinkFor(states.branchList, this.props, {}, true));

      return;
    }

    offerStore.addOffer(offer.id, offer.offerType, this.props.history);
  };

  _renderData() {
    const {
      activeCategory,
      activeSubcategory,
      isHomepageActive
    } = this.props.store.categoryMenuStore;

    const { currentOrderTypeHasPreorder } = this.props.store.openingHoursStore;
    const { sortedOffersList, animateId } = this.props.store.offerStore;
    const { currencyCode } = this.props.store.restaurantStore.branch;

    const {
      isShellThemeActive,
      categoryNameStyle,
      categoryTitleStyle,
      isMobile
    } = this.props.store.themesStore;

    const { getLang } = this.props.store.restaurantStore.restaurant;
    const isDisabled = cantAddProductsToBasket(this.props);

    return (
      <div
        id="main-offer-wrapper"
        className={classNames({
          shell: isShellThemeActive
        })}
      >
        <div className="tablet-and-phone">
          {!isHomepageActive && (
            <ProductListCategoryHeader
              activeSubcategory={activeSubcategory || activeCategory}
              activeCategory={activeCategory}
              sub={!!activeSubcategory}
              hasCategoryImage
              isMobile={isMobile}
              isShellThemeActive={isShellThemeActive}
              style={categoryNameStyle()}
              categoryTitleStyle={categoryTitleStyle()}
            />
          )}
        </div>

        <div className="desktop-only">
          <ProductListCategoryHeader
            activeSubcategory={activeCategory}
            activeCategory={activeCategory}
            hasCategoryImage
            sub={false}
            isShellThemeActive={isShellThemeActive}
          />
        </div>

        <HermesThemeOfferList
          language={getLang}
          currentOrderTypeHasPreorder={currentOrderTypeHasPreorder}
          offers={sortedOffersList}
          currency={currencyCode}
          animateId={animateId}
          onOfferClick={this.onOfferClick}
          isShell={isShellThemeActive}
          buttonProductPriceStyle={this.props.store.themesStore.productPriceStyleButtonStyle()}
          separatorStyle={this.props.store.themesStore.separatorStyle}
        />
      </div>
    );
  }

  render() {
    const { restaurantStore, productsStore } = this.props.store;
    const { isSearchEnabled } = productsStore;
    const { loadingAnimation } = restaurantStore.restaurant;

    if (isSearchEnabled) {
      return <ProductsShellWrapper />;
    }

    return this.props.store.categoryMenuStore.activeCategory.loading ? (
      <SmallLoader loader={loadingAnimation} />
    ) : (
      this._renderData()
    );
  }
}
