import NEGATIVE_EXTRAS_PRICING_MODELS from 'client/enums/negative_extras_price_models.enum';
import SessionStorage from 'client/modules/storage/session_storage';
import RootStore from 'client/stores';
import { OrderSource } from 'client/stores/orderPaymentMethods/orderPaymentMethods.store.type';

import { version } from '../../config';
import { PLATFORM } from '../enums/platform.enum';
import { roundTo2Decimals } from '../utils/functions';

type UtmData = {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
};

type GoogleAdsData = {
  channel: string;
  platform: string;
  rpid: string;
  type: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
};

/**
 * Order Request Model class.
 * Used for prepare basket to the backend request.
 * @constructor
 * @property {number} payment_method - active payment method id.
 * @property {number} branch_id - current branch id.
 * @property {boolean} pickup - is order type is selfcollect.
 * @property {boolean} delivery_type - active delivery type.
 * @property {boolean} [hunger_de = 1] - does the order came form hunger site
 * @property {number} discount - total common discount price
 * @property {string} lang - active language key
 * @property {number} displayedPrice - displayed price in the basket
 * @property {number} payment - displayed price in the basket
 * @property {array} b - generated basket parameters. See {@link BasketRequestModel}
 * @property {boolean} [web_version = 1] - is website or mobile app
 * @property {number} n2 - if order type is delivery this value represents delivery fee and must be multiplied by 666
 * @property {object} delivery_time - delivery time in unix format
 * @property {object} selfcollect_time - selfcollect time in unix format
 * @property {string} first_name - customer first name
 * @property {string} last_name - customer last name
 * @property {string} zip - customer zip code
 * @property {string} company - customer company
 * @property {string} email - customer email
 * @property {string} phone - customer phone
 * @property {string} street - customer street
 * @property {string} city - customer city name
 * @property {string} street_no - customer street_no
 * @property {string} floor - customer floor
 * @property {string} comment - custom customer comments
 * @property {string} cpn - coupon code
 * @property {string} appversion - Mobile App version (for Patrice's analytics)
 * @property {boolean} allowContactMe - Allow receiving marketing info
 * @property {boolean} [isHungerFavorites] - That's true if restaurant added to favorites (for Hunger only)
 */
export class OrderRequestModel {
  utm: UtmData;

  googleAdsData: GoogleAdsData;

  constructor(stores: RootStore) {
    const { themesStore } = stores;

    this.payment_method =
      stores.orderPaymentMethodsStore.activePaymentMethod.id;

    // Replace payment provider from Paypal to Payco
    if (
      stores.orderPaymentMethodsStore.activePaymentMethod.id === 5 &&
      stores.orderPaymentMethodsStore.replacePaypalToCrefopay &&
      !stores.themesStore.isHunger
    ) {
      this.payment_method = 3;
    }

    this.branch_id = stores.restaurantStore.branch.branchId;

    this.pickup = !stores.deliveryAddressStore.isDelivery;

    this.delivery_type = stores.deliveryAddressStore.activeOrderType.value;

    this.discount = stores.basketStore.discountPercentage;

    this.lang = stores.languagesStore.activeLanguage;

    this.front_transaction_uuid = stores.orderPaymentMethodsStore.orderId;

    this.displayedPrice = roundTo2Decimals(
      stores.basketStore.finalPriceWithOffers
    );

    this.payment = roundTo2Decimals(stores.basketStore.finalPriceWithOffers);

    this.b = stores.basketStore.basketToBackendRequest();

    this.web_version = !stores.themesStore.isShellThemeActive
      ? version
      : undefined;

    this.n2 = stores.deliveryAddressStore.isDelivery
      ? stores.basketStore.deliveryFee * 666
      : 0;

    this.cpn =
      stores.couponsStore.isCouponValid && stores.couponsStore.couponCode
        ? Array.of(stores.couponsStore.couponCode)
        : null;

    this.allowContactMe =
      stores.orderPaymentMethodsStore.allowReceiveMarketingInfo;

    // Hack for hunger android app
    const platform =
      stores.themesStore.platform === PLATFORM.PORTAL_APP
        ? PLATFORM.PORTAL_WEB
        : stores.themesStore.platform;

    this.platform = platform || PLATFORM.WEB;

    this.appversion = stores.themesStore.isShellThemeActive
      ? version
      : undefined;

    Object.assign(this, stores.deliveryAddressStore.toBackendRequest());

    this.comment = stores.deliveryAddressStore.getAdditionalFieldsData();

    this.utm = {
      utm_source:
        SessionStorage.getItem('utmSource') ??
        SessionStorage.getItem('platform') ??
        undefined,
      utm_medium:
        SessionStorage.getItem('utmMedium') ??
        SessionStorage.getItem('channel') ??
        undefined,
      utm_campaign:
        SessionStorage.getItem('utmCampaign') ??
        SessionStorage.getItem('type') ??
        undefined,
      utm_term:
        SessionStorage.getItem('utmTerm') ??
        SessionStorage.getItem('rpid') ??
        undefined,
      utm_content: SessionStorage.getItem('utmContent') ?? undefined
    };

    this.googleAdsData = {
      channel: SessionStorage.getItem('channel') ?? undefined,
      platform: SessionStorage.getItem('platform') ?? undefined,
      rpid: SessionStorage.getItem('rpid') ?? undefined,
      type: SessionStorage.getItem('type') ?? undefined,
      utm_source: SessionStorage.getItem('utmSource') ?? undefined,
      utm_medium: SessionStorage.getItem('utmMedium') ?? undefined,
      utm_campaign: SessionStorage.getItem('utmCampaign') ?? undefined,
      utm_term: SessionStorage.getItem('utmTerm') ?? undefined,
      utm_content: SessionStorage.getItem('utmContent') ?? undefined
    };

    this.hunger_type = stores.basketStore.useHungerDiscount
      ? 'hunger'
      : 'hermes';

    this.isHungerFavorites = Number(stores.favoritesStore.isAddedToFavorites);

    this.hunger = themesStore.isPortalWebThemeActive;

    this.negativeExtrasPricingModel =
      stores.restaurantStore.branch.getNegativeExtrasPricingModel;

    let orderSourcePlatform;

    const { hungerData } = stores.orderPaymentMethodsStore;

    if (hungerData?.device) {
      orderSourcePlatform = hungerData?.device;
    } else if (themesStore.isShellThemeActive) {
      orderSourcePlatform = themesStore.platform;
    } else {
      orderSourcePlatform = themesStore.isMobile ? 'web_mobile' : 'web_desktop';
    }

    this.orderSource = {
      product: themesStore.isHunger ? 'hunger' : 'hermes',
      subproduct: hungerData?.hungerBrand || 'hermes',
      platform: orderSourcePlatform
    };
  }

  payment_method;

  branch_id;

  pickup;

  delivery_type;

  discount;

  lang;

  displayedPrice;

  payment;

  b;

  web_version;

  n2 = 0;

  delivery_time;

  selfcollect_time;

  first_name;

  last_name;

  zip;

  city;

  company;

  email;

  phone;

  street;

  street_no;

  floor;

  comment;

  hunger_de = 0;

  cpn;

  allowContactMe;

  platform = PLATFORM.WEB;

  front_transaction_uuid;

  appversion;

  hunger_type: 'hunger' | 'hermes' = 'hermes';

  isHungerFavorites;

  negativeExtrasPricingModel: NEGATIVE_EXTRAS_PRICING_MODELS;

  orderSource: OrderSource;
}
