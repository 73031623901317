import React, { memo } from 'react';

import AdvancedDeliveryForm from 'client/components/AdvancedDeliveryForm';
import DeliveryForm from 'client/components/DeliveryForm';
import PickUpForm from 'client/components/PickUpForm';

// @ts-ignore
import css from './ShippingFormsController.module.scss';
import { ShippingFormsControllerProps } from './ShippingFormsController.type';

const ShippingFormsController = ({
  selectedDeliveryType,
  isAdvancedDelivery
}: ShippingFormsControllerProps) => {
  const getShippingForm = () => {
    if (selectedDeliveryType?.value === 'selfcollect') {
      return <PickUpForm />;
    }

    if (isAdvancedDelivery) {
      return <AdvancedDeliveryForm />;
    }

    return <DeliveryForm />;
  };

  return <div className={css.ShippingFormsController}>{getShippingForm()}</div>;
};

export { ShippingFormsController };

export default memo(ShippingFormsController);
