import React, { memo, useContext } from 'react';

import { RootContext } from 'client/stores';

import StreetAutocompleteFC from './StreetAutocomplete';
import { StreetAutocompleteProps } from './StreetAutocomplete.type';

const StreetAutocomplete = (
  props: Omit<StreetAutocompleteProps, 'country'>
) => {
  const { restaurantStore } = useContext(RootContext);
  const country = restaurantStore.restaurant.countryCode?.toLowerCase();

  return <StreetAutocompleteFC {...props} country={country} />;
};

export default memo(StreetAutocomplete);
