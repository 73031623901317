import classNames from 'classnames';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { IDay } from 'client/models/day.model';

import { ELEMENTS_TYPES } from '../../../../../enums/homepage_elements_types.enum';
import { createHomepageHrefLink } from '../../../../../utils/functions';
import { generateWorktimeForDay, getDayShort } from '../../../../../utils/time';

import './styles.scss';

interface IHomepageOpeningHours extends WithTranslation {
  deliverySchedule: IDay[][];
  pickupSchedule: IDay[][];
  index: number;
  isMobile?: boolean;
}

class HomepageOpeningHours extends Component<IHomepageOpeningHours> {
  renderSchedule = (isDelivery: boolean) => {
    const schedule = isDelivery
      ? this.props.deliverySchedule
      : this.props.pickupSchedule;

    return (
      <div className="homepage-opening-hours-schedule">
        <h2>
          {isDelivery ? (
            <span className="schedule-header">
              <i className="fas fa-motorcycle" />
              {this.props.t?.('deliveryHours')}
            </span>
          ) : (
            <span className="schedule-header">
              <i className="fas fa-clock" />
              {this.props.t?.('openingHours')}
            </span>
          )}
        </h2>
        <div className="schedule-days">
          {schedule.map((sameDays) => {
            const day = sameDays[0];

            return (
              <div className="times" key={day.day}>
                {sameDays.map((day, index) => (
                  <span key={day.day}>
                    {getDayShort(day.day) +
                      (index === sameDays.length - 1 ? ': ' : ', ')}
                  </span>
                ))}
                {generateWorktimeForDay(day)}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    const { deliverySchedule, pickupSchedule, isMobile } = this.props;

    return (
      <section
        className={classNames({
          'homepage-opening-hours-container': true,
          'desktop-homepage-section': !isMobile,
          'mobile-homepage-section': isMobile
        })}
        id={createHomepageHrefLink([
          ELEMENTS_TYPES.OPENING_TIMES,
          this.props.index
        ])}
      >
        {deliverySchedule.length ? this.renderSchedule(true) : null}
        {pickupSchedule.length ? this.renderSchedule(false) : null}
      </section>
    );
  }
}

export default withTranslation(['opening_hours'])(HomepageOpeningHours);
