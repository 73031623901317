import React, { memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootContext } from 'client/stores';

import DeliveryFormFC from './DeliveryFormPl';
import { DeliveryPlFormData } from './DeliveryFormPl.type';

const DeliveryFormPl = () => {
  const { t, i18n } = useTranslation(['address_form', 'validationErrors']);

  const { restaurantStore, deliveryAddressStore, analyticsStore, themesStore } = useContext(
    RootContext
  );

  const { address } = deliveryAddressStore;
  const zipCodes = restaurantStore.branch.areaCodes || [];

  const useStreetAutocomplete = !!(restaurantStore.branch as any)
    .isAutocomplete;

  const currentLocation = zipCodes.find(
    ({ areaCode, sublocality }) =>
      areaCode === address.zip && sublocality === address.city
  );

  const defaultValues: DeliveryPlFormData = {
    firstName: address.first_name,
    lastName: address.last_name,
    zip: currentLocation,
    company: address.company,
    email: address.email,
    phone: address.phone,
    street: address.street,
    houseNumber: address.street_no,
    apartment: address.apartment_num,
    staircase: address.staircase,
    floor: address.floor,
    comment: address.comment
  };

  const handleSubmit = useCallback(
    (formData: DeliveryPlFormData) => {
      const address = {
        first_name: formData.firstName.trim(),
        last_name: formData.lastName.trim(),
        zip: formData.zip?.areaCode.trim(),
        company: formData.company.trim(),
        email: formData.email.trim(),
        phone: formData.phone.trim(),
        street: formData.street.trim(),
        street_no: formData.houseNumber.trim(),
        apartment_num: formData.apartment.trim(),
        staircase: formData.staircase.trim(),
        floor: formData.floor.trim(),
        city: formData.zip?.sublocality.trim(),
        comment: formData.comment.trim()
      };

      deliveryAddressStore.updateAddress(address);

      deliveryAddressStore.onZipChange(formData.zip);

      analyticsStore.sendProvidePersonalInformation();
    },
    [deliveryAddressStore]
  );

  return (
    <DeliveryFormFC
      lang={i18n.language as AppLang}
      t={t}
      zipCodes={zipCodes}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      useStreetAutocomplete={useStreetAutocomplete}
      isMobile={themesStore.isMobile}
    />
  );
};

export default memo(DeliveryFormPl);
