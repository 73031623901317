import fetch from 'isomorphic-fetch';

import {
  apiUrl,
  configApi,
  isProduction,
  newBackendApiUrl,
  version
} from '../../../config';
import { API_TYPES } from '../../enums/api.enum';

import API from './api';

export class Real extends API {
  /**
   * Get url string
   * @param {string} API_TYPE
   * @param {object} [params={}]
   * @returns {string}
   * @memberof Real
   */
  _getUrl(API_TYPE, params = {}) {
    switch (API_TYPE) {
      case API_TYPES.PlACE_ORDER:
        return `${apiUrl}${this.v0}/place-the-order`;
      case API_TYPES.PAY_PALL_ORDER:
        return `${apiUrl}${this.v3}/paypal-standalone/init`;
      case API_TYPES.ONLINE_ORDER:
        return `${apiUrl}${this.v3}/${this.token}/pay-init`;
      case API_TYPES.GET_CATEGORIES:
        return `${apiUrl}${this.v2}/get-categories/${this.branchId}?mlc=1`;
      case API_TYPES.GET_PRODUCTS_BY_CAT_ID:
        return `${apiUrl}${this.v2}/get-products-of-category/${this.branchId}/${params.category_id}`;
      case API_TYPES.GET_PRODUCTS_BY_BRANCH_ID:
        return `${apiUrl}${this.v3_1}/products/${this.branchId}`;
      case API_TYPES.GET_SINGLE_PRODUCT:
        return `${apiUrl}${this.v2}/get-single-product/${this.branchId}/${params.product_id}`;
      case API_TYPES.GET_ADDITIVES:
        return `${apiUrl}${this.v3_1}/branches/${this.branchId}/additives`;
      case API_TYPES.GET_OFFERS:
        return `${apiUrl}${this.v3_1}/${this.branchId}/offers`;
      case API_TYPES.GET_SINGLE_OFFER:
        return `${apiUrl}${this.v3_1}/${this.branchId}/offers/${params.offerId}`;
      case API_TYPES.GET_PRODUCTS_BY_GROUP_ID:
        return `${apiUrl}${this.v3_1}/${this.branchId}/offers/${params.offerId}/${params.groupId}/products`;
      case API_TYPES.GET_FREE_ITEM:
        return `${apiUrl}${this.v3_1}/${this.branchId}/offers/free`;
      case API_TYPES.CHECK_MAINTENANCE:
        return `${apiUrl}${this.v3}/check-maintenance-pwd`;
      case API_TYPES.GET_APP_PIC:
        return `${apiUrl}${this.v3}/${this.token}/branch/create-mobile-app-pic`;
      case API_TYPES.VALIDATE_COUPON:
        return `${apiUrl}${this.v3}/check-voucher/${this.token}`;
      case API_TYPES.SEND_FEEDBACK:
        return `${apiUrl}${this.v3}/${this.token}/form-contact/${this.branchId}`;
      case API_TYPES.RESERVE_TABLE:
        return `${apiUrl}${this.v0}/reserve`;
      case API_TYPES.GET_QR_CODE_URL:
        return `${apiUrl}${this.v3}/${this.token}/appstore/${this.branchId}`;
      case API_TYPES.GET_RESTAURANT_BY_DOMAIN:
        return `${apiUrl}${this.v3_1}/start/byDomain?domain=${params.domain}&lang=${this.lang}`;
      case API_TYPES.GET_RESTAURANT_BY_SLUG:
        return `${apiUrl}${this.v3_1}/start/bySlug?slug=${params.slug}&lang=${this.lang}`;
      case API_TYPES.GET_BRANCH_DATA:
        return `${apiUrl}${this.v3_1}/branches/${params.branchId}?uniq%5B%5D=paypal`; // ?uniq[]=paypal - allows to get to Hermes uniq payment methods
      case API_TYPES.GET_PAGE: {
        const token = params.branchId
          ? `${this.token}-${params.branchId}`
          : this.token;

        return `${apiUrl}${this.v3_1}/page/${params.page}/${token}?lang=${this.lang}`;
      }
      case API_TYPES.GET_SLIDERS:
        return `${apiUrl}${this.v3_1}/branches/${this.branchId}/sliders`;
      case API_TYPES.GET_NEWS:
        return `${apiUrl}${this.v0}/get-news`;
      case API_TYPES.GET_DELIVERY_AVAILABILITY:
        return `${apiUrl}${this.v3_1}/user/areas/${params.branchId}?lat=${params.lat}&lon=${params.lng}`;
      case API_TYPES.GET_ACTIVE_TEMPLATE:
        return `${newBackendApiUrl}${configApi}/v1/hermes/designs/variables/active?customerToken=${this.token}&applicationType=${params.templateCode}`;
      case API_TYPES.GET_TEMPLATE:
        return `${newBackendApiUrl}${configApi}/v1/hermes/designs/${params.designId}/variables?customerToken=${this.token}&&applicationType=${params.templateCode}`;
      case API_TYPES.GET_PAYPAL_TRANSACTION_STATUS:
        return `${apiUrl}${this.v3}/payment/status/${params.branchId}/${params.transactionId}`;
      case API_TYPES.GET_OFFLINE_PAYMENT_ORDER_STATUS:
        return `${apiUrl}${this.v3}/order/status/${params.branchId}/${params.orderId}`;
      case API_TYPES.GET_BRANCH_ID_BY_COORDS:
        return `${apiUrl}${this.v3_1}/user/areas/slug/${params.slug}?lat=${params.lat}&lon=${params.lng}`;
      default:
        return false;
    }
  }

  _request(url, params = {}) {
    params.headers = params.headers || {};

    return fetch(url, params).then(this._handleResponse.bind(this));
  }

  /**
   * Handle success fetching
   * @param {object} [res={}]
   * @returns {promise}
   * @memberof Real
   */
  _handleResponse(res = {}) {
    if (res.ok) {
      return res
        .json()
        .then((res) => {
          if (res && res.success) return res;

          if (res && res.status === 200) return res.content;

          if (
            res &&
            // eslint-disable-next-line no-prototype-builtins
            (res.hasOwnProperty('payment_status') ||
              // eslint-disable-next-line no-prototype-builtins
              res.hasOwnProperty('order_status'))
          )
            return res;

          return this._handleError(res);
        })
        .catch(this._handleError);
    }

    return this._handleError(res);
  }

  _handleError(err = {}) {
    let errorMsg = '';

    try {
      err.e =
        err.e ||
        err.error ||
        err.message ||
        'Undefined error. Check _handleResponse method in real.js';

      err.eTitle = err.eTitle || 'Error';

      errorMsg = err.e;

      const errObject = {
        status: err.status,
        message: errorMsg,
        url: err.url
      };

      if (err instanceof Error) {
        errObject.name = err.name;
      }

      const resultError = new Error(JSON.stringify(errObject));

      return Promise.reject(resultError);
    } catch (e) {
      errorMsg = e.message;
    }

    return Promise.reject(errorMsg);
  }

  /**
   * Method for sending paypal init request
   * @param data
   * @returns {Promise}
   */
  placeTheOrder(data) {
    return this._request(this._getUrl(API_TYPES.PlACE_ORDER), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-ordersmart-client-version': `Hermes${version}`
      },
      body: `data=${encodeURIComponent(JSON.stringify(data))}&lang=${this.lang}`
    });
  }

  /**
   * Method for sending paypal init request
   * @param data
   * @returns {Promise}
   */
  payPallInit(data) {
    data.appversion = this.appversion;

    data.redirect_url = window.location.href;

    data.dyn_token = this.token;

    if (!isProduction) {
      data.is_dev = 1;
    }

    return this._request(this._getUrl(API_TYPES.PAY_PALL_ORDER), {
      method: 'POST',
      headers: {
        'x-ordersmart-client-version': `Hermes${version}`
      },
      body: JSON.stringify(data)
    });
  }

  /**
   * Method for sending paypal init request
   * @param data
   * @returns {Promise}
   */
  payPall2(data) {
    data.appversion = this.appversion;

    data.returnUrl = `${window.location.href}?checkTransaction=true`;

    data.cancelUrl = `${window.location.href}?paymentCanceled=true`;

    data.dyn_token = this.token;

    data.paymentSystem = 'paypal';

    return this._request(this._getUrl(API_TYPES.PAY_PALL_ORDER_2), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-ordersmart-client-version': `Hermes${version}`
      },
      body: JSON.stringify(data)
    });
  }

  /**
   * Method for sending payment request
   * @param data
   * @returns {Promise}
   */
  payOnline(data) {
    data.userData = {
      appversion: this.appversion
    };

    data.uuid = this.uuid;

    return this._request(this._getUrl(API_TYPES.ONLINE_ORDER), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-ordersmart-payment-origin-url': window.location,
        'x-ordersmart-client-version': `Hermes${version}`
      },
      body: `data=${encodeURIComponent(JSON.stringify(data))}`
    });
  }

  /**
   * Load link to application picture (Hand with app)
   * @returns {Promise}
   */
  getLinkToAppPicture() {
    return this._request(this._getUrl(API_TYPES.GET_APP_PIC));
  }

  /**
   * Method for getting array of categories
   * @returns {Promise}
   */
  getCategories() {
    return this._request(this._getUrl(API_TYPES.GET_CATEGORIES));
  }

  /**
   * Method for getting array of products by category id
   * @param {number} category_id - Category ID
   * @returns {Promise}
   */
  getProductsOfCategory(category_id) {
    this.setCategoryId(category_id);

    return this._request(
      this._getUrl(API_TYPES.GET_PRODUCTS_BY_CAT_ID, { category_id })
    );
  }

  /**
   * Get all products by branch
   * @return {Promise<object>}
   */
  getProductsOfBranch() {
    return this._request(this._getUrl(API_TYPES.GET_PRODUCTS_BY_BRANCH_ID));
  }

  /**
   * Method for getting single product by ID
   * @param {number} product_id - Product ID
   * @returns {Promise}
   */
  getSingleProduct(product_id) {
    return this._request(
      this._getUrl(API_TYPES.GET_SINGLE_PRODUCT, { product_id })
    );
  }

  /**
   * Load list of allergens/additives
   * @returns {Promise}
   */
  getAdditives() {
    return this._request(this._getUrl(API_TYPES.GET_ADDITIVES));
  }

  /**
   * Loading offer list
   * @returns {promise}
   * @memberof Real
   */
  getOffers() {
    return this._request(this._getUrl(API_TYPES.GET_OFFERS));
  }

  /**
   * Loading offer by ID
   * @returns {promise}
   * @memberof Real
   */
  getSingleOfferById(offerId) {
    return this._request(this._getUrl(API_TYPES.GET_SINGLE_OFFER, { offerId }));
  }

  /**
   * Loading products of products group by id
   * @param {number} offerId
   * @param {number} groupId
   * @returns {promise}
   * @memberof Real
   */
  getProductsByGroupId(offerId, groupId) {
    return this._request(
      this._getUrl(API_TYPES.GET_PRODUCTS_BY_GROUP_ID, { offerId, groupId })
    );
  }

  /**
   * Get free item
   * @returns {promise}
   */
  getFreeItem() {
    return this._request(this._getUrl(API_TYPES.GET_FREE_ITEM));
  }

  /**
   * Method for validating Maintenance Password
   * @param {object} data
   * @returns {*}
   */
  checkMaintenancePassword(data) {
    return this._request(this._getUrl(API_TYPES.CHECK_MAINTENANCE), {
      method: 'POST',
      body: JSON.stringify(data)
    }).then((res) => {
      if (res.valid) return Promise.resolve(res);

      return Promise.reject('Could not validate maintenance password');
    });
  }

  /**
   * Method for validating coupon
   * @param {object} body - coupon data
   * @returns {promise}
   */
  validateCoupon(body) {
    return this._request(this._getUrl(API_TYPES.VALIDATE_COUPON), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
  }

  /**
   * Method for sending feedback request
   * @param {string} email
   * @param {string} message
   * @returns {promise}
   */
  sendFeedback(email, message) {
    return this._request(this._getUrl(API_TYPES.SEND_FEEDBACK), {
      method: 'POST',
      headers: {
        'X-ordersmart-Hermes-Contact-Form': '6004b43d0101e6d38fc3abb8bb20d666',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `email=${email}&message=${message}`
    });
  }

  /**
   * Method for sending table reservation request
   * @param {string} branchId
   * @param {string} email
   * @param {string} phone
   * @param {string} name
   * @param {number} personQuantity
   * @param {string} date
   * @param {string} time
   * @param {string} comment
   * @param {string} lang
   * @returns {promise}
   */
  reserveTable(
    branchId,
    email,
    phone,
    name,
    personQuantity,
    date,
    time,
    comment,
    lang
  ) {
    const body = {
      branch_id: branchId,
      email,
      phone,
      name,
      amount: personQuantity,
      date,
      time,
      comment,
      uuid: 'Hermes Web Shop 3',
      lang
    };

    return this._request(this._getUrl(API_TYPES.RESERVE_TABLE), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
  }

  /**
   * Method for getting information about restaurant by domain
   * @param {string} domain - Restaurant domain name
   * @returns {promise}
   */
  getRestaurantDataByDomain(domain) {
    return this._request(
      this._getUrl(API_TYPES.GET_RESTAURANT_BY_DOMAIN, { domain })
    );
  }

  /**
   * Method for getting information about restaurant by Slug
   * @param {string} slug - Restaurant slug name
   * @returns {promise}
   */
  getRestaurantDataBySlug(slug) {
    return this._request(
      this._getUrl(API_TYPES.GET_RESTAURANT_BY_SLUG, { slug })
    );
  }

  /**
   * Method for getting information about branch
   * @param {number} branchId
   * @returns {promise}
   */
  getBranchData(branchId) {
    this.setBranchId(branchId);

    return this._request(this._getUrl(API_TYPES.GET_BRANCH_DATA, { branchId }));
  }

  /**
   * Method for getting content of pages
   * @param {string} page - page name
   * @returns {promise}
   */
  getPage(page, branchId) {
    return this._request(this._getUrl(API_TYPES.GET_PAGE, { page, branchId }));
  }

  /**
   * Loading sliders list
   * @returns {promise}
   */
  getSliders() {
    return this._request(this._getUrl(API_TYPES.GET_SLIDERS));
  }

  /**
   * Loading news list
   * @returns {promise}
   */
  getNews() {
    return this._request(this._getUrl(API_TYPES.GET_NEWS));
  }

  /**
   * Getting delivery availability
   * @param {object} coordinates - latitude and longitude
   */
  getDeliveryAvailability(params) {
    return this._request(
      this._getUrl(API_TYPES.GET_DELIVERY_AVAILABILITY, { ...params })
    );
  }

  /**
   * Loading active template by template code
   * @param templateCode
   * @returns {promise}
   */
  getActiveDesignTemplate(templateCode) {
    return this._request(
      this._getUrl(API_TYPES.GET_ACTIVE_TEMPLATE, { templateCode })
    );
  }

  /**
   * Loading template by designId
   * @param designId
   * @param templateCode
   * @returns {promise}
   */
  getDesignTemplateById(designId, templateCode) {
    return this._request(
      this._getUrl(API_TYPES.GET_TEMPLATE, { designId, templateCode })
    );
  }

  getPaypalTransactionStatus(branchId, transactionId) {
    return this._request(
      this._getUrl(API_TYPES.GET_PAYPAL_TRANSACTION_STATUS, {
        branchId,
        transactionId
      })
    );
  }

  getOfflinePaymentOrderStatus(branchId, orderId) {
    return this._request(
      this._getUrl(API_TYPES.GET_OFFLINE_PAYMENT_ORDER_STATUS, {
        branchId,
        orderId
      })
    );
  }

  async getBranchIdByCoords(slug, lat, lng) {
    const url = this._getUrl(API_TYPES.GET_BRANCH_ID_BY_COORDS, {
      lat,
      lng,
      slug
    });

    try {
      const reponse = await fetch(url);
      const { branch } = await reponse.json();

      return branch;
    } catch (e) {
      return null;
    }
  }
}
