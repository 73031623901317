/**
 * Enum of api methods
 */
export const API_TYPES = {
  PlACE_ORDER: 'PLACE_ORDER',
  PAY_PALL_ORDER: 'PAY_PALL_ORDER',
  PAY_PALL_ORDER_2: 'PAY_PALL_ORDER_2',
  ONLINE_ORDER: 'ONLINE_ORDER',
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_PRODUCTS_BY_CAT_ID: 'GET_PRODUCTS_BY_CAT_ID',
  GET_PRODUCTS_BY_BRANCH_ID: 'GET_PRODUCTS_BY_BRANCH_ID',
  GET_PRODUCTS_BY_GROUP_ID: 'GET_PRODUCTS_BY_GROUP_ID',
  GET_SINGLE_PRODUCT: 'GET_SINGLE_PRODUCT',
  GET_ADDITIVES: 'GET_ADDITIVES',
  GET_OFFERS: 'GET_OFFERS',
  GET_SINGLE_OFFER: 'GET_SINGLE_OFFER',
  GET_FREE_ITEM: 'GET_FREE_ITEM',
  CHECK_MAINTENANCE: 'CHECK_MAINTENANCE',
  VALIDATE_COUPON: 'VALIDATE_COUPON',
  SEND_FEEDBACK: 'SEND_FEEDBACK',
  RESERVE_TABLE: 'RESERVE_TABLE',
  GET_QR_CODE_URL: 'GET_QR_CODE_URL',
  GET_RESTAURANT_BY_DOMAIN: 'GET_RESTAURANT_BY_DOMAIN',
  GET_RESTAURANT_BY_SLUG: 'GET_RESTAURANT_BY_SLUG',
  GET_BRANCH_DATA: 'GET_BRANCH_DATA',
  GET_PAGE: 'GET_PAGE',
  GET_SLIDERS: 'GET_SLIDERS',
  SEND_ALERT: 'SEND_ALERT',
  GET_APP_PIC: 'GET_APP_PIC',
  GET_NEWS: 'GET_NEWS',
  GET_DELIVERY_AVAILABILITY: 'GET_DELIVERY_AVAILABILITY',
  GET_TEMPLATE: 'GET_TEMPLATE',
  GET_ACTIVE_TEMPLATE: 'GET_ACTIVE_TEMPLATE',
  GET_PAYPAL_TRANSACTION_STATUS: 'GET_PAYPAL_TRANSACTION_STATUS',
  GET_OFFLINE_PAYMENT_ORDER_STATUS: 'GET_OFFLINE_PAYMENT_ORDER_STATUS',
  GET_BRANCH_ID_BY_COORDS: 'GET_BRANCH_ID_BY_COORDS',
  FETCH_PAYPAL_TRANSACTION_STATUS: 'FETCH_PAYPAL_TRANSACTION_STATUS'
};
