import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import './MarketingCheckbox.scss';

/**
 * Component to render marketing checkbox
 */
class MarketingCheckbox extends React.PureComponent {
  static propTypes = {
    isHideMarketingCheckbox: PropTypes.bool.isRequired,
    allowReceiveMarketingInfo: PropTypes.bool.isRequired,
    marketingCheckboxAction: PropTypes.func.isRequired
  };

  static defaultProps = {};

  render() {
    const {
      allowReceiveMarketingInfo,
      marketingCheckboxAction,
      isHideMarketingCheckbox
    } = this.props;

    return !isHideMarketingCheckbox ? (
      <div className="marketing-checkbox">
        <Checkbox
          className="checkbox"
          checked={allowReceiveMarketingInfo}
          onChange={marketingCheckboxAction}
        />
        <div className="message" onClick={marketingCheckboxAction}>
          {this.props.t('order_payment_methods:marketingText')}
        </div>
      </div>
    ) : null;
  }
}

export default withTranslation(['order_payment_methods'])(MarketingCheckbox);
