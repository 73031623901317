import { observer } from 'mobx-react';
import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootContext } from 'client/stores';

import PickUpFormFC from './PickUpForm';
import { PickUpFormData, PickUpFormProps } from './PickUpForm.type';

type Props = Omit<
  PickUpFormProps,
  | 't'
  | 'defaultValues'
  | 'onSubmit'
  | 'lang'
  | 'isMobile'
  | 'isGermanRestaurant'
>;

const PickUpForm = observer((props: Props) => {
  const { t, i18n } = useTranslation(['address_form', 'validationErrors']);

  const {
    deliveryAddressStore,
    analyticsStore,
    themesStore,
    restaurantStore
  } = useContext(RootContext);

  const { address } = deliveryAddressStore;

  const onSubmit = (values: PickUpFormData) => {
    const address = {
      first_name: values.firstName.trim(),
      last_name: values.lastName.trim(),
      zip: values.zip ? values.zip.trim() : '',
      company: values.company ? values.company.trim() : '',
      email: values.email.trim(),
      phone: values.phone.trim(),
      street: values.street ? values.street.trim() : '',
      street_no: values.houseNumber ? values?.houseNumber.trim() : '',
      city: values.city ? values.city.trim() : '',
      comment: values.comment.trim()
    };

    deliveryAddressStore.updateAddress(address);

    analyticsStore.sendProvidePersonalInformation();
  };

  const defaultValues = {
    firstName: address.first_name,
    lastName: address.last_name,
    zip: address.zip,
    company: address.company,
    email: address.email,
    phone: address.phone,
    street: address.street,
    houseNumber: address.street_no,
    city: address.city,
    comment: address.comment
  };

  return (
    <PickUpFormFC
      t={t}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      lang={i18n.language as AppLang}
      isMobile={themesStore.isMobile}
      isGermanRestaurant={restaurantStore.isGermanRestaurant}
      isPolishRestaurant={restaurantStore.isPolishRestaurant}
      isSimplePickUpForm={restaurantStore.branch.isSimplePickUpForm}
      {...props}
    />
  );
});

export default memo(PickUpForm);
