// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GoogleAddress_GoogleAddress__35vy- .GoogleAddress_poweredByGoogle__95TCg {\n  display: -ms-flexbox;\n  display: flex;\n  height: 24px;\n  -ms-flex-pack: end;\n      justify-content: flex-end;\n  padding: 5px 10px; }\n  .GoogleAddress_GoogleAddress__35vy- .GoogleAddress_poweredByGoogle__95TCg img {\n    height: 100%;\n    text-align: right; }\n", "",{"version":3,"sources":["webpack://src/client/components/GoogleAddress/GoogleAddress.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,aAAa;EACb,YAAY;EACZ,kBAAyB;MAAzB,yBAAyB;EACzB,iBAAiB,EAAE;EACnB;IACE,YAAY;IACZ,iBAAiB,EAAE","sourcesContent":[".GoogleAddress .poweredByGoogle {\n  display: flex;\n  height: 24px;\n  justify-content: flex-end;\n  padding: 5px 10px; }\n  .GoogleAddress .poweredByGoogle img {\n    height: 100%;\n    text-align: right; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GoogleAddress": "GoogleAddress_GoogleAddress__35vy-",
	"poweredByGoogle": "GoogleAddress_poweredByGoogle__95TCg"
};
export default ___CSS_LOADER_EXPORT___;
