import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import openModal from 'client/utils/openModal';
import { IS_CLIENT } from 'config';

import SHELL_HOMEPAGE_BUTTONS from '../../../../../enums/shell_homepage_buttons_type.enum';
import states from '../../../../../enums/states.enum';
import { ShellModalsRouteType } from '../../../../../routes/ShellModalsRoute/ShellModalsRoute.type';
import { filterPrice } from '../../../../../utils/functions';
import { generateLinkFor } from '../../../../../utils/routing';
import DiscountPercentageMobile from '../../../components/discount_block';
import FooterShellButton from '../buttons-shell/footer-button';
import DeliveryAreaSwitch from '../delivery-area-switch';
import HeaderShell from '../header-shell';
import ShellLayout from '../shell-layout';
import ShellOpenStatus from '../shell-open-status';

import ButtonsBar from './buttons-bar';
import './HomePageShell.scss';

@withRouter
@inject('store')
@observer
export default class HomePageShell extends Component {
  UNSAFE_componentWillMount() {
    const { slug } = this.props.store.aggregatorStore;

    if (slug) {
      this.props.store.themesStore.loadTemplate();
    }
  }

  /**
   * Method to handle change order type button action
   * @param {ORDER_TYPES} type
   * @private
   */
  _changeOrderType = (type) => {
    this.props.store.deliveryAddressStore.changeOrderType(type);
  };

  /**
   * Method to handle change order type button action and open Categories
   * @param {string} type
   * @private
   */
  _changeOrderTypeAndCategories = (type) => {
    // Change order type
    this._changeOrderType(type);

    // Open categories
    const { isBranchSelectingSkipped, hasOneBranch } = this.props;

    const categoriesState =
      isBranchSelectingSkipped && !hasOneBranch
        ? states.branchList
        : states.cat;

    this.props.history.push(
      generateLinkFor(categoriesState, this.props, {}, true)
    );
  };

  /**
   * Method to handle area button action
   * @private
   */
  _areaButtonAction = () => {
    const addressModalUrl = openModal(ShellModalsRouteType.ADDRESS_MODAL);

    this.props.store.basketStore.setLastProductClicked();

    this.props.history.push(addressModalUrl);
  };

  /**
   * method for go back from start page (only for aggregators)
   * @private
   */
  _goBack = () => {
    const {
      slug,
      showBackButtonToAggregatorCustomPage,
      customPageUrl
    } = this.props.store.aggregatorStore;

    if (IS_CLIENT && showBackButtonToAggregatorCustomPage) {
      window.location.href = customPageUrl;
    } else {
      this.props.store.aggregatorStore.setAggregatorMode(true);

      this.props.store.restaurantStore.setSlug(slug);

      const path = generateLinkFor(
        '',
        {
          ...this.props,
          match: {
            ...this.props.match,
            params: { ...this.props.store.aggregatorStore }
          }
        },
        {}
      );

      this.props.store.themesStore.clearTemplate();

      this.props.history.push(path);
    }
  };

  _renderHeader = () => {
    const {
      slug,
      showBackButtonToAggregatorCustomPage
    } = this.props.store.aggregatorStore;

    const { title } = this.props.store.restaurantStore.restaurant;

    return (
      <HeaderShell
        title={title}
        backAction={
          slug || showBackButtonToAggregatorCustomPage
            ? this._goBack
            : undefined
        }
        style={this.props.store.themesStore.headerStyle()}
      />
    );
  };

  _renderContent = () => {
    const {
      shellTemplateVarLogo,
      shellTemplateVarLogoOffsets,
      shellTemplateVarHomeBackground,
      showBasketButtonHomepageShell,
      typeHomepageShellBottomButtons,
      buttonsBarStyle,
      showDeliveryAreaSwitchHomepageShell,
      shellHomepageWithDeliveryPickupButtonsBackground,
      shellDeliveryPickupButtonsBar,
      restaurantStatus
    } = this.props.store.themesStore;

    const {
      shareContent,
      areaCode,
      isBranchSelectingSkipped,
      useCalculationTypeByDeliveryArea
    } = this.props.store.restaurantStore;

    const {
      getShellHomepageBackgroundLink,
      logo,
      isShowShare,
      isShowOpenings,
      isShowCall,
      hasOneBranch,
      isShowNews
    } = this.props.store.restaurantStore.restaurant;

    const {
      getWebsitePhone,
      branchIsTableReservation,
      getHideTel
    } = this.props.store.restaurantStore.branch;

    const { translateKeyWithWorkStatus } = this.props.store.openingHoursStore;
    const { discountPercentage } = this.props.store.basketStore;

    const {
      isDelivery,
      hasDelivery,
      hasPickup,
      address,
      fullStreetAddressExists,
      addressPostalCode
    } = this.props.store.deliveryAddressStore;

    const { activeLanguage } = this.props.store.languagesStore;

    const backgroundImageLink =
      shellTemplateVarHomeBackground || getShellHomepageBackgroundLink;

    const templatingStyle = {
      backgroundImage: `url(${backgroundImageLink})`,
      backgroundSize: '100% auto',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    };

    const wrapperStyle = {
      paddingBottom: showBasketButtonHomepageShell ? '80px' : '0px'
    };

    const homepageWithDeliveryPickupButtons =
      typeHomepageShellBottomButtons ===
      SHELL_HOMEPAGE_BUTTONS.DELIVERY_PICKUP_BUTTONS;

    const homepageShellWrapperStyle = {
      ...wrapperStyle,
      ...(homepageWithDeliveryPickupButtons ? templatingStyle : {})
    };

    return (
      <div
        id="homepage-shell-wrapper"
        className={classNames({
          'shell-background-gradient': backgroundImageLink
        })}
        style={homepageShellWrapperStyle}
      >
        {/* Add background gradient if homepageWithDeliveryPickupButtons active */}
        {backgroundImageLink && (
          <style
            dangerouslySetInnerHTML={{
              __html: [
                '.shell-background-gradient::before {' +
                  `  background-image: linear-gradient(to bottom, ${shellHomepageWithDeliveryPickupButtonsBackground()}, transparent);` +
                  '}'
              ].join('\n')
            }}
          />
        )}

        <div className="shell-header">
          {showDeliveryAreaSwitchHomepageShell && (
            <DeliveryAreaSwitch
              langKey={activeLanguage?.id}
              areaCode={addressPostalCode}
              currentAddress={
                fullStreetAddressExists ? address.city : undefined
              }
              isAddressNeeded={useCalculationTypeByDeliveryArea}
              isDelivery={isDelivery}
              changeOrderTypeAction={this._changeOrderType}
              areaButtonAction={this._areaButtonAction}
              isDeliveryEnabled={hasDelivery}
              isPickupEnabled={hasPickup}
              deliverySwitchStyle={this.props.store.themesStore.deliverySwitchStyle()}
              postalCodeStyle={this.props.store.themesStore.postalCodeStyle()}
            />
          )}
          {discountPercentage !== 0 && (
            <DiscountPercentageMobile
              isShell
              discount={discountPercentage}
              style={this.props.store.themesStore.discountStyle()}
            />
          )}
        </div>

        <div
          className="logo-status"
          style={homepageWithDeliveryPickupButtons ? {} : templatingStyle}
        >
          <div className="logo-wrapper">
            <img
              src={shellTemplateVarLogo || logo}
              alt="logo"
              style={shellTemplateVarLogoOffsets}
              data-testid="logo-main-scr"
            />
          </div>
          <ShellOpenStatus
            status={translateKeyWithWorkStatus}
            style={restaurantStatus()}
          />
        </div>

        <ButtonsBar
          changeOrderType={
            (type) =>
              this.props.store.deliveryAddressStore.changeOrderType(type)
            // eslint-disable-next-line react/jsx-curly-newline
          }
          areaCode={areaCode}
          isBranchSelectingSkipped={isBranchSelectingSkipped}
          isCallAvailable={isShowCall}
          isDeliveryAvailable={hasDelivery}
          isNewsAvailable={isShowNews}
          isOpeningsTimeAvailable={isShowOpenings}
          isPickupAvailable={hasPickup}
          isShareAvailable={isShowShare}
          isTableReservationAvailable={branchIsTableReservation}
          phone={getWebsitePhone}
          hasOneBranch={hasOneBranch}
          shareContent={shareContent}
          style={buttonsBarStyle()}
          showPhoneNumber={!getHideTel}
          buttonsBarType={typeHomepageShellBottomButtons}
          changeOrderTypeAction={this._changeOrderTypeAndCategories}
          shellDeliveryPickupButtonsBarStyle={shellDeliveryPickupButtonsBar()}
        />
      </div>
    );
  };

  _renderFooter = () => {
    const { hasProducts } = this.props.store.basketStore;

    const label = hasProducts
      ? this.props.store.basketStore.getIntlPrice(
          this.props.store.basketStore.finalPriceWithOffers
        )
      : this.props.store.basketStore.getIntlPrice(filterPrice(0));

    return (
      <div className="footer-buttons-wrapper">
        <FooterShellButton
          label={label}
          link={generateLinkFor(states.basket, this.props, {}, true)}
          isCartButton
          buttonOrderStyle={this.props.store.themesStore.orderButtonStyle()}
        />
      </div>
    );
  };

  render() {
    const showFooterButton = this.props.store.themesStore
      .showBasketButtonHomepageShell;

    return (
      <ShellLayout
        shellHeader={this._renderHeader()}
        shellContent={this._renderContent()}
        contentFooter={showFooterButton ? this._renderFooter() : null}
        style={this.props.store.themesStore.wrapperStyle()}
      />
    );
  }
}
