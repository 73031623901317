import React from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

import ShortHours from './short-hours';
import FullHours from './full-hours';

// CSS
import './OpeningHours.scss';

/**
 * Opening hours component is used to show in header
 * hours and days when restaurant is open.
 * @name ReactOpeningHoursComponent
 * @property {object} propTypes - .
 * @property {object} defaultProps - .
 */
@inject('store')
@observer
class OpeningHoursComponent extends React.Component {
  static propTypes = {};
  static defaultProps = {};

  /**
   * Method to change time panel state
   * @param value
   * @private
   */
  _onClickTimePanel = (value) => {
    return this.props.store.openingHoursStore.toggleHours(value);
  };

  render() {
    const {
      todayHoursByOrderType,
      parsedHoursBasedOnOrderType,
      isHiddenFullTimes
    } = this.props.store.openingHoursStore;
    const { isDelivery } = this.props.store.deliveryAddressStore;
    const { isHolidayModeActive } = this.props.store.restaurantStore.branch;
    const holidayModeTime =
      this.props.store.restaurantStore.branch.secondsUntilOpenPickUp || 0;
    return isHiddenFullTimes ? (
      <ShortHours
        day={todayHoursByOrderType}
        isDelivery={isDelivery}
        onClickTimePanel={this._onClickTimePanel}
        isHolidayModeActive={isHolidayModeActive}
        holidayModeTime={holidayModeTime}
      />
    ) : (
      <FullHours
        days={parsedHoursBasedOnOrderType}
        isDelivery={isDelivery}
        onClickTimePanel={this._onClickTimePanel}
      />
    );
  }
}

export default withTranslation(['opening_hours'])(OpeningHoursComponent);
