import { Form, Input } from 'antd';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface IAdditionalFieldsProps extends WithTranslation {
  fields: string[];
  fieldDecorator: any;
  getAddressFieldValue: (key: string) => string;
  onFocusField?: () => void;
}

interface IAdditionalFieldsNavProps {
  fields: string[];
  fieldDecorator: any;
  getAddressFieldValue: (key: string) => string;

  t: (key: string) => string;
}

/**
 * Component to render row with "order now" label
 */
class AdditionalFields extends React.PureComponent<
  IAdditionalFieldsProps,
  Record<string, any>,
  IAdditionalFieldsNavProps
> {
  public render() {
    return this.props.fields.map((field, index) =>
      this.renderField(field, index)
    );
  }

  /**
   * Method to render field
   * @param {string} fieldKey - field params key
   * @param {number} index - index of field
   * @returns {React.ReactNode | null}
   */
  private renderField = (fieldKey: string, index: number) => {
    const initialValue = this.props.getAddressFieldValue(fieldKey);

    return (
      <Form.Item key={index} wrapperCol={{ span: 24 }}>
        {this.props.fieldDecorator(fieldKey, {
          initialValue
        })(
          <Input
            placeholder={`${this.props.t(fieldKey)} (${this.props.t(
              'common:optional'
            )})`}
            autoComplete="disable"
            onFocus={this.props.onFocusField}
          />
        )}
      </Form.Item>
    );
  };
}

export default withTranslation(['address_form'])(AdditionalFields);
