import { Styles } from 'react-select';

export const getSelectStyles = (): Styles<{}, false> => ({
  control: (base) => ({
    ...base,
    border: 'solid 1px #d9d9d9',
    boxShadow: 'none',

    '&:hover': {
      borderColor: 'solid 1px #d9d9d9'
    }
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  noOptionsMessage: (base) => ({
    ...base,
    textAlign: 'left',
    paddingLeft: 11
  }),
  option: (base, props) => ({
    ...base,
    opacity: 0.7,
    color: '#1b1b1b',
    backgroundColor: props.isFocused ? '#f6f6f6' : '#ffffff'
  }),
  placeholder: (base) => ({
    ...base,
    opacity: 0.3,
    color: '#1b1b1b'
  }),
  singleValue: (base) => ({
    ...base,
    opacity: 0.7,
    color: '#1b1b1b'
  })
});

export const getAutocompleteStyles = (): Styles<{}, false> => ({
  control: (base, props) => ({
    ...base,
    border: 'solid 1px #d9d9d9',
    boxShadow: 'none',
    borderRadius: props.isFocused ? '3px 3px 0 0' : 3,
    '&:hover': {
      borderColor: 'solid 1px #d9d9d9'
    }
  }),
  dropdownIndicator: () => ({
    display: 'none'
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  input: (base) => ({
    ...base,
    width: '100%'
  }),
  menu: (base) => ({
    ...base,
    borderRadius: '0 0 3px 3px',
    marginTop: 0,
    width: '100%',
    left: '0px',
    boxShadow: '0'
  }),
  noOptionsMessage: (base) => ({
    ...base,
    textAlign: 'left',
    paddingLeft: 11,
    paddingBottom: 0
  }),
  option: (base, props) => ({
    ...base,
    opacity: 0.7,
    color: '#1b1b1b',
    backgroundColor: props.isFocused ? '#f6f6f6' : '#ffffff'
  }),
  placeholder: (base) => ({
    ...base,
    opacity: 0.3,
    color: '#1b1b1b'
  }),
  singleValue: (base) => ({
    ...base,
    opacity: 0.7,
    color: '#1b1b1b'
  })
});
