import React, { memo, useCallback } from 'react';

import { TextAreaProps } from './TextArea.type';

const TextArea = ({
  className,
  pattern,
  rows = 2,
  textAreaRef,
  value,
  dataTestId,
  ...rest
}: TextAreaProps) => {
  const handleKeyPress = useCallback<
    React.KeyboardEventHandler<HTMLTextAreaElement>
  >(
    (event) => {
      const { key } = event;
      const isSymbol = key.length === 1; // key.length avoid matching service keys (Control, Backspace, Tab, etc)
      const isValidSymbol = pattern?.test(key);

      if (isSymbol && !isValidSymbol) {
        event.preventDefault();
      }
    },
    [pattern]
  );

  if (value) {
    return (
      <textarea
        className={className}
        ref={textAreaRef}
        rows={rows}
        value={value}
        data-testid={dataTestId}
        {...rest}
      />
    );
  }

  return (
    <textarea
      className={className}
      ref={textAreaRef}
      rows={rows}
      data-testid={dataTestId}
      onKeyPress={handleKeyPress}
      {...rest}
    />
  );
};

export { TextArea };

export default memo(TextArea);
