import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import CookieConsent from 'react-cookie-consent';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { cookieName } from 'config';

import './CookieConfirmation.scss';
import CookieModal from '../../pages/desktop/CookieModal';
import PrivacyModal from '../../pages/desktop/PrivacyModal';

type TVisibleConsentBar = 'show' | 'hidden' | 'byCookieValue';

type Props = RouteComponentProps & WithTranslation;

type State = {
  consentBar: TVisibleConsentBar;
  isModalOpen: boolean;
  isPrivacyModalOpen: boolean;
};

@inject('store')
@(withRouter as any)
@observer
class CookieConfirmation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      consentBar: 'byCookieValue',
      isModalOpen: false,
      isPrivacyModalOpen: false
    };
  }

  openCookieModal = () => {
    this.openModal();
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  openPrivacyModal = () => {
    this.setState({ isPrivacyModalOpen: true });
  };

  closePrivacyModal = () => {
    this.setState({ isPrivacyModalOpen: false });
  };

  handleClickLink = () => {
    this.openCookieModal();

    this.setState({ consentBar: 'hidden' });
  };

  handleAccept = () => {
    //
  };

  handleDecline = () => {
    //
  };

  render() {
    return (
      <>
        <CookieConsent
          visible={this.state.consentBar}
          containerClasses="cookie-consent"
          contentClasses="content"
          onAccept={this.handleAccept}
          onDecline={this.handleDecline}
          enableDeclineButton
          buttonText={this.props.t('cookie:acceptAll')}
          declineButtonText={this.props.t('cookie:Back')}
          buttonClasses="btn accept-btn"
          declineButtonClasses="btn decline-btn"
          cookieName={cookieName}
        >
          {this.props.t('cookie:usage')}
          <div className="sub-text">
            <span className="link" onClick={this.handleClickLink}>
              {this.props.t('cookie:clickHere')}
            </span>{' '}
            {this.props.t('cookie:moreInfoSettings')}
          </div>
        </CookieConsent>
        {this.state.isModalOpen && (
          <CookieModal
            closeModal={this.closeModal}
            openPrivacyModal={this.openPrivacyModal}
          />
        )}
        {this.state.isPrivacyModalOpen && (
          <PrivacyModal closeModal={this.closePrivacyModal} />
        )}
      </>
    );
  }
}

export default withTranslation()(CookieConfirmation);
