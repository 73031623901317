import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import DeliveryFormDe from 'client/components/DeliveryFormDe';
import DeliveryFormPl from 'client/components/DeliveryFormPl';

const DeliveryForm = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language as AppLang;

  if (lang === 'pl') {
    return <DeliveryFormPl />;
  }

  return <DeliveryFormDe />;
};

export { DeliveryForm };

export default memo(DeliveryForm);
