import React, { memo, useContext } from 'react';

import AdvancedDeliveryDeForm from 'client/components/AdvancedDeliveryDeForm';
import AdvancedDeliveryPlForm from 'client/components/AdvancedDeliveryPlForm';
import { RootContext } from 'client/stores';

const AdvancedDeliveryForm = () => {
  const {
    restaurantStore: {
      restaurant: { getLang }
    }
  } = useContext(RootContext);

  if (getLang === 'pl') {
    return <AdvancedDeliveryPlForm />;
  }

  return <AdvancedDeliveryDeForm />;
};

export default memo(AdvancedDeliveryForm);
