export const PortalTemplate = [
  {
    variableType: 'TEMPLATE_VARIABLE',
    code: 'complementaryColor1',
    value: '#ffffff'
  },
  {
    variableType: 'TEMPLATE_VARIABLE',
    code: 'complementaryColor2',
    value: '#f4f5f8'
  },
  {
    variableType: 'TEMPLATE_VARIABLE',
    code: 'complementaryColor3',
    value: '#eaebef'
  },
  {
    variableType: 'TEMPLATE_VARIABLE',
    code: 'positiveColor',
    value: '#00b382'
  },
  {
    variableType: 'TEMPLATE_VARIABLE',
    code: 'positive2Color',
    value: '#93c700'
  },
  {
    variableType: 'TEMPLATE_VARIABLE',
    code: 'negativeColor',
    value: '#f22c38'
  },
  {
    variableType: 'TEMPLATE_VARIABLE',
    code: 'analogousColorValue',
    value: '#f83fae'
  },
  {
    variableType: 'TEMPLATE_VARIABLE',
    code: 'brandColor',
    value: '#f22c38'
  },
  {
    variableType: 'TEMPLATE_VARIABLE',
    code: 'brand2Color',
    value: '#b70800'
  },
  {
    variableType: 'TEMPLATE_VARIABLE',
    code: 'shade1Color',
    value: '#3e3f41'
  },
  {
    variableType: 'TEMPLATE_VARIABLE',
    code: 'shade3Color',
    value: '#7d8190'
  },
  {
    variableType: 'TEMPLATE_VARIABLE',
    code: 'shade2Color',
    value: '#dd0e1c'
  },
  {
    variableType: 'TEMPLATE_VARIABLE',
    code: 'shellCategoriesType',
    value: 'HorizontalWithoutImages'
  }
];
