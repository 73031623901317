/* eslint-disable camelcase */
import classNames from 'classnames';
import React, { Fragment, useMemo } from 'react';

import { ELEMENTS_TYPES } from '../../../../../enums/homepage_elements_types.enum';
import { createHomepageHrefLink } from '../../../../../utils/functions';
import './HomepageSeoText.scss';

interface IHomepageSeoText {
  content: {
    is_primary: boolean;
    title: string;
    is_disable: boolean;
    text?: string;
    category: string;
  }[];
  headerText: string;
  subHeaderText: string;
  anchors: {
    label: string;
    hash: string;
  }[];
  isMobile?: boolean;
}

const HomepageSeoText = (props: IHomepageSeoText) => {
  const anchorsLine = useMemo(
    () =>
      props.anchors.map((anchor, index) => {
        const hrefHash = `#${anchor.hash}`;

        return (
          <Fragment key={anchor.hash}>
            {index !== 0 ? ' | ' : ''}
            <a href={hrefHash}>{anchor.label}</a>
          </Fragment>
        );
      }),
    [props.anchors]
  );

  return (
    <section
      className={classNames({
        'homepage-seotext': true,
        'desktop-homepage-section': !props.isMobile,
        'mobile-homepage-section': props.isMobile
      })}
    >
      <div className="homepage-title">{props.headerText}</div>
      <h2>{props.subHeaderText}</h2>
      <div className="homepage-anchors">{anchorsLine}</div>
      {props.content.map((elem) => {
        if (elem.is_disable) {
          return null;
        }

        return (
          <Fragment key={elem.title}>
            {elem.title && <h2>{elem.title}</h2>}
            {elem.text && <p>{elem.text}</p>}
          </Fragment>
        );
      })}
    </section>
  );
};

export default HomepageSeoText;
