import classNames from 'classnames';
import React from 'react';

import { ProductNumberProps } from './ProductNumber.type';
import './ProductNumber.scss';

const ProductNumber = ({ value, className }: ProductNumberProps) => {
  if (!value) {
    return null;
  }

  return (
    <div className={classNames('ProductNumber', className)}>
      <p>{value}</p>
    </div>
  );
};

export { ProductNumber };

export default React.memo(ProductNumber);
