import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Metadata from '../../../../components/meta_data';
import { BROWSER_SUPPORT } from '../../../../enums/browser.enum';
import images from '../../../../enums/images_enums/hermes_images.enum';
import MetadataTypes from '../../../../enums/metadata.enums';
import ButtonOrder from '../buttons/ButtonOrder';

import './_error-page.scss';

@withRouter
@inject('store')
@observer
class ErrorPage extends Component {
  componentDidMount() {
    const {
      store: { analyticsStore }
    } = this.props;

    const link = window.location.href;

    analyticsStore.sendGlobalError(link);
  }

  goToHomePage() {
    const {
      history,
      store: { themesStore, errorStore }
    } = this.props;

    const browser = BROWSER_SUPPORT[themesStore.browser];

    if (errorStore.errorState.type === 'BROWSER_SUPPORT' && browser) {
      return window.open(browser.update_link);
    }

    errorStore.clearErrorState();

    history.goBack();
  }

  render() {
    const {
      t,
      store: { errorStore, themesStore }
    } = this.props;

    return (
      <div id="not-found-state">
        <div className="wrapper">
          <div className="block left-block">
            <img
              alt="Error image"
              className="chef-img"
              src={images.errorPageImage}
            />
          </div>
          <div className="block right-block">
            <p className="text error-code">
              {t('order_payment_methods:error')}
            </p>
            <p className="text text-2">{errorStore.getErrorMessage}</p>
            <ButtonOrder
              onClick={() => this.goToHomePage()}
              label={t('common:cmnBack')}
              style={themesStore.orderButtonStyle()}
            />
          </div>
        </div>
        <Metadata metadataType={MetadataTypes.ERROR_PAGE} />
      </div>
    );
  }
}

export default withTranslation(['common'])(ErrorPage);
