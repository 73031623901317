import { observable, toJS } from 'mobx';

import PAYMENT_ORDER_TYPES from '../enums/payment_orders_types.enum';
import PAYPAL_TO_CREFOPAY_SETTINGS from '../enums/paypal_to_crefopay_settings.enum';

import IPaymentMethodSetting from './payment_method_setting.model';

/**
 * Payment Method class. Used to generate payment methods
 */
export class PaymentMethod {
  public id: number;

  public description: string;

  public deliveryMinPrice: number;

  public delivery: boolean;

  public pickupMinPrice: number;

  public pickup: boolean;

  public isUniq: boolean;

  public isOnline: boolean;

  public localKey: string;

  public useAsCrefoPay: PAYPAL_TO_CREFOPAY_SETTINGS;

  @observable public active = false;

  constructor(payment: any) {
    this.id = parseInt(payment.id, 10);

    this.description = payment.description || payment.name || '';

    this.deliveryMinPrice = payment.deliveryMinPrice || 0;

    this.delivery = payment.delivery || false;

    this.pickupMinPrice = payment.pickupMinPrice || 0;

    this.pickup = payment.pickup || false;

    this.isUniq = payment.isUniq || false;

    this.isOnline = payment.isOnline || false;

    this.localKey = payment.localKey || '';

    this.useAsCrefoPay =
      payment.useAsCrefoPay ||
      PAYPAL_TO_CREFOPAY_SETTINGS.DO_NOT_REPLACE_PAYPAL;

    if (
      payment.setting &&
      payment.setting.length > 0 &&
      payment.setting.find(
        (i: IPaymentMethodSetting) =>
          i.orderType === PAYMENT_ORDER_TYPES.DELIVERY
      )
    ) {
      this.deliveryMinPrice = parseInt(
        payment.setting.find(
          (i: IPaymentMethodSetting) =>
            i.orderType === PAYMENT_ORDER_TYPES.DELIVERY
        ).minPrice,
        10
      );

      this.delivery = true;
    }

    if (
      payment.setting &&
      payment.setting.length > 0 &&
      payment.setting.find(
        (i: IPaymentMethodSetting) => i.orderType === PAYMENT_ORDER_TYPES.PICKUP
      )
    ) {
      this.pickupMinPrice = parseInt(
        payment.setting.find(
          (i: IPaymentMethodSetting) =>
            i.orderType === PAYMENT_ORDER_TYPES.PICKUP
        ).minPrice,
        10
      );

      this.pickup = true;
    }
  }

  /**
   * Method to convert instance to pure JS Object.
   * @return {object} pure JS Object.
   */
  public getToJS() {
    return toJS({ ...this, root: {} });
  }
}
