import { toJS } from 'mobx';
/**
 * Homepage Category model
 */
export default class HomepageCategoryModel {
  constructor(category) {
    Object.assign(this, category);
    this.id = category.id;
    this.name = category.name;
    this.picUrl = category.picUrl;
    this.category_id = category.object_id;
    this.parentCategoryId = category.parentCategoryId;
  }

  id;
  category_id;
  parentCategoryId;
  name = '';
  picUrl = '';

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  getToJS() {
    return toJS(Object.assign({}, this, { root: {} }));
  }
}
