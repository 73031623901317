import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import './_global-loader.scss';

//Assets
import images from '../../../../../enums/images_enums/hermes_images.enum';

export default class GlobalAppLoader extends Component {
  static propTypes = {
    fullPage: PropTypes.bool
  };
  static defaultProps = {
    fullPage: false
  };

  render() {
    return (
      <div
        id="global-app-loader"
        className={this.props.fullPage ? 'full-page' : ''}
      >
        <img alt="Loading animation" src={images.loading} className="chef" />
      </div>
    );
  }
}
