import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Types
import images from '../../../../../../enums/images_enums/hermes_images.enum';

import IProductListCategoryHeaderProps from './ProductListCategoryHeader.interface';

// Enums

// CSS
import './ProductListCategoryHeader.scss';

const ProductListCategoryHeader = (props: IProductListCategoryHeaderProps) => {
  const {
    isShell,
    image,
    title,
    description,
    bottomSpace,
    categoryNameStyle,
    loaderSrc,
    setSize,
    additionalClass
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [imageLoaded, setImageLoadStatus] = useState(false);

  useEffect(() => {
    containerRef.current &&
      setSize &&
      setSize(containerRef.current.getBoundingClientRect().height);
  }, [imageLoaded]);

  const renderImageMode = () => (
    <div
      className={classNames({
        'image-mode-container': true,
        'with-description': description
      })}
    >
      {isShell && !image ? (
        <div className="category-image shell" />
      ) : (
        <LazyLoadImage
          src={image}
          placeholderSrc={(loaderSrc && loaderSrc.url) || images.loading}
          wrapperClassName="category-image"
          afterLoad={() => setImageLoadStatus(true)}
        />
      )}
      <div className="image-mode-title-text" style={categoryNameStyle}>
        {title}
      </div>
      {description && (
        <div className="image-mode-description-text" style={categoryNameStyle}>
          {description}
        </div>
      )}
    </div>
  );

  const renderTextMode = () => (
    <div
      className={classNames({
        'text-mode-container': true,
        'bottom-space': bottomSpace
      })}
    >
      <div className="text-mode-title-text">{title}</div>
      {description && (
        <div className="text-mode-description-text">{description}</div>
      )}
    </div>
  );

  return (
    <div
      ref={containerRef}
      className={classNames({
        'product-list-category-header-container': true,
        shell: isShell,
        [`${additionalClass}`]: additionalClass
      })}
    >
      {image || isShell ? renderImageMode() : renderTextMode()}
    </div>
  );
};

export default ProductListCategoryHeader;
