import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Types
import { IBasketButton } from './props.interface';

// CSS
import './styles.scss';

class BasketButton extends PureComponent<IBasketButton> {
  render() {
    const { link, buttonOrderStyle, label, animated } = this.props;

    return (
      <Link
        className="basket-button-shell"
        to={link}
        style={buttonOrderStyle}
        data-testid="basket-button"
      >
        <div className="info">
          <div className="icon mdi mdi-cart" data-testid="basket-btn-icon" />
          <span data-testid="basket-btn-title">
            {this.props.t?.('shoppingCart')}
          </span>
        </div>
        <div
          className={classNames({
            price: true,
            animate: !!animated
          })}
          data-testid="ket-btn-amount"
        >
          {label}
        </div>
      </Link>
    );
  }
}

export default withTranslation(['basket'])(BasketButton);
