/* eslint-disable camelcase */
import { toJS } from 'mobx';

/**
 * Price difference class. Used to work price difference.
 * @constructor
 * @param {number} id - unique identifier.
 * @param {object} priceDiff - price difference object.
 * @property {number} id - Unique identifier.
 * @property {number} price - Price.
 * @property {number} is_fixed - Fixed price.
 * @property {number} is_excluded - Excluded price.
 */
export class PriceDiffModel {
  public id: number;

  public price: number;

  public is_fixed: number;

  public is_excluded: number;

  public old_price: number;

  constructor(id: number, priceDiff: any) {
    this.id = id;

    this.price = priceDiff.price > 0 ? priceDiff.price : 0;

    this.is_fixed = priceDiff.is_fixed || 0;

    this.is_excluded = priceDiff.is_excluded || 0;

    this.old_price = priceDiff.old_price || 0;
  }

  /**
   * Method to serialize PriceDiff to pure JS object.
   * @return {object} - serialized PriceDiff object.
   */
  public getToJS() {
    return toJS({ ...this, root: {} });
  }
}
