import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { StructuredDataCategoryBreadcrumbs } from '../structured_data/categories-breadcrumbs';
import { StructuredDataRestaurant } from '../structured_data/index';

@withRouter
@inject('store')
@observer
class BranchSeo extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    isCategory: PropTypes.bool,
    isSubcategory: PropTypes.bool,
    robots: PropTypes.string
  };

  keywords = () => {
    const { branchName, getCity } = this.props.store.restaurantStore.branch;
    const { getSeoKeywords } = this.props.store.restaurantStore.restaurant;

    return `${branchName},${getCity},${getSeoKeywords.join(',')}`;
  };

  _description() {
    return this.props.store.seoDataStore.makeSeoDescription(this.props.title);
  }

  _renderMetaTags() {
    const {
      branchName,
      getStreet,
      getStreetNo,
      getLat,
      getLong,
      getCity,
      getCityCode,
      getUrlWeb,
      getWebsitePhone,
      getEmail,
      getCountry
    } = this.props.store.restaurantStore.branch;

    const {
      logo,
      predefinedPageTitle
    } = this.props.store.restaurantStore.restaurant;

    const location = this.props.store.restaurantStore.hostName;
    const { title, robots } = this.props;
    const language = this.props.store.languagesStore.activeLanguage.id;
    const pageTitle = `${title} | ${branchName}, ${getCity}, ${getStreet} ${getStreetNo}`;

    return [
      { itemprop: 'name', content: pageTitle },
      { itemprop: 'image', content: logo },
      { name: 'og:title', content: `${pageTitle}` },
      { name: 'og:type', content: 'restaurant.restaurant' },
      { name: 'og:url', content: `https://${location}/` },
      { name: 'og:image', content: logo },
      { name: 'og:description', content: this._description() },
      { name: 'og:site_name', content: `${branchName}` },
      {
        name: 'restaurant:contact_info:street_address',
        content: `${getStreet} ${getStreetNo}`
      },
      { name: 'restaurant:contact_info:locality', content: getCity },
      { name: 'restaurant:contact_info:region', content: '' },
      { name: 'restaurant:contact_info:postal_code', content: getCityCode },
      { name: 'restaurant:contact_info:country_name', content: getCountry },
      { name: 'restaurant:contact_info:email', content: getEmail },
      {
        name: 'restaurant:contact_info:phone_number',
        content: getWebsitePhone
      },
      { name: 'restaurant:contact_info:website', content: getUrlWeb },
      { name: 'place:location:latitude', content: getLong },
      { name: 'place:location:longitude', content: getLat },
      { name: 'description', content: this._description() },
      { name: 'keywords', content: this.keywords() },
      { name: 'language', content: `${language}` },
      { name: 'author', content: 'app smart GmbH' },
      { name: 'robots', content: robots },
      { name: 'audience', content: 'all,alle' },
      { name: 'copyright', content: 'app smart GmbH' },
      { name: 'expires', content: 'never' },
      { name: 'page-topic', content: 'shopping' },
      { name: 'page-type', content: 'Verzeichnis, Portal' }
    ];
  }

  render() {
    const { minDeliveryFee } = this.props.store.restaurantStore.branch;

    const {
      branchId,
      branchName,
      getStreet,
      getStreetNo,
      getLat,
      getLong,
      getCity,
      getCityCode,
      getUrlWeb,
      getWebsitePhone,
      getEmail,
      getFax,
      getCurrencyCode
    } = this.props.store.restaurantStore.branch;

    const { logo } = this.props.store.restaurantStore.restaurant;
    const restaurantName = this.props.store.restaurantStore.restaurant.title;
    const { namesOfPaymentMethods } = this.props.store.orderPaymentMethodsStore;
    const { structuredWorkTime } = this.props.store.openingHoursStore;
    const { activeLanguage } = this.props.store.languagesStore;
    const { canonicalLink } = this.props.store.restaurantStore;
    const hostname = this.props.store.restaurantStore.hostName;
    const { getCountryPrefix } = this.props.store.restaurantStore.branch;

    const schema = StructuredDataRestaurant({
      name: branchName,
      streetAddress: `${getStreet}, ${getStreetNo}`,
      city: getCity,
      countryCode: getCountryPrefix,
      postalCode: getCityCode,
      logo,
      website: getUrlWeb,
      phone: getWebsitePhone,
      email: getEmail,
      fax: getFax,
      paymentAccepted: namesOfPaymentMethods,
      currency: getCurrencyCode,
      branchCode: branchId,
      latitude: getLat,
      longitude: getLong,
      openingHours: structuredWorkTime,
      deliveryFee: minDeliveryFee,
      language: activeLanguage.id
    });

    const scripts = [schema];

    if (this.props.isCategory || this.props.isSubcategory) {
      const breadCrumbs = StructuredDataCategoryBreadcrumbs(
        this.props.store.categoryMenuStore.activeCategory,
        this.props.store.categoryMenuStore.activeSubcategory,
        {
          domain: `https://${hostname}/`,
          restaurantName,
          branchName,
          restaurantLogo: logo,
          branchCode: branchId
        }
      );

      scripts.push(breadCrumbs);
    }

    return (
      <Helmet
        htmlAttributes={{
          lang: activeLanguage.id
        }}
        title={this.props.store.seoDataStore.makeSeoBranchTitle(
          this.props.title
        )}
        link={[{ rel: 'canonical', href: canonicalLink }]}
        script={scripts}
        meta={this._renderMetaTags()}
      />
    );
  }
}

BranchSeo.defaultProps = {
  robots: 'noindex'
};

export default withTranslation(['seo'])(BranchSeo);
