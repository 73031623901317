import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import ReactSVG from 'react-svg';
//CSS
import './_coupon-form.scss';

//Assets
import images from '../../../../../enums/images_enums/hermes_images.enum';
import CouponFormField from './coupon_form';
import { Alert, Form } from 'antd';

@withRouter
@observer
class CouponForm extends React.Component {
  static propTypes = {
    isShowInvalidCoupon: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    couponCode: PropTypes.string,
    validateCouponAction: PropTypes.func.isRequired,
    removeCouponAction: PropTypes.func.isRequired,
    clearCoupon: PropTypes.func.isRequired,
    isShellThemeActive: PropTypes.bool.isRequired,
    isMbvNotAvailable: PropTypes.bool.isRequired,
    couponMbv: PropTypes.string
  };

  render() {
    const {
      isShowInvalidCoupon,
      loading,
      couponCode,
      validateCouponAction,
      removeCouponAction,
      clearCoupon,
      isShellThemeActive,
      isMbvNotAvailable,
      couponMbv
    } = this.props;

    return (
      <div
        className={classNames({
          'coupon-enter-component': true,
          shell: isShellThemeActive
        })}
      >
        <div
          className={classNames({
            hidden: !loading,
            loading: true
          })}
        >
          <ReactSVG
            src={images.payment_loading}
            className="animation"
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 36px; height: 36px;');
            }}
          />
        </div>
        <CouponFormField
          clearCoupon={clearCoupon}
          isShowInvalidCoupon={isShowInvalidCoupon}
          loading={loading}
          couponCode={couponCode}
          validateCouponAction={validateCouponAction}
          removeCouponAction={removeCouponAction}
        />
        {couponMbv && isMbvNotAvailable && (
          <Alert
            message={this.props.t('coupons:couponValidationErrorMessage')}
            description={this.props.t('coupons:couponMbvMessage', {
              mbv: couponMbv
            })}
            type="error"
          />
        )}
      </div>
    );
  }
}
export default withTranslation(['coupons'])(CouponForm);
