import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { generateWorktimeForDay, getDayShort } from '../../../../utils/time';

class ShortHours extends PureComponent {
  /**
   * Action on panel to change view mode
   * @private
   */
  _handleClick = () => {
    !this.props.isHolidayModeActive && this.props.onClickTimePanel(false);
  };

  /**
   * Render holiday mode message, time until open
   * @param time - seconds to open day
   * @returns {*}
   */
  _renderHolidayMode = (time) => {
    const holidayModeDuration = dayjs.duration(time, 'seconds');
    const date = dayjs().add(holidayModeDuration);
    return <span>{this.props.t('store_is_closed_modal:weAreClosedUntil', { date: date.format('DD.MM') })}</span>;
  };

  /**
   * Method to render work time
   * @param isHolidayModeActive
   * @param day
   * @param holidayModeTime
   * @returns {string}
   * @private
   */
  _renderWorkTime = (isHolidayModeActive, day, holidayModeTime) => {
    return isHolidayModeActive ? this._renderHolidayMode(holidayModeTime) : getDayShort(day.day) + ': ' + generateWorktimeForDay(day);
  };

  /**
   * Method to render dropdown arrow
   * @returns {*}
   * @private
   */
  _renderDropdownArrow = () => {
    return (
      <div className='chevron-block desktop-only'>
        <span className='fas fa-chevron-down' />
      </div>
    );
  };

  render() {
    const { day, isDelivery, t, isHolidayModeActive, holidayModeTime } = this.props;
    return (
      <div className='opening-hours-short white-block' onClick={this._handleClick}>
        <div className='times-block'>
          {isDelivery ? (
            <span className='title'>
              <i className='fas fa-motorcycle' />
              {t('deliveryHours')}
            </span>
          ) : (
            <span className='title'>
              <i className='fas fa-clock' />
              {t('openingHours')}
            </span>
          )}
          {<span>{this._renderWorkTime(isHolidayModeActive, day, holidayModeTime)}</span>}
        </div>
        {!isHolidayModeActive && this._renderDropdownArrow()}
      </div>
    );
  }
}
export default withTranslation(['opening_hours'])(ShortHours);
