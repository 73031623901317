import { Icon } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactSVG from 'react-svg';

// Types
import images from '../../../../enums/images_enums/hermes_images.enum';

// CSS
import './ButtonOrder.scss';

const ButtonOrder = ({
  onClick,
  label,
  disabled,
  type,
  positiveAction,
  isShell,
  style,
  isSoldOut,
  loading,
  dataTestId
}) => (
  <button
    className={classNames({
      'button-order': true,
      disabled: disabled || isSoldOut,
      shell: isShell,
      'positive-action': positiveAction,
      'sold-out': isSoldOut
    })}
    onClick={onClick}
    disabled={disabled}
    type={type}
    style={style}
    data-testid={dataTestId}
  >
    {isSoldOut && (
      <ReactSVG
        className="sold-out-icon"
        src={images.iconSoldOut}
        beforeInjection={(svg) => {
          svg.setAttribute(
            'class',
            `${svg.getAttribute('class')} sold-out-icon-image`
          );
        }}
      />
    )}
    {loading ? <Icon type="loading" style={{ fontSize: 14 }} spin /> : label}
  </button>
);

ButtonOrder.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isSoldOut: PropTypes.bool,
  type: PropTypes.string,
  isShell: PropTypes.bool,
  positiveAction: PropTypes.bool,
  style: PropTypes.object,
  dataTestId: PropTypes.string
};

ButtonOrder.defaultProps = {
  label: '',
  onClick: () => {},
  disabled: false,
  type: 'button',
  positiveAction: true,
  isShell: false,
  isSoldOut: false
};

export default ButtonOrder;
