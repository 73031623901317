import classNames from 'classnames';
import React, { Component, MouseEvent, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import styled from 'styled-components';
import { v1 } from 'uuid';

import animationConstants from '../../../../../enums/animation.enum';
import images from '../../../../../enums/images_enums/hermes_images.enum';

import { IProductStandardButtonProps } from './props.interface';
import './ProductStandardButton.scss';

interface State {
  animationBlobs: {
    id: string;
    elem: JSX.Element;
  }[];
}

class ProductStandardButton extends Component<
  IProductStandardButtonProps,
  State
> {
  constructor(props: IProductStandardButtonProps) {
    super(props);

    this.state = {
      animationBlobs: []
    };
  }

  buttonPriceRef = createRef<HTMLDivElement>();

  componentDidMount() {
    this.executeAnimation();
  }

  componentDidUpdate(props: IProductStandardButtonProps) {
    this.executeAnimation(props);
  }

  executeAnimation = (props?: IProductStandardButtonProps) => {
    const {
      animated,
      productCount,
      animationTargetBounds,
      mobileView
    } = this.props;

    const buttonPosition =
      this.buttonPriceRef.current &&
      this.buttonPriceRef.current.getBoundingClientRect();

    if (
      animated &&
      (!props || (props && animated !== props.animated)) &&
      animationTargetBounds &&
      buttonPosition
    ) {
      // 55 - offset from the right edge of the basket button, where the circle must fly
      const widthOffset =
        !this.props.isShell && animationTargetBounds.width
          ? animationTargetBounds.width / 2
          : 55;

      let offsetX =
        // eslint-disable-next-line no-restricted-globals
        (animationTargetBounds.left || 0) - buttonPosition.left - pageXOffset;

      offsetX = mobileView
        ? offsetX +
          (animationTargetBounds.width - widthOffset) -
          buttonPosition.width / 2
        : offsetX - buttonPosition.width / 2;

      let offsetY = animationTargetBounds.top - buttonPosition.top;

      if (mobileView) {
        offsetY = !props
          ? offsetY +
            (animationTargetBounds.offsetY || 0) -
            buttonPosition.height
          : offsetY;
      } else {
        offsetY -= animationTargetBounds.offsetY || 0;
      }

      const key = v1();

      /* eslint-disable */
      const Blob = styled.div`
        ${mobileView ? 'margin: 0 !important;' : ''};
        z-index: 16;
        position: absolute;
        ${mobileView ? `width: ${buttonPosition.width}` : ''};
        animation: moving-${key} ${animationConstants.time.buttonToBuyProduct}ms
          linear normal;
        opacity: 0;
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important;
        min-width: auto !important;
        @keyframes moving-${key} {
          0% {
            opacity: 1;
          }
          100% {
            transform: translate(${offsetX}px, ${offsetY}px);
          }
        }
      `;
      /* eslint-enable */

      this.setState({
        animationBlobs: [
          // eslint-disable-next-line react/no-access-state-in-setstate
          ...this.state.animationBlobs,
          {
            id: key,
            elem: (
              <Blob
                key={key}
                className={classNames({
                  'product-standard-button-container': true,
                  animated,
                  'same-item-in-basket': productCount
                })}
                style={this.props.buttonStyle}
                onAnimationEnd={() => this.dropAnimationBlob(key)}
              />
            )
          }
        ]
      });
    }
  };

  dropAnimationBlob = (key: string) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const animationBlobs = this.state.animationBlobs.filter(
      (blob) => blob.id !== key
    );

    this.setState({
      animationBlobs
    });
  };

  onClickSoldOut = (event: MouseEvent) => {
    event.stopPropagation();
  };

  renderSoldOutMode = () => {
    const soldOutText = this.props.t
      ? this.props.t('product_view:soldOut')
      : '';

    return (
      <div
        className={classNames({
          'product-standard-button-container': true,
          disabled: true,
          'is-shell': this.props.isShell
        })}
        onClick={this.onClickSoldOut}
        style={this.props.buttonStyle}
        data-testid="basket-button"
      >
        <ReactSVG
          className="product-standard-button-icon-sold-out"
          src={images.iconSoldOut}
          beforeInjection={(svg) => {
            svg.setAttribute(
              'class',
              `${svg.getAttribute(
                'class'
              )} product-standard-button-icon-sold-out-image`
            );
          }}
        />
        <span
          className="product-standard-button-text-sold-out"
          data-testid="product-price-on-btn"
        >
          {soldOutText}
        </span>
      </div>
    );
  };

  renderClosedStatusMode = () => (
    <div
      ref={this.buttonPriceRef}
      className={classNames({
        'product-standard-button-container': true,
        disabled: true,
        'shop-closed': true,
        'is-shell': this.props.isShell
      })}
      style={{
        ...this.props.buttonStyle,
        borderColor: this.props.buttonStyle
          ? `${this.props.buttonStyle.color}1A`
          : ''
      }}
      onClick={this.props.onClick}
      data-testid="basket-button"
    >
      <span style={{ opacity: this.props.buttonStyle?.color ? 0.3 : 1 }}>
        {this.props.t && this.props.t('product_view:shopIsClosed')}
      </span>
    </div>
  );

  render() {
    const {
      text,
      onClick,
      animated,
      productCount,
      isSoldOut,
      isClosed,
      disabled,
      isShell,
      buttonStyle
    } = this.props;

    if (isClosed) {
      return this.renderClosedStatusMode();
    }

    if (isSoldOut) {
      return this.renderSoldOutMode();
    }

    const separatorButtonStyle = buttonStyle
      ? {
          borderRightColor: `${buttonStyle.color}1A`
        }
      : undefined;

    return (
      <div
        ref={this.buttonPriceRef}
        className={classNames({
          'product-standard-button-container': true,
          animated,
          'same-item-in-basket': productCount,
          disabled: disabled || isSoldOut,
          'is-shell': isShell
        })}
        style={buttonStyle}
        onClick={onClick}
        data-testid="basket-button"
      >
        {!animated && (
          <>
            {productCount ? (
              <i
                className={classNames({ counter: true, 'is-shell': isShell })}
                style={separatorButtonStyle}
              >
                {`${productCount}x`}
              </i>
            ) : (
              <i
                className={classNames({
                  'left-plus': true,
                  'is-shell': isShell
                })}
                style={separatorButtonStyle}
              >
                +
              </i>
            )}
            <div
              className={classNames({
                'product-standard-button-text': true,
                animated
              })}
              data-testid="product-price-on-btn"
            >
              {text}
            </div>
          </>
        )}
        <i
          className={classNames({
            'product-standard-button-icon': true,
            'fas fa-shopping-cart': true,
            animated
          })}
        />
        {this.state.animationBlobs.map((blob) => blob.elem)}
      </div>
    );
  }
}

export default withTranslation(['product_view'])(ProductStandardButton);
