enum MOBILE_HANDLER {
  SHARE = 'show_share_alert',
  BACK = 'go_back',
  GET_FAVORITES = 'get_favorites_state',
  SET_FAVORITES = 'set_favorites_state',
  ANALYTICS_EVENT = 'send_analytics_event',
  GET_HUNGER_APP_DATA = 'get_hunger_app_data',
  SET_CONTACT_DATA = 'set_contact_data'
}

export default MOBILE_HANDLER;
