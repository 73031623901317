import { action, computed, observable, toJS } from 'mobx';

import STORAGE_KEYS from 'client/enums/storage_keys.enum';
import { COMPONENTS_TEMPLATE_TYPE } from 'client/enums/templates/components_template_type.enum';
import { CategoryModel } from 'client/models/category_menu.model';
import Storage from 'client/modules/storage';
import RootStore from 'client/stores';
import { IS_CLIENT } from 'config';

/**
 * Category Menu Store class. Used to work category menu.
 * @constructor
 * @param {instance} api - {@link API} instance.
 * @property {array <object>} categories - Observable array of categories
 * @property {boolean} dropdownExpanded - Observable state of mobile dropdown category expanding
 * @property {boolean} isShouldOpenCategories - Observable state is should open categories list
 */
class CategoryMenuStore {
  api: any;

  root: RootStore;

  storage: Storage;

  constructor(root, state, api, storage) {
    this.api = api;

    this.root = root;

    this.storage = storage;

    if (state.categories && state.categories.length > 0) {
      this.prepareCategories(state.categories);
    }
  }

  _localStorageActiveCategoryKey = STORAGE_KEYS.ACTIVE_CATEGORY;

  @observable categories: CategoryModel[] = [];

  @observable dropdownExpanded = false;

  @observable isShouldOpenCategories = false;

  @observable loading = false;

  @observable scrollableContainerElement;

  @observable scrollableContainerPosition;

  @observable wholeProductListLoaded = false;

  /**
   * Set loading state
   * @param {boolean} state
   * @memberof CategoryMenuStore
   */
  @action setLoading(state = false) {
    this.loading = state;
  }

  /**
   * Method to set scrollable container ref
   */
  @action setScrollableContainer(element) {
    this.scrollableContainerElement = element.current;
  }

  /**
   * Method to set scroll position of container
   */
  @action setContainerScrollPosition(position?: number) {
    this.scrollableContainerPosition =
      position ||
      (this.scrollableContainerElement
        ? this.scrollableContainerElement.scrollTop
        : 0);
  }

  /**
   * Method for scroll mobile app wrapper
   * @param scrollToTop true if it needs scroll to top
   */
  scrollContainerElement(scrollToTop = false) {
    scrollToTop
      ? this.scrollableContainerElement.scroll(0, 0)
      : this.scrollableContainerElement.scroll(
          0,
          this.scrollableContainerPosition
        );
  }

  /**
   * Method to change expanded state.
   * @param {boolean} flag - expanded state
   * @memberof CategoryMenuStore#
   * @method toggleExpanding
   */
  @action toggleExpanding(flag = !this.dropdownExpanded) {
    this.dropdownExpanded = flag;
  }

  /**
   * Action to set state is should open categories list
   * @param state
   */
  @action setShouldOpenCategoriesState(state = false) {
    this.isShouldOpenCategories = state;
  }

  /**
   * Method to create category model for each category and set active one.
   * @param {array <object>} [categories = []] - array of categories
   * @memberof CategoryMenuStore#
   * @method prepareCategories
   */
  @action prepareCategories(categories = []) {
    this.categories = categories
      .filter((i) => i.name)
      .sort((firstCat, secondCat) => firstCat.pos - secondCat.pos)
      .map((category) => new CategoryModel(category, this.root));
  }

  /**
   * Method to load categories.
   * @param {boolean} isDemoModeActive - state of loading demo data
   * @return {promise} information about categories
   * @memberof CategoryMenuStore#
   * @method loadCategories
   */
  @action
  loadCategories(isDemoModeActive = false) {
    return this.api
      .getCategories()
      .then((data) => {
        const categories = data.d;

        if (this.root.restaurantStore.branch.branchHasOffers) {
          categories.push(toJS(this.root.offerStore.offerCategory));
        }

        if (this.root.homepageStore.isHomepageAvailable) {
          categories.push(toJS(this.root.homepageStore.homepageCategory));
        }

        this.sendLoadingDemoProductsRequest(categories, isDemoModeActive);

        this.prepareCategories(categories);

        this.wholeProductListLoaded = false;

        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /**
   * Method to load previously active category and subcategory from local storage
   * @memberof OrderPaymentMethodsStore#
   * @method loadPreviouslyActiveCategoryFromStorage
   */
  @action
  async loadPreviouslyActiveCategoryFromStorage() {
    const { branch } = this.root.restaurantStore;

    try {
      let [activeCategoryIds] = await this.storage.loadFromStorage(
        [this._localStorageActiveCategoryKey],
        branch.branchId
      );

      activeCategoryIds = activeCategoryIds || [];

      let activeCategoryId = activeCategoryIds[0];

      const activeSubcategoryId = activeCategoryIds[1];

      if (!activeCategoryId && this.hasCategories) {
        activeCategoryId = this.categories[0].id;
      }

      if (activeSubcategoryId) {
        this.setActiveCategory(activeCategoryId, activeSubcategoryId);
      } else {
        this.setActiveCategory(activeCategoryId);
      }

      this.setLoading(false);

      return activeCategoryIds;
    } catch (e) {
      if (this.hasCategories) {
        this.setActiveCategory(this.categories[0].id);
      }

      this.setLoading(false);

      return e;
    }
  }

  /**
   * Method for running method for loading demo products
   * @param {[CategoryModel]} categories - array of categories
   * @param {boolean} isDemoModeActive - state of loading demo data
   */
  @action sendLoadingDemoProductsRequest(categories, isDemoModeActive = false) {
    const filteredCategories = categories.filter(
      (category) => !category.sub_categories
    );

    if (isDemoModeActive && filteredCategories) {
      this.root.productsStore.loadProducts(
        filteredCategories[0].id,
        isDemoModeActive
      );
    }
  }

  /**
   * Method to load subcategories products asynchronously
   * @param {array <object>} items - List of subcategories models
   * @param {object} productsStore - product stor
   * @memberof CategoryMenuStore#
   * @method forEachLoadingProducts
   */
  forEachLoadingProducts(items, productsStore) {
    return items.reduce(
      (promise, item) =>
        promise
          .then(
            () =>
              new Promise((resolve, reject) => {
                process.nextTick(() => {
                  if (item.products && item.hasProducts) {
                    return resolve(null);
                  }

                  productsStore
                    .loadProducts(item.id)
                    .then((data) => {
                      item.products = data;

                      return resolve(null);
                    })
                    .catch((err) => console.log(err));
                });
              })
          )
          .catch((err) => console.log(err)),
      Promise.resolve()
    );
  }

  /**
   * Method to load products after category click
   * @param {object} category - clicked category
   * @memberof CategoryMenuStore#
   * @method loadActiveCategoriesProducts
   */
  @action loadActiveCategoriesProducts(category) {
    const subcategories = category.subCategories.map((sub) => sub);

    return category.loaded || category.hasProducts
      ? new Promise((resolve) => resolve(null))
      : this.root.productsStore.loadProducts(category.id).then((data) => {
          category.products = data;

          category.loaded = true;

          if (subcategories.length > 0) {
            return this.forEachLoadingProducts(
              subcategories,
              this.root.productsStore
            ).then((data) => data);
          }

          return data;
        });
  }

  /**
   * get products for all categories
   */
  @action fillProductsCategories() {
    this.root.productsStore.loadProductsByBranch().then((products) => {
      this.fillCategories(this.categories, products);

      this.wholeProductListLoaded = true;
    });
  }

  /**
   * set products into their categories
   * @param categories
   * @param products
   */
  fillCategories(categories, products) {
    categories.forEach((category) => {
      category.loading = true;

      const categoryProducts =
        products &&
        products
          .filter((product) => (product.categories || []).includes(category.id))
          .sort((x, y) => {
            if (x.isValidForOrder === y.isValidForOrder) {
              return 0;
            }

            return x.isValidForOrder ? -1 : 1;
          });

      category.products = categoryProducts || [];

      category.loading = false;

      category.loaded = true;

      if (category.hasSubCategories) {
        this.fillCategories(category.subCategories, products);
      }
    });
  }

  /**
   * Method to set active category
   * @param {number} catId - unique identifier of category
   * @param {number} subId - unique identifier of subcategory
   * @memberof CategoryMenuStore#
   * @method setActiveCategory
   */
  @action setActiveCategory(catId, subId = null) {
    if (this.categories.length === 0) {
      return;
    }

    catId = parseInt(catId, 10);

    const activeCategory = this.categories.find(
      (category) => category.id === catId
    );

    this.categories.forEach((category) => {
      category.active = category.id === catId;
    });

    if (!activeCategory) {
      return this.setActiveCategory(this.categories[0].id);
    }

    this.activeCategory.setLoading(true);

    if (activeCategory.id === -1 || activeCategory.id === -2) {
      return !this.root.offerStore.offersLoaded
        ? this.root.offerStore
            .loadOffers()
            .then(() => {
              this.saveActiveCategoryToStorage(catId);
            })
            .then(
              () => this.activeCategory && this.activeCategory.setLoading(false)
            )
            .catch((err) => {
              this.root.errorStore.setError({
                type: 'FAILURE',
                message: err.message,
                errorUrl: '',
                redirectUrl: '',
                statusCode: 200
              });
            })
        : Promise.resolve().then(() => {
            this.saveActiveCategoryToStorage(catId);

            this.activeCategory.setLoading(false);

            return true;
          });
    }

    return this.loadActiveCategoriesProducts(activeCategory)
      .then(() => {
        activeCategory.subCategories.forEach((sub) => {
          sub.active = false;
        });

        if (subId && activeCategory.subCategories) {
          const subcategory = activeCategory.subCategories.find(
            (i) => i.id === subId
          );

          if (subcategory) {
            return this.setActiveSubcategory(subcategory);
          }
        }

        if (
          (activeCategory.subCategories.length === 0 ||
            activeCategory.products.length !== 0) &&
          (!this.root.themesStore.isMobile ||
            this.root.themesStore.isShellThemeActive)
        ) {
          this.root.productsStore.setActiveProducts(activeCategory.products);
        } else {
          this.setActiveSubcategory(activeCategory.subCategories[0]);
        }
      })
      .then(() => this.saveActiveCategoryToStorage(catId))
      .then(() => this.activeCategory.setLoading(false))
      .catch((err) => {
        console.log(err);
      });
  }

  /**
   * Method to set active subcategory
   * @param {object} sub - unique identifier of subcategory
   * @memberof CategoryMenuStore#
   * @method setActiveSubcategory
   */
  @action setActiveSubcategory(sub) {
    const { branch } = this.root.restaurantStore;
    const { isShellThemeActive } = this.root.themesStore;

    let products = [];

    return this.activeCategory.subCategories.forEach((subcategory) => {
      subcategory.active = subcategory.id === sub.id;

      if (subcategory.id === sub.id || isShellThemeActive) {
        products = isShellThemeActive
          ? products.concat(subcategory.products)
          : subcategory.products;

        this.root.productsStore.setActiveProducts(products);

        if (IS_CLIENT) {
          this.storage.saveToStorage(
            this._localStorageActiveCategoryKey,
            [this.activeCategory.id, sub.id],
            branch.branchId
          );
        }

        this.activeCategory.setLoading(false);
      }
    });
  }

  saveActiveCategoryToStorage(catId) {
    const { branch } = this.root.restaurantStore;

    if (IS_CLIENT) {
      this.storage.saveToStorage(
        this._localStorageActiveCategoryKey,
        [catId, this.activeSubcategory ? this.activeSubcategory.id : null],
        branch.branchId
      );
    }
  }

  /**
   * Method to serialize every category in categories list.
   * @return {array <object>} serialized array of categories
   */
  getToJS() {
    return this.categories.map((category) => category.getToJS());
  }

  @action findCategoryById(categoryId) {
    return this.categories.find((category) => category.id === categoryId);
  }

  /**
   * Method for checking availability of homepage and offers for mobile wrapper
   * @returns {boolean} true - if offers can be showed
   */
  @computed get isShowOffersForMobile() {
    const isOffersAvailable = this.root.restaurantStore.branch.branchHasOffers;

    return isOffersAvailable;
  }

  /**
   * Method to check if active category is offer
   * @memberof CategoryMenuStore#
   * @method isOfferCategoryActive
   */
  @computed get isOfferCategoryActive() {
    return this.activeCategory && this.activeCategory.id === -1;
  }

  /**
   * Method to check if active category is offer
   * @memberof CategoryMenuStore#
   * @method isOfferCategoryActive
   */
  @computed get isHomepageActive() {
    return this.activeCategory && this.activeCategory.id === -2;
  }

  /**
   * Method to get active category
   * @return {object} active category
   * @memberof CategoryMenuStore#
   * @method activeCategory
   */
  @computed get activeCategory() {
    return this.categories.find((category) => category.active);
  }

  /**
   * Method to get active category
   * @return {object} active category
   * @memberof CategoryMenuStore#
   * @method activeCategory
   */
  @computed get activeSubcategory() {
    if (
      !this.hasCategories ||
      !this.activeCategory ||
      !this.activeCategory.subCategories ||
      this.activeCategory.subCategories.length === 0
    ) {
      return {};
    }

    return this.activeCategory.subCategories.find(
      (category) => category.active
    );
  }

  /**
   * Method to check categories existence
   * @return {boolean} categories existence
   * @memberof CategoryMenuStore#
   * @method hasCategories
   */
  @computed get hasCategories() {
    return !!this.categories.length;
  }

  /**
   * Method to check is active category has active subcategories
   * @returns {boolean}
   */
  @computed get hasSubcategories() {
    return (
      this.activeSubcategory && Object.keys(this.activeSubcategory).length > 0
    );
  }

  /**
   * Method to decide if the category menu should expand in wider width perspective
   * @return {boolean} should expand category menu
   * @memberof CategoryMenuStore#
   * @method shouldApplyWideTheme
   */
  @computed get shouldApplyWideTheme() {
    const categories = this.categories.filter((category) => category.id !== -2);

    switch (this.root.themesStore.categoryTheme) {
      case COMPONENTS_TEMPLATE_TYPE.HERMES_CATEGORIES_WITH_PICS:
        return (
          (categories.length > 3 && categories.length <= 5) ||
          categories.length > 6
        );
      case COMPONENTS_TEMPLATE_TYPE.HERMES_CATEGORIES_NO_PICS:
        return categories.length > 12;
      default:
        return false;
    }
  }

  /**
   * Method to set first category active
   * @memberof CategoryMenuStore#
   * @method setFirstCategoryActive
   */
  @action setFirstCategoryActive() {
    if (this.hasCategories) {
      this.setActiveCategory(this.categories[0].id);
    }
  }

  /**
   * Method to calculate maximum categories count
   * @return {Integer} categories count
   * @memberof CategoryMenuStore#
   * @method hermesThemeCountOfCategories
   */
  @computed get hermesThemeCountOfCategories() {
    const categories = this.categories.filter((category) => category.id !== -2);

    if (
      this.root.themesStore.categoryTheme ===
      COMPONENTS_TEMPLATE_TYPE.HERMES_CATEGORIES_NO_PICS
    ) {
      if (categories.length <= 12) {
        return 12;
      }

      const categoriesCount = Math.ceil(categories.length / 5) * 5;

      return categoriesCount <= 20 ? 20 : categoriesCount;
    }

    if (categories.length <= 6) {
      return 6;
    }

    const categoriesCount = Math.ceil(categories.length / 5) * 5;

    return categoriesCount <= 10 ? 10 : categoriesCount;
  }

  @observable categorySlideId = 0;

  @observable categorySlideSource;

  @action setCategorySlide(categorySlideId, source) {
    if (this.categorySlideId !== categorySlideId) {
      this.categorySlideId = categorySlideId;
    }

    if (source) {
      this.categorySlideSource = source;
    }
  }

  /**
   * Method to calculate count of column
   * @return {int} categories count
   * @memberof CategoryMenuStore#
   * @method hermesThemeCountOfColumn
   */
  @computed get hermesThemeCountOfColumn() {
    return this.hermesThemeCountOfCategories / 5;
  }

  /**
   * Method to calculate width of categories items
   * @return {int} width in percents
   * @memberof CategoryMenuStore#
   * @method widthHermesThemeWithPics
   */
  @computed get widthHermesThemeWithPics() {
    switch (this.categories.filter((category) => category.id !== -2).length) {
      case 1:
        return 100;
      case 2:
        return 50;
      case 3:
      case 5:
      case 6:
        return 33.4;
      case 4:
      case 7:
      case 8:
      case 11:
      case 12:
        return 25;
      default:
        return 20;
    }
  }
}

export default CategoryMenuStore;
