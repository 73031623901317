/**
 * Basket Request Model class.
 * Used for prepare basket to the backend request.
 * @constructor
 * @param {array} groupedProducts - group of identical products.
 * @property {number} p - product id.
 * @property {number} [q = 1] - quantity of identical products with the same ingredients.
 * @property {number} sid - active size id.
 * @property {array <array>} [bm = []] - ingredients of basic ingredient group.
 * @property {array <array>} [xm = []] - ingredients of extra ingredient group.
 * @property {array <array>} [b = []] - ids of ingredients of basic ingredient group.
 * @property {array <array>} [x = []] - ids of ingredients of extra ingredient group.
 */

import { IngredientRequestModel } from './ingredient_request.model';
import { IngredientsGroupModel } from './ingredients_group.model';

export class BasketRequestModel {
  /**
   * Product id
   */
  public p: number;

  /**
   * Quantity of identical products with the same ingredients
   */
  public q: number;

  /**
   * Active size id
   */
  public sid: number;

  /**
   * Price of the product of the selected size
   */
  public price: number;

  /**
   * Ingredients of basic ingredient group
   */
  public bm: IngredientRequestModel[];

  /**
   * Ingredients of extra ingredient group.
   */
  public xm: IngredientRequestModel[];

  /**
   * Ingredients of negative extra group
   */
  public nxm: IngredientRequestModel[];

  /**
   * Ids of ingredients of basic ingredient group
   */
  public b: number[];

  /**
   * Ids of ingredients of extra ingredient group.
   */
  public x: number[];

  /**
   * Ids of ingredients of negative ingredient group.
   */
  public nx: number[];

  /**
   * Comment
   */
  public comment: string;

  constructor(groupedProducts: any) {
    const { product } = groupedProducts;
    const basicIngredients: IngredientRequestModel[] = [];
    const extraIngredients: IngredientRequestModel[] = [];
    const negativeIngredients: IngredientRequestModel[] = [];
    const extraIngredientsIds: number[] = [];
    const basicIngredientsIds: number[] = [];
    const negativeIngredientsIds: number[] = [];

    product.ingredientGroups.forEach((group: IngredientsGroupModel) => {
      group.selectedIngredients.forEach((ingredient) => {
        // eslint-disable-next-line prefer-spread
        Array.apply(null, Array(ingredient.count))
          .map(
            () =>
              new IngredientRequestModel(
                group.id,
                ingredient.id,
                ingredient.price
              )
          )
          .forEach((i) => {
            if (group.isExtraGroup) {
              extraIngredients.push(i);

              extraIngredientsIds.push(ingredient.id);
            } else if (group.isNegative) {
              negativeIngredients.push(i);

              negativeIngredientsIds.push(ingredient.id);
            } else {
              basicIngredients.push(i);

              basicIngredientsIds.push(ingredient.id);
            }
          });
      });
    });

    this.p = product.id;

    this.q = groupedProducts.count || 1;

    this.sid = product.activeSize.id;

    this.price = product.price;

    this.bm = basicIngredients || [];

    this.xm = extraIngredients || [];

    this.nxm = negativeIngredients || [];

    this.b = basicIngredientsIds || [];

    this.x = extraIngredientsIds || [];

    this.nx = negativeIngredientsIds || [];

    this.comment = product.comment || '';
  }
}
