import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DAYJS_FORMAT } from '../../enums/dayjs.enum';
import { ORDER_TYPES } from '../../enums/order_types.enum';

/**
 * Render open/close time row
 * @param isOpen - is restaurant open now
 * @param isHolidayMode - is Holiday Mode active
 * @param hasPreorder - is preorder available
 * @param isFirstHalf - is FirstHalf
 * @param day - day work time
 * @param breakTime - break time
 * @param holidayModeTime - seconds to open
 */
class HoursInfoRow extends PureComponent {
  static propTypes = {
    isOpenNow: PropTypes.bool.isRequired,
    activeOrderType: PropTypes.string.isRequired,
    isMaintenanceModeActive: PropTypes.bool.isRequired,
    isHolidayMode: PropTypes.bool.isRequired,
    isFirstHalfTime: PropTypes.bool.isRequired,
    pickupDay: PropTypes.object.isRequired,
    deliveryDay: PropTypes.object.isRequired,
    isTodayDeliveryHasBreakTime: PropTypes.bool.isRequired,
    isTodayOpeningsHasBreakTime: PropTypes.bool.isRequired,
    holidayModeTime: PropTypes.number.isRequired,
    hasPreorder: PropTypes.bool.isRequired
  };

  static defaultProps = {};

  /**
   * Method for rendering maintenance mode label
   * @returns {*}
   */
  _renderMaintenanceMode = () => {
    return (
      <div className='opening-time-message closed'>
        <i className='mdi mdi-clock type-icon' />
        <span>{this.props.t('maintenance_mode:maintenanceIsActive')}</span>
      </div>
    );
  };

  /**
   * Method to render message if branch is closed
   * @param isHolidayMode
   * @param hasPreorder
   * @param holidayModeTime
   * @returns {*}
   * @private
   */
  _renderClosed = (isHolidayMode, hasPreorder, holidayModeTime) => {
    return isHolidayMode
      ? this._renderHolidayMessage(holidayModeTime)
      : this._renderClosedMessage(hasPreorder);
  };

  /**
   * Method to render holiday message when branch is closed
   * @param time
   * @returns {*}
   * @private
   */
  _renderHolidayMessage = (time) => {
    const holidayModeDuration = dayjs.duration(time, 'seconds');
    const date = dayjs().add(holidayModeDuration);
    return (
      <div className='opening-time-message closed'>
        <i className='mdi mdi-clock type-icon' />
        <span>{this.props.t('store_is_closed_modal:weAreClosedUntil', { date: date.format('DD.MM') })}</span>
      </div>
    );
  };

  /**
   * Method to render closed message when branch is closed by work time
   * @param hasPreorder
   * @returns {*}
   * @private
   */
  _renderClosedMessage = (hasPreorder) => {
    return (
      <div className='opening-time-message closed'>
        <i className='mdi mdi-clock type-icon' />
        <span>{this.props.t('opening_hours:closed')}</span>
        {hasPreorder ? this._renderPreorder() : null}
      </div>
    );
  };

  /**
   * Method to render preorder message if branch is closed and preorder available
   * @returns {*}
   * @private
   */
  _renderPreorder = () => {
    return (
      <span className="preorder-message">
        {' '}
        - {this.props.t('delivery_info:preorderPossible')}
      </span>
    );
  };

  /**
   * Method to render message when branch is open
   * @param day
   * @param isFirstHalf
   * @param breakTime
   * @returns {*}
   * @private
   */
  _renderOpen = (day, isFirstHalf, breakTime) => {
    if (day.start && day.end) {
      return (
        <div className='opening-time-message open'>
          <span>
            <i className='mdi mdi-clock type-icon' />
          </span>
          {!breakTime ? this._renderOpenMessage(day.start, day.end) : this._renderHalf(day, isFirstHalf)}
        </div>
      );
    } else {
      return null;
    }
  };

  /**
   * Method to render message with hours of work when branch is open but have a break time
   * @param day
   * @param isFirstHalf
   * @returns {*}
   * @private
   */
  _renderHalf = (day, isFirstHalf) => {
    return isFirstHalf
      ? this._renderOpenMessage(day.start, day.breakStart)
      : this._renderOpenMessage(day.breakEnd, day.end);
  };

  /**
   * Method to render message with hours of work when branch is open
   * @param start
   * @param end
   * @returns {*}
   * @private
   */
  _renderOpenMessage = (start, end) => {
    return this.props.t('opening_hours:workingHours', {
      start: dayjs(start, DAYJS_FORMAT.timeFormat),
      end: dayjs(end, DAYJS_FORMAT.timeFormat),
    });
  };

  /**
   * Method to render time row
   * @param isOpen
   * @param hasPreorder
   * @param isHolidayMode
   * @param isFirstHalfTime
   * @param day
   * @param breakTime
   * @param holidayModeTime
   * @returns {*}
   * @private
   */
  _renderTimeRow = (
    isOpen,
    hasPreorder,
    isHolidayMode,
    isFirstHalfTime,
    day,
    breakTime,
    holidayModeTime
  ) => {
    return isOpen
      ? this._renderOpen(day, isFirstHalfTime, breakTime)
      : this._renderClosed(isHolidayMode, hasPreorder, holidayModeTime);
  };

  render() {
    const {
      hasPreorder,
      isHolidayMode,
      isFirstHalfTime,
      holidayModeTime,
      activeOrderType,
      isMaintenanceModeActive,
      isOpenNow,
      pickupDay,
      deliveryDay,
      isTodayDeliveryHasBreakTime,
      isTodayOpeningsHasBreakTime,
    } = this.props;

    const day = activeOrderType === ORDER_TYPES.DELIVERY ? deliveryDay : pickupDay;
    const breakTime = activeOrderType === ORDER_TYPES.DELIVERY ? isTodayDeliveryHasBreakTime : isTodayOpeningsHasBreakTime;

    return isMaintenanceModeActive
      ? this._renderMaintenanceMode()
      : this._renderTimeRow(isOpenNow, hasPreorder, isHolidayMode, isFirstHalfTime, day, breakTime, holidayModeTime);
  }
}

export default withTranslation(['opening_hours'])(HoursInfoRow);
