import React, { Fragment, useState } from 'react';
import ReactSVG from 'react-svg';

// Types
import images from '../../../../../../../../enums/images_enums/hermes_images.enum';

import IProductTitleSmallScreen from './props.interface';

// Enums

// CSS
import './ProductTitleSmallScreen.scss';

const ProductTitleSmallScreen = (props: IProductTitleSmallScreen) => {
  const { no, text, additives = [], showMBVIcon, showDiscountIcon } = props;

  const renderTitleIcons = () => (
    <>
      {showMBVIcon && (
        <ReactSVG
          src={images.iconWithoutMinOrder}
          beforeInjection={(svg) => {
            svg.setAttribute(
              'class',
              `${svg.getAttribute('class')} product-title-icon`
            );

            svg.setAttribute('style', 'width: 20px; height: 20px;');
          }}
        />
      )}
      {showDiscountIcon && (
        <ReactSVG
          src={images.iconWithoutDiscount}
          beforeInjection={(svg) => {
            svg.setAttribute(
              'class',
              `${svg.getAttribute('class')} product-title-icon`
            );

            svg.setAttribute('style', 'width: 20px; height: 20px;');
          }}
        />
      )}
    </>
  );

  return (
    <div className="product-title-small-screen-container">
      {no ? <div className="title-number">{no}</div> : null}
      {renderTitleIcons()}
      <div className="product-title-small-screen-text-container">
        <div className="title-name">{text}</div>
        <div className="product-additives">{additives.join(', ')}</div>
      </div>
    </div>
  );
};

export default ProductTitleSmallScreen;
