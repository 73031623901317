import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { supportedLanguages } from 'config';

import i18n from '../../../../../../../i18n';
import useOutsideClick from '../../../../../../hooks/useOutsideClick';
import languageService from '../../../../../../services/LanguageService';
import FakeButton from '../../../../../../ui/FakeButton/FakeButton';

import style from './LanguageSwitcherMobile.module.scss';

const classes = {
  enter: style.enter,
  enterActive: style.enterActive,
  exit: style.exit,
  exitActive: style.exitActive
};

type LanguageSwitcherMobileProps = {
  className?: string;
};

const LanguageSwitcherMobile = ({ className }: LanguageSwitcherMobileProps) => {
  const [languageCode, setLanguageCode] = useState<AppLang>(
    i18n.language as AppLang
  );

  const listRef = useRef<HTMLUListElement>(null);
  const headRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<null | NodeJS.Timeout>(null);
  const [open, setOpen] = useState<boolean>(false);

  useOutsideClick([listRef, headRef], () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setOpen(false);
  });

  const selectLanguage = (langCode: AppLang) => () => {
    setLanguageCode(langCode);

    languageService.selectLanguage(langCode);

    timerRef.current = setTimeout(() => setOpen(false), 190);
  };

  const openMenu = (e) => {
    e.stopPropagation();

    setOpen(!open);
  };

  return (
    <div className={style.LanguageSwitcherMobile}>
      <FakeButton className={className} onClick={openMenu} ref={headRef}>
        <span className={style.title}>
          <span>{i18n.t('title:languageSelection')}</span>
          <span> ({languageCode.toUpperCase()})</span>
        </span>
      </FakeButton>
      <CSSTransition in={open} timeout={200} classNames={classes} unmountOnExit>
        <ul className={style.list} ref={listRef}>
          {supportedLanguages.map((lang) => (
            <li
              key={lang.code}
              className={style.item}
              onClick={selectLanguage(lang.code)}
            >
              {lang.code.toUpperCase()} - {lang.title}
            </li>
          ))}
        </ul>
      </CSSTransition>
    </div>
  );
};

export default LanguageSwitcherMobile;
