import classnames from 'classnames';
import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { RootContext } from '../../../../stores';
import FakeButton from '../../../../ui/FakeButton';
import { getUtmParamsFromSearch } from '../../../../utils/helpers';
import { getIsSlugSupported } from '../../../../utils/routing';

import style from './Branch.module.scss';
import { BranchProps } from './Branch.types';

const Branch = ({ branch, active }: BranchProps) => {
  const { t } = useTranslation(['opening_hours']);
  const { search } = useLocation();
  const history = useHistory();

  const {
    restaurantStore,
    productsStore,
    themesStore,
    basketStore
  } = useContext(RootContext);

  const { id, address, pickupClosed, title, slug } = branch;

  const onClick = () => {
    const { hostName } = restaurantStore;
    const utmParams = getUtmParamsFromSearch(search);
    const searchWithUtm = new URLSearchParams(utmParams).toString();
    const parameters = searchWithUtm ? `?${searchWithUtm}` : '';
    const routeWithSlug = `/${slug}/${id}${parameters}`;
    const route = `/${id}${parameters}`;
    const resultRoute = getIsSlugSupported(hostName) ? routeWithSlug : route;

    productsStore.clearSearchingRequest();

    productsStore.clearBranchProducts();

    themesStore.setVirtualListSizes();

    themesStore.setClickedProductTopOffset();

    basketStore.setAdvancedCalculationTypeData();

    history.push(resultRoute);
  };

  return (
    <FakeButton
      dataTestId="branch"
      className={classnames(style.Branch, {
        [style.active]: active
      })}
      onClick={onClick}
    >
      <div className={style.name}>{title}</div>
      <div className={style.address}>{address}</div>
      {pickupClosed && (
        <div
          className={classnames(style.pickup, {
            [style.close]: pickupClosed
          })}
        >
          {t('lblClosed')}
        </div>
      )}
    </FakeButton>
  );
};

export default memo(Branch);
