/**
 * Product images types
 */
enum PRODUCT_IMAGE_TYPES {
  NO_PICTURES = 0,
  SMALL_PRODUCT_PICTURES = 1,
  BIG_PICTURES_VARIANT_1 = 2,
  BIG_PICTURES_VARIANT_2 = 3
}

export default PRODUCT_IMAGE_TYPES;
