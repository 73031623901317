import React, { useLayoutEffect, useRef, useState } from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

import { hex2Rgb, rgba2hex } from 'client/utils/colorConverters';

const Skeleton = (props: IContentLoaderProps) => {
  const [background, setBackground] = useState('#f3f3f3');
  const ref = useRef(null);

  useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      const root = document.documentElement;

      const primaryColor = getComputedStyle(root).getPropertyValue(
        '--primaryTextColor'
      );

      const rgbColor = hex2Rgb(primaryColor);
      const rgbaColor = rgbColor?.replace(')', ',0.05)');
      const hexColor = rgba2hex(rgbaColor);

      setBackground(hexColor);
    }
  }, []);

  const backgroundColor = props.backgroundColor ?? background;

  return (
    <>
      <ContentLoader
        {...props}
        speed={1.2}
        backgroundColor={backgroundColor}
        foregroundColor="#ffffff"
      />
    </>
  );
};

export default React.memo(Skeleton);
