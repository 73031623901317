import dayjs from 'dayjs';
import { PropTypes, observer } from 'mobx-react';
import ReactPropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

/**
 * Component to render date selector in address form
 */
@observer
class DateSelectAddressFormShell extends React.Component {
  static propTypes = {
    isDelivery: ReactPropTypes.bool.isRequired,
    todayDay: ReactPropTypes.object.isRequired,
    filterDays: PropTypes.arrayOrObservableArray.isRequired,
    restaurantTime: PropTypes.objectOrObservableObject.isRequired,
    isClosedForToday: ReactPropTypes.bool.isRequired,
    changeDeliveryDate: ReactPropTypes.func.isRequired
  };

  static defaultProps = {};

  /**
   * Method to convert value to date
   * @param e
   * @private
   */
  _convertValueToDate = (e) => {
    const { value } = e.target;
    const unixValue = dayjs.unix(value);

    this.props.changeDeliveryDate(unixValue);
  };

  /**
   * Method to render preorder day select rows
   * @param day - selected day
   * @param index
   * @param restaurantTime - restaurant work time
   * @param isClosedForToday - is restaurant works today
   * @returns {*}
   * @private
   */
  _renderPreorderDays = (day, index, restaurantTime, isClosedForToday) =>
    day.isSame(restaurantTime, 'date') && !isClosedForToday ? (
      <option key={index} value={day.unix()} data-testid="day-in-list-basket">
        {`${day.format('DD.MM.')} - ${this.props.t(
          'address_form:deliveryDay'
        )}`}
      </option>
    ) : (
      <option key={index} value={day.unix()} data-testid="day-in-list-basket">
        {`${day.format('DD.MM.')} - ${this.props.t(
          `address_form:${day.locale('en').format('dddd').toLowerCase()}`
        )}`}
      </option>
    );

  render() {
    const {
      isDelivery,
      todayDay,
      filterDays,
      restaurantTime,
      isClosedForToday
    } = this.props;

    return (
      <div className="select-wrapper-shell day-select-shell">
        <label htmlFor="day-select" data-testid="select-day-title-basket">
          {isDelivery ? this.props.t('todayDay') : this.props.t('pickupDay')}
        </label>
        <select
          id="day-select"
          value={todayDay.unix() || ''}
          name="day-select"
          onChange={this._convertValueToDate}
          data-testid="day-selector-basket"
        >
          {filterDays.map((day, index) =>
            this._renderPreorderDays(
              day,
              index,
              restaurantTime,
              isClosedForToday
            )
          )}
        </select>
      </div>
    );
  }
}

export default withTranslation(['address_form'])(DateSelectAddressFormShell);
