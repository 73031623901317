import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Hunger } from 'client/icons/hunger-logo.svg';
import { ReactComponent as OrderSmart } from 'client/icons/order-smart-logo.svg';

import './LegalAppsmartInfo.scss';
import { RootContext } from '../../../../../../stores';

const LegalAppsmartInfo = () => {
  const { themesStore } = useContext(RootContext);
  const { isPortalWebThemeActive: isWebPortal } = themesStore;
  const { t } = useTranslation(['footer']);

  return (
    <div className="appsmart-info">
      {t('footer:imprintDevelopedBy')}
      <a
        href={isWebPortal ? 'https://hunger.de/' : 'https://ordersmart.de/'}
        target="blank"
      >
        {isWebPortal ? <Hunger className="hermes-logo" /> : <OrderSmart />}
      </a>
    </div>
  );
};

export default React.memo(LegalAppsmartInfo);
