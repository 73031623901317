import classnames from 'classnames';
import React from 'react';

import { ReactComponent as Adult16 } from 'client/icons/food/adult-16.svg';
import { ReactComponent as Adult18 } from 'client/icons/food/adult-18.svg';
import { ReactComponent as BioDe } from 'client/icons/food/de/bio-de.svg';
import { ReactComponent as GlutenDe } from 'client/icons/food/de/gluten-de.svg';
import { ReactComponent as LactoseDe } from 'client/icons/food/de/lactose-de.svg';
import { ReactComponent as SugarDe } from 'client/icons/food/de/sugar-de.svg';
import { ReactComponent as VeganDe } from 'client/icons/food/de/vegan-de.svg';
import { ReactComponent as VegetarianDe } from 'client/icons/food/de/vegetarian-de.svg';
import { ReactComponent as BioEn } from 'client/icons/food/en/bio-en.svg';
import { ReactComponent as GlutenEn } from 'client/icons/food/en/gluten-en.svg';
import { ReactComponent as LactoseEn } from 'client/icons/food/en/lactose-en.svg';
import { ReactComponent as SugarEn } from 'client/icons/food/en/sugar-en.svg';
import { ReactComponent as VeganEn } from 'client/icons/food/en/vegan-en.svg';
import { ReactComponent as VegetarianEn } from 'client/icons/food/en/vegetarian-en.svg';
import { ReactComponent as Halal } from 'client/icons/food/halal.svg';
import { ReactComponent as Hot1 } from 'client/icons/food/hot-level1.svg';
import { ReactComponent as Hot2 } from 'client/icons/food/hot-level2.svg';
import { ReactComponent as Hot3 } from 'client/icons/food/hot-level3.svg';
import { ReactComponent as BioPl } from 'client/icons/food/pl/bio-pl.svg';
import { ReactComponent as GlutenPl } from 'client/icons/food/pl/gluten-pl.svg';
import { ReactComponent as LactosePl } from 'client/icons/food/pl/lactose-pl.svg';
import { ReactComponent as SugarPl } from 'client/icons/food/pl/sugar-pl.svg';
import { ReactComponent as VeganPl } from 'client/icons/food/pl/vegan-pl.svg';
import { ReactComponent as VegetarianPl } from 'client/icons/food/pl/vegetarian-pl.svg';
import AdditiveIcon from 'client/ui/AdditiveIcon';

import { AdditiveIconListProps } from './AdditiveIconList.type';

import './AdditiveIconList.scss';

const icons = {
  vegetarian: {
    en: <VegetarianEn data-testid="product-icon-vegetarian-en" />,
    de: <VegetarianDe data-testid="product-icon-vegetarian-de" />,
    pl: <VegetarianPl data-testid="product-icon-vegetarian-pl" />
  },
  vegan: {
    en: <VeganEn data-testid="product-icon-vegan-en" />,
    de: <VeganDe data-testid="product-icon-vegan-de" />,
    pl: <VeganPl data-testid="product-icon-vegan-pl" />
  },
  bio: {
    en: <BioEn data-testid="product-icon-bio-en" />,
    de: <BioDe data-testid="product-icon-bio-de" />,
    pl: <BioPl data-testid="product-icon-bio-pl" />
  },
  halal: <Halal data-testid="product-icon-halal" />,
  lactose: {
    en: <LactoseEn data-testid="product-icon-lactose-en" />,
    de: <LactoseDe data-testid="product-icon-lactose-de" />,
    pl: <LactosePl data-testid="product-icon-lactose-pl" />
  },
  gluten: {
    en: <GlutenEn data-testid="product-icon-gluten-en" />,
    de: <GlutenDe data-testid="product-icon-gluten-de" />,
    pl: <GlutenPl data-testid="product-icon-gluten-pl" />
  },
  sugar: {
    en: <SugarEn data-testid="product-icon-sugar-en" />,
    de: <SugarDe data-testid="product-icon-sugar-de" />,
    pl: <SugarPl data-testid="product-icon-sugar-pl" />
  },
  hot: {
    NONE: null,
    MILD: <Hot1 data-testid="product-icon-mild" />,
    SPICY: <Hot2 data-testid="product-icon-spicy" />,
    HOT: <Hot3 data-testid="product-icon-hot" />
  },
  adultIcon16: <Adult16 data-testid="product-icon-adult16" />,
  adultIcon18: <Adult18 data-testid="product-icon-adult18" />
};

const AdditiveIconList = ({
  additives,
  className,
  onHalalClick,
  language,
  t
}: AdditiveIconListProps) => {
  const {
    hot,
    isAdult16,
    isAdult18,
    isBio,
    isGlutenFree,
    isHalal,
    isLactoseFree,
    isSugarFree,
    isVegan,
    isVegetarian
  } = additives;

  const hotTitle = {
    NONE: '',
    MILD: t('allergens:lblMild'),
    SPICY: t('allergens:lblSpicy'),
    HOT: t('allergens:lblHot')
  };

  if (Object.values(additives).some((additive) => !!additive)) {
    return (
      <div className={classnames('AdditiveIconList', className)}>
        {isLactoseFree && (
          <AdditiveIcon
            className="additiveIcon"
            tooltip={t('allergens:lblLactoseFree')}
          >
            {icons.lactose[language]}
          </AdditiveIcon>
        )}
        {isGlutenFree && (
          <AdditiveIcon
            className="additiveIcon"
            tooltip={t('allergens:lblGlutenFree')}
          >
            {icons.gluten[language]}
          </AdditiveIcon>
        )}
        {isSugarFree && (
          <AdditiveIcon
            className="additiveIcon"
            tooltip={t('allergens:lblSugarFree')}
          >
            {icons.sugar[language]}
          </AdditiveIcon>
        )}
        {isBio && (
          <AdditiveIcon
            className="additiveIcon"
            tooltip={t('allergens:lblBio')}
          >
            {icons.bio[language]}
          </AdditiveIcon>
        )}
        {isVegetarian && (
          <AdditiveIcon
            className="additiveIcon"
            tooltip={t('allergens:lblVegetarian')}
          >
            {icons.vegetarian[language]}
          </AdditiveIcon>
        )}
        {isVegan && (
          <AdditiveIcon
            className="additiveIcon"
            tooltip={t('allergens:lblVegan')}
          >
            {icons.vegan[language]}
          </AdditiveIcon>
        )}
        {isHalal && (
          <AdditiveIcon
            className="additiveIcon halal"
            onClick={onHalalClick}
            tooltip={t('allergens:lblHalal')}
          >
            {icons.halal}
          </AdditiveIcon>
        )}
        {hot !== 'NONE' && (
          <AdditiveIcon className="additiveIcon" tooltip={hotTitle[hot]}>
            {icons.hot[hot]}
          </AdditiveIcon>
        )}
        {isAdult16 && !isAdult18 && (
          <AdditiveIcon
            className="additiveIcon"
            tooltip={t('allergens:lblAdult')}
          >
            {icons.adultIcon16}
          </AdditiveIcon>
        )}
        {isAdult18 && (
          <AdditiveIcon
            className="additiveIcon"
            tooltip={t('allergens:lblAdult')}
          >
            {icons.adultIcon18}
          </AdditiveIcon>
        )}
      </div>
    );
  }

  return null;
};

export { AdditiveIconList };

export default React.memo(AdditiveIconList);
