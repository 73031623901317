import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { Component, MouseEvent, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Measure from 'react-measure';
import ReactSVG from 'react-svg';
import Truncate from 'react-truncate';

import AdditiveIconList from 'client/components/AdditiveIconList';
import Text from 'client/ui/Text';
import { IS_CLIENT } from 'config';

import images from '../../../../../../../enums/images_enums/hermes_images.enum';
import ProductStandardButton from '../../../../buttons/ProductStandardButton/ProductStandardButton';
import ProductDefaultImage from '../product-default-image/ProductDefaultImage';
import ProductMetaData from '../product-seo';
import { IProductBigPictures } from '../ProductCell.props.interface';
import ProductNewBadge from '../ProductNewBadge/product-new-badge';

import ProductTitleSmallScreen from './ProductTitleSmallScreen/ProductTitleSmallScreen';

import './ProductBigPictureVariant2.scss';

interface IState {
  productDescriptionNeedTruncate: boolean;
  showFullDescription: boolean;
  width: number;
  imageLoaded: boolean;
}

interface ITemplatingStyle {
  headerTextColor: string;
  descriptionOpacity: number;
  defaultPictureIconColor: string;
  defaultPictureBackgroundColor: string;
}

@observer
class ProductBigPictureVariant2 extends Component<IProductBigPictures, IState> {
  state = {
    productDescriptionNeedTruncate: true,
    showFullDescription: false,
    width: 0,
    imageLoaded: false
  };

  SMALL_SCREEN_WIDTH = 375;

  containerRef = createRef<HTMLDivElement>();

  componentDidMount() {
    if (this.containerRef.current && this.props.setSize) {
      const { marginBottom, marginTop } = getComputedStyle(
        this.containerRef.current
      );

      this.props.setSize(
        this.containerRef.current.getBoundingClientRect().height +
          parseFloat(marginBottom) +
          parseFloat(marginTop)
      );
    }
  }

  /**
   * Handle click on purchasing button
   */
  onButtonClick = (event: MouseEvent) => {
    this.props.onButtonClick(event, this.props.product);
  };

  /**
   * Handle click on cell block
   */
  onCellClick = (event: MouseEvent) => {
    this.props.onCellClick(event, this.props.product);
  };

  /**
   * Method to show full description, if it's truncated (hidden)
   */
  toShowFullDescription = (event: MouseEvent) => {
    event.stopPropagation();

    this.setState({
      showFullDescription: true
    });
  };

  /**
   * Method for updating status of image loading
   */
  updateImageLoadingStatus = (success: boolean) => {
    this.setState({
      imageLoaded: success
    });
  };

  renderDefaultImage = (templatingStyle: ITemplatingStyle) => (
    <ProductDefaultImage iconColor={templatingStyle.defaultPictureIconColor} />
  );

  /**
   * Render icons in title block
   */
  renderTitleIcons = () => {
    const { product } = this.props;

    return (
      <>
        {product.count_mbw !== 1 && (
          <ReactSVG
            src={images.iconWithoutMinOrder}
            beforeInjection={(svg) => {
              svg.setAttribute(
                'class',
                `${svg.getAttribute('class')} product-title-icon`
              );

              svg.setAttribute('style', 'width: 20px; height: 20px;');
            }}
          />
        )}
        {!product.isHideDiscountBadge && (
          <ReactSVG
            src={images.iconWithoutDiscount}
            beforeInjection={(svg) => {
              svg.setAttribute(
                'class',
                `${svg.getAttribute('class')} product-title-icon`
              );

              svg.setAttribute('style', 'width: 20px; height: 20px;');
            }}
          />
        )}
      </>
    );
  };

  /**
   * Case, when food icons is in layout
   */
  renderFoodIconsVariantOfSmallScreen = (
    isResponsiveView: boolean,
    templatingStyle: ITemplatingStyle,
    buttonText: string
  ) => {
    const {
      isShopClosed,
      product,
      animated,
      productCount,
      foodIcons,
      isMobile,
      animationTargetBounds,
      buttonStyle
    } = this.props;

    return (
      <div className="big-picture-variant-2-food-icons-container">
        {foodIcons && (
          <div className="responsive-food-icons-wrapper">
            <AdditiveIconList additives={foodIcons} />
          </div>
        )}
        <div className="responsive-button-wrapper">
          <ProductStandardButton
            isClosed={isShopClosed}
            onClick={this.onButtonClick}
            text={buttonText}
            animated={animated}
            productCount={productCount}
            animationTargetBounds={animationTargetBounds}
            isSoldOut={product.soldOut}
            disabled={!product.isValidForOrder}
            mobileView={isMobile}
            buttonStyle={buttonStyle}
          />
        </div>
        <div className="big-picture-variant-2-small-screen-description-container">
          {isResponsiveView ? (
            <div className="big-picture-variant-2-small-screen-description-wrapper">
              {product.description}
            </div>
          ) : (
            <Measure
              bounds
              onResize={(contentRect) => {
                contentRect &&
                  contentRect.bounds &&
                  contentRect.bounds.width !== this.state.width &&
                  this.setState({
                    productDescriptionNeedTruncate: true,
                    width: contentRect.bounds.width
                  });
              }}
            >
              {({ measureRef }) => (
                <div
                  ref={measureRef}
                  className="big-picture-variant-2-small-screen-description-wrapper"
                  style={{
                    color: templatingStyle.headerTextColor,
                    opacity: templatingStyle.descriptionOpacity
                  }}
                >
                  {this.state.productDescriptionNeedTruncate &&
                  !this.state.showFullDescription ? (
                    <Truncate
                      lines={2}
                      trimWhitespace
                      ellipsis="…"
                      onTruncate={(truncated: boolean) => {
                        !truncated &&
                          this.setState({
                            productDescriptionNeedTruncate: false
                          });
                      }}
                      width={this.state.width}
                    >
                      {product.description}
                    </Truncate>
                  ) : (
                    <>{product.description}</>
                  )}
                </div>
              )}
            </Measure>
          )}
        </div>
        <span className="clear" />
      </div>
    );
  };

  /**
   * Case, when food icons isn't in layout
   */
  renderSimpleVariantOfSmallScreen = (
    isResponsiveView: boolean,
    templatingStyle: ITemplatingStyle,
    buttonText: string
  ) => {
    const {
      isShopClosed,
      product,
      hideProductArticles,
      animated,
      productCount,
      isMobile,
      animationTargetBounds,
      buttonStyle
    } = this.props;

    return (
      <>
        <div className="big-picture-variant-2-small-screen-description-container">
          {isResponsiveView ? (
            <div className="big-picture-variant-2-small-screen-description-wrapper">
              {product.description}
            </div>
          ) : (
            <Measure
              bounds
              onResize={(contentRect) => {
                contentRect &&
                  contentRect.bounds &&
                  contentRect.bounds.width !== this.state.width &&
                  this.setState({
                    productDescriptionNeedTruncate: true,
                    width: contentRect.bounds.width
                  });
              }}
            >
              {({ measureRef }) => (
                <div
                  ref={measureRef}
                  className="big-picture-variant-2-small-screen-description-wrapper"
                  style={{
                    color: templatingStyle.headerTextColor,
                    opacity: templatingStyle.descriptionOpacity
                  }}
                >
                  {this.state.productDescriptionNeedTruncate &&
                  !this.state.showFullDescription ? (
                    <Truncate
                      lines={!hideProductArticles && product.no ? 4 : 2}
                      trimWhitespace
                      ellipsis="…"
                      onTruncate={(truncated: boolean) => {
                        !truncated &&
                          this.setState({
                            productDescriptionNeedTruncate: false
                          });
                      }}
                      width={this.state.width}
                    >
                      {product.description}
                    </Truncate>
                  ) : (
                    <>{product.description}</>
                  )}
                </div>
              )}
            </Measure>
          )}
          {!hideProductArticles && product.no && (
            <div className="big-picture-variant-2-small-screen-button-wrapper">
              <ProductStandardButton
                isClosed={isShopClosed}
                onClick={this.onButtonClick}
                text={buttonText}
                animated={animated}
                productCount={productCount}
                animationTargetBounds={animationTargetBounds}
                isSoldOut={product.soldOut}
                disabled={!product.isValidForOrder}
                mobileView={isMobile}
                buttonStyle={buttonStyle}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  /**
   * Render version for mobile screens with width <= 375
   */
  renderSmallScreenMobileVersion = (
    additives: string[],
    buttonText: string,
    templatingStyle: ITemplatingStyle
  ) => {
    const {
      isShopClosed,
      product,
      isNew,
      hideProductArticles,
      animated,
      productCount,
      foodIcons,
      currency,
      isMobile,
      isShell,
      animationTargetBounds,
      buttonStyle
    } = this.props;

    const foodIconsCopy: { [index: string]: number | boolean | undefined } = {
      ...foodIcons
    };

    const foodIconsExists = foodIcons
      ? Object.keys(foodIconsCopy).reduce(
          (res: boolean, iconKey: string) => res || !!foodIconsCopy[iconKey],
          false
        )
      : null;

    const noFoodIconsAndProductNumber =
      (hideProductArticles || !product.no) && !foodIconsExists;

    const isResponsiveView = isMobile && !isShell;
    const defaultImage = this.renderDefaultImage(templatingStyle);

    return (
      <div
        ref={this.containerRef}
        className="big-picture-variant-2-container small-mobile-screen"
        onClick={this.onCellClick}
        data-testid="product"
      >
        <div className="big-picture-variant-2-image-container">
          {isNew && isNew.value && (
            <ProductNewBadge
              text={this.props.t && this.props.t('product_view:lblNew')}
              style={isNew.style}
            />
          )}
          <div
            className={classNames({
              'big-picture-variant2-image-wrapper': true,
              loading: !this.state.imageLoaded,
              'default-image': !product.pic_url
            })}
            style={{
              backgroundColor: templatingStyle.defaultPictureBackgroundColor
            }}
          >
            <LazyLoadComponent
              placeholder={defaultImage}
              afterLoad={() => this.updateImageLoadingStatus(true)}
            >
              {product.pic_url ? (
                <img src={product.pic_url} alt={product.name} />
              ) : (
                defaultImage
              )}
            </LazyLoadComponent>
          </div>
        </div>
        <div
          className="big-picture-variant-2-title-wrapper"
          style={{ color: templatingStyle.headerTextColor }}
        >
          <ProductTitleSmallScreen
            text={product.name}
            additives={additives}
            no={!hideProductArticles ? product.no : undefined}
            showMBVIcon={product.count_mbw !== 1}
            showDiscountIcon={!product.isHideDiscountBadge}
          />
          {noFoodIconsAndProductNumber && (
            <ProductStandardButton
              isClosed={isShopClosed}
              onClick={this.onButtonClick}
              text={buttonText}
              animated={animated}
              productCount={productCount}
              animationTargetBounds={animationTargetBounds}
              isSoldOut={product.soldOut}
              disabled={!product.isValidForOrder}
              mobileView={isMobile}
              buttonStyle={buttonStyle}
            />
          )}
        </div>
        {foodIconsExists
          ? this.renderFoodIconsVariantOfSmallScreen(
              !!isResponsiveView,
              templatingStyle,
              buttonText
            )
          : this.renderSimpleVariantOfSmallScreen(
              !!isResponsiveView,
              templatingStyle,
              buttonText
            )}
        {this.state.showFullDescription ? (
          <div
            className="show-full-description-container"
            onClick={this.toShowFullDescription}
          >
            <div className="show-full-description-element" />
            <div className="show-full-description-element" />
            <div className="show-full-description-element" />
          </div>
        ) : null}
        <ProductMetaData product={product} currency={currency} />
      </div>
    );
  };

  handleInfoClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    const { openProductModal, product } = this.props;

    openProductModal(event, product);
  };

  render() {
    const {
      isShopClosed,
      product,
      isNew,
      hideProductArticles,
      animated,
      productCount,
      isUseAdvancedProductView,
      foodIcons,
      currency,
      isMobile,
      isShell,
      productCellStyle,
      buttonStyle,
      showHalalCertificate
    } = this.props;

    const productAdditives = product.allergens || {};

    const {
      additivePrefixes = [],
      allergensPrefixes = []
    } = productAdditives as any;

    const additives = [...additivePrefixes, ...allergensPrefixes];

    const additivesElement = (
      <span className="product-additives">{additives.join(', ')}</span>
    );

    const showMoreButton =
      isUseAdvancedProductView && !!product.description && !isMobile;

    const moreButtonText = this.props.t && this.props.t('product_view:lblMore');

    const ellipsisTruncate = showMoreButton ? (
      <span>
        {'… '}
        {additivesElement}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="more-button">{`(${moreButtonText})`}</a>
      </span>
    ) : (
      additivesElement
    );

    let buttonText = '';

    if (product.isValidForOrder) {
      buttonText = product.hasSeveralSizes
        ? `${this.props.t ? this.props.t('product_view:lblFrom') : ''} ${
            product.getIntlPrice
          }`
        : product.getIntlPrice;
    } else {
      buttonText = this.props.t
        ? this.props.t('common:productNotAvailable')
        : '';
    }

    const templatingStyle = productCellStyle
      ? {
          headerTextColor: productCellStyle.headerTextColor,
          descriptionOpacity: productCellStyle.descriptionOpacity,
          defaultPictureIconColor: productCellStyle.defaultPictureIconColor,
          defaultPictureBackgroundColor:
            productCellStyle.defaultPictureBackgroundColor
        }
      : ({} as ITemplatingStyle);

    if (IS_CLIENT && window.innerWidth <= this.SMALL_SCREEN_WIDTH) {
      return this.renderSmallScreenMobileVersion(
        additives,
        buttonText,
        templatingStyle
      );
    }

    const isResponsiveView = isMobile && !isShell;
    const defaultImage = this.renderDefaultImage(templatingStyle);

    return (
      <div
        ref={this.containerRef}
        className={classNames('big-picture-variant-2-container', {
          'is-closed': isShopClosed
        })}
        onClick={this.onCellClick}
        data-testid="product"
      >
        <div className="big-picture-variant-2-image-container">
          {isNew && isNew.value && (
            <ProductNewBadge
              text={this.props.t && this.props.t('product_view:lblNew')}
              style={isNew.style}
            />
          )}
          <div
            className={classNames({
              'big-picture-variant2-image-wrapper': true,
              loading: !this.state.imageLoaded,
              'default-image': !product.pic_url
            })}
            style={{
              backgroundColor: templatingStyle.defaultPictureBackgroundColor
            }}
          >
            <LazyLoadComponent
              placeholder={defaultImage}
              afterLoad={() => this.updateImageLoadingStatus(true)}
            >
              {product.pic_url ? (
                <img
                  src={product.pic_url}
                  alt={product.name}
                  data-testid="product-image"
                />
              ) : (
                defaultImage
              )}
            </LazyLoadComponent>
          </div>
          <div
            className="big-picture-variant-2-title-container"
            style={{ color: templatingStyle.headerTextColor }}
          >
            {!hideProductArticles && product.no && (
              <div className="title-number">{product.no}</div>
            )}
            {this.renderTitleIcons()}
            <div className="title-name" data-testid="product-title">
              {product.name}
            </div>
            <div className="title-button-wrapper">
              <ProductStandardButton
                isClosed={isShopClosed}
                onClick={this.onButtonClick}
                text={buttonText}
                animated={animated}
                productCount={productCount}
                animationTargetBounds={this.props.animationTargetBounds}
                isSoldOut={product.soldOut}
                disabled={!product.isValidForOrder}
                mobileView={isMobile}
                buttonStyle={buttonStyle}
              />
            </div>
          </div>
        </div>
        <div className="big-picture-variant-2-description-container">
          <div
            className="measure-wrapper"
            style={{
              color: templatingStyle.headerTextColor,
              opacity: templatingStyle.descriptionOpacity
            }}
          >
            {isResponsiveView ? (
              <div className="big-picture-variant-2-description-wrapper">
                {product.description}
              </div>
            ) : (
              <Measure
                bounds
                onResize={(contentRect) => {
                  contentRect &&
                    contentRect.bounds &&
                    contentRect.bounds.width !== this.state.width &&
                    this.setState({
                      productDescriptionNeedTruncate: true,
                      width: contentRect.bounds.width
                    });
                }}
              >
                {({ measureRef }) => (
                  <div
                    ref={measureRef}
                    className="big-picture-variant-2-description-wrapper"
                    data-testid="product-description"
                  >
                    {this.state.productDescriptionNeedTruncate ? (
                      <Truncate
                        lines={2}
                        trimWhitespace
                        ellipsis={ellipsisTruncate}
                        onTruncate={(truncated: boolean) => {
                          !truncated &&
                            this.setState({
                              productDescriptionNeedTruncate: false
                            });
                        }}
                        width={this.state.width}
                      >
                        {product.description}
                        {ellipsisTruncate}
                      </Truncate>
                    ) : (
                      <>
                        {product.description}
                        {additivesElement}
                      </>
                    )}
                  </div>
                )}
              </Measure>
            )}
          </div>
          {foodIcons && !!Object.keys(foodIcons).length && (
            <div>
              <AdditiveIconList
                className="iconList"
                additives={foodIcons}
                onHalalClick={showHalalCertificate}
              />
              <button className="productInfo" onClick={this.handleInfoClick}>
                <Text dataTestId="product-info">
                  {this.props.t('product_view:productInfo')}
                </Text>
              </button>
            </div>
          )}
        </div>
        <ProductMetaData product={product} currency={currency} />
      </div>
    );
  }
}

export default withTranslation('product_view')(ProductBigPictureVariant2);
