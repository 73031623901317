import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

// Utils
import { isOdd } from '../../../../../../utils/functions';
import { generateLinkFor } from '../../../../../../utils/routing';
import states from '../../../../../../enums/states.enum';

// CSS
import './CategoryMenuDesktopHermesThemeWithoutPictures.scss';

@inject('store')
@withRouter
class CategoryMenuDesktopHermesThemeWithoutPictures extends Component {
  static propTypes = {
    activeCategory: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    hermesThemeCountOfCategories: PropTypes.number.isRequired,
    hermesThemeCountOfColumn: PropTypes.number.isRequired,
    shouldApplyWideTheme: PropTypes.bool.isRequired,
    hideActiveCategory: PropTypes.bool
  };
  static defaultProps = {};

  calculateOdd(index) {
    const { categories, hermesThemeCountOfColumn } = this.props;
    if (categories.length > 12) {
      const columnIndex = index / hermesThemeCountOfColumn;
      if (!isOdd(hermesThemeCountOfColumn, 1)) {
        if (!isOdd(columnIndex, 1)) {
          return isOdd(index + 1, 1);
        }
      }
    }
    return isOdd(index, 1);
  }

  prepareCategoryName(category, symbols) {
    const cut = category.indexOf(' ', symbols);
    if (cut === -1) return category;
    return category.substring(0, cut);
  }

  _renderCategories(categories) {
    const { activeCategory, hermesThemeCountOfCategories } = this.props;
    const activeCategoryId = activeCategory.id;
    let categoriesArray = [];
    for (let index = 0; index < hermesThemeCountOfCategories; index++) {
      const odd = this.calculateOdd(index);
      if (index < categories.length) {
        categoriesArray.push(
          this._renderCategory(categories[index], index, activeCategoryId, odd)
        );
      } else {
        categoriesArray.push(this._renderEmptyCategory(index, odd));
      }
    }
    return categoriesArray;
  }

  _renderCategory(category, index, activeCategoryId, odd) {
    const categoryIsActive =
      activeCategoryId === category.id && !this.props.hideActiveCategory;
    return (
      <Link
        key={index}
        className={classNames({
          odd: odd,
          'category-wrapper': true,
          pointer: true,
          active: categoryIsActive
        })}
        to={generateLinkFor(
          `${states.cat}/${category.id}`,
          this.props,
          { checkTransaction: null, paymentCanceled: null },
          true
        )}
        onClick={() => this.props.store.productsStore.clearSearchingRequest()}
      >
        <i
          className={classNames({
            fas: true,
            'fa-chevron-down': categoryIsActive,
            'fa-chevron-right': !categoryIsActive
          })}
        />
        {category.name.length > 23 ? (
          <span
            className={classNames({
              name: true
            })}
          >
            {this.prepareCategoryName(category.name, 15)}
          </span>
        ) : (
          <span
            className={classNames({
              name: true
            })}
          >
            {category.name}
          </span>
        )}
      </Link>
    );
  }

  _renderEmptyCategory(index, odd) {
    return (
      <span
        key={index}
        className={classNames({
          odd: odd,
          'category-wrapper': true
        })}
      ></span>
    );
  }

  render() {
    const {
      hermesThemeCountOfColumn,
      shouldApplyWideTheme,
      categories
    } = this.props;
    const columnHeight =
      hermesThemeCountOfColumn * 28 > 112 ? hermesThemeCountOfColumn * 28 : 112;
    const gridStyle = {
      height: `${columnHeight}px`
    };
    return (
      <div
        id="category-menu-desktop-hermes-theme"
        style={gridStyle}
        className={classNames({
          'desktop-only': true,
          'standard-categories': !shouldApplyWideTheme,
          'wide-categories': shouldApplyWideTheme
        })}
      >
        {this._renderCategories(
          categories.filter((category) => category.id !== -2)
        )}
      </div>
    );
  }
}
export default withTranslation(['basket'])(
  CategoryMenuDesktopHermesThemeWithoutPictures
);
