import isNin from 'lodash/isNil';

import IMPRINT_VIEW_TYPES from 'client/enums/imprint_view_types.enum';
import { BranchModel } from 'client/models/branch.model';
import { IS_CLIENT, isProduction } from 'config';

import images from '../enums/images_enums/hermes_images.enum';
import { trimValue } from '../utils/functions';

import { HermesLoading } from './restaurant.model.type';

/**
 * Restaurant model
 * @constructor
 * @property {uuid} id - Unique id of restaurant.
 * @property {string} title - Restaurant title.
 * @property {string} lang - Restaurant language.
 * @property {array} branches - Restaurant branches.
 * @property {boolean} isShowOpenings - Show "opening times" button in shell.
 * @property {boolean} isShowShare - Show "share" button in shell.
 * @property {boolean} isShowCall - Show "call us" button in shell.
 * @property {boolean} isShowNews - Show "news" button in shell.
 * @property {string} getCustomerLandingPageUrl - link to customer landing page
 * @property {boolean} homePageAvailable - is home page available
 */
export class RestaurantModel {
  id: string;

  title: string;

  lang: string;

  appstoreUrl: string;

  playstoreUrl: string;

  currency: string;

  customerLogoUrl: string;

  branches: BranchModel[];

  analyticsKey: string;

  urlWeb: string;

  urlFB: string;

  seoOrdersmartLink: string;

  seoOrdersmartLinkText: string;

  backgroundStartPage: string;

  backgroundFullScreenPicurl: string;

  isDefaultTheme: boolean;

  backgroundPicurl: string;

  backgroundRightPicurl: string;

  backgroundLeftPicurl: string;

  theme: string;

  hermesLoading: HermesLoading;

  categoriesType: string;

  productsType: string;

  seoKeywords: string[];

  seoDescription: string[];

  seoText: string;

  sani: string;

  qrPicUrl: string;

  mobileAppPic: string;

  UtcOffset: number;

  pageTitle: string;

  pageDescription: string;

  countryPrefix: string;

  hideOrdersmartLogo: boolean;

  isHomePageAvailable: boolean;

  customerLandingPageUrl: string;

  showCall: boolean;

  showShare: boolean;

  showOpenings: boolean;

  showNews: boolean;

  shellHomeBg: string;

  shellCatecoriesBg: string;

  isMaintenance: boolean;

  isMaintenanceApp: boolean;

  streetNo: string;

  street: string;

  owneremail: string;

  ownername: string;

  city: string;

  cityCode: string;

  usePayPal: boolean;

  hasGPSAreas: boolean;

  aggregatorUrl: string;

  imprintType: IMPRINT_VIEW_TYPES;

  countryCode: string;

  canonicalUrls: string;

  migratedDomain: string;

  hungerCustomer: boolean;

  isRunInsideMobileApp: boolean;

  orderStatus: string;

  useNewOrderService: boolean;

  constructor(rest, isRunInsideMobileApp: boolean) {
    Object.assign(this, rest);

    rest.template?.forEach((prop) => {
      this[prop.code] = prop.value;
    });

    rest.properties?.forEach((prop) => {
      if (!isNin(prop.valueStr)) this[prop.code] = prop.valueStr;

      if (!isNin(prop.valueNum)) this[prop.code] = prop.valueNum;

      if (!isNin(prop.valueBool)) this[prop.code] = prop.valueBool;

      if (!isNin(prop.valueArr)) this[prop.code] = prop.valueArr;
    });

    if (IS_CLIENT && this.hermesLoading && this.hermesLoading.url) {
      const preloadAnimation = new Image();

      preloadAnimation.src = this.hermesLoading.url;
    }

    this.isRunInsideMobileApp = isRunInsideMobileApp;
  }

  get getOrderStatus() {
    return this.orderStatus;
  }

  get getToken() {
    return this.id;
  }

  get getTitle() {
    return this.title;
  }

  get getLang() {
    return this.lang;
  }

  get appStoreUrl() {
    return this.appstoreUrl;
  }

  get playStoreUrl() {
    return this.playstoreUrl;
  }

  get hasAppStore() {
    return !!this.appStoreUrl;
  }

  get hasPlayStore() {
    return !!this.playStoreUrl;
  }

  get currencyCode() {
    return this.currency;
  }

  get logo() {
    return this.customerLogoUrl;
  }

  get hasOneBranch() {
    return this.countBranches === 1;
  }

  get countBranches() {
    return this.branches.length;
  }

  get firstBranchId() {
    return this.branches && this.branches[0].id;
  }

  get hasApps() {
    return this.hasAppStore || this.hasPlayStore;
  }

  get hasBothApps() {
    return this.hasAppStore && this.hasPlayStore;
  }

  /**
   * Google Analytics key from customer. Except default key 'UA-49450280-14'
   * @returns {string}
   */
  get customerGoogleAnalyticsKey() {
    const { analyticsKey } = this;
    const isDefaultKey = analyticsKey === 'UA-49450280-14';

    if (isProduction && !isDefaultKey) {
      return analyticsKey;
    }

    return '';
  }

  /**
   * Hermes 3 default Google Analytics key
   * @returns {string}
   */
  get googleAnalyticsKey() {
    const { hungerCustomer, isRunInsideMobileApp } = this;

    if (isProduction) {
      if (isRunInsideMobileApp) {
        return 'UA-49450280-21';
      }

      if (!isRunInsideMobileApp && hungerCustomer) {
        return 'UA-49450280-22';
      }

      return 'UA-49450280-14';
    }

    return 'UA-49450280-20';
  }

  /**
   * Hermes Shell app default Google Analytics key
   * @returns {string}
   */
  get shellGoogleAnalyticsKey() {
    return isProduction ? 'UA-49450280-17' : 'UA-49450280-20';
  }

  get getUrlWeb() {
    return this.urlWeb;
  }

  get facebookUrl() {
    return this.urlFB;
  }

  get getSeoOrdersmartLink() {
    return this.seoOrdersmartLink;
  }

  get getSeoOrdersmartLinkText() {
    return this.seoOrdersmartLinkText;
  }

  get getBranches() {
    return this.branches;
  }

  get allAreaCodes() {
    const parsedDeliveryAreas = [];

    this.getBranches.forEach((i) => {
      i.areaCodes.forEach((j) => {
        parsedDeliveryAreas.push({
          value: `${j.areaCode} ${j.sublocality} ${i.title}`,
          zip: j.areaCode,
          sublocality: j.sublocality,
          slug: i.slug,
          branchId: i.id,
          title: i.title,
          latitude: j.latitude,
          longitude: j.longitude
        });
      });
    });

    return parsedDeliveryAreas;
  }

  get getBackgroundStartPage() {
    return this.backgroundStartPage;
  }

  get getBackgroundFullScreenPicurl() {
    return this.backgroundFullScreenPicurl;
  }

  get getIsDefaultTheme() {
    return this.isDefaultTheme;
  }

  get getBackgroundPicurl() {
    return this.backgroundPicurl;
  }

  get getBackgroundRightPicurl() {
    return this.backgroundRightPicurl;
  }

  get getBackgroundLeftPicurl() {
    return this.backgroundLeftPicurl;
  }

  get getTheme() {
    return this.theme;
  }

  get getCategoriesType() {
    return this.categoriesType;
  }

  get getSeoKeywords() {
    return this.seoKeywords;
  }

  get getSeoDescription() {
    return this.seoDescription;
  }

  get getSeoText() {
    return this.seoText;
  }

  get getSani() {
    return this.sani;
  }

  get customerLandingPageLink() {
    return `https://store.app-smart.com/${this.getSani}`;
  }

  get getQrPicUrl() {
    return this.qrPicUrl;
  }

  get getMobileAppPic() {
    return this.mobileAppPic || images.mobileAppsPlaceholder;
  }

  get getUtcOffset() {
    return this.UtcOffset * 60;
  }

  /**
   * Returns SEO Title, which should replace computed page title
   * @returns {string}
   */
  get predefinedPageTitle() {
    return this.pageTitle || '';
  }

  /**
   * Returns SEO Title, which should replace computed page description
   * @returns {string}
   */
  get predefinedPageDescription() {
    return this.pageDescription || '';
  }

  /**
   * Country code
   * @returns {*|string}
   */
  get getCountryPrefix() {
    return this.countryPrefix || '';
  }

  /**
   * Method to return state of showing order smart marketing
   * @returns {*|boolean} true - if hide
   */
  get isHideMarketingData() {
    return this.hideOrdersmartLogo || false;
  }

  // #region Customer landing page

  /**
   * Method to get home page availability state
   * @returns {*|boolean}
   */
  get homePageAvailable() {
    return this.isHomePageAvailable || false;
  }

  /**
   * Nethod to get url of customer landing page
   * @returns {*}
   */
  get getCustomerLandingPageUrl() {
    return this.customerLandingPageUrl;
  }

  // #endregion Customer landing page

  // #region Shell Settings

  /**
   * Method to get visibility state of call button in shell
   * @returns {boolean}
   */
  get isShowCall() {
    return this.showCall || false;
  }

  /**
   * Method to get visibility state of share button in shell
   * @returns {boolean}
   */
  get isShowShare() {
    return this.showShare || false;
  }

  /**
   * Method to get visibility state of openings time button in shell
   * @returns {boolean}
   */
  get isShowOpenings() {
    return this.showOpenings || false;
  }

  /**
   * Method to get visibility state of news button in shell
   * @returns {boolean}
   */
  get isShowNews() {
    return this.showNews || false;
  }

  /**
   * Method to get link to shell homepage background
   * @returns {string}
   */
  get getShellHomepageBackgroundLink() {
    return this.shellHomeBg || '';
  }

  /**
   * Method to get link to shell categories background
   * @returns {string}
   */
  get getShellCategoriesBackgroundLink() {
    return this.shellCatecoriesBg || '';
  }

  // #endregion Shell Settings

  /**
   * Method to get maintenance mode state for webshop
   * @returns {boolean}
   */
  get hasMaintenance() {
    return this.isMaintenance || false;
  }

  /**
   * Method to get maintenance mode state for application
   * @returns {boolean}
   */
  get hasMaintenanceApp() {
    return this.isMaintenanceApp || false;
  }

  /**
   * Method for setting Mobile App Picture Url
   * @param {string} url
   */
  setMobileAppPictureUrl(url) {
    this.mobileAppPic = url;
  }

  // #region Restaurant Contacts

  /**
   * Street number
   * @returns {string}
   */
  get getStreetNo() {
    return trimValue(this.streetNo);
  }

  /**
   * Street
   * @returns {string}
   */
  get getStreet() {
    return trimValue(this.street);
  }

  /**
   * Shop owner email
   * @returns {*|string}
   */
  get getOwneremail() {
    return trimValue(this.owneremail);
  }

  /**
   * Shop owner name
   * @returns {string}
   */
  get getOwnername() {
    return trimValue(this.ownername);
  }

  /**
   * City
   * @returns {string}
   */
  get getCity() {
    return trimValue(this.city);
  }

  /**
   * City code
   * @returns {string}
   */
  get getCityCode() {
    return trimValue(this.cityCode);
  }

  /**
   * Custom loading animation
   * @return {*}
   */
  get loadingAnimation() {
    return this.hermesLoading;
  }

  /**
   * Get status of using paypal
   * @returns {boolean}
   */
  get isPaypalUsed() {
    return this.usePayPal;
  }

  /**
   * The restaurant has one or more branches with advanced or basic GPS calculation type
   */
  get hasSomeBranchCalculationTypeByDeliveryArea() {
    return this.hasGPSAreas;
  }

  get getCanonicalUrls() {
    return this.canonicalUrls.split(',');
  }

  // TODO  OSSHOP-4068 task. We must remove this as soon as the appropriate parameter is implemented on backend
  /**
   * Getter for custom addictive link
   * @deprecated
   * @return {*}
   */
  get customAddictiveLink() {
    return this.id === 'PZk1OERiqIbfBhYG'
      ? 'https://store.app-smart.com/call-to-eat/information/'
      : undefined;
  }

  /**
   * Complementary status checks of orders are allowed
   */
  get isOrderStatusCheckAllowed() {
    return true;
  }

  get getUseNewOrderService() {
    return this.useNewOrderService;
  }

  // #endregion Restaurant Contacts
}
