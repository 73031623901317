import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import BranchSelector from '../../../../../components/BranchSelector';
import LanguageSwitcher from '../../../../../components/LanguageSwitcher';
import states from '../../../../../enums/states.enum';
import { generateLinkFor } from '../../../../../utils/routing';
import SearchInputField from '../../search-input-field';

import './style.scss';

@withRouter
@inject('store')
@observer
class TitleDesktop extends Component {
  static propTypes = {
    branchName: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    branches: PropTypes.array.isRequired,
    branchId: PropTypes.string.isRequired,
    hostName: PropTypes.string.isRequired
  };

  static defaultProps = {
    hidePhone: false,
    phone: ''
  };

  onChangeSearchInput = (value) => {
    this.props.store.productsStore.setSearchingRequest(value);

    if (
      this.props.store.productsStore.isSearchEnabled &&
      this.props.store.restaurantStore.cantAddProductsToBasket
    ) {
      this.props.history.push(
        generateLinkFor(states.basket, this.props, {}, true)
      );
    }
  };

  getAllProducts = (value) => {
    if (value && !this.props.store.productsStore.isBranchProductListGot) {
      this.props.store.productsStore.loadProductsByBranch();
    }
  };

  render() {
    const { branchName, branches, branchId, hostName } = this.props;
    const restaurantHasMultipleBranch = branches.length > 1;

    const searchFieldStyle = {
      marginLeft: 'auto'
    };

    return (
      <div className="white-block" id="title-component-desktop">
        {restaurantHasMultipleBranch ? (
          <BranchSelector
            branchId={branchId}
            branchName={branchName}
            branches={branches}
            hostName={hostName}
          />
        ) : (
          <h1>{branchName}</h1>
        )}
        <SearchInputField
          style={searchFieldStyle}
          placeholder={this.props.t('product_view:productName')}
          onChange={this.onChangeSearchInput}
          onOpen={this.getAllProducts}
          value={this.props.store.productsStore.searchingRequest}
          tooltipText={this.props.t('product_view:productSearch')}
          keepOpen={!restaurantHasMultipleBranch}
        />
        <LanguageSwitcher />
      </div>
    );
  }
}

export default withTranslation(['title', 'product_view'])(TitleDesktop);
