import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { Fragment, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import openModal from 'client/utils/openModal';

import PICTURE_MODES from '../../../../../../enums/product_images.enum';
import states from '../../../../../../enums/states.enum';
import { RootContext } from '../../../../../../stores';
import {
  cantAddProductsToBasket,
  generateLinkFor
} from '../../../../../../utils/routing';
import ProductListCategoryHeader from '../../common/header/ProductListCategoryHeader';
import ProductList from '../../common/products-list/ProductList';
import ProductListProps from '../../common/products-list/ProductList.type';
import SearchHeader from '../SearchHeader';

import '../../Products.scss';

const ProductsShellWrapper = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { t } = useTranslation('product_view');
  const store = useContext(RootContext);

  const {
    basketStore,
    themesStore,
    productsStore,
    languagesStore,
    additivesStore,
    restaurantStore,
    categoryMenuStore,
    deliveryAddressStore
  } = store;

  useEffect(() => {
    categoryMenuStore.scrollContainerElement();
  }, []);

  const setScrollPosition = () => {
    const { scrollTop } = categoryMenuStore.scrollableContainerElement;

    categoryMenuStore.setContainerScrollPosition(scrollTop);
  };

  /**
   * Method to add product to basket
   * @param product
   */
  const onProductClick: ProductListProps['onCellClick'] = (event, product) => {
    event.stopPropagation();

    setScrollPosition();

    const productUrl = openModal('productModal', {
      productId: `${product.id}`
    });

    history.push(productUrl);
  };

  /**
   * Handle add button click
   * @param product
   * @param event
   * @private
   */
  const onAddProductClick: ProductListProps['onCellClick'] = (
    event,
    product
  ) => {
    event.stopPropagation();

    setScrollPosition();

    const isShell = themesStore.isShellThemeActive;
    const { branches } = restaurantStore.restaurant;
    const amountOfBranches = branches?.length || 0;

    const shouldRedirect =
      isShell &&
      !deliveryAddressStore.branchSelectedStatus &&
      !deliveryAddressStore.isDelivery &&
      amountOfBranches > 1;

    basketStore.setLastProductClicked(product.id);

    if (shouldRedirect) {
      const path = generateLinkFor(
        states.branchList,
        { store, location, match },
        {},
        true
      );

      history.push(path);

      return;
    }

    if (!product.soldOut) {
      productsStore.addProduct(product, history);
    }
  };

  const onCellClick: ProductListProps['onCellClick'] = (event, product) => {
    setScrollPosition();

    const { branch } = restaurantStore;

    branch.isUseAdvancedProductView
      ? onProductClick(event, product)
      : onAddProductClick(event, product);
  };

  /**
   * Open modal for showing halal certificate
   */
  const showHalalCertificate = (event: React.MouseEvent) => {
    event.stopPropagation();

    setScrollPosition();

    const certificateUrl = openModal('certificateModal');

    history.push(certificateUrl);
  };

  /**
   * Method for rendering category block (header and list of products)
   */
  const renderCategoryBlock = (category, isSubcategory) => {
    const { activeCategory } = categoryMenuStore;
    const { branch, isShopClosed } = restaurantStore;
    const { id } = languagesStore.activeLanguage;
    const { loadingAnimation } = restaurantStore.restaurant;
    const { additivesProducts } = additivesStore;

    const {
      animateId,
      searchProducts,
      sortedProductsListFromArray,
      isSearchEnabled
    } = productsStore;

    const badgeStyle = themesStore.productBadgeStyle();
    const products = isSearchEnabled ? searchProducts : category.products;
    const sortedProducts = sortedProductsListFromArray(products);

    let pictureMode = PICTURE_MODES.NO_PICTURES;

    pictureMode =
      branch.showBigPictureVariant1 && category.someProductHasImage
        ? PICTURE_MODES.BIG_PICTURES_VARIANT_1
        : pictureMode;

    pictureMode =
      !pictureMode &&
      branch.showBigPictureVariant2 &&
      category.someProductHasImage
        ? PICTURE_MODES.BIG_PICTURES_VARIANT_2
        : pictureMode;

    pictureMode =
      !pictureMode && branch.showSmallPicture
        ? PICTURE_MODES.SMALL_PRODUCT_PICTURES
        : pictureMode;

    const basketProducts = {};

    basketStore.products.forEach((productItem) => {
      if (!basketProducts[productItem.product.id]) {
        basketProducts[productItem.product.id] = productItem.count;
      } else {
        basketProducts[productItem.product.id] += productItem.count;
      }
    }, {});

    const basketBounds = {
      ...basketStore.bounds[states.basket][states.basket],
      offsetY: categoryMenuStore.scrollableContainerPosition
    };

    const { currencyCode } = restaurantStore.branch;
    const imageUrl = category.picurl_preview || category.picurl;

    const title = isSubcategory
      ? `${activeCategory.name} | ${category.name}`
      : category.name;

    const { searchingRequest } = productsStore;
    const searchEmptyMessage = t('noSearchResults');
    const isSearchEmpty = searchProducts.length === 0;

    const searchHeaderTitle = t('searchResultsFor', {
      request: searchingRequest
    });

    const { description } = category;
    const categoryNameStyle = themesStore.categoryNameStyle();
    const buttonStyle = themesStore.standardButtonStyle();
    const activeButtonStyle = themesStore.orderButtonStyle();
    const separatorStyle = themesStore.separatorStyle();
    const productCellStyle = themesStore.productCellStyle();
    const { previewProducts } = activeCategory;

    return (
      <Fragment key={category.id}>
        {!isSearchEnabled && (
          <ProductListCategoryHeader
            image={imageUrl}
            title={title}
            description={description}
            isShell
            categoryNameStyle={categoryNameStyle}
          />
        )}
        {isSearchEnabled && (
          <SearchHeader
            title={searchHeaderTitle}
            description={isSearchEmpty ? searchEmptyMessage : ''}
          />
        )}
        <ProductList
          isShopClosed={isShopClosed}
          picturesMode={pictureMode}
          products={sortedProducts}
          basketProducts={basketProducts}
          onCellClick={onCellClick}
          openProductModal={onProductClick}
          onButtonClick={onAddProductClick}
          showHalalCertificate={showHalalCertificate}
          categoryAdditives={category.categoryAdditives}
          activeLanguage={id}
          hideProductArticles={branch.isHideArticleNumbers}
          isUseAdvancedProductView={branch.isUseAdvancedProductView}
          styles={{
            newBadgeStyle: badgeStyle
          }}
          animatedId={animateId}
          basketBounds={basketBounds}
          loadingAnimation={loadingAnimation.url}
          isMobile
          isShell
          additivesProducts={additivesProducts}
          currency={currencyCode}
          loading={activeCategory.loading}
          productCellStyle={productCellStyle}
          buttonStyle={buttonStyle}
          activeButtonStyle={activeButtonStyle}
          separatorStyle={separatorStyle}
          productsPreviews={previewProducts}
        />
      </Fragment>
    );
  };

  let content = [];

  const { activeCategory } = categoryMenuStore;
  const { isSearchEnabled } = productsStore;

  if (activeCategory) {
    if (activeCategory.subCategories.length && !isSearchEnabled) {
      activeCategory.subCategories.forEach((cat) =>
        content.push(renderCategoryBlock(cat, true))
      );
    } else {
      content = renderCategoryBlock(activeCategory, false);
    }
  }

  return (
    <div
      id="main-products-wrapper"
      className={classNames('white-block', {
        disabled: cantAddProductsToBasket({ location }),
        shell: themesStore.isShellThemeActive
      })}
    >
      <div className="hermes-products-wrapper shell">{content}</div>
    </div>
  );
};

export default observer(ProductsShellWrapper);
