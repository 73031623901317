import { inject, observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import openModal from 'client/utils/openModal';
import { IS_CLIENT } from 'config';

import PickUpAddressInfo from '../../../../../components/PickUpAddressInfo';
import states from '../../../../../enums/states.enum';
import { COORDINATES } from '../../../../../enums/сoordinates.enum';
import { ShellModalsRouteType } from '../../../../../routes/ShellModalsRoute/ShellModalsRoute.type';
import { generateLinkFor } from '../../../../../utils/routing';
import AddressForm from '../../../components/address/address-form/address-form';
import OrderNowLabel from '../../../components/address/address-form/order-now-label';
import DateSelectAddressFormShell from '../../../components/address/common/date-select-address-form/shell';
import TimeSelectAddressFormShell from '../../../components/address/common/time-select-address-form/shell';
import DeliveryAreaSwitch from '../delivery-area-switch';
import '../../../components/address/_address.scss';
import HeaderShell from '../header-shell';
import ShellLayout from '../shell-layout';

/**
 * Delivery address component is used to show
 * delivery address form which user should fill.
 * @name ReactDeliveryAddressComponent
 */
@withRouter
@inject('store')
@observer
class BasketAddressShell extends Component {
  constructor(props) {
    super(props);

    if (IS_CLIENT) {
      if (
        !this.props.store.basketStore.hasProducts ||
        !this.props.store.basketStore.isMinValueReached ||
        this.props.store.openingHoursStore.filterDays.length === 0 ||
        this.props.store.openingHoursStore.preorderTimes.length === 0
      ) {
        this.props.history.push(
          generateLinkFor(states.basket, this.props, {}, true)
        );
      }
    }
  }

  /**
   * Action on address form submit
   * @returns {*}
   * @private
   */
  _onFormSubmit = () => {
    const { store, history } = this.props;

    const {
      basketStore,
      deliveryAddressStore,
      restaurantStore,
      analyticsStore
    } = store;

    const { branch } = restaurantStore;

    if (!basketStore.isAvailableToAdd)
      return console.warn('Can not order now!');

    if (
      (!branch.isSimplePickUpForm &&
        !deliveryAddressStore.isFullAddressEntered) ||
      (branch.isSimplePickUpForm &&
        !deliveryAddressStore.isSimplePickUpAddressEntered)
    )
      return false;

    deliveryAddressStore.saveAddressAndOrderType();

    history.push(generateLinkFor(states.checkout, this.props, {}, true));

    analyticsStore.sendProvidePersonalInformation();
  };

  /**
   * Action on cancel
   * @private
   */
  _handleCancel = () => {
    this.props.store.offerStore.isFreeItemModalShowedBeforeOrder = false;

    this.props.history.push(
      generateLinkFor(states.basket, this.props, {}, true)
    );
  };

  /**
   * Action on change time
   * @param value
   * @private
   */
  _changeDeliveryTime = (value) => {
    this.props.store.deliveryAddressStore.changeDeliveryTime(value);
  };

  /**
   * Action on change day
   * @param value
   * @private
   */
  _changeDay = (value) => {
    this.props.store.openingHoursStore.generatePreorderTimes(value);
  };

  /**
   * Method to handle change order type button action
   * @param {string} type
   * @private
   */
  _changeOrderType = (type) => {
    this.props.store.deliveryAddressStore.changeOrderType(type);
  };

  /**
   * Method to handle area button action
   * @private
   */
  _areaButtonAction = () => {
    if (this.props.store.themesStore.isPortalAppThemeActive) {
      return;
    }

    const addressModalUrl = openModal(ShellModalsRouteType.ADDRESS_MODAL);

    this.props.store.basketStore.setLastProductClicked();

    this.props.history.push(addressModalUrl);
  };

  /**
   * Action to click back button
   * @private
   */
  _backAction = () => {
    this.props.history.push(generateLinkFor(states.cat, this.props, {}, true));
  };

  /**
   * Method to render shell/mobile/desktop heading panel
   * @param {string} currentAreaCode - current Area Code
   * @param {boolean} isDelivery - is delivery type is active
   * @param {function} changeOrderTypeAction - action on changing order type
   * @param {function} areaButtonAction - action on click area button
   * @param {boolean} isDeliveryEnabled - if delivery available
   * @param {boolean} isPickupEnabled - if pickup available
   * @returns {*}
   * @private
   */
  _renderHeadingPanel = (
    addressPostalCode,
    isDelivery,
    changeOrderTypeAction,
    areaButtonAction,
    isDeliveryEnabled,
    isPickupEnabled,
    langKey,
    useCalculationTypeByDeliveryArea,
    address,
    fullStreetAddressExists
  ) => (
    <DeliveryAreaSwitch
      langKey={langKey}
      areaCode={addressPostalCode}
      currentAddress={fullStreetAddressExists ? address.city : undefined}
      isAddressNeeded={useCalculationTypeByDeliveryArea}
      isDelivery={isDelivery}
      changeOrderTypeAction={changeOrderTypeAction}
      areaButtonAction={areaButtonAction}
      isDeliveryEnabled={isDeliveryEnabled}
      isPickupEnabled={isPickupEnabled}
      deliverySwitchStyle={this.props.store.themesStore.deliverySwitchStyle()}
      postalCodeStyle={this.props.store.themesStore.postalCodeStyle()}
    />
  );

  /**
   * Method to render date select
   * @param isDelivery - is delivery active
   * @param todayDay - dayjs today object
   * @param filterDays - filtered work days
   * @param restaurantTime - restaurant work time
   * @param isClosedForToday - is restaurant closed today
   * @param changeDeliveryDate - func to change delivery date
   * @returns {*}
   * @private
   */
  _renderDateSelect = (
    isDelivery,
    todayDay,
    filterDays,
    restaurantTime,
    isClosedForToday,
    changeDeliveryDate
  ) => (
    <DateSelectAddressFormShell
      isDelivery={isDelivery}
      todayDay={todayDay}
      filterDays={filterDays}
      restaurantTime={restaurantTime}
      isClosedForToday={isClosedForToday}
      changeDeliveryDate={changeDeliveryDate}
    />
  );

  /**
   * Method to render time select
   * @param isDelivery - is delivery active
   * @param changeDeliveryTime - func to change delivery time
   * @param preorderTimes
   * @param currentPreorderValue
   * @returns {*}
   * @private
   */
  _renderTimeSelect = (
    isDelivery,
    changeDeliveryTime,
    preorderTimes,
    currentPreorderValue
  ) => (
    <TimeSelectAddressFormShell
      isDelivery={isDelivery}
      changeDeliveryTime={changeDeliveryTime}
      preorderTimes={preorderTimes}
      currentPreorderValue={currentPreorderValue}
    />
  );

  /**
   * Method to render checkout header
   * @returns {*}
   * @private
   */
  _renderHeader = () => (
    <HeaderShell
      title={this.props.t('pickupInfo')}
      backAction={() => this.props.history.goBack()}
      style={this.props.store.themesStore.headerStyle()}
      isPortalAppThemeActive={
        this.props.store.themesStore.isPortalAppThemeActive
      }
      branchName={this.props.store.restaurantStore.branch.branchName}
    />
  );

  /**
   * Method to render checkout content
   * @returns {*}
   * @private
   */
  _renderContent = () => {
    const { restaurantTime, branch } = this.props.store.restaurantStore;

    const {
      isDelivery,
      hasDelivery,
      hasPickup,
      currentPreorderValue,
      address,
      fullStreetAddressExists,
      addressPostalCode,
      activeOrderType
    } = this.props.store.deliveryAddressStore;

    const {
      activeDay,
      preorderTimes,
      filterDays,
      isClosedForNow,
      currentOrderTypeHasPreorder
    } = this.props.store.openingHoursStore;

    const {
      useCalculationTypeByDeliveryArea
    } = this.props.store.restaurantStore;

    const { id } = this.props.store.languagesStore.activeLanguage;

    return (
      <div id="address-form-component" className="address-shell">
        {!isDelivery &&
          branch.isShowLocation &&
          branch.lat !== COORDINATES.DEFAULT_LAT &&
          branch.long !== COORDINATES.DEFAULT_LONG &&
          branch.lat !== COORDINATES.EMPTY &&
          branch.long !== COORDINATES.EMPTY && <PickUpAddressInfo />}

        {this._renderHeadingPanel(
          addressPostalCode,
          isDelivery,
          this._changeOrderType,
          this._areaButtonAction,
          hasDelivery,
          hasPickup,
          id,
          useCalculationTypeByDeliveryArea,
          address,
          fullStreetAddressExists
        )}

        {currentOrderTypeHasPreorder &&
          this._renderDateSelect(
            isDelivery,
            activeDay,
            filterDays,
            restaurantTime,
            isClosedForNow,
            this._changeDay
          )}

        {currentOrderTypeHasPreorder &&
          this._renderTimeSelect(
            isDelivery,
            this._changeDeliveryTime,
            preorderTimes,
            currentPreorderValue
          )}

        {!currentOrderTypeHasPreorder && (
          <OrderNowLabel isDelivery={isDelivery} t={this.props.t} />
        )}

        <AddressForm
          isDisableChangingAddress={false}
          onSubmitClick={this._onFormSubmit}
          onCancelClick={this._handleCancel}
        />
      </div>
    );
  };

  render() {
    return (
      <ShellLayout
        shellHeader={this._renderHeader()}
        shellContent={this._renderContent()}
      />
    );
  }
}

export default withTranslation(['address_form'])(BasketAddressShell);
