import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { createRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import RootStore from 'client/stores';
import { IS_CLIENT } from 'config';

import states from '../../../../../../enums/states.enum';
import getCurrentBrowser from '../../../../../../utils/getCurrentBrowser';
import { isHungerDomain } from '../../../../../../utils/helpers';
import openModal from '../../../../../../utils/openModal';
import { generateLinkFor } from '../../../../../../utils/routing';
import SearchInputField from '../../../search-input-field';
import NavigationMenu from '../navigation_menu';
import './style.scss';

type StickyHeaderMobileProps = WithTranslation &
  RouteComponentProps & {
    setDefaultCategory: (args: any) => void;
    isTableReservationAvailable: boolean;
    isHideMarketingData: boolean;
    branchName?: string;
    showSearch: boolean;
    store?: RootStore;
    isPortalWebThemeActive?: boolean;
    backAction: () => void;
  };

type StickyHeaderMobileState = {
  isCollapsed: boolean;
  hideBranchName: boolean;
};

/**
 * Component to render Sticky Mobile Header
 */
@(withRouter as any)
@inject('store')
@observer
class StickyHeaderMobile extends React.Component<
  StickyHeaderMobileProps,
  StickyHeaderMobileState
> {
  static defaultProps = {
    branchName: undefined,
    showSearch: false
  };

  state: StickyHeaderMobileState = {
    isCollapsed: true,
    hideBranchName: false
  };

  headerContainerRef = createRef<HTMLDivElement>();

  branchNameElementRef = createRef<HTMLDivElement>();

  branchNameElementTextRef = createRef<HTMLSpanElement>();

  menuButtonRef = createRef<HTMLButtonElement>();

  componentDidUpdate(
    _: StickyHeaderMobileProps,
    prevState: StickyHeaderMobileState
  ) {
    const { isCollapsed } = this.state;

    if (prevState.isCollapsed !== isCollapsed) {
      this.fixSafariOverscroll();
    }
  }

  fixSafariOverscroll = () => {
    const { iOS } = getCurrentBrowser();
    const { isCollapsed } = this.state;

    if (!iOS) {
      return;
    }

    if (!isCollapsed) {
      document.body.style.position = 'fixed';

      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.position = 'static';

      document.body.style.overflow = 'auto';
    }
  };

  handleIsCollapsed = (value: boolean): void => {
    this.setState({
      isCollapsed: value
    });

    const { themesStore } = this.props.store;

    themesStore.setHeaderIsCollapsed(value);
  };

  _openMenu = () => {
    const { themesStore, productsStore } = this.props.store;
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newState = !themesStore.getHeaderIsCollapsed;

    this.setState({ isCollapsed: newState });

    themesStore.setHeaderIsCollapsed(newState);

    productsStore.clearSearchingRequest();
  };

  _renderMenu = () => (
    <NavigationMenu
      isTableReservationAvailable={this.props.isTableReservationAvailable}
      isHideMarketingData={this.props.isHideMarketingData}
      closeAction={this._openMenu}
      setDefaultCategory={this.props.setDefaultCategory}
      onIsCollapsed={this.handleIsCollapsed}
    />
  );

  /**
   * Method to render branch name
   * @returns {null}
   * @private
   */
  _renderBranchName = () => (
    <div
      className={classNames({
        'branch-name': true,
        hide: this.state.hideBranchName
      })}
      ref={this.branchNameElementRef}
    >
      <h1 className="branch-name-title" ref={this.branchNameElementTextRef}>
        {this.props.branchName}
      </h1>
    </div>
  );

  _renderBackButton = () => (
    <button
      onClick={this.props.backAction}
      className={classNames('back-icon', 'header-shell-button')}
      data-testid="back-top-btn"
    >
      <i className="mdi mdi-chevron-left" />
    </button>
  );

  onChangeSearchInput = (value: string) => {
    this.props.store?.productsStore.setSearchingRequest(value);

    if (this.props.store?.productsStore.isSearchEnabled) {
      this.props.history.push(
        generateLinkFor(states.app, this.props, {}, true)
      );
    }
  };

  onOpenSearchField = (value: boolean) => {
    const hideBranchName =
      (document.getElementsByTagName('html')[0].offsetWidth <= 320 ||
        this.toCalculateHidingBranchName()) &&
      value;

    this.setState({
      hideBranchName
    });
  };

  toCalculateHidingBranchName = () => {
    if (
      IS_CLIENT &&
      this.headerContainerRef.current &&
      this.branchNameElementRef.current &&
      this.branchNameElementTextRef.current &&
      this.menuButtonRef.current
    ) {
      const searchInputFieldInOpenStateWidth = 210; // This value is taken from zeplin
      const searchInputFieldMarginRight = window.innerWidth <= 320 ? 13 : 23;

      return (
        this.headerContainerRef.current.offsetWidth -
          (searchInputFieldInOpenStateWidth + searchInputFieldMarginRight) -
          (this.branchNameElementTextRef.current.offsetWidth +
            parseInt(
              getComputedStyle(this.branchNameElementRef.current)
                .paddingLeft.split('')
                .slice(0, -2)
                .join(''),
              10
            )) -
          this.menuButtonRef.current.offsetWidth <
        0
      );
    }

    return false;
  };

  render() {
    const { isPortalWebThemeActive } = this.props;
    const showBackButton = isPortalWebThemeActive && !isHungerDomain();
    const { themesStore, productsStore } = this.props.store;

    const searchFieldStyle = {
      marginLeft: 'auto'
    };

    return (
      <>
        <div
          className={classNames('sticky-header-mobile', {
            'is-mobile-search': productsStore.isSearchEnabled
          })}
          ref={this.headerContainerRef}
        >
          {showBackButton && this._renderBackButton()}
          {this._renderBranchName()}
          {this.props.showSearch && (
            <SearchInputField
              style={searchFieldStyle}
              placeholder={this.props.t!('productName')}
              onChange={this.onChangeSearchInput}
              onOpen={this.onOpenSearchField}
              value={this.props.store?.productsStore.searchingRequest}
              fullWidth={this.toCalculateHidingBranchName()}
              isMobile={themesStore.isMobile}
            />
          )}
          <button
            className="menu-toggle-btn"
            onClick={this._openMenu}
            ref={this.menuButtonRef}
          >
            <i
              className={classNames({
                'mdi mdi-menu': themesStore.getHeaderIsCollapsed,
                'mdi mdi-close': !themesStore.getHeaderIsCollapsed
              })}
            />
          </button>
        </div>
        {!themesStore.getHeaderIsCollapsed && this._renderMenu()}
      </>
    );
  }
}

export default withTranslation(['product_view'])(StickyHeaderMobile);
