import React, { memo, useContext } from 'react';

import { RootContext } from 'client/stores';

import ShippingFormsControllerFC from './ShippingFormsController';

const ShippingFormsController = () => {
  const { deliveryAddressStore, restaurantStore } = useContext(RootContext);

  return (
    <ShippingFormsControllerFC
      selectedDeliveryType={deliveryAddressStore.activeOrderType}
      isAdvancedDelivery={!!restaurantStore.useCalculationTypeByDeliveryArea}
    />
  );
};

export default memo(ShippingFormsController);
