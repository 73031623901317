import { toJS } from 'mobx';

import { roundTo2Decimals, roundTo3Decimals } from '../utils/functions';

/**
 * PayPal Request Model class.
 * Used for prepare data for paypal request.
 * @constructor
 * @param {object} item - identical item of basket.
 * @param {number} count - count of item in basket.
 * @property {number} quan - number of identical products.
 * @property {number} price - product price.
 * @property {string} iname - product name.
 * @property {number} discount - discount.
 * @throws {Error} if parameters are invalid.
 */
export class PayPalRequestModel {
  constructor(item, count, stores) {
    this.quan = count;

    this.price = item.offerType
      ? roundTo2Decimals(item.totalPrice)
      : roundTo2Decimals(item.priceWithIngredients);

    this.iname = item.offerType ? item.title : item.name;

    if (item.offerType) {
      if (
        (stores.deliveryAddressStore.isDelivery &&
          item.availableForDeliveryDiscount) ||
        (!stores.deliveryAddressStore.isDelivery &&
          item.availableForSelfcollectDiscount)
      ) {
        this.discount = roundTo3Decimals(
          this.price * (stores.basketStore.discountPercentage / 100) * this.quan
        );
      }
    } else if (
      (stores.deliveryAddressStore.isDelivery && item.discount_all) ||
      (!stores.deliveryAddressStore.isDelivery && item.discount)
    ) {
      this.discount = roundTo3Decimals(
        this.price * (stores.basketStore.discountPercentage / 100) * this.quan
      );
    }
  }

  quan;

  price;

  iname;

  discount = 0;

  /**
   * Method to serialize PayPal Request to pure JS object.
   * @return {object} - serialized PayPal Request
   */
  getToJS() {
    return toJS({ ...this, root: {} });
  }
}
