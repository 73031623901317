import classNames from 'classnames';
import React, { CSSProperties, PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { IDay } from 'client/models/day.model';

import {
  generateWorktimeForDay,
  getDayShort,
  isHolidayDay
} from '../../../../../../utils/time';
import './OpeningTimesCell.scss';

interface IOpeningTimesCell {
  pickupTimes: IDay;
  deliveryTimes: IDay;
  pickupAvailable: boolean;
  deliveryAvailable: boolean;
  todayWeekDay: number;
  todayStyle: CSSProperties;
}

/**
 * Component to render one cell with work times
 */
@(withTranslation(['opening_hours']) as any)
class OpeningTimesCell extends PureComponent<
  IOpeningTimesCell & WithTranslation
> {
  /**
   * Method to render work time
   * @param pickupTimes - current pickup time
   * @param deliveryTimes - current delivery time
   */
  _renderWorkTime = (pickupTimes: IDay, deliveryTimes: IDay) => {
    const { pickupAvailable, deliveryAvailable } = this.props;

    return (
      <div className="worktime">
        {pickupAvailable && (
          <div className="pickup">
            <i className="fas fa-clock" />
            {generateWorktimeForDay(pickupTimes)}
          </div>
        )}
        {deliveryAvailable && (
          <div className="delivery">
            <i className="fas fa-motorcycle" />
            {generateWorktimeForDay(deliveryTimes)}
          </div>
        )}
      </div>
    );
  };

  /**
   * Method to render holiday day title
   * @param day - day of week
   */
  _renderHolidayTitle = (day: number) =>
    isHolidayDay(day) ? (
      <div className="holiday">{this.props.t('openingHoursHolidays')}</div>
    ) : null;

  render() {
    const { pickupTimes, deliveryTimes, todayWeekDay, todayStyle } = this.props;
    const cellDay = getDayShort(pickupTimes.day, true);
    const isToday = todayWeekDay === pickupTimes.day;

    return (
      <>
        {this._renderHolidayTitle(pickupTimes.day)}
        <figure
          className={classNames({
            'opening-times-cell': true,
            'opening-times-cell-today': isToday
          })}
          style={isToday ? todayStyle : undefined}
        >
          <main>
            <div className="weekday">{cellDay}</div>
            {this._renderWorkTime(pickupTimes, deliveryTimes)}
          </main>
        </figure>
      </>
    );
  }
}

export default OpeningTimesCell;
