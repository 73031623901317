import { inject } from 'mobx-react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import images from '../../../../enums/images_enums/hermes_images.enum';
import languagesImages from '../../../../enums/images_enums/languages_images.enum';
import {
  APP_STORE_IMAGES,
  GOOGLE_PLAY_IMAGES
} from '../../../../enums/images_enums/mobile_apps_images.enum';

import AppWidget from './_app_widget';

import './_widget-wrapper.scss';

@withRouter
@inject('store')
/**
 * Component to render widget panel
 */
class WidgetWrapper extends React.PureComponent {
  render() {
    const { isHunger } = this.props.store.themesStore;

    if (isHunger) {
      return null;
    }

    const {
      customerLandingPageLink,
      hasAppStore,
      hasPlayStore,
      hasBothApps,
      appStoreUrl,
      playStoreUrl,
      getQrPicUrl,
      getMobileAppPic,
      hasApps,
      isHideMarketingData
    } = this.props.store.restaurantStore.restaurant;

    const { activeLanguage } = this.props.store.languagesStore;
    const languageParent = languagesImages[activeLanguage.id].parent;

    return (
      <div id="widget-wrapper">
        <AppWidget
          customerLandingPageLink={customerLandingPageLink}
          hasAppStore={hasAppStore}
          hasPlayStore={hasPlayStore}
          hasBothApps={hasBothApps}
          appStoreUrl={appStoreUrl}
          playStoreUrl={playStoreUrl}
          getQrPicUrl={getQrPicUrl}
          getMobileAppPic={getMobileAppPic}
          hasApps={hasApps}
          ordersmartLogo={images.orderSmartLogo}
          appStoreImage={APP_STORE_IMAGES[languageParent]}
          googlePlayImage={GOOGLE_PLAY_IMAGES[languageParent]}
          isHideMarketingData={isHideMarketingData}
        />
      </div>
    );
  }
}

export default withTranslation(['footer'])(WidgetWrapper);
