// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdditiveIcon_AdditiveIcon__1pZoc {\n  font-size: 0;\n  height: 20px;\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content; }\n  .AdditiveIcon_AdditiveIcon__1pZoc svg,\n  .AdditiveIcon_AdditiveIcon__1pZoc img {\n    height: 20px;\n    -o-object-fit: contain;\n       object-fit: contain;\n    width: 20px; }\n", "",{"version":3,"sources":["webpack://src/client/ui/AdditiveIcon/AdditiveIcon.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,0BAAkB;EAAlB,uBAAkB;EAAlB,kBAAkB,EAAE;EACpB;;IAEE,YAAY;IACZ,sBAAmB;OAAnB,mBAAmB;IACnB,WAAW,EAAE","sourcesContent":[".AdditiveIcon {\n  font-size: 0;\n  height: 20px;\n  width: fit-content; }\n  .AdditiveIcon svg,\n  .AdditiveIcon img {\n    height: 20px;\n    object-fit: contain;\n    width: 20px; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AdditiveIcon": "AdditiveIcon_AdditiveIcon__1pZoc"
};
export default ___CSS_LOADER_EXPORT___;
