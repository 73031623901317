import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';

import useOutsideClick from 'client/hooks/useOutsideClick';

import css from './Modal.module.scss';
import { ModalProps } from './Modal.type';

const Modal = ({
  className,
  children,
  onClose,
  closable = true,
  style
}: ModalProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useOutsideClick([ref], closable && onClose ? onClose : () => null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  return (
    <div className={classnames(css.Modal, className)} style={style}>
      <div className={css.modalContainer} ref={ref}>
        {children}
      </div>
    </div>
  );
};

export { Modal };

export default React.memo(Modal);
