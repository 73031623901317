import React from 'react';

import style from './SearchHeader.module.scss';
import { SearchHeaderProps } from './SearchHeader.types';

const SearchHeader = ({ title, description }: SearchHeaderProps) => (
  <div className={style.SearchHeader}>
    <div className={style.Title}>{title}</div>
    {description && <div className={style.Description}>{description}</div>}
  </div>
);

export default SearchHeader;
