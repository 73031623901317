/**
 * Calculation types for delivery enum
 */
enum DELIVERY_CALCULATION_TYPES {
  SIMPLE = 'Simple',
  ADVANCED = 'Advanced',
  BASIC_GPS = 'Basic'
}

export default DELIVERY_CALCULATION_TYPES;
