import classnames from 'classnames';
import React from 'react';

import css from './Button.module.scss';
import { ButtonProps } from './Button.type';

const Button = ({
  className,
  onClick,
  children,
  disabled = false,
  type = 'button',
  variant = 'regular',
  dataTestId
}: ButtonProps) => {
  const classes = [css.Button, className];

  if (variant === 'submit') {
    classes.push(css.submit);
  }

  return (
    <button
      className={classnames(classes)}
      disabled={disabled}
      onClick={onClick}
      type={type}
      data-testid={dataTestId}
    >
      <p>{children}</p>
    </button>
  );
};

export { Button };

export default React.memo(Button);
