import React, { PureComponent } from 'react';
import { PropTypes } from 'mobx-react';
import PropTypesReact from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { inject } from 'mobx-react';
import classNames from 'classnames';
import { generateLinkFor } from '../../../../../../utils/routing';
import states from '../../../../../../enums/states.enum';

// CSS
import './CategoryMenuDesktopHermesWithPictures.scss';

@inject('store')
@withRouter
/**
 * Component to render desktop categories with images
 */
export default class CategoryMenuDesktopHermesWithPictures extends PureComponent {
  static propTypes = {
    activeCategory: PropTypesReact.object.isRequired,
    categories: PropTypes.observableArray.isRequired,
    hermesThemeCountOfCategories: PropTypesReact.number.isRequired,
    hermesThemeCountOfColumn: PropTypesReact.number.isRequired,
    widthHermesThemeWithPics: PropTypesReact.number.isRequired,
    hideActiveCategory: PropTypesReact.bool
  };
  static defaultProps = {};

  prepareCategoryName(category, symbols) {
    const cut = category.indexOf(' ', symbols);
    if (cut === -1) return category;
    return category.substring(0, cut);
  }

  _renderCategories(categories) {
    const { activeCategory, hermesThemeCountOfCategories } = this.props;
    const activeCategoryId = activeCategory.id;
    let categoriesArray = [];
    for (let index = 0; index < hermesThemeCountOfCategories; index++) {
      if (index < categories.length) {
        categoriesArray.push(
          this._renderCategory(categories[index], index, activeCategoryId)
        );
      } else {
        categoriesArray.push(this._renderEmptyCategory(index));
      }
    }
    return categoriesArray;
  }

  /**
   * Method to render category
   * @param {CategoryModel} category - category item
   * @param {number} index - index of category
   * @param {number} activeCategoryId - id of active category
   */
  _renderCategory(category, index, activeCategoryId) {
    const { widthHermesThemeWithPics, hideActiveCategory } = this.props;
    let picUrl = category.picurl_preview || category.picurl;

    if (category.subCategories && category.subCategories.length > 0) {
      picUrl =
        category.subCategories[0].picurl_preview ||
        category.subCategories[0].picurl;
    }

    const gridStyle = {
      width: `${widthHermesThemeWithPics}%`,
      backgroundImage: `url(${picUrl})`
    };

    const categoryIsActive =
      activeCategoryId === category.id && !hideActiveCategory;

    return (
      <Link
        key={index}
        style={gridStyle}
        className={classNames({
          'category-wrapper': true,
          pointer: true,
          active: categoryIsActive
        })}
        to={generateLinkFor(
          `${states.cat}/${category.id}`,
          this.props,
          { checkTransaction: null, paymentCanceled: null },
          true
        )}
        onClick={() => this.props.store.productsStore.clearSearchingRequest()}
      >
        <div className="category-name-wrapper">
          <i
            className={classNames({
              fas: true,
              'fa-chevron-down': categoryIsActive,
              'fa-chevron-right': !categoryIsActive
            })}
          />
          {category.name.length > 23 ? (
            <span
              className={classNames({
                name: true
              })}
            >
              {this.prepareCategoryName(category.name, 15)}
            </span>
          ) : (
            <span
              className={classNames({
                name: true
              })}
            >
              {category.name}
            </span>
          )}
        </div>
      </Link>
    );
  }

  _renderEmptyCategory(index) {
    return (
      <span
        key={index}
        className={classNames({
          'category-wrapper': true
        })}
      ></span>
    );
  }

  render() {
    const { categories, hermesThemeCountOfColumn } = this.props;
    const columnHeight =
      hermesThemeCountOfColumn * 60 > 120 ? hermesThemeCountOfColumn * 60 : 120;
    const gridStyle = {
      height: `${columnHeight}px`
    };
    return (
      <div
        id="category-menu-desktop-hermes-theme-with-pics"
        style={gridStyle}
        className={classNames({
          'desktop-only': true,
          'high-categories': categories.length <= 4
        })}
      >
        {this._renderCategories(
          categories.filter((category) => category.id !== -2)
        )}
      </div>
    );
  }
}
