import { toJS } from 'mobx';

export class CouponModel {
  constructor(coupon) {
    Object.assign(this, coupon);
    this.amount = coupon.amount;
    this.valid_until = coupon.valid_until;
    this.platform = coupon.platform;
    this.before_mbv = coupon.before_mbv;
    this.is_fixed = coupon.is_fixed;
    this.mbv = coupon.mbv;
  }

  amount = 0;
  platform = '';
  valid_until = 0;
  before_mbv = 0;
  is_fixed;
  mbv = 0;

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  getToJS() {
    return toJS(Object.assign({}, this, { root: {} }));
  }
}
