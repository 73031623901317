/* eslint-disable global-require,import/no-dynamic-require */
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

import i18n from '../../../i18n';
import { dayjsConfiguration } from '../../locales/dayjs.common';

class LanguageService {
  selectLanguage = (langCode: AppLang): void => {
    i18n.changeLanguage(langCode);

    Cookies.set('currentLanguageCode', langCode, { expires: 90 });

    require(`dayjs/locale/${langCode}.js`);

    dayjs.locale(langCode);

    dayjs.updateLocale(langCode, dayjsConfiguration());
  };
}

const languageService = new LanguageService();

export default languageService;
