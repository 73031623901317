import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

import ButtonStandard from '../../../buttons/ButtonStandard';
import SmallLoader from '../../../loaders/small_loader';

import { IProductEmptyListProps } from './props.interface';
import './ProductEmptyList.scss';

const ProductEmptyList = ({
  message,
  buttonLabel = '',
  onButtonClick = () => null,
  loadingAnimation,
  setSize,
  additionalClass
}: IProductEmptyListProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (setSize && containerRef.current) {
      setSize(containerRef.current.offsetHeight);
    }
  }, []);

  return (
    <div
      ref={containerRef}
      className={classNames('product-empty-list-container', {
        [`${additionalClass}`]: additionalClass
      })}
    >
      {!loadingAnimation && additionalClass !== 'is-mobile-search' && (
        <div className="product-empty-list-message">{message}</div>
      )}
      {buttonLabel ? (
        <ButtonStandard
          onClick={onButtonClick}
          customClass="product-empty-list-button"
          label={buttonLabel}
        />
      ) : null}
      {loadingAnimation && <SmallLoader loaderUrl={loadingAnimation} />}
    </div>
  );
};

export default ProductEmptyList;
