// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectField_SelectField__btAS- .SelectField_label__1umge {\n  font-size: 14px; }\n\n.SelectField_SelectField__btAS- .SelectField_error__20wdt {\n  color: var(--primaryError); }\n\n.SelectField_Story__-Z2pM {\n  max-width: 500px; }\n", "",{"version":3,"sources":["webpack://src/client/ui/SelectField/SelectField.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE;;AAEnB;EACE,0BAA0B,EAAE;;AAE9B;EACE,gBAAgB,EAAE","sourcesContent":[".SelectField .label {\n  font-size: 14px; }\n\n.SelectField .error {\n  color: var(--primaryError); }\n\n.Story {\n  max-width: 500px; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectField": "SelectField_SelectField__btAS-",
	"label": "SelectField_label__1umge",
	"error": "SelectField_error__20wdt",
	"Story": "SelectField_Story__-Z2pM"
};
export default ___CSS_LOADER_EXPORT___;
