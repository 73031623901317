/* eslint-disable no-restricted-globals */
import classNames from 'classnames';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { IArea } from 'client/models/area.model';
import googleService from 'client/services/googleService';
import { mapQueryParamsToObject } from 'client/utils/functions';
import { getUtmParamsFromSearch } from 'client/utils/helpers';
import { IS_CLIENT } from 'config';

import AddressAutocomplete, {
  Address
} from '../../../../components/address-automplete';
import Metadata from '../../../../components/meta_data';
import MetadataTypes from '../../../../enums/metadata.enums';
import { ORDER_TYPES } from '../../../../enums/order_types.enum';
import StartPageRoute from '../../../../routes/start-page-route';
import BranchCard from '../../../../ui/BranchCard';
import Select2, { Option } from '../../../../ui/Select';
import { prepareDataInStores } from '../../../../utils/boot';
import { getIsSlugSupported } from '../../../../utils/routing';
import SmallLoader from '../loaders/small_loader';

import { IStartProps } from './props.interface';
import './start.scss';
import './StartPageSelector.scss';

type DeliveryArea = {
  slug?: string;
  branchId: string;
  zip?: string;
  sublocality?: string;
  id: string;
};

interface IStartState {
  currentDeliveryArea: DeliveryArea | null;
  showAreaSelect: boolean;
  addressField: string;
  isHouseAddress: boolean;
  selectedAddress: Address | null;
  searchExecuted: boolean;
  error: string;
  selectedArea: IArea | null;
  isServer: boolean;
  currentWidth: number;
  isRedirect: boolean;
}

@inject('store')
@observer
@(withRouter as any)
class Start extends Component<IStartProps, IStartState> {
  boxRef = React.createRef<HTMLDivElement>();

  constructor(props: IStartProps) {
    super(props);

    const address = IS_CLIENT ? localStorage.getItem('selectedAddress') : null;
    const selectedAddress = address ? JSON.parse(address) : null;

    this.state = {
      currentDeliveryArea: null,
      showAreaSelect: false,
      selectedAddress,
      addressField: '',
      searchExecuted: false,
      isHouseAddress: false,
      error: '',
      selectedArea: null,
      isServer: true,
      currentWidth: 1000,
      isRedirect: true
    };

    if (IS_CLIENT) {
      const params = location ? mapQueryParamsToObject(location.search) : {};

      prepareDataInStores(this.props.store, params);
    }

    reaction(
      () => this.props.store?.deliveryAddressStore.orderTypes,
      () => {
        IS_CLIENT && this.forceUpdate();
      }
    );
  }

  componentDidMount() {
    const { store } = this.props;
    const { id } = store?.restaurantStore.restaurant;
    const address = localStorage.getItem(`selectedAddress${id}`);
    const selectedAddress = address ? JSON.parse(address) : null;

    this.props.store?.restaurantStore.saveBuildVersion();

    this.props.store?.deliveryAddressStore.loadOrderTypeFromStorage();

    window.addEventListener('resize', this.handleResize);

    this.showSelects(selectedAddress);

    this.setBoxHeight();
  }

  showSelects = (selectedAddress: Address) => {
    this.setState({
      showAreaSelect: true,
      isServer: false,
      selectedAddress,
      addressField: selectedAddress?.value ?? '',
      currentWidth: window.innerWidth
    });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    const { showAreaSelect } = this.state;

    if (showAreaSelect !== prevState.showAreaSelect) {
      this.removeBoxHeight();
    }
  }

  setBoxHeight = () => {
    const { current } = this.boxRef;
    const { innerWidth } = window;

    if (innerWidth > 650) {
      current.style.maxHeight = `${current.clientHeight}px`;
    }
  };

  removeBoxHeight = () => {
    const { current } = this.boxRef;
    const { innerWidth } = window;

    if (innerWidth > 650) {
      current.style.maxHeight = `${650}px`;
    }
  };

  handleResize = () => {
    this.setState({ currentWidth: window.innerWidth });
  };

  _handleChangeAddress = (value: string) => {
    const { error } = this.state;

    this.setState({
      addressField: value,
      isHouseAddress: false,
      selectedAddress: null,
      searchExecuted: true,
      error: value
        ? error
        : this.props.t('address_form:enterPleaseStreetAddress')
    });

    return value;
  };

  _handleSelectAddress = (option: Option<Address>) => {
    const { label, value } = option;
    const { isHouseAddress } = value;
    const inputValue = `${label}${isHouseAddress ? '' : ' '}`;

    if (option) {
      this.setState({
        selectedAddress: value,
        addressField: inputValue,
        error: '',
        isHouseAddress
      });
    }

    if (isHouseAddress) {
      this.redirectWithLocation(value);
    }
  };

  redirectWithLocation = async (value: Address) => {
    await this.setState({
      isRedirect: false
    });

    const { store, history, t } = this.props;

    const branch = await store?.thirdPartyServicesStore.getBranchForGpsRedirect(
      value
    );

    if (!branch) {
      return this.setState({
        error: t('delivery_info:addressIsOutsideDeliveryArea')
      });
    }

    const { search } = history.location;
    const utmParams = getUtmParamsFromSearch(search);
    const searchWithUtm = new URLSearchParams(utmParams);
    const pathParameters = `?${searchWithUtm.toString()}`;

    this.resetStoreState();

    const [googleAddress] = await googleService.getPlaceInfo(value.place_id);

    store?.deliveryAddressStore.saveGoogleAddress(googleAddress);

    store?.deliveryAddressStore.saveOrderTypeToStorage(
      ORDER_TYPES.DELIVERY,
      branch.id
    );

    store?.deliveryAddressStore.setPreselectedOrderType(true);

    if (getIsSlugSupported(store?.restaurantStore.hostName)) {
      history.replace(`/${branch?.slug}/${branch.id}/${pathParameters}`);
    } else {
      history.replace(`/${branch.id}/${pathParameters}`);
    }
  };

  redirectWithPostalCode = () => {
    const { selectedArea } = this.state;
    const { store, history } = this.props;
    const { search } = history.location;
    const utmParams = getUtmParamsFromSearch(search);

    const searchWithUtm = new URLSearchParams({
      ...utmParams,
      area_code: selectedArea?.zip || '',
      sublocality: selectedArea?.sublocality || ''
    });

    this.resetStoreState();

    store?.deliveryAddressStore.saveOrderTypeToStorage(
      ORDER_TYPES.DELIVERY,
      selectedArea?.branchId
    );

    store?.deliveryAddressStore.setPreselectedOrderType(true);

    if (getIsSlugSupported(store?.restaurantStore.hostName)) {
      history.replace(
        `/${selectedArea?.slug}/${
          selectedArea?.branchId
        }?${searchWithUtm.toString()}`
      );
    } else {
      history.replace(`/${selectedArea?.branchId}?${searchWithUtm.toString()}`);
    }
  };

  _toTop() {
    if (IS_CLIENT) {
      if (this.props.store?.themesStore.isMobile) {
        document.body.scrollTop = 300;

        document.documentElement.scrollTop = 300;
      }
    }
  }

  saveSelectedBranch = (branch: any) => {
    const { store } = this.props;
    const { id } = store?.restaurantStore.restaurant;

    if (IS_CLIENT) {
      localStorage.setItem(`previousSelectedBranch${id}`, branch.id);
    }
  };

  handleRedirect = (branch: any) => {
    const { store, history } = this.props;
    const { search } = history.location;
    const utmParams = getUtmParamsFromSearch(search);
    const searchWithUtm = new URLSearchParams(utmParams);
    const pathParameters = `?${searchWithUtm.toString()}`;

    this.resetStoreState();

    this.saveSelectedBranch(branch);

    store?.deliveryAddressStore.saveOrderTypeToStorage(
      ORDER_TYPES.PICKUP,
      branch.id
    );

    store?.deliveryAddressStore.setPreselectedOrderType(true);

    if (getIsSlugSupported(this.props.store?.restaurantStore.hostName)) {
      return this.props.history.replace(
        `/${branch.slug}/${branch.id}/${pathParameters}`
      );
    }

    return this.props.history.replace(`/${branch.id}/${pathParameters}`);
  };

  handlePostalCodeChange = (option: any) => {
    this.setState({ selectedArea: option.value });
  };

  resetStoreState = () => {
    const { store } = this.props;

    store?.restaurantStore.setBranch();

    store?.restaurantStore.resetClosedModalState();
  };

  renderPostalCodes = () => {
    const { selectedArea } = this.state;
    const { store, t } = this.props;
    const { allAreaCodes } = store?.restaurantStore.restaurant;

    const options = allAreaCodes.map((area: IArea) => {
      const { sublocality, zip, title } = area;

      return {
        label: `${zip} - ${sublocality} (${title})`,
        value: area
      };
    });

    return (
      <div className="geo">
        <Select2
          onInputChange={this._handleChangeAddress}
          onChange={this.handlePostalCodeChange}
          placeholder={t('address_form:zip')}
          className="places-select"
          options={options}
        />
        <button disabled={!selectedArea} onClick={this.redirectWithPostalCode}>
          {t('enter_address_modal:deliverHere')}
        </button>
      </div>
    );
  };

  renderGps = () => {
    const { t } = this.props;
    const { selectedAddress, error, addressField, isRedirect } = this.state;

    const option = {
      label: selectedAddress?.value,
      value: selectedAddress
    };

    const defaultValue = selectedAddress ? option : null;

    const isButtonDisabled =
      !!error ||
      !addressField ||
      !selectedAddress?.isHouseAddress ||
      !isRedirect;

    const onButtonClick = () => {
      if (selectedAddress?.isHouseAddress) {
        this.redirectWithLocation(selectedAddress);
      }
    };

    return (
      <div className="geo">
        <AddressAutocomplete
          defaultValue={defaultValue}
          onInputChange={this._handleChangeAddress}
          onChange={this._handleSelectAddress}
          value={selectedAddress}
          className="places-select"
          error={error}
        />
        <button disabled={isButtonDisabled} onClick={onButtonClick}>
          {isRedirect
            ? t('enter_address_modal:deliverHere')
            : t('startPageTitle:in-process')}
        </button>
      </div>
    );
  };

  renderContent = () => {
    const { store } = this.props;

    const {
      hasSomeBranchCalculationTypeByDeliveryArea
    } = store?.restaurantStore.restaurant;

    const onlyDelivery = store?.deliveryAddressStore.hasOnlyOneDelivery;

    if (onlyDelivery?.value === ORDER_TYPES.PICKUP) {
      return null;
    }

    if (hasSomeBranchCalculationTypeByDeliveryArea) {
      return this.renderGps();
    }

    return this.renderPostalCodes();
  };

  getLogoStyle = () => {
    const {
      getBackgroundStartPage
    } = this.props.store?.restaurantStore.restaurant;

    const { currentWidth } = this.state;

    if (currentWidth > 650) {
      return {};
    }

    return {
      backgroundImage: `url(${getBackgroundStartPage})`
    };
  };

  render() {
    const {
      logo,
      getBackgroundStartPage,
      getTitle
    } = this.props.store?.restaurantStore.restaurant;

    const onlyDelivery = this.props.store?.deliveryAddressStore
      .hasOnlyOneDelivery;

    const mainStyle = {
      backgroundImage: `url(${getBackgroundStartPage})`
    };

    const { showAreaSelect, isServer } = this.state;

    const {
      loadingAnimation,
      branches
    } = this.props.store?.restaurantStore.restaurant;

    const loader = {
      ...loadingAnimation,
      w: '211px',
      h: '211px'
    };

    const loaderWrapperStyle = {
      height: '211px'
    };

    const { t } = this.props;

    const branchCards = branches.map((branch: any) => {
      const { store, t } = this.props;
      const { id } = store?.restaurantStore.restaurant;

      let previousSelectedBranch;

      // IS_CLIENT doesn't do the trick here
      if (!isServer) {
        previousSelectedBranch = localStorage.getItem(
          `previousSelectedBranch${id}`
        );
      }

      return (
        <BranchCard
          key={branch.id}
          className="card"
          postalCode={branch.postalCode}
          branchName={branch.title}
          street={branch.street}
          buttonText={t('enter_address_modal:pickupHere')}
          city={branch.city}
          house={branch.streetNumber}
          onClick={() => this.handleRedirect(branch)}
          isActive={previousSelectedBranch === branch.id}
        />
      );
    });

    let separatorText = t!('startPageTitle:orSelectBranch');

    if (onlyDelivery?.value === ORDER_TYPES.PICKUP) {
      separatorText = t!('startPageTitle:pickUpOnly');
    }

    if (onlyDelivery?.value === ORDER_TYPES.DELIVERY) {
      separatorText = t!('startPageTitle:deliveryOnlyIsAvailable');
    }

    return (
      <div id="start-page-wrapper" style={mainStyle}>
        <div className={classNames('start-page-content', 'gps')}>
          <div
            className={classNames('start-page-view', 'gps')}
            ref={this.boxRef}
          >
            <div className="logo" style={this.getLogoStyle()}>
              <img
                title={`Logo ${getTitle}`}
                alt={`logo ${getTitle}`}
                src={logo}
              />
            </div>
            {onlyDelivery?.value === ORDER_TYPES.DELIVERY && (
              <h3>{separatorText}</h3>
            )}
            {showAreaSelect ? (
              <>
                {this.renderContent()}
                {onlyDelivery?.value !== ORDER_TYPES.DELIVERY && (
                  <h3>{separatorText}</h3>
                )}
                {onlyDelivery?.value !== ORDER_TYPES.DELIVERY && (
                  <div className="cards">{branchCards}</div>
                )}
              </>
            ) : (
              <SmallLoader loader={loader} wrapperStyle={loaderWrapperStyle} />
            )}
          </div>
        </div>
        <Metadata metadataType={MetadataTypes.START_PAGE} />
        <StartPageRoute hostName={this.props.store?.restaurantStore.hostName} />
      </div>
    );
  }
}

export default withTranslation(['address_form'])(Start);
