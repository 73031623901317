// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductBadge_ProductBadge__Wk4A4 {\n  -ms-flex-align: end;\n      align-items: flex-end;\n  background-color: #E00E00;\n  border-radius: 0 0 4px 4px;\n  color: #FFFFFF;\n  display: -ms-flexbox;\n  display: flex;\n  font-family: Roboto, sans-serif;\n  font-size: 12px;\n  font-stretch: normal;\n  font-style: normal;\n  font-weight: normal;\n  letter-spacing: normal;\n  line-height: 1.5;\n  padding: 3px 10px;\n  text-align: center;\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content; }\n  .ProductBadge_ProductBadge__Wk4A4 p {\n    margin: 0; }\n", "",{"version":3,"sources":["webpack://src/client/ui/ProductBadge/ProductBadge.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAqB;MAArB,qBAAqB;EACrB,yBAAyB;EACzB,0BAA0B;EAC1B,cAAc;EACd,oBAAa;EAAb,aAAa;EACb,+BAA+B;EAC/B,eAAe;EACf,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,0BAAkB;EAAlB,uBAAkB;EAAlB,kBAAkB,EAAE;EACpB;IACE,SAAS,EAAE","sourcesContent":[".ProductBadge {\n  align-items: flex-end;\n  background-color: #E00E00;\n  border-radius: 0 0 4px 4px;\n  color: #FFFFFF;\n  display: flex;\n  font-family: Roboto, sans-serif;\n  font-size: 12px;\n  font-stretch: normal;\n  font-style: normal;\n  font-weight: normal;\n  letter-spacing: normal;\n  line-height: 1.5;\n  padding: 3px 10px;\n  text-align: center;\n  width: fit-content; }\n  .ProductBadge p {\n    margin: 0; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProductBadge": "ProductBadge_ProductBadge__Wk4A4"
};
export default ___CSS_LOADER_EXPORT___;
