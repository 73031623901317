import { action, computed, observable, toJS } from 'mobx';

import RootStore from 'client/stores';

import { ProductModel } from './product.model';

/**
 * OfferProductsGroup model class. Used to work with offers products.
 * @constructor
 * @param {object} offer - offer object.
 * @property {number} id - Unique identifier.
 * @property {string} title - The name of the offer as it is called internally.
 * @property {number} pos - Ordered position
 * @property {string} description - The description of the offer as it is called internally.
 * @property {number} min_qty - Minimal number of times that user has to select offer.
 * @property {number} max_qty - Maximum number of times that user has to select offer.
 * @property {number} free_qty - Quantity of ingredient, that user can take for free with 0 price.
 * @property {array} cachedProducts - Products array
 * @property {object} step - Step in modal
 * @property {bool} loading - State if products are fetching now
 */
export class OfferProductGroupModel {
  root: RootStore;

  pos: number;

  constructor(productGroup, step, root) {
    this.root = root;

    this.step = {
      ...this.step,
      count: step
    };

    this.id = parseInt(productGroup.id, 10);

    this.cachedProducts =
      productGroup.cachedProducts && productGroup.cachedProducts.length > 0
        ? productGroup.cachedProducts.map(
            (p) => new ProductModel(p.id, p, this.root, true)
          )
        : [];

    this.pos = productGroup.pos;

    this.title = productGroup.title;

    this.description = productGroup.description;

    this.free_qty = productGroup.free_qty;

    this.min_qty = productGroup.min_qty;

    this.max_qty = productGroup.max_qty;
  }

  id;

  @observable step = {
    count: 0,
    showProduct: false,
    finished: false
  };

  title = '';

  description = '';

  min_qty = 0;

  max_qty = 0;

  free_qty = 0;

  @observable cachedProducts: ProductModel[] = [];

  @observable loading = false;

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  getToJS() {
    return Object.assign(toJS({ ...this, root: {} }), {
      cachedProducts: this.cachedProducts.map((product) => product.getToJS())
    });
  }

  /**
   * Set group in loading mode, when products is fetching or fetched
   * @param {bool} flag
   * @memberof OfferProductGroupModel
   */
  @action setLoading(flag) {
    this.loading = flag;
  }

  /**
   * set loaded products
   * @param {array} products
   * @memberof OfferProductGroupModel
   */
  @action setProducts(products) {
    this.cachedProducts = products.map((product) => {
      const data = Object.assign(product, {
        extra_ingredients_groups: product.extraIngredientsGroups,
        basic_ingredients_groups: product.basicIngredientsGroups,
        negativeExtraIngredientsGroups: product.negativeExtraIngredientsGroups
      });

      return new ProductModel(data.id, data, this.root, true);
    });
  }

  /**
   * Check if required ingredients of choosed product are selected
   * @returns {bool}
   * @readonly
   * @memberof OfferProductGroupModel
   */
  @computed get requiredIngredientsSelected() {
    return (
      this.choosedProduct &&
      !this.choosedProduct.ingredientGroups
        .map((group) => group.isRequiredIngredientsSelected)
        .filter((bool) => !bool).length
    );
  }

  /**
   * Get choosed product
   * @returns {object||undefined}
   * @readonly
   * @memberof OfferProductGroupModel
   */
  @computed get choosedProduct() {
    return this.cachedProducts.find((i) => i.count > 0);
  }

  /**
   * Check if group has products
   * @returns {bool}
   * @readonly
   * @memberof OfferProductGroupModel
   */
  @computed get hasProducts() {
    return this.cachedProducts.length > 0;
  }
}
