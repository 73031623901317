import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Loadable from '../components/AsyncLoading';
import states from '../enums/states.enum';
import RightsReserved from '../themes/theme-hermes/components/rights-reserved';
import { generateRoutePath } from '../utils/routing';

import ModalRoute from './ModalsRoute';

const AsyncBasket = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "basket", webpackPrefetch: true */ 'client/themes/theme-hermes/components/basket'
    )
});

const AsyncAddress = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "address", webpackPrefetch: true */ '../themes/theme-hermes/components/address'
    )
});

const AsyncCheckout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "checkout", webpackPrefetch: true */ '../themes/theme-hermes/components/checkout'
    )
});

const AsyncWidget = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "widget", webpackPrefetch: true */ '../themes/theme-hermes/components/widgets'
    )
});

const AsyncProductCertificate = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "productcertificate", webpackPrefetch: true */ '../themes/theme-hermes/components/product_certificate/'
    )
});

const DesktopRoute = (props: any) => (
  <>
    <Switch>
      <Route
        path={`${generateRoutePath(
          `:branchId/${states.address}`,
          props.hostName
        )}`}
        component={AsyncAddress}
      />
      <Route
        path={`${generateRoutePath(
          `:branchId/${states.checkout}`,
          props.hostName
        )}`}
        component={AsyncCheckout}
      />
      <Route
        path={`${generateRoutePath(':branchId', props.hostName)}`}
        component={AsyncBasket}
      />
    </Switch>

    <Switch>
      <Route
        exact
        path={`${generateRoutePath(':branchId', props.hostName)}`}
        component={AsyncWidget}
      />
      <Route
        exact
        path={`${generateRoutePath(
          `:branchId/${states.cat}/:categoryId`,
          props.hostName
        )}`}
        component={AsyncWidget}
      />
      <Route
        path={`${generateRoutePath(
          `:branchId/${states.basket}`,
          props.hostName
        )}`}
        component={AsyncWidget}
      />
      <Route
        path={`${generateRoutePath(
          `:branchId/${states.productCertificate}`,
          props.hostName
        )}`}
        component={AsyncWidget}
      />
    </Switch>

    <Switch>
      <Route
        exact
        path={`${generateRoutePath(':branchId', props.hostName)}`}
        component={RightsReserved}
      />
      <Route
        path={`${generateRoutePath(`:branchId/${states.cat}`, props.hostName)}`}
        component={RightsReserved}
      />
      <Route
        path={`${generateRoutePath(
          `:branchId/${states.basket}`,
          props.hostName
        )}`}
        component={RightsReserved}
      />
      <Route
        path={`${generateRoutePath(
          `:branchId/${states.productCertificate}`,
          props.hostName
        )}`}
        component={RightsReserved}
      />
    </Switch>

    <Route
      path={`${generateRoutePath(
        `:branchId/${states.productCertificate}`,
        props.hostName
      )}`}
      component={AsyncProductCertificate}
    />
    <Route
      path={`${generateRoutePath(':branchId', props.hostName)}`}
      component={ModalRoute}
    />
  </>
);

export default DesktopRoute;
