export const beginsWithPlusAndZero = /^\+0/;

export const beginsWithThreeZeros = /^0{3,}/;

export const hasTwoPluses = /^\+{2,}/;

export const beginsWithHyphen = /^-/;

export const endsWithHyphen = /-\s*$/;

export const beginsWithSlash = /^\//;

export const endsWithSlash = /\/\s*$/;

export const beginsWithZero = /^0/;

export const beginsWithDot = /^\./;

export const endsWithDot = /\.\s*$/;

export const containsOnlyOneSlash = /\/{2,}/;

export const containsTwoConsecHyphens = /--/;

export const containsOnlyOneConsecHyphens = /-{2,}/;

export const containsTwoConsecBrackets = /(\({2,})|(\){2,})/;

export const containsTwoConsecSlashes = /\/{2,}/;

export const containsTwoConsecDots = /\.{2,}/;

export const notContainsTwoAts = /^[^@]+@[^@]+$/;

export const containsPlusInTheMiddle = /.\+/;

export const containsAt = /@/;

export const containsDigit = /\d/;

export const startsWithDigitOrLetter = /^[\d\wüäößáéíóúýÜÄÖSSÁÉÍÓÚÝąęćłńóśźżĄĘĆŁŃÓŚŹŻčČďĎěĚňŇřŘšŠťŤůŮžŽ]/;

export const endsWithDigitOrLetter = /[\d\wüäößáéíóúýÜÄÖSSÁÉÍÓÚÝąęćłńóśźżĄĘĆŁŃÓŚŹŻčČďĎěĚňŇřŘšŠťŤůŮžŽ]$/;

export const endsWithDigit = /\d$/;

export const notEndsWithDigit = /[^\d]$/;

export const emailPrefixTooShortOrLong = /^.{1,64}@/;

export const domainContainsLettersOrDigits = /\.\w+$/;

export const domainContainsLetters = /@.*\..*\D.*$/;

export const domainNotEmpty = /@.+\./;

export const containsDomain = /\.\w+$/;

export const domainTooShort = /\w{2,}$/;

export const polishZip = /\d{2}-\d{3}/;

export const germanZip = /\d{5}/;

export const firstNameValidChars = new RegExp(
  /[a-zA-ZüäößáéíóúýÜÄÖSSÁÉÍÓÚÝąęćłńóśźżĄĘĆŁŃÓŚŹŻčČďĎěĚňŇřŘšŠťŤůŮžŽ\- –]/
);

export const lastNameValidChars = new RegExp(
  /[a-zA-ZüäößáéíóúýÜÄÖSSÁÉÍÓÚÝąęćłńóśźżĄĘĆŁŃÓŚŹŻčČďĎěĚňŇřŘšŠťŤůŮžŽ\- –]/
);

export const companyValidChars = new RegExp(
  /[a-zA-Z0-9üäößáéíóúýÜÄÖSSÁÉÍÓÚÝąęćłńóśźżĄĘĆŁŃÓŚŹŻčČďĎěĚňŇřŘšŠťŤůŮžŽ .+\-:!?()@'–/_]/
);

export const emailValidChars = new RegExp(
  /[a-zA-Z0-9üäößáéíóúýÜÄÖSSÁÉÍÓÚÝąęćłńóśźżĄĘĆŁŃÓŚŹŻčČďĎěĚňŇřŘšŠťŤůŮžŽ\-@_.–+]/
);

export const phoneValidChars = new RegExp(/[0-9+()\-/–]/);

export const phoneValidValue = new RegExp(/[+()\-–]{2,}/);

export const phoneBeginValidChars = new RegExp(/^[0-9+(]/);

export const commentValidChars = new RegExp(
  /[a-zA-Z0-9üäößáéíóúýÜÄÖSSÁÉÍÓÚÝąęćłńóśźżĄĘĆŁŃÓŚŹŻčČďĎěĚňŇřŘšŠťŤůŮžŽ .+\-:@/!?()'–_]/
);

export const apartmentValidChars = new RegExp(/[a-zA-Z0-9 \-/–]/);

export const floorValidChars = new RegExp(/[a-zA-Z0-9 \-/–]/);

export const staircaseValidChars = new RegExp(/[a-zA-Z0-9 \-/–]/);

export const streetValidChars = new RegExp(
  /[a-zA-Z0-9üäößáéíóúýÜÄÖSSÁÉÍÓÚÝąęćłńóśźżĄĘĆŁŃÓŚŹŻčČďĎěĚňŇřŘšŠťŤůŮžŽ .\-–']/
);

export const houseValidChars = new RegExp(
  /[a-zA-Z0-9üäößáéíóúýÜÄÖSSÁÉÍÓÚÝąęćłńóśźżĄĘĆŁŃÓŚŹŻčČďĎěĚňŇřŘšŠťŤůŮžŽ \-/–]/
);

export const cityValidChars = new RegExp(
  /[a-zA-Z0-9üäößáéíóúýÜÄÖSSÁÉÍÓÚÝąęćłńóśźżĄĘĆŁŃÓŚŹŻčČďĎěĚňŇřŘšŠťŤůŮžŽ() \-–]/
);

export const zipValidCharsDE = new RegExp(/[0-9()]/);

export const zipValidCharsPL = new RegExp(/[0-9\-–()]/);

export const getZipValidationByLang = (lang: string) => {
  const validateRules: Record<string, RegExp> = {
    de: zipValidCharsDE,
    pl: zipValidCharsPL
  };

  return validateRules[lang];
};

export const leadingPlusOrZero = new RegExp(/^[+0]/);
