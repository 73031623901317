// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../RobotoCondensed-Regular.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../RobotoCondensed-Bold.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-display: swap;\n  font-family: \"Roboto Condensed\";\n  font-style: normal;\n  font-weight: normal;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); }\n\n@font-face {\n  font-display: swap;\n  font-family: \"Roboto Condensed\";\n  font-style: normal;\n  font-weight: bold;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "); }\n", "",{"version":3,"sources":["webpack://src/client/assets/fonts/Roboto_Condensed/scss/robotocondensed.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,+BAA+B;EAC/B,kBAAkB;EAClB,mBAAmB;EACnB,4CAA0C,EAAE;;AAE9C;EACE,kBAAkB;EAClB,+BAA+B;EAC/B,kBAAkB;EAClB,iBAAiB;EACjB,4CAAuC,EAAE","sourcesContent":["@font-face {\n  font-display: swap;\n  font-family: \"Roboto Condensed\";\n  font-style: normal;\n  font-weight: normal;\n  src: url(\"../RobotoCondensed-Regular.ttf\"); }\n\n@font-face {\n  font-display: swap;\n  font-family: \"Roboto Condensed\";\n  font-style: normal;\n  font-weight: bold;\n  src: url(\"../RobotoCondensed-Bold.ttf\"); }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
