import { toJS } from 'mobx';
/**
 * Homepage TextImage model
 */
export default class HomepageContentModel {
  constructor(content) {
    Object.assign(this, content);
    this.id = content.id;
    this.title = content.title;
    this.description = content.description;
    this.picUrl = content.picUrl;
  }

  id;
  title = '';
  description = '';
  picUrl = '';

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  getToJS() {
    return toJS(Object.assign({}, this, { root: {} }));
  }
}
