import dayjs from 'dayjs';
import range from 'lodash/range';
import { action, observable } from 'mobx';

import RootStore from 'client/stores';

/**
 * Reservation store class. Used to work with table reservation in store.
 * @constructor
 * @param {instance} api - {@link API} instance.
 * @param {instance} storage - {@link storage} instance.
 */
export default class ReservationTableStore {
  api: any;

  root: RootStore;

  constructor(root, state, api) {
    Object.assign(this, state);

    this.api = api;

    this.root = root;
  }

  /**
   * Table reservation name
   * @type {string}
   */
  @observable name = '';

  /**
   * Table reservation email
   * @type {string}
   */
  @observable email = '';

  /**
   * Table reservation phone
   * @type {string}
   */
  @observable phone = '';

  /**
   * Table reservation persons quantity
   * @type {number}
   */
  @observable personQuantity = 2;

  /**
   * Table reservation date
   * @type {null}
   */
  @observable date = null;

  /**
   * Table reservation time
   * @type {null}
   */
  @observable time = null;

  /**
   * Table reservation comment
   * @type {string}
   */
  @observable comment = '';

  /**
   * Is table reservation sending error
   * @type {boolean}
   */
  @observable error = null;

  /**
   * Is table reservation send successfully
   * @type {boolean}
   */
  @observable success = false;

  /**
   * Is table reservation loading active
   * @type {boolean}
   */
  @observable loading = false;

  /**
   * Selected table reservation date
   * @type {null}
   */
  @observable selectedDate = null;

  /**
   * Selected table reservation time
   * @type {number}
   */
  @observable selectedTime = undefined;

  /**
   * Available reservation hours for selected date
   * @type {number[]}
   */
  @observable availableHoursArray = [];

  /**
   * Method to send feedback
   * @memberof ReservationStore#
   * @method sendTableReservation
   */
  @action sendTableReservation() {
    if (!this.loading) {
      this.loading = true;

      this.error = null;

      const { restaurantStore } = this.root;
      const { branchId } = restaurantStore.branch;
      const lang = restaurantStore.restaurantLanguage;

      return this.api
        .reserveTable(
          branchId,
          this.email,
          this.phone,
          this.name,
          this.personQuantity,
          this.date || '',
          this.time || '',
          this.comment || '',
          lang
        )
        .then((data) => {
          this.resetReservationForm();

          this.success = true;

          this.loading = false;

          return data;
        })
        .catch((err) => {
          this.error = err.message;

          this.loading = false;

          throw err;
        });
    }

    console.info("You can't send table reservation again");
  }

  /**
   * Action to clear table reservation form fields
   */
  @action resetReservationForm() {
    this.comment = '';

    this.date = null;

    this.time = null;

    this.personQuantity = 2;
  }

  /**
   * Action to clear table reservation form state
   */
  @action clearReservationState() {
    this.error = null;

    this.success = false;

    this.loading = false;
  }

  /**
   * Action to send table reservation request
   * @param values
   */
  @action prepareAndSendReservationRequest(values) {
    this.name = values.name;

    this.phone = values.phone;

    this.email = values.email;

    this.date = values.date.format('DD.MM.YYYY');

    this.time = `${this.selectedTime}:00`;

    this.comment = values.comment;

    this.sendTableReservation();
  }

  /**
   * Action to set reservation time
   * @param time
   */
  @action setReservationTime(time) {
    this.selectedTime = time;
  }

  /**
   * Method returns minimal reservation date/time (now or when restaurant will be open)
   * @returns {dayjs.Dayjs}
   */
  calculateOpeningDateTime() {
    const {
      closedForPickUp,
      secondsUntilOpenPickUp
    } = this.root.restaurantStore.branch;

    const secondsToOpen = closedForPickUp ? secondsUntilOpenPickUp : 0;
    const duration = dayjs.duration(secondsToOpen, 'seconds');

    return this.root.restaurantStore.restaurantTime.clone().add(duration);
  }

  /**
   * Get weekday by date (with fix for sunday)
   * @param date
   * @returns {number}
   */
  getWeekdayByDateWithFix = (date) => {
    const weekday = dayjs(date).weekday();

    return weekday === 7 ? 0 : weekday;
  };

  /**
   * Returns false if restaurant close at date
   * Method checks is restaurant close at weekday and checks past days
   * @param {dayjs.Dayjs} date
   * @returns {boolean}
   */
  isDisabledDate = (date) => {
    // holiday mode checks
    const holidays = this.root.restaurantStore.branch.holidaysDates;

    if (holidays.includes(date.format('YYYY-MM-DD'))) {
      return true;
    }

    // Daily pickup schedule checks
    const weekday = this.getWeekdayByDateWithFix(date);

    const isRestaurantOpen = this.root.openingHoursStore.getOpeningsTimeAtWeekday(
      weekday
    );

    return date < dayjs().startOf('day') || !isRestaurantOpen;
  };

  /**
   * Method for calculating available hours but worktime
   * @param {string} startTime - start time in format HH:mm:ss
   * @param {string} endTime - end time in format HH:mm:ss
   */
  calculateAvailableTime = (startTime, endTime) => {
    let startHour = parseInt(dayjs(startTime, 'HH:mm:ss').format('HH'));

    const startMinute = parseInt(dayjs(startTime, 'HH:mm:ss').format('m'));

    let endHour = parseInt(dayjs(endTime, 'HH:mm:ss').format('HH'));

    const endMinute = parseInt(dayjs(endTime, 'HH:mm:ss').format('m'));

    // Increment startHour, if first hour contains less 60 minutes
    if (startMinute > 0) {
      startHour++;
    }

    // Return empty array if endHour in past
    if (
      this.selectedDate.set({ h: endHour, m: endMinute, s: 0 }) < dayjs() &&
      this.selectedDate.set({ h: startHour, m: startMinute, s: 0 }) <
        this.selectedDate.set({ h: endHour, m: endMinute, s: 0 })
    ) {
      return [];
    }

    // Update startHour value if it past
    if (
      this.selectedDate.set({ h: startHour, m: startMinute, s: 0 }) < dayjs()
    ) {
      startHour = parseInt(dayjs().format('HH')) + 1;
    }

    // Set endHour to 23 restaurant works to midnight.
    // Deincrement endHour if it closing before midnight
    endHour = endTime === '00:00:00' ? 23 : endHour;

    if (endHour < startHour) {
      return [...range(startHour, 24), ...range(0, endHour)];
    }

    return range(startHour, endHour);
  };

  /**
   * Method for filling available hours
   * @param openingsTime
   */
  fillAvailableHours(openingsTime) {
    if (openingsTime) {
      if (openingsTime.breakStart && openingsTime.breakEnd) {
        // Concatenation range hours before break and after
        this.availableHoursArray = [
          ...this.calculateAvailableTime(
            openingsTime.start,
            openingsTime.breakStart
          ),
          ...this.calculateAvailableTime(
            openingsTime.breakEnd,
            openingsTime.end
          )
        ];
      } else {
        // calculate hours if openingsTime hasn't break
        this.availableHoursArray = this.calculateAvailableTime(
          openingsTime.start,
          openingsTime.end
        );
      }
    } else {
      this.availableHoursArray = [];
    }
  }

  /**
   * Method for handling changing date in datePicker
   * @param chosenDate
   */
  dateChanged(chosenDate) {
    if (!chosenDate) {
      return;
    }

    this.selectedDate = chosenDate;

    this.selectedTime = undefined;

    const weekdayFix = this.getWeekdayByDateWithFix(this.selectedDate);

    const openingsTime = this.root.openingHoursStore.getOpeningsTimeAtWeekday(
      weekdayFix
    );

    this.fillAvailableHours(openingsTime);
  }
}
