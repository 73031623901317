import classNames from 'classnames';
import React from 'react';

import './BranchCard.scss';
import { BranchCardProps } from './BranchCard.type';

const BranchCard = ({
  branchName,
  house,
  postalCode,
  onClick,
  street,
  city,
  buttonText,
  className,
  isActive = false
}: BranchCardProps) => (
  <div className={classNames('BranchCard', className, isActive && 'active')}>
    <h4>{branchName}</h4>
    <div>
      <p>
        {street} {house}
      </p>
      <p>
        {postalCode} {city}
      </p>
    </div>
    <button onClick={onClick}>{buttonText}</button>
  </div>
);

export { BranchCard };

export default React.memo(BranchCard);
