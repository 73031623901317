import React, { Ref, forwardRef, memo } from 'react';

import TextField, { TextFieldProps } from 'client/ui/TextField';

const Input = forwardRef(
  ({ className, ...rest }: TextFieldProps, ref: Ref<HTMLInputElement>) => (
    <TextField className={className} {...rest} inputRef={ref} />
  )
);

export { Input };

export default memo(Input);
