/* eslint-disable import/no-cycle */
import { computed } from 'mobx';

import { ELEMENTS_TYPES } from 'client/enums/homepage_elements_types.enum';
import RootStore from 'client/stores';
import { IHomepageElement } from 'client/stores/homepage/homepage.store.type';
import {
  IHomepageAnchor,
  ISeoElement,
  ISeoItem
} from 'client/stores/seoData/seoData.store.type';

import i18n from '../../../i18n';

/**
 * SEO data store
 */
class SeoDataStore {
  root: RootStore;

  constructor(root: RootStore) {
    this.root = root;
  }

  /**
   * Method to recognition availability main seo header on start page
   */
  @computed get mainSeoHeaderIsAvailable() {
    const primarySeoElement = this.seoElement?.items[0]?.find(
      (elem: ISeoItem) => elem.is_primary
    );

    return !!primarySeoElement?.title;
  }

  /**
   * Seo data
   */
  @computed get seoElement() {
    return this.root.homepageStore.homepageElements.find(
      (elem: ISeoElement) => elem.type === ELEMENTS_TYPES.SEO_TEXT
    );
  }

  /**
   * Restaurant header for seo block
   */
  @computed get seoRestaurantTitle() {
    const { restaurant } = this.root.restaurantStore;
    const { hasDelivery } = this.root.deliveryAddressStore;

    const orderNowText = i18n.t('seo:orderNowTo', {
      name: restaurant.getTitle,
      interpolation: { escapeValue: false },
      lng: restaurant.getLang
    });

    const serviceForDeliveryText = i18n.t('seo:serviceForDelivery', {
      lng: restaurant.getLang
    });

    if (hasDelivery) {
      return `${orderNowText} | ${serviceForDeliveryText} ${restaurant.getCity}`;
    }

    return `${orderNowText} | ${restaurant.getCity}`;
  }

  @computed get seoCategoriesTitle() {
    const { restaurant } = this.root.restaurantStore;
    const restaurantTitle = restaurant.getTitle;

    if (this.seoCategoryNames) {
      return `${this.seoCategoryNames} | ${restaurantTitle}`;
    }

    return restaurantTitle;
  }

  @computed get homepageAnchors() {
    const { homepageElements } = this.root.homepageStore;

    const anchors = homepageElements
      .map((elem: IHomepageElement, index: number) => {
        let { title } = elem;

        if (elem.type === ELEMENTS_TYPES.OFFERS) {
          title = i18n.t('seo:currentOffers');
        }

        if (elem.type === ELEMENTS_TYPES.AREA_CODE) {
          title = i18n.t('seo:deliveryAreas');
        }

        if (elem.type === ELEMENTS_TYPES.OPENING_TIMES) {
          title = i18n.t('seo:openingTimes');
        }

        return {
          ...elem,
          title,
          isFirst: index === 0
        };
      })
      .filter(
        (elem: IHomepageAnchor) =>
          elem.type !== ELEMENTS_TYPES.SLIDER &&
          elem.type !== ELEMENTS_TYPES.SEO_TEXT &&
          elem.type !== ELEMENTS_TYPES.TEXT_IMAGE
      );

    return anchors;
  }

  @computed get seoCategoryNames() {
    const items = this.seoElement?.items;

    if (!items) {
      return '';
    }

    const [itemsArr] = items;

    const result = itemsArr
      ?.reduce((acc: string[], { category }: ISeoItem) => {
        if (category) {
          acc.push(category);
        }

        return acc;
      }, [])
      .join(', ');

    return result || '';
  }

  /**
   * Func to get branch header for seo block
   */
  makeSeoBranchTitle(customTitle: string) {
    const { categoryMenuStore, restaurantStore } = this.root;
    const { activeCategory } = categoryMenuStore;
    const { restaurant, branch, isGermanRestaurant } = restaurantStore;
    const { predefinedPageTitle } = restaurant;
    const { branchName, getCity } = branch;
    const title = customTitle || activeCategory?.name || '';

    const seoTitle = isGermanRestaurant
      ? this.seoRestaurantTitle
      : `${branchName}, ${getCity} | ${title}`;

    return predefinedPageTitle || seoTitle;
  }

  makeSeoDescription() {
    const { hasDelivery } = this.root.deliveryAddressStore;
    const { restaurant, branch } = this.root.restaurantStore;

    const categoryNames = this.seoCategoryNames
      ? `${this.seoCategoryNames} | `
      : '';

    const addressString = `${branch.getStreet} ${branch.getStreetNo}, ${branch.getCityCode} ${branch.getCity}`;

    const endString = hasDelivery
      ? i18n.t('seo:orderOnlineNowAndHaveDelivered', {
          lng: restaurant.getLang
        })
      : i18n.t('seo:orderOnlineNowAndPickup', { lng: restaurant.getLang });

    return `${categoryNames}${restaurant.getTitle} | ${addressString} &rArr; ${endString}`;
  }
}

export default SeoDataStore;
