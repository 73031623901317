import axios, { AxiosError } from 'axios';

import {
  AdditivesResponse,
  BranchIdByCoordsResponse,
  CategoriesResponse,
  DeliveryInfoByBranchResponse,
  HtmlString,
  PayPalStatusResponse,
  PlaceOrderResponse,
  ProductAdditiveResponse,
  ProductsByBranchResponse,
  ProductsByCategoryIdResponse,
  RestaurantOrderResponse,
  SingleProductResponse
} from 'client/api/types';
import { OrderModel } from 'client/models/order.model';
import { api2, api31, apiJavaDev, apiPhpInt } from 'client/utils/api';

const fetchAdditives = async (branchId: string) => {
  const { data } = await api31.get<AdditivesResponse>(
    `/branches/${branchId}/additives`
  );

  return data;
};

const fetchBranchIdByCoords = async (
  slug: string,
  lat: number,
  lng: number
) => {
  const { data } = await api31.get<BranchIdByCoordsResponse>(
    `/user/areas/slug/${slug}?lat=${lat}&lon=${lng}`
  );

  return data;
};

const fetchProductsByCategoryId = async (
  branchId: string,
  categoryId: number
) => {
  const url = `/get-products-of-category/${branchId}/${categoryId}`;
  const { data } = await api2.get<ProductsByCategoryIdResponse>(url);

  return data;
};

const fetchProductsByBranch = async (branchId: string) => {
  const url = `/products/${branchId}`;
  const { data } = await api31.get<ProductsByBranchResponse>(url);

  return data;
};

const fetchProduct = async (branchId: string, productId: number) => {
  const url = `/get-single-product/${branchId}/${productId}`;
  const { data } = await api2.get<SingleProductResponse>(url);

  return data;
};

const fetchCategories = async (branchId: string) => {
  const url = `/get-categories/${branchId}`;

  const params = {
    mlc: 1
  };

  const { data } = await api2.get<CategoriesResponse>(url, { params });

  return data;
};

const fetchProductAdditives = async (productId: number) => {
  const url = `/products/${productId}/additives`;
  const { data } = await api31.get<ProductAdditiveResponse>(url);

  return data;
};

const fetchPayPalStatus = async (transactionId: string) => {
  const url = `/api/v1/payments/status?transaction_id=${transactionId}`;
  const { data } = await apiPhpInt.get<PayPalStatusResponse>(url);

  return data;
};

const fetchRestaurantOrderStatus = async (transactionId: string) => {
  const url = `/api/v1/payments/orders/status?transaction_id=${transactionId}`;
  const { data } = await apiPhpInt.get<RestaurantOrderResponse>(url);

  return data;
};

const fetchImprintContent = async (
  token: string,
  branchId: string,
  hunger = false
) => {
  const combinedString = branchId ? `${token}-${branchId}` : token;
  const url = `/page/imprint/${combinedString}?lang=de`;
  const params = { hunger: Number(!!hunger) };
  const { data } = await api31.get<HtmlString>(url, { params });

  return data;
};

const fetchPrivacyPolicyContent = async (
  token: string,
  branchId: string,
  hunger = false
) => {
  const combinedString = branchId ? `${token}-${branchId}` : token;
  const url = `/page/privacy-policy/${combinedString}?lang=de`;
  const params = { hunger: Number(!!hunger) };
  const { data } = await api31.get<HtmlString>(url, { params });

  return data;
};

const fetchTermsContent = async (
  token: string,
  branchId: string,
  hunger = false
) => {
  const combinedString = branchId ? `${token}-${branchId}` : token;
  const url = `/page/terms/${combinedString}?lang=de`;
  const params = { hunger: Number(!!hunger) };
  const { data } = await api31.get<HtmlString>(url, { params });

  return data;
};

const fetchHungerImprint = async () => {
  const url = 'https://hunger.de/apps/imprint-de.html';
  const { data } = await axios.get<string>(url);

  return data;
};

const fetchHungerPrivacyPolicy = async () => {
  const url = 'https://hunger.de/apps/data-protection-de.html';
  const { data } = await axios.get<string>(url);

  return data;
};

const fetchDeliveryInfoByBranch = () => {
  const cache = new Map<string, DeliveryInfoByBranchResponse>();

  return async (branchId: string, lat: number, lng: number) => {
    const cacheKey = `${lat}${lng}`;
    const url = `/user/areas/${branchId}`;
    const params = { lat, lon: lng };
    const cacheResult = cache.get(cacheKey);

    if (cacheResult) {
      return cacheResult;
    }

    const { data } = await api31.get<DeliveryInfoByBranchResponse>(url, {
      params
    });

    cache.set(cacheKey, data);

    return data;
  };
};

const fetchDeliveryInfoByBranchMemo = fetchDeliveryInfoByBranch();

const placeOrderCashPayment = async (branchId: string, body: OrderModel) => {
  try {
    const url = `order/public/api/v1/hermes/branches/legacy/${branchId}/orders`;
    const { data } = await apiJavaDev.post<PlaceOrderResponse>(url, body);

    return data;
  } catch (error) {
    const err = error as AxiosError;

    throw err;
  }
};

const checkOrderStatus = async (branchId: string, orderId: string) => {
  try {
    const url = `order/public/api/v1/hermes/branches/${branchId}/orders/${orderId}/status`;
    const { data } = await apiJavaDev.get<PlaceOrderResponse>(url);

    return data;
  } catch (error) {
    const err = error as AxiosError;

    throw err;
  }
};

export {
  fetchAdditives,
  fetchBranchIdByCoords,
  fetchProductsByCategoryId,
  fetchProductsByBranch,
  fetchProduct,
  fetchCategories,
  fetchPayPalStatus,
  fetchRestaurantOrderStatus,
  fetchProductAdditives,
  fetchImprintContent,
  fetchPrivacyPolicyContent,
  fetchTermsContent,
  fetchHungerImprint,
  fetchHungerPrivacyPolicy,
  fetchDeliveryInfoByBranch,
  fetchDeliveryInfoByBranchMemo,
  placeOrderCashPayment,
  checkOrderStatus
};
