import React, { memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootContext } from 'client/stores';

import DeliveryFormFC from './DeliveryFormDe';
import { DeliveryDeFormData } from './DeliveryFormDe.type';

const DeliveryFormDe = () => {
  const { t, i18n } = useTranslation(['address_form', 'validationErrors']);

  const {
    restaurantStore,
    deliveryAddressStore,
    analyticsStore,
    themesStore
  } = useContext(RootContext);

  const { address } = deliveryAddressStore;
  const zipCodes = restaurantStore.branch.areaCodes || [];
  const { useCalculationTypeByDeliveryArea } = restaurantStore;

  const currentLocation = zipCodes.find(
    ({ areaCode, sublocality }) =>
      areaCode === address.zip && sublocality === address.city
  );

  const defaultValues: DeliveryDeFormData = {
    firstName: address.first_name,
    lastName: address.last_name,
    zip: currentLocation,
    company: address.company,
    email: address.email,
    phone: address.phone,
    street: address.street,
    houseNumber: address.street_no,
    comment: address.comment
  };

  const handleSubmit = useCallback(
    (formData: DeliveryDeFormData) => {
      const address = {
        first_name: formData.firstName.trim(),
        last_name: formData.lastName.trim(),
        zip: formData.zip?.areaCode,
        company: formData.company.trim(),
        email: formData.email.trim(),
        phone: formData.phone.trim(),
        street: formData.street.trim(),
        street_no: formData.houseNumber.trim(),
        city: formData.zip?.sublocality.trim(),
        comment: formData.comment.trim()
      };

      deliveryAddressStore.updateAddress(address);

      deliveryAddressStore.onZipChange(formData.zip);

      analyticsStore.sendProvidePersonalInformation();
    },
    [deliveryAddressStore]
  );

  return (
    <DeliveryFormFC
      lang={i18n.language as AppLang}
      t={t}
      zipCodes={zipCodes}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      useStreetAutocomplete={!!useCalculationTypeByDeliveryArea}
      isMobile={themesStore.isMobile}
    />
  );
};

export default memo(DeliveryFormDe);
