import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { IDay } from '../../../../../models/day.model';
import CommonMobileHeader from '../../header/mobile/common';
import ModalHeader from '../../modals_components/header/ModalHeader';

import OpeningTimesCell from './opening-times-cell';
import { IOpeningHoursMobileProps } from './props.interface';
import './OpeningHoursMobile.scss';

@(withRouter as any)
@inject('store')
@observer
class OpeningHoursMobile extends Component<IOpeningHoursMobileProps> {
  render() {
    if (this.props.store) {
      const {
        pickupDaysSorted,
        deliveryDaysSorted,
        todayWeekDay
      } = this.props.store.openingHoursStore;

      const { hasDelivery, hasPickup } = this.props.store.deliveryAddressStore;

      return (
        <div>
          <CommonMobileHeader />
          <ModalHeader
            title={
              this.props.t && this.props.t('opening_hours:lblWorkingHours')
            }
            isMobile
          />
          <div className="openings-hours-list-container">
            {pickupDaysSorted &&
              deliveryDaysSorted &&
              pickupDaysSorted.length === deliveryDaysSorted.length &&
              pickupDaysSorted.map((pickupTimes: IDay, index: number) => (
                <OpeningTimesCell
                  key={pickupTimes.day}
                  pickupTimes={pickupTimes}
                  deliveryTimes={deliveryDaysSorted[index]}
                  pickupAvailable={hasPickup}
                  deliveryAvailable={hasDelivery}
                  todayWeekDay={todayWeekDay}
                />
              ))}
          </div>
        </div>
      );
    }

    return null;
  }
}

export default withTranslation(['title'])(OpeningHoursMobile);
