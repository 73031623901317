import * as yup from 'yup';

import { ORDER_TYPES } from 'client/enums/order_types.enum';
import i18n from 'i18n';

import createValidationObjects from './validationObjects';

const createPickUpFormValidation = () => {
  const {
    firstNameValidation,
    lastNameValidation,
    zipValidation,
    companyValidation,
    emailValidation,
    phoneValidation,
    streetValidation,
    houseNumberValidation,
    cityValidation,
    additionalInfoValidation
  } = createValidationObjects();

  return yup.object().shape({
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    zip: zipValidation,
    company: companyValidation,
    email: emailValidation,
    phone: phoneValidation,
    street: streetValidation,
    houseNumber: houseNumberValidation,
    city: cityValidation,
    comment: additionalInfoValidation
  });
};

const createSimplePickUpFormValidation = () => {
  const {
    firstNameValidation,
    lastNameValidation,
    emailValidation,
    phoneValidation,
    additionalInfoValidation
  } = createValidationObjects();

  return yup.object().shape({
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    email: emailValidation,
    phone: phoneValidation,
    comment: additionalInfoValidation
  });
};

const createDeliveryFormDeValidation = () => {
  const {
    firstNameValidation,
    lastNameValidation,
    companyValidation,
    emailValidation,
    phoneValidation,
    streetValidation,
    houseNumberValidation,
    zipSelectValidation,
    additionalInfoValidation
  } = createValidationObjects();

  return yup.object().shape({
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    zip: zipSelectValidation,
    company: companyValidation,
    email: emailValidation,
    phone: phoneValidation,
    street: streetValidation,
    houseNumber: houseNumberValidation,
    comment: additionalInfoValidation
  });
};

const createDeliveryFormPlValidation = () => {
  const {
    firstNameValidation,
    lastNameValidation,
    companyValidation,
    emailValidation,
    phoneValidation,
    streetValidation,
    apartmentValidation,
    floorValidation,
    zipSelectValidation,
    staircaseNumberValidation,
    houseNumberValidation,
    additionalInfoValidation
  } = createValidationObjects();

  return yup.object().shape({
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    zip: zipSelectValidation,
    company: companyValidation,
    email: emailValidation,
    phone: phoneValidation,
    street: streetValidation,
    houseNumber: houseNumberValidation,
    apartment: apartmentValidation,
    floor: floorValidation,
    staircase: staircaseNumberValidation,
    comment: additionalInfoValidation
  });
};

const createAdvancedDeliveryDeFormValidation = () => {
  const {
    firstNameValidation,
    lastNameValidation,
    companyValidation,
    emailValidation,
    phoneValidation,
    googleAddressValidation,
    additionalInfoValidation
  } = createValidationObjects();

  return yup.object().shape({
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    company: companyValidation,
    googleAddress: googleAddressValidation,
    email: emailValidation,
    phone: phoneValidation,
    comment: additionalInfoValidation
  });
};

const createAdvancedDeliveryPlFormValidation = () => {
  const {
    firstNameValidation,
    lastNameValidation,
    companyValidation,
    emailValidation,
    phoneValidation,
    googleAddressValidation,
    additionalInfoValidation,
    staircaseNumberValidation,
    floorValidation,
    apartmentValidation
  } = createValidationObjects();

  return yup.object().shape({
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    company: companyValidation,
    googleAddress: googleAddressValidation,
    email: emailValidation,
    phone: phoneValidation,
    comment: additionalInfoValidation,
    apartment: apartmentValidation,
    floor: floorValidation,
    staircase: staircaseNumberValidation
  });
};

const createAddressPromtValidation = () => {
  const { googleAddressValidation } = createValidationObjects();

  return yup.object().shape({
    deliveryType: yup.string(),
    googleAddress: googleAddressValidation.when(
      'deliveryType',
      (deliveryType: ORDER_TYPES, schema: typeof googleAddressValidation) => {
        if (deliveryType === 'selfcollect') {
          return yup.object().optional();
        }

        return schema;
      }
    )
  });
};

const createSimpleAddressPromptValidation = () => {
  const { zipSelectValidation } = createValidationObjects();

  return yup.object().shape({
    deliveryType: yup.string(),
    zipCode: zipSelectValidation.when(
      'deliveryType',
      (deliveryType: ORDER_TYPES, schema: typeof zipSelectValidation) => {
        if (deliveryType === 'selfcollect') {
          return yup.object().optional();
        }

        return schema;
      }
    )
  });
};

i18n.on('languageChanged', createValidationObjects);

export {
  createPickUpFormValidation,
  createSimplePickUpFormValidation,
  createDeliveryFormDeValidation,
  createDeliveryFormPlValidation,
  createAdvancedDeliveryDeFormValidation,
  createAdvancedDeliveryPlFormValidation,
  createAddressPromtValidation,
  createSimpleAddressPromptValidation
};
