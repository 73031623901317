import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import BranchRow from './branch-row';

/**
 * Component to render list of branches
 * @returns {*}
 */
export default class BranchList extends PureComponent {
  static propTypes = {
    isBranchSelectingSkipped: PropTypes.bool.isRequired,
    branches: PropTypes.array.isRequired,
    currentBranch: PropTypes.object.isRequired,
    changeBranchAction: PropTypes.func.isRequired,
    hostName: PropTypes.string.isRequired,
    style: PropTypes.object
  };

  static defaultProps = {};

  render() {
    const {
      isBranchSelectingSkipped,
      branches,
      currentBranch,
      changeBranchAction,
      hostName,
      style
    } = this.props;

    const branchId = currentBranch.id;

    return (
      <div id="branch-list-shell" className="branch-list">
        {sortBy(branches, (branch) => branch.id !== branchId).map((branch) => (
          <BranchRow
            key={branch.id}
            branch={branch}
            isBranchSelectingSkipped={isBranchSelectingSkipped}
            changeBranchAction={changeBranchAction}
            currentBranch={currentBranch}
            hostName={hostName}
            style={style}
          />
        ))}
      </div>
    );
  }
}
