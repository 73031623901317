import React from 'react';

import CookiesModal from 'client/components/CookiesModal';

const CookieModal = ({
  closeModal,
  openPrivacyModal
}: {
  closeModal: () => void;
  openPrivacyModal: () => void;
}) => (
  <CookiesModal closeModal={closeModal} openPrivacyModal={openPrivacyModal} />
);

export default React.memo(CookieModal);
