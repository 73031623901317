import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

/**
 * Component to render row with phone number
 * @param phone
 * @returns {null}
 */
export default class BranchPhoneRow extends PureComponent {
  static propTypes = {
    phone: PropTypes.string
  };

  static defaultProps = {};

  render() {
    const { phone } = this.props;

    return phone ? (
      <div className="phone" data-testid="branch-phone-brselector">
        <i className="fas fa-phone" />
        {phone}
      </div>
    ) : null;
  }
}
