import classNames from 'classnames';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './DeliverySwitchShell.scss';
import { withRouter } from 'react-router-dom';

import { ORDER_TYPES } from '../../../../../enums/order_types.enum';

/**
 * Component to render pickup/delivery switch in shell
 */
@withRouter
@inject('store')
class DeliverySwitchShell extends Component {
  static propTypes = {
    isDeliveryChosen: PropTypes.bool,
    changeOrderType: PropTypes.func.isRequired,
    isDeliveryEnabled: PropTypes.bool.isRequired,
    isPickupEnabled: PropTypes.bool.isRequired,
    style: PropTypes.object
  };

  static defaultProps = {};

  /**
   * Method to handle delivery type changing
   * @private
   * @param isDelivery
   */
  _handleDeliveryTypeChange = (isDelivery) => {
    this.props.changeOrderType(
      isDelivery ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PICKUP
    );
  };

  /**
   * Method to render order type button
   * @param {boolean} isOrderTypeActive - if this order type active now
   * @param {string} title - title of button
   * @param {boolean} isDeliveryButton - if this button is delivery
   * @returns {*}
   * @private
   */
  _renderOrderTypeButton = (isOrderTypeActive, title, isDeliveryButton) => (
    <button
      onClick={() => this._handleDeliveryTypeChange(isDeliveryButton)}
      className={classNames({
        'type-btn': true,
        selected: isOrderTypeActive
      })}
      style={
        isOrderTypeActive
          ? this.props.style.buttonSelected
          : this.props.style.button
      }
      data-testid="shell-deliv-pickup-btn"
    >
      {title}
    </button>
  );

  render() {
    const { location } = this.props;
    const regex = /address$/;
    const shouldHideButtons = regex.test(location.pathname);
    const isDelivery = this.props.isDeliveryChosen;

    return (
      <div className="delivery-switch-shell">
        {!shouldHideButtons && (
          <div className="wrapper" style={this.props.style.wrapper}>
            {this.props.isDeliveryEnabled &&
              this._renderOrderTypeButton(
                isDelivery,
                this.props.t('enter_address_modal:delivery'),
                true
              )}

            {this.props.isPickupEnabled &&
              this._renderOrderTypeButton(
                !isDelivery,
                this.props.t('enter_address_modal:selfcollect'),
                false
              )}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(['enter_address_modal'])(DeliverySwitchShell);
