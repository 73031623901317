import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'client/ui/Button';
import ButtonGroup from 'client/ui/ButtonGroup';
import Heading from 'client/ui/Heading';
import Modal from 'client/ui/Modal';

import css from './AddressChangeConfirmationModal.module.scss';
import { AddressChangeConfirmationModalProps } from './AddressChangeConfirmationModal.type';

const AddressChangeConfirmationModal = ({
  onConfirm,
  onCancel
}: AddressChangeConfirmationModalProps) => {
  const { t } = useTranslation('address_form');

  return (
    <Modal onClose={() => null}>
      <div className={css.AddressChangeConfirmationModal}>
        <div className={css.confirmationText}>
          <Heading variant="h4">{t('confirm_change_zip')}</Heading>
          <Heading variant="h4">{t('mbv_df_changed')}</Heading>
        </div>
        <ButtonGroup className={css.buttonGroup}>
          <Button onClick={onCancel}>{t('cancel_change')}</Button>
          <Button variant="submit" onClick={onConfirm}>
            {t('confirm_change')}
          </Button>
        </ButtonGroup>
      </div>
    </Modal>
  );
};

export { AddressChangeConfirmationModal };

export default memo(AddressChangeConfirmationModal);
