import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import CategoryMenu from '../../themes/theme-hermes/components/category_menu/_category_menu_desktop';
import Footer from '../../themes/theme-hermes/components/footer';
import TitleDesktop from '../../themes/theme-hermes/components/header/desktop';
import HomepageDesktop from '../../themes/theme-hermes/components/homepage/desktop';
import OfferList from '../../themes/theme-hermes/components/offer/offer_list';
import OpeningHours from '../../themes/theme-hermes/components/opening_hours/OpeningHours';
import ProductsDesktopContainer from '../../themes/theme-hermes/components/products_list/desktop/ProductsDesktopContainer';
import RightSideBlock from '../../themes/theme-hermes/components/right_side_block';
import SubcategoryMenu from '../../themes/theme-hermes/components/subcategory_menu/SubcategoryMenu';

// States

// CSS
import './AppWrapper.scss';

@inject('store')
@withRouter
@observer
export default class AppWrapperDesktopComponent extends Component {
  pageContent = () => {
    if (
      this.props.store.productsStore.isSearchEnabled ||
      !this.props.store.categoryMenuStore.isHomepageActive
    ) {
      return this.categoriesList();
    }

    return <HomepageDesktop />;
  };

  categoriesList = () =>
    this.props.store.categoryMenuStore.isOfferCategoryActive &&
    !this.props.store.productsStore.isSearchEnabled ? (
      <OfferList />
    ) : (
      <ProductsDesktopContainer />
    );

  rightPanel = () => (
    <div id="right-panel-wrapper">
      <RightSideBlock />
    </div>
  );

  menu = () =>
    this.props.store.categoryMenuStore.activeCategory ? (
      <CategoryMenu
        category={this.props.store.categoryMenuStore.activeCategory.id}
      />
    ) : null;

  subMenu = () =>
    !this.props.store.productsStore.isSearchEnabled ? (
      <SubcategoryMenu
        subcategory={this.props.store.categoryMenuStore.activeSubcategory}
      />
    ) : null;

  _renderDefaultCategoryMenu() {
    return (
      <div id="row-2">
        <div id="categories-slim-width-wrapper">
          {this.menu()}
          {this.subMenu()}
          {this.pageContent()}
        </div>
        {this.rightPanel()}
      </div>
    );
  }

  _renderWideCategoryMenu() {
    return (
      <div id="row-2">
        <div id="categories-wide-width-wrapper" className="white-block">
          {this.menu()}
        </div>
      </div>
    );
  }

  _renderMain() {
    const isWide = this.props.store.categoryMenuStore.shouldApplyWideTheme;

    return (
      <div
        id="hermes-theme"
        className={classNames({
          'app-state-desktop-wrapper': true,
          'hermes-wide-theme': isWide
        })}
      >
        <div id="row-1">
          <div id="title-wrapper">
            <TitleDesktop
              branchName={this.props.store.restaurantStore.branch.branchName}
              branches={this.props.store.restaurantStore.restaurant.getBranches}
              branchId={this.props.store.restaurantStore.branch.id}
              hostName={this.props.store.restaurantStore.hostName}
            />
          </div>
          <div id="hours-wrapper">
            <OpeningHours />
          </div>
        </div>
        {isWide
          ? this._renderWideCategoryMenu()
          : this._renderDefaultCategoryMenu()}
        {isWide ? (
          <div id="row-3">
            <div id="products-view-wrapper" className="white-block">
              {this.subMenu()}
              {this.pageContent()}
            </div>
            {this.rightPanel()}
          </div>
        ) : null}
      </div>
    );
  }

  _themeRender() {
    const {
      leftBackgroundLink,
      rightBackgroundLink,
      mainBackgroundLink,
      mainBackgroundFullScreenPicurl
    } = this.props.store.themesStore;

    const fullScreenStyle = {
      backgroundImage: `url(${mainBackgroundFullScreenPicurl})`,
      backgroundAttachment: 'fixed'
    };

    const patternStyle = {
      backgroundImage: `url(${mainBackgroundLink})`
    };

    const leftStyle = {
      backgroundImage: `url(${leftBackgroundLink})`
    };

    const rightStyle = {
      backgroundImage: `url(${rightBackgroundLink})`
    };

    const isFullScreenEnabled = !!mainBackgroundFullScreenPicurl;

    return (
      // TODO ElijahSh: Move this inside theme

      <div
        style={isFullScreenEnabled ? fullScreenStyle : patternStyle}
        className={classNames({
          'desktop-wrapper': true,
          'full-screen': isFullScreenEnabled
        })}
      >
        <div style={leftStyle} className="left-bg" />
        {this._renderMain()}
        <div style={rightStyle} className="right-bg" />
      </div>
    );
  }

  render() {
    return (
      <>
        {this._themeRender()}
        <Footer
          branch={this.props.store.restaurantStore.branch}
          restaurant={this.props.store.restaurantStore.restaurant}
          isTableReservationAvailable={
            this.props.store.restaurantStore.branch.branchIsTableReservation
          }
          isStartPage={false}
        />
      </>
    );
  }
}
