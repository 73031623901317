import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import openModal from 'client/utils/openModal';

import states from '../../../../../enums/states.enum';
import { ShellModalsRouteType } from '../../../../../routes/ShellModalsRoute/ShellModalsRoute.type';
import { filterPrice } from '../../../../../utils/functions';
import { generateLinkFor } from '../../../../../utils/routing';
import ClosedMobile from '../../../components/closed-store/closed-mobile';
import DiscountPercentageMobile from '../../../components/discount_block';
import OfferList from '../../../components/offer/offer_list';
import { ProductsShellWrapper } from '../../../components/products_list/shell';
import FooterShellButton from '../buttons-shell/footer-button';
import DeliveryAreaSwitch from '../delivery-area-switch';
import HeaderShell from '../header-shell';
import ShellLayout from '../shell-layout';

import './ProductsListShell.scss';

@inject('store')
@withRouter
@observer
class ProductsListShell extends Component {
  componentDidMount() {
    const categoryName = this.props.store.categoryMenuStore.activeCategory.name;

    this.props.store.themesStore.setShellTitle(categoryName, this._backAction);
  }

  /**
   * Action to click back button
   * @private
   */
  _backAction = () => {
    this.props.history.push(generateLinkFor(states.cat, this.props, {}, true));
  };

  /**
   * Method to render products/offers list
   * @param {boolean} isOfferCategoryActive - true is current category is offers
   * @returns {*}
   * @private
   */
  _renderProductsList = (isOfferCategoryActive) =>
    isOfferCategoryActive ? <OfferList /> : <ProductsShellWrapper />;

  /**
   * Method to render discount panel
   * @param {number} discountPercentage - discount value
   * @returns {null}
   * @private
   */
  _renderDiscountPanel = (discountPercentage) =>
    discountPercentage !== 0 ? (
      <DiscountPercentageMobile
        discount={discountPercentage}
        isShell
        style={this.props.store.themesStore.discountStyle()}
      />
    ) : null;

  /**
   * Method to render shell area panel
   * @param {string} currentAreaCode - current Area Code
   * @param {boolean} isDelivery - is delivery type is active
   * @param {function} changeOrderTypeAction - action on changing order type
   * @param {function} areaButtonAction - action on click area button
   * @param {boolean} isDeliveryEnabled - if delivery available
   * @param {boolean} isPickupEnabled - if pickup available
   * @returns {*}
   * @private
   */
  _renderAreaPanel = (
    addressPostalCode,
    isDelivery,
    changeOrderTypeAction,
    areaButtonAction,
    isDeliveryEnabled,
    isPickupEnabled,
    langKey,
    address,
    useCalculationTypeByDeliveryArea,
    fullStreetAddressExists
  ) => (
    <DeliveryAreaSwitch
      langKey={langKey}
      areaCode={addressPostalCode}
      currentAddress={fullStreetAddressExists ? address.city : undefined}
      isAddressNeeded={useCalculationTypeByDeliveryArea}
      isDelivery={isDelivery}
      changeOrderTypeAction={changeOrderTypeAction}
      areaButtonAction={areaButtonAction}
      isDeliveryEnabled={isDeliveryEnabled}
      isPickupEnabled={isPickupEnabled}
      deliverySwitchStyle={this.props.store.themesStore.deliverySwitchStyle()}
      postalCodeStyle={this.props.store.themesStore.postalCodeStyle()}
    />
  );

  /**
   * Method to handle change order type button action
   * @param {string} type
   * @private
   */
  _changeOrderType = (type) => {
    this.props.store.deliveryAddressStore.changeOrderType(type);
  };

  /**
   * Method to handle area button action
   * @private
   */
  _areaButtonAction = () => {
    const addressModalUrl = openModal(ShellModalsRouteType.ADDRESS_MODAL);

    this.props.store.basketStore.setLastProductClicked();

    this.props.history.push(addressModalUrl);
  };

  _renderHeader = () => {
    const { shellBackButtonAction, shellTitle } = this.props.store.themesStore;

    return (
      <HeaderShell
        title={shellTitle}
        backAction={shellBackButtonAction}
        style={this.props.store.themesStore.headerStyle()}
      />
    );
  };

  _renderContent = () => {
    const { discountPercentage } = this.props.store.basketStore;
    const { isOfferCategoryActive } = this.props.store.categoryMenuStore;

    const {
      isDelivery,
      hasDelivery,
      hasPickup,
      address,
      fullStreetAddressExists,
      addressPostalCode
    } = this.props.store.deliveryAddressStore;

    const {
      useCalculationTypeByDeliveryArea
    } = this.props.store.restaurantStore;

    const { id } = this.props.store.languagesStore.activeLanguage;

    return (
      <>
        <ClosedMobile
          isShopClosed={this.props.store.restaurantStore.isShopClosed}
        />

        {this._renderAreaPanel(
          addressPostalCode,
          isDelivery,
          this._changeOrderType,
          this._areaButtonAction,
          hasDelivery,
          hasPickup,
          id,
          address,
          useCalculationTypeByDeliveryArea,
          fullStreetAddressExists
        )}
        {this._renderDiscountPanel(discountPercentage)}
        {this._renderProductsList(isOfferCategoryActive)}
      </>
    );
  };

  _renderContentFooter = () => {
    const { hasProducts } = this.props.store.basketStore;

    const label = hasProducts
      ? this.props.store.basketStore.getIntlPrice(
          this.props.store.basketStore.finalPriceWithOffers
        )
      : this.props.store.basketStore.getIntlPrice(filterPrice(0));

    const { orderButtonStyle } = this.props.store.themesStore;

    return (
      <div className="footer-buttons-wrapper">
        <FooterShellButton
          label={label}
          link={generateLinkFor(states.basket, this.props, {}, true)}
          isCartButton
          animated={!!this.props.store.productsStore.animateId}
          buttonOrderStyle={orderButtonStyle()}
        />
      </div>
    );
  };

  render() {
    this.props.store.themesStore.changeMobileState(states.app);

    return (
      <ShellLayout
        shellHeader={this._renderHeader()}
        shellContent={this._renderContent()}
        contentFooter={this._renderContentFooter()}
      />
    );
  }
}

export default withTranslation(['seo'])(ProductsListShell);
