import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { RootContext } from '../../../../stores';
import CommonHeaderMobile from '../../../../themes/theme-hermes/components/header/mobile/common';
import Button from '../../../../ui/Button';
import Branch from '../Branch';

import style from './BranchListMobile.module.scss';

const BranchListMobile = () => {
  const history = useHistory();
  const { restaurantStore } = useContext(RootContext);
  const { restaurant, branch } = restaurantStore;
  const branches = restaurant.getBranches;
  const { t } = useTranslation(['modal_footer_common']);

  const onClick = () => {
    history.goBack();
  };

  return (
    <div className={style.BranchListMobile}>
      <CommonHeaderMobile />
      <div className={style.container}>
        <div className={style.scroll}>
          {branches?.map((item) => (
            <Branch
              key={item.id}
              branch={item}
              active={item.id === branch.id}
            />
          ))}
        </div>
      </div>
      <div className={style.footer}>
        <Button className={style.Button} onClick={onClick}>
          {t('confirm')}
        </Button>
      </div>
    </div>
  );
};

export default memo(BranchListMobile);
