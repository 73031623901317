import { observable, toJS } from 'mobx';

/**
 * Language Model class. Used to create language.
 * @constructor
 * @param {string} id - different languages.
 * @param {object} language - different languages.
 * @property {number} key - The unique id of a language
 * @property {string} name - The name of a language
 * @property {string} icon - The image for a language
 * @property {boolean} active - The state of the language
 * @throws {Error} if parameters are invalid
 */
export class LanguageModel {
  id;
  key;
  name;
  icon = '';

  constructor(id, language) {
    language.id = id;
    Object.assign(this, language);
  }

  @observable active = false;

  /**
   * Method to serialize Language to pure JS object.
   * @return {object} - serialized Language
   */
  getToJS() {
    return toJS(Object.assign({}, this, { root: {} }));
  }
}
