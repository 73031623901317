import React, { PureComponent } from 'react';
import { PropTypes } from 'mobx-react';
import { Tooltip } from 'antd';

/**
 * Component to render allergen tooltip
 */
export default class AllergenTooltip extends PureComponent {
  static propTypes = {
    allergens: PropTypes.objectOrObservableObject
  };

  state = {
    visible: false
  };

  /**
   * Method to change visibility state
   * @param visible
   * @private
   */
  _handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  /**
   * Method to render tooltip content with allergens
   * @returns {*}
   * @private
   */
  _renderTooltipContent = () => {
    const allAdditives = this.props.allergens
      ? this.props.allergens.additives.concat(this.props.allergens.allergens)
      : [];
    return allAdditives.map((additive) => {
      return (
        <React.Fragment key={additive}>
          {additive} <br />
        </React.Fragment>
      );
    });
  };

  render() {
    if (
      this.props.allergens &&
      this.props.allergens.additives.concat(this.props.allergens.allergens)
        .length
    ) {
      return (
        <Tooltip
          title={this._renderTooltipContent()}
          trigger="click"
          visible={this.state.visible}
          onVisibleChange={this._handleVisibleChange}
        >
          <i
            className="mdi mdi-information info-button"
            onClick={(e) => e.stopPropagation()}
          />
        </Tooltip>
      );
    }
    return null;
  }
}
