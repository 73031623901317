/* eslint-disable import/no-cycle */
import { action, toJS } from 'mobx';

import RootStore from '../stores';

import AdditiveProductModel from './additive_product.model';

/**
 * Additives Category model
 * @constructor
 * @property {id} - Unique id of category.
 * @property {string} - Name of category.
 * @property {array} - Array of AdditiveProductModel objects
 */
export default class AdditiveCategoryModel {
  public root: RootStore;

  public id: number;

  public name: string;

  public products: AdditiveProductModel[];

  public isShowPicture: boolean;

  public isBio: boolean;

  public isHalal: boolean;

  public isVegetarian: boolean;

  public isVegan: boolean;

  public isLactoseFree: boolean;

  public isGlutenFree: boolean;

  public isSugarFree: boolean;

  public isNew: boolean;

  public isAdult16: boolean;

  public isAdult18: boolean;

  public hot: number;

  constructor(category: any, root: RootStore) {
    this.root = root;

    this.id = category.id;

    this.name = category.name || '';

    this.isShowPicture = category.is_show_picture || false;

    this.isBio = category.is_bio || false;

    this.isHalal = category.is_halal || false;

    this.isVegetarian = category.is_vegetarian || false;

    this.isVegan = category.is_vegan || false;

    this.isLactoseFree = category.is_lactose_free || false;

    this.isGlutenFree = category.is_gluten_free || false;

    this.isSugarFree = category.is_sugar_free || false;

    this.isNew = category.is_new || false;

    this.isAdult16 = category.is_food_adult || false;

    this.isAdult18 = category.is_adults || false;

    this.hot = category.hot || 0;

    this.products = this.prepareData(category.products) || [];
  }

  /**
   * Prepare products array
   * @param products
   */
  @action public prepareData(products = []) {
    const halalIsAvailable = !!this.root.restaurantStore.branch
      ?.HalalCertificate;

    return products.map(
      (product) => new AdditiveProductModel(product, this, halalIsAvailable)
    );
  }

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  public getToJS() {
    return toJS({ ...this, root: {} });
  }
}
