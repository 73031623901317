import classNames from 'classnames';
import React, { ChangeEvent, Component, KeyboardEvent, createRef } from 'react';
import { withTranslation } from 'react-i18next';

import { ICommentFormProps } from './props.interface';
import './styles.scss';

class CommentForm extends Component<ICommentFormProps> {
  commentStatuses = {
    save: 'save',
    edit: 'edit',
    add: 'add'
  };

  state = {
    commentStatus: this.commentStatuses.add,
    commentText: ''
  };

  commentTextAreaRef = createRef<HTMLTextAreaElement>();

  componentDidMount() {
    const { comment } = this.props.group.product;

    if (comment) {
      this.setState({
        commentText: comment,
        commentStatus: this.commentStatuses.edit
      });
    }
  }

  /**
   * Handler for button of adding/editing/saving product comment
   */
  onCommentButtonClick = () => {
    const { commentStatus, commentText } = this.state;

    let newCommentStatus = this.commentStatuses.save;

    if (commentStatus === this.commentStatuses.save) {
      newCommentStatus = commentText
        ? this.commentStatuses.edit
        : this.commentStatuses.add;

      this.props.saveProductComment(
        this.props.group.product.uniqString,
        commentText
      );
    }

    this.setState(
      {
        commentStatus: newCommentStatus
      },
      () => {
        if (
          this.state.commentStatus === this.commentStatuses.save &&
          this.commentTextAreaRef.current
        ) {
          this.commentTextAreaRef.current.focus();

          setTimeout(() => {
            this.commentTextAreaRef.current &&
              this.commentTextAreaRef.current.setSelectionRange(
                commentText.length,
                commentText.length
              );
          }, 0);
        }
      }
    );
  };

  /**
   * Handler for changing comment textarea value
   */
  onChangeCommentTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      commentText: event.target.value
    });
  };

  /**
   * Method for saving comment on press Enter event
   */
  onKeyPressCommentTextArea = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.applyCommentFieldChanges();
    }
  };

  /**
   * Method for saving comment on blur
   */
  onBlurCommentTextArea = () => {
    this.state.commentStatus === this.commentStatuses.save &&
      this.state.commentText &&
      this.applyCommentFieldChanges();
  };

  /**
   * Method for applying changes in comment field
   */
  applyCommentFieldChanges = () => {
    const { commentText } = this.state;

    const newCommentStatus = commentText
      ? this.commentStatuses.edit
      : this.commentStatuses.add;

    this.props.saveProductComment(
      this.props.group.product.uniqString,
      commentText
    );

    this.setState({
      commentStatus: newCommentStatus
    });
  };

  render() {
    const { dataTestId } = this.props;
    const { comment: commentTextFromStore } = this.props.group.product;
    const { commentStatus, commentText } = this.state;

    let commentButtonText = this.props.t('order_checkout:addComment');

    // eslint-disable-next-line default-case
    switch (commentStatus) {
      case this.commentStatuses.save:
        commentButtonText = this.props.t('common:save');

        break;
      case this.commentStatuses.edit:
        commentButtonText = this.props.t('order_checkout:editComment');
    }

    const showCommentTextArea = commentStatus === this.commentStatuses.save;

    const commentTextAreaPlaceholder = this.props.t(
      'order_checkout:productComment'
    );

    const templateStyles = this.templateStyles();

    return (
      <div
        className={classNames({
          'comment-container': true,
          'comment-existing-mode': !showCommentTextArea && commentTextFromStore
        })}
      >
        {templateStyles && (
          <style>{templateStyles.commentAreaStyle.placeholderColor}</style>
        )}
        <div className="comment-button-wrapper">
          <button
            className={classNames({
              'comment-button': true,
              'save-mode':
                this.state.commentStatus === this.commentStatuses.save
            })}
            onClickCapture={this.onCommentButtonClick}
            data-testid="comment-product-btn-basket"
          >
            {commentButtonText}
          </button>
        </div>
        {showCommentTextArea ? (
          <textarea
            ref={this.commentTextAreaRef}
            className="comment-text-area"
            onChange={this.onChangeCommentTextArea}
            onKeyPress={this.onKeyPressCommentTextArea}
            onBlur={this.onBlurCommentTextArea}
            placeholder={commentTextAreaPlaceholder}
            value={commentText}
            style={
              templateStyles
                ? {
                    borderColor: templateStyles.commentAreaStyle.borderColor
                  }
                : undefined
            }
            data-testid="comment-product-basket"
          />
        ) : (
          <div
            className={classNames({
              'comment-text-block': true,
              empty: !commentTextFromStore
            })}
            data-testid={dataTestId}
          >
            {commentTextFromStore}
          </div>
        )}
      </div>
    );
  }

  templateStyles = () => {
    const { wrapperStyle } = this.props;

    return wrapperStyle
      ? {
          commentAreaStyle: {
            borderColor: `${wrapperStyle.color}33`,
            placeholderColor: `.comment-text-area::placeholder { color: ${wrapperStyle.color}33; }`
          }
        }
      : null;
  };
}

export default withTranslation(['basket', 'common', 'coupons'])(CommentForm);
