import { inject, observer } from 'mobx-react';
import React, { CSSProperties, Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import ContactPhoneIcon from 'client/icons/icon-contact-phone.svg';
import WhatsAppIconWithoutBG from 'client/icons/icon-whatsapp-without-bg.svg';
import WhatsAppIcon from 'client/icons/icon-whatsapp.svg';
import RootStore from 'client/stores';
import { generateImageFromText } from 'client/utils/functions';

import ModalFooterButtonsShell from '../../../../theme-hermes-shell/components/modal-footer';
import ButtonStandard from '../../../buttons/ButtonStandard';
import { ContactDataType } from '../../types';
import './OrderError.scss';

interface OrderErrorProps {
  successComponentMountAction?: () => void;
  errorComponentMountAction?: () => void;
  willUnmount?: () => void;
  backAction?: () => void;
  contactData?: ContactDataType;
  mainHeader: string;
  contentHeader: string;
  contentDescription: string;
  style: CSSProperties;
  buttonStandardStyle?: CSSProperties;
  wrapperStyle?: CSSProperties;
}

/**
 * Order error component is used to show information about error in attempt to make order
 */
@inject('store')
@observer
class OrderError extends Component<
  OrderErrorProps & WithTranslation & { store: RootStore }
> {
  componentDidMount() {
    const { errorComponentMountAction } = this.props;

    errorComponentMountAction?.();
  }

  componentWillUnmount() {
    const { willUnmount } = this.props;

    willUnmount?.();
  }

  /**
   * Method to render footer buttons
   */
  renderFooterButtons = () => {
    const {
      store: {
        themesStore,
        themesStore: { isShellThemeActive }
      },
      backAction,
      t,
      buttonStandardStyle
    } = this.props;

    return isShellThemeActive ? (
      <ModalFooterButtonsShell
        backButtonAction={backAction}
        backButtonLabel={t('common:cmnBack')}
        isHideNextButton
        orderButtonStyle={themesStore.orderButtonStyle}
        buttonStandardStyle={buttonStandardStyle}
      />
    ) : (
      <div className="button-container">
        <ButtonStandard onClick={backAction} label={t('common:cmnBack')} />
      </div>
    );
  };

  renderEmailImage = () => {
    const { t, contactData } = this.props;

    return (
      contactData?.appSmartInfo.email && (
        <div className="contact-email" data-testid="sup-email-title-checkout">
          {`${t('address_form:emailAddress')}:`}
          <img
            src={generateImageFromText(
              contactData.appSmartInfo.email,
              {
                string: contactData.appSmartInfo.email,
                offset: 0
              },
              {
                string: contactData.appSmartInfo.email,
                offset: contactData.appSmartInfo.email.length
              },
              { width: 300, height: 16, fontSize: 12 }
            )}
            alt="Email address"
          />
        </div>
      )
    );
  };

  renderResponsiveCallDataBlock = (isContactServiceProvider: boolean) => {
    const {
      store: {
        themesStore: { isMobile },
        analyticsStore
      },
      contactData,
      t,
      wrapperStyle
    } = this.props;

    return isMobile ? (
      <div className="responsive-contacts">
        <a
          className="responsive-contact"
          href={`tel:${
            isContactServiceProvider
              ? contactData?.appSmartInfo.phoneNumber
              : contactData?.restaurantInfo.phoneNumber
          }`}
          onClick={() => analyticsStore.sendPhoneNumberFailedOrder()}
          data-testid={
            isContactServiceProvider
              ? 'sup-phone-checkout'
              : 'cont-phone-checkout'
          }
          style={
            wrapperStyle
              ? {
                  border: `1px solid ${wrapperStyle?.color}1A`,
                  backgroundColor: `${wrapperStyle?.color}0D`
                }
              : undefined
          }
        >
          <img src={ContactPhoneIcon} alt="phone number" />
        </a>
        {isContactServiceProvider && (
          <a
            className="responsive-contact whatsapp"
            href={`whatsapp://send?phone=${contactData?.appSmartInfo.phoneNumber.replace(
              /\D/g,
              ''
            )}`}
            data-testid="sup-whatsapp-checkout"
          >
            <img src={WhatsAppIconWithoutBG} alt="call whatsapp" />
          </a>
        )}
      </div>
    ) : (
      <div>
        <div
          className="contact-phone"
          data-testid={
            isContactServiceProvider
              ? 'sup-phone-title-checkout'
              : 'cont-phone-title-checkout'
          }
        >
          {`${t('address_form:phone')}:`}
          <a
            className="phone-value"
            href={`tel:${
              isContactServiceProvider
                ? contactData?.appSmartInfo.phoneNumber
                : contactData?.restaurantInfo.phoneNumber
            }`}
            onClick={() => analyticsStore.sendPhoneNumberFailedOrder()}
            data-testid={
              isContactServiceProvider
                ? 'sup-phone-checkout'
                : 'cont-phone-checkout'
            }
          >
            {isContactServiceProvider
              ? contactData?.appSmartInfo.phoneNumber
              : contactData?.restaurantInfo.phoneNumber}
          </a>
        </div>
        {isContactServiceProvider &&
          contactData?.appSmartInfo.email &&
          this.renderEmailImage()}
        {isContactServiceProvider && contactData?.appSmartInfo.phoneNumber && (
          <a
            className="contact-whatsapp"
            href={`whatsapp://send?phone=${contactData.appSmartInfo.phoneNumber.replace(
              /\D/g,
              ''
            )}`}
            data-testid="whatsapp-button"
          >
            <img src={WhatsAppIcon} alt="whatsapp-call" />
            <div className="inner-wrapper">
              <span>{`WhatsApp chat ${contactData.appSmartInfo.phoneNumber}`}</span>
            </div>
          </a>
        )}
      </div>
    );
  };

  renderContactBlock = ({
    header,
    name,
    address,
    city,
    isContactServiceProvider
  }: {
    header: string;
    name: string;
    address: string;
    city: string;
    isContactServiceProvider: boolean;
  }) => {
    const {
      store: {
        themesStore: { isMobile }
      },
      contactData,
      wrapperStyle
    } = this.props;

    return (
      <div
        className="contact-block"
        style={
          wrapperStyle
            ? {
                backgroundColor: `${wrapperStyle?.color}08`,
                borderRadius: 4
              }
            : undefined
        }
      >
        <div>
          <div className="contact-header" data-testid="cont-title-checkout">
            {header}
          </div>
          <hr />
          <div className="contact-info" data-testid="cont-name-checkout">
            {name}
          </div>
          <div className="contact-info" data-testid="cont-addr-checkout">
            {address}
          </div>
          <div className="contact-info" data-testid="cont-city-checkout">
            {city}
          </div>

          {isMobile &&
            isContactServiceProvider &&
            contactData?.appSmartInfo.email &&
            this.renderEmailImage()}
        </div>
        {this.renderResponsiveCallDataBlock(isContactServiceProvider)}
      </div>
    );
  };

  renderContactData = () => {
    const { contactData, t } = this.props;

    return (
      contactData && (
        <>
          {this.renderContactBlock({
            header: t('order_checkout:contact'),
            name: contactData.restaurantInfo.name,
            address: contactData.restaurantInfo.address,
            city: contactData.restaurantInfo.city,
            isContactServiceProvider: false
          })}
          {this.renderContactBlock({
            header: t('order_checkout:contactServiceProvider'),
            name: contactData.appSmartInfo.name,
            address: contactData.appSmartInfo.address,
            city: contactData.appSmartInfo.city,
            isContactServiceProvider: true
          })}
        </>
      )
    );
  };

  render() {
    const { mainHeader, contentHeader, contentDescription, style } = this.props;

    return (
      <div id="order-payment-methods" className="order-error">
        <div className="heading" style={style}>
          <i className="fas fa-times fa-lg" />
          {mainHeader}
        </div>
        <div className="wrapper">
          <p className="title">{contentHeader}</p>
          <p className="description">{contentDescription}</p>
          {this.renderContactData()}
        </div>
        {this.renderFooterButtons()}
      </div>
    );
  }
}

export default withTranslation(['order_payment_methods'])(OrderError);
