/* eslint-disable import/no-cycle */
import { action, observable } from 'mobx';
import { Context, createContext } from 'react';

import API from 'client/modules/api';
import Storage from 'client/modules/storage';
import AdditivesStore from 'client/stores/additives';
import AggregatorStore from 'client/stores/aggregator';
import AnalyticsStore from 'client/stores/analytics';
import BasketStore from 'client/stores/basket';
import CategoryMenuStore from 'client/stores/categoryMenu';
import CouponsStore from 'client/stores/coupons';
import DeliveryAddressStore from 'client/stores/deliveryAddress';
import ErrorStore from 'client/stores/error';
import FavoritesStore from 'client/stores/favorites';
import FeedbackStore from 'client/stores/feedback';
import HomePageStore from 'client/stores/homepage';
import IngredientsSelectStore from 'client/stores/ingridientsSelect';
import LanguagesStore from 'client/stores/languages';
import MaintenanceModeModalStore from 'client/stores/maintenanceMode';
import NewsStore from 'client/stores/news';
import OffersStore from 'client/stores/offers';
import OpeningHoursStore from 'client/stores/openingHours';
import OrderPaymentMethodsStore from 'client/stores/orderPaymentMethods';
import ProductsStore from 'client/stores/products';
import ReservationTableStore from 'client/stores/reservation';
import RestaurantStore from 'client/stores/restaurant';
import SeoDataStore from 'client/stores/seoData';
import ThemesStore from 'client/stores/themes';
import ThirdPartyServicesStore from 'client/stores/thirdPartyService';
import UtmStore from 'client/stores/utm';

import { PLATFORM } from '../../enums/platform.enum';

const storage = new Storage();

const initialStore = {
  loading: false,
  restaurantStore: {},
  aggregatorStore: {},
  languagesStore: {},
  orderPaymentMethodsStore: {},
  openingHoursStore: {},
  basketStore: {
    lastProductClickedId: null
  },
  analyticsStore: {},
  deliveryAddressStore: {
    address: {
      first_name: '',
      last_name: '',
      zip: '',
      company: '',
      email: '',
      phone: '',
      street: '',
      street_no: '',
      city: '',
      floor: '',
      apartment_num: '',
      comment: '',
      hunger_de: 0
    },
    changed: {}
  },
  offerStore: {
    offerToEdit: {},
    offer: {},
    offerCategory: {
      id: -1,
      name: 'Offers',
      pos: -1,
      description: '',
      picurl: ''
    },
    freeItem: {}
  },
  homepageStore: {
    homepageElements: []
  },
  categoryMenuStore: {},
  productsStore: {},
  themesStore: {
    platform: ''
  },
  maintenanceModeModalStore: {},
  additivesStore: {},
  couponsStore: {},
  ingredientsSelectStore: {
    product: {}
  },
  errorStore: {}
};

type Store = Partial<typeof initialStore> & Record<string, any>;

class RootStore {
  api: { [param: string]: any };

  states: Store;

  restaurantStore: RestaurantStore;

  aggregatorStore: AggregatorStore;

  languagesStore: LanguagesStore;

  orderPaymentMethodsStore: OrderPaymentMethodsStore;

  openingHoursStore: OpeningHoursStore;

  basketStore: BasketStore;

  deliveryAddressStore: DeliveryAddressStore;

  offerStore: OffersStore;

  productsStore: ProductsStore;

  themesStore: ThemesStore;

  maintenanceModeModalStore: MaintenanceModeModalStore;

  additivesStore: AdditivesStore;

  seoDataStore: SeoDataStore;

  couponsStore: CouponsStore;

  reservationTableStore: ReservationTableStore;

  feedbackStore: FeedbackStore;

  homepageStore: HomePageStore;

  ingredientsSelectStore: IngredientsSelectStore;

  errorStore: ErrorStore;

  newsStore: NewsStore;

  analyticsStore: AnalyticsStore;

  categoryMenuStore: CategoryMenuStore;

  thirdPartyServicesStore: ThirdPartyServicesStore;

  favoritesStore: FavoritesStore;

  utmStore: UtmStore;

  @observable loading = false;

  @action setLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  getIsRunInsideMobileApp(): boolean {
    const { themesStore } = this.states;

    return themesStore?.platform === PLATFORM.PORTAL_WEB;
  }

  constructor(states: Store = initialStore) {
    this.api = new API();

    this.states = states;

    this.restaurantStore = new RestaurantStore(
      this,
      states.restaurantStore,
      this.api,
      storage
    );

    this.aggregatorStore = new AggregatorStore(
      this,
      states.aggregatorStore,
      this.api,
      storage
    );

    this.languagesStore = new LanguagesStore(
      this,
      states.languagesStore,
      this.api
    );

    this.orderPaymentMethodsStore = new OrderPaymentMethodsStore(
      this,
      states.orderPaymentMethodsStore,
      this.api,
      storage
    );

    this.openingHoursStore = new OpeningHoursStore(
      this,
      states.openingHoursStore as any
    );

    this.basketStore = new BasketStore(
      this,
      states.basketStore as any,
      storage
    );

    this.deliveryAddressStore = new DeliveryAddressStore(
      this,
      states.deliveryAddressStore as any,
      storage
    );

    this.offerStore = new OffersStore(this, states.offerStore as any, this.api);

    this.categoryMenuStore = new CategoryMenuStore(
      this,
      states.categoryMenuStore,
      this.api,
      storage
    );

    this.productsStore = new ProductsStore(this, states.productsStore);

    this.themesStore = new ThemesStore(
      this,
      states.themesStore,
      this.api,
      storage
    );

    this.maintenanceModeModalStore = new MaintenanceModeModalStore(
      this,
      states.maintenanceModeModalStore,
      this.api,
      storage
    );

    this.additivesStore = new AdditivesStore(
      this,
      states.additivesStore as any
    );

    this.couponsStore = new CouponsStore(
      this,
      states.couponsStore,
      this.api,
      storage
    );

    this.reservationTableStore = new ReservationTableStore(
      this,
      states.reservationTableStore,
      this.api
    );

    this.feedbackStore = new FeedbackStore(
      this,
      states.feedbackStore,
      this.api
    );

    this.homepageStore = new HomePageStore(
      this,
      states.homepageStore,
      this.api
    );

    this.ingredientsSelectStore = new IngredientsSelectStore(
      this,
      states.ingredientsSelectStore,
      this.api
    );

    this.errorStore = new ErrorStore(this, states.errorStore, this.api);

    this.newsStore = new NewsStore(states.newsStore, this.api);

    this.analyticsStore = new AnalyticsStore(this, states.analyticsStore);

    this.seoDataStore = new SeoDataStore(this);

    this.thirdPartyServicesStore = new ThirdPartyServicesStore(this);

    this.favoritesStore = new FavoritesStore(this, this.api as any, storage);

    this.utmStore = new UtmStore();
  }
}

let store: RootStore | undefined;
// eslint-disable-next-line import/no-mutable-exports
let RootContext: Context<RootStore>;

const createStore = (initialStore?: Record<string, any>) => {
  store = new RootStore(initialStore);

  RootContext = createContext(store);

  return store;
};

export { RootContext, createStore, store };

export default RootStore;
