import { Modal } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import {
  fetchImprintContent,
  fetchPrivacyPolicyContent,
  fetchTermsContent
} from '../../../../api/queries';
import Metadata from '../../../../components/meta_data';
import credentials from '../../../../enums/credentials.enum';
import MetadataTypes from '../../../../enums/metadata.enums';
import states from '../../../../enums/states.enum';

import './TermOfUse.scss';
import ModalHeader from '../modals_components/header/ModalHeader';

import HeaderImprint from './imprint-header';
import LegalContent from './legal-content';

import './_TermOfUse_desktop.scss';

@withRouter
@inject('store')
@observer
class LegalInfoDesktop extends Component {
  constructor(props) {
    super(props);

    this.state = { content: '' };
  }

  componentDidMount() {
    this.loadPageContent();
  }

  loadPageContent = () => {
    const { pageName } = this.props;

    switch (pageName) {
      case 'imprint':
        this.loadImprint();

        break;
      case 'privacy-policy':
        this.loadPrivacy();

        break;
      default:
        this.loadTerms();
    }
  };

  loadImprint = async () => {
    const { store } = this.props;
    const { restaurantStore, themesStore, api } = store;
    const { branchId } = restaurantStore.branch;
    const { token } = api;
    const { isPortalWebThemeActive: isHunger } = themesStore;
    const { content } = await fetchImprintContent(token, branchId, isHunger);

    content && this.setState({ content });
  };

  loadPrivacy = async () => {
    const { store } = this.props;
    const { restaurantStore, themesStore, api } = store;
    const { branchId } = restaurantStore.branch;
    const { token } = api;
    const { isPortalWebThemeActive: isHunger } = themesStore;

    const { content } = await fetchPrivacyPolicyContent(
      token,
      branchId,
      isHunger
    );

    content && this.setState({ content });
  };

  loadTerms = async () => {
    const { store } = this.props;
    const { restaurantStore, themesStore, api } = store;
    const { branchId } = restaurantStore.branch;
    const { token } = api;
    const { isPortalWebThemeActive: isHunger } = themesStore;
    const { content } = await fetchTermsContent(token, branchId, isHunger);

    content && this.setState({ content });
  };

  render() {
    const { pageName, history, closeModal } = this.props;
    const page = pageName ?? history.location.pathname.split('/').pop();

    const metadataType =
      page === states.imprint
        ? MetadataTypes.IMPRINT_PAGE
        : page === states.privacyPolicy
        ? MetadataTypes.PRIVACY_POLICY_PAGE
        : MetadataTypes.TERMS_PAGE;

    const { restaurant } = this.props.store.restaurantStore;
    const { branch } = this.props.store.restaurantStore;

    const { logo } =
      page === states.imprint || page === states.termsOfUse
        ? this.props.store.restaurantStore.restaurant
        : {};

    const content = this.state.content.replace(/style=".+?"/g, '');

    const title =
      page === states.imprint
        ? this.props.t('footer:legalInfo')
        : page === states.privacyPolicy
        ? this.props.t('footer:dsvgoTitle')
        : this.props.t('footer:termsOfUse');

    const appsmartCredentials = branch.useAppsmartCredentials
      ? credentials.appsmart
      : null;

    const imprintProps =
      page === states.imprint
        ? {
            branch,
            restaurant:
              this.props.store.restaurantStore.onlyCustomerImprint ||
              this.props.store.restaurantStore.customerWithAppSmartImprint
                ? restaurant
                : null,
            appsmartCredentials:
              this.props.store.restaurantStore.onlyAppSmartImprint ||
              this.props.store.restaurantStore.customerWithAppSmartImprint
                ? appsmartCredentials
                : null,
            logo
          }
        : null;

    return (
      <>
        <Metadata metadataType={metadataType} />
        <ModalHeader
          cssClass="wide-modal-padding-header"
          closeAction={closeModal}
          title={title}
        />
        <div id="imprint" className="imprint-desktop">
          {logo ? (
            page === states.imprint ? (
              <HeaderImprint {...imprintProps} />
            ) : (
              <HeaderImprint
                branch={branch}
                restaurant={restaurant}
                logo={logo}
              />
            )
          ) : null}
          <LegalContent content={content} />
        </div>
      </>
    );
  }
}

export default withTranslation(['table_reservation', 'footer'])(
  LegalInfoDesktop
);
