import { matchPath } from 'react-router-dom';

import { routesForLoad } from '../client/routes/main';

export function matchRoute(url, host, withAggregator) {
  let route;
  let routeConfig = routesForLoad(host, withAggregator).find(
    ({ path }) => (route = matchPath(url, path))
  );

  if (
    route &&
    !url.match(/\.(css|js|map|jpg|png*?.+)|undefined|null|(favicon*?.+)$/)
  ) {
    return { params: route.params, key: routeConfig.key, url: url };
  }
  return {};
}
