export default class SessionStorage {
  static hermesIdentifier = 'HERMES';

  static getItem(key: string) {
    const value = window.sessionStorage.getItem(
      `${this.hermesIdentifier}_${key}`
    );

    return value ? JSON.parse(value) : undefined;
  }

  static setItem(key: string, value: any) {
    window.sessionStorage.setItem(
      `${this.hermesIdentifier}_${key}`,
      JSON.stringify(value)
    );
  }

  static removeItem(key: string) {
    window.sessionStorage.removeItem(`${this.hermesIdentifier}_${key}`);
  }

  static clear() {
    window.sessionStorage.clear();
  }
}
