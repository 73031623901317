import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import propTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';

import {IS_CLIENT} from 'config';

import states from '../../../../../enums/states.enum';
import { generateLinkFor } from '../../../../../utils/routing';

import './shellLayout.scss';

@withRouter
@inject('store')
@observer
export default class ShellLayout extends Component {
  static propTypes = {
    shellHeader: propTypes.object,
    shellContent: propTypes.object,
    shellFooter: propTypes.object,
    contentFooter: propTypes.object,
    style: propTypes.object
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.basketButtonRef = createRef();

    this.contentContainerRef = createRef();
  }

  componentDidMount() {
    this.setComponentCoordinates();

    IS_CLIENT &&
      window.addEventListener('resize', this.setComponentCoordinates);

    this.props.store.categoryMenuStore.setScrollableContainer(
      this.contentContainerRef
    );
  }

  componentDidUpdate(prevProps) {
    const rootRoutePath = generateLinkFor(states.app, this.props, null, true);

    const currentRoutePath = this.addSlashToPathname(
      this.props.location.pathname
    );

    const prevRoutePath = this.addSlashToPathname(prevProps.location.pathname);

    const legalPages = [
      `${rootRoutePath.pathname}${states.imprint}/`,
      `${rootRoutePath.pathname}${states.privacyPolicy}/`,
      `${rootRoutePath.pathname}${states.termsOfUse}/`
    ];

    if (currentRoutePath !== prevRoutePath) {
      if (legalPages.includes(currentRoutePath)) {
        this.contentContainerRef.current.scrollTo(0, 0);
      }
    } else if (
      this.props.store.themesStore.isShellCategoryHorizontalType &&
      currentRoutePath === `${rootRoutePath.pathname}${states.cat}/`
    ) {
      this.props.store.categoryMenuStore.scrollContainerElement();
    }
  }

  componentWillUnmount() {
    IS_CLIENT &&
      window.removeEventListener('resize', this.setComponentCoordinates);
  }

  addSlashToPathname = (pathname) =>
    pathname.match(/\/$/gim) ? pathname : `${pathname}/`;

  setComponentCoordinates = () => {
    if (this.basketButtonRef.current) {
      const bounds = this.basketButtonRef.current.getBoundingClientRect();

      this.props.store.basketStore.updateBounds({
        id: states.basket,
        type: states.basket,
        bounds: {
          top: bounds.top + pageYOffset,
          left: bounds.left + pageXOffset,
          width: bounds.width,
          height: bounds.height
        }
      });
    }
  };

  /**
   * Method to render shell header
   * top part of the screen, has sticky behavior - stick to the top
   * @returns {html} - shell header html node
   * @private
   */
  _renderShellHeader = () =>
    this.props.shellHeader ? (
      <div className="shell-header">{this.props.shellHeader}</div>
    ) : null;

  /**
   * Method to render shell footer
   * bottom part of the screen, placed below content part and shrink it
   * @returns {html} - shell header html node
   * @private
   */
  _renderShellFooter = () =>
    this.props.shellFooter ? (
      <div className="shell-footer">{this.props.shellFooter}</div>
    ) : null;

  /**
   * Method to render content footer
   * bottom part of the content part, has sticky behavior - stick to the bottom
   * @returns {html} - shell header html node
   * @private
   */
  _renderContentFooter = () => {
    const {
      hideBasketButtonHorizontalMenuShell
    } = this.props.store.themesStore;

    const rootRoutePath = generateLinkFor(states.app, this.props, null, true);

    const isCategoryPage =
      this.addSlashToPathname(this.props.location.pathname) ===
      `${rootRoutePath.pathname}${states.cat}/`;

    return this.props.contentFooter ? (
      <div
        className={classNames({
          'content-footer': true,
          'bounds-out': hideBasketButtonHorizontalMenuShell && isCategoryPage
        })}
        ref={this.basketButtonRef}
      >
        {this.props.contentFooter}
      </div>
    ) : null;
  };

  /**
   * Method to render shell content
   * central part of the screen which scale to screen size
   * @returns {html} - shell header html node
   * @private
   */
  _renderContentMain = () =>
    this.props.shellContent ? (
      <div
        className="content-wrapper"
        id="shell-content-wrapper"
        ref={this.contentContainerRef}
      >
        {this.props.shellContent}
      </div>
    ) : null;

  render() {
    return (
      <div className="shell-layout" style={this.props.style}>
        {this._renderShellHeader()}
        <div className="shell-content">
          {this._renderContentMain()}
          {this._renderContentFooter()}
        </div>
        {this._renderShellFooter()}
      </div>
    );
  }
}
