import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import dayjs from 'dayjs';

import { Modal } from 'antd';

// CSS
import './_closed-modal.scss';

//Components
import ButtonStandard from '../../buttons/ButtonStandard';
import FooterShellButton from '../../../theme-hermes-shell/components/buttons-shell/footer-button';

@inject('store')
@observer
class ClosedModal extends Component {
  constructor(props) {
    super(props);
    const {
      closedForDelivery,
      closedForPickUp,
      secondsUntilOpenDelivery,
      secondsUntilOpenPickUp
    } = this.props.store.restaurantStore.branch;
    const deliverySeconds = closedForDelivery ? secondsUntilOpenDelivery : 0;
    const selfcollectSeconds = closedForPickUp ? secondsUntilOpenPickUp : 0;
    this.state = {
      deliverySeconds: dayjs.duration(deliverySeconds, 'seconds'),
      selfcollectSeconds: dayjs.duration(selfcollectSeconds, 'seconds')
    };
  }

  componentDidMount() {
    this.props.show && this.setupInterval();
  }

  componentDidUpdate() {
    this.props.show && this.setupInterval();
  }

  setupInterval = () => {
    if (!this.timeout) {
      this.timeout = setInterval(() => {
        const d = this.state.deliverySeconds;
        const s = this.state.selfcollectSeconds;
        if (d === 0 && this.state.selfcollectSeconds === 0) {
          return document.location.reload();
        }
        this.setState({
          deliverySeconds:
            d.days() > 0
              ? d
              : d.seconds() || d.minutes() || d.hours()
              ? d.clone().subtract(1, 'second')
              : d,
          selfcollectSeconds:
            s.days() > 0
              ? s
              : s.seconds() || s.minutes() || s.hours()
              ? s.clone().subtract(1, 'second')
              : s
        });
      }, 1000);
    }
  };

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  /**
   * Action on abort button click
   * @private
   */
  _abortAction = () => {
    this.props.store.restaurantStore.toggleClosedModal(false);
    clearInterval(this.timeout);
  };

  /**
   * Method to create closing message
   * @returns {string}
   * @private
   */
  _renderClosedMessage() {
    const {
      closedForDelivery,
      closedForPickUp
    } = this.props.store.restaurantStore.branch;
    if (closedForDelivery && closedForPickUp) {
      return this.props.t('header');
    }
    if (closedForDelivery) {
      return this.props.t('closedForDelivery');
    }
    if (closedForPickUp) {
      return this.props.t('closedForSelfcollect');
    }
  }

  /**
   * Method to calculate work time duration
   * @param {number} duration - time in seconds to open
   * @param {string} closedUntilMessage - closed until translations
   * @param {string} willOpen - will open translations
   * @param {string} willOpenTomorrow - will open tomorrow translations
   * @returns {string}
   * @private
   */
  _renderDuration(duration, closedUntilMessage, willOpen, willOpenTomorrow) {
    const { restaurantTime } = this.props.store.restaurantStore;
    const hours = duration.hours();
    const minutes = duration.minutes();
    const days = duration.days();
    const timeToOpen = restaurantTime.clone().add(duration);
    const openToday = timeToOpen.weekday() === restaurantTime.weekday();
    const hoursToOpen = days * 24 + hours;
    if (hoursToOpen > 24) {
      return `${this.props.t(closedUntilMessage, {
        date: timeToOpen.format('DD.MM')
      })} `;
    }
    if (days === 0 && openToday) {
      return `${this.props.t(willOpen, {
        time:
          (hours ? `${this.props.t('hours', { hours: hours })} ` : '') +
          (minutes ? `${this.props.t('minutes', { minutes: minutes })}` : '')
      })} `;
    }
    if (!openToday) {
      return `${this.props.t(willOpenTomorrow)}`;
    }
  }

  /**
   * Method to render countdown time to open
   * @deprecated
   * @returns {*}
   * @private
   */
  _renderTimeToOpen() {
    const {
      isPickupAvailable,
      isDeliveryAvailable
    } = this.props.store.openingHoursStore;
    const durationDelivery = this.state.deliverySeconds;
    const durationSelfcollect = this.state.selfcollectSeconds;
    const {
      closedForDelivery,
      closedForPickUp
    } = this.props.store.restaurantStore.branch;
    const { branchHasPreorder } = this.props.store.restaurantStore.branch;
    if (closedForDelivery && closedForPickUp) {
      if (
        (durationDelivery <= durationSelfcollect || !isPickupAvailable) &&
        isDeliveryAvailable
      ) {
        return (
          <React.Fragment>
            {this._renderDuration(
              durationDelivery,
              'weAreClosedUntil',
              'willOpenToday',
              'weWillBeOpenedTomorrow'
            )}
            {!!branchHasPreorder && this.props.t('preorderAvailable')}
          </React.Fragment>
        );
      } else if (isPickupAvailable) {
        return (
          <React.Fragment>
            {this._renderDuration(
              durationSelfcollect,
              'weAreClosedUntil',
              'willOpenToday',
              'weWillBeOpenedTomorrow'
            )}
            {!!branchHasPreorder && this.props.t('preorderAvailable')}
          </React.Fragment>
        );
      }
    }
    if (closedForDelivery) {
      return (
        <React.Fragment>
          {this._renderDuration(
            durationDelivery,
            'deliveryWillOpen',
            'deliveryAvailableIn',
            'deliveryTomorrow'
          )}
          {!!branchHasPreorder && `${this.props.t('preorderAvailable')} `}
          {this.props.t('collectionOpened')}
        </React.Fragment>
      );
    }
    if (closedForPickUp) {
      return (
        <React.Fragment>
          {this._renderDuration(
            durationSelfcollect,
            'selfcollectWillOpen',
            'selfcollectAvailableIn',
            'selfcollectTomorrow'
          )}
          {!!branchHasPreorder && `${this.props.t('preorderAvailable')} `}
          {this.props.t('deliveryOpened')}
        </React.Fragment>
      );
    }
    return null;
  }

  /**
   * Conditional rendering preorder countdown relative delivery and pickup available
   * @returns {*}
   * @private
   */
  _rendePreorderMessage() {
    const {
      isPickupAvailable,
      isDeliveryAvailable
    } = this.props.store.openingHoursStore;
    const durationDelivery = this.state.deliverySeconds;
    const durationSelfcollect = this.state.selfcollectSeconds;
    const {
      closedForDelivery,
      closedForPickUp
    } = this.props.store.restaurantStore.branch;
    if (closedForDelivery && closedForPickUp) {
      if (
        (durationDelivery <= durationSelfcollect || !isPickupAvailable) &&
        isDeliveryAvailable
      ) {
        return this._renderDuration(
          durationDelivery,
          'weAreClosedUntil',
          'willOpenToday',
          'weWillBeOpenedTomorrow'
        );
      } else if (isPickupAvailable) {
        return this._renderDuration(
          durationSelfcollect,
          'weAreClosedUntil',
          'willOpenToday',
          'weWillBeOpenedTomorrow'
        );
      }
    }
    if (closedForDelivery) {
      return this._renderDuration(
        durationDelivery,
        'deliveryWillOpen',
        'preorderForDelivery',
        'deliveryTomorrow'
      );
    }
    if (closedForPickUp) {
      return this._renderDuration(
        durationSelfcollect,
        'selfcollectWillOpen',
        'preorderForPickup',
        'selfcollectTomorrow'
      );
    }
    return null;
  }

  /**
   * Method for rendering closed message
   * @returns {*}
   * @private
   */
  _renderClosedMessageDescription() {
    const {
      isPreorderAvailable,
      isPreorderAlways
    } = this.props.store.restaurantStore;
    if (!isPreorderAlways && !isPreorderAvailable) {
      return this._rendePreorderMessage();
    } else if (isPreorderAlways) {
      return this.props.t('preorderAlways');
    } else if (isPreorderAvailable) {
      return this._rendePreorderMessage();
    }
  }

  /**
   * Method to render close modal
   * @param {boolean} isShellThemeActive - is shell active now
   * @returns {*}
   * @private
   */
  _renderClosedModal = (isShellThemeActive) => {
    return (
      <React.Fragment>
        <div className="title">{this._renderClosedMessage()}</div>
        <div className="description">
          <React.Fragment>
            {this.props.t('modalErrorClosed')}
            &nbsp;
            {this._renderClosedMessageDescription()}
          </React.Fragment>
        </div>
        {this._renderModalFooter(isShellThemeActive)}
      </React.Fragment>
    );
  };

  /**
   * Method to render holiday modal
   * @param {string} title - title of modal
   * @param {string} description - description message
   * @param {string} image - image url
   * @param {boolean} isShellThemeActive - is shell active now
   * @returns {*}
   * @private
   */
  _renderHolidayModal = (title, description, image, isShellThemeActive) => {
    return (
      <React.Fragment>
        {image && <img className="image" src={image} />}
        {title && <div className="title">{title}</div>}
        {description && <div className="description">{description}</div>}
        {this._renderModalFooter(isShellThemeActive)}
      </React.Fragment>
    );
  };

  /**
   * Method to render footer button
   * @param {boolean} isShellThemeActive - is shell active now
   * @returns {*}
   * @private
   */
  _renderModalFooter = (isShellThemeActive) => {
    return isShellThemeActive ? (
      <div className="modal-footer-wrapper">
        <FooterShellButton
          label={this.props.t('viewMenu')}
          colored={true}
          smallText={true}
          action={this._abortAction}
          buttonOrderStyle={this.props.store.themesStore.orderButtonStyle()}
          buttonStandardStyle={this.props.store.themesStore.standardButtonStyle()}
        />
      </div>
    ) : (
      <div className="modal-footer-wrapper">
        <ButtonStandard
          onClick={this._abortAction}
          label={this.props.t('viewMenu')}
        />
      </div>
    );
  };

  render() {
    const {
      isHolidayModeActive,
      holidayModeTitleText,
      holidayModeDescription,
      holidayModePicture
    } = this.props.store.restaurantStore.branch;
    const { isShellThemeActive } = this.props.store.themesStore;
    return (
      <Modal
        visible={this.props.show}
        closable={false}
        footer={null}
        width={635}
        centered={true}
      >
        <div id="store-is-closed-state">
          {isHolidayModeActive
            ? this._renderHolidayModal(
                holidayModeTitleText,
                holidayModeDescription,
                holidayModePicture,
                isShellThemeActive
              )
            : this._renderClosedModal(isShellThemeActive)}
        </div>
      </Modal>
    );
  }
}

export default withTranslation(['store_is_closed_modal'])(ClosedModal);
