import { AggregatorButtonModel } from './aggregator_button.model';
/**
 * Aggregator model
 * @constructor
 * @property {uuid} id - Unique id of agregator.
 * @property {string} title - Aggregator title.
 * @property {string} logo - Aggregator logo url
 * @property {string} description - Aggregator description
 * @property {array} buttons - Buttons of aggregator restaurants
 */

export class AggregatorModel {
  public id: number;

  public title: string;

  public isAggregator: boolean;

  public logo: string;

  public description: string;

  public buttons: AggregatorButtonModel[];

  constructor(aggregatorResponse: any) {
    Object.assign(this, aggregatorResponse);

    this.id = parseInt(aggregatorResponse.id, 10);

    this.title = aggregatorResponse.title || '';

    this.logo = aggregatorResponse.logo || '';

    this.description = aggregatorResponse.description || '';

    this.buttons = aggregatorResponse.buttons || [];

    this.isAggregator = false;
  }

  /**
   * Method for determination aggregator
   * @return {boolean}
   */
  get getAggregatorMode() {
    return this.isAggregator;
  }

  /**
   * Method for getting aggregator id
   * @return {string}
   */
  get getId() {
    return this.id;
  }

  /**
   * Method for getting aggregator title
   * @return {string}
   */
  get getTitle() {
    return this.title;
  }

  /**
   * Method for getting aggregator logo url
   * @return {string}
   */
  get getLogo() {
    return this.logo;
  }

  /**
   * Method for getting aggregator description
   * @return {string}
   */
  get getDescription() {
    return this.description;
  }

  /**
   * Method for getting count of aggregator buttons
   * @return {number}
   */
  get getButtonsLength() {
    return this.buttons.length;
  }

  /**
   * Method for getting aggregator buttons
   * @return {array}
   */
  get getButtons() {
    return this.buttons;
  }
}
