import React, { useEffect, useRef } from 'react';

import { ICategoryHeader } from './props.interface';

import './CategoryHeader.scss';

const CategoryHeader = (props: ICategoryHeader) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    containerRef.current &&
      props.setSize &&
      props.setSize(containerRef.current.getBoundingClientRect().height);
  }, []);

  return (
    <div
      ref={containerRef}
      className="category-header-container"
      style={props.style ? props.style.containerStyle : {}}
    >
      <div
        className="header-wrapper"
        style={props.style ? props.style.headerStyle : {}}
        data-testid="product-title"
      >
        {props.headerText}
      </div>
      {props.description && (
        <div className="description-wrapper">{props.description}</div>
      )}
    </div>
  );
};

export default CategoryHeader;
