import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IDay } from 'client/models/day.model';
import RootStore from 'client/stores';

import OpeningTimesCell from '../../../components/opening_hours/mobile/opening-times-cell';
import HeaderShell from '../header-shell';
import ShellLayout from '../shell-layout';

import './OpeningTimes.scss';

@(withTranslation(['opening_hours', 'enter_address_modal']) as any)
@(withRouter as any)
@inject('store')
@observer
/**
 * Component to render list of work's times shell
 */
class OpeningTimesShell extends Component<
  { store: RootStore } & RouteComponentProps & WithTranslation
> {
  /**
   * Method for back action of routing
   */
  backAction = () => {
    const {
      history: { goBack }
    } = this.props;

    goBack();
  };

  renderHeader = () => {
    const {
      t,
      store: {
        themesStore: { headerStyle, isPortalAppThemeActive },
        restaurantStore: {
          branch: { branchName }
        }
      }
    } = this.props;

    return (
      <HeaderShell
        title={t('opening_hours:lblWorkingHours')}
        style={headerStyle()}
        backAction={this.backAction}
        isPortalAppThemeActive={isPortalAppThemeActive}
        branchName={branchName}
      />
    );
  };

  /**
   * Render list of days
   */
  renderContent = (
    pickupDays: IDay[],
    deliveryDays: IDay[],
    todayWeekDay: number
  ) => {
    const {
      store: {
        deliveryAddressStore: { hasDelivery, hasPickup },
        themesStore: { headerStyle }
      }
    } = this.props;

    return (
      <div id="opening-times-shell">
        <div className="opening-times-list">
          {pickupDays.length === deliveryDays.length &&
            pickupDays.map((pickupTimes, index) => (
              <OpeningTimesCell
                key={pickupTimes.day}
                pickupTimes={pickupTimes}
                deliveryTimes={deliveryDays[index]}
                pickupAvailable={hasPickup}
                deliveryAvailable={hasDelivery}
                todayWeekDay={todayWeekDay}
                todayStyle={headerStyle()}
              />
            ))}
        </div>
      </div>
    );
  };

  render() {
    const {
      store: {
        openingHoursStore: {
          pickupDaysSorted,
          deliveryDaysSorted,
          todayWeekDay
        }
      }
    } = this.props;

    return (
      <ShellLayout
        shellHeader={this.renderHeader()}
        shellContent={this.renderContent(
          pickupDaysSorted,
          deliveryDaysSorted,
          todayWeekDay
        )}
      />
    );
  }
}

export default OpeningTimesShell;
