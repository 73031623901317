export class LocalStorage {
  static saveToStorage(key: string, data: any) {
    return window.localStorage.setItem(key, JSON.stringify(data));
  }

  static loadFromStorage(key: string) {
    const data = window.localStorage.getItem(key);

    return data ? JSON.parse(data) : null;
  }

  static deleteFromStorage(key: string) {
    return window.localStorage.removeItem(key);
  }
}
