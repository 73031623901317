import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';

// CSS
import './footer.scss';

/**
 * Footer component is used to show company information,
 * ordersmart logo and links to download android and ios applications.
 * @name ReactFooterComponent
 * @property {object} settings - settings object with info about branch and links to phone applications.
 */
@inject('store')
@observer
class MobileFooter extends Component {
  static propTypes = {
    restaurant: PropTypes.object.isRequired,
    branch: PropTypes.object.isRequired,
    isTableReservationAvailable: PropTypes.bool,
    isStartPage: PropTypes.bool
  };

  static defaultProps = {
    isTableReservationAvailable: false,
    isStartPage: false,
    branch: {}
  };

  /**
   * Method for render address block
   * @param {string} title - block title
   * @param {BranchModel} branch - current branch
   * @param {string} countryPrefix - country code
   * @returns {*}
   */
  _renderAddressBlock = (title, branch, countryPrefix) => {
    return (
      <ul className="item">
        <li>{title}</li>
        {branch.getStreet && branch.getStreetNo && (
          <li>{`${branch.getStreet} ${branch.getStreetNo}`}</li>
        )}
        {countryPrefix && branch.getCityCode && branch.getCity && (
          <li>{`${countryPrefix}-${branch.getCityCode} ${branch.getCity}`}</li>
        )}
      </ul>
    );
  };

  /**
   * Method for render contact block
   * @param {BranchModel} branch - current branch
   * @returns {*}
   */
  _renderContactsBlock = (branch) => {
    return (
      <ul className="item">
        {branch.getOwnername && !branch.isHideOwnerInfoFromFooter && (
          <li>
            {this.props.t('owner')} {branch.getOwnername}
          </li>
        )}
        {branch.getShopmanagerName &&
          branch.getShopmanagerName !== branch.getOwnername &&
          !branch.isHideOwnerInfoFromFooter && (
            <li>
              {this.props.t('managing', { name: branch.getShopmanagerName })}
            </li>
          )}
        {branch.getVatin && !branch.isHideRestaurantInfoFromFooter && (
          <li>
            {' '}
            {this.props.t('taxNumber', {
              tax: branch.getVatin,
              interpolation: { escapeValue: false }
            })}{' '}
          </li>
        )}
        {branch.getRegistrationNumber &&
          !branch.isHideRestaurantInfoFromFooter && (
            <li>
              {this.props.t('registrationNumber', {
                number: branch.getRegistrationNumber
              })}
            </li>
          )}
      </ul>
    );
  };

  /**
   * Method to render information about delivery service
   * @param {string} restaurantName - restaurant name
   * @param {BranchModel} branch - branch item
   * @param {string} areaCode - postal code
   */
  renderDeliveryServiceInfo = (restaurantName, branch, areaCode) => {
    return (
      <ul className="item">
        <div className="title">
          {this.props.t('delivery_info:deliveryService')}
        </div>
        {restaurantName && <li>{restaurantName}</li>}
        {branch.getStreet && branch.getStreetNo && (
          <li>{`${branch.getStreet} ${branch.getStreetNo}`}</li>
        )}
        {areaCode && branch.getCity && (
          <li>{`${areaCode} ${branch.getCity}`}</li>
        )}
      </ul>
    );
  };

  render() {
    const { restaurant, t, branch } = this.props;
    const branchName =
      branch.companyNameImprint || branch.branchName || restaurant.name;
    let contactsContent = null;
    const {
      onlyCustomerImprint,
      customerWithAppSmartImprint
    } = this.props.store.restaurantStore;
    if (onlyCustomerImprint) {
      contactsContent = (
        <Fragment>
          <div className="title">{t('legalInfo')}</div>
          <div className="block">
            {!branch.isHideRestaurantInfoFromFooter &&
              this._renderAddressBlock(
                branchName,
                branch,
                restaurant.countryPrefix
              )}
          </div>
          <div className="block">{this._renderContactsBlock(branch)}</div>
        </Fragment>
      );
    } else if (customerWithAppSmartImprint) {
      contactsContent = this.renderDeliveryServiceInfo(
        branchName,
        branch,
        branch.getCityCode
      );
    }

    return (
      <footer id="footer" className={'mobile-footer'}>
        {contactsContent}
      </footer>
    );
  }
}
export default withTranslation(['footer'])(MobileFooter);
