import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { PAYMENT_METHODS } from 'client/enums/payment_methods.enum';
import { OfferModel } from 'client/models/offer.model';
import { ProductModel } from 'client/models/product.model';
import RootStore from 'client/stores';
import { IS_CLIENT } from 'config';

import CREDENTIALS from '../../../../../enums/credentials.enum';
import states from '../../../../../enums/states.enum';
import {
  cantAddProductsToBasket,
  generateLinkFor
} from '../../../../../utils/routing';
import CheckoutDeliveryTypeTitle from '../../../components/checkout/common/checkout-delivery-type-title';
import OrderError from '../../../components/checkout/common/error/OrderError';
import MarketingCheckbox from '../../../components/checkout/common/marketing-checkbox';
import OrderPending from '../../../components/checkout/common/pending/order-pending';
import PickupInfoMessage from '../../../components/checkout/common/pickup-info-message';
import OrderSuccess from '../../../components/checkout/common/success/OrderSuccess';
import CheckoutTermsLink from '../../../components/checkout/common/terms-link';
import PaymentMethodsSelect from '../../../components/payment_methods/select-methods/PaymentMethodsSelect';
import BasketShellInfo from '../basket-shell/pure/basket-info';
import BasketShellWithProducts from '../basket-shell/pure/basket-with-products';
import HeaderShell from '../header-shell';
import ModalFooterButtonsShell from '../modal-footer';
import ShellLayout from '../shell-layout';

import './styles.scss';

type ContactDataType = {
  restaurantInfo: {
    name: string | undefined;
    address: string;
    city: string;
    phoneNumber: string | undefined;
  };
  appSmartInfo: {
    name: any;
    address: string;
    city: string;
    phoneNumber: any;
  };
} | null;

type State = {
  paypalExperimentId: number | null;
};

type Props = {
  store: RootStore;
} & RouteComponentProps &
  WithTranslation;
/**
 * Order payment methods component is used to show form that provides payment methods and ability to choose one of them
 */
@(withTranslation([
  'common',
  'order_checkout',
  'enter_address_modal',
  'order_payment_methods'
]) as any)
@(withRouter as any)
@inject('store')
@observer
class CheckoutShell extends Component<Props, State> {
  state = {
    paypalExperimentId: null
  };

  componentDidUpdate(_: Props, prevState: State) {
    const {
      store: {
        basketStore: { hasProducts, isMinValueReached },
        deliveryAddressStore: {
          isDelivery,
          isFullAddressEntered,
          isSimplePickUpAddressEntered,
          isLoadAddressFromStorage
        },
        restaurantStore: { isLocationChosen, branch },
        orderPaymentMethodsStore: {
          isOrderSent,
          isError,
          pendingPayPalPaymentStatus,
          isPaymentProcessing,
          orderInProcess,
          activePaymentMethod
        }
      },
      history
    } = this.props;

    if (prevState.paypalExperimentId !== this.state.paypalExperimentId) {
      const { PAYPAL_ID, PAYPAL_V2_ID } = PAYMENT_METHODS;

      if (
        (activePaymentMethod?.id === PAYPAL_ID ||
          activePaymentMethod?.id === PAYPAL_V2_ID) &&
        activePaymentMethod?.id !== this.state.paypalExperimentId
      ) {
        this._setActivePaymentMethod(this.state.paypalExperimentId!);
      }
    }

    if (
      (((hasProducts && !isMinValueReached) ||
        !hasProducts ||
        (isDelivery && !isLocationChosen)) &&
        !isOrderSent &&
        !isError &&
        !(
          orderInProcess ||
          isPaymentProcessing ||
          pendingPayPalPaymentStatus
        )) ||
      (!branch.isSimplePickUpForm &&
        !isFullAddressEntered &&
        isLoadAddressFromStorage &&
        !branch.isSimplePickUpForm &&
        !isSimplePickUpAddressEntered &&
        isLoadAddressFromStorage)
    ) {
      return history.push(generateLinkFor(states.basket, this.props, {}, true));
    }
  }

  componentWillUnmount() {
    this.props.store.orderPaymentMethodsStore.clearTimer();
  }

  /**
   * Action on place order click
   */
  _placeOrderAction = () => {
    this.props.store.orderPaymentMethodsStore.handleOrder();
  };

  /**
   * Action on success order component mount
   */
  _successComponentMountAction = () => {
    const {
      deliveryAddressStore,
      analyticsStore,
      basketStore
    } = this.props.store;

    deliveryAddressStore.loadOrderTypeFromStorage(true);

    analyticsStore.sendSuccessfulPayment();

    basketStore.resetBasket();
  };

  /**
   * Action on error order component mount
   */
  _errorComponentMountAction = () => {
    const {
      deliveryAddressStore,
      analyticsStore,
      restaurantStore: {
        restaurant: { title },
        branch: { branchId }
      },
      basketStore,
      orderPaymentMethodsStore: { activePaymentMethod, isCanceledOrder }
    } = this.props.store;

    deliveryAddressStore.loadOrderTypeFromStorage(true);

    !isCanceledOrder && analyticsStore.sendFailedPayment();
  };

  /**
   * Action on back button
   */
  _backAction = () => {
    const {
      store: {
        orderPaymentMethodsStore: { isOrderSent, isError },
        orderPaymentMethodsStore,
        offerStore
      },
      history,
      location
    } = this.props;

    if (isOrderSent && !isError) {
      history.push(
        generateLinkFor(
          states.app,
          this.props,
          { success: null, checkTransaction: null, paymentCanceled: null },
          true
        )
      );
    } else {
      const linkObject = generateLinkFor(
        states.address,
        this.props,
        { success: null, checkTransaction: null, paymentCanceled: null },
        true
      );

      if (location.prevPath && linkObject.pathname === location.prevPath) {
        history.goBack();
      } else {
        history.push(linkObject);
      }
    }

    orderPaymentMethodsStore.resetOrderPaymentInformation();

    orderPaymentMethodsStore.clearOrderInfo();

    offerStore.isFreeItemModalShowedBeforeOrder = false;
  };

  /**
   * Action on back to backet
   */
  _backToBasketAction = () => {
    const {
      store: {
        orderPaymentMethodsStore: { isOrderSent, isError },
        offerStore,
        orderPaymentMethodsStore
      },
      history
    } = this.props;

    if (isOrderSent && !isError) {
      history.push(
        generateLinkFor(states.app, this.props, { success: null }, true)
      );

      orderPaymentMethodsStore.resetOrderPaymentInformation();
    } else {
      history.push(
        generateLinkFor(states.basket, this.props, { success: null }, true)
      );
    }

    orderPaymentMethodsStore.clearOrderInfo();

    offerStore.isFreeItemModalShowedBeforeOrder = false;
  };

  /**
   * Action to set active payment method
   * @param id - id of active payment method
   */
  _setActivePaymentMethod = (id: number) => {
    this.props.store.orderPaymentMethodsStore.setActivePaymentMethod(id, true);
  };

  /**
   * Action on changing marketing checkbox state
   */
  _marketingCheckboxAction = () => {
    const {
      orderPaymentMethodsStore: { allowReceiveMarketingInfo },
      orderPaymentMethodsStore
    } = this.props.store;

    orderPaymentMethodsStore.setMarketingCheckbox(!allowReceiveMarketingInfo);
  };

  /**
   * Method to render footer buttons
   */
  _renderCheckoutFooterButtons = () => {
    const {
      store: {
        basketStore: { finalPriceWithOffers, checkoutUnavailableToOrder },
        basketStore,
        themesStore,
        orderPaymentMethodsStore: { activePaymentMethod }
      },
      t
    } = this.props;

    const { paypalExperimentId } = this.state;

    /* const isOrderButtonDisabled =
      checkoutUnavailableToOrder ||
      !activePaymentMethod ||
      (activePaymentMethod?.id === PAYMENT_METHODS.PAYPAL_ID ||
      activePaymentMethod?.id === PAYMENT_METHODS.PAYPAL_V2_ID
        ? activePaymentMethod.id !== paypalExperimentId
        : false); */
    const disableOrderButton =
      checkoutUnavailableToOrder || !activePaymentMethod;

    return (
      <ModalFooterButtonsShell
        nextButtonLabel={this.props.t(
          'order_payment_methods:orderPriceWithPrice',
          {
            price: basketStore.getIntlPrice(finalPriceWithOffers)
          }
        )}
        nextButtonAction={this._placeOrderAction}
        isDisabledNextButton={disableOrderButton}
        backButtonLabel={t('common:cmnBack')}
        backButtonAction={this._backAction}
        orderButtonStyle={themesStore.orderButtonStyle()}
        buttonStandardStyle={themesStore.standardButtonStyle()}
        dataTestIdConfirmButton="basket-order-btn"
      />
    );
  };

  /**
   * Method to render error payment view
   */
  _renderErrorPayment = (contactData: ContactDataType) => {
    const {
      store: {
        orderPaymentMethodsStore: {
          paymentStatus: { shortDesc, statusDesc, fullDesc }
        },
        themesStore
      },
      t
    } = this.props;

    const mainHeader = shortDesc || t('common:failed');

    const contentHeader =
      statusDesc || t('order_checkout:paymentTransactionFailed');

    const contentDescription =
      fullDesc || t('order_checkout:paymentProcessCouldntBeCompleted');

    return (
      <div id="checkout-wrapper" className="checkout-shell">
        <OrderError
          key={1}
          backAction={this._backToBasketAction}
          errorComponentMountAction={this._errorComponentMountAction}
          isShellThemeActive
          orderButtonStyle={themesStore.orderButtonStyle()}
          buttonStandardStyle={themesStore.standardButtonStyle()}
          wrapperStyle={themesStore.wrapperStyle()}
          mainHeader={mainHeader}
          contentHeader={contentHeader}
          contentDescription={contentDescription}
          contactData={contactData}
          style={themesStore.restaurantStatus().negative}
        />
      </div>
    );
  };

  /**
   * Method to render success payment view
   */
  _renderSuccessPayment = (contactData: ContactDataType) => {
    const {
      store: {
        orderPaymentMethodsStore: {
          paymentStatus: { shortDesc, statusDesc, fullDesc }
        },
        themesStore,
        deliveryAddressStore: {
          address: { email }
        }
      },
      t
    } = this.props;

    const mainHeader = shortDesc || `${t('order_payment_methods:thankYou')}!`;

    const contentHeader =
      statusDesc || t('order_payment_methods:yourOrderWasSuccessful');

    const contentDescription =
      fullDesc ||
      t('order_payment_methods:orderHasBeenSent', {
        email
      });

    return (
      <div id="checkout-wrapper" className="checkout-shell">
        <OrderSuccess
          key={1}
          backAction={this._backAction}
          successComponentMountAction={this._successComponentMountAction}
          isShellThemeActive
          mainHeader={mainHeader}
          contentHeader={contentHeader}
          contentDescription={contentDescription}
          contactData={contactData}
          style={themesStore.restaurantStatus().positive}
          wrapperStyle={themesStore.wrapperStyle()}
        />
      </div>
    );
  };

  /**
   * Method to render pending panel
   */
  renderPendingPanel = () => {
    const {
      t,
      store: {
        themesStore,
        restaurantStore: { restaurant: loadingAnimation }
      }
    } = this.props;

    return (
      <div id="checkout-wrapper" className="checkout-shell">
        <OrderPending
          loadingAnimation={loadingAnimation}
          mainHeader={t('order_checkout:payment_processing')}
          contentHeader={t('order_payment_methods:pleaseWait')}
          contentDescription={t('order_checkout:please_be_patient')}
          style={themesStore.headerStyle()}
        />
      </div>
    );
  };

  getPaypalExperimentId = (id: number) => {
    this.setState({ paypalExperimentId: id });
  };

  /**
   * Method to render payment selector
   */
  _renderPaymentSelector = () => {
    const {
      store: {
        orderPaymentMethodsStore: {
          PAYPAL_ID,
          isContactLessCheckboxChecked,
          isShowContactLessMessage,
          paymentMethodsFilteredByOrderAmount
        },
        restaurantStore: {
          restaurant: { isPaypalUsed }
        },
        basketStore: { finalPriceWithOffers },
        themesStore
      },
      t
    } = this.props;

    const payPalInfo = {
      id: PAYPAL_ID,
      value: !!isPaypalUsed
    };

    const isContactLessActive =
      isContactLessCheckboxChecked && isShowContactLessMessage;

    return (
      <PaymentMethodsSelect
        title={t('order_payment_methods:choosePaymentMethod')}
        methods={paymentMethodsFilteredByOrderAmount}
        setActivePaymentMethod={this._setActivePaymentMethod}
        finalPrice={finalPriceWithOffers}
        paymentMethodStyle={themesStore.paymentMethodStyle()}
        payPalInfo={payPalInfo}
        isDisableCashMethods={!!isContactLessActive}
        getPaypalExperimentId={this.getPaypalExperimentId}
      />
    );
  };

  /**
   * Method to render payment form
   */
  _renderPaymentData() {
    const {
      store: {
        restaurantStore: {
          restaurant: { isHideMarketingData, getTitle, countryPrefix },
          branch: {
            isHideMarketingCheckbox,
            companyNameImprint,
            branchName,
            getStreet,
            getStreetNo,
            getCityCode,
            getCity,
            getWebsitePhone
          }
        },
        orderPaymentMethodsStore: {
          paymentStatus: { contactData: contactDataStore },
          allowReceiveMarketingInfo,
          orderInProcess,
          isError,
          isOrderSent,
          isPaymentProcessing,
          pendingPayPalPaymentStatus
        },
        deliveryAddressStore: { isDelivery, nearestPickupTime }
      }
    } = this.props;

    const {
      branch: {
        branchName: branchNameCredentials,
        getStreet: getStreetCredentials,
        getStreetNo: getStreetNoCredentials,
        getCityCode: getCityCodeCredentials,
        getCity: getCityCredentials,
        getWebsitePhone: getWebsitePhoneCredentials
      },
      restaurant: {
        getCountryPrefix: getCountryPrefixCredentials,
        getOwneremail: getOwneremailCredentials
      }
    } = CREDENTIALS.appsmart;

    const contactData = contactDataStore
      ? {
          restaurantInfo: {
            name: companyNameImprint || branchName || getTitle,
            address: `${getStreet} ${getStreetNo}`,
            city: `${countryPrefix}-${getCityCode} ${getCity}`,
            phoneNumber: getWebsitePhone
          },
          appSmartInfo: {
            name: branchNameCredentials,
            address: `${getStreetCredentials} ${getStreetNoCredentials}`,
            city: `${getCountryPrefixCredentials}-${getCityCodeCredentials} ${getCityCredentials}`,
            phoneNumber: getWebsitePhoneCredentials,
            email: getOwneremailCredentials
          }
        }
      : null;

    if (isError) {
      return this._renderErrorPayment(contactData);
    }

    if (isOrderSent) {
      return this._renderSuccessPayment(contactData);
    }

    if (orderInProcess || isPaymentProcessing || pendingPayPalPaymentStatus) {
      return this.renderPendingPanel();
    }

    return (
      <div id="order-payment-methods" className="checkout-shell">
        <CheckoutDeliveryTypeTitle isDelivery={isDelivery} />

        {!isDelivery && (
          <PickupInfoMessage nearestPickupTime={nearestPickupTime} />
        )}

        {this._renderBasketWithProducts()}
        {this._renderBasketShellInfo()}
        {this._renderPaymentSelector()}

        <CheckoutTermsLink isHideMarketingData={isHideMarketingData} />

        <MarketingCheckbox
          isHideMarketingCheckbox={isHideMarketingCheckbox}
          allowReceiveMarketingInfo={!!allowReceiveMarketingInfo}
          marketingCheckboxAction={this._marketingCheckboxAction}
        />
      </div>
    );
  }

  /**
   * Method to render checkout info with coupon field
   */
  _renderBasketShellInfo = () => {
    const {
      basketStore: {
        deliveryFee,
        orderPriceWithOffers,
        finalPriceWithOffers,
        priceWithoutFee,
        intlDeliveryFreeFrom,
        discountPercentage,
        discountPriceWithOffers,
        isMinValueReached,
        minPrice,
        intlMinPrice
      },
      basketStore,
      deliveryAddressStore: { isDelivery },
      themesStore: { isShellThemeActive },
      themesStore
    } = this.props.store;

    return (
      <BasketShellInfo
        fees={basketStore.getIntlPrice(deliveryFee)}
        deliveryFee={deliveryFee}
        isDelivery={isDelivery}
        orderSum={basketStore.getIntlPrice(orderPriceWithOffers)}
        total={basketStore.getIntlPrice(finalPriceWithOffers)}
        valueWithoutFee={basketStore.getIntlPrice(priceWithoutFee)}
        deliveryFreeFrom={intlDeliveryFreeFrom}
        discount={discountPercentage}
        discountValue={basketStore.getIntlPrice(discountPriceWithOffers)}
        mbvReached={isMinValueReached}
        hasMinPrice={(minPrice || 0) > 0}
        mbv={intlMinPrice}
        isShell={isShellThemeActive}
        discountStyle={themesStore.discountStyle()}
      />
    );
  };

  /**
   * Method to render basket with products
   */
  _renderBasketWithProducts = () => {
    const {
      ingredientsSelectStore,
      offerStore,
      basketStore: { offers, products },
      basketStore
    } = this.props.store;

    const prepareProductsForEditing = (
      product: ProductModel | OfferModel,
      index: number
    ) => ingredientsSelectStore.prepareProductsForEditing(product, index);

    const setChangeIndex = (index: number) => offerStore.setChangeIndex(index);

    return (
      <BasketShellWithProducts
        props={this.props}
        offers={offers}
        products={products}
        cantAddProductsToBasket={cantAddProductsToBasket(this.props)}
        getPrice={(price: number) => basketStore.getIntlPrice(price)}
        setOfferChangeIndex={setChangeIndex}
        prepareProductsForEditing={prepareProductsForEditing}
        isReadOnly
      />
    );
  };

  /**
   * Method to render footer
   */
  _renderFooterButtons = () => {
    const {
      isError,
      isOrderSent,
      activePaymentMethod
    } = this.props.store.orderPaymentMethodsStore;

    const isHide = isError || isOrderSent || !activePaymentMethod;

    return !isHide ? this._renderCheckoutFooterButtons() : null;
  };

  /**
   * Method to render content
   */
  _renderContent = () => {
    const {
      isPaymentProcessing,
      orderInProcess,
      paymentStatus
    } = this.props.store.orderPaymentMethodsStore;

    if (!IS_CLIENT && !paymentStatus.contactData) {
      return this.renderPendingPanel();
    }

    const paymentInProcess = orderInProcess || isPaymentProcessing;

    return (
      <>
        {this._renderPaymentData()}
        {!paymentInProcess && this._renderFooterButtons()}
      </>
    );
  };

  /**
   * Method to render header
   */
  _renderHeader = () => {
    const {
      store: {
        themesStore: { isPortalAppThemeActive },
        themesStore,
        restaurantStore: {
          branch: { branchName }
        }
      },
      t
    } = this.props;

    const backAction = isPortalAppThemeActive ? this._backAction : undefined;

    return (
      <HeaderShell
        title={t('order_payment_methods:order')}
        style={themesStore.headerStyle()}
        backAction={backAction}
        isPortalAppThemeActive={isPortalAppThemeActive}
        branchName={branchName}
      />
    );
  };

  render() {
    return (
      <ShellLayout
        shellHeader={this._renderHeader()}
        shellContent={this._renderContent()}
      />
    );
  }
}

export default CheckoutShell;
