import classNames from 'classnames';

// utils
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

/**
 * Category menu component is used to show
 * category list in header.
 * @name ReactCategoryMenuComponent
 */
export default class RestaurantLogo extends PureComponent {
  static propTypes = {
    isLogoActionAvailable: PropTypes.bool.isRequired,
    altText: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    onLogoClick: PropTypes.func.isRequired
  };

  render() {
    const { isLogoActionAvailable, altText, logo, onLogoClick } = this.props;

    return (
      <div
        onClick={(e) => onLogoClick(e, this.props)}
        className={classNames({
          'logo-container': true,
          'logo-click': isLogoActionAvailable
        })}
      >
        <img
          className="logo"
          alt={`${altText} logo`}
          title={altText}
          src={logo}
        />
      </div>
    );
  }
}
