import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import states from '../enums/states.enum';
import { generateRoutePath } from '../utils/routing';

@withRouter
export default class StartPageRoute extends React.Component {
  render() {
    return <></>;
  }
}
