import sum from 'lodash/sum';
import { action, computed, observable } from 'mobx';

import NEGATIVE_EXTRAS_PRICING_MODELS from 'client/enums/negative_extras_price_models.enum';
import { ProductModel } from 'client/models/product.model';
import RootStore from 'client/stores';
import { filterPrice } from 'client/utils/functions';

import i18n from '../../../i18n';

/**
 * Ingredient select store class. Used to work with ingredient`s selection in store.
 * @constructor
 * @param {instance} api - {@link API} instance.
 * @param {instance} storage - {@link storage} instance.
 * @property {object} product - Single product.
 * @property {array} _groupedProducts - Single product.
 * @property {array <object>} ingredientGroups - Observable array of ingredient`s groups.
 * @property {boolean} isLoading - Observable isLoading variable.
 * @property {boolean} isEditing - Observable isEditing variable.

 */
export default class IngredientsSelectStore {
  api: any;

  root: RootStore;

  constructor(root, state, api) {
    Object.assign(this, state);

    this.api = api;

    this.root = root;
  }

  @observable product: Partial<ProductModel> = {};

  @observable countToAdd = 1;

  _groupedProducts = [];

  @observable ingredientGroups = [];

  @observable isLoading = true;

  @observable isEditing = false;

  @observable editingIndex = null;

  @observable activeIngredient = 'sizes';

  @observable isCollapseAnimate = false;

  @observable scrollToTopAnimate = false;

  /**
   * Method to set value of active ingredient group
   * @param {string} id - selected active
   */
  @action setActiveIngredient(id = 'sizes') {
    this.activeIngredient = String(id);
  }

  @action setActiveIngredientWithRequired(): void {
    let flag = true;
    let value = '';

    this.product.ingredientGroups.map((group) => {
      if (flag) {
        const isShowWarning = !!(
          group.min_quan - group.selectedIngredientsCount >
          0
        );

        if (isShowWarning) {
          value = String(group.id);

          flag = false;
        }
      }
    });

    if (value !== this.activeIngredient) {
      this.activeIngredient = value;
    }
  }

  /**
   * Method to let collapse header wiggle animation happens
   * when user click to order button in extras modal
   * @param {number} delay - delay before time when flag should be disabled
   */
  @action toggleCollapseAnimate(delay = 1100) {
    this.isCollapseAnimate = true;

    setTimeout(() => {
      this.isCollapseAnimate = false;
    }, delay);
  }

  /**
   * Method to let collapse header wiggle animation happens
   * when user click to order button in extras modal
   * @param {number} delay - delay before time when flag should be disabled
   */
  @action toggleScrollToTopAnimate(delay = 0) {
    this.scrollToTopAnimate = true;

    setTimeout(() => {
      this.scrollToTopAnimate = false;
    }, delay);
  }

  /**
   * Method to set loading status.
   * @param {boolean} flag - loading status.
   * @memberof IngredientsSelectStore#
   * @method toggleLoading
   */
  @action toggleLoading(flag) {
    this.isLoading = flag;
  }

  /**
   * Method to get product by id and load it to application.
   * @param {number} product_id - unique identifier.
   * @return {promise} prepared product.
   * @memberof IngredientsSelectStore#
   * @method loadSingleProduct
   */
  @action loadSingleProduct(product_id) {
    if (!this.isEditing) {
      this.toggleLoading(true);

      return this.api
        .getSingleProduct(product_id)
        .then((data) => {
          this.prepareData(data.d);

          return this.product;
        })
        .then(() => this.toggleLoading(false));
    }
  }

  /**
   * Method to prepare product.
   * @param {object} product - full product info.
   */
  @action prepareData(product) {
    this.product = new ProductModel(product.id, product, this.root);

    this.countToAdd = 1;

    this.product.setCount(1);

    if (!this.isEditing && this.product.sizeWithWrightPrice) {
      this.product.setSize(this.product.sizeWithWrightPrice.id);
    }
  }

  /**
   * Method to prepare data for editing product.
   * @param {array} groupedProduct - group of identical products.
   * @param {number} indexOfProduct - index of editing product in array
   */
  @action prepareProductsForEditing(groupedProduct, indexOfProduct) {
    this.editingIndex = indexOfProduct;

    this.isEditing = true;

    const clonedProduct = groupedProduct;

    this.prepareData(clonedProduct.product.getToJS());

    this.countToAdd = clonedProduct.count;

    this.isLoading = false;
  }

  /**
   * Method to reset ingredient selection.
   */
  @action reset() {
    this.product = {};

    this._groupedProducts = [];

    this.isLoading = true;

    this.isEditing = false;
  }

  /**
   * Method to serialize Product to pure JS object.
   * @return {object} serialized Product with ingredients
   */
  getToJS() {
    return {
      product: this.product.getToJS()
    };
  }

  /**
   * Method to calculate ingredients price.
   * @return {number} ingredients price.
   * @memberof IngredientsSelectStore#
   * @method calculatedIngredientsPrice
   */
  @computed get calculatedIngredientsPrice() {
    return sum(
      this.product.ingredientGroups.map(
        (group) => group.selectedIngredientsPrice
      )
    );
  }

  /**
   * Method to calculate order price (product price + ingredients price)
   * @return {number} order price.
   * @memberof IngredientsSelectStore#
   * @method calculatedOrderPrice
   */
  @computed get calculatedOrderPrice() {
    let result = this.product.price + this.calculatedIngredientsPrice;

    if (this.isNegativeExtrasPricingModelExact) {
      result = Math.max(result, 0);
    } else if (this.isNegativeExtrasPricingModelFair) {
      result = Math.max(result, this.product.price);
    }

    return result;
  }

  /**
   * Method to prepare price for the UI
   * @return {number} prepared order price.
   * @memberof IngredientsSelectStore#
   * @method filteredCalculatedOrderPrice
   */
  @computed get filteredCalculatedOrderPrice() {
    return filterPrice(this.calculatedOrderPrice);
  }

  @computed get intlCalculatedOrderPrice() {
    return this.root.basketStore.getIntlPrice(this.calculatedOrderPrice);
  }

  /**
   * Negative extras pricing model is none for branch
   */
  @computed get isNegativeExtrasPricingModelNone() {
    return (
      this.root.restaurantStore.branch.getNegativeExtrasPricingModel ===
      NEGATIVE_EXTRAS_PRICING_MODELS.NONE
    );
  }

  /**
   * Negative extras pricing model is fair for branch
   */
  @computed get isNegativeExtrasPricingModelFair() {
    return (
      this.root.restaurantStore.branch.getNegativeExtrasPricingModel ===
      NEGATIVE_EXTRAS_PRICING_MODELS.FAIR
    );
  }

  /**
   * Negative extras pricing model is exact for branch
   */
  @computed get isNegativeExtrasPricingModelExact() {
    return (
      this.root.restaurantStore.branch.getNegativeExtrasPricingModel ===
      NEGATIVE_EXTRAS_PRICING_MODELS.EXACT
    );
  }

  @computed get negativeExtrasTitleText() {
    return (
      this.root.restaurantStore.branch.getNegativeExtrasTitle ||
      i18n.t('extras_modal:negativeExtrasTitle')
    );
  }

  @action increaseCount() {
    this.countToAdd++;
  }

  @action reduceCount() {
    if (this.countToAdd > 1) {
      this.countToAdd--;
    }
  }
}
