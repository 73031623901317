import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {IS_CLIENT} from 'config';

// Components
import DesktopRoute from 'client/routes/desktop-route';

import SmallLoader from '../loaders/small_loader';

// CSS
import './_right-side-block.scss';
import CustomerInfo from 'client/themes/theme-hermes/components/delivery_info/customer.info';

@withRouter
@inject('store')
@observer
class RightSideBlock extends Component {
  constructor(props) {
    super(props);

    if (IS_CLIENT) {
      setTimeout(() => {
        this.props.store.restaurantStore.setLoadingRightBlock(false);
      }, 500);
    }

    IS_CLIENT && this.props.store.basketStore.loadBasketFromStorage();
  }

  render() {
    const { loadingAnimation } = this.props.store.restaurantStore.restaurant;

    return (
      <div id="right-side-block" className="white-block">
        {this.props.store.restaurantStore.loadRightBlock ? (
          <SmallLoader loader={loadingAnimation} />
        ) : (
          <>
            <CustomerInfo />
            <DesktopRoute
              hostName={this.props.store.restaurantStore.hostName}
            />
          </>
        )}
      </div>
    );
  }
}

export default withTranslation(['home'])(RightSideBlock);
