import React, { useEffect } from 'react';

import Modal from '../../../ui/Modal/Modal';

import { SearchMobile } from './index';

import { IS_CLIENT } from '../../../../config';
import './Modal.scss';

export const SearchModalMobile = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  let widthMobileMenu: number;
  let menu: HTMLDivElement | undefined;

  if (IS_CLIENT) {
    menu = document.querySelector('.sticky-header-mobile') as HTMLDivElement;

    if (menu) {
      widthMobileMenu = menu.offsetHeight;
    }
  }

  return (
    <Modal className="SearchModal">
      <div className="ant-modal">
        <div className="ant-modal-body">
          <div className="SearchModal-container">
            <SearchMobile style={{ paddingTop: widthMobileMenu }} />
          </div>
        </div>
      </div>
    </Modal>
  );
};
