import { Tooltip } from 'antd';
import classnames from 'classnames';
import React from 'react';

import FakeButton from 'client/ui/FakeButton';

import css from './AdditiveIcon.module.scss';
import { AdditiveIconProps } from './AdditiveIcon.type';

const AdditiveIcon = ({
  className,
  children,
  onClick,
  tooltip
}: AdditiveIconProps) => {
  const icon = (
    <FakeButton
      className={classnames(css.AdditiveIcon, className)}
      onClick={onClick}
    >
      {children}
    </FakeButton>
  );

  if (tooltip) {
    return (
      <Tooltip getPopupContainer={(element) => element} title={tooltip}>
        {icon}
      </Tooltip>
    );
  }

  return icon;
};

export { AdditiveIcon };

export default React.memo(AdditiveIcon);
