import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { PAYMENT_METHODS } from 'client/enums/payment_methods.enum';
import { PaymentMethod } from 'client/models/payment_method.model';

import { isProduction } from '../../../../../../config';
import GoogleOptimize from '../../../../../components/GoogleOptimize';

import PaymentMethodRow from './PaymentMethod/PaymentMethod';
import { PaymentMethodsSelectProps } from './PaymentMethodsSelect.type';

import './_payment_methods.scss';

/**
 * Method component is used to show single payment method
 * and display some information about it in payment methods form.
 */
class PaymentMethodsSelect extends Component<
  PaymentMethodsSelectProps & WithTranslation
> {
  /**
   * Action to set active method id
   */
  setActivePaymentMethod = (methodId: number) => {
    const { setActivePaymentMethod } = this.props;

    setActivePaymentMethod(methodId);
  };

  setPaypalExperimentValue = (id: number) => {
    const { getPaypalExperimentId } = this.props;

    getPaypalExperimentId?.(id);
  };

  /**
   * Reason of disabled payment method
   */
  disableReason = (
    disabledByAdminPanel: boolean,
    disabledByContactlessDelivery: boolean
  ) => {
    const { t } = this.props;

    if (disabledByAdminPanel) {
      return t('order_payment_methods:temporarilyUnavailable');
    }

    if (disabledByContactlessDelivery) {
      return t('order_checkout:contactlessDeliveryReason');
    }

    return null;
  };

  renderPaypalExperiment = (
    disabledByAdminPanel: boolean,
    disabledByContactlessDelivery: boolean
  ) => {
    const experimentId = isProduction
      ? 'gu1yWg5PSziRIiKKakC2lw'
      : 'zHYpArQCQI-3-k5Eyh7Mrw';

    const { methods, paymentMethodStyle } = this.props;
    const { PAYPAL_ID, PAYPAL_V2_ID } = PAYMENT_METHODS;
    const oldPaypalMethod = methods.find((method) => method.id === PAYPAL_ID);

    const newPaypalMethod = methods.find(
      (method) => method.id === PAYPAL_V2_ID
    );

    const disableReason = this.disableReason(
      disabledByAdminPanel,
      disabledByContactlessDelivery
    );

    return (
      oldPaypalMethod &&
      newPaypalMethod && (
        <GoogleOptimize key="paypal" id={experimentId}>
          <PaymentMethodRow
            method={oldPaypalMethod}
            setActive={() => this.setActivePaymentMethod(PAYPAL_ID)}
            afterMount={() => this.setPaypalExperimentValue(PAYPAL_ID)}
            disabled={disabledByAdminPanel || disabledByContactlessDelivery}
            disableReason={disableReason}
            style={paymentMethodStyle}
          />
          <PaymentMethodRow
            method={newPaypalMethod}
            setActive={() => this.setActivePaymentMethod(PAYPAL_V2_ID)}
            afterMount={() => this.setPaypalExperimentValue(PAYPAL_V2_ID)}
            disabled={disabledByAdminPanel || disabledByContactlessDelivery}
            disableReason={disableReason}
            style={paymentMethodStyle}
          />
        </GoogleOptimize>
      )
    );
  };

  renderMethod = (method: PaymentMethod) => {
    const { payPalInfo, isDisableCashMethods, paymentMethodStyle } = this.props;

    const disabledByAdminPanel =
      method.id === payPalInfo.id && !payPalInfo.value;

    const disabledByContactlessDelivery =
      isDisableCashMethods && !method.isOnline;

    /* if (method.id === PAYMENT_METHODS.PAYPAL_ID) {
      return this.renderPaypalExperiment(
        disabledByAdminPanel,
        disabledByContactlessDelivery
      );
    } */

    const disableReason = this.disableReason(
      disabledByAdminPanel,
      disabledByContactlessDelivery
    );

    return (
      <PaymentMethodRow
        key={method.id}
        method={method}
        setActive={() => this.setActivePaymentMethod(method.id)}
        disabled={disabledByAdminPanel || disabledByContactlessDelivery}
        disableReason={disableReason}
        style={paymentMethodStyle}
      />
    );
  };

  /**
   * Filter only cash if basket final Price === 0, case online payment methods unavailable
   * Filter if it's a new paypal
   */
  render() {
    const { methods, title, finalPrice } = this.props;

    const paymentMethods = methods.filter((method) =>
      finalPrice === 0
        ? method.id === 1
        : method.id !== PAYMENT_METHODS.PAYPAL_V2_ID
    );

    return (
      <div className="paymethods">
        <div className="paymethod-title">{title}</div>
        {paymentMethods.map((method) => this.renderMethod(method))}
      </div>
    );
  }
}

export default withTranslation(['order_payment_methods', 'order_checkout'])(
  PaymentMethodsSelect
);
