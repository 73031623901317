/* eslint-disable no-restricted-globals */
import dayjs from 'dayjs';
import { action, computed, observable } from 'mobx';

import ImprintViewTypes from 'client/enums/imprint_view_types.enum';
import handlers from 'client/enums/mobile_handlers.enum';
import states from 'client/enums/states.enum';
import STORAGE_KEYS from 'client/enums/storage_keys.enum';
import { BranchModel } from 'client/models/branch.model';
import { RestaurantModel } from 'client/models/restaurant.model';
import Storage from 'client/modules/storage';
import RootStore from 'client/stores';
import { setApiTokens } from 'client/utils/api';
import { handleError } from 'client/utils/functions';
import { getIsSlugSupported } from 'client/utils/routing';
import { IS_CLIENT } from 'config';

/**
 * Restaurant Store
 * @constructor
 * @property {RestaurantModel} restaurant - Restaurant object
 * @property {BranchModel} branch - branch object
 */
export default class RestaurantStore {
  api: any;

  root: RootStore;

  storage: Storage;

  isRunInsideMobileApp: boolean;

  constructor(root, state, api, storage) {
    Object.assign(this, state, {
      isBranchSelectingSkipped: state.isBranchSelectingSkipped || false
    });

    this.api = api;

    this.root = root;

    this.storage = storage;

    this.isRunInsideMobileApp = root.getIsRunInsideMobileApp();

    if (state.restaurant && Object.keys(state.restaurant).length > 0) {
      this.restaurant = new RestaurantModel(
        state.restaurant,
        this.isRunInsideMobileApp
      );

      this.api.setToken(this.restaurant.getToken);

      const token = this.restaurant.getToken;

      setApiTokens(token);
    }

    if (state.branch && Object.keys(state.branch).length > 0) {
      this.branch = new BranchModel(state.branch, state.branch.isApp);

      this.api.setBranchId(this.branch.branchId);
    }

    if (state.hostName) {
      this.hostName = state.hostName;
    }

    this.currentUtcOffset = dayjs().utcOffset();

    if (state.restaurantTime || state.customerTime) {
      this.setCurrentTime();
    }
  }

  _localStorageAreaCode = STORAGE_KEYS.AREA_CODE;

  /* Observable variables */

  @observable restaurant: Partial<RestaurantModel> = {};

  @observable branch: Partial<BranchModel> = {};

  @observable isFetching = false;

  @observable hostName = '';

  @observable loadingPage = true;

  @observable loadRightBlock = true;

  /**
   * Activation state of templating preview mode
   * @type {boolean}
   */
  @observable isTemplatingPreviewModeActive = false;

  @observable showClosedModal = false;

  @observable closedModalAlreadyShowed = false;

  @observable isBranchSelectingSkipped = false;

  @observable pageContent = '';

  @observable currentUtcOffset = dayjs().utcOffset();

  @observable restaurantTime = dayjs();

  @observable customerTime = dayjs();

  @observable areaCode = '';

  @observable sublocality = '';

  @observable canonicalLink = '';

  @observable domain = '';

  @observable slug = '';

  @observable preparationTime;

  @observable showOnlyBranchAreaCodes = false;

  /* Action setters */

  @action setHostName(host) {
    this.hostName = host;
  }

  @action setSlug(slug) {
    this.slug = slug;
  }

  @action setIsFetching(flag) {
    this.isFetching = flag;
  }

  @action setDomain(domain) {
    this.domain = domain;
  }

  @action setLoadingRightBlock(flag) {
    this.loadRightBlock = flag;
  }

  @action getRestaurantData(domain, slug) {
    const isSlugSupported = getIsSlugSupported(this.hostName);

    if (!isSlugSupported) {
      return this.fetchRestaurantDataByDomain(domain);
    }

    return this.fetchRestaurantDataBySlug(slug);
  }

  @action fetchRestaurantDataByDomain(domain) {
    return new Promise((resolve, reject) =>
      this.api
        .getRestaurantDataByDomain(domain)
        .then((data) => {
          if (
            data.type === 'Aggregator' &&
            this.root.themesStore.isShellThemeActive
          ) {
            this.root.aggregatorStore.setAggregator(data.aggregator);

            this.root.aggregatorStore.setAggregatorSlug(this.slug);

            this.root.aggregatorStore.setAggregatorMode(true);

            this.api.setToken(data.aggregator.id);

            const token = data.aggregator.id;

            setApiTokens(token);

            return resolve(null);
          }

          this.setRestaurant(data);

          this.root.aggregatorStore.setAggregatorMode(false);

          this.api.setToken(this.restaurant.getToken);

          const token = this.restaurant.getToken;

          setApiTokens(token);

          this.setCurrentTime();

          return resolve(null);
        })
        .catch((err) => {
          console.error(err);

          reject('Something went wrong to load restaurant data');
        })
    );
  }

  @action fetchRestaurantDataBySlug(slug) {
    this.setSlug(slug);

    return new Promise((resolve, reject) =>
      this.api
        .getRestaurantDataBySlug(slug)
        .then((data) => {
          if (
            data.type === 'Aggregator' &&
            this.root.themesStore.isShellThemeActive
          ) {
            this.root.aggregatorStore.setAggregator(data.aggregator);

            this.root.aggregatorStore.setAggregatorSlug(this.slug);

            this.root.aggregatorStore.setAggregatorMode(true);

            this.api.setToken(data.aggregator.id);

            const token = data.aggregator.id;

            setApiTokens(token);

            return resolve(null);
          }

          this.setRestaurant(data);

          this.root.aggregatorStore.setAggregatorMode(false);

          this.api.setToken(this.restaurant.getToken);

          const token = this.restaurant.getToken;

          setApiTokens(token);

          this.setCurrentTime();

          return resolve(null);
        })
        .catch((err) => {
          console.error(err);

          reject('Something went wrong to load restaurant data');
        })
    );
  }

  @action fetchBranchDataById(branchId) {
    this.root.offerStore.clearOffersList(); // clearing of offers array if branch was changed
    if (branchId === undefined) {
      return Promise.reject(
        handleError(
          'branch id',
          this.isSlugSupported ? `/${this.slug}` : states.error,
          'no branch id'
        )
      );
    }
    return this.api
      .getBranchData(branchId)
      .then((data) => {
        this.setBranch(data);

        this.root.themesStore.isShellThemeActive &&
          this.root.categoryMenuStore.setShouldOpenCategoriesState(
            !!this.branch.defaultThemeHomepage
          );

        this.root.offerStore.setOfferCategory(this.branch.getOfferCategory);

        return Promise.resolve();
      })
      .catch((err) =>
        Promise.reject(
          handleError(
            'redirect',
            this.isSlugSupported ? `/${this.slug}` : states.error,
            'Loading branch data by id has failed'
          )
        )
      );
  }

  @action getBranchById(branchId) {
    return this.restaurant.branches?.find(
      ({ id }) => id === branchId.toString()
    );
  }

  @action fetchPage(page) {
    page = page === states.termsOfUse ? 'terms' : page;

    this.toggleLoadingPage(true);

    const branchId =
      this.branch.id ||
      (this.restaurant.countBranches
        ? this.restaurant.firstBranchId
        : undefined);

    return this.api
      .getPage(page, branchId)
      .then((data) => {
        this.pageContent = data;

        this.toggleLoadingPage(false);

        return data;
      })
      .catch((err) =>
        Promise.reject(
          handleError(
            'redirect',
            this.isSlugSupported ? `/${this.slug}` : states.error,
            'Loading legal page data has been failed'
          )
        )
      );
  }

  /**
   * Method for loading  app picture image
   * @returns {Promise<void | never>}
   */
  @action loadAppPictureImage() {
    return this.api
      .getLinkToAppPicture()
      .then((data) => {
        this.restaurant.setMobileAppPictureUrl(data.url);

        return Promise.resolve();
      })
      .catch((err) =>
        Promise.reject(`Loading app picture image error: ${err}`)
      );
  }

  @action setCanonicalLink({
    url,
    host,
    protocol
  }: {
    url: string;
    host: string;
    protocol: string;
  }) {
    const isAggregator = host === 'hermes.ordersmart.de';

    if (isAggregator) {
      const { canonicalUrls } = this.restaurant;
      const mainDomain = canonicalUrls && canonicalUrls[0];

      this.canonicalLink = mainDomain
        ? `${protocol}://${mainDomain}${url}`
        : '';
    } else {
      this.canonicalLink = `${protocol}://${host}${url}`;
    }
  }

  @action setBranch(data?: any) {
    this.branch = data
      ? new BranchModel(
          data,
          this.root && this.root.themesStore.isShellThemeActive
        )
      : {};
  }

  @action setRestaurant(data) {
    this.restaurant = new RestaurantModel(data, this.isRunInsideMobileApp);

    if (this.restaurant.aggregatorUrl) {
      this.root.aggregatorStore.setAggregatorCustomPageUrl(
        this.restaurant.aggregatorUrl
      );
    }
  }

  @action toggleLoadingPage(flag) {
    this.loadingPage = flag;
  }

  @action setCurrentTime() {
    if (
      this.restaurant.getUtcOffset &&
      this.restaurant.getUtcOffset !== this.currentUtcOffset
    ) {
      this.restaurantTime = dayjs().add(
        this.restaurant.getUtcOffset - this.currentUtcOffset,
        'minute'
      );
    } else {
      this.restaurantTime = dayjs();
    }

    this.customerTime = dayjs();
  }

  /**
   * Set area code to store and save to local storage
   * @param {string} areaCode
   * @param {string} [sublocality=null]
   * @memberof RestaurantStore
   */
  @action setAreaCode(areaCode: string, sublocality?: string) {
    this.areaCode = areaCode;

    if (sublocality) {
      this.sublocality = sublocality;
    }

    if (IS_CLIENT) {
      this.saveAreaCode(this.areaCode, this.sublocality);
    }
  }

  /**
   * Save area code and sublocality to local storage
   * @param {string} areaCode
   * @param {string} sublocality
   * @memberof RestaurantStore
   */
  @action saveAreaCode(areaCode, sublocality) {
    this.storage.saveToStorage(
      this._localStorageAreaCode,
      [areaCode, sublocality],
      this.branch.branchId
    );
  }

  /**
   * Load area code and sublocality
   * from local storage and set to store
   * @returns
   * @memberof RestaurantStore
   */
  @action
  async loadAreaCode() {
    try {
      const currentAreaCode = await this.storage.loadFromStorage(
        [this._localStorageAreaCode],
        this.branch.branchId
      );

      if (currentAreaCode[0]) {
        const areaCode = currentAreaCode[0][0];
        const sublocality = currentAreaCode[0][1];

        if (areaCode) {
          this.setAreaCode(areaCode, sublocality);

          this.root &&
            this.root.deliveryAddressStore.handleChangeZip({
              zip: areaCode,
              sublocality
            });
        }

        return currentAreaCode[0];
      }

      return currentAreaCode;
    } catch (err) {
      this.areaCode = '';

      this.sublocality = '';

      console.error(err);
    }
  }

  @action setShowClosedModal() {
    if (this.closedModalAlreadyShowed) {
      return;
    }

    if (this.root) {
      const showModalForDelivery =
        this.root.deliveryAddressStore.hasDelivery &&
        this.branch.closedForDelivery &&
        this.root.deliveryAddressStore.isDelivery;

      const showModalForPickup =
        this.root.deliveryAddressStore.hasPickup &&
        this.branch.closedForPickUp &&
        !this.root.deliveryAddressStore.isDelivery;

      if (
        ((showModalForDelivery || showModalForPickup) &&
          !this.root.themesStore.isMobile) ||
        this.branch.isHolidayModeActive
      ) {
        this.closedModalAlreadyShowed = true;

        return this.toggleClosedModal(true);
      }
    }
  }

  @action toggleClosedModal(flag) {
    this.showClosedModal = flag;
  }

  @action resetClosedModalState() {
    this.showClosedModal = false;

    this.closedModalAlreadyShowed = false;
  }

  /**
   * Method to setting isBranchSelectingSkipped state, which allow to skip branch selector, and show it before adding to basket
   * @param {boolean} state - true is skip branch selecting
   */
  @action setSkipBranchSelectingState(state) {
    this.isBranchSelectingSkipped = state;
  }

  /**
   * Action to change templating preview mode state
   * @param {boolean} state
   */
  @action setTemplatingPreviewModeState(state = false) {
    this.isTemplatingPreviewModeActive = state;
  }

  /**
   * Action to set preparation time
   * @param {string} time
   */
  @action setPreparationTime(time = '') {
    this.preparationTime = time;
  }

  /**
   * Action to set state of showing only branch area codes on shell
   */
  @action setShowingBranchPostalCodes(state) {
    this.showOnlyBranchAreaCodes =
      this.root.themesStore.isShellThemeActive && state;
  }

  /* Computed getters */

  /**
   * Method to check is slug supported
   * @returns {*}
   */
  @computed get isSlugSupported() {
    return getIsSlugSupported(this.hostName);
  }

  /**
   * Method to get is location chosen
   * @returns {boolean}
   */
  @computed get isLocationChosen() {
    const { advancedCalcTypeData } = this.root.basketStore;

    const result = this.useCalculationTypeByDeliveryArea
      ? advancedCalcTypeData.availability
      : this.areaCode !== '';

    return result;
  }

  /**
   * Method to prepare share content for shell
   * @returns {INativeNotification}
   */
  @computed get shareContent() {
    return {
      name: handlers.SHARE,
      data: {
        text: this.restaurant.getTitle,
        image: this.restaurant.logo,
        link: this.restaurant.customerLandingPageLink
      }
    };
  }

  /**
   * Method find current area code object
   * @return {object} - current area code object
   */
  @computed get currentAreaCode() {
    return this.branch.areaCodes?.find(
      ({ areaCode, sublocality }) =>
        this.areaCode === areaCode && this.sublocality === sublocality
    );
  }

  /**
   * Method find changed area code object
   * @return {object} - changed area code object
   * @memberof GlobalStore#
   * @method changedAreaCode
   */
  @computed get changedAreaCode() {
    const { zipToChange } = this.root.deliveryAddressStore;

    if (typeof zipToChange === 'object') {
      const areaCode = zipToChange.zip;
      const { sublocality } = zipToChange;

      return this.branch.areaCodes?.find(
        (ac) => areaCode === ac.areaCode && sublocality === ac.sublocality
      );
    }

    return this.branch.areaCodes?.find((ac) => zipToChange === ac.areaCode);
  }

  /**
   * Method to compare current area code and changed area code
   * @return {bool} - result of compare
   */
  @computed get currentAreaEqualsChangedArea() {
    return this.currentAreaCode
      ? this.currentAreaCode.mbv === this.changedAreaCode?.mbv &&
          this.currentAreaCode.deliveryCosts ===
            this.changedAreaCode.deliveryCosts
      : true;
  }

  /**
   * Method to get current location by areaCode and sublocality
   * @return {object} areaCode object
   * @memberof GlobalStore#
   * @method getCurrentLocation
   */
  @computed get getCurrentLocation() {
    return this.branch && this.branch.parsedAreaCodes
      ? this.branch.parsedAreaCodes.find((i) => {
          const { zip, city } = this.root.deliveryAddressStore.address;

          if (zip && city) {
            return i.value === `${zip} - ${city}`;
          }

          return i.value === `${this.areaCode} - ${this.sublocality}`;
        })
      : {};
  }

  /**
   * Method to check is closed message modal should be shown
   * @returns {boolean}
   */
  @computed get isShowClosedModal() {
    if (this.root.themesStore.isShellThemeActive) {
      return (
        this.showClosedModal &&
        this.root.restaurantStore.branch.isHolidayModeActive
      );
    }

    return this.showClosedModal;
  }

  @computed get pageFetching() {
    return this.loadingPage;
  }

  @computed get isPreorderAvailable() {
    return (
      this.branch.branchHasPreorder &&
      this.root?.openingHoursStore.preorderDays.length > 0
    );
  }

  @computed get isPreorderAlways() {
    return (
      this.branch.branchHasAllwaysPreorder &&
      this.root?.openingHoursStore.preorderDays.length > 0
    );
  }

  @computed get isShopClosed() {
    const { isDelivery } = this.root.deliveryAddressStore;

    return (
      ((isDelivery && this.branch.closedForDelivery) ||
        (!isDelivery && this.branch.closedForPickUp)) &&
      !this.isPreorderAlways
    );
  }

  @computed get cantAddProductsToBasket() {
    return (
      this.isShopClosed ||
      location.pathname.split('/').pop() === states.address ||
      location.pathname.split('/').pop() === states.checkout
    );
  }

  /**
   * Method to get default Google Analytics Key
   * @returns {string}
   */
  @computed get defaultGoogleAnalyticsKey() {
    return this.root.themesStore.isShellThemeActive
      ? this.restaurant.shellGoogleAnalyticsKey
      : this.restaurant.googleAnalyticsKey;
  }

  /**
   * Method to recognize for showing privacy policy menu item
   * @returns {boolean}
   */
  @computed get showPrivacyPolicy() {
    const currentLang = this.root.languagesStore.activeLanguage.id;

    return currentLang !== 'pl';
  }

  /**
   * Method to recognition of advanced type of delivery calculation
   */
  @computed get useCalculationTypeByDeliveryArea() {
    return (
      this.branch.isAdvancedDeliveryCalculationType ||
      this.branch.isBasicGpsCalculationType
    );
  }

  /**
   * Method to recognition of german restaurants
   */
  @computed get isGermanRestaurant() {
    return this.restaurant.getLang === 'de';
  }

  /**
   * Method to recognition of polish restaurants
   */
  @computed get isPolishRestaurant() {
    return this.restaurant.getLang === 'pl';
  }

  /**
   * Method to recognition of only customer information in imprint
   */
  @computed get onlyCustomerImprint() {
    return (
      this.isGermanRestaurant &&
      (this.restaurant.imprintType === ImprintViewTypes.CUSTOMER ||
        this.restaurant.imprintType ===
          ImprintViewTypes.CUSTOMER_WITH_APP_SMART_EMAIL)
    );
  }

  /**
   * Method to recognition of only app smart information in imprint
   */
  @computed get onlyAppSmartImprint() {
    return (
      this.isGermanRestaurant &&
      this.restaurant.imprintType === ImprintViewTypes.APP_SMART_CREDENTIALS
    );
  }

  /**
   * Method to recognition of customer with app smart information in imprint
   */
  @computed get customerWithAppSmartImprint() {
    return (
      this.isPolishRestaurant ||
      this.restaurant.imprintType === ImprintViewTypes.BOTH
    );
  }

  /**
   * Method to recognition of customer imprint but with appsmart email
   */
  @computed get showAppsmartEmailInImprint() {
    return (
      this.restaurant.imprintType ===
      ImprintViewTypes.CUSTOMER_WITH_APP_SMART_EMAIL
    );
  }

  @computed get areaCodesForSelect() {
    return this.showOnlyBranchAreaCodes
      ? this.restaurant.allAreaCodes.filter(
          (areaCode) => this.branch.id === areaCode.branchId
        )
      : this.restaurant.allAreaCodes;
  }

  /* Getters */

  saveBuildVersion() {
    this.storage.saveToStorage(STORAGE_KEYS.BUILD_V, BUILD_VERSION, 'HERMES');
  }

  async loadBuildVersion() {
    const [buildVersion] = await this.storage.loadFromStorage(
      [STORAGE_KEYS.BUILD_V],
      'HERMES'
    );

    return buildVersion;
  }

  forceClearStorage() {
    this.storage.forcedClearStorage(
      [
        STORAGE_KEYS.ORDER_TYPE,
        STORAGE_KEYS.BASKET,
        STORAGE_KEYS.BASKET_LAST_UPDATED,
        STORAGE_KEYS.PAYMENT_METHODS,
        STORAGE_KEYS.ACTIVE_CATEGORY,
        STORAGE_KEYS.AREA_CODE,
        STORAGE_KEYS.COUPON,
        STORAGE_KEYS.COUPON_LAST_UPDATED
      ],
      this.branch.branchId
    );
  }

  @computed get restaurantLanguage() {
    return this.restaurant.lang;
  }
}
