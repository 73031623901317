// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddressChangeConfirmationModal_AddressChangeConfirmationModal__3YmYS {\n  background-color: var(--hermesModalsBackground);\n  padding: 15px; }\n  .AddressChangeConfirmationModal_AddressChangeConfirmationModal__3YmYS .AddressChangeConfirmationModal_confirmationText__2oh0M {\n    text-align: center; }\n  .AddressChangeConfirmationModal_AddressChangeConfirmationModal__3YmYS .AddressChangeConfirmationModal_buttonGroup__1l17f {\n    margin-top: 20px; }\n", "",{"version":3,"sources":["webpack://src/client/components/AddressChangeConfirmationModal/AddressChangeConfirmationModal.module.scss"],"names":[],"mappings":"AAAA;EACE,+CAA+C;EAC/C,aAAa,EAAE;EACf;IACE,kBAAkB,EAAE;EACtB;IACE,gBAAgB,EAAE","sourcesContent":[".AddressChangeConfirmationModal {\n  background-color: var(--hermesModalsBackground);\n  padding: 15px; }\n  .AddressChangeConfirmationModal .confirmationText {\n    text-align: center; }\n  .AddressChangeConfirmationModal .buttonGroup {\n    margin-top: 20px; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AddressChangeConfirmationModal": "AddressChangeConfirmationModal_AddressChangeConfirmationModal__3YmYS",
	"confirmationText": "AddressChangeConfirmationModal_confirmationText__2oh0M",
	"buttonGroup": "AddressChangeConfirmationModal_buttonGroup__1l17f"
};
export default ___CSS_LOADER_EXPORT___;
