import classNames from 'classnames';
import React, { CSSProperties, Component, MouseEvent, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import AdditiveIconList from 'client/components/AdditiveIconList';

import { withPropsDeepComparison } from '../../../../../../hocs/withPropsDeepComparison/withPropsDeepComparison';
import ProductStandardButton from '../../../../components/buttons/ProductStandardButton/ProductStandardButton';
import AllergenTooltip from '../../../../components/products_list/common/allergen-tooltip';
import ProductDefaultImage from '../../../../components/products_list/common/cell/product-default-image/ProductDefaultImage';
import ProductMetaData from '../../../../components/products_list/common/cell/product-seo';
import ProductNewBadge from '../../../../components/products_list/common/cell/ProductNewBadge/product-new-badge';
import ProductTitle from '../../../../components/products_list/common/cell/ProductTitle/ProductTitle';

import { IProductBigPictureShell } from './props.interface';
import './ProductBigPictureShell.scss';

interface IState {
  productDescriptionNeedTruncate: boolean;
  width: number;
  imageLoaded: boolean;
}

@withPropsDeepComparison()
class ProductBigPictureShell extends Component<
  IProductBigPictureShell,
  IState
> {
  state = {
    productDescriptionNeedTruncate: true,
    width: 0,
    imageLoaded: false
  };

  containerRef = createRef<HTMLDivElement>();

  componentDidMount() {
    if (this.containerRef.current && this.props.setSize) {
      const { marginBottom, marginTop } = getComputedStyle(
        this.containerRef.current
      );

      this.props.setSize(
        this.containerRef.current.getBoundingClientRect().height +
          parseFloat(marginBottom) +
          parseFloat(marginTop)
      );
    }
  }

  /**
   * Handle click on purchasing button
   */
  onButtonClick = (event: MouseEvent) => {
    this.props.onButtonClick(event, this.props.product);
  };

  /**
   * Method for updating status of image loading
   */
  updateImageLoadingStatus = (success: boolean) => {
    this.setState({
      imageLoaded: success
    });
  };

  render() {
    const {
      isShopClosed,
      product,
      isNew,
      hideProductArticles,
      animated,
      productCount,
      onCellClick,
      foodIcons,
      isMobile,
      animationTargetBounds,
      additivesNames,
      buttonStyle,
      separatorStyle,
      horizontalMenuProductStyle,
      currency
    } = this.props;

    let buttonText = '';

    if (product.isValidForOrder) {
      buttonText = product.hasSeveralSizes
        ? `${this.props.t ? this.props.t('product_view:lblFrom') : ''} ${
            product.getIntlPrice
          }`
        : product.getIntlPrice;
    } else {
      buttonText = this.props.t
        ? this.props.t('common:productNotAvailable')
        : '';
    }

    const containerStyle: CSSProperties = {
      borderBottomColor: separatorStyle
        ? separatorStyle.borderBottomColor
        : undefined
    };

    const fullProductListShellDesign = horizontalMenuProductStyle
      ? {
          textColor: horizontalMenuProductStyle.color,
          productNumberTextColor:
            horizontalMenuProductStyle.productNumberTextColor,
          descriptionOpacity: horizontalMenuProductStyle.descriptionOpacity,
          additivesOpacity: horizontalMenuProductStyle.additivesOpacity,
          productNumberBorderColor:
            horizontalMenuProductStyle.productNumberBorderColor,
          defaultPictureIconColor:
            horizontalMenuProductStyle.defaultPictureIconColor,
          defaultPictureBackgroundColor:
            horizontalMenuProductStyle.defaultPictureBackgroundColor
        }
      : {};

    const additivesElement =
      additivesNames && Object.keys(additivesNames).length ? (
        <span style={{ opacity: fullProductListShellDesign.additivesOpacity }}>
          <AllergenTooltip allergens={additivesNames} />
        </span>
      ) : null;

    const defaultImage = (
      <ProductDefaultImage
        iconColor={fullProductListShellDesign.defaultPictureIconColor}
      />
    );

    return (
      <div
        ref={this.containerRef}
        className="big-picture-shell-container"
        onClick={(event) => onCellClick(event, product)}
        style={{ ...containerStyle }}
      >
        <div className="big-picture-shell-image-container">
          {isNew && isNew.value && (
            <ProductNewBadge
              text={this.props.t && this.props.t('product_view:lblNew')}
              style={isNew.style}
            />
          )}
          <div
            className={classNames({
              'big-picture-shell-image-wrapper': true,
              loading: !this.state.imageLoaded,
              'default-image': !product.pic_url
            })}
            style={{
              backgroundColor: product.pic_url
                ? ''
                : fullProductListShellDesign.defaultPictureBackgroundColor
            }}
          >
            <LazyLoadComponent
              placeholder={defaultImage}
              afterLoad={() => this.updateImageLoadingStatus(true)}
            >
              {product.pic_url ? (
                <img
                  src={product.pic_url}
                  alt={product.name}
                  style={{
                    borderColor:
                      fullProductListShellDesign.productNumberBorderColor
                  }}
                  data-testid="product-image"
                />
              ) : (
                defaultImage
              )}
            </LazyLoadComponent>
          </div>
        </div>
        <div
          className="big-picture-shell-title-wrapper"
          style={{ color: fullProductListShellDesign.textColor }}
        >
          <ProductTitle
            text={product.name}
            no={!hideProductArticles ? product.no : ''}
            showMBVIcon={product.count_mbw !== 1}
            showDiscountIcon={!product.isHideDiscountBadge}
            lines={2}
            styles={{
              titleNumberStyle: {
                borderColor:
                  fullProductListShellDesign.productNumberBorderColor,
                color: fullProductListShellDesign.productNumberTextColor
              }
            }}
          >
            {additivesElement}
          </ProductTitle>
        </div>
        <div className="big-picture-shell-description-container">
          <div
            className="big-picture-shell-description-wrapper"
            style={{
              color: fullProductListShellDesign.textColor,
              opacity: fullProductListShellDesign.descriptionOpacity
            }}
            data-testid="product-description"
          >
            {product.description}
          </div>
          <ProductStandardButton
            onClick={this.onButtonClick}
            text={buttonText}
            animated={animated}
            productCount={productCount}
            animationTargetBounds={animationTargetBounds}
            isSoldOut={product.soldOut}
            isClosed={isShopClosed}
            disabled={!product.isValidForOrder}
            mobileView={isMobile}
            isShell
            buttonStyle={buttonStyle}
          />
        </div>
        {foodIcons && !!Object.keys(foodIcons).length && (
          <div className="big-picture-shell-food-icons-wrapper">
            {foodIcons && !!Object.keys(foodIcons).length && (
              <AdditiveIconList additives={foodIcons} />
            )}
          </div>
        )}
        <ProductMetaData product={product} currency={currency} />
      </div>
    );
  }
}

export default withTranslation('product_view')(ProductBigPictureShell);
