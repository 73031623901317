import { inject } from 'mobx-react';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import './DeliveryInfoPage.scss';
import HeaderShell from '../header-shell';
import ShellLayout from '../shell-layout';

@withRouter
@inject('store')
class DeliveryInfoPage extends PureComponent {
  static propTypes = {};

  static defaultProps = {};

  /**
   * Method for back action of routing
   */
  backAction = () => {
    this.props.history.goBack();
  };

  delivery = () => {
    const { areaCode } = this.props.store.restaurantStore;

    const {
      minDeliveryFee,
      maxDeliveryFee
    } = this.props.store.restaurantStore.branch;

    const {
      deliveryFee,
      intlDeliveryFee,
      intlMinDeliveryFee,
      intlMaxDeliveryFee
    } = this.props.store.basketStore;

    if (
      minDeliveryFee === maxDeliveryFee &&
      minDeliveryFee !== 0 &&
      !areaCode
    ) {
      return intlMinDeliveryFee;
    }

    if (areaCode || minDeliveryFee === maxDeliveryFee) {
      return deliveryFee !== 0
        ? intlDeliveryFee
        : this.props.t('delivery_info:freeDelivery');
    }

    return `(${intlMinDeliveryFee} - ${intlMaxDeliveryFee})`;
  };

  mbv = () => {
    const { isDelivery } = this.props.store.deliveryAddressStore;
    const { minPrice } = this.props.store.basketStore;
    const { areaCode } = this.props.store.restaurantStore;

    const {
      mbwMinPrice,
      mbwMaxPrice,
      intlMinPrice
    } = this.props.store.basketStore;

    if ((minPrice !== 0 && areaCode) || !isDelivery) {
      return intlMinPrice;
    }

    return mbwMinPrice === mbwMaxPrice
      ? mbwMinPrice
      : `(${mbwMinPrice} - ${mbwMaxPrice})`;
  };

  cell = (title, value, dataTestIdTitle, dataTestIdValue) => (
    <div className="cell" data-testid="deliv-info-cell">
      <div className="title" data-testid={dataTestIdTitle}>
        {title}
      </div>
      <div className="value" data-testid={dataTestIdValue}>
        {value}
      </div>
    </div>
  );

  table = () => {
    const { isDelivery } = this.props.store.deliveryAddressStore;

    const {
      getAverageDeliveryTime,
      getMaxSelfcollectTime
    } = this.props.store.restaurantStore.branch;

    return (
      <div className="table">
        {isDelivery &&
          !!getAverageDeliveryTime &&
          this.cell(
            this.props.t('delivery_info:lblAverageDeliveryTime'),
            this.props.t('store_is_closed_modal:minutes', {
              minutes: getAverageDeliveryTime
            }),
            'deliv-info-deltime-title',
            'deliv-info-detime-value'
          )}

        {isDelivery &&
          !!getMaxSelfcollectTime &&
          this.cell(
            this.props.t('delivery_info:lblMaxPickupTime'),
            this.props.t('store_is_closed_modal:minutes', {
              minutes: getMaxSelfcollectTime
            }),
            'deliv-info-picktime-title',
            'deliv-info-picktime-value'
          )}
        {isDelivery &&
          this.cell(
            this.props.t('basket:deliveryFeeSplit'),
            this.delivery(),
            'deliv-info-fee-title',
            'deliv-info-fee-value'
          )}
        {this.cell(
          this.props.t('delivery_info:lblMBV'),
          this.mbv(),
          'deliv-info-mbv-title',
          'deliv-info-mbv-value'
        )}
      </div>
    );
  };

  _renderHeader = () => (
    <HeaderShell
      title={this.props.t('delivery_info:lblDeliveryInfo')}
      style={this.props.store.themesStore.headerStyle()}
      backAction={this.backAction}
      isPortalAppThemeActive={
        this.props.store.themesStore.isPortalAppThemeActive
      }
      branchName={this.props.store.restaurantStore.branch.branchName}
    />
  );

  _renderContent = () => (
    <div id="delivery-info-page-wrapper">{this.table()}</div>
  );

  render() {
    return (
      <ShellLayout
        shellHeader={this._renderHeader()}
        shellContent={this._renderContent()}
      />
    );
  }
}

export default withTranslation([
  'opening_hours',
  'enter_address_modal',
  'delivery_info',
  'basket'
])(DeliveryInfoPage);
