import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { DAYJS_FORMAT } from '../../../../../enums/dayjs.enum';

// CSS

import classNames from 'classnames';
import './_coupon-basket-Item.scss';

@withRouter
@observer
class CouponBasketItem extends React.Component {
  static propTypes = {
    filteredCouponValue: PropTypes.string.isRequired,
    calculateCouponSaleValueWithOffers: PropTypes.number.isRequired,
    coupon: PropTypes.object.isRequired,
    getIntlPrice: PropTypes.func.isRequired
  };

  render() {
    const {
      filteredCouponValue,
      calculateCouponSaleValueWithOffers,
      coupon
    } = this.props;

    return (
      <div id="coupon-basket-item">
        <div className="coupon-title">
          <i className="fas fa-certificate" />
          {this.props.t('lblCoupon')}
        </div>
        <div className="coupon-value">
          <div className="item" data-testid="coupon-perc-basket">
            {this.props.t('lblCouponValue', { coupon: filteredCouponValue })}
          </div>
          <div className="cost" data-testid="coupon-dis-basket">
            -
            {this.props.t('common:cmnPrice', {
              price: this.props.getIntlPrice(calculateCouponSaleValueWithOffers)
            })}
          </div>
        </div>
        <div
          className={classNames({
            'coupon-valid-until': true,
            hidden: coupon.valid_until === null
          })}
        >
          {this.props.t('lblCouponValidUntil', {
            date: dayjs.unix(coupon.valid_until).format(DAYJS_FORMAT.dateFormat)
          })}
        </div>
        <div
          className="coupon-remove"
          onClick={() => this.props.removeCouponAction()}
        >
          {this.props.t('btnRemoveCoupon')}
        </div>
      </div>
    );
  }
}

export default withTranslation(['coupons', 'common'])(CouponBasketItem);
