// Imports
import React, { PureComponent } from 'react';

import { ELEMENTS_TYPES } from '../../../../../../../enums/homepage_elements_types.enum';
import { createHomepageHrefLink } from '../../../../../../../utils/functions';
// Styles
import './styles.scss';

export default class HomepageTextMobile extends PureComponent {
  /**
   * Method to render title of image/text section
   * @param title - {string} section title
   * @returns {*}
   * @private
   */
  _renderTitle = (title) => <h2>{title}</h2>;

  /**
   * Method to render text block of image/text section
   * @param text - {string} section text
   * @returns {*}
   * @private
   */
  _renderText = (text) => (
    <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
  );

  /**
   * Method to render image block of image/text section
   * @param image - {string} image src
   * @param title - {string} section title
   * @returns {*}
   * @private
   */
  _renderImage = (image, title) => (
    <div className="img-wrapper">
      <img src={image} alt={title} />
    </div>
  );

  /**
   * Method to render text/image section item
   * @param item - {object} image/text item
   * @returns {*}
   * @private
   */
  _renderSection = (item) => (
    <div className="homepage-text-mobile" key={item.id}>
      {item.title && this._renderTitle(item.title)}
      {item.description && this._renderText(item.description)}
      {item.picUrl && this._renderImage(item.picUrl, item.title)}
      {item.description2 && this._renderText(item.description2)}
    </div>
  );

  render() {
    return (
      <section className="mobile-homepage-section">
        {this.props.content.map((item) => this._renderSection(item))}
      </section>
    );
  }
}
