// Imports
import { inject } from 'mobx-react';
import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';

// Utils
// Config
import { ELEMENTS_TYPES } from '../../../../../../../enums/homepage_elements_types.enum';
import states from '../../../../../../../enums/states.enum';
import { createHomepageHrefLink } from '../../../../../../../utils/functions';
import { generateLinkFor } from '../../../../../../../utils/routing';

// Styles
import './styles.scss';
import { withTranslation } from 'react-i18next';

/**
 * Component to render categories on mobile homepage
 */
@inject('store')
@withRouter
class HomepageCategoriesDesktop extends PureComponent {
  /**
   * Method to render single category
   * @param category - Category object
   * @returns {*}
   * @private
   */
  _renderItem = (category) => {
    const linkAddress = category.parentCategoryId
      ? `${states.cat}/${category.parentCategoryId}/${category.category_id}`
      : `${states.cat}/${category.category_id}`;

    const link = generateLinkFor(linkAddress, this.props, {}, true);

    return (
      <div className="item-wrapper" key={category.category_id}>
        <Link to={link} className="item">
          <div className="img-wrapper">
            {category.picUrl && (
              <img src={category.picUrl} alt={category.name} />
            )}
          </div>
          <span className="title">{category.name}</span>
        </Link>
      </div>
    );
  };

  render() {
    const { index, categories, t } = this.props;

    return (
      <section
        className="desktop-homepage-section desktop-homepage-categories"
        id={createHomepageHrefLink([ELEMENTS_TYPES.CATEGORIES, index])}
      >
        <h2>{t('common:ourDishes')}</h2>
        <div className="list">
          {categories.map((category) => this._renderItem(category))}
        </div>
      </section>
    );
  }
}

export default withTranslation()(HomepageCategoriesDesktop);
