import classnames from 'classnames';
import React, { memo } from 'react';

import Select2 from 'client/ui/Select2';
import Text from 'client/ui/Text';

import css from './SelectField.module.scss';
import { SelectFieldProps } from './SelectField.type';

const SelectField = <T,>({
  className,
  label = '',
  error = '',
  dataTestId,
  ...selectProps
}: SelectFieldProps<T>) => {
  const classes = classnames(css.container, className, {
    [css.hasError]: !!error
  });

  return (
    <label className={css.SelectField} data-testid={dataTestId}>
      {label && <Text className={css.label}>{label}</Text>}
      <div className={classes}>
        <Select2 error={!!error} {...selectProps} />
      </div>
      <Text className={css.error}>{error}</Text>
    </label>
  );
};

export { SelectField };

export default memo(SelectField) as typeof SelectField;
