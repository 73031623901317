import { toJS } from 'mobx';

/**
 * News model
 */
export default class NewsModel {
  constructor(news) {
    Object.assign(this, news);
    this.id = news.id;
    this.title = news.title;
    this.content = news.content;
    this.date = news.created;
    this.image = news.imagelink;
    this.url = news.extraurl;
  }

  id;

  title = '';

  content = '';

  date = 0;

  image = '';

  url = '';

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  getToJS() {
    return toJS(Object.assign({}, this, { root: {} }));
  }
}
