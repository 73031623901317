import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

/**
 * Component to render checkout delivery message
 */
class CheckoutDeliveryTypeTitle extends React.PureComponent {
  static propTypes = {
    isDelivery: PropTypes.bool.isRequired
  };

  static defaultProps = {};

  render() {
    const message = this.props.isDelivery
      ? `(${this.props.t('enter_address_modal:delivery')})`
      : `(${this.props.t('enter_address_modal:selfcollect')})`;

    return (
      <div className="checkout-title" data-testid="checkout-title-basket">
        {this.props.t('yourOrder')}
        <span>{message}</span>
      </div>
    );
  }
}

export default withTranslation([
  'order_payment_methods',
  'enter_address_modal'
])(CheckoutDeliveryTypeTitle);
