import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

// CSS
import './_discount-block.scss';

class DiscountPercentageMobile extends PureComponent {
  static propTypes = {
    isShell: PropTypes.bool,
    value: PropTypes.number,
    discount: PropTypes.number.isRequired,
    discountValue: PropTypes.string,
    style: PropTypes.object
  };

  static defaultProps = {
    isShell: false
  };

  _renderMobileDiscount = (discountAmount) => {
    const { t } = this.props;

    return (
      <div id="discount">
        <i className="fas fa-certificate" data-testid="discount-main-scr" />
        <span>{t('discount', { amount: discountAmount })}</span>
      </div>
    );
  };

  _renderShellDiscount = (discountAmount) => {
    const { t } = this.props;

    return (
      <div
        id="discount"
        className={classNames({
          discount: true,
          shell: true,
          hasValue: this.props.discountValue
        })}
        style={this.props.style}
      >
        <span>
          <i
            className="fas fa-certificate"
            data-testid="discount-percent-basket"
          />
          {t('discount', { amount: discountAmount })}
        </span>
        {this.props.discountValue ? (
          <div className="value">{this.props.discountValue}</div>
        ) : null}
      </div>
    );
  };

  render() {
    const { discount } = this.props;
    const discountAmount = discount.toString().replace('.', ',');

    return this.props.isShell
      ? this._renderShellDiscount(discountAmount)
      : this._renderMobileDiscount(discountAmount);
  }
}

export default withTranslation('basket')(DiscountPercentageMobile);
