import React, { PureComponent } from 'react';
import ReactPropTypes from 'prop-types';

/**
 * Component to render product meta data
 */
export default class ProductMetaData extends PureComponent {
  static propTypes = {
    product: ReactPropTypes.object.isRequired,
    currency: ReactPropTypes.string.isRequired
  };

  render() {
    const { currency, product } = this.props;
    return (
      <div
        itemID={product.id}
        itemScope={true}
        itemType="http://schema.org/Product"
        className="hidden"
      >
        <meta itemProp="description" content={product.description} />
        <meta itemProp="image" content={product.pic_url} />
        <meta itemProp="name" content={product.name} />
        <div
          itemProp="offers"
          itemScope={true}
          itemType="http://schema.org/Offer"
        >
          <meta itemProp="priceCurrency" content={currency} />
          <meta itemProp="price" content={product.price} />
          <meta itemProp="availability" content="http://schema.org/InStock" />
        </div>
      </div>
    );
  }
}
