import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

// Config
import states from '../../../../../../enums/states.enum';
// Utils
import { goBackInHermes } from '../../../../../../utils/native-apps-communication';
import { generateLinkFor } from '../../../../../../utils/routing';
// CSS
import './CategoriesList.scss';
import StickyHeaderMobile from '../../../header/mobile/sticky-header';
import { CategoryMenuSlider } from '../category_menu_slider/CategoryMenuSlider';

@inject('store')
@withRouter
@observer
/**
 * Component for render mobile categories list
 */
export default class CategoriesListComponent extends Component {
  constructor(props) {
    super(props);

    this.props.store.categoryMenuStore.toggleExpanding(true);
  }

  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    onCategoryClick: PropTypes.func
  };

  static defaultProps = {
    onCategoryClick() {
      return null;
    }
  };

  componentWillUnmount() {
    this.props.store.categoryMenuStore.toggleExpanding(false);
  }

  _onCategoryClick() {
    this.props.store.categoryMenuStore.toggleExpanding(false);
  }

  render() {
    const { history, match } = this.props;

    const {
      categoryMenuStore,
      themesStore,
      restaurantStore
    } = this.props.store;

    const {
      activeCategory,
      categories,
      setFirstCategoryActive
    } = categoryMenuStore;

    const { setFixedStyleState, isPortalWebThemeActive } = themesStore;
    const { branch, restaurant, isSlugSupported } = restaurantStore;

    return (
      <div id="categories-modal-state">
        <div id="categories-list">
          <StickyHeaderMobile
            branchName={branch.branchName}
            setFixedStyleState={(state) => setFixedStyleState(state)}
            isHideMarketingData={restaurant.isHideMarketingData}
            isTableReservationAvailable={branch.branchIsTableReservation}
            setDefaultCategory={setFirstCategoryActive}
            isPortalWebThemeActive={isPortalWebThemeActive}
            backAction={() => goBackInHermes(history, match, isSlugSupported)}
          />

          <CategoryMenuSlider
            categories={categories}
            activeCategoryId={activeCategory.id}
            excludeCategoryIds={[-2]}
          />
          {categories.map((category) => (
            <div
              key={category.id}
              className={classNames({
                'category-wrapper': true,
                active: category.active
              })}
            >
              <Link
                className={classNames({
                  category: true
                })}
                onClick={() => this._onCategoryClick()}
                to={generateLinkFor(
                  `${states.cat}/${category.id}`,
                  this.props,
                  {},
                  true
                )}
              >
                <i className="fas fa-chevron-right" />
                <span className="name">{category.name}</span>
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
