import { toJS } from 'mobx';

/**
 * Template model
 */
export default class TemplateVariableModel {
  constructor(template) {
    Object.assign(this, template);
    this.code = template.code;
    this.value = template.value;
    this.variableType = template.variableType;
  }

  code;
  value;
  variableType;

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  getToJS() {
    return toJS(Object.assign({}, this, { root: {} }));
  }
}
