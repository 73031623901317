import { Checkbox } from 'antd';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { IContactlessComponentProps } from './props.interface';
import './ContactlessDeliveryComponent.scss';

@(withRouter as any)
@inject('store')
@observer
class ContactlessDeliveryComponent extends Component<IContactlessComponentProps> {
  /**
   * Toggle checkbox
   */
  toggleContactLessDelivery = () => {
    this.props.store.orderPaymentMethodsStore.toggleContactLessDelivery();
  };

  render() {
    const {
      isContactLessCheckboxChecked
    } = this.props.store.orderPaymentMethodsStore;

    const title = this.props.store.orderPaymentMethodsStore.contactLessTitle;

    const description = this.props.store.orderPaymentMethodsStore
      .contactLessDescription;

    return (
      <div
        className={classNames({
          'contactless-delivery-wrapper': true
        })}
        data-testid="contactless-basket"
      >
        <Checkbox
          defaultChecked={isContactLessCheckboxChecked}
          onClick={this.toggleContactLessDelivery}
          className="checkbox"
        >
          {title}
        </Checkbox>
        <div className="contactless-delivery-description">{description}</div>
      </div>
    );
  }
}

export default withTranslation(['order_checkout'])(
  ContactlessDeliveryComponent
);
