/* eslint-disable global-require,import/no-dynamic-require */
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import map from 'lodash/map';
import { action, computed, observable } from 'mobx';

import { dayjsConfiguration } from 'client/locales/dayjs.common';
import { LanguageModel } from 'client/models/language.model';
import RootStore from 'client/stores';
import { stripCountry } from 'client/utils/functions';
import { IS_CLIENT, defaultLanguage, supportedLanguages } from 'config';

import i18n from '../../../i18n';

/**
 * Languages class. Used to work with stores languages.
 * @constructor
 * @param {instance} api - {@link API} instance.
 * @property {array <object>} languages - Observable array of languages.
 */
export default class LanguagesStore {
  api: any;

  root: RootStore;

  constructor(root, state, api) {
    this.api = api;

    this.root = root;

    if (state.languages && state.languages.length > 0) {
      this.languages = state.languages.map(
        (lang) => new LanguageModel(lang.id, lang)
      );
    }
  }

  @observable languages = [];

  /**
   * Method to full languages from object and create instance for each language automatically.
   * @param {object} [languages = {}] - Languages set. See {@link LanguageModel} class.
   * @param {object} store
   * @memberof LanguagesStore#
   * @method prepareLanguages
   */
  @action prepareLanguages(languages = {}, store) {
    this.languages = map(
      languages,
      (language, id) => new LanguageModel(id, language)
    ).filter((i) => {
      if (store.restaurantStore.restaurant.getLang === 'at') {
        return i.id === 'de';
      }

      return i.id === store.restaurantStore.restaurant.getLang;
    });
  }

  /**
   * Method to set language as active.
   * @param {string} id - id of the language.
   * @memberof LanguagesStore#
   * @method setActiveLanguage
   */
  @action setActiveLanguage(id) {
    const branchLangCode = stripCountry(id);

    this.languages.forEach((language) => {
      language.active = language.id === branchLangCode;
    });

    const getLanguageCode = () => {
      const cookiesLanguageCode = Cookies.get('currentLanguageCode');
      const browserLangCode = i18n.language?.slice(0, 2);
      const languageCodes = supportedLanguages.map((lang) => lang.code);

      const possibleLanguages = [
        cookiesLanguageCode,
        browserLangCode,
        branchLangCode,
        defaultLanguage
      ];

      return possibleLanguages.find((lang) => languageCodes.includes(lang));
    };

    const languageCode = getLanguageCode();

    if (IS_CLIENT) {
      i18n.changeLanguage(languageCode);
    }

    require(`dayjs/locale/${languageCode}.js`);

    dayjs.locale(languageCode);

    dayjs.updateLocale(languageCode, dayjsConfiguration());

    if (branchLangCode) {
      this.api.setLanguage(branchLangCode);
    }
  }

  /**
   * Method to find an active language in the current store.
   * @return {object | undefined} active language
   * @memberof LanguagesStore#
   * @method activeLanguage
   */
  @computed get activeLanguage() {
    return this.languages.find((i) => i.active);
  }

  /**
   * Method to determine if there is some languages in store.
   * @return {boolean} existence of languages in the store
   * @memberof LanguagesStore#
   * @method hasLanguages
   */
  @computed get hasLanguages() {
    return !!this.languages.length;
  }

  /**
   * Method that returns count of languages supported in current store.
   * @return {number} languages count
   * @memberof LanguagesStore#
   * @method languagesCount
   */
  @computed get languagesCount() {
    return this.languages.length;
  }
}
