import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { matchPath, withRouter } from 'react-router-dom';

// Enums
import MetadataTypes from '../../enums/metadata.enums';
import { getIsSlugSupported } from '../../utils/routing';
import BranchSeo from '../seo/branch-seo';
import CustomerSeo from '../seo/customer-seo';

@withRouter
@inject('store')
@observer
class Metadata extends Component {
  static propTypes = {
    metadataType: PropTypes.string.isRequired
  };

  getRobots = () => {
    const {
      store: {
        restaurantStore: { hostName }
      },
      location: { pathname, search }
    } = this.props;

    return matchPath(pathname, {
      path: getIsSlugSupported(hostName) ? '/:slug/:branchId/' : '/:branchId',
      exact: true
    }) && !search
      ? 'index, follow'
      : 'noindex';
  };

  _loadingPage() {
    return <Helmet title="" />;
  }

  _termsPage() {
    return <CustomerSeo title={this.props.t('footer:termsOfUse')} />;
  }

  _imprintPage() {
    return <CustomerSeo title={this.props.t('footer:legalInfo')} />;
  }

  _privacyPolicyPage() {
    return <CustomerSeo title={this.props.t('footer:dsvgoTitle')} />;
  }

  _maintenanceMode() {
    return (
      <CustomerSeo
        title={this.props.t('maintenance_mode:maintenanceModeTitle')}
      />
    );
  }

  _shopMigrated() {
    return (
      <CustomerSeo
        title={this.props.t('shop_moved:shopMovedTitle')}
      />
    );
  }

  _errorPage() {
    return <CustomerSeo title={this.props.t('order_payment_methods:error')} />;
  }

  _activeCategory() {
    const {
      store: {
        categoryMenuStore: { activeCategory }
      }
    } = this.props;

    return activeCategory ? (
      <BranchSeo
        title={activeCategory.name}
        isCategory
        robots={this.getRobots()}
      />
    ) : null;
  }

  _activeSubCategory() {
    const { activeCategory } = this.props.store.categoryMenuStore || {};

    return <BranchSeo title={activeCategory.name} isSubcategory />;
  }

  _startPage() {
    return (
      <CustomerSeo
        title={this.props.t('title:startPageTitle')}
        robots="index, follow"
      />
    );
  }

  _tableReservation() {
    return <BranchSeo title={this.props.t('footer:btnTableReservation')} />;
  }

  _contactForm() {
    return <CustomerSeo title={this.props.t('footer:contactUs')} />;
  }

  _additiveList() {
    return <BranchSeo title={this.props.t('title:allergensAdditives')} />;
  }

  render() {
    // Disable for no shell mode
    if (this.props.store.themesStore.isShellThemeActive) {
      return null;
    }

    const { metadataType } = this.props;

    switch (metadataType) {
      case MetadataTypes.SHOP_MOVED:
        return this._shopMigrated();
      case MetadataTypes.MAINTENANCE_MODE:
        return this._maintenanceMode();
      case MetadataTypes.ERROR_PAGE:
        return this._errorPage();
      case MetadataTypes.ACTIVE_CATEGORY:
        return this._activeCategory();
      case MetadataTypes.TERMS_PAGE:
        return this._termsPage();
      case MetadataTypes.IMPRINT_PAGE:
        return this._imprintPage();
      case MetadataTypes.PRIVACY_POLICY_PAGE:
        return this._privacyPolicyPage();
      case MetadataTypes.LOADING_PAGE:
        return this._loadingPage();
      case MetadataTypes.START_PAGE:
        return this._startPage();
      case MetadataTypes.TABLE_RESERVATION:
        return this._tableReservation();
      case MetadataTypes.CONTACT_FORM:
        return this._contactForm();
      case MetadataTypes.ACTIVE_SUBCATEGORY:
        return this._activeSubCategory();
      case MetadataTypes.ADDITIVE_LIST:
        return this._additiveList();
      default:
        return null;
    }
  }
}

export default withTranslation([
  'common',
  'footer',
  'maintenance_mode',
  'order_payment_methods',
  'title',
  'footer'
])(Metadata);
