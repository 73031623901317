import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import { ReactComponent as Hunger } from 'client/icons/hunger-logo.svg';
import { ReactComponent as OrderSmart } from 'client/icons/order-smart-logo.svg';
import { ModalsRouteType } from 'client/routes/ModalsRoute/ModalsRoute.type';
import openModal from 'client/utils/openModal';

import languagesImages from '../../../../enums/images_enums/languages_images.enum';
import {
  APP_STORE_IMAGES,
  GOOGLE_PLAY_IMAGES
} from '../../../../enums/images_enums/mobile_apps_images.enum';
import './footer.scss';

/**
 * Footer component is used to show company information,
 * ordersmart logo and links to download android and ios applications.
 * @name ReactFooterComponent
 * @property {object} settings - settings object with info about branch and links to phone applications.
 */
@withRouter
@inject('store')
@observer
class Footer extends Component {
  static defaultProps = {
    isTableReservationAvailable: false,
    isStartPage: false,
    branch: {}
  };

  /**
   * Method for render address block
   * @param {string} title - block title
   * @param {BranchModel} branch - current branch
   * @param {string} countryPrefix - country code
   * @returns {*}
   */
  _renderAddressBlock = (title, branch, countryPrefix) =>
    !branch.isHideRestaurantInfoFromFooter ? (
      <ul className="item">
        <li>{title}</li>
        {branch.getStreet && branch.getStreetNo && (
          <li>{`${branch.getStreet} ${branch.getStreetNo}`}</li>
        )}
        {countryPrefix && branch.getCityCode && branch.getCity && (
          <li>{`${countryPrefix}-${branch.getCityCode} ${branch.getCity}`}</li>
        )}
      </ul>
    ) : null;

  handleLinkClick = (event) => {
    event.stopPropagation();
  };

  /**
   * Method for render contact block
   * @param {BranchModel} branch - current branch
   * @returns {*}
   */
  _renderContactsBlock = (branch) => (
    <ul className="item">
      {branch.getOwnername && !branch.isHideOwnerInfoFromFooter && (
        <li>
          {this.props.t('owner')} {branch.getOwnername}
        </li>
      )}
      {branch.getShopmanagerName &&
        branch.getShopmanagerName !== branch.getOwnername &&
        !branch.isHideOwnerInfoFromFooter && (
          <li>
            {this.props.t('managing', { name: branch.getShopmanagerName })}
          </li>
        )}
      {branch.getVatin && !branch.isHideRestaurantInfoFromFooter && (
        <li>
          {' '}
          {this.props.t('taxNumber', {
            tax: branch.getVatin,
            interpolation: { escapeValue: false }
          })}{' '}
        </li>
      )}
      {branch.getRegistrationNumber && !branch.isHideRestaurantInfoFromFooter && (
        <li>
          {this.props.t('registrationNumber', {
            number: branch.getRegistrationNumber
          })}
        </li>
      )}
    </ul>
  );

  /**
   * Method for render footer navigation menu block
   * @param {boolean} isTableReservationAvailable - Table Reservation feature toogle
   * @param {string} facebookUrl - url to fb page
   * @param {boolean} isBranchSelected - true if branch selected
   * @returns {*}
   */
  _renderNavigationMenu = (
    isTableReservationAvailable,
    facebookUrl,
    isBranchSelected
  ) => {
    const { showPrivacyPolicy } = this.props.store.restaurantStore;

    return (
      <ul className="item">
        {isTableReservationAvailable && (
          <li>
            <Link
              onClick={this.handleLinkClick}
              to={openModal(ModalsRouteType.TABLE_RESERVATION)}
            >
              {this.props.t('footer:btnTableReservation')}
            </Link>
          </li>
        )}
        {facebookUrl && (
          <li>
            <a href={facebookUrl} target="blank">
              {this.props.t('becomeFan')}
            </a>
          </li>
        )}
        {isBranchSelected && (
          <li>
            <Link
              to={openModal(ModalsRouteType.CONTACT_MODAL)}
              onClick={this.handleLinkClick}
            >
              {this.props.t('contactUs')}
            </Link>
          </li>
        )}

        <li>
          <Link
            onClick={this.handleLinkClick}
            to={openModal(ModalsRouteType.IMPRINT_MODAL)}
          >
            {this.props.t('legalInfo')}
          </Link>
        </li>
        {showPrivacyPolicy ? (
          <li>
            <Link
              to={openModal(ModalsRouteType.PRIVACY_MODAL)}
              onClick={this.handleLinkClick}
            >
              {this.props.t('dsvgoTitle')}
            </Link>
          </li>
        ) : null}
        <li>
          <Link
            to={openModal(ModalsRouteType.TERMS_MODAL)}
            onClick={this.handleLinkClick}
          >
            {this.props.t('termsOfUse')}
          </Link>
        </li>
      </ul>
    );
  };

  /**
   * Method for render OrderSmart logo info block
   * @param {string} link - link to order smart website
   * @param {string} text - OrderSmart ads text
   * @param {boolean} isHideMarketingData - is order smart logo should be hidden
   * @returns {*}
   */
  _renderShopSystemInfo = (orderSmartLink, text, isHideMarketingData) => {
    const { store } = this.props;
    const { isPortalWebThemeActive } = store.themesStore;
    const link = isPortalWebThemeActive ? 'https://hunger.de/' : orderSmartLink;

    if (isHideMarketingData) {
      return null;
    }

    return (
      <div className="shopsystem-info-wrapper">
        <a href={link} target="blank">
          {isPortalWebThemeActive ? (
            <Hunger className="logo" />
          ) : (
            <OrderSmart className="logo" />
          )}
        </a>
        <a href={link} target="blank">
          <div className="text">{text}</div>
        </a>
      </div>
    );
  };

  /**
   * Method for render apps block in footer
   * @param {boolean} hasPlayStore - is Google PlayStore app available
   * @param {boolean} hasAppStore - is AppStore app available
   * @param {string} playStoreUrl - Google PlayStore app link
   * @param {string} appStoreUrl - AppStore app link
   * @param {string} language - current language
   * @returns {*}
   */
  _renderAppsBlock = (
    hasPlayStore,
    hasAppStore,
    playStoreUrl,
    appStoreUrl,
    language
  ) => {
    const languageParent = languagesImages[language].parent;

    return (
      <>
        {hasPlayStore && (
          <div className="mobile-app">
            <a href={playStoreUrl} target="blank">
              <img
                alt="Google Play app"
                className="img-responsive"
                src={GOOGLE_PLAY_IMAGES[languageParent]}
              />
            </a>
          </div>
        )}
        {hasAppStore && (
          <div className="mobile-app">
            <a href={appStoreUrl} target="blank">
              <img
                alt="App Store app"
                className="img-responsive"
                src={APP_STORE_IMAGES[languageParent]}
              />
            </a>
          </div>
        )}
      </>
    );
  };

  /**
   * Method to render first column with apps links
   * @param {boolean} hasApps - is restaurant has apps links
   * @param {boolean} hasPlayStore - is has PlayMarket link
   * @param {boolean} hasAppStore - is has AppStore link
   * @param {string} playStoreUrl - PlayMarket url
   * @param {string} appStoreUrl - AppStore url
   * @param {string} language - language
   * @returns {null}
   * @private
   */
  _renderAppsColumn = (
    hasApps,
    hasPlayStore,
    hasAppStore,
    playStoreUrl,
    appStoreUrl,
    language
  ) =>
    hasApps ? (
      <div className="footer-column desktop-only">
        <div className="title">{this.props.t('useOurApp')}</div>
        <div className="mobile-apps-wrapper">
          {this._renderAppsBlock(
            hasPlayStore,
            hasAppStore,
            playStoreUrl,
            appStoreUrl,
            language
          )}
        </div>
      </div>
    ) : null;

  /**
   * Method render menu column
   * @param {boolean} isTableReservationAvailable - is table reservation available in restaurant
   * @param {string} facebookUrl - facebook url link
   * @param {boolean} isBranchSelected - is branch was selected
   * @param {string} getSeoOrdersmartLink - seo ordersmart link
   * @param {string} getSeoOrdersmartLinkText - seo ordersmart link text
   * @param {boolean} isHideLogoFromFooter - is ordersmart logo should be hidden
   * @returns {*}
   * @private
   */
  _renderMenuColumn = (
    isTableReservationAvailable,
    facebookUrl,
    isBranchSelected,
    getSeoOrdersmartLink,
    getSeoOrdersmartLinkText,
    isHideLogoFromFooter
  ) => (
    <div className="footer-column desktop-only">
      <div className="title">{this.props.t('information')}</div>
      {this._renderNavigationMenu(
        isTableReservationAvailable,
        facebookUrl,
        isBranchSelected
      )}
      {this._renderShopSystemInfo(
        getSeoOrdersmartLink,
        getSeoOrdersmartLinkText,
        isHideLogoFromFooter
      )}
    </div>
  );

  /**
   * Method to render contacts column
   * @param {boolean} hasContactInfo - is has contacts
   * @param {string} restaurantName - restaurant name
   * @param {BranchModel} branch - branch item
   * @param {string} countryPrefix - country code
   * @returns {null}
   * @private
   */
  _renderContactsColumn = (
    hasContactInfo,
    restaurantName,
    branch,
    countryPrefix,
    areaCode
  ) => {
    const {
      onlyCustomerImprint,
      customerWithAppSmartImprint
    } = this.props.store.restaurantStore;

    let contactsContent = null;

    if (onlyCustomerImprint) {
      contactsContent = (
        <>
          {(!branch.isHideOwnerInfoFromFooter ||
            !branch.isHideRestaurantInfoFromFooter) && (
            <div className="title">{this.props.t('legalInfo')}</div>
          )}
          {this._renderAddressBlock(restaurantName, branch, countryPrefix)}
          {this._renderContactsBlock(branch)}
        </>
      );
    } else if (customerWithAppSmartImprint) {
      contactsContent = this.renderDeliveryServiceInfo(
        restaurantName,
        branch,
        areaCode
      );
    }

    return hasContactInfo ? (
      <div className="footer-column">{contactsContent}</div>
    ) : null;
  };

  /**
   * Method to render information about delivery service
   * @param {string} restaurantName - restaurant name
   * @param {BranchModel} branch - branch item
   * @param {string} areaCode - postal code
   */
  renderDeliveryServiceInfo = (restaurantName, branch, areaCode) => (
    <ul className="item">
      <div className="title">
        {this.props.t('delivery_info:deliveryService')}
      </div>
      {restaurantName && <li>{restaurantName}</li>}
      {branch.getStreet && branch.getStreetNo && (
        <li>{`${branch.getStreet} ${branch.getStreetNo}`}</li>
      )}
      {areaCode && branch.getCity && <li>{`${areaCode} ${branch.getCity}`}</li>}
    </ul>
  );

  render() {
    const {
      restaurant,
      isTableReservationAvailable,
      isStartPage,
      branch
    } = this.props;

    const {
      hasApps,
      hasPlayStore,
      hasAppStore,
      playStoreUrl,
      appStoreUrl,
      getLang,
      facebookUrl,
      getSeoOrdersmartLink,
      getSeoOrdersmartLinkText,
      getTitle,
      getCountryPrefix
    } = restaurant;

    const hasContactInfo = !!branch.hasContactInfo;
    const isBranchSelected = this.props.match.params.branchId;
    const hasAllColumn = hasApps && hasContactInfo;

    const branchName =
      branch.companyNameImprint || branch.branchName || getTitle;

    return (
      <footer id="footer" className="desktop-footer">
        <div
          className={classNames({
            container: true,
            small: isStartPage && !hasAllColumn,
            'footer-wrapper': true,
            'one-column': isStartPage && !hasContactInfo && !hasApps
          })}
        >
          {this._renderAppsColumn(
            hasApps,
            hasPlayStore,
            hasAppStore,
            playStoreUrl,
            appStoreUrl,
            getLang
          )}
          {this._renderMenuColumn(
            isTableReservationAvailable,
            facebookUrl,
            isBranchSelected,
            getSeoOrdersmartLink,
            getSeoOrdersmartLinkText,
            branch.isHideLogoFromFooter
          )}
          {this._renderContactsColumn(
            hasContactInfo,
            branchName,
            branch,
            getCountryPrefix,
            branch.getCityCode
          )}

          {!isStartPage && !isBranchSelected && (
            <div className="spacer desktop-only" />
          )}
          {!isStartPage && !hasContactInfo && (
            <div className="spacer desktop-only" />
          )}
        </div>
      </footer>
    );
  }
}

export default withTranslation(['footer', 'delivery_info'])(Footer);
