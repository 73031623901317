/**
 * Negative extras pricing models
 */
enum NEGATIVE_EXTRAS_PRICING_MODELS {
  NONE = 'None',
  FAIR = 'Fair',
  EXACT = 'Exact'
}

export default NEGATIVE_EXTRAS_PRICING_MODELS;
