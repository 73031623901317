/**
 * Shell category view types
 */
enum SHELL_CATEGORY_VIEW_TYPE {
  DEFAULT = 'Default',
  HORIZONTAL = 'HorizontalWithImages',
  HORIZONTAL_WITH_IMAGES_ALWAYS = 'HorizontalWithImagesAlways',
  HORIZONTAL_WITHOUT_IMAGES = 'HorizontalWithoutImages'
}

export default SHELL_CATEGORY_VIEW_TYPE;
