// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StreetAutocomplete_StreetAutocomplete__Rn_kp .StreetAutocomplete_poweredByGoogle__1DEW3 {\n  display: -ms-flexbox;\n  display: flex;\n  height: 24px;\n  -ms-flex-pack: end;\n      justify-content: flex-end;\n  padding: 5px 10px; }\n  .StreetAutocomplete_StreetAutocomplete__Rn_kp .StreetAutocomplete_poweredByGoogle__1DEW3 img {\n    height: 100%;\n    text-align: right; }\n", "",{"version":3,"sources":["webpack://src/client/components/StreetAutocomplete/StreetAutocomplete.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAa;EAAb,aAAa;EACb,YAAY;EACZ,kBAAyB;MAAzB,yBAAyB;EACzB,iBAAiB,EAAE;EACnB;IACE,YAAY;IACZ,iBAAiB,EAAE","sourcesContent":[".StreetAutocomplete .poweredByGoogle {\n  display: flex;\n  height: 24px;\n  justify-content: flex-end;\n  padding: 5px 10px; }\n  .StreetAutocomplete .poweredByGoogle img {\n    height: 100%;\n    text-align: right; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StreetAutocomplete": "StreetAutocomplete_StreetAutocomplete__Rn_kp",
	"poweredByGoogle": "StreetAutocomplete_poweredByGoogle__1DEW3"
};
export default ___CSS_LOADER_EXPORT___;
