/* eslint-disable camelcase */
import address_form from './address_form.json';
import allergens from './allergens.json';
import basket from './basket.json';
import business_delivery from './business_delivery.json';
import common from './common.json';
import company_code_search from './company_code_search.json';
import contact_form from './contact_form.json';
import cookie from './cookie.json';
import coupons from './coupons.json';
import delivery_info from './delivery_info.json';
import enter_address_modal from './enter_address_modal.json';
import errors from './errors.json';
import extras_modal from './extras_modal.json';
import food_warning_list from './food_warning_list.json';
import footer from './footer.json';
import home from './home.json';
import maintenance_mode from './maintenance_mode.json';
import migration_modal from './migration_modal.json';
import modal_footer_common from './modal_footer_common.json';
import news from './news.json';
import not_found from './not_found.json';
import offers from './offers.json';
import opening_hours from './opening_hours.json';
import order_checkout from './order_checkout.json';
import order_payment_methods from './order_payment_methods.json';
import pickup_address_info from './pickup_address_info.json';
import preorder_possible_modal from './preorder_possible_modal.json';
import product_view from './product_view.json';
import seo from './seo.json';
import startPageTitle from './startPageTitle.json';
import store_is_closed_modal from './store_is_closed_modal.json';
import table_reservation from './table_reservation.json';
import title from './title.json';
import validationErrors from './validationErrors.json';
import widgets from './widgets.json';

export default {
  address_form,
  basket,
  business_delivery,
  enter_address_modal,
  product_view,
  common,
  contact_form,
  delivery_info,
  extras_modal,
  offers,
  food_warning_list,
  footer,
  home,
  maintenance_mode,
  modal_footer_common,
  not_found,
  opening_hours,
  order_checkout,
  order_payment_methods,
  pickup_address_info,
  preorder_possible_modal,
  store_is_closed_modal,
  title,
  company_code_search,
  coupons,
  widgets,
  table_reservation,
  allergens,
  errors,
  news,
  seo,
  startPageTitle,
  validationErrors,
  cookie,
  migration_modal
};
