import { action, computed, observable } from 'mobx';

import DEVICES from 'client/enums/device.enum';
import themeImages from 'client/enums/images_enums/default_theme_images.enums';
import SHELL_CATEGORY_VIEW_TYPE from 'client/enums/shell_category_view_types.enum';
import SHELL_HOMEPAGE_BUTTONS from 'client/enums/shell_homepage_buttons_type.enum';
import SHELL_HOMEPAGE_TYPE from 'client/enums/shell_homepage_type.enum';
import states from 'client/enums/states.enum';
import templatesCodes from 'client/enums/template_codes.enum';
import TemplateVariablesTypes from 'client/enums/template_varaiables_types.enum';
import TEMPLATE_VARIABLES_TYPES from 'client/enums/template_variables_types.enum';
import categoriesTypes from 'client/enums/templates/categories_template_type.enums';
import { COMPONENTS_TEMPLATE_TYPE } from 'client/enums/templates/components_template_type.enum';
import themeTypes from 'client/enums/themes.enum';
import TemplateResponseModel from 'client/models/template_response.model';
import Storage from 'client/modules/storage';
import RootStore from 'client/stores';
import { PortalTemplate } from 'client/themes/theme-hermes/theme-hermes-portal/portal-template';
import { isLightColor } from 'client/utils/functions';
import { theme } from 'config';

import { PLATFORM } from '../../enums/platform.enum';

/**
 * Class for themes and appearances manipulations.
 * @property {bool} isFixedStyleActive - is Fixed style active
 * @constructor
 */
export default class ThemesStore {
  root: RootStore;

  storage: Storage;

  api: any;

  constructor(root, state, api, storage) {
    Object.assign(this, state);

    this.root = root;

    this.api = api;

    this.storage = storage;

    if (state.currentTheme) {
      this.currentTheme = state.currentTheme;
    }

    if (state.platform) {
      this.platform = state.platform;
    }

    if (state.device) {
      this.device = state.device;
    }
  }

  @observable currentMobileState = states.app;

  @observable themeStyle;

  @observable currentTheme = themeTypes.HERMES;

  @observable components = {};

  @observable platform = '';

  @observable showAppUpdateButton = false;

  @observable device = '';

  @observable width = '';

  @observable isFixedStyleActive = false;

  @observable isSafari = false;

  @observable browser = '';

  @observable shellTitle = '';

  @observable shellBackButtonAction;

  @observable sidebarOpen = false;

  @observable designId = undefined;

  @observable templateVariables = [];

  @observable isScreenshotModeActive = false;

  @observable isIOSDevice = false;

  @observable iOSKeyboardHeight = null;

  @observable clickedProductTopOffset = null;

  @observable scrollableListPaddingTop = 0;

  @observable lastScrolledSliderElementIndex = 0;

  @observable virtualListSizes = null;

  @observable postHeaderBranchInfoMobileHeight = 0;

  @observable templateLoaded = false;

  @observable headerIsCollapsed = true;

  /**
   * If device has a such width, it's a tablet one
   */
  TABLET_WIDTH = 768;

  /**
   * Set loading status of templating
   */
  @action
  setTemplateLoadingStatus(state = false) {
    this.templateLoaded = state;
  }

  /**
   * Set if user open in safari
   * @param {bool} isSafari
   * @memberof ThemesStore
   */
  @action setIsSafari(isSafari) {
    this.isSafari = isSafari;
  }

  /**
   * Set if user open in iOS
   * @param {bool} isIOS
   */
  @action setIsIOS(isIOS) {
    this.isIOSDevice = isIOS;
  }

  /**
   * Set device type desktop/mobile
   * @param device
   */
  @action setDevice(device) {
    this.device = device;

    this.width = device === DEVICES.MOBILE ? '1023' : '1024';
  }

  /**
   * Set name of user browser
   * @param {string} browser
   * @memberof ThemesStore
   */
  @action setBrowser(browser) {
    this.browser = browser;
  }

  /**
   * Set keyboard height for iOS
   * @param {number} height
   * @memberof ThemesStore
   */
  @action setIOSKeyboardHeight(height = null) {
    this.iOSKeyboardHeight = height !== null ? Number(height) : null;
  }

  /**
   * Set Screenshot Mode state
   * @param state
   */
  @action setScreenshotModeState(state = false) {
    this.isScreenshotModeActive = state;
  }

  @action changeMobileState(state) {
    this.currentMobileState = state;
  }

  @action changeThemeStyle(data) {
    this.themeStyle = data;
  }

  @action setComponents(data) {
    this.components = data;
  }

  /**
   * Method to set current platform
   * @param {PLATFORM} platform - current platform
   */
  @action setCurrentThemeForPlatform(platform) {
    this.setPlatform(platform);

    switch (platform) {
      case PLATFORM.ANDROID:
      case PLATFORM.IOS:
      case PLATFORM.PORTAL_APP:
        this.currentTheme = themeTypes.HERMES_SHELL;

        break;
      case PLATFORM.PORTAL_WEB:
        this.root.deliveryAddressStore.changePortal(true);

        this.root.deliveryAddressStore.checkAddressForPortal();

        this.currentTheme = themeTypes.HERMES;

        break;
      default:
        this.currentTheme = themeTypes.HERMES;

        break;
    }
  }

  /**
   * Method to set platform of device
   * @param platform
   */
  @action setPlatform(platform) {
    this.platform = platform;
  }

  /**
   * Method to show app update button
   * @param showUpdateButton
   */
  @action setShowAppUpdateButton(showAppUpdateButton: boolean) {
    this.showAppUpdateButton = showAppUpdateButton;
  }

  /**
   * Method to show side bar
   */
  @action showSidebar() {
    this.sidebarOpen = true;
  }

  /**
   * Method to hide side bar
   */
  @action hideSidebar() {
    this.sidebarOpen = false;
  }

  /**
   * Method to set shell title
   * @param {string} title - title in shell
   * @param {function} backButtonAction - action to click back button
   */
  @action setShellTitle(title = '', backButtonAction = null) {
    this.shellTitle = title;

    this.shellBackButtonAction = backButtonAction;
  }

  /**
   * Method for set fixed style
   * @param state
   */
  @action setFixedStyleState(state) {
    this.isFixedStyleActive = state;
  }

  /**
   * Method for set design id
   * @param {number} designId
   */
  @action setDesignId(designId) {
    this.designId = designId;
  }

  /**
   * Post header - top block on the responsive view with restaurant information about logo, working hours, type of delivery, MBV and so on
   * @param {number} height
   */
  @action setPostHeaderHeight(height) {
    this.postHeaderBranchInfoMobileHeight = height;
  }

  @action setHeaderIsCollapsed(value: boolean): void {
    this.headerIsCollapsed = value;
  }

  /**
   * Method to get is modal state
   * @returns {boolean}
   */
  @computed get isMobile() {
    if (this.isHunger) {
      return true;
    }

    return this.device === DEVICES.MOBILE;
  }

  /**
   * Method to get is shell theme active state
   * @returns {boolean}
   */
  @computed get isShellThemeActive() {
    return (
      this.platform === PLATFORM.IOS ||
      this.platform === PLATFORM.ANDROID ||
      this.platform === PLATFORM.PORTAL_APP
    );
  }

  /**
   * Method to get is portal app theme active state
   * @returns {boolean}
   */
  @computed get isPortalAppThemeActive() {
    return this.platform === PLATFORM.PORTAL_APP;
  }

  /**
   * Method to get is portal web theme active state
   * @returns {boolean}
   */
  @computed get isPortalWebThemeActive() {
    return this.platform === PLATFORM.PORTAL_WEB;
  }

  /**
   * Method to get link to main background
   * @return {string}
   * @memberof ThemesStore#
   * @method mainBackgroundLink
   */
  @computed get mainBackgroundLink() {
    return this.root.restaurantStore.restaurant.getIsDefaultTheme
      ? null
      : this.root.restaurantStore.restaurant.getBackgroundPicurl;
  }

  /**
   * Method to get link to main fullscreen background
   * @return {string}
   * @memberof ThemesStore#
   * @method mainBackgroundFullScreenPicurl
   */
  @computed get mainBackgroundFullScreenPicurl() {
    return this.root.restaurantStore.restaurant.getIsDefaultTheme
      ? null
      : this.root.restaurantStore.restaurant.getBackgroundFullScreenPicurl;
  }

  /**
   * Method to get link to left background
   * @return {string}
   * @memberof ThemesStore#
   * @method rightBackgroundLink
   */
  @computed get rightBackgroundLink() {
    return this.root.restaurantStore.restaurant.getIsDefaultTheme
      ? themeImages.right_bg
      : this.root.restaurantStore.restaurant.getBackgroundRightPicurl;
  }

  /**
   * Method to get link to left background
   * @return {string}
   * @memberof ThemesStore#
   * @method leftBackgroundLink
   */
  @computed get leftBackgroundLink() {
    return this.root.restaurantStore.restaurant.getIsDefaultTheme
      ? themeImages.left_bg
      : this.root.restaurantStore.restaurant.getBackgroundLeftPicurl;
  }

  /**
   * Method to get current theme
   * @return {string}
   * @memberof ThemesStore#
   */
  @computed get theme() {
    return this.root.restaurantStore.restaurant.getTheme || theme;
  }

  /**
   * Method to get current category component template
   * @return {string}
   */
  @computed get categoryTheme() {
    const categoryType = this.root.restaurantStore.restaurant.getCategoriesType;

    if (this.theme === themeTypes.HERMES) {
      switch (categoryType) {
        case categoriesTypes.NO_PICS:
          return COMPONENTS_TEMPLATE_TYPE.HERMES_CATEGORIES_NO_PICS;
        case categoriesTypes.WITH_PICS:
          return COMPONENTS_TEMPLATE_TYPE.HERMES_CATEGORIES_WITH_PICS;
        default:
          return COMPONENTS_TEMPLATE_TYPE.HERMES_CATEGORIES_NO_PICS;
      }
    }
  }

  /**
   * Should be basket button showed on homepage
   * @returns {boolean}
   */
  @computed get showBasketButtonHomepageShell() {
    return (
      this.root.restaurantStore.branch.shellHomepageScreenType !==
      SHELL_HOMEPAGE_TYPE.WITH_DELIVERY_PICKUP_BUTTONS
    );
  }

  /**
   * Should be delivery area switch showed on homepage
   * @returns {boolean}
   */
  @computed get showDeliveryAreaSwitchHomepageShell() {
    return (
      this.root.restaurantStore.branch.shellHomepageScreenType !==
      SHELL_HOMEPAGE_TYPE.WITH_DELIVERY_PICKUP_BUTTONS
    );
  }

  /**
   * Returns type of homepage bottom buttons
   * @returns {string}
   */
  @computed get typeHomepageShellBottomButtons() {
    if (
      this.root.restaurantStore.branch.shellHomepageScreenType ===
      SHELL_HOMEPAGE_TYPE.WITH_DELIVERY_PICKUP_BUTTONS
    ) {
      return SHELL_HOMEPAGE_BUTTONS.DELIVERY_PICKUP_BUTTONS;
    }

    return SHELL_HOMEPAGE_BUTTONS.CIRCLE_3_BUTTONS;
  }

  @computed get isHunger() {
    return this.isPortalAppThemeActive || this.isPortalWebThemeActive;
  }

  @computed get getHeaderIsCollapsed(): boolean {
    return this.headerIsCollapsed;
  }

  /**
   * Method for getting template color
   * @param colorCode
   * @param isAssetVariable - if true then ignore checking colors variables
   * @returns {string}
   */
  getTemplateVariable = (colorCode, isAssetVariable = false) => {
    if (!this.isTemplateAvailable && !isAssetVariable) {
      return {};
    }

    const colorVariable =
      this.templateVariables &&
      this.templateVariables.find((color) => color.code === colorCode);

    return colorVariable || {};
  };

  /**
   * Method for calculating text color
   * @returns {string|undefined}
   */
  templateTextColor = (backgroundColor = { value: '#ffffff' }) => {
    if (!this.isTemplateAvailable) {
      return undefined;
    }

    // Requirements from the designer
    switch ((backgroundColor as any).code) {
      case TemplateVariablesTypes.NEGATIVE:
      case TemplateVariablesTypes.POSITIVE:
        return '#ffffff';
      case TemplateVariablesTypes.SHADE2:
        return this.templateTextColor(
          this.getTemplateVariable(TemplateVariablesTypes.BRAND)
        );
      default:
        return isLightColor(backgroundColor.value || '#ffffff')
          ? '#000000'
          : '#ffffff';
    }
  };

  /**
   * Is json template available
   * @returns {boolean}
   */
  @computed get isTemplateAvailable() {
    const isContainsTemplateVars =
      !!(
        this.templateVariables &&
        this.templateVariables.find(
          (item) =>
            item.variableType === TEMPLATE_VARIABLES_TYPES.TEMPLATE_VARIABLE
        )
      ) &&
      !!this.templateVariables.find(
        (item) => item.code === TemplateVariablesTypes.BRAND
      );

    return isContainsTemplateVars && this.isShellThemeActive;
  }

  /**
   * Categories horizontal view type for shell
   * @returns {boolean}
   */
  @computed get isShellCategoryHorizontalType() {
    const categoriesTypeVariable = this.getTemplateVariable(
      TemplateVariablesTypes.SHELLCATEGORIESTYPE,
      true
    );

    return (
      categoriesTypeVariable.value === SHELL_CATEGORY_VIEW_TYPE.HORIZONTAL ||
      categoriesTypeVariable.value ===
        SHELL_CATEGORY_VIEW_TYPE.HORIZONTAL_WITHOUT_IMAGES ||
      categoriesTypeVariable.value ===
        SHELL_CATEGORY_VIEW_TYPE.HORIZONTAL_WITH_IMAGES_ALWAYS
    );
  }

  /**
   * Show category pictures on horizontal menu always on shell
   */
  @computed get showMenuCategoryImagesAlwaysShell() {
    return (
      this.isShellCategoryHorizontalType &&
      this.getTemplateVariable(TemplateVariablesTypes.SHELLCATEGORIESTYPE, true)
        .value === SHELL_CATEGORY_VIEW_TYPE.HORIZONTAL_WITH_IMAGES_ALWAYS
    );
  }

  /**
   * Never show category pictures on horizontal menu on shell
   */
  @computed get hideMenuCategoryImagesShell() {
    return (
      this.isShellCategoryHorizontalType &&
      this.getTemplateVariable(TemplateVariablesTypes.SHELLCATEGORIESTYPE, true)
        .value === SHELL_CATEGORY_VIEW_TYPE.HORIZONTAL_WITHOUT_IMAGES
    );
  }

  /**
   * Hide basket button on horizontal view of products on the shell
   * @returns {boolean}
   */
  @computed get hideBasketButtonHorizontalMenuShell() {
    const { hasProducts } = this.root.basketStore;

    return this.isShellCategoryHorizontalType && !hasProducts;
  }

  /**
   * Method for loading template by design Id or active template
   * @return {promise} information about template
   */
  @action loadTemplate() {
    return this.designId
      ? this._parseTemplate(
          this.api
            .getDesignTemplateById(
              this.designId,
              templatesCodes.HERMES_SHELL_TEMPLATE
            )
            .then((res) => res)
            .catch(() => null)
        )
      : this._parseTemplate(
          this.api
            .getActiveDesignTemplate(templatesCodes.HERMES_SHELL_TEMPLATE)
            .then((res) => res)
            .catch(() => null)
        );
  }

  /**
   * Method for clearing templating variables
   */
  @action clearTemplate() {
    this.templateVariables = [];
  }

  /**
   * Method to set offset of last clicked product from category mobile list on the responsive view and horizontal menu on shell
   */
  @action setClickedProductTopOffset(offset = null) {
    this.clickedProductTopOffset = offset;
  }

  @action saveScrollableListPaddingTop(paddingTop = 0) {
    this.scrollableListPaddingTop = paddingTop;
  }

  @action setLastScrolledSliderElementIndex(index = 0) {
    this.lastScrolledSliderElementIndex = index;
  }

  /**
   * Method to save sizes of rendered virtual list elements
   */
  @action setVirtualListSizes(elementsSizes = null) {
    this.virtualListSizes = elementsSizes;
  }

  /**
   * Method for parsing templating request result
   * @param {promise} response
   */
  _parseTemplate = async (response) => {
    try {
      const result = await response;
      const content = new TemplateResponseModel(result);

      if (content.variables) {
        this.templateVariables = content.variables;

        this.applyPortalVariables();
      }

      this.setTemplateLoadingStatus(true);

      return Promise.resolve();
    } catch (err) {
      this.setTemplateLoadingStatus(true);

      return Promise.reject(err || 'Something went wrong to load template');
    }
  };

  /**
   * Wrapper style
   * @returns {{color: string, background: string}}
   */
  wrapperStyle = () => {
    const backgroundColor = this.getTemplateVariable(
      TemplateVariablesTypes.COMPLEMENTARY1
    );

    return {
      color: this.templateTextColor(backgroundColor),
      background: backgroundColor.value
    };
  };

  /**
   * Background of shell Homepage With Delivery Pickup Buttons
   * @returns {string}
   */
  shellHomepageWithDeliveryPickupButtonsBackground = () =>
    this.getTemplateVariable(TemplateVariablesTypes.COMPLEMENTARY1).value;

  /**
   * Button Order style
   * @returns {{color: string, background: string}}
   */
  orderButtonStyle = () => {
    const backgroundColor = this.isPortalAppThemeActive
      ? this.getTemplateVariable(TemplateVariablesTypes.POSITIVE2)
      : this.getTemplateVariable(TemplateVariablesTypes.BRAND);

    const textColor = this.isPortalAppThemeActive
      ? '#fff'
      : this.templateTextColor(backgroundColor);

    return {
      color: textColor,
      background: backgroundColor.value
    };
  };

  /**
   * Button standard style
   * @returns {{color: string, background: string}}
   */
  standardButtonStyle = () => {
    const backgroundColor = this.getTemplateVariable(
      TemplateVariablesTypes.COMPLEMENTARY3
    );

    return {
      color: this.templateTextColor(backgroundColor),
      background: backgroundColor.value
    };
  };

  /**
   * Button standard active style
   * @returns {{color: string, background: string}}
   */
  standardButtonActiveStyle = () => {
    const backgroundColor = this.getTemplateVariable(
      TemplateVariablesTypes.BRAND
    );

    return {
      color: this.templateTextColor(backgroundColor),
      background: backgroundColor.value
    };
  };

  /**
   * Button standard disabled style
   */
  standardButtonDisabledStyle = () => ({
    color: this.getTemplateVariable(TemplateVariablesTypes.COMPLEMENTARY1)
      .value,
    background: this.getTemplateVariable(TemplateVariablesTypes.COMPLEMENTARY3)
      .value
  });

  /**
   * Button prices style
   * @returns {{color: string, background: string}}
   */
  productPriceStyleButtonStyle = () => {
    const backgroundColor = this.getTemplateVariable(
      TemplateVariablesTypes.COMPLEMENTARY3
    );

    return {
      color: this.templateTextColor(backgroundColor),
      background: backgroundColor.value
    };
  };

  /**
   * Product badge style
   */
  productBadgeStyle = () => {
    const backgroundColor = this.getTemplateVariable(
      TemplateVariablesTypes.NEGATIVE
    );

    return {
      color: this.templateTextColor(backgroundColor),
      background: backgroundColor.value
    };
  };

  buttonSquareCountStyle = () => {
    const backgroundColor = this.getTemplateVariable(
      TemplateVariablesTypes.COMPLEMENTARY3
    );

    return {
      color: this.templateTextColor(backgroundColor),
      background: backgroundColor.value
    };
  };

  /**
   * Button size style
   * @returns {{standard: {color: string, background: string}, active: {color: string, background: string}}}
   */
  sizeStyleButtonStyle = () => {
    const complementary3BackgroundColor = this.getTemplateVariable(
      TemplateVariablesTypes.COMPLEMENTARY3
    );

    const shade1BackgroundColor = this.getTemplateVariable(
      TemplateVariablesTypes.SHADE1
    );

    return {
      standard: {
        color: this.templateTextColor(complementary3BackgroundColor),
        background: complementary3BackgroundColor.value
      },
      active: {
        color: this.templateTextColor(shade1BackgroundColor),
        background: shade1BackgroundColor.value
      }
    };
  };

  /**
   * Button ingredient style
   * @returns {{standard: {color: string, background: string}, active: {color: string, background: string}}}
   */
  ingredientButtonStyle = () => {
    const complementary3BackgroundColor = this.getTemplateVariable(
      TemplateVariablesTypes.COMPLEMENTARY3
    );

    const shade1BackgroundColor = this.isPortalAppThemeActive
      ? this.getTemplateVariable(TemplateVariablesTypes.SHADE3)
      : this.getTemplateVariable(TemplateVariablesTypes.SHADE1);

    return {
      standard: {
        color: this.templateTextColor(complementary3BackgroundColor),
        background: complementary3BackgroundColor.value
      },
      active: {
        color: this.templateTextColor(shade1BackgroundColor),
        background: shade1BackgroundColor.value
      }
    };
  };

  /**
   * Homepage buttons bar
   * @returns {{color: string}}
   */
  buttonsBarStyle = () => {
    const color = this.getTemplateVariable(TemplateVariablesTypes.SHADE1);

    return {
      background: this.getTemplateVariable(
        TemplateVariablesTypes.COMPLEMENTARY1
      ).value,
      color: color.value,
      borderColor: color.value
    };
  };

  /**
   * Homepage buttons bar
   * @returns {{color: string}}
   */
  buttonsWideBarStyle = () => {
    const buttonsBarStyle = this.buttonsBarStyle();

    return {
      ...buttonsBarStyle,
      borderColor: `${this.templateTextColor({
        value: buttonsBarStyle.background
      })}1A`
    };
  };

  /**
   * Homepage shell delivery/pickup buttons bar
   * @returns {{color: string}}
   */
  shellDeliveryPickupButtonsBar = () => ({
    background: this.getTemplateVariable(TemplateVariablesTypes.COMPLEMENTARY1)
      .value
  });

  /**
   * Discount strip style
   * @returns {{color: string, background: string}}
   */
  discountStyle = () => {
    const backgroundColor = this.isPortalAppThemeActive
      ? this.getTemplateVariable(TemplateVariablesTypes.BRAND2)
      : this.getTemplateVariable(TemplateVariablesTypes.NEGATIVE);

    return {
      color: this.templateTextColor(backgroundColor),
      background: backgroundColor.value
    };
  };

  /**
   * Delivery switch style
   * @returns {{button: {color: string, opacity: number}, buttonSelected: {color: string, background: string}, wrapper: {borderColor: string}}}
   */
  deliverySwitchStyle = () => {
    const shade1Color = this.getTemplateVariable(TemplateVariablesTypes.SHADE1);

    return {
      wrapper: {
        borderColor: shade1Color.value
      },
      buttonSelected: {
        color: this.templateTextColor(shade1Color),
        background: shade1Color.value
      },
      button: {
        color: shade1Color.value,
        opacity: this.isTemplateAvailable ? 0.5 : undefined
      }
    };
  };

  /**
   * Postal code selector style
   * @returns {{color: string}}
   */
  postalCodeStyle = () => ({
    color: this.templateTextColor(
      this.getTemplateVariable(TemplateVariablesTypes.COMPLEMENTARY1)
    )
  });

  /**
   * Header style
   * @returns {{color: string, background: string}}
   */
  headerStyle = () => {
    const backgroundColor = this.getTemplateVariable(
      TemplateVariablesTypes.BRAND
    );

    return {
      color: this.templateTextColor(backgroundColor),
      background: backgroundColor.value
    };
  };

  /**
   * Side menu template colors
   * @returns {{color: string, background: string}}
   */
  sideMenuStyle = () => {
    const backgroundColor = this.isPortalAppThemeActive
      ? this.getTemplateVariable(TemplateVariablesTypes.BRAND)
      : this.getTemplateVariable(TemplateVariablesTypes.SHADE2);

    return {
      color: this.templateTextColor(backgroundColor),
      background: backgroundColor.value,
      borderColor: `${this.templateTextColor(backgroundColor)}33`
    };
  };

  /**
   * Cell products list separator
   * @returns {{borderBottomColor: string}}
   */
  separatorStyle = () => ({
    borderBottomColor: `${this.templateTextColor(
      this.getTemplateVariable(TemplateVariablesTypes.COMPLEMENTARY2)
    )}1A`
  });

  /**
   * Default icon on product picture styles
   */
  defaultProductPictureStyle = () => {
    const templateTextColor = this.templateTextColor();

    return {
      defaultPictureIconColor: templateTextColor
        ? `${templateTextColor}33`
        : '',
      defaultPictureBackgroundColor: templateTextColor
        ? `${templateTextColor}0D`
        : ''
    };
  };

  /**
   * Product cell shell style
   */
  productCellStyle = () => ({
    ...this.defaultProductPictureStyle(),
    headerTextColor: this.templateTextColor(),
    descriptionOpacity: 0.5,
    additivesOpacity: 0.5,
    productNumberBorderColor: `${this.templateTextColor()}1A`,
    productNumberTextColor: `${this.templateTextColor()}80`
  });

  /**
   * Category name style
   * @returns {{color: string, background: string}}
   */
  categoryNameStyle = () => ({
    color: this.isTemplateAvailable ? '#ffffff' : undefined,
    background: this.isTemplateAvailable ? 'rgba(0,0,0,0.6)' : undefined
  });

  /**
   * Category title style
   * @returns {{color: string, background: string}}
   */
  categoryTitleStyle = () => ({
    color: this.isTemplateAvailable ? '#ffffff' : undefined
  });

  /**
   * Header style
   * @returns {{color: string, background: string}}
   */
  categoriesListStyle = () => ({
    color: this.getTemplateVariable(TemplateVariablesTypes.SHADE1).value,
    background: this.getTemplateVariable(TemplateVariablesTypes.COMPLEMENTARY2)
      .value,
    borderColor: 'transparent'
  });

  /**
   * Category row style
   */
  categoryRowStyle = () => {
    const categoriesListStyle = this.categoriesListStyle();

    return {
      color: categoriesListStyle.color
        ? this.templateTextColor({ value: categoriesListStyle.background })
        : null
    };
  };

  /**
   * Branch list style
   * @returns {{closed: {color: string}, active: {color: string}, branch: {color: string}, open: {color: string}}}
   */
  branchListStyle = () => ({
    branch: {
      color: this.templateTextColor(
        this.getTemplateVariable(TemplateVariablesTypes.COMPLEMENTARY1)
      )
    },
    open: {
      color: this.getTemplateVariable(TemplateVariablesTypes.POSITIVE).value
    },
    closed: {
      color: this.getTemplateVariable(TemplateVariablesTypes.NEGATIVE).value
    },
    active: {
      color: this.getTemplateVariable(TemplateVariablesTypes.POSITIVE).value
    }
  });

  /**
   * Required block style
   */
  requiredBlockStyle = () => {
    const backgroundColor = this.isPortalAppThemeActive
      ? this.getTemplateVariable(TemplateVariablesTypes.BRAND2)
      : this.getTemplateVariable(TemplateVariablesTypes.NEGATIVE);

    return {
      background: backgroundColor.value,
      color: this.templateTextColor(backgroundColor)
    };
  };

  /**
   * Positive coloring style
   */
  positiveStyle = () => ({
    color: this.getTemplateVariable(TemplateVariablesTypes.POSITIVE).value
  });

  /**
   * Modal header style
   * @returns {{title: {color: string, background: string}}}
   */
  modalHeaderStyle = () => ({
    title: {
      color: this.getTemplateVariable(TemplateVariablesTypes.SHADE1).value,
      background: this.getTemplateVariable(
        TemplateVariablesTypes.COMPLEMENTARY1
      ).value
    }
  });

  /**
   * Category header on horizontal menu
   */
  categoryHeaderHorizontalMenu = () => {
    const backgroundColor = this.getTemplateVariable(
      TemplateVariablesTypes.COMPLEMENTARY2
    );

    return {
      titleBackground: backgroundColor.value,
      color: this.templateTextColor(backgroundColor)
    };
  };

  /**
   * Horizontal menu font styles
   */
  horizontalMenuProductStyle = () => ({
    ...this.defaultProductPictureStyle(),
    color: this.templateTextColor(),
    productNumberBorderColor: `${this.templateTextColor()}1A`,
    productNumberTextColor: `${this.templateTextColor()}80`,
    descriptionOpacity: 0.5,
    additivesOpacity: 0.3
  });

  /**
   * General css
   * @returns {string}
   */
  commonCss = () => {
    const complementary2BackgroundColor = this.getTemplateVariable(
      TemplateVariablesTypes.COMPLEMENTARY2
    );

    const headerBlock = this.requiredBlockStyle();

    return `
        .accordion-item__event-panel {
          background-color: ${complementary2BackgroundColor.value} !important;
          color: ${this.templateTextColor(
            complementary2BackgroundColor
          )} !important;
        }
        .accordion-item__event-panel.ingredients-required {
          background-color: ${headerBlock.background} !important;
          color: ${headerBlock.color} !important;
        }
    `;
  };

  /**
   * Current active branch style
   * @returns {{color: string, background: string}}
   */
  activeBranchStyle = () => ({
    color: this.getTemplateVariable(TemplateVariablesTypes.COMPLEMENTARY1)
      .value,
    background: this.getTemplateVariable(TemplateVariablesTypes.SHADE1).value
  });

  /**
   * Offer title style
   * @returns {{color: string, background: string}}
   */
  offerTitleStyle = () => {
    const backgroundColor = this.getTemplateVariable(
      TemplateVariablesTypes.BRAND
    );

    return {
      color: this.templateTextColor(backgroundColor),
      background: this.getTemplateVariable(TemplateVariablesTypes.BRAND).value
    };
  };

  /**
   * Payment methods style
   * @returns {{activeRadio: {color: string, background: string}, defaultSpan: {border: string}}}
   */
  paymentMethodStyle = () => {
    const backgroundColor = this.isPortalAppThemeActive
      ? this.getTemplateVariable(TemplateVariablesTypes.POSITIVE2)
      : this.getTemplateVariable(TemplateVariablesTypes.BRAND);

    return {
      defaultSpan: {
        border: `1px solid ${backgroundColor.value}`
      },
      activeRadio: {
        color: this.templateTextColor(backgroundColor),
        background: backgroundColor.value
      }
    };
  };

  /**
   * All blocks which need have to be positive or negative status
   */
  restaurantStatus = () => ({
    positive: {
      background: this.getTemplateVariable(TemplateVariablesTypes.POSITIVE)
        .value,
      color: this.templateTextColor(
        this.getTemplateVariable(TemplateVariablesTypes.POSITIVE)
      )
    },
    negative: {
      background: this.getTemplateVariable(TemplateVariablesTypes.NEGATIVE)
        .value,
      color: this.templateTextColor(
        this.getTemplateVariable(TemplateVariablesTypes.NEGATIVE)
      )
    }
  });

  /**
   * All negative text (errors ex.) needs be colored into this color
   */

  negativeText = () => ({
    color: this.getTemplateVariable(TemplateVariablesTypes.NEGATIVE).value
  });

  /**
   * getter for logo form template variable
   * @return {string}
   */
  get shellTemplateVarLogo() {
    return this.getTemplateVariable(TemplateVariablesTypes.SHELLAPPLOGO, true)
      .value;
  }

  /**
   * getter for offsets (margins and paddings) form template variable
   * @return {string}
   */
  get shellTemplateVarLogoOffsets() {
    let padding = this.getTemplateVariable(
      TemplateVariablesTypes.SHELLAPPLOGOPADDING,
      true
    ).value;

    padding = padding ? `${padding.split(',').join('px ')}px` : undefined;

    let margin = this.getTemplateVariable(
      TemplateVariablesTypes.SHELLAPPLOGOMARGIN,
      true
    ).value;

    margin = margin ? `${margin.split(',').join('px ')}px` : undefined;

    return {
      margin,
      padding
    };
  }

  /**
   * getter for start page background  form template variable
   * @return {string}
   */
  get shellTemplateVarHomeBackground() {
    return this.getTemplateVariable(
      TemplateVariablesTypes.SHELLAPPHOMEBACKGROUND,
      true
    ).value;
  }

  /**
   * getter for category background  form template variable
   * @return {string}
   */
  get shellTemplateVarCategoryBackground() {
    return this.getTemplateVariable(
      TemplateVariablesTypes.SHELLAPPCATEGORIESBACKGROUND,
      true
    ).value;
  }

  applyPortalVariables() {
    if (this.isPortalAppThemeActive) {
      let allVariables = this.templateVariables;

      PortalTemplate.forEach((variable) => {
        allVariables = allVariables.filter(
          (item) => item.code !== variable.code
        );
      });

      this.templateVariables = [...allVariables, ...PortalTemplate];
    }
  }

  setRootVars() {
    const root = document.documentElement;
    const wrapperVars = this.wrapperStyle();
    const standardButtonVars = this.standardButtonStyle();
    const orderButtonVars = this.orderButtonStyle();
    const buttonDisabledVars = this.standardButtonDisabledStyle();

    if (this.isTemplateAvailable) {
      root.style.setProperty(
        '--hermesModalsBackground',
        wrapperVars.background
      );

      root.style.setProperty(
        '--buttonBackground',
        standardButtonVars.background
      );

      root.style.setProperty(
        '--buttonBackgroundHover',
        standardButtonVars.background
      );

      root.style.setProperty(
        '--buttonBackgroundDisabled',
        buttonDisabledVars.background
      );

      root.style.setProperty('--buttonTextColor', standardButtonVars.color);

      root.style.setProperty(
        '--buttonTextColorHover',
        standardButtonVars.color
      );

      root.style.setProperty(
        '--buttonTextColorDisabled',
        buttonDisabledVars.color
      );

      root.style.setProperty(
        '--buttonOrderBackground',
        orderButtonVars.background
      );

      root.style.setProperty(
        '--buttonOrderBackgroundHover',
        orderButtonVars.background
      );

      root.style.setProperty(
        '--buttonOrderBackgroundDisabled',
        buttonDisabledVars.background
      );

      root.style.setProperty('--buttonOrderTextColor', orderButtonVars.color);

      root.style.setProperty(
        '--buttonOrderTextColorHover',
        orderButtonVars.color
      );

      root.style.setProperty(
        '--buttonOrderTextColorHover',
        buttonDisabledVars.color
      );

      root.style.setProperty(
        '--primaryColor',
        this.templateTextColor(wrapperVars.background)
      );
    }
  }
}
