import classNames from 'classnames';
import React, { Component, Fragment } from 'react';
import Measure from 'react-measure';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import Truncate from 'react-truncate';

import images from '../../../../../../../enums/images_enums/hermes_images.enum';

import IProductTitleProps from './props.interface';
import './ProductTitle.scss';

interface IState {
  width: number;
  productDescriptionNeedTruncate: boolean;
}

export default class ProductTitle extends Component<
  IProductTitleProps,
  IState
> {
  constructor(props: IProductTitleProps) {
    super(props);

    this.state = {
      width: 0,
      productDescriptionNeedTruncate: true
    };
  }

  /**
   * Render title block
   */
  renderTitle = () => {
    const titleNumberStyle = this.props.styles
      ? this.props.styles.titleNumberStyle
      : undefined;

    return (
      <>
        {this.props.no && (
          <p className="product-title-number" style={titleNumberStyle}>
            {this.props.no}
          </p>
        )}
        {this.renderTitleIcons()}
        <span className="product-title-text" data-testid="product-title">
          {this.props.text}
        </span>
      </>
    );
  };

  /**
   * Render icons in title block
   */
  renderTitleIcons = () => {
    const { showMBVIcon, showDiscountIcon } = this.props;

    return (
      <>
        {showMBVIcon && (
          <ReactSVG
            src={images.iconWithoutMinOrder}
            beforeInjection={(svg) => {
              svg.setAttribute(
                'class',
                `${svg.getAttribute('class')} product-title-icon`
              );

              svg.setAttribute('style', 'width: 20px; height: 20px;');
            }}
          />
        )}
        {showDiscountIcon && (
          <ReactSVG
            src={images.iconWithoutDiscount}
            beforeInjection={(svg) => {
              svg.setAttribute(
                'class',
                `${svg.getAttribute('class')} product-title-icon`
              );

              svg.setAttribute('style', 'width: 20px; height: 20px;');
            }}
          />
        )}
      </>
    );
  };

  render() {
    const { lines, no, text, children } = this.props;
    const isMultiLineTitle = lines && lines > 1;

    const ellipsisTruncate = isMultiLineTitle ? (
      <span>
        {'… '}
        {children}
      </span>
    ) : null;

    const titleNumberStyle = this.props.styles
      ? this.props.styles.titleNumberStyle
      : undefined;

    return (
      <div className="product-title-container">
        <div
          className={classNames({
            'product-title-wrapper': true,
            'no-truncated-title': !isMultiLineTitle
          })}
        >
          {true ? (
            <Measure
              bounds
              onResize={(contentRect) => {
                contentRect &&
                  contentRect.bounds &&
                  contentRect.bounds.width !== this.state.width &&
                  this.setState({
                    productDescriptionNeedTruncate: true,
                    width: contentRect.bounds.width
                  });
              }}
            >
              {({ measureRef }) => (
                <>
                  {no && (
                    <p
                      className="product-title-number"
                      style={titleNumberStyle}
                    >
                      {no}
                    </p>
                  )}
                  {this.renderTitleIcons()}
                  <div ref={measureRef} className="measure-wrapper">
                    {this.state.productDescriptionNeedTruncate ? (
                      <Truncate
                        lines={lines}
                        trimWhitespace
                        ellipsis={ellipsisTruncate}
                        width={this.state.width}
                        onTruncate={(truncated: boolean) => {
                          !truncated &&
                            this.setState({
                              productDescriptionNeedTruncate: false
                            });
                        }}
                      >
                        {text}
                        {children}
                      </Truncate>
                    ) : (
                      <>
                        {text}
                        {children}
                      </>
                    )}
                  </div>
                </>
              )}
            </Measure>
          ) : (
            this.renderTitle()
          )}
          {!isMultiLineTitle ? children : null}
        </div>
      </div>
    );
  }
}
