/* eslint-disable no-bitwise */
const rgba2hex = (value) => {
  const rgba = value
    .replace(/\s/g, '')
    .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);

  const [_, r, g, b, a] = rgba;
  const alpha = a ?? 0o1;

  const hex = rgba
    ? (r | (1 << 8)).toString(16).slice(1) +
      (g | (1 << 8)).toString(16).slice(1) +
      (b | (1 << 8)).toString(16).slice(1)
    : value;

  // multiply before convert to HEX
  const alphaHex = ((alpha * 255) | (1 << 8)).toString(16).slice(1);

  return `#${hex}${alphaHex}`;
};

/**
 *
 * @param rgb - rgb string as rgb(255,255,255)
 */
const rgb2hex = (rgb: string) => {
  const matches = rgb.matchAll(/\d+/gi);
  const result: number[] = [];

  for (const match of matches) {
    const [value] = match;

    result.push(parseInt(value, 10));
  }

  const [r, g, b] = result;

  // eslint-disable-next-line no-bitwise
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

const hex2Rgb = (hex: string) => {
  const value = hex.replace('#', '');
  const bigint = parseInt(value, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgb(${r},${g},${b})`;
};

export { rgb2hex, rgba2hex, hex2Rgb };
