// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Text_Text__2q0SF {\n  font-family: Roboto, sans-serif;\n  font-size: 12px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n  text-align: left;\n  color: var(--secondaryTextColor); }\n", "",{"version":3,"sources":["webpack://src/client/ui/Text/Text.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,eAAe;EACf,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,gBAAgB;EAChB,gCAAgC,EAAE","sourcesContent":[".Text {\n  font-family: Roboto, sans-serif;\n  font-size: 12px;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.5;\n  letter-spacing: normal;\n  text-align: left;\n  color: var(--secondaryTextColor); }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Text": "Text_Text__2q0SF"
};
export default ___CSS_LOADER_EXPORT___;
