import React, { Ref, forwardRef, memo } from 'react';

import TextAreaField, { TextAreaFieldProps } from 'client/ui/TextAreaField';

const TextArea = forwardRef(
  (
    { className, ...rest }: TextAreaFieldProps,
    ref: Ref<HTMLTextAreaElement>
  ) => <TextAreaField className={className} textAreaRef={ref} {...rest} />
);

export { TextArea };

export default memo(TextArea);
