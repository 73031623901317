import { OrderRequestModel } from 'client/models/orderRequest.model';
import { apiPhpInt } from 'client/utils/api';

import { CreatePayPalResponse } from '../types';

const createPayPalTransaction = async (order: OrderRequestModel) => {
  const currentUrl = new URL(window.location.pathname, window.location.href);
  const returnUrl = `${currentUrl.href}?checkTransaction=true`;
  const cancelUrl = `${currentUrl.href}?paymentCanceled=true`;
  const paymentSystem = 'paypal';
  const request = { data: order, returnUrl, cancelUrl, paymentSystem };
  const url = '/api/v1/payments/create';
  const { data } = await apiPhpInt.post<CreatePayPalResponse>(url, request);

  return data;
};

export { createPayPalTransaction };
