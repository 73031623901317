import { action, computed, observable } from 'mobx';

import RootStore from 'client/stores';

export default class ErrorStore {
  root: RootStore;

  api: any;

  constructor(root, state, api) {
    Object.assign(this, state);

    this.root = root;

    this.api = api;
  }

  @observable errorState = {
    type: '',
    message: '',
    errorUrl: '',
    redirectUrl: '',
    statusCode: 200
  };

  @action setError(error) {
    this.errorState = {
      ...this.errorState,
      type: error.type,
      message: error.message,
      errorUrl: error.errorUrl,
      redirectUrl: error.redirectUrl,
      statusCode: error.statusCode
    };
  }

  @action clearErrorState() {
    this.errorState = {
      ...this.errorState,
      type: '',
      message: '',
      errorUrl: '',
      redirectUrl: '',
      statusCode: 200
    };
  }

  @computed get getErrorMessage() {
    return this.errorState.message;
  }
}
