import classNames from 'classnames';
import React, { Component } from 'react';

import { CategoryModel } from 'client/models/category_menu.model';

import { IHeaderProps } from './props.interface';
import './ProductListCategoryHeader.scss';

/**
 * Component to render category header in products list
 */
export default class ProductListCategoryHeader extends Component<IHeaderProps> {
  /**
   * Method to render description of category
   * @param {boolean} isMobile - is mobile theme active
   * @param {string} description - description of category
   * @returns {null}
   * @private
   */
  private _renderDescriptionBlock(description: string) {
    return description ? (
      <div className="description" style={this.props.style}>
        {description}
      </div>
    ) : null;
  }

  /**
   * Method to render title of category
   * @param {boolean} isHaveSubcategories - is category has subcategory
   * @param {CategoryModel} activeCategory - active category item
   * @param {CategoryModel} activeSubcategory - active subcategory item
   * @returns {*}
   * @private
   */
  private _renderTitleName(
    isHaveSubcategories: boolean,
    activeCategory: CategoryModel,
    activeSubcategory: CategoryModel
  ) {
    return isHaveSubcategories ? (
      <div className="name" style={this.props.style}>
        <div className="name-title" style={this.props.categoryTitleStyle}>
          {`${activeCategory.name} | ${activeSubcategory.name} `}
        </div>
      </div>
    ) : (
      <div className="name" style={this.props.style}>
        <div className="name-title" style={this.props.categoryTitleStyle}>
          {activeCategory.name}
        </div>
      </div>
    );
  }

  public render() {
    const {
      isHaveSubcategories,
      activeCategory,
      activeSubcategory,
      isMobile,
      isShellThemeActive,
      hasCategoryImage,
      isSearch
    } = this.props;

    const category = isHaveSubcategories ? activeSubcategory : activeCategory;
    const description = category ? category.description : '';

    const image =
      category.picurl_preview && isMobile
        ? category.picurl_preview
        : category.picurl;

    const style =
      hasCategoryImage && image ? { backgroundImage: `url(${image})` } : {};

    /**
     * additional category-title-hermes classes 'no-header-image' used if in no shell mode active (web/mobile web)
     * and hasCategoryImage is presented (only if category image is uploaded)
     * @type {{'no-header-image': boolean; 'category-title-container': boolean}}
     */
    // eslint-disable-next-line no-nested-ternary
    const noHeaderImage = isShellThemeActive
      ? false
      : image
      ? !hasCategoryImage
      : true;

    const titleClassNames = {
      'category-title-container': true,
      'no-header-image': noHeaderImage,
      'with-description': !!description,
      'is-search': isSearch
    };

    return (
      <div
        id="category-title-hermes"
        style={style}
        className={classNames({
          shell: isShellThemeActive,
          'no-header-image': noHeaderImage,
          'is-search': isSearch
        })}
      >
        <div className={classNames(titleClassNames)}>
          <div className="title">
            {this._renderTitleName(
              isHaveSubcategories,
              activeCategory,
              activeSubcategory as CategoryModel
            )}
          </div>
          {this._renderDescriptionBlock(description)}
        </div>
      </div>
    );
  }
}
