import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import HeaderShell from '../header-shell';
import ShellLayout from '../shell-layout';

import BranchList from './branch-list';

import './BranchListShell.scss';

/**
 * Component to render branch list in shell
 */
@withRouter
@inject('store')
@observer
class BranchListShell extends Component {
  static propTypes = {};

  static defaultProps = {};

  /**
   * Method to handle change branch action
   * @private
   */
  _changeBranchAction = () => {
    const { store } = this.props;
    const { deliveryAddressStore, productsStore } = store;

    deliveryAddressStore.setBranchSelectedStatus();

    productsStore.clearSearchingRequest();

    if (this.props.store.restaurantStore.isBranchSelectingSkipped) {
      this.props.store.categoryMenuStore.setShouldOpenCategoriesState(true);

      this.props.store.restaurantStore.setSkipBranchSelectingState(false);
    }
  };

  /**
   * Method to render branches list
   * @param {[Object]} branches - array of branches
   * @param {BranchModel} currentBranch - current branch
   * @param {boolean} isBranchSelectingSkipped - if branch not chosen
   * @param {string} hostName - current host name
   * @returns {*}
   * @private
   */
  _renderContent = (
    branches,
    currentBranch,
    isBranchSelectingSkipped,
    hostName
  ) => (
    <BranchList
      branches={branches}
      currentBranch={currentBranch}
      isBranchSelectingSkipped={isBranchSelectingSkipped}
      changeBranchAction={this._changeBranchAction}
      hostName={hostName}
      style={this.props.store.themesStore.branchListStyle()}
    />
  );

  /**
   * Method to render header
   * @returns {*}
   * @private
   */
  _renderHeader = () => (
    <HeaderShell
      title={this.props.t('opening_hours:lblBranchList')}
      style={this.props.store.themesStore.headerStyle()}
    />
  );

  render() {
    const {
      isBranchSelectingSkipped,
      restaurant,
      branch,
      hostName
    } = this.props.store.restaurantStore;

    const branches = restaurant.getBranches;

    return (
      <ShellLayout
        shellHeader={this._renderHeader()}
        shellContent={this._renderContent(
          branches,
          branch,
          isBranchSelectingSkipped,
          hostName
        )}
        style={this.props.store.themesStore.wrapperStyle()}
      />
    );
  }
}

export default withTranslation(['extras_modal'])(BranchListShell);
