const getInvalidCharsMessage = (value: string | undefined, regex: RegExp) => {
  const forbiddenRule = `${regex}`
    .replace('[', '[^')
    .replace(/^\//, '')
    .replace(/\/$/, '');

  const forbiddenRegExp = new RegExp(forbiddenRule, 'g');
  const matchedChars = value?.match(forbiddenRegExp);
  const uniqueMatch = [...new Set(matchedChars)];

  return `${uniqueMatch?.join('')}`;
};

export default getInvalidCharsMessage;
