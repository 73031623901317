import cn from 'classnames';
import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { supportedLanguages } from 'config';

import i18n from '../../../i18n';
import useOutsideClick from '../../hooks/useOutsideClick';
import languageService from '../../services/LanguageService';
import FakeButton from '../../ui/FakeButton';

import { ReactComponent as GlobeIcon } from './globeIcon.svg';
import './LanguageSwitcher.scss';

const classes = {
  enter: 'LanguageSwitcher_enter',
  enterActive: 'LanguageSwitcher_enterActive',
  exit: 'LanguageSwitcher_exit',
  exitActive: 'LanguageSwitcher_exitActive'
};

const LanguageSwitcher = () => {
  const [languageCode, setLanguageCode] = useState<AppLang>(
    i18n.language as AppLang
  );

  const listRef = useRef<HTMLUListElement>(null);
  const headRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<null | NodeJS.Timeout>(null);
  const [open, setOpen] = useState<boolean>(false);

  useOutsideClick([listRef, headRef], () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setOpen(false);
  });

  const currentLanguage = supportedLanguages.find(
    (item) => item.code === languageCode
  );

  const selectLanguage = (langCode: AppLang) => () => {
    setLanguageCode(langCode);

    languageService.selectLanguage(langCode);

    timerRef.current = setTimeout(() => setOpen(false), 200);
  };

  return (
    <div className="LanguageSwitcher">
      <FakeButton
        className="LanguageSwitcher__head"
        onClick={() => setOpen(!open)}
        ref={headRef}
      >
        <span className="LanguageSwitcher__icon">
          <GlobeIcon />
        </span>
        {currentLanguage && (
          <span className="LanguageSwitcher__title">
            {currentLanguage.code.toUpperCase()}
          </span>
        )}
      </FakeButton>
      <CSSTransition in={open} timeout={200} classNames={classes} unmountOnExit>
        <ul className="LanguageSwitcher__list" ref={listRef}>
          <span className="LanguageSwitcher__triangle" />
          {supportedLanguages.map((lang) => (
            <li
              key={lang.code}
              className={cn('LanguageSwitcher__item', {
                LanguageSwitcher__item_active: lang.code === languageCode
              })}
              onClick={selectLanguage(lang.code)}
            >
              {lang.title}&nbsp;
              {lang.code.toUpperCase()}
            </li>
          ))}
        </ul>
      </CSSTransition>
    </div>
  );
};

export { LanguageSwitcher };

export default LanguageSwitcher;
