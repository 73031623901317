import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import DeliveryInfoRow from '../../../../../../components/delivery-info-row';
import DeliveryMbvInfoRow from '../../../../../../components/delivery-mbv-info-row';
import HoursInfoRow from '../../../../../../components/hours-info-row';
import MbvInfoRow from '../../../../../../components/mbv-info-row';
import PAYMENT_IMAGES from '../../../../../../enums/images_enums/payment_methods_images.enum';
import PaymentImages from '../../../payment_methods/images-methods';
import PreparationTime from '../../../preparation_time/preparation_time';

export default class BranchInfoMobile extends PureComponent {
  static propTypes = {
    altText: PropTypes.string.isRequired,
    onLogoClick: PropTypes.func.isRequired,
    logo: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types,react/require-default-props
    paymentMethods: PropTypes.array.isRequired,
    locationChosen: PropTypes.bool.isRequired,
    minDeliveryFee: PropTypes.number.isRequired,
    maxDeliveryFee: PropTypes.number.isRequired,
    intlMinDeliveryFee: PropTypes.string.isRequired,
    intlMaxDeliveryFee: PropTypes.string.isRequired,
    intlDeliveryFee: PropTypes.string.isRequired,
    deliveryFee: PropTypes.number.isRequired,
    isDelivery: PropTypes.bool.isRequired,
    isLocationChosen: PropTypes.bool.isRequired,
    mbvMinPrice: PropTypes.string.isRequired,
    mbvMaxPrice: PropTypes.string.isRequired,
    minPrice: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types,react/require-default-props
    currentAreaCode: PropTypes.object,
    isOpenNow: PropTypes.bool.isRequired,
    activeOrderType: PropTypes.string.isRequired,
    isMaintenanceModeActive: PropTypes.bool.isRequired,
    isHolidayMode: PropTypes.bool.isRequired,
    isFirstHalfTime: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types,react/require-default-props
    pickupDay: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types,react/require-default-props
    deliveryDay: PropTypes.object.isRequired,
    isTodayDeliveryHasBreakTime: PropTypes.bool.isRequired,
    isTodayOpeningsHasBreakTime: PropTypes.bool.isRequired,
    holidayModeTime: PropTypes.number.isRequired,
    hasPreorder: PropTypes.bool.isRequired,
    isDeliveryAvailable: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    areaCodes: PropTypes.array.isRequired,
    deliveryFreeFrom: PropTypes.string,
    averageDeliveryTime: PropTypes.number.isRequired,
    maxSelfcollectTime: PropTypes.number.isRequired
  };

  static defaultProps = {};

  render() {
    const { isDelivery, areaCodes } = this.props;

    return (
      <div className="branch-info">
        <div className="logo" onClick={this.props.onLogoClick}>
          <img src={this.props.logo} alt={this.props.altText} />
        </div>
        <div className="info">
          <div className="line">
            {this.props.activeOrderType && (
              <HoursInfoRow
                isOpenNow={this.props.isOpenNow}
                activeOrderType={this.props.activeOrderType}
                isMaintenanceModeActive={this.props.isMaintenanceModeActive}
                isHolidayMode={this.props.isHolidayMode}
                pickupDay={this.props.pickupDay}
                deliveryDay={this.props.deliveryDay}
                isTodayDeliveryHasBreakTime={
                  this.props.isTodayDeliveryHasBreakTime
                }
                isTodayOpeningsHasBreakTime={
                  this.props.isTodayOpeningsHasBreakTime
                }
                holidayModeTime={this.props.holidayModeTime}
                hasPreorder={this.props.hasPreorder}
                isFirstHalfTime={this.props.isFirstHalfTime}
              />
            )}
          </div>
          {isDelivery && areaCodes.length ? (
            <div className="line">
              <DeliveryInfoRow
                locationChosen={this.props.locationChosen}
                minDeliveryFee={this.props.minDeliveryFee}
                maxDeliveryFee={this.props.maxDeliveryFee}
                intlMinDeliveryFee={this.props.intlMinDeliveryFee}
                intlMaxDeliveryFee={this.props.intlMaxDeliveryFee}
                intlDeliveryFee={this.props.intlDeliveryFee}
                deliveryFee={this.props.deliveryFee}
                withIcon
                isDeliveryAvailable={this.props.isDeliveryAvailable}
              />
            </div>
          ) : null}
          <div className="line">
            <DeliveryMbvInfoRow
              deliveryFreeFrom={this.props.deliveryFreeFrom}
            />
          </div>
          {(isDelivery && areaCodes.length) || !isDelivery ? (
            <div className="line">
              <MbvInfoRow
                isDelivery={this.props.isDelivery}
                isLocationChosen={this.props.isLocationChosen}
                mbvMinPrice={this.props.mbvMinPrice}
                mbvMaxPrice={this.props.mbvMaxPrice}
                minPrice={this.props.minPrice}
                t={this.props.t}
              />
            </div>
          ) : null}
          <div className="payment">
            <PaymentImages
              methods={this.props.paymentMethods}
              images={PAYMENT_IMAGES}
              hiddenDesktop
            />
          </div>
        </div>
      </div>
    );
  }
}
