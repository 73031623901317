import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { withRouter } from 'react-router-dom';

import images from '../../../../enums/images_enums/hermes_images.enum';
import ModalFooterActions from '../modals_components/footer_actions/ModalFooterActions';
import ModalHeader from '../modals_components/header/ModalHeader';

import { IProductCertificateProps } from './props.interface';

import './ProductCertificate.scss';
/*
  Component to render modal of view with product certificate
*/

@inject('store')
@(withRouter as any)
@observer
export default class ProductCertificate extends Component<
  IProductCertificateProps
> {
  /**
   * Method for render desktop modal with certificate
   */
  renderDesktop = () => (
    <div className="modal-content" onClick={this.handleClose}>
      {this.renderCertificateImage()}
      <ModalHeader
        closeAction={this.props.closeModal}
        style={{ display: 'flex', justifyContent: 'center' }}
      />
    </div>
  );

  /**
   * Method for render mobile view with certificate
   */
  renderMobile = () => (
    <div className="modal-content mobile" onClick={this.props.closeModal}>
      {this.renderCertificateImage()}
      <ModalFooterActions confirm={this.props.closeModal} isHideBackButton />
    </div>
  );

  /**
   * Method for render certificate image
   */
  renderCertificateImage = () => {
    const certificateUrl = this.props.store.restaurantStore.branch
      ? this.props.store.restaurantStore.branch.HalalCertificate
      : null;

    const { loadingAnimation } = this.props.store.restaurantStore.restaurant;

    return (
      <LazyLoadImage
        src={certificateUrl}
        placeholderSrc={
          (loadingAnimation && loadingAnimation.url) || images.loading
        }
        wrapperClassName="certificate-wrapper"
      />
    );
  };

  render() {
    const isMobile =
      this.props.store.themesStore.isMobile ||
      this.props.store.themesStore.isShellThemeActive;

    if (isMobile) {
      return this.renderMobile();
    }

    return this.renderDesktop();
  }
}
