import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

class DeliveryInfoRow extends PureComponent {
  static propTypes = {
    locationChosen: PropTypes.bool.isRequired,
    minDeliveryFee: PropTypes.number.isRequired,
    maxDeliveryFee: PropTypes.number.isRequired,
    intlMinDeliveryFee: PropTypes.string.isRequired,
    intlMaxDeliveryFee: PropTypes.string.isRequired,
    intlDeliveryFee: PropTypes.string.isRequired,
    deliveryFee: PropTypes.number.isRequired,
    withIcon: PropTypes.bool,
    isDeliveryAvailable: PropTypes.bool.isRequired,
    deliveryFreeFrom: PropTypes.string
  };

  static defaultProps = {
    withIcon: true,
    deliveryFreeFrom: null
  };

  /**
   * Method to render delivery info
   * @returns {*}
   * @private
   */
  _renderDeliveryInfo = () => {
    const {
      locationChosen,
      minDeliveryFee,
      maxDeliveryFee,
      intlMaxDeliveryFee,
      intlMinDeliveryFee,
      deliveryFee,
      intlDeliveryFee,
      deliveryFreeFrom
    } = this.props;

    let deliveryCostText = this.props.t('freeDelivery');

    if (locationChosen) {
      if (deliveryFee !== 0) {
        deliveryCostText = this.props.t('deliveryFee', {
          price: intlDeliveryFee
        });

        deliveryCostText = deliveryFreeFrom
          ? `${deliveryCostText} (${this.props.t('freeFrom', {
              cost: deliveryFreeFrom
            })})`
          : deliveryCostText;
      }
    } else if (minDeliveryFee !== maxDeliveryFee) {
      const freeFromText = deliveryFreeFrom
        ? `, ${this.props.t('freeFrom', { cost: deliveryFreeFrom })}`
        : '';

      deliveryCostText = this.props.t('deliveryFeeRange', {
        priceRange: `(${intlMinDeliveryFee} - ${intlMaxDeliveryFee}${freeFromText})`
      });
    } else if (minDeliveryFee !== 0) {
      deliveryCostText = this.props.t('deliveryFee', {
        price: intlMinDeliveryFee
      });

      deliveryCostText = deliveryFreeFrom
        ? `${deliveryCostText} (${this.props.t('freeFrom', {
            cost: deliveryFreeFrom
          })})`
        : deliveryCostText;
    }

    return <span className="delivery-info-text">{deliveryCostText}</span>;
  };

  render() {
    const { withIcon, isDeliveryAvailable } = this.props;

    return isDeliveryAvailable ? (
      <>
        {withIcon && <i className="mdi mdi-motorbike type-icon" />}
        {this._renderDeliveryInfo()}
      </>
    ) : null;
  }
}

export default withTranslation(['delivery_info'])(DeliveryInfoRow);
