function addListItem(position, link, name, imageLink) {
  return {
    '@type': 'ListItem',
    position: position,
    item: {
      '@id': link,
      name: name,
      image: imageLink
    }
  };
}

export function StructuredDataCategoryBreadcrumbs(category, subcategory, item) {
  let itemListElements = [
    addListItem(1, item.domain, item.restaurantName, item.restaurantLogo),
    addListItem(
      2,
      item.domain + item.branchCode,
      item.branchName,
      item.restaurantLogo
    ),
    addListItem(3, item.domain + item.branchCode + '/cats', 'Categories', ''),
    addListItem(
      4,
      item.domain + item.branchCode + '/cats/' + category.id,
      category.name,
      category.picurl
    )
  ];

  if (subcategory !== undefined) {
    const subcategoryItem = addListItem(
      5,
      item.domain +
        item.branchCode +
        '/cats/' +
        category.id +
        '/' +
        subcategory.id,
      subcategory.name,
      category.subcategory
    );
    itemListElements.push(subcategoryItem);
  }
  return {
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: itemListElements
    })
  };
}
