import classNames from 'classnames';
import React, { useMemo } from 'react';
import ReactSelect from 'react-select';

import { getStyles } from './Select.styles';
import { SelectProps } from './Select.type';
import './Select.scss';

const ClearIndicator = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M2 2H16V16H2z"
              transform="translate(-803 -426) translate(425 413) translate(376 11)"
            />
            <path
              fill="#BFBFBF"
              fillRule="nonzero"
              d="M9.071 3.071c.237 0 .429.192.429.429v5.142h5.142c.237 0 .43.192.43.43 0 .236-.193.428-.43.428H9.5v5.142c0 .237-.191.43-.428.43s-.429-.193-.429-.43V9.5H3.5c-.237 0-.429-.191-.429-.428s.192-.429.429-.429h5.142V3.5c0-.237.192-.429.43-.429z"
              transform="translate(-803 -426) translate(425 413) translate(376 11) rotate(45 9.071 9.071)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const Select = <T,>({
  className,
  error,
  components,
  forwardRef,
  ...rest
}: SelectProps<T>) => {
  const styles = useMemo(() => getStyles(error), [error]);

  return (
    <div className={classNames('Select', className)}>
      <ReactSelect
        styles={styles as any}
        components={{ ...components }}
        ref={forwardRef}
        {...rest}
      />
      <p className="error">{error}</p>
    </div>
  );
};

export { Select };

export default React.memo(Select);
