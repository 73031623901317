import { v1 } from 'uuid';

import {
  apiUrlVersion0,
  apiUrlVersion2,
  apiUrlVersion3,
  apiUrlVersion3_1
} from '../../../config';

/**
 * API base class to handle common hared logic and store app's endpoint properties
 * @constructor
 * @property {string} appversion - Version of the application
 * @property {string} lang - Active language
 * @property {string} branchId - Slug representation
 * @property {string} token - token of the app
 * @property {string} v0 - generated URL of the first backend API version
 * @property {string} v2 - generated URL of the second backend API version
 * @property {string} v3 - generated URL of the third backend API version
 * @property {string} v3_1 - generated URL of the third backend API version
 */
export default class API {
  token: string;
  v0: string;
  v2: string;
  appversion = '2.3.96';
  lang = 'de';
  branchId = null;
  categoryId = null;
  uuid = v1();
  v3 = `${apiUrlVersion3}`;
  v3_1 = `${apiUrlVersion3_1}`;

  setToken(token) {
    this.token = token;
    this.v0 = `${apiUrlVersion0}/${this.token}`;
    this.v2 = `${apiUrlVersion2}/${this.token}`;
    this.v3 = `${apiUrlVersion3}`;
    this.v3_1 = `${apiUrlVersion3_1}`;
  }

  setBranchId(id) {
    this.branchId = id;
  }

  setCategoryId(id) {
    this.categoryId = id.toString();
  }

  setLanguage(lang) {
    this.lang = lang;
  }

  getDefaultParams() {
    return {
      lang: this.lang
    };
  }
}
