import { TestContext } from 'yup';
import * as yup from 'yup';

import { fetchDeliveryInfoByBranchMemo } from 'client/api/queries';
import googleService, { GeocoderResult } from 'client/services/googleService';
import { GeoInfo } from 'client/stores/thirdPartyService';
import i18n from 'i18n';

import getInvalidCharsMessage from './getInvalidCharsMessage';
import * as helpers from './helpers';
import * as rules from './regexpRules';

type Context = TestContext<{
  lang?: AppLang;
  branchId?: string;
  isGermanRestaurant?: boolean;
  isPolishRestaurant?: boolean;
}>;

/**
 * In order to make the schemas display the error message in correct locale,
 * the schemas have to be instantiated and returned in this function. It gets
 * called on every language change event from i18n thus recreating the validation
 * objects with correct locale.
 */
const createValidationObjects = () => {
  const firstNameValidation = yup
    .string()
    .required(i18n.t('validationErrors:firstNameEmpty'))
    .test(
      'shouldNotBeginWithHyphen',
      i18n.t('validationErrors:beginsWithHyphen'),
      (value = '') => !rules.beginsWithHyphen.test(value)
    )
    .test(
      'shouldNotEndWithHyphen',
      i18n.t('validationErrors:endsWithHyphen'),
      (value = '') => !rules.endsWithHyphen.test(value)
    )
    .test(
      'shouldNotContainInvalidChars',
      ({ value }) =>
        i18n.t('validationErrors:firstNameInvalidChars', {
          invalidChars: getInvalidCharsMessage(
            value,
            rules.firstNameValidChars
          ),
          interpolation: {
            escapeValue: false
          }
        }),
      (value = '') =>
        helpers.checkForbiddenSymbols(value, rules.firstNameValidChars)
    )
    .max(32, i18n.t('validationErrors:maxLengthError'));

  const lastNameValidation = yup
    .string()
    .required(i18n.t('validationErrors:lastNameEmpty'))
    .test(
      'shouldNotBeginWithHyphen',
      i18n.t('validationErrors:beginsWithHyphen'),
      (value = '') => !rules.beginsWithHyphen.test(value)
    )
    .test(
      'shouldNotEndWithHyphen',
      i18n.t('validationErrors:endsWithHyphen'),
      (value = '') => !rules.endsWithHyphen.test(value)
    )
    .test(
      'shouldNotContainInvalidChars',
      ({ value }) =>
        i18n.t('validationErrors:lastNameInvalidChars', {
          invalidChars: getInvalidCharsMessage(value, rules.lastNameValidChars)
        }),
      (value = '') =>
        helpers.checkForbiddenSymbols(value, rules.lastNameValidChars)
    )
    .min(2, i18n.t('validationErrors:lastNameLength'))
    .max(32, i18n.t('validationErrors:maxLengthError'));

  const companyValidation = yup
    .string()
    .test(
      'shouldNotContainInvalidChars',
      ({ value }) =>
        i18n.t('validationErrors:companyInvalidChars', {
          invalidChars: getInvalidCharsMessage(value, rules.companyValidChars)
        }),
      (value = '') =>
        helpers.checkForbiddenSymbols(value, rules.companyValidChars)
    )
    .max(32, i18n.t('validationErrors:maxLengthError'));

  const streetValidation = yup
    .string()
    .required(i18n.t('validationErrors:streetEmpty'))
    .matches(
      rules.startsWithDigitOrLetter,
      i18n.t('validationErrors:letterOrNumberFirstChar')
    )
    .test(
      'shouldStartWithDigitOrLetter',
      i18n.t('validationErrors:letterOrNumberFirstChar'),
      (value = '') => rules.startsWithDigitOrLetter.test(value)
    )
    .test(
      'shouldEndWithDigitOrLetterOrDot',
      i18n.t('validationErrors:letterOrNumberLastChar'),
      (value = '') => /[\d\wüäößáéíóúý.]$/.test(value)
    )
    .test(
      'shouldNotContainInvalidChars',
      ({ value }) =>
        i18n.t('validationErrors:streetInvalidChars', {
          invalidChars: getInvalidCharsMessage(value, rules.streetValidChars)
        }),
      (value = '') =>
        helpers.checkForbiddenSymbols(value, rules.streetValidChars)
    )
    .max(64, i18n.t('validationErrors:maxLengthError'));

  const houseNumberValidation = yup
    .string()
    .required(i18n.t('validationErrors:houseNumberEmpty'))
    .test(
      'shouldContainOnlyOneSlash',
      i18n.t('validationErrors:onlyOneSlash'),
      (value = '') => !rules.containsOnlyOneSlash.test(value)
    )
    .test(
      'shouldContainOnlyOneConsecHyphens',
      i18n.t('validationErrors:onlyOneConsecHyphens'),
      (value = '') => !rules.containsOnlyOneConsecHyphens.test(value)
    )
    .test(
      'shouldStartWithDigitOrLetter',
      i18n.t('validationErrors:letterOrNumberFirstChar'),
      (value = '') => rules.startsWithDigitOrLetter.test(value)
    )
    .test(
      'shouldEndWithDigitOrLetter',
      i18n.t('validationErrors:letterOrNumberLastChar'),
      (value = '') => rules.endsWithDigitOrLetter.test(value)
    )
    .test(
      'shouldContainDigit',
      i18n.t('validationErrors:hasOnlyChars'),
      (value = '') => rules.containsDigit.test(value)
    )
    .test(
      'shouldContainDigit',
      i18n.t('validationErrors:hasOnlyChars'),
      (value = '') => rules.containsDigit.test(value)
    )
    .test(
      'shouldNotBeginWithZero',
      i18n.t('validationErrors:beginsWithZero'),
      (value = '') => !rules.beginsWithZero.test(value)
    )
    .test(
      'shouldNotContainInvalidChars',
      ({ value }) =>
        i18n.t('validationErrors:houseNumberInvalidChars', {
          invalidChars: getInvalidCharsMessage(value, rules.houseValidChars)
        }),
      (value = '') =>
        helpers.checkForbiddenSymbols(value, rules.houseValidChars)
    )
    .max(6, i18n.t('validationErrors:maxLengthError'));

  const apartmentValidation = yup
    .string()
    .test(
      'shouldNotBeginWithHyphen',
      i18n.t('validationErrors:beginsWithHyphen'),
      (value = '') => !rules.beginsWithHyphen.test(value)
    )
    .test(
      'shouldNotEndWithHyphen',
      i18n.t('validationErrors:endsWithHyphen'),
      (value = '') => !rules.endsWithHyphen.test(value)
    )
    .test(
      'shouldNotBeginWithSlash',
      i18n.t('validationErrors:beginsWithHyphenOrSlash'),
      (value = '') => !rules.beginsWithSlash.test(value)
    )
    .test(
      'shouldNotEndWithSlash',
      i18n.t('validationErrors:endsWithHyphenOrSlash'),
      (value = '') => !rules.endsWithSlash.test(value)
    )
    .test(
      'shouldNotContainInvalidChars',
      ({ value }) =>
        i18n.t('validationErrors:apartmentInvalidChars', {
          invalidChars: getInvalidCharsMessage(value, rules.apartmentValidChars)
        }),
      (value = '') =>
        helpers.checkForbiddenSymbols(value, rules.apartmentValidChars)
    )
    .max(6, i18n.t('validationErrors:maxLengthError'));

  const floorValidation = yup
    .string()
    .test(
      'shouldNotBeginWithHyphen',
      i18n.t('validationErrors:beginsWithHyphen'),
      (value = '') => !rules.beginsWithHyphen.test(value)
    )
    .test(
      'shouldNotEndWithHyphen',
      i18n.t('validationErrors:endsWithHyphen'),
      (value = '') => !rules.endsWithHyphen.test(value)
    )
    .test(
      'shouldNotBeginWithSlash',
      i18n.t('validationErrors:beginsWithHyphenOrSlash'),
      (value = '') => !rules.beginsWithSlash.test(value)
    )
    .test(
      'shouldNotEndWithSlash',
      i18n.t('validationErrors:endsWithHyphenOrSlash'),
      (value = '') => !rules.endsWithSlash.test(value)
    )
    .test(
      'shouldNotContainInvalidChars',
      ({ value }) =>
        i18n.t('validationErrors:floorInvalidChars', {
          invalidChars: getInvalidCharsMessage(value, rules.floorValidChars)
        }),
      (value = '') =>
        helpers.checkForbiddenSymbols(value, rules.floorValidChars)
    )
    .max(5, i18n.t('validationErrors:maxLengthError'));

  const staircaseNumberValidation = yup
    .string()
    .test(
      'shouldNotBeginWithHyphen',
      i18n.t('validationErrors:beginsWithHyphen'),
      (value = '') => !rules.beginsWithHyphen.test(value)
    )
    .test(
      'shouldNotEndWithHyphen',
      i18n.t('validationErrors:endsWithHyphen'),
      (value = '') => !rules.endsWithHyphen.test(value)
    )
    .test(
      'shouldNotBeginWithSlash',
      i18n.t('validationErrors:beginsWithHyphenOrSlash'),
      (value = '') => !rules.beginsWithSlash.test(value)
    )
    .test(
      'shouldNotEndWithSlash',
      i18n.t('validationErrors:endsWithHyphenOrSlash'),
      (value = '') => !rules.endsWithSlash.test(value)
    )
    .test(
      'shouldNotContainInvalidChars',
      ({ value }) =>
        i18n.t('validationErrors:stairwellInvalidChars', {
          invalidChars: getInvalidCharsMessage(value, rules.staircaseValidChars)
        }),
      (value = '') =>
        helpers.checkForbiddenSymbols(value, rules.staircaseValidChars)
    )
    .max(6, i18n.t('validationErrors:maxLengthError'));

  const cityValidation = yup
    .string()
    .required(i18n.t('validationErrors:cityEmpty'))
    .test(
      'shouldNotBeginWithHyphen',
      i18n.t('validationErrors:beginsWithHyphen'),
      (value = '') => !rules.beginsWithHyphen.test(value)
    )
    .test(
      'shouldNotEndWithHyphen',
      i18n.t('validationErrors:endsWithHyphen'),
      (value = '') => !rules.endsWithHyphen.test(value)
    )
    .test(
      'shouldNotContainInvalidChars',
      ({ value }) =>
        i18n.t('validationErrors:cityInvalidChars', {
          invalidChars: getInvalidCharsMessage(value, rules.cityValidChars)
        }),
      (value = '') => helpers.checkForbiddenSymbols(value, rules.cityValidChars)
    )
    .min(2, i18n.t('validationErrors:cityTooShort'))
    .max(48, i18n.t('validationErrors:maxLengthError'));

  const emailValidation = yup
    .string()
    .required(i18n.t('validationErrors:emailEmpty'))
    .matches(rules.containsAt, i18n.t('validationErrors:atSignMissing'))
    .matches(
      rules.emailPrefixTooShortOrLong,
      i18n.t('validationErrors:shortPrefix')
    )
    .test(
      'shouldNotBeginWithDot',
      i18n.t('validationErrors:dotIsFirstChar'),
      (value = '') => !rules.beginsWithDot.test(value || '')
    )
    .test(
      'shouldNotEndWithDot',
      i18n.t('validationErrors:dotIsLastChar'),
      (value = '') => !rules.endsWithDot.test(value || '')
    )
    .test(
      'shouldNotContainTwoConsecutiveDots',
      i18n.t('validationErrors:twoConsecDots'),
      (value = '') => !rules.containsTwoConsecDots.test(value || '')
    )
    .matches(rules.domainNotEmpty, i18n.t('validationErrors:domainNotEmpty'))
    .matches(rules.containsDomain, i18n.t('validationErrors:domainIsMissing'))
    .matches(rules.domainTooShort, i18n.t('validationErrors:domainTooShort'))
    .matches(rules.notContainsTwoAts, i18n.t('validationErrors:emailHasTwoAts'))
    .matches(
      rules.domainContainsLettersOrDigits,
      i18n.t('validationErrors:improperHyphens')
    )
    .test(
      'shouldNotContainDigitsOnlyInDomain',
      i18n.t('validationErrors:domainHasIncorrectChars'),
      (value = '') => rules.domainContainsLetters.test(value || '')
    )
    .test(
      'shouldNotContainInvalidChars',
      ({ value }) =>
        i18n.t('validationErrors:emailInvalidChars', {
          invalidChars: getInvalidCharsMessage(value, rules.emailValidChars)
        }),
      (value = '') =>
        helpers.checkForbiddenSymbols(value, rules.emailValidChars)
    )
    .min(2, i18n.t('validationErrors:cityTooShort'))
    .max(48, i18n.t('validationErrors:maxLengthError'));

  const additionalInfoValidation = yup
    .string()
    .test(
      'shouldNotContainInvalidChars',
      ({ value }) =>
        i18n.t('validationErrors:additionalInfoInvalidChars', {
          invalidChars: getInvalidCharsMessage(value, rules.commentValidChars)
        }),
      (value = '') =>
        helpers.checkForbiddenSymbols(value, rules.commentValidChars)
    )
    .max(256, i18n.t('validationErrors:maxLengthError'));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const productCommentValidation = yup
    .string()
    .test(
      'shouldNotContainInvalidChars',
      ({ value }) =>
        i18n.t('validationErrors:commentInvalidChars', {
          invalidChars: getInvalidCharsMessage(value, rules.commentValidChars)
        }),
      (value = '') =>
        helpers.checkForbiddenSymbols(value, rules.commentValidChars)
    )
    .max(256, i18n.t('validationErrors:maxLengthError'));

  const zipValidation = yup
    .mixed()
    .required(i18n.t('validationErrors:zipNotValid'))
    .test(
      'validZip',
      i18n.t('validationErrors:zipNotValid'),
      (value: string, { options }: Context) => {
        const isGermanRestaurant = options.context;
        const isPolishRestaurant = options.context;

        if (isGermanRestaurant) {
          return rules.germanZip.test(value);
        }

        if (isPolishRestaurant) {
          return rules.polishZip.test(value);
        }

        return true;
      }
    )
    .test(
      'max',
      i18n.t('validationErrors:maxLengthError'),
      (value: string, { options }: Context) => {
        const isGermanRestaurant = options.context?.isGermanRestaurant;
        const isPolishRestaurant = options.context?.isPolishRestaurant;

        if (isGermanRestaurant) {
          return value.length < 6;
        }

        if (isPolishRestaurant) {
          return value.length < 7;
        }

        return true;
      }
    )
    .test(
      'shouldNotContainInvalidChars',
      ({ value }) => {
        const { language } = i18n;
        const validateRule = rules.getZipValidationByLang(language);

        return i18n.t('validationErrors:zipInvalidChars', {
          invalidChars: getInvalidCharsMessage(value, validateRule)
        });
      },
      (value = '', { options }: Context) => {
        const lang = options.context?.lang;

        if (!lang) {
          return true;
        }

        const validateRule = rules.getZipValidationByLang(lang);

        return helpers.checkForbiddenSymbols(value, validateRule);
      }
    );

  const zipSelectValidation = yup.object().shape({
    areaCode: yup.string().required(i18n.t('validationErrors:zipNotValid')),
    sublocality: yup.string().required(i18n.t('validationErrors:zipNotValid'))
  });

  const phoneValidation = yup
    .mixed()
    .required(i18n.t('validationErrors:phoneEmpty'))
    .test(
      'emptyPhone',
      i18n.t('validationErrors:phoneEmpty'),
      (value: string) => value.length > 1
    )
    .test(
      'shouldNotContainTwoPluses',
      i18n.t('validationErrors:phoneHasTooManyPlusesAtStart'),
      (value: string) => !rules.hasTwoPluses.test(value)
    )
    .test(
      'shouldNotContainInvalidChars',
      ({ value }) =>
        i18n.t('validationErrors:phoneInvalidChars', {
          invalidChars: getInvalidCharsMessage(value, rules.phoneValidChars)
        }),
      (value: string) =>
        helpers.checkForbiddenSymbols(value, rules.phoneValidChars)
    )
    .test(
      'shouldNotStartWithInvalidChars',
      ({ value }) =>
        i18n.t('validationErrors:phoneHasIncorrectStartChars', {
          invalidChar: getInvalidCharsMessage(value, rules.phoneBeginValidChars)
        }),
      (value: string) =>
        helpers.checkForbiddenSymbols(value, rules.phoneBeginValidChars)
    )
    .test(
      'shouldHaveCorrectStart',
      i18n.t('validationErrors:phoneHasIncorrectStart'),
      (value: string) => {
        const regexp = /(^\+[1-9])|(^[0-9])/;

        return regexp.test(value);
      }
    )
    .test(
      'shouldContainOnlyOneSlash',
      i18n.t('validationErrors:onlyOneSlash'),
      (value: string) => !rules.containsOnlyOneSlash.test(value)
    )
    .test(
      'shouldNotStartWithPlusAndZero',
      i18n.t('validationErrors:phoneHasIncorrectStart'),
      (value: string) => !rules.beginsWithPlusAndZero.test(value)
    )
    .test(
      'shouldNotStartWithThreeZeroes',
      i18n.t('validationErrors:phoneHasTooManyZeroes'),
      (value: string) => !rules.beginsWithThreeZeros.test(value)
    )
    .test(
      'shouldNotEndsWithDigit',
      i18n.t('validationErrors:notEndsWithDigit'),
      (value: string) => !rules.notEndsWithDigit.test(value)
    )
    .test(
      'shouldNotContainPlusInTheMiddle',
      i18n.t('validationErrors:phoneHasTooManyPluses'),
      (value: string) => !rules.containsPlusInTheMiddle.test(value)
    )
    .test(
      'shouldHaveMinLength',
      i18n.t('validationErrors:phoneTooShort'),
      (value: string) => {
        if (rules.leadingPlusOrZero.test(value)) {
          return value.length > 8;
        }

        return value.length > 2;
      }
    )
    .test(
      'shouldHaveMaxLength',
      i18n.t('validationErrors:maxLengthError'),
      (value: string) => {
        if (rules.leadingPlusOrZero.test(value)) {
          return value.length < 18;
        }

        return value.length < 17;
      }
    );

  const googleAddressValidation = yup
    .mixed()
    .test(
      'shouldNotBeUndefined',
      i18n.t('address_form:enterPleaseStreetAddress'),
      (value?: GeocoderResult) => !!value
    )
    .test(
      'shouldContainHouseNumber',
      i18n.t('address_form:enterPleaseStreetnumber'),
      (value?: GeocoderResult) =>
        !!value?.address_components.some(({ types }) =>
          googleService.verifyAddressType(types)
        )
    )
    .test(
      'shouldBeInDeliveryRange',
      i18n.t('delivery_info:addressIsOutsideDeliveryArea'),
      async (
        value: GeocoderResult | GeoInfo | undefined,
        { options }: Context
      ) => {
        const { context } = options;

        let lat: number;
        let lng: number;

        if (value?.lat || value?.lng) {
          lat = value?.lat;
          lng = value?.lng;
        } else {
          // For unknown reason the fields lat and lng might be
          // either a function or a number
          if (typeof value?.geometry.location.lat === 'function') {
            lat = value?.geometry.location.lat();
          } else {
            lat = value?.geometry.location.lat as any;
          }

          if (typeof value?.geometry.location.lng === 'function') {
            lng = value?.geometry.location.lng();
          } else {
            lng = value?.geometry.location.lng as any;
          }
        }

        if (context?.branchId && lat && lng) {
          const { content } = await fetchDeliveryInfoByBranchMemo(
            context.branchId,
            lat,
            lng
          );

          return content.fee !== undefined;
        }

        return false;
      }
    );

  return {
    firstNameValidation,
    lastNameValidation,
    zipValidation,
    zipSelectValidation,
    companyValidation,
    emailValidation,
    phoneValidation,
    streetValidation,
    houseNumberValidation,
    cityValidation,
    additionalInfoValidation,
    staircaseNumberValidation,
    productCommentValidation,
    floorValidation,
    apartmentValidation,
    googleAddressValidation
  };
};

export default createValidationObjects;
