import { LocalStorage } from './local_storage';

export default class Storage {
  storage: typeof LocalStorage | undefined;

  constructor() {
    if (typeof localStorage !== 'undefined') {
      this.storage = LocalStorage;
    }
  }

  /**
   * Method for saving data to localstorage
   * @param {string} key - record key
   * @param {object} data - data for saving
   * @param {string} mainKey - BranchId or something uniq for key
   */
  saveToStorage(key, data, mainKey) {
    this.storage?.saveToStorage(`${mainKey}_${key}`, data);
  }

  /**
   * Method for loading data from localstorage
   * @param {array} keys - array of keys
   * @param {string} mainKey - BranchId or something uniq for key
   * @returns {Promise<T>}
   */
  loadFromStorage(keys, mainKey) {
    return Promise.all(
      keys.map((key) => this.storage?.loadFromStorage(`${mainKey}_${key}`))
    )
      .then((res) => res)
      .catch((err) => {
        console.error('Error in storage', err);

        return err;
      });
  }

  /**
   * Method for removing key from localstorage
   * @param {string} key - record key
   * @param {string} mainKey - BranchId or something uniq for key
   * @returns {Promise<T>}
   */
  deleteFromStorage(key, mainKey) {
    return new Promise((resolve) =>
      resolve(this.storage?.deleteFromStorage(`${mainKey}_${key}`))
    )
      .then((res) => res)
      .catch((err) => {
        console.error('Error in storage', err);
      });
  }

  /**
   * Method for removing all data from localstorage
   * @param {string} keysToClear - record key
   * @param {string} mainKey - BranchId or something uniq for key
   */
  forcedClearStorage(keysToClear, mainKey) {
    keysToClear.forEach((i) => {
      this.storage?.deleteFromStorage(`${mainKey}_${i}`);
    });
  }
}
