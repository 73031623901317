export const APP_STORE_IMAGES = {
  en: require('../../assets/images/mobile_apps/app_store/black/app-store-badge-blk-en.svg'),
  de: require('../../assets/images/mobile_apps/app_store/black/app-store-badge-blk-de.svg'),
  pl: require('../../assets/images/mobile_apps/app_store/black/app-store-badge-blk-pl.svg')
};

export const GOOGLE_PLAY_IMAGES = {
  en: require('../../assets/images/mobile_apps/google_play/google-play-badge-en.svg'),
  de: require('../../assets/images/mobile_apps/google_play/google-play-badge-de.svg'),
  pl: require('../../assets/images/mobile_apps/google_play/google-play-badge-pl.svg')
};
