/* eslint-disable camelcase */
// Data
import { BranchModel } from 'client/models/branch.model';
import SessionStorage from 'client/modules/storage/session_storage';
import RootStore from 'client/stores';
import { IS_CLIENT } from 'config';

import languages from '../enums/images_enums/languages_images.enum';
import { ORDER_TYPES } from '../enums/order_types.enum';
import states from '../enums/states.enum';

/**
 * Function to load all data from server side rendering depends on page
 * @param {object} url - url parameters
 * @param {object} store - mobx store instance
 * @memberof boot#
 * @method loadData
 */
export function loadData(url, store) {
  return loadRestaurantData(
    store.restaurantStore.hostName,
    url.params.slug,
    store
  )
    .then(() => {
      const isMigrated = store.restaurantStore.restaurant.branches.some(
        (b: BranchModel) => b.isMigrated
      );

      const { hasApps } = store.restaurantStore.restaurant;

      if (url.key === states.maintenanceMode) {
        return loadMaintenanceMode(url.params.branchId, store);
      }

      if(!store.themesStore.isShellThemeActive && url.key === states.shopMoved) {
        return checkIfShopIsMigrated(store);
      }

      if(!store.themesStore.isShellThemeActive && isMigrated) {
        return Promise.resolve({ isMigrated: true });
      }

      if (
        store.maintenanceModeModalStore.maintenanceIsActive &&
        !store.maintenanceModeModalStore.activated &&
        (!isMigrated || (isMigrated && !hasApps))
      ) {
        return Promise.resolve({ maintenance: true });
      }

      if (url.params.branchId) {
        return loadBranchData(url.params, store);
      }

      if (!url.params.branchId) {
        if (
          url.key === states.imprint ||
          url.key === states.termsOfUse ||
          url.key === states.privacyPolicy
        ) {
          return loadFirstBranch(store);
        }

        if (
          url.key === 'slug' &&
          !store.aggregatorStore.aggregator.getAggregatorMode
        ) {
          return store.themesStore.isShellThemeActive
            ? loadBranchData(
                { branchId: store.restaurantStore.restaurant.firstBranchId },
                store
              )
            : loadFirstBranch(store);
        }
      }
    })
    .catch((err) => Promise.reject(err));
}

/**
 * Function to load data and prepare stores from maintenance mode page
 * @param {string} branchId - branch id
 * @param {object} store - mobx store instance
 * @memberof boot#
 * @method loadMaintenanceMode
 */
export function loadMaintenanceMode(branchId, store) {
  if (!store.maintenanceModeModalStore.maintenanceIsActive) {
    return { maintenance: false };
  }

  return undefined;
}

export function checkIfShopIsMigrated(store) {
  if (!store?.restaurantStore?.restaurant?.branches?.some((b) => b.isMigrated)) {
    return { isMigrated: false };
  }

  return undefined;
}

/**
 * Function to load first branch if imprint page requested
 * @param {object} store - mobx store instance
 * @returns {*|Promise<T | Promise<never>>}
 */
export function loadFirstBranch(store) {
  const firstBranch = store.restaurantStore.restaurant.firstBranchId;

  return store.restaurantStore
    .fetchBranchDataById(firstBranch)
    .then(() => Promise.resolve())
    .catch((err) => console.error(err));
}

/**
 * Function to load restaurant data and prepare stores for start page
 * @param {string} domain - url domain
 * @param {string} slug - restaurant slug
 * @param {object} store - mobx store instance
 * @memberof boot#
 * @method loadRestaurantData
 */
export function loadRestaurantData(domain, slug, store) {
  return store.restaurantStore
    .getRestaurantData(domain, slug)
    .then((data) => {
      store.languagesStore.prepareLanguages(languages, store);

      store.languagesStore.setActiveLanguage(
        store.restaurantStore.restaurant.getLang &&
          store.restaurantStore.restaurant.getLang !== 'at'
          ? store.restaurantStore.restaurant.getLang
          : 'de'
      );

      return data;
    })
    .catch((err) => console.error(err));
}

const getUtmData = (params: Record<string, string | undefined>) => {
  const {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
    channel,
    platform,
    rpid,
    type
  } = params;

  return {
    utmSource: utm_source,
    utmMedium: utm_medium,
    utmCampaign: utm_campaign,
    utmTerm: utm_term,
    utmContent: utm_content,
    channel,
    platform,
    rpid,
    type
  };
};

/**
 * Function to load branch data and prepare stores for branch page
 * @param {object} params - url parameters
 * @param {object} store - mobx store instance
 */
export function loadBranchData(params, store: RootStore) {
  return store.restaurantStore
    .fetchBranchDataById(params.branchId)
    .then(async () => {
      store.orderPaymentMethodsStore.preparePaymentMethods(
        store.restaurantStore.branch.getPaymentMethods
      );

      store.openingHoursStore.calculateHours(
        store.restaurantStore.branch.getDeliveryHours,
        store.restaurantStore.branch.getPickUpHours
      );

      if (!store.themesStore.isShellThemeActive) {
        store.restaurantStore.setAreaCode('');
      }

      if (store.restaurantStore.useCalculationTypeByDeliveryArea) {
        store.deliveryAddressStore.clearAddress();
      }

      store.openingHoursStore.generatePreorderDays();

      IS_CLIENT && store.basketStore.loadBasketFromStorage();

      await Promise.all([
        store.homepageStore.loadSliders(),
        store.offerStore.loadFreeItem()
      ]);

      return new Promise((resolve, reject) => {
        store.categoryMenuStore
          .loadCategories(false)
          .then(() =>
            store.categoryMenuStore
              .setActiveCategory(
                parseInt(params.catId, 10),
                params.subId && parseInt(params.subId, 10)
              )
              .then(() => resolve())
          )
          .catch((err) => reject(err));
      });
    })
    .catch((err) => Promise.reject(err));
}

/**
 * Function to handle query parameters and prepare stores
 * @param {object} store - mobx store instance
 * @param {object} params - url query parameters
 * @memberof boot
 * @method prepareDataInStores
 */
export function prepareDataInStores(store, params) {
  /**
   * Parameter to get preview mode for templating
   */
  const templatingPreview = params[states.queryParameters.stylePreview];
  /**
   * Identifier of homepage element for preview from query parameter
   */
  const homepagePreviewId = params[states.queryParameters.previewId];
  /**
   * Street name from query parameter
   */
  const streetName = params[states.queryParameters.streetName];
  /**
   * Street number from query parameter
   */
  const streetNo = params[states.queryParameters.streetNo];
  /**
   * City from query parameter
   */
  const city = params[states.queryParameters.city];
  /**
   * Latitude from query parameter
   */
  const latitude = params[states.queryParameters.latitude];
  /**
   * Longitude from query parameter
   */
  const longitude = params[states.queryParameters.longitude];
  /**
   * GooglePlaceId from query parameter
   */
  const googlePlaceId = params[states.queryParameters.googlePlaceId];
  /**
   * First name from query parameter
   */
  const firstName = params[states.queryParameters.firstName];
  /**
   * Last name from query parameter
   */
  const lastName = params[states.queryParameters.lastName];
  /**
   * Email from query parameter
   */
  const email = params[states.queryParameters.email];
  /**
   * Phone from query parameter
   */
  const phone = params[states.queryParameters.phone];
  /**
   * Company from query parameter
   */
  const company = params[states.queryParameters.company];
  /**
   * Is payment was successful from query parameter
   */
  const success = params[states.queryParameters.success];
  /**
   * Area code from query parameter
   */
  const areaCode = params[states.queryParameters.areaCode];
  /**
   * Sublocality from query parameter
   */
  const sublocality = params[states.queryParameters.sublocality];
  /**
   * Platform (ios/android) from query parameter
   */
  const platform = params[states.queryParameters.platform];

  /**
   * Show app update button
   */
  const showAppUpdateButton =
    params[states.queryParameters.showAppUpdateButton];

  /**
   * Is branch was'n chosen from query parameter
   */
  const skippedBranch = params[states.queryParameters.skippedBranch];
  /**
   * Is basket should be filled by random products (feature for screenshots maker)
   */
  const generateDemoBasket = params[states.queryParameters.generateDemoBasket];
  /**
   * DesignId for loading template preview
   */
  const designId = params[states.queryParameters.designId];
  /**
   * Open news after open by received notification click
   */
  const openNews = params[states.queryParameters.openNews];
  /**
   * Activation screenshot mode
   */
  const isScreenshotModeActive = params[states.queryParameters.screenshotMode];
  /**
   * Restaurant is opened by aggregator custom page
   */
  const aggregator = params[states.queryParameters.aggregator];
  /**
   * Shell app has to show in modal only current branch postal codes
   */
  const branchPostalCodes = params[states.queryParameters.branchPostalCodes];
  /**
   * Check payPal transaction status
   */
  const { checkTransaction } = params;
  /**
   * payment was canceled by user
   */
  const { paymentCanceled } = params;
  /**
   * Check order status
   */
  const { checkOrderStatus } = params;
  /**
   * Order type from query params
   */
  const orderType = params[states.queryParameters.orderType];
  const { getOrderStatus } = store.restaurantStore.restaurant;

  if (skippedBranch) {
    store.restaurantStore.setSkipBranchSelectingState(true);
  }

  if (areaCode) {
    store.deliveryAddressStore.handleChangeZip({
      zip: areaCode,
      sublocality: sublocality || city
    });

    store.restaurantStore.setAreaCode(areaCode, sublocality || city);
  }

  if (orderType) {
    store.deliveryAddressStore.setActiveOrderTypeFromQuery(
      orderType as ORDER_TYPES
    );
  }

  if (IS_CLIENT) {
    if (!success) {
      store.restaurantStore.setShowClosedModal();
    }

    /**
     * Success string maybe "true", "false" and "cancel"
     * If it's "cancel" we handle it like "cancelled by user"
     * In other cases we use the payment checking from our backend
     */
    if (success && !store.orderPaymentMethodsStore.pendingPayPalPaymentStatus) {
      store.restaurantStore.closedModalAlreadyShowed = true;

      store.orderPaymentMethodsStore.loadPreviousOrderIdFromStorage();

      if (!store.orderPaymentMethodsStore.complementaryStatusCheck) {
        if (success === 'true' || getOrderStatus === 'always_success') {
          store.orderPaymentMethodsStore.makeOrderSuccessful();
        } else {
          store.orderPaymentMethodsStore.makeOrderFailed();
        }
      } else if (!store.orderPaymentMethodsStore.pendingPayPalPaymentStatus) {
        store.orderPaymentMethodsStore.checkPayPalTransactionStatus(
          success === 'cancel'
        );
      }
    }
  }

  if (streetName) {
    store.deliveryAddressStore.changeStreet(streetName);
  }

  if (streetNo) {
    store.deliveryAddressStore.changeStreetNo(streetNo);
  }

  if (latitude && longitude) {
    store.deliveryAddressStore.changeCoordinates(latitude, longitude);
  }

  if (googlePlaceId) {
    store.deliveryAddressStore.changeAddressId(googlePlaceId);
  }

  if (firstName) {
    store.deliveryAddressStore.changeFirstName(firstName);
  }

  if (lastName) {
    store.deliveryAddressStore.changeLastName(lastName);
  }

  if (email) {
    store.deliveryAddressStore.changeEmail(email);
  }

  if (phone) {
    store.deliveryAddressStore.changePhone(phone);
  }

  if (company) {
    store.deliveryAddressStore.changeCompany(company);
  }

  if (platform) {
    store.themesStore.setCurrentThemeForPlatform(platform);
  }

  if (showAppUpdateButton) {
    store.themesStore.setShowAppUpdateButton(showAppUpdateButton);
  }

  if (homepagePreviewId) {
    store.homepageStore.setPreviewId(homepagePreviewId);
  }

  if (templatingPreview) {
    store.restaurantStore.setTemplatingPreviewModeState(templatingPreview);
  }

  if (generateDemoBasket) {
    store.basketStore.generateProductsInBasket(generateDemoBasket);
  }

  if (designId) {
    store.themesStore.setDesignId(designId);
  }

  if (openNews) {
    store.newsStore.openNewsPage(true);
  }

  if (isScreenshotModeActive) {
    store.themesStore.setScreenshotModeState(isScreenshotModeActive);
  }

  if (aggregator) {
    store.aggregatorStore.setAggregatorQueryParameter(true);
  }

  if (branchPostalCodes === 'true') {
    store.restaurantStore.setShowingBranchPostalCodes(true);
  }

  if (checkTransaction) {
    store.orderPaymentMethodsStore.checkTransactionStatus();
  }

  if (paymentCanceled) {
    store.orderPaymentMethodsStore.checkTransactionStatus(true);
  }

  if (checkOrderStatus) {
    if (IS_CLIENT) {
      const localStorage = new Storage();

      localStorage
        .loadFromStorage(
          [storageKeys.BRANCH_ID, storageKeys.ORDER_ID],
          store.restaurantStore.branch.branchId
        )
        .then(([orderBranchId, orderId]) => {
          if (orderBranchId !== null && orderId !== null) {
            store.orderPaymentMethodsStore.setProcessingOrderStatus(true);

            store.orderPaymentMethodsStore.checkOrderStatus(
              orderBranchId,
              orderId
            );
          }
        });
    }
  }

  if (IS_CLIENT) {
    const utmData = getUtmData(params);

    Object.entries(utmData).forEach(([key, value]) => {
      if (value) {
        SessionStorage.setItem(key, value);
      }
    });
  }
}
