import debounce from 'lodash/debounce';
import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { CSSTransition } from 'react-transition-group';

import FakeButton from '../../../../ui/FakeButton';
import Branch from '../Branch';

import style from './BranchList.module.scss';
import { BranchListProps } from './BranchList.types';
import { ReactComponent as ChevronIcon } from './chevronDown.svg';

const BranchList = ({ branches = [], branchId }: BranchListProps) => {
  const count = 4;
  const isExcessive = branches?.length > count;
  const [maxHeight, setMaxHeight] = useState<number | null>(null);
  const [showNextBranches, setShowNextBranches] = useState(true);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const getIntervals = useCallback(() => {
    let intervals: number[] = [];

    if (containerRef.current) {
      const el = containerRef.current;
      const { children } = el;
      const childrenArr = Array.from(children);
      const intervalsCount = Math.ceil(childrenArr.length / count);

      for (let i = 0; i <= intervalsCount - 1; i++) {
        const from = i * count;
        const to = i * count + count;

        const intervalHeight = childrenArr
          .slice(from, to)
          .reduce((accum, next) => {
            const { height } = next.getBoundingClientRect();

            accum += height;

            return accum;
          }, 0);

        const intervalStartFrom =
          i === 0 ? intervalHeight : intervalHeight + intervals[i - 1];

        intervals = [...intervals, intervalStartFrom];
      }
    }

    return intervals;
  }, []);

  const checkNextBranchesDisplay = debounce(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, offsetHeight } = containerRef.current;

      if (scrollTop + offsetHeight === scrollHeight) {
        setShowNextBranches(false);
      } else {
        setShowNextBranches(true);
      }
    }
  }, 150);

  useEffect(() => {
    const el = containerRef.current;

    el?.addEventListener('scroll', checkNextBranchesDisplay);

    return () => {
      el?.removeEventListener('scroll', checkNextBranchesDisplay);
    };
  }, [checkNextBranchesDisplay]);

  useLayoutEffect(() => {
    if (containerRef.current && isExcessive) {
      const intervals = getIntervals();

      setMaxHeight(intervals[0]);
    }
  }, [getIntervals, isExcessive]);

  const scrollToNextBranches = () => {
    if (containerRef.current) {
      const el = containerRef.current;
      const { scrollTop } = el;
      const intervals = getIntervals();

      const currentIntervalId = intervals.findIndex(
        (interval) => scrollTop < interval
      );

      el.scrollTo({
        top: intervals[currentIntervalId],
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  const classes = {
    enter: style.enter,
    enterActive: style.enterActive,
    exit: style.exit,
    exitActive: style.exitActive
  };

  return (
    <div className={style.BranchList} data-testid="branch-list">
      <div
        className={style.container}
        ref={containerRef}
        style={{ maxHeight: maxHeight || '100%' }}
      >
        {branches?.map((branch) => (
          <Branch
            key={branch.id}
            branch={branch}
            active={branch.id === branchId}
          />
        ))}
      </div>
      <CSSTransition
        in={isExcessive && showNextBranches}
        timeout={200}
        classNames={classes}
        unmountOnExit
      >
        <FakeButton
          className={style.nextBranches}
          onClick={scrollToNextBranches}
        >
          <ChevronIcon />
        </FakeButton>
      </CSSTransition>
    </div>
  );
};

export default memo(BranchList);
