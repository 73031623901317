export enum ShellModalsRouteType {
  ADDRESS_MODAL = 'addressModal',
  ALLERGENS_MODAL = 'allergensModal',
  CERTIFICATE_MODAL = 'certificateModal',
  CONTACT_MODAL = 'contactModal',
  DELETE_PRODUCT_MODAL = 'deleteConfirm',
  EXTRAS_MODAL = 'extrasModal',
  IMPRINT_MODAL = 'imprintModal',
  PRODUCT_MODAL = 'productModal',
  OFFER_MODAL = 'offerModal',
  PRIVACY_MODAL = 'privacyModal',
  TABLE_RESERVATION = 'tableReservation',
  TERMS_MODAL = 'termsModal',
  CHANGE_ADDRESS_MODAL = 'changeAddressModal'
}

export type Modals = Record<ShellModalsRouteType, React.ComponentType<any>>;
