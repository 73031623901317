import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import './style.scss';

interface IOrderNowLabelProps extends WithTranslation {
  isDelivery: boolean;
}

interface IOrderNowLabelNavProps {
  isDelivery: boolean;

  t: (key: string) => string;
}

/**
 * Component to render row with "order now" label
 */
class MbvInfoRow extends React.PureComponent<
  IOrderNowLabelProps,
  Record<string, any>,
  IOrderNowLabelNavProps
> {
  public render() {
    return (
      <div className="order-now-label">
        <label className="title">
          {this.props.isDelivery
            ? this.props.t('address_form:timeName')
            : this.props.t('address_form:pickupTime')}
        </label>

        <label className="value">
          {this.props.t('address_form:deliveryTime')}
        </label>
      </div>
    );
  }
}

export default withTranslation(['delivery_info'])(MbvInfoRow);
