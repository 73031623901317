import { action, toJS } from 'mobx';
import TemplateVariableModel from './template_variable.model';

/**
 * Template response model
 */
export default class TemplateResponseModel {
  variables: any[];

  constructor(response) {
    Object.assign(this, response);
    this.variables = this.prepareVariables(response ? response.content : []);
  }

  /**
   * Mapping template variables array
   * @param {array} content - array of variables
   */
  @action prepareVariables(content = []) {
    return content.length > 0
      ? content.map((variable) => new TemplateVariableModel(variable))
      : [];
  }

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  getToJS() {
    return toJS(Object.assign({}, this, { root: {} }));
  }
}
