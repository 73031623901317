// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Heading_Heading__2JCuX {\n  font-weight: bold;\n  font-stretch: condensed;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  margin: 0;\n  color: var(--primaryTextColor); }\n\nh1.Heading_Heading__2JCuX {\n  font-size: 36px; }\n\nh2.Heading_Heading__2JCuX {\n  font-size: 30px; }\n\nh3.Heading_Heading__2JCuX {\n  font-size: 26px; }\n\nh4.Heading_Heading__2JCuX {\n  font-size: 20px; }\n\nh5.Heading_Heading__2JCuX {\n  font-size: 16px; }\n\nh6.Heading_Heading__2JCuX {\n  font-size: 14px; }\n", "",{"version":3,"sources":["webpack://src/client/ui/Heading/Heading.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,SAAS;EACT,8BAA8B,EAAE;;AAElC;EACE,eAAe,EAAE;;AAEnB;EACE,eAAe,EAAE;;AAEnB;EACE,eAAe,EAAE;;AAEnB;EACE,eAAe,EAAE;;AAEnB;EACE,eAAe,EAAE;;AAEnB;EACE,eAAe,EAAE","sourcesContent":[".Heading {\n  font-weight: bold;\n  font-stretch: condensed;\n  font-style: normal;\n  line-height: normal;\n  letter-spacing: normal;\n  margin: 0;\n  color: var(--primaryTextColor); }\n\nh1.Heading {\n  font-size: 36px; }\n\nh2.Heading {\n  font-size: 30px; }\n\nh3.Heading {\n  font-size: 26px; }\n\nh4.Heading {\n  font-size: 20px; }\n\nh5.Heading {\n  font-size: 16px; }\n\nh6.Heading {\n  font-size: 14px; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Heading": "Heading_Heading__2JCuX"
};
export default ___CSS_LOADER_EXPORT___;
