import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { InputActionMeta, components } from 'react-select';

import Select from 'client/ui/Select';

import images from '../../enums/images_enums/hermes_images.enum';

import './AddressAutocomplete.scss';
import {
  AddressAutocompleteProps,
  AddressAutocompleteState
} from './AddressAutocomplete.type';
import ClearIndicator from './ClearIndicator';

let sessionToken: google.maps.places.AutocompleteSessionToken | null = null;

@inject('store')
@observer
class AddressAutocomplete extends Component<
  AddressAutocompleteProps,
  AddressAutocompleteState
> {
  state: AddressAutocompleteState = {
    sessionToken: null,
    error: '',
    inputValue: this.props.defaultValue?.value.value ?? '',
    selectedAddress: this.props.defaultValue?.value ?? null,
    menuIsOpen: undefined
  };

  selectRef = React.createRef<any>();

  componentDidUpdate() {
    if (!sessionToken && window.google && window.google.maps) {
      sessionToken = new window.google.maps.places.AutocompleteSessionToken();
    }
  }

  handleInputChange = (value: string, meta: InputActionMeta) => {
    const { store, onInputChange } = this.props;
    const { action } = meta;

    if (action === 'input-change') {
      if (
        value &&
        value.length >=
          store?.deliveryAddressStore.COUNT_SYMBOLS_TO_SEARCH_ADDRESS
      ) {
        this.getPlacesPredictions(value);
      }

      if (!value) {
        store?.thirdPartyServicesStore.clearPlacesPredictions();
      }

      this.setState({ inputValue: value, selectedAddress: null });

      onInputChange && onInputChange(value, meta);
    }
  };

  handleChange = (option: any) => {
    const { t, onChange } = this.props;

    if (option) {
      const { label, value } = option;
      const inputValue = `${label}${value.isHouseAddress ? '' : ' '}`;

      this.setState({ error: '', inputValue, selectedAddress: value });

      if (!value.isHouseAddress) {
        this.setState({ error: t!('address_form:enterPleaseStreetnumber') });
      }
    }

    onChange(option);
  };

  getPlacesPredictions = (value: string) => {
    this.props.store?.thirdPartyServicesStore.getPlacesByText(value);
  };

  getNoOptionMessage = () => {
    const { t, store } = this.props;
    const { inputValue } = this.state;

    const noResultsState =
      store?.thirdPartyServicesStore.noPlacesResultsFound &&
      inputValue.length > 2;

    return (props: any) => (
      <components.NoOptionsMessage {...props}>
        <div className="google-places-autocomplete-dropdown-menu-container">
          <div className="google-places-autocomplete-dropdown-menu-cell">
            {noResultsState
              ? t!('address_form:noAddressFound')
              : t!('address_form:enterPleaseStreetAddress')}
          </div>
        </div>
      </components.NoOptionsMessage>
    );
  };

  clearValue = () => {
    this.setState({ menuIsOpen: true });

    this.handleInputChange('', { action: 'input-change' });

    this.selectRef.current?.select.focus();
  };

  handleOutsideClick = () => {
    this.setState({ menuIsOpen: undefined });
  };

  getIndicatorsContainer = () => {
    const { inputValue } = this.state;

    const style: React.CSSProperties = {
      visibility: !inputValue ? 'hidden' : 'visible'
    };

    return (props: any) => (
      <components.IndicatorsContainer {...props}>
        <ClearIndicator
          style={style}
          onClick={this.clearValue}
          onOutsideClick={this.handleOutsideClick}
        />
      </components.IndicatorsContainer>
    );
  };

  getMenuList = () => (props: any) => (
    <components.MenuList {...props}>
      {props.children}
      <img
        src={images.PBG}
        alt="Powered by Google"
        className="google-places-autocomplete-dropdown-menu-google-logo"
      />
    </components.MenuList>
  );

  render() {
    const { error, inputValue, selectedAddress, menuIsOpen } = this.state;
    const { disabled, store, t, className, defaultValue } = this.props;

    const options = store?.thirdPartyServicesStore.placesPredictions.map(
      (prediction) => ({
        label: prediction.value,
        value: prediction
      })
    );

    if (!options?.length && defaultValue) {
      options?.push(defaultValue);
    }

    const NoOptionsMessage = this.getNoOptionMessage();
    const IndicatorsContainer = this.getIndicatorsContainer();
    const MenuList = this.getMenuList();

    const value =
      options?.find(
        (option) => option.value.placeId === selectedAddress?.placeId
      ) ?? null;

    const errorMsg = error || this.props.error;

    return (
      <Select
        forwardRef={this.selectRef}
        className={classNames(
          'AddressAutocomplete',
          className,
          error && 'error'
        )}
        placeholder={t!('enterAddressForDelivery')}
        components={{ NoOptionsMessage, IndicatorsContainer, MenuList }}
        onInputChange={this.handleInputChange}
        onChange={this.handleChange}
        filterOption={() => true}
        isDisabled={disabled}
        inputValue={inputValue}
        options={options}
        classNamePrefix="AddressAutocomplete"
        blurInputOnSelect={false}
        error={errorMsg}
        value={value}
        menuIsOpen={menuIsOpen}
      />
    );
  }
}

export default withTranslation(['enter_address_modal'])(AddressAutocomplete);
