import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { ELEMENTS_TYPES } from '../../../../../enums/homepage_elements_types.enum';
import { createHomepageHrefLink } from '../../../../../utils/functions';
import HomepageOpeningHours from '../HomepageOpeningHours/HomepageOpeningHours';
import HomepageSeoText from '../HomepageSeoText';

import HomepageBannersDesktop from './pure/HomepageBannersDesktop';
import HomepageCategoriesDesktop from './pure/HomepageCategoriesDesktop';
import HomepageDeliveryDesktop from './pure/HomepageDeliveryDesktop';
import HomepageOffersDesktop from './pure/HomepageOffersDesktop';
import HomepageProductsDesktop from './pure/HomepageProductsDesktop';
import HomepageTextDesktop from './pure/HomepageTextDesktop';

import './styles.scss';

@inject('store')
@withRouter
@observer
class HomepageDesktop extends Component {
  static propTypes = {
    previewId: PropTypes.number
  };

  /**
   * Method to render banners slider
   * @param slides - array of slides objects
   * @returns {*}
   * @private
   */
  _renderBannerSlider = (slides, key) => (
    <HomepageBannersDesktop slides={slides} key={key} />
  );

  /**
   * Method to render offers slider
   * @param offers - array of offers objects
   * @param title - slide title
   * @returns {*}
   * @private
   */
  _renderOffers = (offers, title, key, index) => (
    <HomepageOffersDesktop
      buttonLabel={this.props.t('home:lblAllOffers')}
      setActiveCategory={() =>
        this.props.store.categoryMenuStore.setActiveCategory(-1)}
      offers={offers}
      title={title}
      key={key}
      index={index}
    />
  );

  /**
   * Method to render discounts slider
   * @param products - array of products objects
   * @param title - section title
   * @returns {*}
   * @private
   */
  _renderProducts = (title, products, key, index) => (
    <HomepageProductsDesktop
      title={title}
      products={products}
      key={key}
      index={index}
    />
  );

  /**
   * Method to render categories displayed as a grid
   * @param categories - array of categories objects
   * @param title - section title
   * @returns {*}
   * @private
   */
  _renderCategories = (title, categories, key, index) => (
    <HomepageCategoriesDesktop
      title={title}
      categories={categories}
      key={key}
      index={index}
    />
  );

  /**
   * Method to render delivery info
   * @param areas - array of areas objects
   * @param title - section title
   * @param {boolean} isHidePostalCodes - is hide postal codes from delivery area
   * @returns {*}
   * @private
   */
  _renderDelivery = (title, areas, isHidePostalCodes, key, index) => (
    <HomepageDeliveryDesktop
      title={title}
      areas={areas}
      key={key}
      isHidePostalCodes={isHidePostalCodes}
      index={index}
    />
  );

  /**
   * Method to render text/image section
   * @param content - array of text/image blocks
   * @returns {*}
   * @private
   */
  _renderTextBlock = (content, key) => (
    <HomepageTextDesktop content={content} key={key} />
  );

  /**
   * Method to render seo text section
   * @param content - array of text blocks
   * @param elementID - number key
   * @returns {*}
   * @private
   */
  _renderSeoTextBlock = (content, key) => {
    const {
      seoRestaurantTitle,
      seoCategoriesTitle
    } = this.props.store.seoDataStore;

    const { homepageAnchors } = this.props.store.seoDataStore;

    const anchors = homepageAnchors.map((elem, index) => ({
      label: elem.title,
      hash: createHomepageHrefLink([elem.type, index])
    }));

    return (
      <HomepageSeoText
        content={content}
        key={key}
        headerText={seoRestaurantTitle}
        subHeaderText={seoCategoriesTitle}
        anchors={anchors}
      />
    );
  };

  /**
   * Method to render opening hours section
   * @private
   */
  _renderOpeningHoursBlock = (key, index) => {
    const { sortedDelivery, sortedPickup } = this.props.store.openingHoursStore;

    return (
      <HomepageOpeningHours
        key={key}
        deliverySchedule={sortedDelivery}
        pickupSchedule={sortedPickup}
        index={index}
      />
    );
  };

  /**
   * Method to render single element
   * @param element - element object
   * @param key
   * @returns {*}
   * @private
   */
  _renderElement = (element) => {
    const areas = this.props.store.restaurantStore.branch.areaCodes;
    const { homepageAnchors } = this.props.store.seoDataStore;

    const index = homepageAnchors.findIndex(
      (anchor) => anchor.id === element.id
    );

    switch (element.type) {
      case ELEMENTS_TYPES.SLIDER:
        return this._renderBannerSlider(element.slides, element.id);
      case ELEMENTS_TYPES.CATEGORIES:
        return this._renderCategories(
          element.title,
          element.categories,
          element.id,
          index
        );
      case ELEMENTS_TYPES.PRODUCTS:
        return this._renderProducts(
          element.title,
          element.products,
          element.id,
          index
        );
      case ELEMENTS_TYPES.OFFERS:
        return this._renderOffers(
          element.offers,
          element.title,
          element.id,
          index
        );
      case ELEMENTS_TYPES.TEXT_IMAGE:
        return this._renderTextBlock(element.contentArray, element.id);
      case ELEMENTS_TYPES.AREA_CODE:
        return this._renderDelivery(
          element.title,
          areas,
          element.hidePostalCodes,
          element.id,
          index
        );
      case ELEMENTS_TYPES.SEO_TEXT:
        return this._renderSeoTextBlock(element.items[0], element.id);
      case ELEMENTS_TYPES.OPENING_TIMES:
        return this._renderOpeningHoursBlock(element.id, index);
      default:
        console.log('new element.type', element.type);
    }
  };

  render() {
    let { homepageElements } = this.props.store.homepageStore;

    // Filter homepage elements for preview
    if (this.props.previewId) {
      homepageElements = homepageElements.filter(
        (item) => item.id === this.props.previewId
      );
    }

    return (
      <div className="homepage-desktop" id="homepage-desktop">
        <main className="desktop-content">
          {homepageElements.map((item) => this._renderElement(item))}
        </main>
      </div>
    );
  }
}

export default withTranslation(['home', 'common'])(HomepageDesktop);
