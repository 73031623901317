import classNames from 'classnames';
import React from 'react';

import css from './ProductBadge.module.scss';
import { ProductBadgeProps } from './ProductBadge.type';

const ProductBadge = ({ className, title = 'new' }: ProductBadgeProps) => (
  <div className={classNames(css.ProductBadge, className)}>
    <p>{title}</p>
  </div>
);

export { ProductBadge };

export default React.memo(ProductBadge);
