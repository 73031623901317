// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchHeader_SearchHeader__1SOx0 {\n  padding: 0 10px;\n  padding-top: 19px;\n  width: 100vw; }\n\n.SearchHeader_Title__3rH0k {\n  color: #212121;\n  font-size: 18px;\n  font-weight: bold;\n  overflow-x: hidden;\n  text-overflow: ellipsis;\n  width: 100%; }\n\n.SearchHeader_Description__3GXKE {\n  color: rgba(33, 33, 33, 0.5);\n  font-size: 12px;\n  padding-top: 16px; }\n", "",{"version":3,"sources":["webpack://src/client/themes/theme-hermes/components/products_list/shell/SearchHeader/SearchHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY,EAAE;;AAEhB;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,uBAAuB;EACvB,WAAW,EAAE;;AAEf;EACE,4BAA4B;EAC5B,eAAe;EACf,iBAAiB,EAAE","sourcesContent":[".SearchHeader {\n  padding: 0 10px;\n  padding-top: 19px;\n  width: 100vw; }\n\n.Title {\n  color: #212121;\n  font-size: 18px;\n  font-weight: bold;\n  overflow-x: hidden;\n  text-overflow: ellipsis;\n  width: 100%; }\n\n.Description {\n  color: rgba(33, 33, 33, 0.5);\n  font-size: 12px;\n  padding-top: 16px; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SearchHeader": "SearchHeader_SearchHeader__1SOx0",
	"Title": "SearchHeader_Title__3rH0k",
	"Description": "SearchHeader_Description__3GXKE"
};
export default ___CSS_LOADER_EXPORT___;
