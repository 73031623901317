import classNames from 'classnames';
import React, { Component, MouseEvent, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import AdditiveIconList from 'client/components/AdditiveIconList';
import { ReactComponent as Info } from 'client/icons/info.svg';
import { ReactComponent as NoDiscount } from 'client/icons/no-discount.svg';
import { ReactComponent as NoMbv } from 'client/icons/no-mbv.svg';
import Heading from 'client/ui/Heading';

import { withPropsDeepComparison } from '../../../../../../hocs/withPropsDeepComparison/withPropsDeepComparison';
import ProductStandardButton from '../../../../components/buttons/ProductStandardButton/ProductStandardButton';
import AllergenTooltip from '../../../../components/products_list/common/allergen-tooltip';
import ProductDefaultImage from '../../../../components/products_list/common/cell/product-default-image/ProductDefaultImage';
import ProductMetaData from '../../../../components/products_list/common/cell/product-seo';
import ProductNewBadge from '../../../../components/products_list/common/cell/ProductNewBadge/product-new-badge';

import { IProductSmallPictureShell } from './props.interface';
import './ProductSmallPictureShell.scss';

interface IState {
  imageLoaded: boolean;
}

@withPropsDeepComparison()
class ProductSmallPictureShell extends Component<
  IProductSmallPictureShell,
  IState
> {
  state = {
    imageLoaded: false
  };

  containerRef = createRef<HTMLDivElement>();

  componentDidMount() {
    if (this.containerRef.current && this.props.setSize) {
      const { marginBottom, marginTop } = getComputedStyle(
        this.containerRef.current
      );

      this.props.setSize(
        this.containerRef.current.getBoundingClientRect().height +
          parseFloat(marginBottom) +
          parseFloat(marginTop)
      );
    }
  }

  /**
   * Handle click on button for purchasing
   */
  onButtonClick = (event: MouseEvent) => {
    this.props.onButtonClick(event, this.props.product);
  };

  /**
   * Handle click on product cell
   */
  onCellClick = (event: MouseEvent) => {
    this.props.onCellClick(event, this.props.product);
  };

  /**
   * Method for updating status of image loading
   */
  updateImageLoadingStatus = (success: boolean) => {
    this.setState({
      imageLoaded: success
    });
  };

  render() {
    const {
      isShopClosed,
      isNew,
      hideProductArticles,
      product,
      productCount,
      animated,
      isMobile,
      additivesNames,
      animationTargetBounds,
      currency,
      isShell,
      buttonStyle,
      horizontalMenuProductStyle,
      foodIcons,
      showHalalCertificate
    } = this.props;

    let buttonText = '';

    if (product.isValidForOrder) {
      buttonText = product.hasSeveralSizes
        ? `${this.props.t ? this.props.t('product_view:lblFrom') : ''} ${
            product.getIntlPrice
          }`
        : product.getIntlPrice;
    } else {
      buttonText = this.props.t
        ? this.props.t('common:productNotAvailable')
        : '';
    }

    const templatingStyles = horizontalMenuProductStyle
      ? {
          productFontColor: horizontalMenuProductStyle.color,
          additivesOpacity: horizontalMenuProductStyle.additivesOpacity,
          descriptionOpacity: horizontalMenuProductStyle.descriptionOpacity,
          productNumberTextColor:
            horizontalMenuProductStyle.productNumberTextColor,
          productNumberBorderColor:
            horizontalMenuProductStyle.productNumberBorderColor,
          defaultPictureIconColor:
            horizontalMenuProductStyle.defaultPictureIconColor,
          defaultPictureBackgroundColor:
            horizontalMenuProductStyle.defaultPictureBackgroundColor
        }
      : {};

    const additivesElement =
      additivesNames && Object.keys(additivesNames).length ? (
        <span style={{ opacity: templatingStyles.additivesOpacity }}>
          <AllergenTooltip allergens={additivesNames} />
        </span>
      ) : null;

    const defaultImage = (
      <ProductDefaultImage
        iconColor={templatingStyles.defaultPictureIconColor}
      />
    );

    return (
      <div
        ref={this.containerRef}
        className={classNames('product-small-picture-shell-container', {
          'is-closed': isShopClosed
        })}
        onClick={this.onCellClick}
      >
        <div className="product-small-picture-shell-image">
          <div
            className={classNames({
              'product-small-picture-shell-image-wrapper': true,
              loading: !this.state.imageLoaded,
              'default-image': !this.props.product.pic_url
            })}
            style={{
              backgroundColor: templatingStyles.defaultPictureBackgroundColor
            }}
          >
            <LazyLoadComponent
              placeholder={defaultImage}
              afterLoad={() => this.updateImageLoadingStatus(true)}
            >
              {product.pic_url ? (
                <img
                  src={product.pic_url}
                  alt={product.name}
                  style={{
                    borderColor: templatingStyles.productNumberBorderColor
                  }}
                  data-testid="product-image"
                />
              ) : (
                defaultImage
              )}
            </LazyLoadComponent>
          </div>
          {isNew && isNew.value && (
            <ProductNewBadge
              text={this.props.t && this.props.t('product_view:lblNew')}
              style={isNew.style}
            />
          )}
          <AdditiveIconList additives={foodIcons} className="iconList" />
        </div>
        <div className="product-small-picture-shell-text-button-wrapper">
          <div className="product-small-picture-shell-text-container">
            <div
              className="product-small-picture-shell-title-block"
              style={{ color: templatingStyles.productFontColor }}
            >
              {product.count_mbw !== 1 && <NoMbv />}
              {!product.isHideDiscountBadge && <NoDiscount />}
              <Heading variant="h6" dataTestId="product-name">
                {product.name}
              </Heading>
              <Info onClick={this.onCellClick} />
              {!isMobile && (
                <AdditiveIconList
                  additives={foodIcons}
                  onHalalClick={showHalalCertificate}
                />
              )}
            </div>
            <div
              className="product-small-picture-shell-description-block"
              style={{
                color: templatingStyles.productFontColor,
                opacity: templatingStyles.descriptionOpacity
              }}
              data-testid="product-description"
            >
              {product.description}
            </div>
          </div>
          <div className="product-small-picture-shell-number-button-wrapper">
            <ProductStandardButton
              isClosed={isShopClosed}
              onClick={this.onButtonClick}
              text={buttonText}
              animated={animated}
              productCount={productCount}
              animationTargetBounds={animationTargetBounds}
              isSoldOut={product.soldOut}
              disabled={!product.isValidForOrder}
              mobileView={isMobile}
              isShell={isShell}
              buttonStyle={buttonStyle}
            />
            {!hideProductArticles && product.no && (
              <div
                className="product-small-picture-shell-number-block"
                style={{
                  color: templatingStyles.productNumberTextColor,
                  borderColor: templatingStyles.productNumberBorderColor
                }}
              >
                {product.no}
              </div>
            )}
          </div>
        </div>
        <ProductMetaData product={product} currency={currency} />
      </div>
    );
  }
}

export default withTranslation(['product_view'])(ProductSmallPictureShell);
