import React from 'react';
import { withTranslation } from 'react-i18next';

/**
 * Component to render rights
 */
class RightsReserved extends React.PureComponent {
  render() {
    return (
      <div className="bottom-text">
        {this.props.t('basket:allPricesInclVAT')}
        <br />
        {this.props.t('basket:printingErrorsMistakesChangesReserved')}
      </div>
    );
  }
}
export default withTranslation(['home'])(RightsReserved);
