import TagManager from 'react-gtm-module';

import RootStore from 'client/stores';
import { isProduction } from 'config';

import { AnalyticsEvent } from '../../enums/analytics_event.enum';
import { ProductModel } from '../../models/product.model';

/**
 * Class for sending events to Google Tags Manager
 * @constructor
 */
export default class GoogleTagsManager {
  root: RootStore;

  initialized: boolean;

  constructor(root: RootStore) {
    this.root = root;

    this.initialized = false;
  }

  init() {
    if (!this.initialized) {
      this.initialized = true;

      const tagManagerArgs = {
        gtmId: isProduction ? 'GTM-K4HG4SX' : 'GTM-5MNNTFJ',
        dataLayerName: 'PageDataLayer'
      };

      TagManager.initialize(tagManagerArgs);
    }
  }

  _productsFromBasket() {
    return this.root.basketStore.products.map((product) =>
      this._productModel(product.product)
    );
  }

  _productModel(product: ProductModel) {
    return {
      id: product.id,
      name: product.name,
      price: product.priceWithIngredients,
      brand: this.root.restaurantStore.branch.branchName,
      category:
        (this.root.categoryMenuStore.activeCategory &&
          this.root.categoryMenuStore.activeCategory.seoCategory) ||
        '',
      variant: '',
      quantity: product.count > 0 ? product.count : 1
    };
  }

  trackViewShop(shopName: string) {
    const tagManagerArgs = {
      dataLayer: {
        googleAnalyticsKey: this.root.restaurantStore.restaurant
          .customerGoogleAnalyticsKey,
        defaultGoogleAnalyticsKey: this.root.restaurantStore
          .defaultGoogleAnalyticsKey,
        country: this.root.restaurantStore.restaurant.countryCode,
        event: AnalyticsEvent.VIEW_SHOP,
        shopName
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackAddToBasket(product: ProductModel) {
    const tagManagerArgs = {
      dataLayer: {
        googleAnalyticsKey: this.root.restaurantStore.restaurant
          .customerGoogleAnalyticsKey,
        defaultGoogleAnalyticsKey: this.root.restaurantStore
          .defaultGoogleAnalyticsKey,
        country: this.root.restaurantStore.restaurant.countryCode,
        event: AnalyticsEvent.ADD_TO_BASKET,
        ecommerce: {
          currencyCode: this.root.restaurantStore.branch.currencyCode,
          add: {
            products: [this._productModel(product)]
          }
        }
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackProvidePersonalInformation() {
    const tagManagerArgs = {
      dataLayer: {
        googleAnalyticsKey: this.root.restaurantStore.restaurant
          .customerGoogleAnalyticsKey,
        defaultGoogleAnalyticsKey: this.root.restaurantStore
          .defaultGoogleAnalyticsKey,
        order_value: this.root.basketStore.finalPriceWithOffers,
        country: this.root.restaurantStore.restaurant.countryCode,
        event: AnalyticsEvent.PROVIDE_PERSONAL_INFORMATION,
        ecommerce: {
          currencyCode: this.root.restaurantStore.branch.currencyCode,
          checkout: {
            actionField: {
              step: 2,
              option: `${this.root.deliveryAddressStore.address.first_name} ${this.root.deliveryAddressStore.address.last_name}`
            },
            products: this._productsFromBasket()
          }
        }
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackStartCheckout() {
    const tagManagerArgs = {
      dataLayer: {
        googleAnalyticsKey: this.root.restaurantStore.restaurant
          .customerGoogleAnalyticsKey,
        defaultGoogleAnalyticsKey: this.root.restaurantStore
          .defaultGoogleAnalyticsKey,
        order_value: this.root.basketStore.finalPriceWithOffers,
        country: this.root.restaurantStore.restaurant.countryCode,
        event: AnalyticsEvent.START_CHECKOUT,
        ecommerce: {
          currencyCode: this.root.restaurantStore.branch.currencyCode,
          checkout: {
            actionField: {
              step: 1,
              option: this.root.basketStore.finalPriceWithOffers
            },
            products: this._productsFromBasket()
          }
        }
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackPlaceOrder() {
    const {
      restaurantStore,
      basketStore,
      orderPaymentMethodsStore
    } = this.root;

    const { restaurant, branch } = restaurantStore;

    const tagManagerArgs = {
      dataLayer: {
        googleAnalyticsKey: restaurant.customerGoogleAnalyticsKey,
        defaultGoogleAnalyticsKey: restaurantStore.defaultGoogleAnalyticsKey,
        order_value: basketStore.finalPriceWithOffers,
        transactionId: orderPaymentMethodsStore.orderId,
        country: restaurant.countryCode,
        event: AnalyticsEvent.PLACE_ORDER,
        ecommerce: {
          currencyCode: branch.currencyCode,
          checkout: {
            actionField: {
              step: 2,
              option: basketStore.finalPriceWithOffers
            },
            products: this._productsFromBasket()
          }
        }
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackSuccessfulPayment() {
    const {
      basketStore,
      couponsStore,
      restaurantStore,
      orderPaymentMethodsStore
    } = this.root;

    const { restaurant, branch } = restaurantStore;

    const tagManagerArgs = {
      dataLayer: {
        googleAnalyticsKey: restaurant.customerGoogleAnalyticsKey,
        defaultGoogleAnalyticsKey: restaurantStore.defaultGoogleAnalyticsKey,
        order_value: basketStore.finalPriceWithOffers,
        transactionId: orderPaymentMethodsStore.orderId,
        country: restaurant.countryCode,
        event: AnalyticsEvent.SUCCESSFUL_PAYMENT,
        ecommerce: {
          currencyCode: branch.currencyCode,
          purchase: {
            actionField: {
              id: orderPaymentMethodsStore.orderId,
              affiliation: branch.branchName,
              revenue: basketStore.finalPriceWithOffers,
              shipping: basketStore.deliveryFee,
              coupon: couponsStore.couponCode
            },
            products: this._productsFromBasket()
          }
        }
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackFailedPayment() {
    const {
      basketStore,
      restaurantStore,
      orderPaymentMethodsStore
    } = this.root;

    const { restaurant, branch } = restaurantStore;

    const tagManagerArgs = {
      dataLayer: {
        googleAnalyticsKey: restaurant.customerGoogleAnalyticsKey,
        defaultGoogleAnalyticsKey: restaurantStore.defaultGoogleAnalyticsKey,
        order_value: basketStore.finalPriceWithOffers,
        country: restaurant.countryCode,
        transactionId: orderPaymentMethodsStore.orderId,
        event: AnalyticsEvent.FAILED_PAYMENT,
        ecommerce: {
          currencyCode: branch.currencyCode,
          checkout: {
            actionField: {
              step: 3,
              option: basketStore.finalPriceWithOffers
            },
            products: this._productsFromBasket()
          }
        }
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackGlobalError(link: string) {
    const {
      restaurantStore,
      restaurantStore: { restaurant }
    } = this.root;

    const tagManagerArgs = {
      dataLayer: {
        googleAnalyticsKey: restaurant.customerGoogleAnalyticsKey,
        defaultGoogleAnalyticsKey: restaurantStore.defaultGoogleAnalyticsKey,
        event: AnalyticsEvent.GLOBAL_ERROR,
        restaurantLink: link
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackThankYouPage(transactionId: string) {
    const tagManagerArgs = {
      dataLayer: {
        event: AnalyticsEvent.TRACK_THANK_YOU_PAGE,
        transactionId
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackPhoneNumberTopBar() {
    const tagManagerArgs = {
      dataLayer: {
        event: AnalyticsEvent.PHONE_NUMBER_TOP_BAR
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackPhoneNumberLegalPage() {
    const tagManagerArgs = {
      dataLayer: {
        event: AnalyticsEvent.PHONE_NUMBER_LEGAL_PAGE
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackPhoneNumberSuccessfulPayment() {
    const tagManagerArgs = {
      dataLayer: {
        event: AnalyticsEvent.PHONE_NUMBER_SUCCESSFUL_PAYMENT
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackPhoneNumberFailedOrder() {
    const tagManagerArgs = {
      dataLayer: {
        event: AnalyticsEvent.PHONE_NUMBER_FAILED_ORDER
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackPhoneNumberAppMainPage() {
    const tagManagerArgs = {
      dataLayer: {
        event: AnalyticsEvent.PHONE_NUMBER_APP_MAIN_PAGE
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackPhoneNumberAppSidemenu() {
    const tagManagerArgs = {
      dataLayer: {
        event: AnalyticsEvent.PHONE_NUMBER_APP_SIDEMENU
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackConfirmedOrder() {
    const tagManagerArgs = {
      dataLayer: {
        event: AnalyticsEvent.CONFIRMED_ORDER
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  trackCanceledOrder() {
    const tagManagerArgs = {
      dataLayer: {
        event: AnalyticsEvent.CANCELED_ORDER
      },
      dataLayerName: 'PageDataLayer'
    };

    TagManager.dataLayer(tagManagerArgs);
  }
}
