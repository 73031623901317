import React from 'react';

import style from './Phone.module.scss';

type PhoneProps = {
  phone: string;
  onClick?: () => void;
};

const Phone = ({ phone, onClick }: PhoneProps) => (
  <div className={style.Phone}>
    <i className={`fas fa-phone ${style.icon}`} />
    <a href={`tel:${phone}`} data-testid="website-phone" onClick={onClick}>
      {phone}
    </a>
  </div>
);

export default Phone;
