import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import ShellModalsRoute from 'client/routes/ShellModalsRoute/ShellModalsRoute';

import states from '../enums/states.enum';
import ProductCertificate from '../themes/theme-hermes/components/product_certificate';
import BasketAddressShell from '../themes/theme-hermes/theme-hermes-shell/components/address';
import ShellBasket from '../themes/theme-hermes/theme-hermes-shell/components/basket-shell';
import BranchList from '../themes/theme-hermes/theme-hermes-shell/components/branch-list-shell/index';
import CategoryListAlt from '../themes/theme-hermes/theme-hermes-shell/components/category-list/categoryListAlt';
import CheckoutShell from '../themes/theme-hermes/theme-hermes-shell/components/checkout';
import DeliveryInfoPage from '../themes/theme-hermes/theme-hermes-shell/components/delivery_info_page/index';
import HomeScreen from '../themes/theme-hermes/theme-hermes-shell/components/home-shell/index';
import MobileShellSidebar from '../themes/theme-hermes/theme-hermes-shell/components/mobile-shell-sidebar';
import NewsList from '../themes/theme-hermes/theme-hermes-shell/components/news_list/index';
import OpeningTimesComponent from '../themes/theme-hermes/theme-hermes-shell/components/opening-times/index';
import ProductsListShell from '../themes/theme-hermes/theme-hermes-shell/components/products_list/index';
import SideBarMenuShell from '../themes/theme-hermes/theme-hermes-shell/components/shell-sidebar-menu/index';
import { generateRoutePath } from '../utils/routing';

@(withRouter as any)
@inject('store')
@observer
export default class ShellRoute extends React.Component {
  componentDidMount() {
    this._showNativeLoader(false);
  }

  /**
   * Show or hide native loading screen
   * @param isShow
   */
  _showNativeLoader = (isShow) => {
    if (typeof Android !== 'undefined' && Android !== null) {
      Android.showLoader(isShow);
    }
  };

  /**
   * Method for open or close sidebar menu
   */
  _onSetOpenSidebar = (open) => {
    if (open) {
      this.props.store.themesStore.showSidebar();
    } else {
      this.props.store.themesStore.hideSidebar();
    }
  };

  /**
   * Method for close sidebar menu
   */
  _onCloseSidebar() {
    this.props.store.themesStore.hideSidebar();
  }

  render() {
    const { themesStore } = this.props.store;
    const { shareContent } = this.props.store.restaurantStore;
    const phone = this.props.store.restaurantStore.branch.getWebsitePhone;
    const { hostName } = this.props.store.restaurantStore;
    const { isShellCategoryHorizontalType } = themesStore;

    const {
      isShellThemeActive,
      isPortalAppThemeActive
    } = this.props.store.themesStore;

    const sideBarMenu = (
      <SideBarMenuShell
        shareContent={shareContent}
        closeMenuAction={this._onCloseSidebar.bind(this)}
        isHidden={false}
        phone={phone}
        isShellThemeActive={isShellThemeActive}
        isPortalAppThemeActive={isPortalAppThemeActive}
      />
    );

    return (
      <MobileShellSidebar
        pullRight
        sidebar={sideBarMenu}
        open={this.props.store.themesStore.sidebarOpen}
        onSetOpen={this._onSetOpenSidebar.bind(this)}
        isEnabledAnimations={
          !this.props.store.themesStore.isScreenshotModeActive
        }
        contentId="mobile-shell-content"
      >
        <div className={classNames('shell-wrapper')}>
          <Switch>
            {!isShellCategoryHorizontalType && (
              <Route
                path={`${generateRoutePath(
                  `:branchId/${states.cat}/:catId/:subId`,
                  hostName
                )}`}
                component={ProductsListShell}
              />
            )}
            {!isShellCategoryHorizontalType && (
              <Route
                path={`${generateRoutePath(
                  `:branchId/${states.cat}/:catId`,
                  hostName
                )}`}
                component={ProductsListShell}
              />
            )}
            <Route
              path={`${generateRoutePath(`:branchId/${states.cat}`, hostName)}`}
              component={CategoryListAlt}
            />
            <Route
              path={`${generateRoutePath(
                `:branchId/${states.address}`,
                hostName
              )}`}
              component={BasketAddressShell}
            />
            <Route
              path={`${generateRoutePath(
                `:branchId/${states.basket}`,
                hostName
              )}`}
              component={ShellBasket}
            />
            <Route
              path={`${generateRoutePath(
                `:branchId/${states.checkout}`,
                hostName
              )}`}
              component={CheckoutShell}
            />
            <Route
              path={`${generateRoutePath(
                `:branchId/${states.news}`,
                hostName
              )}`}
              component={NewsList}
            />
            <Route
              path={`${generateRoutePath(
                `:branchId/${states.branchList}`,
                hostName
              )}`}
              component={BranchList}
            />
            <Route
              path={`${generateRoutePath(
                `:branchId/${states.openingTimes}`,
                hostName
              )}`}
              component={OpeningTimesComponent}
            />
            <Route
              path={`${generateRoutePath(
                `:branchId/${states.deliveryInfo}`,
                hostName
              )}`}
              component={DeliveryInfoPage}
            />
            <Route
              path={`${generateRoutePath(
                `:branchId/${states.productCertificate}`,
                hostName
              )}`}
              component={ProductCertificate}
            />
            <Route
              exact
              path={`${generateRoutePath(':branchId', hostName)}`}
              component={HomeScreen}
            />
          </Switch>
          <Route
            path={`${generateRoutePath(':branchId', hostName)}`}
            component={ShellModalsRoute}
          />
        </div>
      </MobileShellSidebar>
    );
  }
}
