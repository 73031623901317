/* eslint-disable prefer-destructuring */
const theme = process.env.THEME;
const apiUrlVersion0 = '/api';
const apiUrlVersion2 = '/api2.5';
const apiUrlVersion3 = '/api3';
const apiUrlVersion3_1 = '/api3/1';
const configApi = '/api/config';
const version = '3.41.0';
const IS_SERVER = typeof localStorage === 'undefined';
const IS_CLIENT = typeof localStorage !== 'undefined';
const apiUrl = process.env.API_URL;
const PHP_INTEGRATION = process.env.PHP_INTEGRATION;
const BACKEND_API = process.env.BACKEND_API;
const sentryDSN = process.env.SENTRY_DSN;
const defaultLanguage = 'de';
const googlePlacesSupportedCountryCodes = ['de', 'pl', 'at', 'ch', 'cz'];
const newBackendApiUrl = BACKEND_API;
const isProduction = process.env.NODE_ENV === 'production';
const googlePlacesAPIKey = process.env.GOOGLE_MAPS_API_KEY;
const cookieName = 'AppSmart';

const supportedLanguages = [
  { title: 'Deutsch', code: 'de' },
  { title: 'English', code: 'en' },
  { title: 'Polski', code: 'pl' },
  { title: 'Česky', code: 'cs' }
] as const;

const phpIntegrationUrl =
  PHP_INTEGRATION || 'https://php-integration.devteam.win';

const hungerRestaurantToken =
  process.env.API_URL !== 'https://dev.delivery-app.app-smart.services'
    ? 'Ro61LGX5fhushWZH'
    : '';

const isFeeFromTotalPrice = true;

export {
  theme,
  apiUrlVersion0,
  apiUrlVersion2,
  apiUrlVersion3,
  apiUrlVersion3_1,
  configApi,
  version,
  sentryDSN,
  defaultLanguage,
  supportedLanguages,
  apiUrl,
  newBackendApiUrl,
  isProduction,
  googlePlacesAPIKey,
  googlePlacesSupportedCountryCodes,
  phpIntegrationUrl,
  hungerRestaurantToken,
  IS_SERVER,
  IS_CLIENT,
  isFeeFromTotalPrice,
  cookieName
};
