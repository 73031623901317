import { toJS } from 'mobx';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import googleService from 'client/services/googleService';
import { RootContext } from 'client/stores';

import AdvancedDeliveryFormFC from './AdvancedDeliveryDeForm';
import {
  AdvancedDeliveryDeFormData,
  AdvancedDeliveryDeFormProps
} from './AdvancedDeliveryDeForm.type';

const AdvancedDeliveryForm = ({
  className
}: Pick<AdvancedDeliveryDeFormProps, 'className'>) => {
  const { t, i18n } = useTranslation(['address_form', 'validationErrors']);

  const {
    deliveryAddressStore,
    restaurantStore,
    analyticsStore,
    themesStore
  } = useContext(RootContext);

  const branchId = restaurantStore.branch.branchId ?? '';
  const { address } = deliveryAddressStore;

  const defaultValues = {
    firstName: address.first_name,
    lastName: address.last_name,
    company: address.company,
    googleAddress: toJS(address.selectedGoogleAddress) ?? undefined,
    email: address.email,
    phone: address.phone,
    comment: address.comment
  };

  const handleSubmit = useCallback(
    (values: AdvancedDeliveryDeFormData) => {
      const {
        city,
        street,
        streetNumber,
        zip
      } = googleService.getAddressFromGeoInfo(values.googleAddress);

      const addressValues = {
        first_name: values.firstName.trim(),
        last_name: values.lastName.trim(),
        company: values.company.trim(),
        email: values.email.trim(),
        phone: values.phone.trim(),
        street,
        street_no: streetNumber,
        city,
        zip,
        comment: values.comment.trim(),
        selectedGoogleAddress: values.googleAddress
      };

      deliveryAddressStore.updateAddress(addressValues);

      const area = {
        areaCode: zip,
        sublocality: city
      };

      deliveryAddressStore.onZipChange(area);

      analyticsStore.sendProvidePersonalInformation();
    },
    [deliveryAddressStore]
  );

  return (
    <AdvancedDeliveryFormFC
      className={className}
      t={t}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      lang={i18n.language as AppLang}
      branchId={branchId}
      isMobile={themesStore.isMobile}
    />
  );
};

export default AdvancedDeliveryForm;
