import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RoundShellButton from '../../buttons-shell/round-button';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import images from '../../../../../../enums/images_enums/hermes_images.enum';

import { ORDER_TYPES } from '../../../../../../enums/order_types.enum';
import './ButtonsBar.scss';

@withRouter
class DeliveryPickupButtons extends Component {
  static propTypes = {
    changeOrderTypeAction: PropTypes.func
  };

  render() {
    return (
      <div
        className="delivery-pickup-buttons-bar"
        style={this.props.shellDeliveryPickupButtonsBarStyle}
      >
        {this.props.isPickupAvailable && (
          <RoundShellButton
            label={this.props.t('footer:pickupAppButton')}
            action={() => this.props.changeOrderTypeAction(ORDER_TYPES.PICKUP)}
            image={images.iconPickup}
            svgClassName="pickup-button-image"
            className="pickup-button"
            dataTestId="pickup-btn"
          />
        )}
        {this.props.isDeliveryAvailable && (
          <RoundShellButton
            label={this.props.t('footer:deliveryAppButton')}
            action={() =>
              this.props.changeOrderTypeAction(ORDER_TYPES.DELIVERY)
            }
            image={images.iconDelivery}
            svgClassName="delivery-button-image"
            className="delivery-button"
            dataTestId="delivery-btn"
          />
        )}
      </div>
    );
  }
}

export default withTranslation([
  'enter_address_modal',
  'footer',
  'common',
  'opening_hours'
])(DeliveryPickupButtons);
