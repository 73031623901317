import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Measure from 'react-measure';

// CSS

import { Tooltip } from 'antd';
import './ButtonStandard.scss';

/**
 * Standard button component
 */
export default class ButtonStandard extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    type: PropTypes.string,
    smallHeight: PropTypes.bool,
    isShell: PropTypes.bool,
    bigHeight: PropTypes.bool,
    customClass: PropTypes.string,
    primary: PropTypes.bool,
    id: PropTypes.string,
    isMobile: PropTypes.bool,
    label: PropTypes.string,
    componentLabel: PropTypes.object,
    style: PropTypes.object,
    activeStyle: PropTypes.object,
    dataTestId: PropTypes.string
  };

  static defaultProps = {
    onClick: () => {},
    disabled: false,
    active: false,
    type: 'button',
    smallHeight: false,
    customClass: '',
    id: '',
    isMobile: false,
    label: '',
    isShell: false
  };

  state = {
    dimensions: {
      width: -1,
      height: -1
    }
  };

  /**
   * Render standard button for mobile (without tooltips)
   * @returns {*}
   */
  mobileButton = () => (
    <div
      onClick={this.props.onClick}
      className={classNames(
        {
          'button-standard': true,
          small: this.props.smallHeight,
          big: this.props.bigHeight,
          shell: this.props.isShell,
          primary: this.props.primary,
          disabled: this.props.disabled,
          'button-standard-active': this.props.active
        },
        this.props.customClass
      )}
      style={this.props.active ? this.props.activeStyle : this.props.style}
      data-testid={this.props.dataTestId}
    >
      <div className="text">
        {this.props.label}
        {this.props.componentLabel ? this.props.componentLabel : null}
      </div>
    </div>
  );

  /**
   * Render standard button for desktop (with tooltips)
   * @returns {*}
   */
  desktopButton = () => (
    <Measure
      offset
      scroll
      onResize={(contentRect) => {
        this.setState({
          offsetWidth: contentRect.offset.width,
          scrollWidth: contentRect.scroll.width
        });
      }}
    >
      {({ measureRef }) => (
        <div
          ref={measureRef}
          onClick={this.props.onClick}
          className={classNames(
            {
              'button-standard': true,
              small: this.props.smallHeight,
              big: this.props.bigHeight,
              shell: this.props.isShell,
              primary: this.props.primary,
              disabled: this.props.disabled,
              'button-standard-active': this.props.active
            },
            this.props.customClass
          )}
          data-testid={this.props.dataTestId}
        >
          <Tooltip
            title={
              this.state.offsetWidth < this.state.scrollWidth
                ? this.props.label
                : ''
            }
          >
            <div className="text">
              {this.props.componentLabel ? this.props.componentLabel : null}
              {this.props.label}
            </div>
          </Tooltip>
        </div>
      )}
    </Measure>
  );

  render() {
    return this.props.isMobile || this.props.isShell
      ? this.mobileButton()
      : this.desktopButton();
  }
}
