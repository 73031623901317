// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Phone_Phone__3rwt8 a {\n  color: var(--primaryTextColor); }\n\n.Phone_Phone__3rwt8 .Phone_icon__4gy9l {\n  color: var(--primaryTextColor);\n  -webkit-transform: scale(-1, 1);\n          transform: scale(-1, 1);\n  width: 13px; }\n", "",{"version":3,"sources":["webpack://src/client/themes/theme-hermes/components/delivery_info/Phone.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAA8B,EAAE;;AAElC;EACE,8BAA8B;EAC9B,+BAAuB;UAAvB,uBAAuB;EACvB,WAAW,EAAE","sourcesContent":[".Phone a {\n  color: var(--primaryTextColor); }\n\n.Phone .icon {\n  color: var(--primaryTextColor);\n  transform: scale(-1, 1);\n  width: 13px; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Phone": "Phone_Phone__3rwt8",
	"icon": "Phone_icon__4gy9l"
};
export default ___CSS_LOADER_EXPORT___;
