import classnames from 'classnames';
import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import useOutsideClick from 'client/hooks/useOutsideClick';
import { ReactComponent as Chevron } from 'client/icons/chevron.svg';
import Dropdown from 'client/ui/Dropdown';
import Input from 'client/ui/Input';

import css from './Select2.module.scss';
import { Select2Props } from './Select2.type';

const Select2 = <T,>({
  className,
  disabled = false,
  error = false,
  getActiveOption,
  getOptionLabel,
  onChange,
  options,
  placeholder = '',
  value
}: Select2Props<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, changeInputValue] = useState('');
  const ref = useRef<HTMLDivElement | null>(null);

  const toggleOpen = () => {
    if (!isOpen) {
      changeInputValue('');
    }

    setIsOpen(!isOpen);
  };

  const close = () => {
    setIsOpen(false);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      close();
    }
  };

  const handleOptionClick = useCallback(
    (option: T) => {
      onChange(option);

      changeInputValue(getOptionLabel(option));

      close();
    },
    [onChange]
  );

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      changeInputValue(event.target.value);
    },
    []
  );

  useOutsideClick([ref], close);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const filterOptions = useMemo(
    () =>
      options.filter((option) =>
        getOptionLabel(option)
          .toLocaleLowerCase()
          .includes(inputValue.toLowerCase())
      ),
    [inputValue]
  );

  return (
    <div
      className={classnames(css.Select2, className, disabled && css.disabled)}
      onClick={toggleOpen}
      onKeyDown={() => null}
      role="button"
      tabIndex={0}
      ref={ref}
      data-testid="Select2"
    >
      <div className={classnames(css.container, isOpen && css.opened)}>
        <Input
          disabled={disabled}
          className={classnames(
            css.input,
            isOpen && css.opened,
            error && css.error
          )}
          value={inputValue}
          placeholder={getOptionLabel(value) || placeholder}
          onChange={handleChangeInput}
        />
        <Chevron className={css.chevron} />
      </div>
      {isOpen && (
        <Dropdown
          className={css.dropdown}
          options={filterOptions}
          onClick={handleOptionClick}
          getActiveOption={getActiveOption}
          getOptionLabel={getOptionLabel}
        />
      )}
    </div>
  );
};

export { Select2 };

export default memo(Select2) as typeof Select2;
