import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import HeaderShell from '../header-shell';
import ShellLayout from '../shell-layout';

import NewsCell from './news_cell';

import './newsList.scss';

/**
 * Component to render news list
 */
@withRouter
@inject('store')
@observer
class NewsList extends Component {
  static propTypes = {};

  static defaultProps = {};

  componentDidMount() {
    this.props.store.newsStore.loadNews();
  }

  /**
   * Method for back action of routing
   */
  backAction = () => {
    this.props.history.goBack();
  };

  /**
   * Method to render list of news
   * @param {[NewsModel]} news - array of news
   */
  _renderNewsList = (news) =>
    news.length > 0
      ? news.map((newsItem) => (
          <NewsCell
            key={newsItem.id}
            newsItem={newsItem}
            utcOffset={this.props.store.restaurantStore.restaurant.getUtcOffset}
          />
        ))
      : null;

  /**
   * Method to render message if there is no news from this customer
   */
  _showEmptyMessage = () => {
    const { t } = this.props;
    const { news, loaded } = this.props.store.newsStore;

    return loaded && !news.length ? (
      <figure className="empty-list-msg">
        <header>{t('news:No-messages')}</header>
        <main>{t('news:No-messages-text')}</main>
      </figure>
    ) : null;
  };

  _renderHeader = () => (
    <HeaderShell
      title={this.props.t('title:lblNews')}
      style={this.props.store.themesStore.headerStyle()}
      backAction={this.backAction}
      isPortalAppThemeActive={this.props.store.themesStore.isPortalAppThemeActive}
      branchName={this.props.store.restaurantStore.branch.branchName}
    />
  );

  _renderContent = () => {
    const { news } = this.props.store.newsStore;

    return (
      <div id="news-page-shell">
        {this._showEmptyMessage()}
        <div className="news-list">{this._renderNewsList(news)}</div>
      </div>
    );
  };

  render() {
    return (
      <ShellLayout
        shellHeader={this._renderHeader()}
        shellContent={this._renderContent()}
      />
    );
  }
}

export default withTranslation(['title'])(NewsList);
