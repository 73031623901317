import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Components
import CategoryMenuDesktop from './CategoryMenuDesktop';
import RestaurantLogo from './_logo/RestaurantLogo';
import Metadata from '../../../../../components/meta_data';

// utils
import { generateLinkFor } from '../../../../../utils/routing';

// CSS
import './CategoryMenu.scss';

//Enums
import MetadataTypes from '../../../../../enums/metadata.enums';

/**
 * Category menu component is used to show
 * category list in header.
 * @name ReactCategoryMenuComponent
 */
@inject('store')
@withRouter
@observer
class CategoryMenu extends Component {
  /**
   * Logo click action
   * @param e
   * @private
   */
  _onLogoClick(e) {
    e.preventDefault();
    this.props.store.productsStore.clearSearchingRequest();
    // open home page
    if (this.props.store.homepageStore.isHomepageAvailable) {
      this.props.store.categoryMenuStore.setActiveCategory(-2);
      this.props.store.categoryMenuStore.saveActiveCategoryToStorage(-2);
      this.props.history.replace(
        generateLinkFor(
          this.props.store.restaurantStore.branch.branchId,
          this.props
        )
      );
    } else {
      // Open customer landing page
      if (this.props.store.restaurantStore.restaurant.homePageAvailable) {
        window
          .open(
            this.props.store.restaurantStore.restaurant
              .getCustomerLandingPageUrl,
            '_blank'
          )
          .focus();
      }
    }
  }

  render() {
    const {
      categoryMenuStore,
      themesStore,
      restaurantStore,
      homepageStore,
      productsStore,
      seoDataStore
    } = this.props.store;
    const altText = seoDataStore.makeSeoBranchTitle('');
    return (
      <div id="category-menu-wrapper" className="white-block">
        <RestaurantLogo
          altText={altText}
          logo={restaurantStore.restaurant.logo}
          isLogoActionAvailable={
            homepageStore.isHomepageAvailable ||
            restaurantStore.restaurant.homePageAvailable
          }
          onLogoClick={this._onLogoClick.bind(this)}
        />

        <CategoryMenuDesktop
          activeCategory={categoryMenuStore.activeCategory}
          categories={categoryMenuStore.categories}
          hermesThemeCountOfCategories={
            categoryMenuStore.hermesThemeCountOfCategories
          }
          hermesThemeCountOfColumn={categoryMenuStore.hermesThemeCountOfColumn}
          shouldApplyWideTheme={categoryMenuStore.shouldApplyWideTheme}
          categoryTheme={themesStore.categoryTheme}
          widthHermesThemeWithPics={categoryMenuStore.widthHermesThemeWithPics}
          hideActiveCategory={productsStore.isSearchEnabled}
        />
        <Metadata metadataType={MetadataTypes.ACTIVE_CATEGORY} />
      </div>
    );
  }
}
export default withTranslation(['basket'])(CategoryMenu);
