import classnames from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { InputProps } from './Input.type';

const Input = ({
  value,
  className,
  inputRef,
  dataTestId,
  onChange,
  ...rest
}: InputProps) => {
  const [inputValue, changeInputValue] = useState(value);

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    changeInputValue(event.target.value);

    onChange?.(event);
  };

  useEffect(() => {
    changeInputValue(value);
  }, [value]);

  return (
    <input
      className={classnames(className)}
      ref={inputRef}
      value={inputValue}
      onChange={onChangeValue}
      data-testid={dataTestId}
      {...rest}
    />
  );
};

export { Input };

export default React.memo(Input);
