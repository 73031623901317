import classNames from 'classnames';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import images from '../../../../../../enums/images_enums/hermes_images.enum';
import PAYMENT_IMAGES from '../../../../../../enums/images_enums/payment_methods_images.enum';

import { PaymentMethodRowProps } from './PaymentMethod.type';

const PaymentMethodRow = ({
  style,
  method,
  disabled,
  disableReason,
  afterMount,
  setActive
}: PaymentMethodRowProps) => {
  const { t } = useTranslation(['order_payment_methods']);
  const paymentImage = PAYMENT_IMAGES.find((image) => image.id === method.id);

  useEffect(() => {
    afterMount?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPaymentImage = (
    imageMethod: PaymentMethodRowProps['method'],
    paymentMethodImage: typeof PAYMENT_IMAGES[number]
  ) => (
    <div className="payment-image ">
      <img
        title={`${imageMethod.description}`}
        alt={`logo ${imageMethod.description}`}
        key={imageMethod.id}
        src={paymentMethodImage.image}
      />
    </div>
  );

  return (
    <label
      className={classNames({
        active: disabled ? false : method.active,
        disabled
      })}
      key={method.id}
      onChange={setActive}
      htmlFor={`payment-method-${method.localKey}-${method.id}`}
    >
      <div className="radio">
        <input
          type="radio"
          name="paymentMethod"
          id={`payment-method-${method.localKey}-${method.id}`}
        />
        {method.active ? (
          <div className="radio-circle-active">
            <div
              className="radio-checkbox"
              style={style && method.active ? style.activeRadio : undefined}
            >
              <ReactSVG
                src={images.checkMarkIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    'class',
                    `${svg.getAttribute('class')} check-mark`
                  );

                  svg.setAttribute('style', 'width: 14px; height: 14px;');
                }}
              />
            </div>
          </div>
        ) : (
          <span
            className="radio-circle"
            style={style ? style.defaultSpan : undefined}
          />
        )}
      </div>
      <div className="paysystem">
        {paymentImage && renderPaymentImage(method, paymentImage)}
        <div className="description">
          <span>{t(method.localKey)}</span>
          {disableReason ? (
            <span className="description-subtext">{` (${disableReason})`}</span>
          ) : null}
        </div>
      </div>
    </label>
  );
};

export default memo(PaymentMethodRow);
