import { History } from 'history';
import { action, computed, observable, reaction } from 'mobx';
import { v1 } from 'uuid';

import {
  fetchProduct,
  fetchProductsByBranch,
  fetchProductsByCategoryId
} from 'client/api/queries';
import { ResponseProduct } from 'client/api/types';
import animation from 'client/enums/animation.enum';
import { ProductModel } from 'client/models/product.model';
import RootStore from 'client/stores';
import { shuffle } from 'client/utils/functions';
import openModal from 'client/utils/openModal';

/**
 * Product class. Used to work with products in store.
 * @constructor
 * @param {instance} api - {@link API} instance.
 * @param {instance} storage - {@link storage} instance.
 * @property {array <object>} products - Observable array of products.
 * @property {number} animateId - Product Id to animate
 * @property {array<object>} branchProducts - full product list of branch
 */
class ProductsStore {
  root: RootStore;

  constructor(root: RootStore, state) {
    Object.assign(this, state);

    this.root = root;

    if (state.products?.length) {
      const products = state.products.map(
        (product) => new ProductModel(product.id, product, root)
      );

      this.setActiveProducts(products);
    }
  }

  prevSearchingProducts: ProductModel[] = [];

  @observable products: ProductModel[] = [];

  @observable branchProducts: ProductModel[] = [];

  @observable searchProducts: ProductModel[] = [];

  @observable animateId = 0;

  @observable searchingRequest = '';

  @observable isSearching = false;

  COUNT_SYMBOLS_TO_SEARCH_PRODUCT_NAME = 3;

  COUNT_SYMBOLS_TO_SEARCH_PRODUCT_NUMBER = 1;

  /**
   * Method for getting sorted products list by availability parameter
   * @returns {[ProductModel]} - sorted array of products
   */
  @computed get sortedProductsList() {
    if (!this.products) {
      return [];
    }

    return this.products.sort((a, b) => {
      if (a.isValidForOrder === b.isValidForOrder) {
        return 0;
      }

      if (a.isValidForOrder) {
        return -1;
      }

      return 1;
    });
  }

  /**
   * Method for recognition filling branch product list
   * @returns {boolean}
   */
  @computed get isBranchProductListGot() {
    return !!this.branchProducts.length;
  }

  /**
   * Method for sorting products list by availability parameter
   * @returns {[ProductModel]} - sorted array of products
   */
  @action sortedProductsListFromArray(productsArray?: ProductModel[]) {
    if (!productsArray) {
      return [];
    }

    return productsArray.sort((a, b) => {
      if (a.isValidForOrder === b.isValidForOrder) {
        return 0;
      }

      if (a.isValidForOrder) {
        return -1;
      }

      return 1;
    });
  }

  /**
   * Method to full products from array and create instance for each product automatically.
   * @param {array <object>} products - The list of products.
   * @memberof ProductsStore#
   * @method productsFromJS
   */
  @action productsFromJS(products: ResponseProduct[]) {
    const filteredProducts = products.filter(
      ({ sizes_o }) => Object.keys(sizes_o ?? {}).length
    );

    const productModels = filteredProducts.map((product) => {
      const model = { ...product, sizes: product.sizes_o };

      return new ProductModel(product.id, model, this.root);
    });

    return productModels;
  }

  /**
   * Method to map json to Product model
   * @param product_id - id
   * @param product - json
   * @returns {ProductModel}
   */
  @action productFromJS(productId, product) {
    return new ProductModel(productId, product, this.root);
  }

  /**
   * Method to set animation after adding product to basket
   * @param {number} productID - Product id
   * @memberof ProductsStore#
   * @method animateProduct
   */
  @action animateProduct(productID) {
    if (!this.root.restaurantStore.cantAddProductsToBasket) {
      this.animateId = productID;

      setTimeout(() => {
        this.animateId = 0;
      }, animation.time.buttonToBuyProduct);
    }
  }

  /**
   * Method to get products by category.
   * @param {number} categoryId - unique identifier of category.
   * @param {boolean} isDemoModeActive - state of loading demo data
   * @memberof ProductsStore
   * @method loadProducts
   */
  @action
  async loadProducts(categoryId: number, isDemoModeActive = false) {
    const { branchId } = this.root.restaurantStore.branch;

    try {
      const response = await fetchProductsByCategoryId(branchId, categoryId);
      const availableProducts = response.d.filter(({ available }) => available);
      const products = this.productsFromJS(availableProducts);

      if (isDemoModeActive) {
        this.addDemoProductsToBasket(products);
      }

      return products;
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Get all products by branch
   * @return {*}
   */
  @action
  async loadProductsByBranch() {
    const { branch } = this.root.restaurantStore;

    try {
      const { content } = await fetchProductsByBranch(branch.branchId);

      this.branchProducts = this.productsFromJS(content);

      if (!this.products.length) {
        this.setActiveProducts(this.branchProducts);
      }

      return this.branchProducts;
    } catch (e) {
      console.error(e);
    }

    return this.branchProducts;
  }

  /**
   * Method for adding demo products to basket
   * @param {[ProductModel]} products
   * @param isDemoModeActive
   */
  @action addDemoProductsToBasket(products: ProductModel[]) {
    const { basketStore } = this.root;

    if (!basketStore.hasProducts) {
      const demoProducts = shuffle(products)
        .filter((product) => product.is_fixed)
        .slice(0, 3);

      demoProducts.forEach((product) => {
        const productCount = Math.floor(Math.random() * 3) + 1;

        this.root.basketStore.addProductToBasket(
          product,
          productCount,
          false,
          false,
          true
        );
      });
    }
  }

  /**
   * Method to get single product
   * @param {number} productId - unique identifier of product.
   * @memberof ProductsStore#
   * @method loadSingleProduct
   */
  // TODO Can be potentially removed
  @action
  async loadSingleProduct(productId: number) {
    const { branch } = this.root.restaurantStore;

    try {
      const response = await fetchProduct(branch.branchId, productId);

      return this.productFromJS(productId, response.d);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Method to add single product to products array
   * @param {ProductModel} productModel - product object.
   * @memberof ProductsStore#
   * @method addSingleProductToCommonArray
   */
  @action addSingleProduct(product: ProductModel) {
    const { id } = product;

    if (!this.hasProduct(id)) {
      this.products.push(product);
    }
  }

  /**
   * Method to set product of  current active category
   * @param {array <object>} products - The list of products.
   * @memberof ProductsStore#
   * @method setActiveProducts
   */
  @action setActiveProducts(products: ProductModel[]) {
    this.products = products;
  }

  /**
   * Method to check product's availability in products array
   * @param {number} productId - The id of search product
   * @memberof ProductsStore#
   * @method isProductsArrayHasProductWithId
   */
  hasProduct(productId: number) {
    return this.products.some(({ id }) => id === productId);
  }

  /**
   * Method to serialize every product in product list.
   * @return {object} serialized product array
   */
  getToJS() {
    return this.products.map((product) => product.getToJS());
  }

  /**
   * Method to get one product form the products list by id.
   * @param {number} productId - unique identifier of the product.
   * @return {object} serialized product
   */
  getProduct(productId: number): ProductModel | undefined {
    if (this.isSearchEnabled) {
      return this.searchProducts.find(({ id }) => id === productId);
    }

    return this.products.find(({ id }) => id === productId);
  }

  /**
   * Method to open shell theme
   */
  _openAddressModal = (history: History, productId?: string) => {
    const addressUrl = openModal('addressModal', { productId });

    history.push(addressUrl);
  };

  /**
   * Method add handle click to product
   * @param product
   * @param props
   * @param {boolean} goBackAfterAdding - if go back after adding product to basket
   * @returns {*}
   */
  @action addProduct(
    product: ProductModel,
    history: History,
    goBackAfterAdding = false
  ) {
    const { restaurantStore, deliveryAddressStore, basketStore } = this.root;

    const {
      cantAddProductsToBasket,
      isShopClosed,
      isLocationChosen
    } = restaurantStore;

    const { isDelivery } = deliveryAddressStore;

    if (isLocationChosen || !isDelivery) {
      if (product.is_fixed && product.isValidForOrder) {
        if (cantAddProductsToBasket) {
          console.warn('shop is closed');

          return;
        }

        if (goBackAfterAdding) {
          history.goBack();
        }

        product._uuid = v1();

        basketStore.addProductToBasket(product, 1);

        return;
      }

      const extrasUrl = openModal('extrasModal', {
        productId: product.id.toString()
      });

      history.replace(extrasUrl);
    } else {
      if (isShopClosed) return;

      this.root.basketStore.setLastProductClicked(product.id);

      this._openAddressModal(history, product.id);
    }
  }

  /**
   * Action to add product to basket by Id
   * @param productId
   */
  @action
  async addProductById(productId: number, history: History) {
    let product = this.getProduct(productId);

    if (!product) {
      product = await this.loadSingleProduct(productId);

      this.addSingleProduct(product);
    }

    this.addProduct(product, history);
  }

  /**
   * Method to set searching request
   * @param {string} request
   */
  @action setSearchingRequest(request: string) {
    this.searchingRequest = request;
  }

  /**
   * Method to clear searching request
   */
  @action clearSearchingRequest() {
    this.searchingRequest = '';
  }

  @action clearBranchProducts() {
    this.branchProducts = [];
  }

  /**
   * Method to searching products by search request
   * @returns {array<ProductModel>}
   */
  searchProcess = reaction(
    () => this.searchingRequest,
    () => {
      if (this.startSearchingBy) {
        this.isSearching = true;

        let products: ProductModel[] = [];

        if (this.startSearchingBy.productNumber) {
          const foundProducts = this.searchProductsByField('no');

          products = products.concat(foundProducts);
        }

        if (this.startSearchingBy.productName) {
          const foundProducts = this.searchProductsByField('name');

          products = products.concat(foundProducts);
        }

        this.isSearching = false;

        this.searchProducts = products;
      } else if (!this.searchingRequest) {
        this.searchProducts = [];
      }
    },
    {
      delay: 1
    }
  );

  /**
   * Method for searching by field name
   */
  searchProductsByField = (field: keyof ProductModel) =>
    this.branchProducts.filter((product) => {
      const productField = product[field] || '';
      const searchText = this.searchingRequest.toLowerCase().trim();

      return productField.toLowerCase().includes(searchText);
    });

  /**
   * Method to check search activity
   * @returns {boolean}
   */
  @computed get isSearchEnabled() {
    return !!(this.searchProducts.length || this.startSearchingBy);
  }

  /**
   * Method to track when searching had started
   */
  @computed get startSearchingBy() {
    if (this.searchingRequest.length) {
      let isDigit = true;

      if (
        this.COUNT_SYMBOLS_TO_SEARCH_PRODUCT_NUMBER <
        this.COUNT_SYMBOLS_TO_SEARCH_PRODUCT_NAME
      ) {
        const pattern = `^\\d{${this.COUNT_SYMBOLS_TO_SEARCH_PRODUCT_NUMBER}}`;
        const regex = new RegExp(pattern);

        isDigit = regex.test(this.searchingRequest);
      }

      const searchingRequestFilled =
        this.searchingRequest.length >=
        this.COUNT_SYMBOLS_TO_SEARCH_PRODUCT_NAME;

      return isDigit || searchingRequestFilled
        ? {
            productNumber: isDigit || searchingRequestFilled,
            productName: searchingRequestFilled
          }
        : null;
    }

    return null;
  }

  /**
   * Add product or offer after choosing area code
   */
  isAddedProductAfterChosenAddress(props: any, offerHistoryPush: any): boolean {
    const { lastProductClickedId } = this.root.basketStore;
    const { offer } = this.root.offerStore;

    if (lastProductClickedId) {
      const product = this.getProduct(lastProductClickedId);

      if (!product?.is_fixed) {
        const extrasUrl = openModal('extrasModal', {
          productId: product?.id.toString() ?? ''
        });

        props.history.push(extrasUrl);
      } else {
        this.root.basketStore.addProductToBasket(product, 1);
      }

      return true;
    }

    if (offer?.id) {
      const offerUrl = openModal('offerModal', {
        offerType: offer.offerType as string,
        offerId: offer.id.toString()
      });

      if (offerHistoryPush) {
        offerHistoryPush(offerUrl);
      } else {
        props.history.push(offerUrl);
      }

      return true;
    }

    return false;
  }
}

export default ProductsStore;
