import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { Component, MouseEvent, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { withRouter } from 'react-router';

import AdditiveIconList from 'client/components/AdditiveIconList';
import { ReactComponent as NoDiscount } from 'client/icons/no-discount.svg';
import { ReactComponent as NoMbv } from 'client/icons/no-mbv.svg';
import Heading from 'client/ui/Heading';
import ProductNumber from 'client/ui/ProductNumber';
import Text from 'client/ui/Text';

import ProductSmallPictureMobile from '../../../../../../../components/ProductSmallPictureMobile';
import ProductStandardButton from '../../../../buttons/ProductStandardButton/ProductStandardButton';
import AllergenTooltip from '../../allergen-tooltip';
import ProductDefaultImage from '../product-default-image/ProductDefaultImage';
import ProductMetaData from '../product-seo';
import { IProductSmallPictures } from '../ProductCell.props.interface';
import ProductNewBadge from '../ProductNewBadge/product-new-badge';

import './ProductSmallPicture.scss';

interface IState {
  imageLoaded: boolean;
}

interface ITemplatingStyle {
  textColor: string;
  productNumberTextColor: string;
  descriptionOpacity: number;
  additivesOpacity: number;
  productNumberBorderColor: string;
  defaultPictureIconColor: string;
  defaultPictureBackgroundColor: string;
}

@observer
class ProductSmallPicture extends Component<IProductSmallPictures, IState> {
  state = {
    imageLoaded: false
  };

  containerRef = createRef<HTMLDivElement>();

  componentDidMount() {
    this.passSizeToParent();
  }

  passSizeToParent = () => {
    if (this.containerRef.current && this.props.setSize) {
      const { marginBottom, marginTop } = getComputedStyle(
        this.containerRef.current
      );

      this.props.setSize(
        this.containerRef.current.getBoundingClientRect().height +
          parseFloat(marginBottom) +
          parseFloat(marginTop)
      );
    }
  };

  /**
   * Handle click on button for purchasing
   */
  onButtonClick = (event: MouseEvent) => {
    this.props.onButtonClick(event, this.props.product);
  };

  /**
   * Handle click on product cell
   */
  onCellClick = (event: MouseEvent) => {
    this.props.onCellClick(event, this.props.product);
  };

  handleProductInfoClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    const { openProductModal, product } = this.props;

    openProductModal(event, product);
  };

  /**
   * Method for updating status of image loading
   */
  updateImageLoadingStatus = (success: boolean) => {
    this.setState({
      imageLoaded: success
    });
  };

  render() {
    const {
      isShopClosed,
      isNew,
      imagesEnabled,
      hideProductArticles,
      product,
      productCount,
      animated,
      isMobile,
      additivesNames,
      animationTargetBounds,
      currency,
      isShell,
      fullProductListDesign,
      productCellStyle,
      buttonStyle,
      separatorStyle,
      horizontalMenuProductStyle,
      foodIcons,
      language,
      showHalalCertificate,
      t
    } = this.props;

    const productAdditives = product.allergens || {};

    const {
      additivePrefixes = [],
      allergensPrefixes = []
    } = productAdditives as any;

    const additives = [...additivePrefixes, ...allergensPrefixes];

    let buttonText = '';

    if (product.isValidForOrder) {
      buttonText = product.hasSeveralSizes
        ? `${this.props.t ? this.props.t('product_view:lblFrom') : ''} ${
            product.getIntlPrice
          }`
        : product.getIntlPrice;
    } else {
      buttonText = this.props.t
        ? this.props.t('common:productNotAvailable')
        : '';
    }

    let templatingStyle: ITemplatingStyle;

    if (fullProductListDesign && horizontalMenuProductStyle) {
      templatingStyle = {
        textColor: horizontalMenuProductStyle.color,
        productNumberTextColor:
          horizontalMenuProductStyle.productNumberTextColor,
        descriptionOpacity: horizontalMenuProductStyle.descriptionOpacity,
        additivesOpacity: horizontalMenuProductStyle.additivesOpacity,
        productNumberBorderColor:
          horizontalMenuProductStyle.productNumberBorderColor,
        defaultPictureIconColor:
          horizontalMenuProductStyle.defaultPictureIconColor,
        defaultPictureBackgroundColor:
          horizontalMenuProductStyle.defaultPictureBackgroundColor
      };
    } else if (productCellStyle) {
      templatingStyle = {
        textColor: productCellStyle.headerTextColor,
        descriptionOpacity: productCellStyle.descriptionOpacity,
        additivesOpacity: productCellStyle.additivesOpacity,
        productNumberTextColor: productCellStyle.productNumberTextColor,
        productNumberBorderColor: productCellStyle.productNumberBorderColor,
        defaultPictureIconColor: productCellStyle.defaultPictureIconColor,
        defaultPictureBackgroundColor:
          productCellStyle.defaultPictureBackgroundColor
      };
    } else {
      templatingStyle = {} as ITemplatingStyle;
    }

    let additivesElement;

    if (isMobile) {
      additivesElement =
        additivesNames && Object.keys(additivesNames).length ? (
          <span style={{ opacity: templatingStyle.additivesOpacity }}>
            <AllergenTooltip allergens={additivesNames} />
          </span>
        ) : null;
    } else {
      additivesElement = (
        <span className="product-additives">{additives.join(', ')}</span>
      );
    }

    const defaultImage = (
      <ProductDefaultImage
        iconColor={templatingStyle.defaultPictureIconColor}
      />
    );

    return (
      <div
        ref={this.containerRef}
        className={classNames({
          'product-small-picture-container': true,
          'is-closed': isShopClosed,
          'is-mobile': isMobile,
          'full-list-products': fullProductListDesign
        })}
        onClick={this.onCellClick}
        style={separatorStyle}
        data-testid="product"
      >
        {isMobile ? (
          <ProductSmallPictureMobile
            onClick={this.onButtonClick}
            onProductInfoButtonClick={this.handleProductInfoClick}
            text={buttonText}
            animated={animated}
            productCount={productCount}
            animationTargetBounds={animationTargetBounds}
            isSoldOut={product.soldOut}
            disabled={!product.isValidForOrder}
            mobileView={isMobile}
            isShell={isShell}
            isClosed={isShopClosed}
            buttonStyle={buttonStyle}
            foodIcons={foodIcons}
            imageUrl={product.pic_url}
            productDescription={product.description}
            productName={product.name}
            productNumber={product.no}
            isNew={isNew?.value}
            badgeTitle={t!('product_view:lblNew')}
            language={language}
            onFoodIconClick={showHalalCertificate}
            productAdditives={additives.join(', ')}
            imagesEnabled={imagesEnabled}
            showMbvIcon={product.count_mbw !== 1}
            showDiscountIcon={!product.isHideDiscountBadge}
            hideProductNumber={hideProductArticles}
            resizeCallback={this.passSizeToParent}
          />
        ) : (
          <>
            {isNew && isNew.value && (
              <ProductNewBadge
                text={this.props.t && this.props.t('product_view:lblNew')}
                style={isNew.style}
              />
            )}
            <div className="product-small-picture-wrapper">
              {imagesEnabled && (
                <div className="product-small-picture-image">
                  <div
                    className={classNames({
                      'product-small-picture-image-wrapper': true,
                      loading: !this.state.imageLoaded,
                      'default-image': !product.pic_url
                    })}
                    style={{
                      backgroundColor:
                        templatingStyle.defaultPictureBackgroundColor
                    }}
                  >
                    <LazyLoadComponent
                      placeholder={defaultImage}
                      afterLoad={() => this.updateImageLoadingStatus(true)}
                    >
                      {product.pic_url ? (
                        <img
                          src={product.pic_url}
                          alt={product.name}
                          style={{
                            borderColor:
                              templatingStyle.productNumberBorderColor
                          }}
                        />
                      ) : (
                        defaultImage
                      )}
                    </LazyLoadComponent>
                  </div>
                </div>
              )}
              <div className="product-small-picture-text-container">
                <div
                  className="product-small-picture-title-block"
                  style={{ color: templatingStyle.textColor }}
                >
                  <ProductNumber value={product.no} className="number" />
                  {product.count_mbw !== 1 && <NoMbv />}
                  {!product.isHideDiscountBadge && <NoDiscount />}
                  <div className="productNameWrap">
                    <Heading
                      variant="h5"
                      className="productName"
                      dataTestId="product-title"
                    >
                      {product.name}
                    </Heading>
                    <button
                      className="productInfo"
                      onClick={this.handleProductInfoClick}
                    >
                      <Text dataTestId="product-info">
                        {this.props.t('product_view:productInfo')}
                      </Text>
                    </button>
                  </div>
                  {!isMobile && (
                    <div className="product-small-picture-icons">
                      <AdditiveIconList
                        additives={foodIcons}
                        onHalalClick={showHalalCertificate}
                      />
                    </div>
                  )}
                </div>
                {isMobile && !product.description ? (
                  <AdditiveIconList
                    additives={foodIcons}
                    onHalalClick={showHalalCertificate}
                  />
                ) : (
                  <div
                    className="product-small-picture-description-block"
                    style={{
                      opacity: templatingStyle.descriptionOpacity,
                      color: templatingStyle.textColor
                    }}
                    data-testid="product-description"
                  >
                    {product.description}
                  </div>
                )}
              </div>
              <div className="product-small-picture-button-wrapper">
                <ProductStandardButton
                  onClick={this.onButtonClick}
                  text={buttonText}
                  animated={animated}
                  productCount={productCount}
                  animationTargetBounds={animationTargetBounds}
                  isSoldOut={product.soldOut}
                  disabled={!product.isValidForOrder}
                  mobileView={isMobile}
                  isShell={isShell}
                  isClosed={isShopClosed}
                  buttonStyle={buttonStyle}
                />
              </div>
            </div>
            {isMobile && product.description && (
              <div
                className={classNames({
                  'food-icons-outer-wrapper': true,
                  'image-offset': imagesEnabled
                })}
              >
                <AdditiveIconList
                  additives={foodIcons}
                  onHalalClick={showHalalCertificate}
                />
              </div>
            )}
            <ProductMetaData product={product} currency={currency} />
          </>
        )}
      </div>
    );
  }
}

export default withRouter(
  withTranslation(['product_view'])(ProductSmallPicture)
);
