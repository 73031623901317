import dayjs, { Dayjs } from 'dayjs';
import find from 'lodash/find';
import { action, computed, observable } from 'mobx';

import { BranchWorkStatus } from 'client/enums/branch_work_status.enum';
import { IDay } from 'client/models/day.model';
import { IPreorderTime } from 'client/models/preorder_time.model';
import RootStore from 'client/stores';
import {
  generateStructuredData,
  getDaysTemplate
} from 'client/utils/functions';
import {
  addNowTimeItem,
  calculateAvailableForOrderDays,
  calculateTodayDay,
  filterDaysForOffers,
  filterPastAndOfferTime,
  getWeekday,
  groupHours,
  isBreakTimeNow,
  isClosed,
  sortAndSetMondayFirst
} from 'client/utils/time';

import i18n from '../../../i18n';

/**
 * @constructor
 * @param {instance} api - {@link API} instance.
 * @param {instance} storage - {@link Storage} instance.
 * @property {object} dayStringArray - Observable loading status.
 * @property {array <object>} pickupDays - Observable pickupDays array.
 * @property {array <object>} deliveryDays - Observable deliveryDays array.
 * @property {array <object>} isHiddenFullTimes - Observable status of hiding fill times block.
 */
class OpeningHoursStore {
  public readonly root: RootStore;

  constructor(root: RootStore, state: OpeningHoursStore) {
    Object.assign(this, state);

    this.root = root;

    if (state.availableTimes && state.availableTimes.length > 0) {
      this.availableTimes = state.availableTimes.map((i) => dayjs(i));
    }

    if (state.preorderDays && state.preorderDays.length > 0) {
      this.preorderDays = state.preorderDays.map((i) => dayjs(i));
    }

    if (state.activeDay) {
      this.setActiveDay(dayjs(state.activeDay));
    }
  }

  @observable public isHiddenFullTimes = true;

  @observable public holidayWeeks: number[] = [];

  @observable public sortedDelivery: IDay[][] = [];

  @observable public sortedPickup: IDay[][] = [];

  @observable public pickupHoliday = {} as IDay;

  @observable public deliveryHoliday = {} as IDay;

  @observable public pickupDays: IDay[] = getDaysTemplate();

  @observable public deliveryDays: IDay[] = getDaysTemplate();

  /**
   * Times for displaying in preorder dropdown
   * @type {IPreorderTime[]}
   */
  @observable public preorderTimes: IPreorderTime[] = [];

  /**
   * Available for order days
   * @type {dayjs.Dayjs[]}
   */
  @observable public preorderDays: Dayjs[] = [];

  /**
   * Available active day times
   * @type {dayjs.Dayjs[]}
   */
  @observable public availableTimes: Dayjs[] = [];

  /**
   * Chosen by user day, today by default
   * @type {dayjs.Dayjs}
   */
  @observable public activeDay: Dayjs = dayjs();

  /**
   * Method to calculate hours
   * @param deliveryHours
   * @param pickUpHours
   */
  public calculateHours(deliveryHours: IDay[], pickUpHours: IDay[]) {
    this.calculateDeliveryPickupHours(deliveryHours, true);

    this.calculateDeliveryPickupHours(pickUpHours, false);

    this.isDeliveryAvailable
      ? this.getHolidayWeeks(true)
      : this.getHolidayWeeks(false);
  }

  /**
   * get delivery hours and concatenate with the same time
   * @param {array} hoursList - list of delivery hours
   * @param {boolean} isDelivery - true is calculate for delivery
   */
  public calculateDeliveryPickupHours(hoursList: IDay[], isDelivery: boolean) {
    if (hoursList && hoursList.length !== 0) {
      const sortedHoursList = [...hoursList].sort((a, b) => a.day - b.day);

      sortedHoursList[0].daySort =
        dayjs.localeData().firstDayOfWeek() === 1 ? 7 : 0;

      sortedHoursList[sortedHoursList.length - 1].daySort = 8;

      for (let i = 1; i < 7; i++) {
        sortedHoursList[i].daySort = i;
      }

      const holiday = sortedHoursList.pop();

      const d = groupHours(sortedHoursList).sort((a, b) => {
        a.sort((a1, b1) => a1.daySort - b1.daySort);

        return a[0].daySort - b[0].daySort;
      });

      if (holiday && !isClosed(holiday)) {
        d.push([holiday]);
      }

      if (isDelivery) {
        this.deliveryDays = sortedHoursList.sort(
          (a, b) => a.daySort - b.daySort
        );

        this.sortedDelivery = d;

        this.deliveryHoliday = holiday || ({} as IDay);
      } else {
        this.pickupDays = sortedHoursList.sort((a, b) => a.daySort - b.daySort);

        this.sortedPickup = d;

        this.pickupHoliday = holiday || ({} as IDay);
      }
    }
  }

  /**
   * Method to get delivery/pickup hours arrays
   * @param {boolean} isDelivery - true is calculate for delivery
   * @returns {*}
   */
  public workTimeArrayByWeekday(isDelivery: boolean) {
    return isDelivery ? this.deliveryDays : this.pickupDays;
  }

  /**
   * generate delivery holidays.
   * @memberof OpeningHoursStore#
   * @method getHolidayWeeks
   */
  @action public getHolidayWeeks(isDelivery: boolean) {
    const arr: number[] = [];
    const inputArray = isDelivery ? this.deliveryDays : this.pickupDays;

    inputArray.forEach((i) => {
      if (!i.start && !i.end) {
        arr.push(i.day);
      }
    });

    this.holidayWeeks = arr;
  }

  /**
   * toggle show hours component
   * @param {bool} value
   * @memberof OpeningHoursStore
   */
  @action public toggleHours(value: boolean) {
    this.isHiddenFullTimes = value;
  }

  /**
   * Method to get openings time at weekday
   * @param {number} weekday, 1-7
   * @returns {*}
   */
  public getOpeningsTimeAtWeekday(weekday: number) {
    return this.pickupDays.find((i) => i.start && i.end && i.day === weekday);
  }

  /**
   * Generate list preorder days.
   */
  public generatePreorderDays() {
    this.root.restaurantStore.setCurrentTime();

    const { getUpcomingHolidayStart } = this.root.restaurantStore.branch;
    const { restaurantTime, customerTime } = this.root.restaurantStore;
    const { offersTimesBySelectedDay } = this.root.basketStore;

    const worktimeSchedule = this.workTimeArrayByWeekday(
      this.root.deliveryAddressStore.isDelivery
    );

    /**
     * Calculation array of available for order days
     * @type {dayjs.Dayjs[]}
     */
    this.preorderDays = calculateAvailableForOrderDays(
      restaurantTime,
      customerTime,
      this.todayWeekDay,
      this.dayIsOver,
      this.holidayWeeks,
      getUpcomingHolidayStart,
      offersTimesBySelectedDay,
      worktimeSchedule
    );

    /**
     * Calculation first available for order day
     */
    const firstAvailableForOrderDay:
      | dayjs.Dayjs
      | undefined = calculateTodayDay(
      this.isClosedForToday,
      this.filterDays,
      restaurantTime
    );

    if (firstAvailableForOrderDay) {
      this.generatePreorderTimes(firstAvailableForOrderDay);
    }
  }

  generateDays = () => {
    const { restaurantTime, customerTime } = this.root.restaurantStore;
    const currentDay = restaurantTime;
    const nextDay = dayjs(currentDay).add(1, 'd');
    const days = [currentDay, nextDay];

    return days;
  };

  /**
   * Method to generate preorder times.
   * @param {object} day - today dayjs object.
   */
  @action public generatePreorderTimes(day: Dayjs) {
    const {
      restaurantTime,
      customerTime,
      isPreorderAvailable,
      branch
    } = this.root.restaurantStore;

    const { offersTimesBySelectedDay } = this.root.basketStore;

    this.setActiveDay(day);

    const weekday = getWeekday(restaurantTime, dayjs(day));

    const worktimeSchedule = this.workTimeArrayByWeekday(
      this.root.deliveryAddressStore.isDelivery
    );

    // Calculation available times for active day
    this.availableTimes = filterPastAndOfferTime(
      offersTimesBySelectedDay,
      weekday,
      worktimeSchedule,
      this.processingTime,
      customerTime,
      this.todayWeekDay,
      isPreorderAvailable,
      {
        yesterday: branch.specialTimes?.yesterday,
        tomorrow: branch.specialTimes?.tomorrow
      }
    );

    const offerTime = offersTimesBySelectedDay.find(
      ({ dayNo }) => dayNo === weekday
    );

    // Calculation preorder times (dropdown preorder) for active day
    this.preorderTimes = addNowTimeItem(
      this.availableTimes,
      this.isActiveDayToday,
      this.isClosedForToday,
      !this.isClosedOnBackend
    );

    if (offerTime) {
      const offerTimeDayjs = dayjs(offerTime?.startT, 'HH:mm:ss');
      const now = dayjs();
      const isBefore = offerTimeDayjs.isBefore(now);

      if (!isBefore) {
        this.preorderTimes = this.preorderTimes.filter(({ value }) => !!value);
      }
    }

    if (day.isSame(restaurantTime, 'date') && !this.isClosedForNow) {
      this.root.deliveryAddressStore.changeDeliveryTime(null);
    } else if (this.availableTimes.length > 0) {
      this.root.deliveryAddressStore.changeDeliveryTime(
        this.availableTimes[0].format('HH:mm')
      );
    }
  }

  /**
   * Method for setting active day
   * @param day
   */
  @action public setActiveDay(day: Dayjs) {
    this.activeDay = day;
  }

  /**
   * Method to get filtered days for offer availability
   */
  @computed get filterDays() {
    const { offersTimesBySelectedDay } = this.root.basketStore;
    const offerAvailabilityWeekdays = this.root.basketStore.offersDays;

    return offerAvailabilityWeekdays
      ? filterDaysForOffers(
          this.preorderDays,
          offerAvailabilityWeekdays,
          offersTimesBySelectedDay
        )
      : this.preorderDays;
  }

  /**
   * check if delivery times is not empty.
   * @memberof OpeningHoursStore#
   * @method isDeliveryAvailable
   */
  @computed get isDeliveryAvailable() {
    return !!find(this.deliveryDays, (i) => !!i.start);
  }

  /**
   * check if opening times is not empty.
   * @memberof OpeningHoursStore#
   * @method isPickupAvailable
   */
  @computed get isPickupAvailable() {
    return !!find(this.pickupDays, (i) => !!i.start);
  }

  /**
   * check if today delivery/pickup is no longer available.
   * @memberof OpeningHoursStore#
   */
  @computed get isClosedForToday() {
    const { restaurantTime } = this.root.restaurantStore;
    const { isDelivery } = this.root.deliveryAddressStore;
    const today = isDelivery ? this.todayDeliveryHours : this.todayOpeningHours;

    if (!today) {
      return true;
    }

    if (dayjs(today.end, 'HH:mm:ss') < dayjs(today.start, 'HH:mm:ss')) {
      return (
        dayjs(today.end, 'HH:mm:ss')
          .weekday(restaurantTime.weekday())
          .add(1, 'day') < restaurantTime ||
        (!today.start && !today.end)
      );
    }

    return (
      dayjs(today.end, 'HH:mm:ss').weekday(restaurantTime.weekday()) <
        restaurantTime ||
      (!today.start && !today.end)
    );
  }

  /**
   * Method to check is restaurant opens for current delivery type
   * @returns {boolean}
   */
  @computed get isOpenNow() {
    return this.root.deliveryAddressStore.isDelivery
      ? this.isOpenForDelivery
      : this.isOpenForPickup;
  }

  @computed get isClosedOnBackend() {
    const {
      closedForPickUp,
      closedForDelivery
    } = this.root.restaurantStore.branch;

    const { isDelivery } = this.root.deliveryAddressStore;

    return isDelivery ? closedForDelivery : closedForPickUp;
  }

  /**
   * Method to check if opened in current time for pickup.
   * @return {boolean} check result.
   * @memberof OpeningHoursStore#
   * @method isOpenForPickup
   */
  @computed get isOpenForPickup() {
    return (
      !this.root.restaurantStore.branch.closedForPickUp &&
      !this.isPickupNotAvailableToday &&
      !!this.preorderTimes.length &&
      !this.isBreakTimeNowForPickup
    );
  }

  /**
   * Method to check if opened in current time for delivery.
   * @return {boolean} check result.
   * @memberof OpeningHoursStore#
   * @method isOpenForDelivery
   */
  @computed get isOpenForDelivery() {
    return (
      !this.root.restaurantStore.branch.closedForDelivery &&
      !this.isDeliveryNotAvailableToday &&
      !!this.preorderTimes.length &&
      !this.isBreakTimeNowForDelivery
    );
  }

  /**
   * Method to check if current time is in the first half of opening hours.
   * @return {boolean} check result.
   * @memberof OpeningHoursStore#
   * @method isFirstHalf
   */
  @computed get isFirstHalfTime() {
    const { restaurantTime } = this.root.restaurantStore;
    const { isDelivery } = this.root.deliveryAddressStore;
    const today = isDelivery ? this.todayDeliveryHours : this.todayOpeningHours;

    if (!today) return false;

    if (
      !isDelivery &&
      (!this.isTodayOpeningsHasBreakTime || !this.isOpenForPickup)
    )
      return false;

    if (
      isDelivery &&
      (!this.isTodayDeliveryHasBreakTime || !this.isOpenForDelivery)
    )
      return false;

    return (
      dayjs(today.start, 'HH:mm:ss') < restaurantTime &&
      dayjs(today.breakStart, 'HH:mm:ss') > restaurantTime
    );
  }

  /**
   * Check if day is over according to order type
   * @readonly
   * @memberof OpeningHoursStore
   */
  @computed get dayIsOver() {
    const { restaurantTime } = this.root.restaurantStore;
    const { isDelivery } = this.root.deliveryAddressStore;
    const today = isDelivery ? this.todayDeliveryHours : this.todayOpeningHours;
    const breakStart = dayjs(today.breakStart);
    const now = dayjs();
    const diff = breakStart.subtract(this.processingTime, 'm');

    if (!diff.isBefore(now)) {
      return false;
    }

    if (!today) {
      return true;
    }

    const endTime =
      dayjs(today.end, 'HH:mm:ss') < dayjs(today.start, 'HH:mm:ss')
        ? dayjs('23:59:59', 'HH:mm:ss')
        : dayjs(today.end, 'HH:mm:ss');

    if (
      today &&
      endTime <
        dayjs(today.breakEnd, 'HH:mm:ss').add(this.processingTime, 'minute')
    ) {
      return true;
    }

    return endTime < restaurantTime;
  }

  /**
   * Method to check if break time in opening hours now.
   * @return {boolean} check result.
   * @memberof OpeningHoursStore#
   * @method isBreakTimeNowForPickup
   */
  @computed get isBreakTimeNowForPickup() {
    const { restaurantTime } = this.root.restaurantStore;

    if (!this.todayOpeningHours) {
      return false;
    }

    return isBreakTimeNow(
      this.todayOpeningHours,
      restaurantTime,
      this.isTodayOpeningsHasBreakTime
    );
  }

  /**
   * Method to check if break time in delivery hours now.
   * @return {boolean} check result.
   * @memberof OpeningHoursStore#
   * @method isBreakTimeNowForDelivery
   */
  @computed get isBreakTimeNowForDelivery() {
    const { restaurantTime } = this.root.restaurantStore;

    if (!this.todayDeliveryHours) {
      return false;
    }

    return isBreakTimeNow(
      this.todayDeliveryHours,
      restaurantTime,
      this.isTodayDeliveryHasBreakTime
    );
  }

  /**
   * Method to check if today is weekend for pickup.
   * @return {boolean} check result.
   * @memberof OpeningHoursStore#
   * @method isPickupNotAvailableToday
   */
  @computed get isPickupNotAvailableToday() {
    return !this.todayOpeningHours?.start && !this.todayOpeningHours?.end;
  }

  /**
   * Method to check if today is weekend for delivery.
   * @return {boolean} check result.
   * @memberof OpeningHoursStore#
   * @method isDeliveryNotAvailableToday
   */
  @computed get isDeliveryNotAvailableToday() {
    return !this.todayDeliveryHours?.start && !this.todayDeliveryHours?.end;
  }

  /**
   * Method to check if opening hours have break time.
   * @return {boolean} check result.
   * @memberof OpeningHoursStore#
   * @method isTodayOpeningsHasBreakTime
   */
  @computed get isTodayOpeningsHasBreakTime() {
    return (
      !!this.todayOpeningHours?.breakStart && !!this.todayOpeningHours.breakEnd
    );
  }

  /**
   * Method to check if delivery hours have break time.
   * @return {boolean} check result.
   * @memberof OpeningHoursStore#
   * @method isTodayDeliveryHasBreakTime
   */
  @computed get isTodayDeliveryHasBreakTime() {
    return (
      !!this.todayDeliveryHours?.breakStart &&
      !!this.todayDeliveryHours.breakEnd
    );
  }

  /**
   * Method to get today opening hours.
   * @return {object} opening hours.
   * @memberof OpeningHoursStore#
   * @method todayOpeningHours
   */
  @computed get todayOpeningHours() {
    return this.pickupDays.find((i) => i.day === this.todayWeekDay);
  }

  /**
   * Method to get today delivery hours.
   * @return {object} delivery hours.
   * @memberof OpeningHoursStore#
   * @method todayDeliveryHours
   */
  @computed get todayDeliveryHours() {
    return this.deliveryDays.find((i) => i.day === this.todayWeekDay);
  }

  /**
   * Opening or delivery hours based on order type.
   * @return {array} today hours.
   * @memberof OpeningHoursStore#
   * @method parsedHoursBasedOnOrderType
   */
  @computed get parsedHoursBasedOnOrderType() {
    return this.root.deliveryAddressStore.isDelivery
      ? this.sortedDelivery
      : this.sortedPickup;
  }

  /**
   * Sorted days depend on monday for pickup
   */
  @computed get pickupDaysSorted() {
    return sortAndSetMondayFirst(this.pickupDays, this.pickupHoliday);
  }

  /**
   * Sorted days depend on monday for delivery
   */
  @computed get deliveryDaysSorted() {
    return sortAndSetMondayFirst(this.deliveryDays, this.deliveryHoliday);
  }

  /**
   * Method to get today weekday
   * @returns {*}
   */
  @computed get todayWeekDay() {
    const { restaurantTime } = this.root.restaurantStore;

    return restaurantTime.localeData().firstDayOfWeek() === 1
      ? restaurantTime.weekday() + 1
      : restaurantTime.weekday();
  }

  /**
   * Get hours by order type
   * @readonly
   * @memberof OpeningHoursStore
   */
  @computed get todayHoursByOrderType() {
    return this.root.deliveryAddressStore.isDelivery
      ? this.todayDeliveryHours
      : this.todayOpeningHours;
  }

  /**
   * Translate key with current status (open/closed/preorder/maintenance_mode/holiday)
   * @return {{state, value}} translation and state
   * @memberof OpeningHoursStore#
   * @method translateKeyWithWorkStatus
   */
  @computed get translateKeyWithWorkStatus() {
    if (this.root.maintenanceModeModalStore.maintenanceIsActive) {
      return {
        state: BranchWorkStatus.MAINTENANCE,
        value: i18n.t('maintenance_mode:maintenanceIsActive')
      };
    }

    if (this.root.restaurantStore.branch.isHolidayModeActive) {
      const holidayModeTime =
        this.root.restaurantStore.branch.secondsUntilOpenPickUp || 0;

      const holidayModeDuration = dayjs.duration(holidayModeTime, 'seconds');
      const date = dayjs().add(holidayModeDuration);

      return {
        state: BranchWorkStatus.HOLIDAY_MODE,
        value: i18n.t('store_is_closed_modal:weAreClosedUntil', {
          date: date.format('DD.MM.YYYY')
        })
      };
    }

    const { isPreorderAlways } = this.root.restaurantStore;

    if (this.isOpenNow) {
      return {
        state: BranchWorkStatus.OPEN,
        value: i18n.t('opening_hours:lblOpen')
      };
    }

    if (isPreorderAlways) {
      return {
        state: BranchWorkStatus.PREORDER,
        value: i18n.t('opening_hours:lblPreorder')
      };
    }

    return {
      state: BranchWorkStatus.CLOSED,
      value: i18n.t('opening_hours:lblClosed')
    };
  }

  /**
   * Opening hours for structured data
   * @return {array} today hours.
   * @memberof OpeningHoursStore#
   * @method structuredWorkTime
   */
  @computed get structuredWorkTime() {
    return generateStructuredData(this.pickupDays);
  }

  /**
   * Check is placing order is unavailable by time
   */
  @computed get isOrderUnavailableByTime(): boolean {
    return this.filterDays.length === 0 || this.availableTimes.length === 0;
  }

  /**
   * Is active day (selected day) today
   */
  @computed get isActiveDayToday(): boolean {
    return this.activeDay.isSame(
      this.root.restaurantStore.restaurantTime,
      'date'
    );
  }

  /**
   * Calculating processing time depends on delivery method
   */
  @computed get processingTime(): number {
    return this.root.deliveryAddressStore.isDelivery
      ? this.root.restaurantStore.branch.getAverageDeliveryTime
      : this.root.restaurantStore.branch.getMaxSelfcollectTime;
  }

  /**
   * Method to get closed shop today state
   */
  @computed get isClosedForNow(): boolean {
    return this.root.deliveryAddressStore.isDelivery
      ? !this.isOpenForDelivery
      : !this.isOpenForPickup;
  }

  /**
   * Method to get is current order type has preorder
   */
  @computed get currentOrderTypeHasPreorder(): boolean {
    return (
      (this.isOpenNow && this.root.restaurantStore.isPreorderAvailable) ||
      this.root.restaurantStore.isPreorderAlways
    );
  }
}

export default OpeningHoursStore;
