// Imports
import React from 'react';
import SlickCarousel from '../../../../../../../components/SlickCarousel';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
// Components
import ButtonProductPriceAnimation from '../../../../buttons/ButtonProductPriceAnimation';
// Utils
import { createHomepageHrefLink } from '../../../../../../../utils/functions';
import OfferTypes from '../../../../../../../enums/offer_types.enum';
import { ELEMENTS_TYPES } from '../../../../../../../enums/homepage_elements_types.enum';
// Styles
import './styles.scss';

/**
 * Homepage mobile component to render offers section
 */
@withRouter
@inject('store')
@observer
class HomepageOffersMobile extends React.Component {
  /**
   * Action to show offer modal
   * @param offer
   * @returns {*}
   * @private
   */
  _onOfferClick = (offer) => {
    if (!offer.isValid) {
      return;
    }

    if (
      !this.props.store.offerStore.isOffersArrayHasOfferWithId(offer.offer_id)
    ) {
      this.props.store.offerStore
        .loadSingleOfferById(offer.offer_id)
        .then((loadedOffer) => {
          this.props.store.offerStore.addSingleOfferToCommonArray(loadedOffer);
          this.props.store.offerStore.addOffer(
            loadedOffer.id,
            OfferTypes.KOMBI,
            this.props
          );
        });
    } else {
      const loadedOffer = this.props.store.offerStore.getOfferById(
        offer.offer_id
      );
      this.props.store.offerStore.addOffer(
        loadedOffer.id,
        OfferTypes.KOMBI,
        this.props
      );
    }
  };

  /**
   * Method to render single offer slide
   * @param offer -{object} offer object
   * @param {number} animateId - animated product id
   * @param key
   * @returns {*}
   * @private
   */
  _renderItem = (offer, key, animateId) => {
    const { isDelivery } = this.props.store.deliveryAddressStore;
    const {
      isShopClosed,
      restaurant,
      branch
    } = this.props.store.restaurantStore;
    const { getLang: language } = restaurant;
    const { currencyCode } = branch;
    const offerCount =
      this.props.store.basketStore.offers
        .filter((offerItem) => offerItem.offer.id === offer.offer_id)
        .reduce((count, offerItem) => count + offerItem.count, 0) || null;
    return (
      <div
        className="item"
        onClick={this._onOfferClick.bind(this, offer)}
        key={key}
      >
        <div className="title">{offer.name}</div>
        <div className="ingredients-list">
          <div className="ingredient">{offer.description}</div>
        </div>
        <div className="item-footer">
          <ButtonProductPriceAnimation
            disabled={!offer.isValid}
            animated={offer.offer_id === animateId}
            itemCount={offerCount}
            isClosed={isShopClosed}
          >
            {offer.priceLabel(isDelivery, language, currencyCode)}
          </ButtonProductPriceAnimation>
        </div>
      </div>
    );
  };

  render() {
    const animateId = this.props.store.offerStore.animateId;

    const settings = {
      infinite: true,
      slidesToScroll: 1,
      arrows: false,
      variableWidth: true,
      slide: '.item'
    };

    return (
      <section
        className="mobile-homepage-section mobile-homepage-offers"
        id={createHomepageHrefLink([ELEMENTS_TYPES.OFFERS, this.props.index])}
      >
        <h2>{this.props.title}</h2>
        <SlickCarousel settings={settings}>
          {this.props.offers.map((item, index) => {
            return this._renderItem(item, index, animateId);
          })}
        </SlickCarousel>
      </section>
    );
  }
}
export default withTranslation(['common'])(HomepageOffersMobile);
