import React, { PureComponent } from 'react';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import './SidebarMenuShell.scss';

//Assets
import states from '../../../../../enums/states.enum';
import SidebarMenuListShell from './sidebar-menu-list';
import { share } from '../../../../../utils/native-apps-communication';

@withRouter
@inject('store')
/**
 * Component for rendering shell sidebar menu
 */
export default class SideBarMenuShell extends PureComponent {
  static propTypes = {
    closeMenuAction: PropTypes.func.isRequired,
    isTableReservationAvailable: PropTypes.bool,
    shareContent: PropTypes.object.isRequired,
    isShellThemeActive: PropTypes.bool,
    isPortalAppThemeActive: PropTypes.bool
  };
  static defaultProps = {
    isTableReservationAvailable: true
  };

  /**
   * Action home menu item
   * @private
   */
  _closeSideMenuAction = () => {
    this.props.closeMenuAction();
  };

  /**
   * Action share menu item
   */
  _shareAction = () => {
    this._closeSideMenuAction();
    share(this.props.shareContent);
  };

  render() {
    const {
      isBranchSelectingSkipped,
      restaurant
    } = this.props.store.restaurantStore;
    const { phone } = this.props;
    const categoriesState =
      isBranchSelectingSkipped && !restaurant.hasOneBranch
        ? states.branchList
        : states.cat;
    const isBranchListAllowed = !this.props.store.restaurantStore.restaurant
      .hasOneBranch;
    const isTableReservationAllowed = this.props.store.restaurantStore.branch
      .branchIsTableReservation;
    const hideOrderSmartLogo = this.props.store.restaurantStore.branch
      .isHideLogoFromFooter;
    const { sideMenuStyle } = this.props.store.themesStore;
    const showPhoneNumber = !this.props.store.restaurantStore.branch.getHideTel;
    const { showPrivacyPolicy } = this.props.store.restaurantStore;
    const { isAddedToFavorites } = this.props.store.favoritesStore;
    return (
      <div id="sidebar-menu-shell" style={sideMenuStyle()}>
        <SidebarMenuListShell
          categoriesState={categoriesState}
          isBranchListAllowed={isBranchListAllowed}
          isTableReservationAllowed={isTableReservationAllowed}
          phoneNumber={phone}
          shareAction={this._shareAction}
          closeSideMenuAction={this._closeSideMenuAction}
          style={sideMenuStyle()}
          showPhoneNumber={showPhoneNumber}
          showPrivacyPolicy={showPrivacyPolicy}
          hideOrderSmartLogo={hideOrderSmartLogo}
          isPortalAppThemeActive={this.props.isPortalAppThemeActive}
          isShellThemeActive={this.props.isShellThemeActive}
          isAddedToFavorites={isAddedToFavorites}
          inverseFavoritesState={() =>
            this.props.store.favoritesStore.inverseFavoritesState()
          }
        />
      </div>
    );
  }
}
