import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useIntersection } from 'react-use';

import { ProductLazyImageProps } from 'client/ui/ProductLazyImage/ProductLazyImage.type';

import ProductDefaultImage from '../ProductDefaultImage';

import './ProductLazyImage.scss';

const ProductLazyImage = ({
  url,
  className,
  altText = ''
}: ProductLazyImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 0
  });

  const rect = ref.current?.parentElement?.getBoundingClientRect();
  // const style = { width: rect?.width, height: rect?.height };

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div
      className={classNames(
        'ProductLazyImage',
        !isLoaded && 'loading',
        className
      )}
      ref={ref}
    >
      <img
        className={classNames(!isLoaded && 'hidden')}
        src={intersection?.isIntersecting ? url : undefined}
        alt={altText}
        onLoad={handleImageLoad}
      />
      {!isLoaded && <ProductDefaultImage className="imagePlaceholder" />}
    </div>
  );
};

export default React.memo(ProductLazyImage);
