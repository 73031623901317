import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

/**
 * Component to render MBV Info
 */
class DeliveryMbvInfoRow extends PureComponent {
  static propTypes = {
    deliveryFreeFrom: PropTypes.string
  };

  render() {
    const { deliveryFreeFrom } = this.props;
    return deliveryFreeFrom ? (
      <div className="delivery-info">
        <i className="mdi mdi-information type-icon" />
        <span className="delivery-info-text">
          {this.props.t('deliveryMbw', { price: deliveryFreeFrom })}
        </span>
      </div>
    ) : null;
  }
}

export default withTranslation(['delivery_info'])(DeliveryMbvInfoRow);
