import classnames from 'classnames';
import React from 'react';

import css from './ButtonGroup.module.scss';
import { ButtonGroupProps } from './ButtonGroup.type';

const ButtonGroup = ({ children, className, vertical }: ButtonGroupProps) => (
  <div
    className={classnames(css.ButtonGroup, vertical && css.vertical, className)}
  >
    {children}
  </div>
);

export { ButtonGroup };

export default React.memo(ButtonGroup);
