import dayjs from 'dayjs';
import { action, computed, observable } from 'mobx';

import STORAGE_KEYS from 'client/enums/storage_keys.enum';
import { AggregatorModel } from 'client/models/aggregator.model';
import Storage from 'client/modules/storage';
import RootStore from 'client/stores';
import { api2, setApiTokens } from 'client/utils/api';
import {IS_CLIENT} from 'config';

/**
 * Aggregator Store
 * @constructor
 * @property {AggregatorModel} aggregator - Aggregator object
 */

export default class AggregatorStore {
  api: any;

  root: RootStore;

  storage: Storage;

  currentUtcOffset: number;

  url: string;

  slug: string;

  constructor(root, state, api, storage) {
    Object.assign(this, state);

    this.api = api;

    this.root = root;

    this.storage = storage;

    if (state && state.aggregator && Object.keys(state.aggregator).length) {
      this.aggregator = new AggregatorModel(state.aggregator);

      this.aggregator.getId && this.api.setToken(this.aggregator.getId);

      const token = this.aggregator.getId.toString();

      this.aggregator.getId && setApiTokens(token);
    }

    if (state && state.hostName) {
      this.hostName = state.hostName;
    }

    if (state && state.url) {
      this.url = state.url;
    }

    this.currentUtcOffset = dayjs().utcOffset();
  }

  _localStorageAggregatorKey = STORAGE_KEYS.AGGREGATOR;

  _localStorageAggregatorSlugKey = STORAGE_KEYS.AGGREGATOR_SLUG;

  /* Observable variables */

  /**
   * @type {object}
   * Aggregator object
   */
  @observable aggregator: Partial<AggregatorModel> = {};

  /**
   * @type {object}
   * Restaurant object
   */
  @observable isFetching = false;

  /**
   * @type {string}
   * URL host name
   */
  @observable hostName = '';

  /**
   * @type {string}
   * Aggregator custom page url
   */
  @observable customPageUrl = '';

  /**
   * @type {boolean}
   * Transition to restaurant with 'aggregator' query parameter
   */
  @observable queryParameterExists = false;

  /* Computed values */

  /**
   * @returns {boolean}
   * Allow showing back button on start page
   */
  @computed get showBackButtonToAggregatorCustomPage() {
    return this.queryParameterExists && this.customPageUrl;
  }

  /* Action setters */

  /**
   * Method for setting current fetching status
   * @param {boolean} flag - fetching status
   */
  @action setIsFetching(flag) {
    this.isFetching = flag;
  }

  /**
   * Method for setting aggregator data
   * @param {object} data - aggregator data
   */
  @action setAggregator(data) {
    this.aggregator = new AggregatorModel(data);

    this._saveAggregator(data);
  }

  /**
   * Method for setting aggregator mode for routing
   * @param {boolean} state
   */
  @action setAggregatorMode(state) {
    this.aggregator.isAggregator = state;
  }

  /**
   * method for setting slug
   * @param slug
   */
  @action setAggregatorSlug(slug) {
    this.slug = slug;

    this._saveAggregatorSlug(slug);
  }

  /**
   * Loading aggregator slug from storage
   * @returns {*}
   */
  @action
  async loadAggregatorSlugFromStorage() {
    const [slug] = await this.storage.loadFromStorage(
      [this._localStorageAggregatorSlugKey],
      undefined
    );

    if (slug) {
      this.slug = slug;
    }
  }

  /**
   * Loading aggregator data from storage
   * @returns {*}
   */
  @action
  async loadAggregatorFromStorage() {
    const [data] = await this.storage.loadFromStorage(
      [this._localStorageAggregatorKey],
      undefined
    );

    if (data) {
      this.aggregator = new AggregatorModel(data);
    }
  }

  /**
   * Set aggregator custom page url
   * @param {string} url
   */
  @action setAggregatorCustomPageUrl(url = '') {
    this.customPageUrl = url;
  }

  /**
   * Set recognition of aggregator query parameter
   * @param {boolean} param
   */
  @action setAggregatorQueryParameter(param = false) {
    this.queryParameterExists = param;
  }

  /**
   * Saving aggregator data to localstorage
   * @param {AggregatorModel} data - aggregator data
   * @private
   */
  _saveAggregator(data) {
    if (IS_CLIENT) {
      this.storage.saveToStorage(this._localStorageAggregatorKey, data);
    }
  }

  /**
   * Saving aggregator slug to localstorage
   * @param {string} slug - aggregator slug
   * @private
   */
  _saveAggregatorSlug(slug) {
    if (IS_CLIENT) {
      this.storage.saveToStorage(this._localStorageAggregatorSlugKey, slug);
    }
  }
}
