import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

import FooterShellButton from '../buttons-shell/footer-button';

/**
 * Component to render bottom shell buttons
 */
class ModalFooterButtonsShell extends React.PureComponent {
  static propTypes = {
    nextButtonLabel: PropTypes.string,
    nextButtonAction: PropTypes.func,
    backButtonLabel: PropTypes.string,
    backButtonAction: PropTypes.func,
    isDisabledNextButton: PropTypes.bool,
    isHideBackButton: PropTypes.bool,
    isHideNextButton: PropTypes.bool,
    orderButtonStyle: PropTypes.object,
    buttonStandardStyle: PropTypes.object,
    dataTestIdConfirmButton: PropTypes.string,
    dataTestIdBackButton: PropTypes.string
  };

  static defaultProps = {
    isDisabledNextButton: false,
    isHideBackButton: false,
    isHideNextButton: false
  };

  render() {
    const {
      nextButtonLabel,
      nextButtonAction,
      backButtonLabel,
      backButtonAction,
      isDisabledNextButton,
      isHideBackButton,
      isHideNextButton,
      orderButtonStyle,
      buttonStandardStyle,
      dataTestIdConfirmButton,
      dataTestIdBackButton
    } = this.props;

    return (
      <div className="footer-buttons-wrapper">
        {!isHideNextButton && (
          <FooterShellButton
            label={nextButtonLabel}
            colored
            smallText
            action={nextButtonAction}
            disabled={isDisabledNextButton}
            buttonOrderStyle={orderButtonStyle}
            buttonStandardStyle={buttonStandardStyle}
            dataTestId={dataTestIdConfirmButton}
          />
        )}
        {!isHideBackButton && (
          <FooterShellButton
            label={backButtonLabel}
            colored={false}
            smallText
            action={backButtonAction}
            buttonOrderStyle={orderButtonStyle}
            buttonStandardStyle={buttonStandardStyle}
            dataTestId={dataTestIdBackButton}
          />
        )}
      </div>
    );
  }
}

export default withTranslation(['order_payment_methods'])(
  ModalFooterButtonsShell
);
