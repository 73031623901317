/**
 * PayPal payment statuses
 */
enum PAYPAL_PAYMENT_STATUSES {
  CONFIRMED = 2,
  SUCCESSFUL = 1,
  PENDING = 0,
  CANCELLED_BY_RESTAURANT = -1,
  CANCELLED_BY_USER = -2,
  FAILED = -3
}

export default PAYPAL_PAYMENT_STATUSES;
