import React, { CSSProperties, MouseEvent } from 'react';

import './StandardButtonShell.scss';

interface IStandardButtonShell {
  label?: string;
  onClick?: (event: MouseEvent) => void;
  style?: CSSProperties;
  dataTestId?: string;
}

const StandardButtonShell = (props: IStandardButtonShell) => (
  <button
    className="standard-button-wrapper"
    onClick={props.onClick}
    style={props.style}
    data-testid={props.dataTestId}
  >
    {props.label}
  </button>
);

export default StandardButtonShell;
