import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Components
import SubcategoryItem from './SubcategoryItem';

@inject('store')
@withRouter
@observer
class SubcategoryMenuDesktop extends Component {
  static propTypes = {
    categoryId: PropTypes.number,
    onCategoryClick: PropTypes.func.isRequired
  };
  static defaultProps = {};

  state = {
    dimensions: {
      width: -1
    }
  };

  _changeWidthState(width) {
    this.setState({ ...this.state, width });
  }

  _renderItem(category, index) {
    return (
      <SubcategoryItem
        key={category.id}
        categoryIndex={index}
        category={category}
        changeWidth={this._changeWidthState.bind(this)}
      />
    );
  }

  _renderRow(currentRowArray, index) {
    return (
      <div className="subcategory-row" key={index}>
        {currentRowArray.map((renderItem) => {
          return renderItem;
        })}
      </div>
    );
  }

  //OPTIMIZE LATER
  _renderSubcategoriesLists() {
    let subcategoriesArray = [];
    let currentRowArray = [];
    let elementsLength = 0;
    this.props.store.categoryMenuStore.activeCategory.subCategories.map(
      (category, indx) => {
        if (category.width === -1) {
          subcategoriesArray.push(this._renderItem(category, indx));
        } else {
          if (elementsLength + category.width <= 680) {
            currentRowArray.push(this._renderItem(category, indx));
            elementsLength += category.width;
          } else {
            subcategoriesArray.push(this._renderRow(currentRowArray, indx));
            elementsLength = category.width;
            currentRowArray = [this._renderItem(category, indx)];
          }
        }
      }
    );

    subcategoriesArray.push(this._renderRow(currentRowArray, -1));

    return subcategoriesArray;
  }

  render() {
    return (
      <div id="subcategory-list-component" className="white-block">
        <div className="subcategory-desktop-list">
          <div
            id="subcategory-menu-desktop-hermes-theme"
            className="sub-list desktop-only"
          >
            {this._renderSubcategoriesLists()}
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation(['basket'])(SubcategoryMenuDesktop);
