import { toJS } from 'mobx';
import { intlPrice } from '../utils/functions';
/**
 * Homepage Product model
 */
export default class HomepageProductModel {
  constructor(product) {
    Object.assign(this, product);
    this.id = product.id;
    this.name = product.name;
    this.picUrl = product.picUrl;
    this.product_id = product.object_id;
    this.pickupMinPrice = product.pickupMinPrice;
    this.deliveryMinPrice = product.deliveryMinPrice;
  }

  id;
  product_id;
  name = '';
  picUrl = '';
  pickupMinPrice = 0;
  deliveryMinPrice = 0;

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  getToJS() {
    return toJS(Object.assign({}, this, { root: {} }));
  }

  /**
   * Method to get price to caption on button
   * @returns {*}
   */
  priceLabel(isDelivery, language, currencyCode) {
    const price = this.price(isDelivery);
    return intlPrice(price, language, currencyCode);
  }

  /**
   * Method to get product price.
   * @return {number} lowest delivery or pick up price.
   * @memberof HomepageProductModel#
   * @method price
   */
  price(isDelivery) {
    return isDelivery ? this.deliveryMinPrice : this.pickupMinPrice;
  }
}
