import { useEffect } from 'react';

type Callback = () => void;
type Refs = React.RefObject<HTMLElement>[];

const useOutsideClick = (refs: Refs, callback: Callback): void => {
  const handleClick = (event: MouseEvent) => {
    const path = event.composedPath();

    const isOutside = refs.some(
      (ref) => ref.current && path.includes(ref.current)
    );

    if (!isOutside) {
      callback();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);
};

export default useOutsideClick;
