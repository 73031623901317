import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import Measure from 'react-measure';
import { generateLinkFor } from '../../../../../utils/routing';

import states from '../../../../../enums/states.enum';

@inject('store')
@withRouter
@observer
class SubcategoryItem extends Component {
  static propTypes = {
    category: PropTypes.object.isRequired,
    onCategoryClick: PropTypes.func,
    changeWidth: PropTypes.func.isRequired
  };
  static defaultProps = {
    onCategoryClick: () => {}
  };

  state = {
    dimensions: {
      width: -1,
      height: -1
    }
  };

  render() {
    const {
      activeSubcategory,
      activeCategory
    } = this.props.store.categoryMenuStore;

    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          this.props.category.width = contentRect.bounds.width;
          this.setState({ dimensions: contentRect.bounds });
          if (this.props.categoryIndex === 0) {
            this.props.changeWidth(contentRect.bounds.width);
          }
        }}
      >
        {({ measureRef }) => (
          <Link
            to={generateLinkFor(
              `${states.cat}/${activeCategory.id}/${this.props.category.id}`,
              this.props,
              {},
              true
            )}
          >
            <span
              ref={measureRef}
              key={this.props.category.id}
              className={classNames({
                'category-wrapper': true,
                active:
                  activeSubcategory &&
                  activeSubcategory.id === this.props.category.id
              })}
              onClick={(e) =>
                this.props.onCategoryClick(e, this.props.category)
              }
            >
              <div>
                <span className="name">{this.props.category.name}</span>
              </div>
            </span>
          </Link>
        )}
      </Measure>
    );
  }
}
export default withTranslation(['basket'])(SubcategoryItem);
