import dayjs from 'dayjs';
import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import cs from './client/locales/cs';
import de from './client/locales/de';
import en from './client/locales/en';
import pl from './client/locales/pl';

const resources = {
  de,
  pl,
  cs,
  en
} as const;

const options: InitOptions = {
  detection: {
    order: ['navigator'],
    caches: []
  },
  resources,
  react: {
    useSuspense: false
  },
  defaultNS: 'common',
  fallbackLng: false, // We are using local storage or browser language or branch language
  interpolation: {
    format(value, format, lng) {
      if (value instanceof dayjs) {
        return dayjs(value).format(format);
      }

      if (format === 'intlPrice') {
        const currency = 'PLN';

        return new Intl.NumberFormat(lng, {
          style: 'currency',
          currency
        }).format(parseFloat(value));
      }

      return value;
    }
  }
};

i18n.use(LanguageDetector).use(initReactI18next).init(options);

export { resources };

export default i18n;
