/* eslint-disable import/no-cycle */
import concat from 'lodash/concat';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import min from 'lodash/min';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import { action, computed, observable, toJS } from 'mobx';
import { v1 } from 'uuid';

import { ProductAdditives } from 'client/models/product.model.type';
import RootStore from 'client/stores';

import { convertHttpToHttps, intlPrice } from '../utils/functions';

import { IngredientsGroupModel } from './ingredients_group.model';
import { SizeModel } from './size.model';

/**
 * Product Model class. Used to work with products.
 * @constructor
 * @param {object} product - product object.
 * @property {number} _uuid - Unique identifier of the product instance.
 * @property {number} id - Unique identifier.
 * @property {string} name - Name.
 * @property {string} description - Product description.
 * @property {number} no - Product number.
 * @property {number} discount - Define if discount should be applied to the product for selcollect only
 * @property {number} discount_all - Define if discount should be applied to the product for delivery only
 * @property {number} count_mbw - Define if the product should be counted in minimum basket value calculation
 * @property {number} lowest_price - Lowest price.
 * @property {number} lowest_price_selfcollect - Lowest price with pick up.
 * @property {number} lowest_price_delivery - Lowest price with delivery.
 * @property {string} pic_url - URL of the product's picture with HTTP protocol.
 * @property {number} is_fixed - size fixed status.
 * @property {number} is_groups_fixed - size fixed status with no ingredient groups.
 * @property {array <object>} ingredientGroups - Observable ingredients group.
 * @property {array <object>} sizes - Observable product sizes.
 * @property {boolean} isOfferProduct - is this ingredients group model from offer
 */
export class ProductModel {
  root: RootStore;

  _uuid: string;

  id: number;

  name: string;

  description = '';

  no = '';

  discount = 0;

  discount_all = 0;

  count_mbw = 0;

  lowest_price = 0;

  lowest_price_selfcollect = 0;

  lowest_price_delivery = 0;

  pic_url: string | null;

  is_fixed = 0;

  is_groups_fixed = 0;

  sizes = [];

  isOfferProduct = false;

  categories: number[] = [];

  category_id;

  is_sold_out = false;

  free_quan: number | null;

  min_quan: number | null;

  max_quan: number | null;

  size_id?: number;

  additives: ProductAdditives = {};

  constructor(id: number, product, root: RootStore, isOfferProduct = false) {
    const { restaurantStore } = root;
    const { branch } = restaurantStore;
    const hasHalalCertificate = !!root.restaurantStore.branch.HalalCertificate;

    product.extra_ingredients_groups = product.extra_ingredients_groups || {};

    product.basic_ingredients_groups = product.basic_ingredients_groups || {};

    product.negative_extra_ingredients_groups =
      product.negative_extra_ingredients_groups ||
      product.negativeExtraIngredientsGroups ||
      {};

    product.ingredientGroups = product.ingredientGroups || [];

    this.root = root;

    this.id = parseInt(product.id, 10) || parseInt(id, 10);

    this.free_quan = product.free_quan || null;

    this.min_quan = product.min_quan || null;

    this.max_quan = product.max_quan || null;

    this.count = product.count || 0;

    this.category_id =
      (product.category_ids && product.category_ids[0]) ||
      (product.categories && product.categories[0]) ||
      null;

    this.categories = product.categories_ids ?? product.categories_ids ?? [];

    this.categories_ids = product.categories_ids;

    this.name = product.name;

    this.description = product.description;

    // TODO this logic should be done on backend
    this.no = branch.isHideArticleNumbers ? '' : product.no;

    this.discount =
      product.discount || product.discount_enabled || product.isDiscountPickup; // Discount for pickup

    this.discount_all =
      product.discount_all ||
      product.discount_all_enabled ||
      product.isDiscountDelivery; // Discount for delivery

    this.count_mbw = product.count_mbw || product.mbw;

    this.lowest_price =
      product.lowest_price ||
      min([product.lowestPricePickup, product.lowestPriceDelivery]); // It looks like deprecated

    this.lowest_price_selfcollect =
      product.lowest_price_selfcollect || product.lowestPricePickup || 0;

    this.lowest_price_delivery =
      product.lowest_price_delivery || product.lowestPriceDelivery || 0;

    this.pic_url = convertHttpToHttps(product.picurl || product.pic_url);

    this.is_fixed =
      product.is_groups_fixed || product.is_fixed || product.isFixed;

    this.is_sold_out = product.is_sold_out || false;

    this._uuid = product._uuid || v1();

    this.comment = product.comment || '';

    this.isOfferProduct = isOfferProduct;

    if (isArray(product.sizes)) {
      this.sizes = product.sizes.map(
        (size) => new SizeModel(size.id, size, root)
      );
    } else {
      this.sizes = map(
        product.sizes,
        (size, key) => new SizeModel(key, size, root)
      );
    }

    if (isArray(product.categories)) {
      this.categories = product.categories;
    }

    if (this.size_id) {
      this.sizes = this.sizes.filter(
        (i) => i.id === parseInt(this.size_id as any, 10)
      );
    }

    this.ingredientGroups = []
      .concat(
        map(
          product.extra_ingredients_groups,
          (g, key) =>
            new IngredientsGroupModel(key, g, true, this, this.isOfferProduct)
        )
      )
      .concat(
        map(
          product.basic_ingredients_groups,
          (g, key) =>
            new IngredientsGroupModel(key, g, false, this, this.isOfferProduct)
        )
      )
      .concat(
        product.ingredientGroups.map(
          (g) =>
            new IngredientsGroupModel(
              g.id,
              g,
              g.isExtraGroup,
              this,
              this.isOfferProduct
            )
        )
      )
      .sort((a, b) => b.min_quan - a.min_quan);

    this.ingredientGroups = sortBy(this.ingredientGroups, 'pos');

    const basicIngredientsGroups = map(
      product.basic_ingredients_groups,
      (g, key) =>
        new IngredientsGroupModel(key, g, false, this, this.isOfferProduct)
    );

    const extraIngredientsGroups = map(
      product.extra_ingredients_groups,
      (g, key) =>
        new IngredientsGroupModel(key, g, true, this, this.isOfferProduct)
    );

    const additionalIngredientsGroups = product.ingredientGroups.map(
      (g) =>
        new IngredientsGroupModel(
          g.id,
          g,
          g.isExtraGroup,
          this,
          this.isOfferProduct
        )
    ); // Mike: I don't really know about this ingredient group

    // Preparing ingredients groups
    const allIngredientGroups = [
      ...basicIngredientsGroups,
      ...extraIngredientsGroups,
      ...additionalIngredientsGroups
    ];

    const requiredIngredientsGroups = allIngredientGroups.filter(
      (group) => !!group.isMandatoryGroup
    );

    const notRequiredIngredientsGroups = allIngredientGroups.filter(
      (group) => !group.isMandatoryGroup
    );

    this.ingredientGroups = [
      ...sortBy(requiredIngredientsGroups, 'pos'),
      ...sortBy(notRequiredIngredientsGroups, 'pos')
    ];

    // Adding negative extras
    const calcNegativeExtrasPrice =
      this.root?.ingredientsSelectStore &&
      (this.root.ingredientsSelectStore.isNegativeExtrasPricingModelFair ||
        this.root.ingredientsSelectStore.isNegativeExtrasPricingModelExact);

    this.ingredientGroups = this.ingredientGroups.concat(
      map(
        product.negative_extra_ingredients_groups,
        (g, key) =>
          new IngredientsGroupModel(
            key,
            g,
            false,
            this,
            this.isOfferProduct,
            true,
            calcNegativeExtrasPrice
          )
      )
    );

    this.ingredientGroups = this.ingredientGroups.sort(
      (a, b) => +a.isNegative - +b.isNegative
    );

    this.ingredientGroups = this.ingredientGroups.filter(
      (group) => group.hasIngredients
    );

    this.size_id = product.size_id ? product.size_id : null;

    this.uniqueProductInBasket = product.uniqueProductInBasket || false;

    this.food_icons = product.food_icons;

    this.additives = {
      isNew: product?.food_icons?.is_new,
      hot: product.food_icons?.hot,
      isVegan: product?.food_icons?.is_vegan,
      isVegetarian: product?.food_icons?.is_vegetarian,
      isBio: product?.food_icons?.is_bio,
      isHalal: hasHalalCertificate && product?.food_icons?.is_halal,
      isLactoseFree: product?.food_icons?.is_lactose_free,
      isGlutenFree: product?.food_icons?.is_gluten_free,
      isAdult16: product?.food_icons?.is_food_adult,
      isAdult18: product?.food_icons?.is_adults,
      isSugarFree: product?.food_icons?.is_sugar_free
    };
  }

  @observable count = 0;

  @observable ingredientGroups = [];

  @observable comment = '';

  @observable uniqueProductInBasket;

  @action setCount(count) {
    this.count = count;
  }

  @action increaseCount() {
    this.count += 1;
  }

  @action reduceCount() {
    this.count -= 1;
  }

  /**
   * Method to set active size of the product.
   * @return {number} size id.
   * @memberof ProductModel#
   * @method setSize
   */
  @action setSize(id) {
    this.sizes.forEach((i) => (i.active = i.id === id));
  }

  /**
   *
   */
  @action makeThisProductUnique() {
    this.uniqueProductInBasket = true;
  }

  /**
   * Method to serialize Product to pure JS object.
   * @return {object} serialized Product with ingredients.
   */
  getToJS() {
    return Object.assign(toJS({ ...this, root: {} }), {
      sizes: this.sizes.map((size) => size.getToJS()),
      ingredientGroups: this.ingredientGroups.map((group) => group.getToJS())
    });
  }

  /**
   * Method to get allergens/additives of product
   */
  @computed get allergens() {
    return this.root.additivesStore.additivesProducts.find(
      (product) => product.id === this.id
    );
  }

  @computed get sizeWithWrightPrice() {
    return this.root?.deliveryAddressStore.isDelivery
      ? this.sizes.find((i) => i.delivery_price > 0)
      : this.sizes.find((i) => i.self_collector_price > 0);
  }

  @computed get uniqString() {
    return (
      this.name +
      this.id +
      this.comment +
      (this.activeSize ? this.activeSize.id : '') +
      this.selectedIngredients
        .map((ingredient) => ingredient.count + ingredient.name + ingredient.id)
        .join(', ') +
      (this.uniqueProductInBasket ? this._uuid : '')
    );
  }

  /**
   * Method to get product's size.
   * @return {object} lowest delivery or pick up price.
   * @memberof ProductModel#
   * @method activeSize
   */
  @computed get activeSize() {
    if (this.is_fixed) return this.sizes[0];

    return find(this.sizes, (i) => i.active);
  }

  /**
   * Method to get product price.
   * @return {number} lowest delivery or pick up price.
   * @memberof ProductModel#
   * @method price
   */
  @computed get price() {
    if (!this.is_fixed && this.activeSize)
      return Number(this.activeSize.price || 0);

    return this.root?.deliveryAddressStore.isDelivery
      ? Number(this.lowest_price_delivery)
      : Number(this.lowest_price_selfcollect);
  }

  /**
   * Method to valid product for order
   * @return {boolean} true is valid
   * @memberof ProductModel#
   * @method isValidForOrder
   */
  @computed get isValidForOrder() {
    return this.price > 0;
  }

  /**
   * Method to get product price with selected ingredients.
   * @return {number} lowest delivery or pick up price with ingredients.
   * @memberof ProductModel#
   * @method priceWithIngredients
   */
  @computed get priceWithIngredients() {
    let result = this.price
      ? this.price +
        sum(
          this.ingredientGroups.map((group) =>
            Number(group.selectedIngredientsPrice)
          )
        )
      : 0;

    if (this.root?.ingredientsSelectStore.isNegativeExtrasPricingModelExact) {
      result = Math.max(result, 0);
    } else if (
      this.root?.ingredientsSelectStore.isNegativeExtrasPricingModelFair
    ) {
      result = Math.max(result, this.price);
    }

    return result;
  }

  @computed get totalIngredientPrice() {
    return +sum(
      this.ingredientGroups.map((group) => group.selectedIngredientsPrice)
    );
  }

  @computed get intlTotalIngredientsPrice() {
    return this.intlPrice(this.totalIngredientPrice);
  }

  @computed get getIntlPrice() {
    return this.intlPrice(this.price);
  }

  @computed get intlPriceWithIngredients() {
    return this.intlPrice(this.priceWithIngredients);
  }

  /**
   * Method to get all ingredients from all ingredientGroups.
   * @return {array} flat array of all ingredients.
   * @memberof ProductModel#
   * @method ingredients
   */
  @computed get ingredients() {
    return this.ingredientGroups.reduce((i, groups) => groups.ingredients, []);
  }

  /**
   * Method to checkout if product has ingredients.
   * @return {boolean} has ingredients.
   * @memberof ProductModel#
   * @method hasIngredients
   */
  @computed get hasIngredients() {
    return this.ingredients.length > 0;
  }

  /**
   * Method to check if product available to choose options.
   * @return {boolean} available for options.
   * @memberof ProductModel#
   * @method availableForOptions
   */
  @computed get availableForOptions() {
    return this.hasIngredients || this.hasSeveralSizes || !this.is_fixed;
  }

  /**
   * Method to check if product has several sizes
   * @return {boolean} has several sizes.
   * @memberof ProductModel#
   * @method hasSeveralSizes
   */
  @computed get hasSeveralSizes() {
    return this.sizes.length > 1;
  }

  /**
   * Method to get selected ingredients from all ingredientGroups.
   * @return {array} flat array of selected ingredients.
   * @memberof ProductModel#
   * @method selectedIngredients
   */
  @computed get selectedIngredients() {
    return this.isOfferProduct
      ? this.ingredientGroups.reduce(
          (i, groups) => concat(i, groups.selectedIngredients),
          []
        )
      : this.ingredientGroups
          .filter((i) => !i.isExcluded) // filter unavailable ingredients to current size
          .reduce((i, groups) => concat(i, groups.selectedIngredients), []);
  }

  /**
   * Method to get selected required ingredients
   * @returns {boolean}
   */
  @computed get requiredIngredientsSelected() {
    return !this.ingredientGroups
      .filter((i) => !i.isExcluded) // filter unavailable ingredients to current size
      .map((group) => group.isRequiredIngredientsSelected)
      .filter((bool) => bool === false).length;
  }

  /**
   * Method to hideHideDiscountBadge
   * @return {boolean} true if discount badge should be hidden.
   * @memberof ProductModel#
   * @method isHideDiscountBadge
   */
  @computed get isHideDiscountBadge() {
    return !!(
      (this.root?.deliveryAddressStore.isDelivery &&
        this.countDeliveryDiscount) ||
      (!this.root?.deliveryAddressStore.isDelivery && this.countPickupDiscount)
    );
  }

  /**
   * Check if product count for mbv
   * @readonly
   * @memberof ProductModel
   */
  @computed get countMbv() {
    return this.count_mbw;
  }

  /**
   * Check if product included for delivery discount
   * @readonly
   * @memberof ProductModel
   */
  @computed get countDeliveryDiscount() {
    return this.discount_all;
  }

  /**
   * Check if product is sold out
   */
  @computed get soldOut() {
    return this.is_sold_out;
  }

  /**
   * Check if product included for pickup discount
   * @readonly
   * @memberof ProductModel
   */
  @computed get countPickupDiscount() {
    return this.discount;
  }

  /**
   * Method to get localized prise with current currency
   * @return {string} Localized price with currency
   * @memberof ProductModel#
   * @method intlPrice
   */
  intlPrice(price) {
    const language = this.root.restaurantStore.restaurant.getLang;
    const { currencyCode } = this.root.restaurantStore.branch;

    return intlPrice(price, language, currencyCode);
  }
}
