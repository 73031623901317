import { computed, toJS } from 'mobx';

import i18n from '../../i18n';
import { intlPrice } from '../utils/functions';

/**
 * Homepage Offer model
 */
export default class HomepageOfferModel {
  constructor(offer) {
    Object.assign(this, offer);

    this.id = offer.id;

    this.name = offer.name;

    this.offer_id = offer.object_id;

    this.pickupPrice = offer.pickupPrice;

    this.deliveryPrice = offer.deliveryPrice;

    this.description = offer.description;

    this.validNow = offer.validNow;
  }

  id;

  offer_id;

  name = '';

  pickupPrice = 0;

  deliveryPrice = 0;

  description = '';

  validNow = true;

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  getToJS() {
    return toJS({ ...this, root: {} });
  }

  /**
   * Method to get price to caption on button
   * @returns {*}
   */
  priceLabel(isDelivery, language, currencyCode) {
    const price = this.price(isDelivery);

    return this.isValid
      ? intlPrice(price, language, currencyCode)
      : i18n.t('common:productNotAvailable');
  }

  /**
   * Method to get product price.
   * @return {number} lowest delivery or pick up price.
   * @method price
   */
  price(isDelivery) {
    return isDelivery ? this.deliveryPrice : this.pickupPrice;
  }

  /**
   * Is Offer valid
   * @returns {boolean}
   */
  @computed get isValid() {
    return this.validNow;
  }
}
