import { Modal } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { PLATFORM } from '../../../../../enums/platform.enum';
import StandardButtonShell from '../buttons-shell/standard-button/StandardButtonShell';

import './_migrated-store-modal.scss';

@inject('store')
@observer
class MigratedStoreModal extends Component {
  state = {
    copiedButtonLabelText: 'copyStoreLink'
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { restaurant } = this.props.store.restaurantStore;
    const { platform, showAppUpdateButton } = this.props.store.themesStore;
    const { playStoreUrl, appStoreUrl } = restaurant;

    return (
      <Modal
        closable={false}
        footer={false}
        centered
        visible={this.props.show}
        className="MigratedStoreModal"
      >
        <div id="store-is-migrated">
          <div className="logo">
            <img
              title={`Logo ${restaurant.getTitle}`}
              alt={`logo ${restaurant.getTitle}`}
              src={restaurant.logo}
            />
          </div>
          <div className="title">{this.props.t('appUpdateRequired')}</div>
          <div className="description">
            {this.props.t('newAppVersionAvailable')}
            <br />
            {this.props.t('pleaseUpdateToContinueUsing')}
          </div>
          {showAppUpdateButton && (
            <div className="action-button-wrapper">
              <a
                href={`${
                  platform === PLATFORM.IOS ? appStoreUrl : playStoreUrl
                }`}
                rel="noopener noreferrer"
              >
                <StandardButtonShell label={this.props.t('updateNow')} />
              </a>
            </div>
          )}
          {!showAppUpdateButton && (
            <div className="action-button-wrapper">
              <StandardButtonShell
                label={this.props.t(this.state.copiedButtonLabelText)}
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(
                      platform === PLATFORM.IOS ? appStoreUrl : playStoreUrl
                    );

                    this.setState({ copiedButtonLabelText: 'copied' });

                    setTimeout(
                      () =>
                        this.setState({
                          copiedButtonLabelText: 'copyStoreLink'
                        }),
                      2000
                    );
                  } catch (err) {
                    console.error('Could not copy text: ', err);
                  }
                }}
              />
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default withTranslation(['migration_modal'])(MigratedStoreModal);
