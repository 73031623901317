import classNames from 'classnames';
import { inject } from 'mobx-react';
import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';

import states from '../../../../../../enums/states.enum';
import { generateLinkFor } from '../../../../../../utils/routing';
import './styles.scss';

/**
 * Component to category row
 * @param phone
 * @returns {null}
 */
@inject('store')
@withRouter
export default class CategoryListAltRow extends PureComponent {
  render() {
    const { category, style } = this.props;

    return (
      <Link
        to={generateLinkFor(
          `${states.cat}/${category.id}`,
          this.props,
          {},
          true
        )}
        className="category-row-alt"
        data-testid="shell-cat-item"
      >
        {category.picurl && (
          <div className="category-image">
            <img src={category.picurl} alt={category.name} />
          </div>
        )}
        <div
          className={classNames({
            'category-name': true,
            'with-image': !!category.picurl
          })}
          style={style}
          data-testid="shell-cat-item-name"
        >
          {category.name}
        </div>
        <div className="icon" />
      </Link>
    );
  }
}
