import React, { Component } from 'react';
import { PropTypes } from 'mobx-react';
import PropTypesReact from 'prop-types';
import { observer } from 'mobx-react';

import CategoryMenuDesktopHermesThemeNoPics from './_without_pictures/CategoryMenuDesktopHermesThemeWithoutPictures';
import CategoryMenuDesktopHermesThemeWithPics from './_with_pictures/CategoryMenuDesktopHermesWithPictures';

import { COMPONENTS_TEMPLATE_TYPE } from '../../../../../enums/templates/components_template_type.enum';

@observer
export default class CategoryMenuDesktop extends Component {
  static propTypes = {
    activeCategory: PropTypesReact.object.isRequired,
    categories: PropTypes.observableArray.isRequired,
    hermesThemeCountOfCategories: PropTypesReact.number.isRequired,
    hermesThemeCountOfColumn: PropTypesReact.number.isRequired,
    shouldApplyWideTheme: PropTypesReact.bool.isRequired,
    categoryTheme: PropTypesReact.string.isRequired,
    widthHermesThemeWithPics: PropTypesReact.number.isRequired,
    hideActiveCategory: PropTypesReact.bool
  };
  static defaultProps = {};

  render() {
    const {
      activeCategory,
      categories,
      hermesThemeCountOfCategories,
      hermesThemeCountOfColumn,
      shouldApplyWideTheme,
      categoryTheme,
      widthHermesThemeWithPics,
      hideActiveCategory
    } = this.props;

    switch (categoryTheme) {
      case COMPONENTS_TEMPLATE_TYPE.HERMES_CATEGORIES_NO_PICS:
        return (
          <CategoryMenuDesktopHermesThemeNoPics
            activeCategory={activeCategory}
            categories={categories}
            hermesThemeCountOfCategories={hermesThemeCountOfCategories}
            hermesThemeCountOfColumn={hermesThemeCountOfColumn}
            shouldApplyWideTheme={shouldApplyWideTheme}
            hideActiveCategory={hideActiveCategory}
          />
        );

      case COMPONENTS_TEMPLATE_TYPE.HERMES_CATEGORIES_WITH_PICS:
        return (
          <CategoryMenuDesktopHermesThemeWithPics
            activeCategory={activeCategory}
            categories={categories}
            hermesThemeCountOfCategories={hermesThemeCountOfCategories}
            hermesThemeCountOfColumn={hermesThemeCountOfColumn}
            shouldApplyWideTheme={shouldApplyWideTheme}
            widthHermesThemeWithPics={widthHermesThemeWithPics}
            hideActiveCategory={hideActiveCategory}
          />
        );

      default:
        throw new Error('Categories theme was not specified');
    }
  }
}
