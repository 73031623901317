import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as HungerLogo } from 'client/icons/hunger-logo.svg';

import './_app-widget.scss';
import { RootContext } from 'client/stores';

const AppWidget = ({
  customerLandingPageLink,
  hasApps,
  hasAppStore,
  hasPlayStore,
  hasBothApps,
  appStoreUrl,
  playStoreUrl,
  getQrPicUrl,
  getMobileAppPic,
  ordersmartLogo,
  appStoreImage,
  googlePlayImage,
  isHideMarketingData
}) => {
  const { t } = useTranslation(['widgets']);
  const { themesStore } = useContext(RootContext);
  const { isPortalWebThemeActive: isHunger } = themesStore;

  const getLogo = () => {
    if (isHunger) {
      return (
        <div className="logo-widget">
          <a href="https://hunger.de" target="_blank" rel="noopener noreferrer">
            <HungerLogo />
          </a>
        </div>
      );
    }

    return (
      <div className="logo-widget">
        <a
          href="https://ordersmart.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img alt="Order Smart logo" src={ordersmartLogo} />
        </a>
      </div>
    );
  };

  if (!hasApps) {
    return null;
  }

  return (
    <div id="app-widget">
      <div className="app-widget-title">
        <span>{t('widgets:appWidgetTitle')}</span>
      </div>

      <div
        className="btns"
        style={{ backgroundImage: `url(${getMobileAppPic})` }}
      >
        <img
          title={t('widgets:appWidgetTitle')}
          alt={t('widgets:appWidgetTitle')}
          className="qr-code"
          src={getQrPicUrl}
        />
        <div className="app-img">
          {hasAppStore && (
            <a href={appStoreUrl} target="_blank" rel="noreferrer">
              <img alt="App Store app" src={appStoreImage} />
            </a>
          )}
        </div>
        <div className="app-img">
          {hasPlayStore && (
            <a href={playStoreUrl} target="_blank" rel="noreferrer">
              <img alt="Google Play app" src={googlePlayImage} />
            </a>
          )}
        </div>
      </div>
      <a
        className="bottom-info"
        href={`${customerLandingPageLink}`}
        target="_blank"
        rel="noreferrer"
      >
        <span>
          {hasBothApps
            ? t('widgets:availableBoth')
            : t('widgets:availableForOne', {
                app: hasAppStore ? 'IOS' : 'Android'
              })}
        </span>
      </a>
      {!isHideMarketingData && <div className="logo-widget">{getLogo()}</div>}
    </div>
  );
};

export default React.memo(AppWidget);
