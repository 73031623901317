/* eslint-disable import/no-cycle,no-prototype-builtins */
import { toJS } from 'mobx';

import AdditiveCategoryModel from 'client/models/additive_category.model';

/**
 * Additives Product model
 * @constructor
 * @property {id} - Unique id of category.
 * @property {string} - Name of category.
 * @property {array} - additives: array of additives names
 * @property {array} - additivePrefixes: array of additives prefixes
 * @property {array} - allergens: array of allergens names
 * @property {array} - allergensPrefixes: array of allergens prefixes
 */
export default class AdditiveProductModel {
  public id: number;

  public name: string;

  public additives: string[];

  public allergens: string[];

  public additivePrefixes: string[];

  public allergensPrefixes: string[];

  public isSoldOut?: boolean;

  public isBio?: boolean;

  public isHalal?: boolean;

  public isVegetarian?: boolean;

  public isVegan?: boolean;

  public isNew?: boolean;

  public hot?: number;

  public isLactoseFree?: boolean;

  public isGlutenFree?: boolean;

  public isSugarFree?: boolean;

  public isAdult16?: boolean;

  public isAdult18?: boolean;

  constructor(
    product: any,
    categoryAdditives: AdditiveCategoryModel,
    halalIsAvailable: boolean
  ) {
    this.id = product.id;

    this.name = product.name;

    this.additives = product.additives;

    this.allergens = product.allergens;

    this.additivePrefixes = product.additivePrefixes;

    this.allergensPrefixes = product.allergensPrefixes;

    this.isNew = product.is_new || categoryAdditives.isNew || false;

    this.hot = product.hot || 0;

    this.isVegan = product.hasOwnProperty('is_vegan')
      ? product.is_vegan
      : categoryAdditives.isVegan;

    this.isVegetarian = product.hasOwnProperty('is_vegetarian')
      ? product.is_vegetarian
      : categoryAdditives.isVegetarian;

    this.isBio = product.hasOwnProperty('is_bio')
      ? product.is_bio
      : categoryAdditives.isBio;

    this.isHalal =
      halalIsAvailable &&
      (product.hasOwnProperty('is_halal')
        ? product.is_halal
        : categoryAdditives.isHalal);

    this.isLactoseFree = product.hasOwnProperty('is_lactose_free')
      ? product.is_lactose_free
      : categoryAdditives.isLactoseFree;

    this.isGlutenFree = product.hasOwnProperty('is_gluten_free')
      ? product.is_gluten_free
      : categoryAdditives.isGlutenFree;

    this.isAdult16 = product.hasOwnProperty('is_food_adult')
      ? product.is_food_adult
      : categoryAdditives.isAdult16;

    this.isAdult18 = product.hasOwnProperty('is_adults')
      ? product.is_adults
      : categoryAdditives.isAdult18;

    this.isSugarFree = product.hasOwnProperty('is_sugar_free')
      ? product.is_sugar_free
      : categoryAdditives.isSugarFree;
  }

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  public getToJS() {
    return toJS({ ...this, root: {} });
  }
}
