import classnames from 'classnames';
import React, { memo, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import useOutsideClick from '../../hooks/useOutsideClick';
import FakeButton from '../../ui/FakeButton';

import style from './BranchSelector.module.scss';
import { BranchSelectorProps } from './BranchSelector.types';
import { ReactComponent as ChevronIcon } from './chevronDown.svg';
import BranchList from './components/BranchList';

const BranchSelector = ({
  branchName,
  className,
  branches,
  branchId
}: BranchSelectorProps) => {
  const [show, setShow] = useState(false);
  const branchSelectorRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  useOutsideClick([branchSelectorRef, popoverRef], () => {
    setShow(false);
  });

  const classes = {
    enter: style.enter,
    enterActive: style.enterActive,
    exit: style.exit,
    exitActive: style.exitActive
  };

  return (
    <div className={style.popoverContainer}>
      <FakeButton
        data-testid="branch-selector"
        ref={branchSelectorRef}
        className={classnames(style.BranchSelector, className)}
        onClick={() => setShow(!show)}
      >
        <div className={style.chevron}>
          <ChevronIcon />
        </div>
        <h1 className={style.title}>{branchName}</h1>
      </FakeButton>

      <CSSTransition in={show} timeout={200} classNames={classes} unmountOnExit>
        <div className={style.popover} ref={popoverRef}>
          <div className={style.arrow} />
          <BranchList branches={branches} branchId={branchId} />
        </div>
      </CSSTransition>
    </div>
  );
};

export default memo(BranchSelector);
