import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Components
import ButtonOrder from '../../buttons/ButtonOrder';
import ButtonStandard from '../../buttons/ButtonStandard';

// CSS

import FooterShellButton from '../../../theme-hermes-shell/components/buttons-shell/footer-button';
import './ModalFooterActions.scss';

/**
 * Modal footer actions component is used to confirm and abort actions.
 * @name ReactModalFooterActions
 * @property {function} abort - on modal cancel callback.
 * @property {function} confirm - on modal confirm callback.
 * @property {boolean} disabled - should confirm button be disabled.
 * @property {string} confirmText - custom confirm text.
 */
@withRouter
@inject('store')
@observer
class ModalFooterActions extends Component {
  static propTypes = {
    abort: PropTypes.func,
    confirm: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    confirmText: PropTypes.string,
    abortText: PropTypes.string,
    isHideBackButton: PropTypes.bool,
    shellUseSmallText: PropTypes.bool,
    isSoldOut: PropTypes.bool,
    loading: PropTypes.bool,
    dataTestIdConfirmButton: PropTypes.string,
    dataTestIdBackButton: PropTypes.string
  };

  static defaultProps = {
    disabled: false,
    isHideBackButton: false,
    shellUseSmallText: false,
    isSoldOut: false
  };

  /**
   *  Method for render modal footer confirm/next button
   * @returns {*}
   */
  _renderConfirmButton = () => {
    const {
      confirmText,
      disabled,
      confirm,
      isSoldOut,
      loading,
      dataTestIdConfirmButton
    } = this.props;

    const confirmLabel = confirmText || this.props.t('confirm');

    return (
      <ButtonOrder
        type="submit"
        disabled={disabled}
        onClick={confirm}
        label={confirmLabel}
        isSoldOut={isSoldOut}
        loading={loading}
        dataTestId={dataTestIdConfirmButton}
      />
    );
  };

  /**
   * Method for render modal footer back button
   * @returns {*}
   */
  _renderBackButton = () => {
    const {
      isHideBackButton,
      abortText,
      abort,
      dataTestIdBackButton
    } = this.props;

    const backLabel = abortText || this.props.t('abort');

    return !isHideBackButton ? (
      <ButtonStandard
        onClick={abort}
        label={backLabel}
        dataTestId={dataTestIdBackButton}
      />
    ) : null;
  };

  /**
   *  Method for render modal footer confirm/next button
   * @returns {*}
   */
  _renderConfirmButtonShell = () => {
    const {
      confirmText,
      disabled,
      confirm,
      isSoldOut,
      dataTestIdConfirmButton
    } = this.props;

    const confirmLabel = confirmText || this.props.t('confirm');

    return (
      <FooterShellButton
        label={confirmLabel}
        disabled={disabled || isSoldOut}
        action={confirm}
        smallText={this.props.shellUseSmallText}
        buttonOrderStyle={this.props.store.themesStore.orderButtonStyle()}
        buttonStandardStyle={this.props.store.themesStore.standardButtonStyle()}
        buttonDisabledStyle={this.props.store.themesStore.standardButtonDisabledStyle()}
        colored
        isSoldOut={isSoldOut}
        dataTestId={dataTestIdConfirmButton}
      />
    );
  };

  /**
   * Method for render modal footer back button
   * @returns {*}
   */
  _renderBackButtonShell = () => {
    const {
      isHideBackButton,
      abortText,
      abort,
      dataTestIdBackButton
    } = this.props;

    const backLabel = abortText || this.props.t('abort');

    return !isHideBackButton ? (
      <FooterShellButton
        label={backLabel}
        action={abort}
        smallText={this.props.shellUseSmallText}
        buttonOrderStyle={this.props.store.themesStore.orderButtonStyle()}
        buttonStandardStyle={this.props.store.themesStore.standardButtonStyle()}
        dataTestId={dataTestIdBackButton}
      />
    ) : null;
  };

  _renderShell = () => (
    <div className="footer-buttons-wrapper">
      {this._renderConfirmButtonShell()}
      {this._renderBackButtonShell()}
    </div>
  );

  _renderModal = () => (
    <div className="modal-footer-actions">
      <div className="btn-wrapper">
        {this._renderBackButton()}
        {this._renderConfirmButton()}
      </div>
    </div>
  );

  render() {
    return this.props.store.themesStore.isShellThemeActive
      ? this._renderShell()
      : this._renderModal();
  }
}

export default withTranslation(['modal_footer_common'])(ModalFooterActions);
