import { action, computed, observable, toJS } from 'mobx';

import STORAGE_KEYS from 'client/enums/storage_keys.enum';
import { CouponModel } from 'client/models/coupon.model';
import Storage from 'client/modules/storage';
import RootStore from 'client/stores';
import { IS_CLIENT, isFeeFromTotalPrice } from 'config';

/**
 * Coupons Store class. Used to work with coupons.
 * @constructor
 * @param {instance} api - {@link API} instance.
 * @property {boolean} loading - observable loading status.
 * @property {boolean} isReedemCouponActive - observable status of activation coupon enter feature
 * @property {boolean} isValidationPassed - observable status when validation passed
 * @property {boolean} couponValid - observable coupon valid status
 * @property {string} couponCode - observable coupon code
 * @property {CouponModel} coupon - observable coupo  n object
 */
export default class CouponsStore {
  api: any;

  root: RootStore;

  storage: Storage;

  constructor(root, state, api, storage) {
    Object.assign(this, state);

    this.api = api;

    this.root = root;

    this.storage = storage;
  }

  localStorageKey = STORAGE_KEYS.COUPON;

  @observable coupon: Record<string, any> = {};

  @observable isReedemCouponActive = false;

  @observable isValidationPassed = false;

  @observable couponValid = false;

  @observable couponCode: string | null = null;

  @observable loading = false;

  /** Method to save serialized coupon code into storage */
  saveCouponCodeInStorage() {
    const { branch } = this.root.restaurantStore;

    return this.storage.saveToStorage(
      this.localStorageKey,
      toJS(this.couponCode),
      branch.branchId
    );
  }

  /**
   * Method to get coupon discount value
   * @returns {*}
   */
  @computed get calculateCouponSaleValueWithOffers() {
    if (this.isFixedCoupon) {
      return this.coupon.amount;
    }

    const { basketStore } = this.root;

    const price = isFeeFromTotalPrice
      ? basketStore.totalPriceWithOffers
      : basketStore.orderPriceWithOffers;

    return price * (this.coupon.amount / 100);
  }

  /**
   * Method to get validation state of coupon
   * @returns {boolean}
   */
  @computed get isCouponValid() {
    return this.couponValid && !this.isMbvNotAvailable;
  }

  /**
   * Method to showing amount of coupon discount
   * @memberof CouponsStore#
   * @method filteredCouponValue
   */
  @computed get filteredCouponValue() {
    if (this.isFixedCoupon) {
      return this.root.basketStore.getIntlPrice(
        this.calculateCouponSaleValueWithOffers
      );
    }

    return `${this.coupon.amount}%`;
  }

  /**
   * Method to check is coupon mbv not achieved
   * @returns {boolean}
   */
  @computed get isMbvNotAvailable() {
    return this.root.basketStore.totalPriceWithOffers < this.coupon.mbv;
  }

  /**
   * Method to sending validation request
   * @memberof CouponsStore#
   * @method validateCouponAction
   */
  @action validateCouponAction(coupon) {
    this.couponCode = coupon;

    if (this.couponCode) {
      this.isValidationPassed = false;

      this.toggleLoading(true);

      const body = {
        basket_value: this.root.basketStore.totalPriceWithOffers,
        branch_id: this.root.restaurantStore.branch.branchId,
        code: this.couponCode
      };

      return this.api
        .validateCoupon(body)
        .then((data) => {
          this.saveCouponCodeInStorage();

          this.prepareData(data);

          return this.coupon;
        })
        .then((data) => {
          this.toggleLoading(false);

          if (this.coupon) {
            this.couponValid = true;

            if (!this.isMbvNotAvailable) {
              this.isValidationPassed = true;
            }
          }

          return data;
        })
        .catch(() => {
          this.couponValid = false;

          this.isValidationPassed = true;

          this.toggleLoading(false);
        });
    }
  }

  /**
   * Method to load coupon from storage and validate it on the server
   * @memberof CouponsStore#
   * @method checkCouponCodeInStorage
   */
  @action
  async checkCouponCodeInStorage() {
    const { branch } = this.root.restaurantStore;

    const [cachedCouponCode] = await this.storage.loadFromStorage(
      [this.localStorageKey],
      branch.branchId
    );

    if (cachedCouponCode) {
      this.couponCode = cachedCouponCode;

      this.isReedemCouponActive = true;
    }

    return this.validateCouponAction(this.couponCode);
  }

  /**
   * Method to prepare coupon.
   * @param {object} coupon - coupon data.
   * @memberof CouponsStore#
   * @method prepareData
   */
  @action prepareData(coupon) {
    this.coupon = new CouponModel(coupon);
  }

  /**
   * Method to activation coupon enter feature
   * @memberof CouponsStore#
   * @method removeCouponAction
   */
  @action activateEnterCoupon() {
    this.isReedemCouponActive = true;
  }

  /**
   * Method to clear coupon code
   * @memberof CouponsStore#
   * @method clearCoupon
   */
  @action clearCoupon() {
    this.coupon = {};

    this.isValidationPassed = false;

    this.couponValid = false;

    this.couponCode = null;

    if (IS_CLIENT) {
      this.saveCouponCodeInStorage();
    }
  }

  /**
   * Method to removing activated coupon
   * @memberof CouponsStore#
   * @method removeCouponAction
   */
  @action removeCouponAction() {
    this.isReedemCouponActive = false;

    this.clearCoupon();
  }

  /**
   * Method to change coupon code amount.
   * @param {string} value - new coupon code amount.
   * @memberof CouponsStore#
   * @method changeCouponCode
   */
  @action changeCouponCode(value) {
    this.couponCode = value;

    this.isValidationPassed = false;

    this.couponValid = false;
  }

  /**
   * Method to check type of coupon amount "fixed" or "percent"
   * @return {boolean} true if coupon fixed
   * @memberof CouponsStore#
   * @method isFixedCoupon
   */
  @computed get isFixedCoupon() {
    return this.coupon.is_fixed === 1;
  }

  /**
   * Method to loading status.
   * @param {boolean} flag - loading status.
   * @memberof CouponsStore#
   * @method toggleLoading
   */
  @action toggleLoading(flag) {
    this.loading = flag;
  }

  /**
   * Method to check if coupon code entered.
   * @return {boolean} check result.
   * @memberof CouponsStore#
   * @method isCouponCodeEntered
   */
  @computed get isCouponCodeEntered() {
    return !!this.couponCode;
  }

  /**
   * Method to check validation of coupon
   * @return {boolean} true if coupon invalid
   * @memberof CouponsStore#
   * @method isShowInvalidCoupon
   */
  @computed get isShowInvalidCoupon() {
    return this.isValidationPassed && !this.couponValid;
  }
}
