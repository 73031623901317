/**
 * Offline order statuses
 */
enum OFFLINE_PAYMENT_ORDER_STATUSES {
  RECEIVED_BY_SYSTEM = 1,
  RESTAURANT_CONFIRMED = 2,
  CANCELLED_BY_RESTAURANT = -1,
  NOT_FOUND_IN_SYSTEM = -2
}

export default OFFLINE_PAYMENT_ORDER_STATUSES;
