import { AnalyticsEvent } from '../../enums/analytics_event.enum';
import {
  INativeAnalyticsEvent,
  INativeAnalyticsEventEcommerceProduct
} from '../../models/native_analytics_event';
import { ProductModel } from '../../models/product.model';
import RootStore from '../../stores';
import { sendAnalyticsEvent } from '../native-apps-communication';

export default class NativeAnalytics {
  root: RootStore;

  constructor(root: RootStore) {
    this.root = root;
  }

  _productsFromBasket() {
    return this.root.basketStore.products.map((product) =>
      this._productModel(product.product)
    );
  }

  _productModel(product: ProductModel): INativeAnalyticsEventEcommerceProduct {
    return {
      id: String(product.id),
      name: product.name,
      price: product.priceWithIngredients,
      quantity: product.count > 0 ? product.count : 1
    };
  }

  trackViewShop(shopName: string) {
    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.VIEW_SHOP,
      branchName: shopName
    };

    sendAnalyticsEvent(data);
  }

  trackAddToBasket(product: ProductModel) {
    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.ADD_TO_BASKET,
      ecommerce: {
        currencyCode: this.root.restaurantStore.branch.currencyCode,
        products: [this._productModel(product)]
      }
    };

    sendAnalyticsEvent(data);
  }

  trackProvidePersonalInformation() {
    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.PROVIDE_PERSONAL_INFORMATION,
      ecommerce: {
        currencyCode: this.root.restaurantStore.branch.currencyCode,
        products: this._productsFromBasket()
      }
    };

    sendAnalyticsEvent(data);
  }

  trackStartCheckout() {
    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.START_CHECKOUT,
      ecommerce: {
        currencyCode: this.root.restaurantStore.branch.currencyCode,
        totalPrice: this.root.basketStore.finalPriceWithOffers,
        products: this._productsFromBasket()
      }
    };

    sendAnalyticsEvent(data);
  }

  trackPlaceOrder() {
    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.PLACE_ORDER,
      branchName: this.root.restaurantStore.branch.branchName,
      ecommerce: {
        orderId: this.root.orderPaymentMethodsStore.orderId,
        totalPrice: this.root.basketStore.finalPriceWithOffers,
        deliveryFee: this.root.basketStore.deliveryFee,
        products: this._productsFromBasket(),
        currencyCode: this.root.restaurantStore.branch.currencyCode
      }
    };

    sendAnalyticsEvent(data);
  }

  trackSuccessfulPayment() {
    const {
      basketStore,
      restaurantStore,
      orderPaymentMethodsStore
    } = this.root;

    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.SUCCESSFUL_PAYMENT,
      branchName: restaurantStore.branch.branchName,
      ecommerce: {
        orderId: orderPaymentMethodsStore.orderId,
        totalPrice: basketStore.finalPriceWithOffers,
        deliveryFee: basketStore.deliveryFee,
        products: this._productsFromBasket(),
        currencyCode: restaurantStore.branch.currencyCode
      }
    };

    sendAnalyticsEvent(data);
  }

  trackFailedPayment() {
    const {
      basketStore,
      restaurantStore,
      orderPaymentMethodsStore
    } = this.root;

    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.FAILED_PAYMENT,
      branchName: restaurantStore.branch.branchName,
      ecommerce: {
        orderId: orderPaymentMethodsStore.orderId,
        totalPrice: basketStore.finalPriceWithOffers,
        deliveryFee: basketStore.deliveryFee,
        products: this._productsFromBasket(),
        currencyCode: restaurantStore.branch.currencyCode
      }
    };

    sendAnalyticsEvent(data);
  }

  trackGlobalError(link: string) {
    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.GLOBAL_ERROR,
      restaurantLink: link
    };

    sendAnalyticsEvent(data);
  }

  trackPhoneNumberTopBar() {
    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.PHONE_NUMBER_TOP_BAR
    };

    sendAnalyticsEvent(data);
  }

  trackPhoneNumberLegalPage() {
    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.PHONE_NUMBER_LEGAL_PAGE
    };

    sendAnalyticsEvent(data);
  }

  trackPhoneNumberSuccessfulPayment() {
    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.PHONE_NUMBER_SUCCESSFUL_PAYMENT
    };

    sendAnalyticsEvent(data);
  }

  trackPhoneNumberFailedOrder() {
    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.PHONE_NUMBER_FAILED_ORDER
    };

    sendAnalyticsEvent(data);
  }

  trackPhoneNumberAppMainPage() {
    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.PHONE_NUMBER_APP_MAIN_PAGE
    };

    sendAnalyticsEvent(data);
  }

  trackPhoneNumberAppSidemenu() {
    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.PHONE_NUMBER_APP_SIDEMENU
    };

    sendAnalyticsEvent(data);
  }

  trackConfirmedOrder() {
    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.CONFIRMED_ORDER
    };

    sendAnalyticsEvent(data);
  }

  trackCanceledOrder() {
    const data: INativeAnalyticsEvent = {
      event: AnalyticsEvent.CANCELED_ORDER
    };

    sendAnalyticsEvent(data);
  }
}
