import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import BasketShellItem from '../basket-item';

import './styles.scss';
export default class BasketShellWithProducts extends PureComponent {
  static propTypes = {
    products: PropTypes.object,
    offers: PropTypes.object,
    showActionButtons: PropTypes.bool,
    showItemQuantity: PropTypes.bool,
    cantAddProductsToBasket: PropTypes.bool,
    setOfferChangeIndex: PropTypes.func,
    removeAction: PropTypes.func,
    addAction: PropTypes.func,
    getPrice: PropTypes.func.isRequired,
    prepareProductsForEditing: PropTypes.func,
    isReadOnly: PropTypes.bool.isRequired,
    isOffersCanBeAddedToBasket: PropTypes.bool
  };

  static defaultProps = {
    showActionButtons: true,
    showItemQuantity: false
  };

  _renderProducts = () => {
    return this.props.products.map((groupedProduct, index) => {
      return (
        <BasketShellItem
          key={index}
          indexOfProduct={index}
          group={groupedProduct}
          getPrice={this.props.getPrice}
          plusAction={this.props.addAction}
          minusAction={this.props.removeAction}
          setChangeIndex={this.props.setOfferChangeIndex}
          prepareProductsForEditing={this.props.prepareProductsForEditing}
          cantAddProductsToBasket={this.props.cantAddProductsToBasket}
          isReadOnly={this.props.isReadOnly}
        />
      );
    });
  };

  _renderFreeItemOffers = () => {
    return this.props.cantAddProductsToBasket
      ? this.props.offers
          .filter((offerGroup) => offerGroup.offer.isFreeOffer)
          .map((offerGroup, index) => {
            return (
              <BasketShellItem
                key={index}
                indexOfProduct={index}
                group={offerGroup}
                getPrice={this.props.getPrice}
                plusAction={this.props.addAction}
                minusAction={this.props.removeAction}
                setChangeIndex={this.props.setOfferChangeIndex}
                prepareProductsForEditing={this.props.prepareProductsForEditing}
                cantAddProductsToBasket={this.props.cantAddProductsToBasket}
                isReadOnly={this.props.isReadOnly}
              />
            );
          })
      : null;
  };

  _renderOffers = () => {
    return this.props.offers
      .filter((offerGroup) => !offerGroup.offer.isFreeOffer)
      .map((offerGroup, index) => {
        return (
          <BasketShellItem
            key={index}
            indexOfProduct={index}
            group={offerGroup}
            getPrice={this.props.getPrice}
            plusAction={this.props.addAction}
            minusAction={this.props.removeAction}
            setChangeIndex={this.props.setOfferChangeIndex}
            prepareProductsForEditing={this.props.prepareProductsForEditing}
            cantAddProductsToBasket={this.props.cantAddProductsToBasket}
            isReadOnly={this.props.isReadOnly}
            disablePlusButton={!this.props.isOffersCanBeAddedToBasket}
          />
        );
      });
  };

  render() {
    return (
      <div className="shell-basket-with-products">
        <div className="product-list">
          {this._renderFreeItemOffers()}
          {this._renderProducts()}
          {this._renderOffers()}
        </div>
      </div>
    );
  }
}
