/* eslint-disable camelcase */
import dayjs from 'dayjs';
import isNin from 'lodash/isNil';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';

import { SpecialTimes } from 'client/models/branch.model.type';

import DELIVERY_CALCULATION_TYPES from '../enums/delivery_calculation_types.enum';
import { ORDER_FIELDS } from '../enums/order_fields.enum';
import { ORDER_TYPES } from '../enums/order_types.enum';
import PRODUCT_IMAGE_TYPES from '../enums/product_images.enum';
import SHELL_HOMEPAGE_TYPE from '../enums/shell_homepage_type.enum';
import { trimValue } from '../utils/functions';

import { IArea } from './area.model';
import { AreaCodeModel } from './area_code.model';
import { IBranchResponseModel } from './branch_response.model';
import { IDay } from './day.model';
import { IOfferCategory } from './offer_category.model';
import { PaymentMethod } from './payment_method.model';
import { IBranchProperty } from './property.model';

/**
 * Restaurant model
 * @constructor
 * @property {uuid} id - Unique id of restaurant.
 * @property {string} title - Restaurant title.
 * @property {string} lang - Restaurant language.
 * @property {array} branches - Restaurant branches.
 * @property {boolean} isHolidayModeActive - Holiday mode active
 * @property {string} holidayModeTitleText - Holiday mode title
 * @property {string} holidayModeDescription - Holiday mode description
 * @property {string} holidayModePicture - Holiday mode image url
 */
export class BranchModel {
  id: string;

  title: string;

  areaCodes: AreaCodeModel[];

  hoursDelivery: IDay[];

  hoursPickup: IDay[];

  paymentMethods: PaymentMethod[];

  offerCategory: IOfferCategory;

  postalCode: string;

  slug: string;

  address: string;

  latitude: string;

  longitude: string;

  maxDeliveryFee?: number;

  minDeliveryFee?: number;

  maxMbw?: number;

  minMbw?: number;

  isApp: boolean;

  parsedAreaCodes: IArea[];

  // Private
  companyName = '';

  orderTypes = '0';

  deliveryClosed = true;

  pickupClosed = true;

  deliverySecondsUntilOpening = 0;

  pickupSecondsUntilOpening = 0;

  upcomingHolidayStart: string | undefined = undefined;

  isPreorder = false;

  isPreorderAllways = false;

  isMigrated = false;

  discountDelivery = 0;

  discountDeliveryWeb = 0;

  discountPickup = 0;

  discountPickupWeb = 0;

  discountHungerDelivery = 0;

  discountHungerPickup = 0;

  discountHungerDeliveryWeb = 0;

  discountHungerPickupWeb = 0;

  hasOffers = false;

  isTableReservation = false;

  hasVouchers = false;

  hideTel = false;

  maxSelfcollectTime = '0';

  averageDeliveryTime = '0';

  mbv = 0;

  offerIsSimplified = false;

  hideArticleNumbers = false;

  hideMarketingCheckbox = false;

  fax = '';

  tel = '';

  websitePhoneNumber = '';

  lat = 0;

  long = 0;

  city = '';

  cityCode = '';

  countryName = '';

  currencyCode = '';

  email = '';

  owneremail = '';

  ownername = '';

  shopmanagerName = '';

  shopmanagerEmail = '';

  taxNumber = '';

  companyRegistrationNumber = '';

  streetNo = '';

  street = '';

  urlWeb = '';

  urlFB = '';

  additionalOrderFields: string[] = [];

  discountsBeforeMbwCheck = 0;

  showCustomStartPage = false;

  isHolidayMode = false;

  holidayModeTitle = '';

  holidayModeDesc = '';

  holidayModePicUrl = '';

  holidays: string[] = [];

  allowedFieldsInOrderForm: string[] = [];

  hideDevelopedByUsHint = false;

  hideOrderSmartLogoAndSEOLink = false;

  isHideMarketingData = false;

  hideShopOwner = false;

  hideFootImprint = false;

  advancedProductView = 0;

  hideProductImages = 0;

  orderCustomText = '';

  isShowPickupDeliveryTimeInfoAfterOrder = false;

  isBigPicture: PRODUCT_IMAGE_TYPES =
    PRODUCT_IMAGE_TYPES.SMALL_PRODUCT_PICTURES;

  shellHomepageType: SHELL_HOMEPAGE_TYPE = SHELL_HOMEPAGE_TYPE.DEFAULT;

  is_appsmart_credentials = false;

  negativeExtras = false;

  negativeExtrasPricingModel = '';

  negativeExtrasTitle = '';

  activeDeliveryCalculationType = '';

  defaultThemeHomepage: number;

  HalalCertificate: string;

  specialTimes: SpecialTimes;

  isSimplePickUpForm: boolean;

  isShowLocation: boolean;

  /**
   * Contactless delivery toggle. True - display "Contactless delivery" notification.
   * @type {boolean}
   */
  cd = false;

  /**
   * Contactless pickup toggle. True - display "Contactless pickup" notification.
   * @type {boolean}
   */
  cp = false;

  constructor(branchResponse: IBranchResponseModel, isApp: boolean) {
    Object.assign(this, branchResponse);

    this.id = branchResponse.id;

    this.title = branchResponse.title;

    this.areaCodes = branchResponse.areaCodes;

    this.hoursDelivery = branchResponse.hoursDelivery;

    this.hoursPickup = branchResponse.hoursPickup;

    this.paymentMethods = branchResponse.paymentMethods;

    this.offerCategory = branchResponse.offerCategory;

    this.postalCode = branchResponse.postalCode;

    this.slug = branchResponse.slug;

    this.address = branchResponse.address;

    this.latitude = branchResponse.latitude;

    this.longitude = branchResponse.longitude;

    this.isMigrated = branchResponse.isMigrated;

    this.isApp = isApp;

    this.parsedAreaCodes = [];

    this.maxDeliveryFee = maxBy(this.areaCodes, 'deliveryCosts')?.deliveryCosts;

    this.minDeliveryFee = minBy(this.areaCodes, 'deliveryCosts')?.deliveryCosts;

    this.maxMbw = maxBy(this.areaCodes, 'mbv')?.mbv;

    this.minMbw = minBy(this.areaCodes, 'mbv')?.mbv;

    if (branchResponse.properties) {
      branchResponse.properties.forEach((prop: IBranchProperty) => {
        if (!isNin(prop.valueStr)) (this as any)[prop.code] = prop.valueStr;

        if (!isNin(prop.valueNum)) (this as any)[prop.code] = prop.valueNum;

        if (!isNin(prop.valueBool)) (this as any)[prop.code] = prop.valueBool;

        if (!isNin(prop.valueArr)) (this as any)[prop.code] = prop.valueArr;
      });
    }

    this.branchSettings(this.areaCodes);
  }

  branchSettings(areaCodes: AreaCodeModel[]) {
    this.parsedAreaCodes = areaCodes.map((i) => ({
      value: `${i.areaCode} - ${i.sublocality}`,
      label: `${i.areaCode} - ${i.sublocality}`,
      zip: i.areaCode,
      sublocality: i.sublocality,
      title: this.title,
      branchId: this.id
    }));
  }

  /**
   * Company name (for imprint)
   * @returns {*}
   */
  get companyNameImprint() {
    return trimValue(this.companyName);
  }

  get isAppPlatform() {
    return this.isApp;
  }

  get branchId() {
    return this.id;
  }

  get branchName() {
    return this.title;
  }

  get getAreaCodes() {
    return this.areaCodes;
  }

  get getOrderTypes(): ORDER_TYPES[] {
    switch (parseInt(this.orderTypes, 10)) {
      case 0:
        return [ORDER_TYPES.DELIVERY, ORDER_TYPES.PICKUP];
      case 1:
        return [ORDER_TYPES.DELIVERY];
      case 2:
        return [ORDER_TYPES.PICKUP];
      default:
        return [];
    }
  }

  get closedForDelivery() {
    return this.deliveryClosed;
  }

  get closedForPickUp() {
    return this.pickupClosed;
  }

  get secondsUntilOpenDelivery() {
    return this.deliverySecondsUntilOpening || 0;
  }

  get secondsUntilOpenPickUp() {
    return this.pickupSecondsUntilOpening || 0;
  }

  get getUpcomingHolidayStart() {
    return this.upcomingHolidayStart
      ? dayjs(this.upcomingHolidayStart, 'YYYY-MM-DD')
      : undefined;
  }

  get branchHasPreorder() {
    return this.isPreorder || this.branchHasAllwaysPreorder;
  }

  get branchHasAllwaysPreorder() {
    return this.isPreorderAllways;
  }

  get getDiscountDelivery() {
    return this.isAppPlatform
      ? this.discountDelivery
      : this.discountDeliveryWeb;
  }

  get getDiscountPickUp() {
    return this.isAppPlatform ? this.discountPickup : this.discountPickupWeb;
  }

  get getDiscountDeliveryApp() {
    return this.discountDelivery;
  }

  get getDiscountPickupApp() {
    return this.discountPickup;
  }

  get getDiscountDeliveryWeb() {
    return this.discountDeliveryWeb;
  }

  get getDiscountPickupWeb() {
    return this.discountPickupWeb;
  }

  get getDiscountHungerDelivery() {
    return this.discountHungerDeliveryWeb;
  }

  get getDiscountHungerPickup() {
    return this.discountHungerPickupWeb;
  }

  get branchHasOffers() {
    return this.hasOffers;
  }

  get branchIsTableReservation() {
    return this.isTableReservation || false;
  }

  get branchHasVouchers() {
    return this.hasVouchers;
  }

  get getHideTel() {
    return this.hideTel || false;
  }

  /**
   * Delivery time + cooking time
   * @returns {*|number}
   */
  get getAverageDeliveryTime() {
    return parseInt(this.averageDeliveryTime, 10) || 0;
  }

  /**
   * Cooking time
   * @returns {*|number}
   */
  get getMaxSelfcollectTime() {
    return parseInt(this.maxSelfcollectTime, 10) || 0;
  }

  get getMbw() {
    return this.mbv;
  }

  get getOfferIsSimplified() {
    return this.offerIsSimplified;
  }

  /**
   * Method to get state visibility of articles numbers
   * @returns {boolean}
   */
  get isHideArticleNumbers() {
    return this.hideArticleNumbers || false;
  }

  /**
   * Method to get state hiding marketing checkbox
   * @returns {boolean}
   */
  get isHideMarketingCheckbox() {
    return this.hideMarketingCheckbox || false;
  }

  // #region Contacts

  /**
   * Fax number
   * @returns {string}
   */
  get getFax() {
    return trimValue(this.fax);
  }

  /**
   * Phone number
   * @returns {string}
   */
  get getTel() {
    return trimValue(this.tel);
  }

  /**
   * Phone number for using by website
   * @returns {string}
   */
  get getWebsitePhone() {
    return trimValue(this.websitePhoneNumber);
  }

  /**
   * Latitude
   * @returns {string}
   */
  get getLat() {
    return trimValue(this.lat);
  }

  /**
   * Longitude
   * @returns {string}
   */
  get getLong() {
    return trimValue(this.long);
  }

  /**
   * City
   * @returns {string}
   */
  get getCity() {
    return trimValue(this.city);
  }

  /**
   * City code
   * @returns {string}
   */
  get getCityCode() {
    return trimValue(this.cityCode);
  }

  /**
   * Country
   * @returns {string}
   */
  get getCountry() {
    return trimValue(this.countryName);
  }

  /**
   * Currency code
   * @returns {string}
   */
  get getCurrencyCode() {
    return trimValue(this.currencyCode);
  }

  /**
   * Shop email
   * @returns {string}
   */
  get getEmail() {
    return trimValue(this.email);
  }

  /**
   * Shop owner email
   * @returns {*|string}
   */
  get getOwneremail() {
    return trimValue(this.owneremail);
  }

  /**
   * Shop owner name
   * @returns {string}
   */
  get getOwnername() {
    return trimValue(this.ownername);
  }

  /**
   * Shop manager name
   * @returns {string}
   */
  get getShopmanagerName() {
    return trimValue(this.shopmanagerName);
  }

  /**
   * Shop manager email
   * @returns {string}
   */
  get getShopmanagerEmail() {
    return trimValue(this.shopmanagerEmail);
  }

  /**
   * TAX (VAT) number
   * @returns {string}
   */
  get getVatin() {
    return trimValue(this.taxNumber);
  }

  /**
   * Company registration number number
   * @returns {string}
   */
  get getRegistrationNumber() {
    return trimValue(this.companyRegistrationNumber);
  }

  /**
   * Street number
   * @returns {string}
   */
  get getStreetNo() {
    return trimValue(this.streetNo);
  }

  /**
   * Street
   * @returns {string}
   */
  get getStreet() {
    return trimValue(this.street);
  }

  /**
   * Website url
   * @returns {string}
   */
  get getUrlWeb() {
    return trimValue(this.urlWeb);
  }

  /**
   * Facebook url
   * @returns {string}
   */
  get getUrlFB() {
    return trimValue(this.urlFB);
  }

  /**
   * Is branch has contact info
   * @returns {boolean}
   */
  get hasContactInfo() {
    return !!(
      this.branchName ||
      this.getStreet ||
      this.getCity ||
      this.getOwnername ||
      this.getShopmanagerName ||
      this.getVatin
    );
  }

  // #endregion Contacts

  get getPaymentMethods() {
    return this.paymentMethods;
  }

  get getAdditionalOrderFields() {
    return this.additionalOrderFields;
  }

  get getDeliveryHours() {
    return this.hoursDelivery;
  }

  get getPickUpHours() {
    return this.hoursPickup;
  }

  get getDiscountsBeforeMbwCheck() {
    return this.discountsBeforeMbwCheck;
  }

  get isHomepageAvailable() {
    return this.showCustomStartPage || false;
  }

  // #region Holiday mode

  /**
   * Holiday mode state
   * @returns {boolean}
   */
  get isHolidayModeActive() {
    return this.isHolidayMode || false;
  }

  /**
   * Holiday mode title text
   * @returns {string}
   */
  get holidayModeTitleText() {
    return this.holidayModeTitle || '';
  }

  /**
   * Holiday mode description text
   * @returns {string}
   */
  get holidayModeDescription() {
    return this.holidayModeDesc || '';
  }

  /**
   * Holiday mode image
   * @returns {string}
   */
  get holidayModePicture() {
    return this.holidayModePicUrl || '';
  }

  /**
   * Contains array of dates with active holiday mode
   * @returns {*|Array}
   */
  get holidaysDates() {
    return this.holidays || [];
  }
  // #endregion Holiday mode

  /**
   * Offer category name
   * @returns {string}
   */
  get getOfferCategory() {
    return this.offerCategory || '';
  }

  /**
   * Array of additional fields for order form
   * @returns {array}
   */
  get getAllowedFieldsInOrderForm() {
    const renamedFields = {
      City: ORDER_FIELDS.city,
      Company: ORDER_FIELDS.company,
      Number: ORDER_FIELDS.number,
      'Phone Number': ORDER_FIELDS.phone,
      'Postal code': ORDER_FIELDS.zip,
      Street: ORDER_FIELDS.street
    };

    return (
      this.allowedFieldsInOrderForm?.map(
        (field) => renamedFields[field] || field
      ) || []
    );
  }

  /**
   * Method to return state of hiding app-smart logo from imprint/terms of use pages
   * @returns {*|boolean} true - if hide
   */
  get isHideLogoFromImprint() {
    return this.hideDevelopedByUsHint || false;
  }

  /**
   * Method to return state of hiding app-smart logo from footer
   * @returns {*|boolean} true - if hide
   */
  get isHideLogoFromFooter() {
    return (
      this.hideOrderSmartLogoAndSEOLink || this.isHideMarketingData || false
    );
  }

  /**
   * Method to return state of hiding owner info from footer
   * @returns {*|boolean} true - if hide
   */
  get isHideOwnerInfoFromFooter() {
    return this.hideShopOwner || false;
  }

  /**
   * Method to return state of hiding restaurant info from footer
   * @returns {*|boolean} true - if hide
   */
  get isHideRestaurantInfoFromFooter() {
    return this.hideFootImprint || false;
  }

  /**
   * Use product view in modal
   * @return {boolean}
   */
  get isUseAdvancedProductView() {
    return !!this.advancedProductView;
  }

  /**
   * Is product images in products list should be hidden
   * @returns {boolean}
   */
  get isHideProductImages() {
    return !!this.hideProductImages || false;
  }

  /**
   * Text message after successful order
   * @returns {*|string}
   */
  get textAfterSuccessfulOrder() {
    return this.orderCustomText || '';
  }

  /**
   * Is show information about pickup/delivery time after order
   * @returns {boolean}
   */
  get showPickupDeliveryTimeInfoAfterOrder() {
    return this.isShowPickupDeliveryTimeInfoAfterOrder || false;
  }

  /**
   * Big pictures variant 1 enabled flag
   * @returns {boolean}
   */
  get showBigPictureVariant1() {
    return (
      this.isBigPicture === PRODUCT_IMAGE_TYPES.BIG_PICTURES_VARIANT_1 || false
    );
  }

  /**
   * Big pictures variant 2 enabled flag
   * @returns {boolean}
   */
  get showBigPictureVariant2() {
    return (
      this.isBigPicture === PRODUCT_IMAGE_TYPES.BIG_PICTURES_VARIANT_2 || false
    );
  }

  /**
   * Small pictures enabled flag
   * @returns {boolean}
   */
  get showSmallPicture() {
    return (
      this.isBigPicture === PRODUCT_IMAGE_TYPES.SMALL_PRODUCT_PICTURES || false
    );
  }

  /**
   * Small pictures enabled flag
   * @returns {boolean}
   */
  get showNoPicture() {
    return this.isBigPicture === PRODUCT_IMAGE_TYPES.NO_PICTURES || false;
  }

  /**
   * Shell homepage type
   * @returns {*|number}
   */
  get shellHomepageScreenType() {
    return this.shellHomepageType || SHELL_HOMEPAGE_TYPE.DEFAULT;
  }

  /**
   * Use in legal pages appsmart credentials
   * @returns {boolean}
   */
  get useAppsmartCredentials(): boolean {
    return this.is_appsmart_credentials || false;
  }

  /**
   * Advanced calculation type of delivery
   * @returns {boolean}
   */
  get isAdvancedDeliveryCalculationType(): boolean {
    return (
      this.activeDeliveryCalculationType === DELIVERY_CALCULATION_TYPES.ADVANCED
    );
  }

  /**
   * Basic GPS calculation type of delivery
   * @returns {boolean}
   */
  get isBasicGpsCalculationType(): boolean {
    return (
      this.activeDeliveryCalculationType ===
      DELIVERY_CALCULATION_TYPES.BASIC_GPS
    );
  }

  /**
   * Negative extras feature toggle value
   * @returns {boolean}
   */
  get isNegativeExtrasActive(): boolean {
    return this.negativeExtras;
  }

  /**
   * Negative extras pricing model
   */
  get getNegativeExtrasPricingModel(): string {
    return this.negativeExtrasPricingModel;
  }

  /**
   * Negative extras title
   */
  get getNegativeExtrasTitle(): string {
    return this.negativeExtrasTitle;
  }

  /**
   * Will show contact less delivery message and add comment to order
   * @returns {boolean}
   */
  get showContactLessDeliveryMessage(): boolean {
    return this.cd || false;
  }

  /**
   * Will show contact less pickup message and add comment to order
   * @returns {boolean}
   */
  get showContactLessPickupMessage(): boolean {
    return this.cp || false;
  }
}
