// Imports
import React, { PureComponent } from 'react';

import SlickCarousel from '../../../../../../../components/SlickCarousel';
// Utils
import { ELEMENTS_TYPES } from '../../../../../../../enums/homepage_elements_types.enum';
import offerTypes from '../../../../../../../enums/offer_types.enum';
import SliderLinksTypes from '../../../../../../../enums/slider_links_types.enum';
import states from '../../../../../../../enums/states.enum';
import { createHomepageHrefLink } from '../../../../../../../utils/functions';
import { generateLinkFor } from '../../../../../../../utils/routing';

// Styles
import './styles.scss';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';

/**
 * Homepage mobile component to render banner slider
 */
@inject('store')
@withRouter
export default class HomepageMobileBanners extends PureComponent {
  /**
   * Method to handle slide click
   * @param slide
   */
  _onSlideClick = (slide) => {
    switch (slide.link.type) {
      case SliderLinksTypes.OFFER:
        return this.props.history.push(
          generateLinkFor(
            `${states.offer}/${offerTypes.KOMBI}/${slide.link.value}`,
            this.props,
            {},
            true
          )
        );
      case SliderLinksTypes.CATEGORY:
        return this.props.history.push(
          generateLinkFor(
            `${states.cat}/${slide.link.value}`,
            this.props,
            {},
            true
          )
        );
      case SliderLinksTypes.PRODUCT:
        this._onProductClick(slide.link.value);
    }
  };

  /**
   * Method to add product to basket
   * @param {number} productId
   */
  _onProductClick = (productId) => {
    const { history } = this.props;

    this.props.store.productsStore.addProductById(productId, history);
  };

  /**
   * Method to render slide info block
   * @param title
   * @param subtitle
   * @returns {*}
   * @private
   */
  _renderInfo = (title, subtitle) => (
    <div className="info">
      <div className="subtitle">{subtitle}</div>
      <div className="title">{title}</div>
    </div>
  );

  /**
   * Method to render discount stripe
   * @param {string} title - stripe text
   * @returns {*}
   * @private
   */
  _renderDiscountStripe = (title) => (
    <div className="discount-stripe">
      <div className="text">{title}</div>
    </div>
  );

  /**
   * Method to render single slide
   * @param slide - {object} slide object
   * @param key - {number}
   * @returns {null}
   * @private
   */
  _renderItem = (slide, key) =>
    slide.picUrl ? (
      <div
        className="mobile-homepage-banner"
        key={key}
        onClick={() => this._onSlideClick(slide)}
      >
        {slide.discountText && this._renderDiscountStripe(slide.discountText)}
        <div className="img-wrapper">
          <img src={slide.picUrl} alt={slide.title} />
        </div>
        {slide.title && this._renderInfo(slide.title, slide.description)}
      </div>
    ) : null;

  _renderLink = (slide, key) =>
    slide.picUrl ? (
      <a
        href={slide.link.value}
        target="_blank"
        rel="nofollow noreferrer"
        className="mobile-homepage-banner"
        key={key}
      >
        {slide.discountText && this._renderDiscountStripe(slide.discountText)}
        <div className="img-wrapper">
          <img src={slide.picUrl} alt={slide.title} />
        </div>
        {slide.title && this._renderInfo(slide.title, slide.description)}
      </a>
    ) : null;

  _renderSlide = (slide, key) => {
    switch (slide.link.type) {
      case SliderLinksTypes.URL:
        return this._renderLink(slide, key);
      default:
        return this._renderItem(slide, key);
    }
  };

  render() {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      autoplaySpeed: 5000,
      autoplay: true
    };

    return (
      <section
        className="mobile-homepage-section banners-slider"
        id={createHomepageHrefLink([ELEMENTS_TYPES.SLIDER, this.props.index])}
      >
        <SlickCarousel settings={settings}>
          {this.props.slides.length &&
            this.props.slides.map((item, index) =>
              this._renderSlide(item, index)
            )}
        </SlickCarousel>
      </section>
    );
  }
}
