import dayjs from 'dayjs';
import sum from 'lodash/sum';
import { action, computed, observable, toJS } from 'mobx';
import { v1 } from 'uuid';

import { PriceRule } from 'client/enums/price.enum';
import RootStore from 'client/stores';

import OFFER_TYPES from '../enums/offer_types.enum';
import { getWeekDaysArray } from '../utils/functions';
import { generateOfferHours } from '../utils/time';

import { IDayInterface } from './offer.model.type';
import { OfferProductGroupModel } from './offer_product_group.model';
import { ProductModel } from './product.model';

/**
 * Offer model class. Used to work with offers.
 * @constructor
 * @param {object} id - offer id.
 * @param {object} offer - offer object.
 * @param {object} root - stores.
 * @property {number} id - Unique offer identifier.
 * @property {string} title - The name of the offer as it is called internally.
 * @property {string} description - The description of the offer as it is called internally.
 * @property {string} img - The image of the offer as it is called internally.
 * @property {number} pos - Position of the offer.
 * @property {string} offerType - Type of the offer. Full list of available types can be found in offer_types.enum.js
 * @property {array} days - Offer working hours
 * @property {array} parsedDays - Offer grouped working hours
 * @property {string} startDay - Offer start day
 * @property {string} endDay - Offer end day
 * @property {boolean} validForPreorder - Is offer available for preorder
 * @property {boolean} validForDelivery - Is offer available for delivery
 * @property {boolean} validForPickup - Is offer available for pickup
 * @property {boolean} validForDiscountPickup - Is offer available for pickup discount
 * @property {boolean} validForDiscountDelivery - Is offer available for delivery discount
 * @property {boolean} validForDeliveryMbv - Is offer available for delivery mbv
 * @property {boolean} validForPickupMbv - Is offer available for pickup mbv
 * @property {number} validFromMbvDelivery - MBV value delivery when product is available
 * @property {number} validFromMbvPickup - MBV value pickup when product is available
 * @property {number} minQty - Minimal number of times that user has to select offer.
 * @property {number} maxQty - Maximum number of times that user has to select offer.
 * @property {array} groups - List of product groups.
 * @property {array} products - Free item products
 */
export class OfferModel {
  root: RootStore;

  uuid: string;

  id: number;

  inappTitle: string;

  inappDescription: string;

  inappImg: string;

  offerType: 'free' | 'kombi' | 'fixed';

  validNow: boolean;

  startDay: string;

  endDay: string;

  validForPreorder: boolean;

  validForDelivery: boolean;

  validForPickup: boolean;

  validForDiscountPickup: boolean;

  validForDiscountDelivery: boolean;

  validForDeliveryMbv: boolean | number;

  validForPickupMbv: boolean | number;

  validFromMbvDelivery: number;

  validFromMbvPickup: number;

  priceDelivery: number;

  priceDeliveryRule: PriceRule;

  pricePickup: number;

  pricePickupRule: PriceRule;

  products: ProductModel[];

  constructor(id, offer, root) {
    this.root = root;

    this.id = parseInt(id, 10);

    this.pos = offer.pos;

    this.title = offer.title;

    this.inappTitle = offer.inappTitle;

    this.description = offer.description;

    this.inappDescription = offer.inappDescription;

    this.img = offer.img || '';

    this.inappImg = offer.inappImg || '';

    this.offerType = offer.offerType.toLowerCase();

    this.validNow = offer.validNow;

    this.days = this.parseDays(offer.days);

    this.parsedDays = this.getParsedDays(this.days);

    this.startDay = offer.startDay || offer.startDt;

    this.endDay = offer.endDay || offer.endDt;

    this.validForPreorder = offer.validForPreorder || offer.isPreorder;

    this.validForDelivery = offer.validForDelivery || offer.isDelivery;

    this.validForPickup = offer.validForPickup || offer.isPickup;

    this.validForDiscountPickup =
      offer.validForDiscountPickup || offer.isDiscountPickup;

    this.validForDiscountDelivery =
      offer.validForDiscountDelivery || offer.isDiscountDelivery;

    this.validForDeliveryMbv = offer.validForDeliveryMbv || offer.isMbvDelivery;

    this.validForPickupMbv = offer.validForPickupMbv || offer.isMbvPickup;

    this.validFromMbvDelivery = offer.validFromMbvDelivery || offer.mbvDelivery;

    this.validFromMbvPickup = offer.validFromMbvPickup || offer.mbvPickup;

    this.priceDelivery = offer.priceDelivery;

    this.priceDeliveryRule = offer.priceDeliveryRule;

    this.pricePickup = offer.pricePickup;

    this.pricePickupRule = offer.pricePickupRule;

    this.minQty = offer.minQty;

    this.maxQty = offer.maxQty;

    this.groups = offer.groups
      ? offer.groups.map(
          (item, index) => new OfferProductGroupModel(item, index, root)
        )
      : [];

    this.products = offer.products
      ? offer.products.map((p) => new ProductModel(p.id, p, root, true))
      : [];

    this.allSteps = this.groups.length > 0 ? this.groups.length - 1 : 0;

    this.hasProducts = offer.hasProducts;

    this.uuid = v1();
  }

  title = '';

  description = '';

  img = '';

  pos = 0;

  minQty = 0;

  maxQty = 0;

  days: IDayInterface[] = [];

  parsedDays: IDayInterface[][] = getWeekDaysArray();

  hasProducts = false;

  @observable groups: OfferProductGroupModel[] = [];

  @observable free_product_groups = [];

  @observable current_step = 0;

  @observable allSteps = 0;

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  getToJS() {
    return Object.assign(toJS({ ...this, root: {} }), {
      groups: this.groups && this.groups.map((group) => group.getToJS()),
      products:
        this.products && this.products.map((product) => product.getToJS())
    });
  }

  /**
   * Validate offer working hours
   * @param {array} days
   * @returns {array}
   * @memberof OfferModel
   */
  parseDays(days) {
    if (days && days.length !== 0) {
      days.forEach((i) => {
        if (i.endT === '00:00' || i.endT === null) i.endT = '23:59';

        if (i.startT === null) i.startT = '00:00';
      });

      return days;
    }

    return getWeekDaysArray();
  }

  /**
   * Get array of weekdays grouped by working hours
   * @param {array} days
   * @returns {array}
   * @memberof OfferModel
   */
  getParsedDays(days) {
    if (days && days.length !== 0) {
      const arr2 = [...days].sort((a, b) => a.dayNo - b.dayNo);

      if (arr2[0].dayNo === '0') {
        arr2[0].daySort = dayjs.localeData().firstDayOfWeek() === 1 ? 7 : 0;

        for (let i = 1; i < arr2.length; i++) {
          arr2[i].daySort = i - 1;
        }
      } else {
        for (let i = 0; i < arr2.length; i++) {
          arr2[i].daySort = i;
        }
      }

      const d = (generateOfferHours(arr2) as any).sort((a, b) => {
        a.sort((a1, b1) => a1.daySort - b1.daySort);

        return a[0].daySort - b[0].daySort;
      });

      d.forEach((i) => {
        i.forEach((c, index) => {
          if (index !== i.length - 1 && index !== 0) {
            c.show = !(
              parseInt(c.dayNo) - 1 === parseInt(i[index - 1].dayNo) &&
              parseInt(c.dayNo) + 1 === parseInt(i[index + 1].dayNo)
            );
          } else {
            c.show = true;
          }
        });
      });

      return d;
    }

    return [];
  }

  /**
   * Method to select only one product and deselect others.
   * @param {string} productId - The unique identifier of product to select.
   * @param {object} group - Group object
   * @memberof OfferModel#
   * @method selectOnlyOneProductInGroup
   */
  @action selectOnlyOneProductInGroup(group, productId) {
    group.cachedProducts.forEach((product) => {
      if (product._uuid === productId) {
        product.count = 1;
      } else {
        product.count = 0;
      }
    });

    group.choosedProduct.setSize(group.choosedProduct.sizes[0].id);

    if (!group.choosedProduct.availableForOptions) {
      group.step.finished = true;

      group.step.showProduct = false;
    } else {
      group.step.finished = false;
    }
  }

  /**
   * Select free item product
   * @param {number} productId
   * @memberof OfferModel
   */
  @action selectFreeItemProduct(productId) {
    this.products.forEach((product) => {
      if (product._uuid === productId) {
        product.count = 1;
      } else {
        product.count = 0;
      }
    });

    this.chosenFreeItemProduct.setSize(
      (this.chosenFreeItemProduct.sizes[0] as any).id
    );
  }

  /**
   * Increase offer step to show
   * next product group in modal
   * @memberof OfferModel
   */
  @action increaseStep() {
    this.currentGroup.step.finished = true;

    this.currentGroup.step.showProduct = true;

    this.current_step += 1;
  }

  /**
   * Reduce offer step to show
   * previous product group in modal
   * @memberof OfferModel
   */
  @action reduceStep() {
    this.current_step -= 1;

    if (!this.currentGroup.choosedProduct.availableForOptions) {
      this.currentGroup.step.showProduct = false;
    }

    this.currentGroup.step.finished = false;
  }

  /**
   * Get current product group in modal
   */
  @computed get currentGroup() {
    return this.groups.find(({ step }) => step.count === this.current_step);
  }

  /**
   * Check if current group is finished
   */
  @computed get currentGroupFinished() {
    return (
      this.currentGroup.step.finished || this.currentGroup.step.showProduct
    );
  }

  /**
   * Get offer working hours array
   */
  @computed get getOpeningWeekDays() {
    return this.days;
  }

  /**
   * Check if current step (product group) is last
   * @return {string}
   * @readonly
   * @memberof OfferModel
   */
  @computed get lastStep() {
    return (
      this.currentGroup.step.count === this.allSteps &&
      this.currentGroupFinished
    );
  }

  /**
   * Check if current step (product group) is first
   * @return {bool}
   * @readonly
   * @memberof OfferModel
   */
  @computed get firstStep() {
    return this.current_step === 0;
  }

  /**
   * Get offer fixed price depending on order type
   * @return {number} offer fixed price
   * @readonly
   * @memberof OfferModel
   */
  @computed get price() {
    if (this.isFreeOffer) return 0;

    return this.root.deliveryAddressStore.isDelivery
      ? this.priceDelivery
      : this.pricePickup;
  }

  /**
   * Count and get offer total price
   * depending on following price rules (Fixed, Reduce, Percent)
   * @return {number} offer total price
   * @readonly
   * @memberof OfferModel
   */
  @computed get totalPrice() {
    // TODO: Mike - refactor this sh*t
    if (this.isFreeOffer) return this.price;

    let result = 0;

    if (this.root.deliveryAddressStore.isDelivery) {
      if (this.priceDeliveryRule === 'Fixed') {
        result =
          this.price +
          sum(
            this.groups.map((group) =>
              group.choosedProduct
                ? group.choosedProduct.totalIngredientPrice
                : 0
            )
          );
      }

      if (this.priceDeliveryRule === 'Reduce') {
        result =
          +sum(
            this.groups.map((group) =>
              group.choosedProduct
                ? group.choosedProduct.priceWithIngredients
                : 0
            )
          ) - this.price;
      }

      if (this.priceDeliveryRule === 'Percent') {
        result =
          +sum(
            this.groups.map((group) =>
              group.choosedProduct
                ? group.choosedProduct.priceWithIngredients
                : 0
            )
          ) *
          (1 - this.price / 100);
      }
    } else {
      if (this.pricePickupRule === 'Fixed') {
        result =
          this.price +
          sum(
            this.groups.map((group) =>
              group.choosedProduct
                ? group.choosedProduct.totalIngredientPrice
                : 0
            )
          );
      }

      if (this.pricePickupRule === 'Reduce') {
        result =
          +sum(
            this.groups.map((group) =>
              group.choosedProduct
                ? group.choosedProduct.priceWithIngredients
                : 0
            )
          ) - this.price;
      }

      if (this.pricePickupRule === 'Percent') {
        result =
          +sum(
            this.groups.map((group) =>
              group.choosedProduct
                ? group.choosedProduct.priceWithIngredients
                : 0
            )
          ) *
          (1 - this.price / 100);
      }
    }

    if (this.root.ingredientsSelectStore.isNegativeExtrasPricingModelExact) {
      result = Math.max(result, 0);
    } else if (
      this.root.ingredientsSelectStore.isNegativeExtrasPricingModelFair
    ) {
      result = Math.max(result, this.price);
    }

    return result;
  }

  /**
   * Detect if offer default price is fixed
   *
   * @readonly
   * @memberof OfferModel
   */
  @computed get isFixedPrice() {
    return this.root.deliveryAddressStore.isDelivery
      ? this.priceDeliveryRule === 'Fixed'
      : this.pricePickupRule === 'Fixed';
  }

  /**
   * Get formated price
   * @return {string} offer price
   * @readonly
   * @memberof OfferModel
   */
  @computed get getIntlTotlPrice() {
    return this.root.basketStore.getIntlPrice(this.totalPrice);
  }

  /**
   * Method to check if offer available for delivery discount
   * @return {boolean} available for delivery discount
   * @memberof OfferModel#
   * @method availableForDeliveryDiscount
   */
  @computed get availableForDeliveryDiscount() {
    return this.validForDiscountDelivery;
  }

  /**
   * Method to check if offer available for pickup discount
   * @return {boolean} available for pickup discount
   * @memberof OfferModel#
   * @method availableForSelfcollectDiscount
   */
  @computed get availableForSelfcollectDiscount() {
    return !!this.validForDiscountPickup;
  }

  /**
   * Check if offer available for
   * current order type discount
   * @readonly
   * @memberof OfferModel
   */
  @computed get availableForCurrentDiscount() {
    return (
      (this.root.deliveryAddressStore.isDelivery &&
        this.availableForDeliveryDiscount) ||
      (!this.root.deliveryAddressStore.isDelivery &&
        this.availableForSelfcollectDiscount)
    );
  }

  /**
   * Method to check if offer not available for both discount
   * @return {boolean}
   * @memberof OfferModel#
   * @method notAvailableForBothtDiscounts
   */
  @computed get notAvailableForBothtDiscounts() {
    return (
      !this.availableForDeliveryDiscount &&
      !this.availableForSelfcollectDiscount
    );
  }

  /**
   * Check if offer is count for mbv delivery
   * @return {boolean}
   * @readonly
   * @memberof OfferModel
   */
  @computed get isCountForMbvDelivery() {
    return this.validForDeliveryMbv;
  }

  /**
   * Check if offer is count for mbv pickup
   * @return {boolean}
   * @readonly
   * @memberof OfferModel
   */
  @computed get isCountForMbvPickup() {
    return this.validForPickupMbv;
  }

  /**
   * Method to check if offer should be counted for mbv
   * @return {boolean} mbv value
   * @memberof OfferModel#
   * @method isCountForMbw
   */
  @computed get isCountForMbw() {
    return (
      (this.root.deliveryAddressStore.isDelivery &&
        this.isCountForMbvDelivery) ||
      (!this.root.deliveryAddressStore.isDelivery && this.isCountForMbvPickup)
    );
  }

  /**
   * Method to get mbv value to make offer available for basket
   * @return {number} mbv value
   * @memberof OfferModel#
   * @method mbvValue
   */
  @computed get mbvValue() {
    return this.root.deliveryAddressStore.isDelivery
      ? this.validFromMbvDelivery
      : this.validFromMbvPickup;
  }

  /**
   * Method to check if offer depends on mbv
   * @return {boolean} mbv value
   * @memberof OfferModel#
   * @method dependsOnMbw
   */
  @computed get dependsOnMbw() {
    return this.mbvValue > 0;
  }

  /**
   * Method to check if offer is valid depending on mbv
   * @return {boolean} mbv value
   * @memberof OfferModel#
   * @method isValidForMbw
   */
  @computed get isValidForMbw() {
    return this.dependsOnMbw
      ? this.root.basketStore.orderPriceForMbvWithDiscount >= this.mbvValue
      : true;
  }

  /**
   * Method to check if offer has dependencies
   * @return {boolean} has dependencies
   * @memberof OfferModel#
   * @method hasDependencies
   */
  @computed get hasDependencies() {
    return (
      this.dependsOnMbw ||
      !this.availableForDeliveryDiscount ||
      !this.availableForSelfcollectDiscount ||
      !this.validForDelivery ||
      !this.validForPickup ||
      !this.isCountForMbw
    );
  }

  /**
   * Check if offer available for current order type
   * @return {boolean} available for order type
   * @readonly
   * @memberof OfferModel
   */
  @computed get isAvailableForOrderType() {
    return this.root.deliveryAddressStore.isDelivery
      ? this.validForDelivery
      : this.validForPickup;
  }

  /**
   * Method for getting availability state of offer
   * @returns {boolean} - true is offer available for order
   */
  @computed get isValidForOrder() {
    return !this.isSoldOut && (this.isValid || this.isValidForPreorderNow);
  }

  /**
   * Check if offer available preorder if preorder is active now
   * @return {boolean}
   * @readonly
   * @memberof OfferModel
   */
  @computed get isValidForPreorderNow() {
    const {
      preorderDays,
      currentOrderTypeHasPreorder
    } = this.root.openingHoursStore;

    return (
      this.validForOptions &&
      currentOrderTypeHasPreorder &&
      this.validForPreorder &&
      this._offerAvailabilityDays(preorderDays, this.days)
    );
  }

  /**
   * Calculating offer availability days depends (including check will it be also available today)
   * @param preorderDays - days when order can be make
   * @param offerDays - days when offer available
   * @returns {*}
   * @private
   */
  _offerAvailabilityDays = (preorderDays, offerDays) =>
    preorderDays.some((i) =>
      offerDays.find((c) => {
        // if offer availability time day is today
        if (c.dayNo === dayjs().weekday()) {
          // Check if offer availability time not ended today
          return (
            parseInt(c.dayNo) === i.weekday() &&
            dayjs(c.endT, 'HH:mm:ss').weekday(c.dayNo) > dayjs()
          );
        }

        return parseInt(c.dayNo) === i.weekday();
      })
    );

  /**
   * Check if offer available now if shop is opened
   * @return {boolean}
   * @readonly
   * @memberof OfferModel
   */
  @computed get isValid() {
    const { isClosedForNow } = this.root.openingHoursStore;

    return (
      this.validForOptions &&
      !isClosedForNow &&
      this.validNow &&
      this.id &&
      !this.isSoldOut
    );
  }

  /**
   * Check if offer available for all options (mbw, order type)
   * @return {boolean}
   * @readonly
   * @memberof OfferModel
   */
  @computed get validForOptions() {
    return this.isValidForMbw && this.isAvailableForOrderType;
  }

  /**
   * Check if offer is available always
   * @return {boolean}
   * @readonly
   * @memberof OfferModel
   */
  @computed get validEveryTime() {
    return !this.startDay && !this.endDay;
  }

  /**
   * Check if offer is free item
   * @readonly
   * @memberof OfferModel
   */
  @computed get isFreeOffer() {
    return this.offerType === OFFER_TYPES.FREE;
  }

  /**
   * Get free item products names
   * @readonly
   * @memberof OfferModel
   */
  @computed get freeItemProducts() {
    return this.products.map((product) => product.name).join(', ');
  }

  /**
   * Get free item chosen product
   * @readonly
   * @memberof OfferModel
   */
  @computed get chosenFreeItemProduct() {
    return this.products.find((i) => i.count > 0);
  }

  /**
   * Get offer uniq string by id, name
   * @return {string} uuid
   * @readonly
   * @memberof OfferModel
   */
  @computed get uniqString() {
    return (
      this.id +
      this.title +
      this.groups
        .map((group) =>
          group.id + group.title + group.choosedProduct
            ? group.choosedProduct.uniqString
            : ''
        )
        .join(', ')
    );
  }

  /**
   * Is offer sold out
   * @returns {boolean}
   */
  @computed get isSoldOut() {
    return !this.hasProducts;
  }
}
