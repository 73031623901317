import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SHELL_HOMEPAGE_BUTTONS from '../../../../../../enums/shell_homepage_buttons_type.enum';
import DefaultButtonsBar from './default-buttons-bar';
import DeliveryPickupButtons from './delivery-pickup-buttons-bar';

export default class ButtonsBar extends Component {
  static propTypes = {
    buttonsBarType: PropTypes.string
  };

  render() {
    return this.props.buttonsBarType ===
      SHELL_HOMEPAGE_BUTTONS.DELIVERY_PICKUP_BUTTONS ? (
      <DeliveryPickupButtons {...this.props} />
    ) : (
      <DefaultButtonsBar {...this.props} />
    );
  }
}
