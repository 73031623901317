/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import React from 'react';

import PICTURES_MODES from 'client/enums/product_images.enum';
import { ProductModel } from 'client/models/product.model';
import ProductListProps from 'client/themes/theme-hermes/components/products_list/common/products-list/ProductList.type';
import ProductBigPictureVariant1Loader from 'client/ui/Loaders/ProductBigPictureVariant1Loader';

import SmallLoader from '../../../loaders/small_loader';

import ProductCell from './product-cell/ProductCell';
import './ProductList.scss';

const ProductList = ({
  isShopClosed,
  picturesMode,
  products,
  onButtonClick,
  onCellClick,
  openProductModal,
  showHalalCertificate,
  activeLanguage,
  hideProductArticles,
  isUseAdvancedProductView,
  styles,
  animatedId,
  basketProducts,
  basketBounds,
  loadingAnimation,
  isMobile,
  isShell,
  additivesProducts,
  loading,
  fullProductListDesign,
  productCellStyle,
  buttonStyle,
  activeButtonStyle,
  separatorStyle,
  horizontalMenuProductStyle,
  currency,
  productsPreviews
}: ProductListProps) => {
  const renderProductCell = (product: ProductModel) => {
    const productPreview = productsPreviews?.find(
      ({ id }) => parseInt(id, 10) === product.id
    );

    return (
      <ProductCell
        key={product.id}
        isShopClosed={isShopClosed}
        product={product}
        animatedId={animatedId}
        basketProducts={basketProducts}
        hideProductArticles={hideProductArticles}
        onButtonClick={onButtonClick}
        onCellClick={onCellClick}
        activeLanguage={activeLanguage}
        isUseAdvancedProductView={isUseAdvancedProductView}
        showHalalCertificate={showHalalCertificate}
        picturesMode={picturesMode}
        styles={styles}
        basketBounds={basketBounds}
        isMobile={isMobile}
        isShell={isShell}
        additivesProducts={additivesProducts}
        productCellStyle={productCellStyle}
        buttonStyle={buttonStyle}
        activeButtonStyle={activeButtonStyle}
        separatorStyle={separatorStyle}
        horizontalMenuProductStyle={horizontalMenuProductStyle}
        fullProductListDesign={fullProductListDesign}
        currency={currency}
        productLoadingColor={productPreview?.pic_color}
        openProductModal={openProductModal}
      />
    );
  };

  if (loading) {
    return <SmallLoader loaderUrl={loadingAnimation} />;
  }

  const preloading = productsPreviews?.map(({ id, pic_color }, index) => (
    <ProductBigPictureVariant1Loader
      key={id}
      className="big-picture-variant1-container"
      backgroundColor={pic_color}
    />
  ));

  const productCards = products.map(renderProductCell);

  return (
    <div
      className={classNames({
        'products-list-container': true,
        'big-picture-variant-1':
          picturesMode === PICTURES_MODES.BIG_PICTURES_VARIANT_1,
        'big-picture-variant-2':
          picturesMode === PICTURES_MODES.BIG_PICTURES_VARIANT_2,
        'is-mobile': isMobile,
        'is-shell': isShell
      })}
    >
      {loading ? preloading : productCards}
    </div>
  );
};

export default ProductList;
