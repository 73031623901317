import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { StructuredDataRestaurant } from '../structured_data';

@withRouter
@inject('store')
@observer
class CustomerSeo extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    robots: PropTypes.string
  };

  /**
   * Method to generate keywords string by predefined list of keywords
   * @returns {string}
   * @private
   */
  _generatedKeywords = () => {
    const {
      getSeoKeywords,
      getTitle
    } = this.props.store.restaurantStore.restaurant;

    const { getCity } = this.props.store.restaurantStore.branch;

    return `${getTitle},${getCity},${getSeoKeywords.join(',')}`;
  };

  /**
   * Method to generate description by predefined list of descriptions
   * @returns {string}
   * @private
   */
  _description() {
    const {
      getSeoDescription,
      getTitle
    } = this.props.store.restaurantStore.restaurant;

    const { getCity } = this.props.store.restaurantStore.branch;

    return `${getTitle}, ${getCity} | ${getSeoDescription}`;
  }

  /**
   * Method to prepare meta tags object
   * @returns {*[]}
   * @private
   */
  _renderMetaTags() {
    const {
      logo,
      getUrlWeb,
      getTitle,
      getLang,
      predefinedPageTitle,
      predefinedPageDescription
    } = this.props.store.restaurantStore.restaurant;

    const {
      getCity,
      getStreet,
      getStreetNo,
      getCityCode,
      getCountry,
      getEmail
    } = this.props.store.restaurantStore.branch;

    const location = this.props.store.restaurantStore.hostName;
    const { title, robots } = this.props;
    const pageTitle = `${title} | ${getTitle}, ${getCity}, ${getStreet} ${getStreetNo}`;

    return [
      { itemprop: 'name', content: pageTitle },
      { itemprop: 'image', content: logo },
      {
        name: 'og:title',
        content: predefinedPageTitle || `${pageTitle}`
      },
      { name: 'og:type', content: 'restaurant.restaurant' },
      { name: 'og:url', content: `https://${location}/` },
      { name: 'og:image', content: logo },
      {
        name: 'og:description',
        content: predefinedPageDescription || this._description()
      },
      { name: 'og:site_name', content: `${getTitle}` },
      {
        name: 'restaurant:contact_info:street_address',
        content: `${getStreet} ${getStreetNo}`
      },
      { name: 'restaurant:contact_info:locality', content: getCity },
      { name: 'restaurant:contact_info:region', content: null },
      { name: 'restaurant:contact_info:postal_code', content: getCityCode },
      { name: 'restaurant:contact_info:country_name', content: getCountry },
      { name: 'restaurant:contact_info:email', content: getEmail },
      { name: 'restaurant:contact_info:website', content: getUrlWeb },
      {
        name: 'description',
        content: predefinedPageDescription || this._description()
      },
      { name: 'keywords', content: this._generatedKeywords() },
      { name: 'language', content: `${getLang}` },
      { name: 'author', content: 'app smart GmbH' },
      { name: 'robots', content: robots },
      { name: 'audience', content: 'all,alle' },
      { name: 'copyright', content: 'app smart GmbH' },
      { name: 'expires', content: 'never' },
      { name: 'page-topic', content: 'shopping' },
      { name: 'page-type', content: 'Verzeichnis, Portal' }
    ];
  }

  render() {
    const {
      logo,
      getTitle,
      currencyCode,
      getUrlWeb,
      getLang,
      predefinedPageTitle
    } = this.props.store.restaurantStore.restaurant;

    const {
      getCity,
      getStreet,
      getStreetNo,
      getCityCode,
      getCountryPrefix,
      getEmail
    } = this.props.store.restaurantStore.branch;

    const schema = StructuredDataRestaurant({
      name: getTitle,
      streetAddress: `${getStreet}, ${getStreetNo}`,
      city: getCity,
      countryCode: getCountryPrefix,
      postalCode: getCityCode,
      logo,
      website: getUrlWeb,
      email: getEmail,
      currency: currencyCode,
      language: getLang
    });

    const {
      canonicalLink,
      isGermanRestaurant
    } = this.props.store.restaurantStore;

    const { seoRestaurantTitle } = this.props.store.seoDataStore;
    const { title } = this.props;

    const commonTitle = isGermanRestaurant
      ? seoRestaurantTitle
      : `${title} | ${getTitle}`;

    return (
      <Helmet
        htmlAttributes={{
          lang: getLang
        }}
        title={predefinedPageTitle || commonTitle}
        link={[{ rel: 'canonical', href: canonicalLink }]}
        script={[schema]}
        meta={this._renderMetaTags()}
      />
    );
  }
}

CustomerSeo.defaultProps = {
  robots: 'noindex'
};

export default withTranslation(['seo'])(CustomerSeo);
