// Imports
import React, { PureComponent } from 'react';

import { IS_CLIENT } from 'config';

import SlickCarousel from '../../../../../../../components/SlickCarousel';

// Utils
import { ELEMENTS_TYPES } from '../../../../../../../enums/homepage_elements_types.enum';
import offerTypes from '../../../../../../../enums/offer_types.enum';
import SliderLinksTypes from '../../../../../../../enums/slider_links_types.enum';
import states from '../../../../../../../enums/states.enum';
import { createHomepageHrefLink } from '../../../../../../../utils/functions';
import { generateLinkFor } from '../../../../../../../utils/routing';

// Styles
import './styles.scss';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

/**
 * Homepage mobile component to render banner slider
 */
@inject('store')
@withRouter
export default class HomepageBannersDesktop extends PureComponent {
  /**
   * Method to handle slide click
   * @param slide
   */
  _onSlideClick = (slide) => {
    switch (slide.link.type) {
      case SliderLinksTypes.OFFER:
        return this.props.history.push(
          generateLinkFor(
            `${states.offer}/${offerTypes.KOMBI}/${slide.link.value}`,
            this.props,
            {},
            true
          )
        );
      case SliderLinksTypes.CATEGORY:
        return this.props.history.push(
          generateLinkFor(
            `${states.cat}/${slide.link.value}`,
            this.props,
            {},
            true
          )
        );
      case SliderLinksTypes.PRODUCT:
        this._onProductClick(slide.link.value);
      default:
        return null;
    }
  };

  /**
   * Method to add product to basket
   * @param {number} productId
   */
  _onProductClick = (productId) => {
    const { history } = this.props;
    const id = parseInt(productId, 0);

    this.props.store.productsStore.addProductById(id, history);
  };

  /**
   * Method to render slide info block
   * @param title
   * @param subtitle
   * @param style
   * @returns {*}
   * @private
   */
  _renderInfo = (title, subtitle, style) => (
    <div className="info" style={style}>
      {subtitle && (
        <div
          className="subtitle"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      <div className="title">{title}</div>
    </div>
  );

  /**
   * Method to render discount stripe
   * @param {string} title - stripe text
   * @returns {*}
   * @private
   */
  _renderDiscountStripe = (title) => (
    <div className="discount-stripe">
      <div className="text">{title}</div>
    </div>
  );

  /**
   * Method to render single slide
   * @param {SlideModel} slide - slide object
   * @param key - {number}
   * @param style
   * @returns {null}
   * @private
   */
  _renderItem = (slide, key, style) =>
    slide.picUrl ? (
      <div
        className="desktop-homepage-banner"
        key={key}
        onClick={() => this._onSlideClick(slide)}
      >
        <div className="img-wrapper">
          <img src={slide.picUrl} alt={slide.title} />
        </div>
        {slide.title && this._renderInfo(slide.title, slide.description, style)}
        {slide.discountText &&
          IS_CLIENT &&
          this._renderDiscountStripe(slide.discountText)}
      </div>
    ) : null;

  /**
   * Method to render slide with href and render simple div slide if link is empty
   * @param slide - {object} slide object
   * @param key
   * @param style
   * @returns {*}
   * @private
   */
  _renderLink = (slide, key, style) => {
    if (slide.picUrl && slide.link.value) {
      return (
        <a
          href={slide.link.value}
          target="_blank"
          rel="nofollow noreferrer"
          className="desktop-homepage-banner"
          key={key}
        >
          <div className="img-wrapper">
            <img src={slide.picUrl} alt={slide.title} />
          </div>
          {slide.title &&
            this._renderInfo(slide.title, slide.description, style)}
          {slide.discountText &&
            IS_CLIENT &&
            this._renderDiscountStripe(slide.discountText)}
        </a>
      );
    }

    return this._renderItem(slide, key, style);
  };

  /**
   * Method to render slide depends on its type
   * @param slide - {object} slide object
   * @param key
   * @returns {*}
   * @private
   */
  _renderSlide = (slide, key) => {
    const titleStyle = {
      color: `${slide.titleTextColor}`,
      background: `${slide.titleBackgroundColor}`
    };

    switch (slide.link.type) {
      case SliderLinksTypes.URL:
        return this._renderLink(slide, key, titleStyle);
      default:
        return this._renderItem(slide, key, titleStyle);
    }
  };

  render() {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      autoplaySpeed: 5000,
      autoplay: true
    };

    return (
      <section className="desktop-homepage-section desktop-banners-slider">
        <SlickCarousel settings={settings}>
          {this.props.slides.length &&
            this.props.slides.map((item, index) =>
              this._renderSlide(item, index)
            )}
        </SlickCarousel>
      </section>
    );
  }
}
