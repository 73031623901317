import { Icon, Input, Tooltip } from 'antd';
import classNames from 'classnames';
import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState
} from 'react';

import { ReactComponent as ClearFieldBoldIcon } from '../../../../assets/images/hermes_theme/clear-field-bold.svg';
import { ReactComponent as ClearFieldIcon } from '../../../../assets/images/hermes_theme/clear-field.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/images/hermes_theme/search.svg';

import { SearchInputFieldProps } from './SearchInputField.type';
import './SearchInputField.style.scss';

const SearchInputField = ({
  style = {},
  placeholder = '',
  onChange,
  onOpen,
  value = '',
  tooltipText = '',
  fullWidth = false,
  keepOpen = false,
  isMobile
}: SearchInputFieldProps) => {
  const [isInputShowed, showInput] = useState(!!value || keepOpen);
  const inputRef = useRef<Input>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      setLoading(false);

      if (value && onOpen) {
        onOpen(true);
      }
    }
  }, [loading]);

  const activeField = () => {
    showInput(true);

    onOpenComponentHandle(true);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onChangeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const onBlurInput = () => {
    if (!value) {
      showInput(keepOpen);

      onOpenComponentHandle(false);
    }
  };

  const clearInputValue = (event: MouseEvent) => {
    onChange('');

    event.preventDefault();
  };

  const onOpenComponentHandle = (openState: boolean) => {
    onOpen && onOpen(openState);
  };

  const searchIcon = (
    <SearchIcon
      className={classNames('search-input-field-icon', {
        active: isInputShowed
      })}
    />
  );

  return (
    <div
      className={classNames('search-input-field-container', {
        active: isInputShowed,
        'full-width': fullWidth
      })}
      onClick={activeField}
      style={style}
      data-testid="search-icon"
    >
      {loading ? (
        <Icon type="loading" style={{ fontSize: 14 }} spin />
      ) : (
        <>
          {tooltipText && !isInputShowed ? (
            <Tooltip title={tooltipText}>{searchIcon}</Tooltip>
          ) : (
            searchIcon
          )}
          <Input
            ref={inputRef}
            placeholder={placeholder}
            size="small"
            value={value}
            className={classNames('search-input', {
              active: isInputShowed
            })}
            onBlur={onBlurInput}
            onChange={onChangeInputValue}
            data-testid="input-search"
          />
          {!isMobile && (
            <ClearFieldIcon
              className={classNames('search-input-clear-field-icon', {
                active: isInputShowed && value
              })}
              onMouseDown={clearInputValue}
              data-testid="product-search-reset"
            />
          )}
          {isMobile && (
            <ClearFieldBoldIcon
              className={classNames(
                'search-input-clear-field-icon',
                'is-mobile',
                {
                  active: isInputShowed && value
                }
              )}
              onMouseDown={clearInputValue}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SearchInputField;
