import classNames from 'classnames';
import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import animationConstants from '../../../../../../enums/animation.enum';
import { DAYJS_FORMAT } from '../../../../../../enums/dayjs.enum';
import states from '../../../../../../enums/states.enum';
import { intlPrice } from '../../../../../../utils/functions';

// Components
import { getDayLong } from '../../../../../../utils/time';
import ButtonProductPriceAnimation from '../../../buttons/ButtonProductPriceAnimation';

@withRouter
@inject('store')
@observer
class HermesThemeOfferCell extends Component {
  static propTypes = {
    onOfferClick: PropTypes.func,
    isDeliveryPreorderChoosed: PropTypes.bool,
    isPickupPreorderChoosed: PropTypes.bool,
    offer: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    animateId: PropTypes.number.isRequired,
    offsetY: PropTypes.number,
    isShell: PropTypes.bool,
    buttonProductPriceStyle: PropTypes.object,
    separatorStyle: PropTypes.func,
    setSize: PropTypes.func
  };

  static defaultProps = {
    isDeliveryPreorderChoosed: false,
    isPickupPreorderChoosed: false,
    isShell: false,
    onOfferClick() {}
  };

  state = {
    animateButton: false
  };

  /**
   * This variable will keep setTimeout object
   */
  timer;

  containerRef = createRef();

  _seoData(offer, currency) {
    return (
      <div
        itemID={offer.id}
        itemScope
        itemType="http://schema.org/Product"
        className="hidden"
      >
        <meta itemProp="description" content={offer.description} />
        <meta itemProp="image" content={offer.img} />
        <meta itemProp="name" content={offer.title} />

        <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
          <meta itemProp="priceCurrency" content={currency} />
          <meta itemProp="price" content={offer.price} />
          <meta itemProp="availability" content="http://schema.org/InStock" />
        </div>
      </div>
    );
  }

  _availability(offer) {
    const offerPrice = intlPrice(
      offer.mbvValue,
      this.props.language,
      this.props.currency
    );

    return (
      <span className="validation-options">
        {offer.hasDependencies &&
          this.props.t('pleaseNotice', {
            notice: `${
              (offer.dependsOnMbw
                ? this.props.t('mbvAvailable', { price: offerPrice })
                : '') +
              (offer.notAvailableForBothtDiscounts
                ? this.props.t('discountNotAllowed')
                : '') +
              (!offer.notAvailableForBothtDiscounts &&
              !offer.availableForDeliveryDiscount
                ? this.props.t('deliveryDiscountNotAllowed')
                : '') +
              (!offer.notAvailableForBothtDiscounts &&
              !offer.availableForSelfcollectDiscount
                ? this.props.t('selfcollectDiscountNotAllowed')
                : '') +
              (!offer.isCountForMbw ? this.props.t('notCountForMbv') : '')
            }`
          })}
      </span>
    );
  }

  _workingHoursFormatter(index, sameDays, count, showTime, day, offer) {
    return (
      <span key={index}>
        {sameDays.map((day, index) => {
          if (day.show) {
            count = 0;

            return (
              <span key={day.dayNo}>
                {getDayLong(day.dayNo) +
                  (index === sameDays.length - 1
                    ? showTime
                      ? ': '
                      : '; '
                    : sameDays[index + 1].show
                    ? ', '
                    : '')}
              </span>
            );
          }

          const result = count === 0 ? ' - ' : '';

          count += 1;

          return result;
        })}
        {showTime &&
          `${this.props.t('opening_hours:workingHours', {
            start: dayjs(day.startT, DAYJS_FORMAT.timeFormat),
            end: dayjs(day.endT, DAYJS_FORMAT.timeFormat)
          })}${index !== offer.parsedDays.length - 1 ? ', ' : ''}`}
      </span>
    );
  }

  _validationTime(offer) {
    return (
      <div className="validation-time">
        {!offer.validEveryTime &&
          !offer.startDay &&
          `${this.props.t('availableUntil', { date: offer.endDay })}`}
        {!offer.validEveryTime &&
          !offer.endDay &&
          `${this.props.t('availableFrom', { date: offer.startDay })}`}
        {!offer.validEveryTime &&
          offer.startDay &&
          offer.endDay &&
          this.props.t('availableFrom', {
            dateFrom: offer.startDay,
            dateTo: offer.endDay
          })}
        {offer.parsedDays.length > 0 && `${this.props.t('every')} `}
        {offer.parsedDays.length > 0 &&
          offer.parsedDays.map((sameDays, index) => {
            const count = 0;

            let showTime = true;

            const day = sameDays[0];

            if (!day.startT && !day.endT) {
              showTime = false;
            }

            return this._workingHoursFormatter(
              index,
              sameDays,
              count,
              showTime,
              day,
              offer
            );
          })}
      </div>
    );
  }

  _deliveryInfo(offer, currentOrderTypeHasPreorder) {
    const showIfNotValid = !offer.isValidForOrder;

    const showPreorderValidation =
      !offer.isValid &&
      offer.isValidForPreorderNow &&
      currentOrderTypeHasPreorder;

    return (
      <div
        className={classNames({
          valid: offer.isValid,
          preorder: showPreorderValidation,
          'not-valid': showIfNotValid
        })}
      >
        {offer.isValid && this.props.t('nowAvailable')}
        {showPreorderValidation && this.props.t('availableForPreorder')}
        {showIfNotValid && this.props.t('common:productNotAvailableFull')}
      </div>
    );
  }

  getBasketItemCoordinates = (mobileView) =>
    mobileView
      ? this.props.store.basketStore.bounds[states.basket][states.basket]
      : this.props.store.basketStore.bounds[states.offer][
          this.props.store.basketStore.uniqStringLastAddedOffer
        ];

  toAnimateButton = () => {
    this.setState(
      {
        animateButton: true
      },
      () => {
        this.timer = setTimeout(() => {
          this.setState({
            animateButton: false
          });
        }, animationConstants.time.buttonToBuyProduct);
      }
    );
  };

  componentDidMount() {
    if (this.containerRef.current && this.props.setSize) {
      const { marginBottom, marginTop } = getComputedStyle(
        this.containerRef.current
      );

      this.props.setSize(
        this.containerRef.current.getBoundingClientRect().height +
          parseFloat(marginBottom) +
          parseFloat(marginTop)
      );
    }

    if (
      !this.state.animateButton &&
      this.props.animateId === this.props.offer.id
    ) {
      this.toAnimateButton();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.state.animateButton &&
      prevProps.animateId !== this.props.animateId &&
      this.props.animateId === this.props.offer.id
    ) {
      this.toAnimateButton();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const {
      onOfferClick,
      offer,
      currency,
      isShell,
      currentOrderTypeHasPreorder,
      store,
      t,
      buttonProductPriceStyle
    } = this.props;

    const { animateButton } = this.state;
    const { themesStore, restaurantStore, basketStore, productsStore } = store;
    const { isShopClosed } = restaurantStore;
    const { isSearchEnabled } = productsStore;
    const isOfferDisabled = !offer.isValidForOrder;
    const mobileView = themesStore.isMobile || this.props.isShell;

    const offerPriceLabel =
      offer.totalPrice <= 0
        ? t('common:select')
        : t('common:cmnPrice', { price: offer.getIntlTotlPrice });

    const basketItemCoordinates = animateButton
      ? this.getBasketItemCoordinates(mobileView)
      : null;

    const offerCount =
      basketStore.offers
        .filter((offerItem) => offerItem.offer.id === offer.id)
        .reduce((count, offerItem) => count + offerItem.count, 0) || null;

    return (
      <div
        key={offer.id}
        ref={this.containerRef}
        className={classNames('product-wrapper', 'offer', {
          'offer-active': !isOfferDisabled,
          shell: isShell,
          disabled: isOfferDisabled
        })}
        onClick={(event) => {
          event.stopPropagation();

          this.props.onOfferClick(offer);
        }}
        style={
          this.props.separatorStyle ? this.props.separatorStyle() : undefined
        }
      >
        <div className="offer-img">
          {offer.img && (
            <img
              title={isShell ? offer.inappTitle : offer.title}
              alt={isShell ? offer.inappTitle : offer.title}
              src={isShell ? offer.inappImg : offer.img}
            />
          )}
        </div>

        <div className="product-description-wrapper">
          <div className="title">
            {' '}
            {isShell ? offer.inappTitle : offer.title}{' '}
          </div>
          <div className="description">
            {isShell ? offer.inappDescription : offer.description}
          </div>
          {this._deliveryInfo(offer, currentOrderTypeHasPreorder)}
          {this._validationTime(offer)}
          {this._availability(offer)}
        </div>

        <ButtonProductPriceAnimation
          animated={animateButton && !!basketItemCoordinates}
          animation={{
            type: 'blobToBasket',
            basketCoordinates: basketItemCoordinates,
            mobileView,
            offsetY: this.props.offsetY
          }}
          disabled={isOfferDisabled}
          isShell={isShell}
          itemCount={offerCount}
          style={
            offerCount
              ? themesStore.orderButtonStyle()
              : buttonProductPriceStyle
          }
          isSoldOut={offer.isSoldOut}
          isClosed={isShopClosed}
        >
          {isOfferDisabled ? t('common:productNotAvailable') : offerPriceLabel}
        </ButtonProductPriceAnimation>

        {this._seoData(offer, currency)}
      </div>
    );
  }
}

export default withTranslation(['offers', 'common'])(HermesThemeOfferCell);
