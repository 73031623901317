import { Form, Input, Select as SelectAntD } from 'antd';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';

import ShippingFormsController from 'client/components/ShippingFormsController';
import openModal from 'client/utils/openModal';
import { IS_CLIENT, isProduction } from 'config';

import GoogleOptimize from '../../../../../components/GoogleOptimize';
import images from '../../../../../enums/images_enums/hermes_images.enum';
import { ORDER_FIELDS } from '../../../../../enums/order_fields.enum';
import states from '../../../../../enums/states.enum';
import { ModalsRouteType } from '../../../../../routes/ModalsRoute/ModalsRoute.type';
import { generateLinkFor } from '../../../../../utils/routing';
import REGEX_PATTERNS from '../../../../../utils/validation';
import FooterShellButton from '../../../theme-hermes-shell/components/buttons-shell/footer-button';
import ButtonOrder from '../../buttons/ButtonOrder';
import ButtonStandard from '../../buttons/ButtonStandard';
import ContactlessDeliveryComponent from '../../checkout/common/contactless-delivery/ContactlessDeliveryComponent';
import AdditionalFields from '../additional_fields';

import './_address-form.scss';
import { getAutocompleteStyles, getSelectStyles } from './select-styles';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

/**
 * Component to render address form in basket
 */
@withRouter
@Form.create()
@inject('store')
@observer
class AddressForm extends Component {
  static propTypes = {
    onSubmitClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    isDisableChangingAddress: PropTypes.bool
  };

  static defaultProps = {
    isDisableChangingAddress: false
  };

  constructor(props) {
    super(props);

    this.state = {
      sessionToken: null,
      street: '',
      streetNumber: '',
      city: '',
      zip: '',
      googlePlaceId: '',
      isHouseAddress: false,
      error: null,
      addressInputValue: '',
      selectedAddress: null,
      modalShowed: false,
      searchExecuted: false,
      isLoading: false
    };

    this._handleChangeStreet = debounce(this._handleChangeStreet, 400);

    this._getPlacesPredictions = debounce(this._getPlacesPredictions, 400);

    this.validateAndScrollField = debounce(this.validateAndScrollField, 300);

    reaction(
      () => this.props.store.deliveryAddressStore.isDelivery,
      () => {
        if (this.props.form.getFieldValue('zip') === '') {
          this.props.form.setFieldsValue({
            zip: undefined
          });

          this.props.form.validateFields(['zip']);
        }

        const { getCurrentLocation } = this.props.store.restaurantStore;

        if (
          this.props.store.deliveryAddressStore.isDelivery &&
          !this.props.store.restaurantStore.useCalculationTypeByDeliveryArea
        ) {
          this.props.form.setFieldsValue({
            zip: getCurrentLocation?.zip,
            city: getCurrentLocation?.sublocality
          });

          if (this.props.store.restaurantStore.branch.isAutocomplete) {
            this.props.form.setFieldsValue({
              street: undefined,
              street_no: undefined
            });
          }
        }
      }
    );

    reaction(
      () => this.props.store.deliveryAddressStore.isShowConfirmChangeZip,
      () => {
        if (
          this.props.store.deliveryAddressStore.isDelivery &&
          !this.props.store.restaurantStore.useCalculationTypeByDeliveryArea &&
          this.props.store.deliveryAddressStore.zipToChange === '' &&
          !this.props.store.deliveryAddressStore.isShowConfirmChangeZip
        ) {
          this.props.form.setFieldsValue({
            zip: this.props.store.restaurantStore.getCurrentLocation?.zip
          });
        }
      }
    );
  }

  componentDidMount() {
    this._setSessionToken();

    const {
      useCalculationTypeByDeliveryArea
    } = this.props.store.restaurantStore;

    const { isDelivery } = this.props.store.deliveryAddressStore;

    if (isDelivery && useCalculationTypeByDeliveryArea) {
      const { address } = this.props.store.deliveryAddressStore;

      this.setState({
        googlePlaceId: address.address_id,
        isHouseAddress: true,
        street: address.street,
        streetNumber: address.street_no,
        city: address.city,
        zip: address.zip,
        addressInputValue: this._getFormattedAddress(
          address.street,
          address.street_no
        )
      });
    }

    const streetElement = IS_CLIENT && document.querySelectorAll('#street');

    if (streetElement && streetElement.length) {
      streetElement.forEach((el) => {
        el.tagName === 'INPUT' && el.setAttribute('autocomplete', 'chrome-off');
      });
    }
  }

  componentDidUpdate() {
    this._setSessionToken();
  }

  /**
   * Method for update google places session token
   */
  _setSessionToken = () => {
    if (
      IS_CLIENT &&
      this.props.store.thirdPartyServicesStore.googlePlacesIsReady &&
      !this.state.sessionToken &&
      window.google &&
      window.google.maps
    ) {
      const sessionToken = new window.google.maps.places.AutocompleteSessionToken();

      this.setState({
        sessionToken
      });
    }
  };

  /**
   * onKeyPress callback for restriction a first whitespace in fields
   */
  onKeyPress = (e, fieldName) => {
    const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);

    if (fieldName === 'email') {
      if (pressedKey === ' ') {
        e.preventDefault();

        return false;
      }
    } else if (!e.target.selectionStart && pressedKey === ' ') {
      e.preventDefault();

      return false;
    }
  };

  /**
   * Method to get initial value from store for google autocomplete field
   */
  getInitialValueForAddressAutocomplete = () => {
    const { address } = this.props.store.deliveryAddressStore;

    return this.state.searchExecuted
      ? null
      : {
          label: this._getFormattedAddress(address.street, address.street_no),
          isHouseAddress: true,
          value: address.address_id
        };
  };

  validateAndScrollField = (fieldKey) => {
    this.props.form.validateFieldsAndScroll([fieldKey], {
      scroll: { alignWithTop: false, offsetBottom: 30 }
    });
  };

  /**
   * Method to render zip delivery dropdown
   * @returns {*}
   * @private
   */
  _renderZipDelivery() {
    return (
      <Select
        className="change-zip"
        value={this.props.store.restaurantStore.getCurrentLocation}
        placeholder={this.props.t('zip')}
        options={this.props.store.restaurantStore.branch.parsedAreaCodes}
        onChange={this._handleChangeZip}
        onMenuOpen={this._handleZipOpen}
        onMenuClose={this._handleZipClose}
        onFocus={this._checkChangingStreetAddress}
        styles={getSelectStyles()}
        data-testid="zip-select-basket"
      />
    );
  }

  /**
   * Method to render zip delivery dropdown shell
   * @returns {*}
   * @private
   */
  _renderZipDeliveryShell() {
    const { getFieldDecorator } = this.props.form;
    const fieldName = 'zip';
    const currentLocation = this.props.store.restaurantStore.getCurrentLocation;

    return (
      <Form.Item wrapperCol={{ span: 24 }}>
        {getFieldDecorator(fieldName, {
          initialValue: currentLocation?.zip,
          rules: [
            {
              required: true,
              message: this.props.t('enterYourPostalCode')
            }
          ]
        })(
          <SelectAntD
            placeholder={this.props.t('zip')}
            onChange={this._prepareChangeZip}
            onFocus={this._checkChangingStreetAddress}
            disabled={this.props.isDisableChangingAddress}
            data-testid="zip-select-basket"
          >
            {this._renderZipDeliveryOptions(
              this.props.store.restaurantStore.branch.parsedAreaCodes
            )}
          </SelectAntD>
        )}
      </Form.Item>
    );
  }

  /**
   * Method to render shell delivery options
   * @param areaCodes - array of areas objects
   * @returns {*}
   * @private
   */
  _renderZipDeliveryOptions = (areaCodes) =>
    areaCodes.map((i) => (
      <SelectAntD.Option key={i.value} data-testid="zip-in-list-basket">
        {i.label}
      </SelectAntD.Option>
    ));

  /**
   * Method to render zip pickup dropdown
   * @returns {*}
   * @private
   */
  _renderZipPickup() {
    const { t, isDisableChangingAddress, form, store } = this.props;
    const { deliveryAddressStore, restaurantStore } = store;
    const fieldName = 'zip';

    return (
      <Form.Item wrapperCol={{ span: 24 }}>
        {form.getFieldDecorator(fieldName, {
          initialValue: deliveryAddressStore.address.zip,
          rules: [
            {
              required: true,
              message: t('enterYourPostalCode'),
              pattern: /^[0-9-]{4,6}$/gim
            }
          ],
          validateTrigger: null
        })(
          <Input
            placeholder={t('zip')}
            autoComplete="chrome-off"
            onKeyPress={(e) => this.onKeyPress(e, fieldName)}
            disabled={isDisableChangingAddress}
            onChange={() => this.validateAndScrollField(fieldName)}
            inputMode={restaurantStore.isGermanRestaurant ? 'tel' : 'text'}
            data-testid="zip-input-basket"
          />
        )}
      </Form.Item>
    );
  }

  /**
   * Method to render city field
   * @param {boolean} isDelivery - true is delivery type active
   * @returns {*}
   * @private
   */
  _renderCityField = (isDelivery) => {
    const { deliveryAddressStore } = this.props.store;
    const { getFieldDecorator } = this.props.form;
    const fieldName = 'city';

    return (
      <Form.Item wrapperCol={{ span: 24 }}>
        {getFieldDecorator(fieldName, {
          initialValue: this.state.city || deliveryAddressStore.address.city,
          rules: [
            {
              required: true,
              message: this.props.t('enterPleaseCity'),
              pattern: REGEX_PATTERNS.cityName
            }
          ],
          validateTrigger: null
        })(
          <Input
            placeholder={this.props.t('city')}
            disabled={isDelivery || this.props.isDisableChangingAddress}
            autoComplete="chrome-off"
            onKeyPress={(e) => this.onKeyPress(e, fieldName)}
            onChange={() => this.validateAndScrollField(fieldName)}
            data-testid="city-input-basket"
          />
        )}
      </Form.Item>
    );
  };

  /**
   * Method to render fist name field
   * @param getFieldDecorator - field decorator
   * @param {string} firstName - fist name value from store
   * @returns {*}
   * @private
   */
  _renderFirstNameField = (getFieldDecorator, firstName) => {
    const fieldName = 'first_name';

    return (
      <Form.Item wrapperCol={{ span: 24 }}>
        {getFieldDecorator(fieldName, {
          initialValue: firstName,
          rules: [
            {
              required: true,
              message: this.props.t('enterPleaseFirstname'),
              pattern: REGEX_PATTERNS.personName
            }
          ],
          validateTrigger: null
        })(
          <Input
            placeholder={this.props.t('firstName')}
            autoComplete="chrome-off"
            onFocus={this._checkChangingStreetAddress}
            onKeyPress={(e) => this.onKeyPress(e, fieldName)}
            onChange={() => this.validateAndScrollField(fieldName)}
            data-testid="name-input-basket"
          />
        )}
      </Form.Item>
    );
  };

  /**
   * Method to render last name field
   * @param getFieldDecorator - field decorator
   * @param {string} lastName - last name value from store
   * @returns {*}
   * @private
   */
  _renderLastNameField = (getFieldDecorator, lastName) => {
    const fieldName = 'last_name';

    return (
      <Form.Item wrapperCol={{ span: 24 }}>
        {getFieldDecorator(fieldName, {
          initialValue: lastName,
          rules: [
            {
              required: true,
              message: this.props.t('enterPleaseLastname'),
              pattern: REGEX_PATTERNS.personName
            }
          ],
          validateTrigger: null
        })(
          <Input
            placeholder={this.props.t('lastName')}
            autoComplete="chrome-off"
            onFocus={this._checkChangingStreetAddress}
            onKeyPress={(e) => this.onKeyPress(e, fieldName)}
            onChange={() => this.validateAndScrollField(fieldName)}
            data-testid="sec-name-input-basket"
          />
        )}
      </Form.Item>
    );
  };

  /**
   * Method to render street field
   * @param getFieldDecorator - field decorator
   * @param {string} street - street value from store
   * @returns {*}
   * @private
   */
  _renderStreetField = (getFieldDecorator, street, isAddressAutocomplete) => {
    const fieldName = 'street';
    const { isDisableChangingAddress } = this.props;

    // If it's hunger app and autocomplete of street is enabled, we must fill the street field and disable it
    return (
      <Form.Item wrapperCol={{ span: 24 }}>
        {getFieldDecorator(fieldName, {
          initialValue:
            isAddressAutocomplete && !isDisableChangingAddress
              ? undefined
              : street || undefined,
          rules: [
            {
              required: true,
              message: this.props.t('enterPleaseStreet'),
              pattern: REGEX_PATTERNS.streetName
            }
          ],
          validateTrigger: isAddressAutocomplete ? 'onChange' : null
        })(
          isAddressAutocomplete ? (
            <SelectAntD
              showSearch
              placeholder={this.props.t('street')}
              notFoundContent={this.props.t('errors:noResults')}
              showArrow={false}
              onSearch={this._handleChangeStreet}
              filterOption={false}
              dropdownRender={(dropdownMenu) => (
                <div className="google-places-autocomplete-dropdown-menu-container">
                  {dropdownMenu}
                  <img
                    src={images.PBG}
                    alt="Powered by Google"
                    className="google-places-autocomplete-dropdown-menu-google-logo"
                  />
                </div>
              )}
              disabled={isDisableChangingAddress}
              data-testid="street-input-basket"
            >
              {this.props.store.thirdPartyServicesStore.placesPredictions.map(
                ({ terms }) => {
                  const [term] = terms;

                  return (
                    <SelectAntD.Option key={term.value}>
                      {term.value}
                    </SelectAntD.Option>
                  );
                }
              )}
            </SelectAntD>
          ) : (
            <Input
              placeholder={this.props.t('street')}
              autoComplete="chrome-off"
              onKeyPress={(e) => this.onKeyPress(e, fieldName)}
              disabled={isDisableChangingAddress}
              onChange={() => this.validateAndScrollField(fieldName)}
              data-testid="street-input-basket"
            />
          )
        )}
      </Form.Item>
    );
  };

  /**
   * Method to render street number field
   * @param getFieldDecorator - field decorator
   * @param {string} streetNumber - street value from store
   * @returns {*}
   * @private
   */
  _renderStreetNumberField = (
    getFieldDecorator,
    streetNumber,
    isAddressAutocomplete
  ) => {
    const fieldName = 'street_no';
    const { isDisableChangingAddress } = this.props;

    // If it's hunger app and autocomplete of street is enabled, we must fill the street number field and disable it
    return (
      <Form.Item wrapperCol={{ span: 24 }}>
        {getFieldDecorator(fieldName, {
          initialValue:
            isAddressAutocomplete && !isDisableChangingAddress
              ? undefined
              : streetNumber || undefined,
          rules: [
            {
              required: true,
              message: this.props.t('enterPleaseStreetnumber'),
              pattern: REGEX_PATTERNS.streetNumber
            }
          ],
          validateTrigger: null
        })(
          <Input
            placeholder={this.props.t('number')}
            autoComplete="chrome-off"
            onKeyPress={(e) => this.onKeyPress(e, fieldName)}
            disabled={isDisableChangingAddress}
            onChange={() => this.validateAndScrollField(fieldName)}
            data-testid="house-input-basket"
          />
        )}
      </Form.Item>
    );
  };

  /**
   * Method to render autocomplete field for street and street number
   * @returns {*}
   * @private
   */
  _renderStreetAddress = () => {
    const options = this.props.store.thirdPartyServicesStore.placesPredictions.map(
      (prediction) => ({
        label: prediction.value,
        value: prediction
      })
    );

    const selectAddressValue = this.state.addressInputValue
      ? {
          label: this.state.addressInputValue,
          value: this.state.googlePlaceId,
          isHouseAddress: this.state.isHouseAddress
        }
      : this.getInitialValueForAddressAutocomplete();

    const noResultsState =
      this.state.addressField &&
      this.state.searchExecuted &&
      this.props.store.thirdPartyServicesStore.noPlacesResultsFound;

    return (
      <Form.Item
        wrapperCol={{ span: 24 }}
        validateStatus={this.state.error ? 'error' : ''}
        help={this.state.error || ''}
        className="address-autocomplete-wrapper"
      >
        <Select
          className="address-autocomplete"
          classNamePrefix="address-autocomplete"
          id="street"
          data-testid="google-select-basket"
          isSearchable
          placeholder={`${this.props.t('street')}, ${this.props.t('number')}`}
          noOptionsMessage={() => (
            <div className="google-places-autocomplete-dropdown-menu-container">
              <div className="google-places-autocomplete-dropdown-menu-cell">
                {noResultsState
                  ? this.props.t('address_form:noAddressFound')
                  : this.props.t('address_form:enterPleaseStreetAddress')}
              </div>
            </div>
          )}
          isClearable={false}
          onInputChange={this._handleChangeStreetAddress}
          onChange={this._handleSelectStreetAddress}
          filterOption={() => true}
          isDisabled={
            !this.props.store.deliveryAddressStore.isDelivery ||
            this.props.isDisableChangingAddress
          }
          isLoading={this.state.isLoading}
          components={{
            MenuList: ({ children }) => (
              <div className="Select-menu-outer google-places-autocomplete-dropdown-menu-container">
                <div className="Select-menu" role="listbox">
                  {children}
                </div>
                <img
                  src={images.PBG}
                  alt="Powered by Google"
                  className="google-places-autocomplete-dropdown-menu-google-logo"
                />
              </div>
            )
          }}
          options={options}
          inputValue={this.state.addressInputValue}
          value={selectAddressValue}
          styles={getAutocompleteStyles()}
        />
      </Form.Item>
    );
  };

  /**
   * Method to render email field
   * @param getFieldDecorator - field decorator
   * @param {string} email - email value from store
   * @returns {*}
   * @private
   */
  _renderEmailField = (getFieldDecorator, email) => {
    const fieldName = 'email';

    return (
      <Form.Item wrapperCol={{ span: 24 }}>
        {getFieldDecorator(fieldName, {
          initialValue: email,
          rules: [
            {
              required: true,
              whitespace: true,
              pattern: REGEX_PATTERNS.emailAddress,
              message: this.props.t('enterPleaseEmail')
            }
          ],
          validateTrigger: null
        })(
          <Input
            placeholder={this.props.t('emailAddress')}
            autoComplete="chrome-off"
            onFocus={this._checkChangingStreetAddress}
            onKeyPress={(e) => this.onKeyPress(e, fieldName)}
            onPaste={(event) => {
              event.preventDefault();

              const data = event.clipboardData.getData('text');
              const value = data.trim();

              this.props.form.setFieldsValue({ [fieldName]: value });
            }}
            onChange={() => this.validateAndScrollField(fieldName)}
            data-testid="email-input-basket"
          />
        )}
      </Form.Item>
    );
  };

  /**
   * Method to render phone field
   * @param getFieldDecorator - field decorator
   * @param {string} phone - phone value from store
   * @returns {*}
   * @private
   */
  _renderPhoneField = (getFieldDecorator, phone) => {
    const fieldName = 'phone';
    const { themesStore } = this.props.store;

    return (
      <Form.Item wrapperCol={{ span: 24 }}>
        {getFieldDecorator(fieldName, {
          initialValue: phone,
          rules: [
            {
              required: true,
              pattern: REGEX_PATTERNS.phoneNumber,
              message: this.props.t('enterPleasePhone')
            }
          ],
          validateTrigger: null
        })(
          <Input
            placeholder={this.props.t('phone')}
            onFocus={this._checkChangingStreetAddress}
            onKeyPress={(e) => this.onKeyPress(e, fieldName)}
            onChange={() => this.validateAndScrollField(fieldName)}
            inputMode={themesStore.isMobile ? 'tel' : 'text'}
            data-testid="phone-input-basket"
          />
        )}
      </Form.Item>
    );
  };

  /**
   * Method to render apartments number field
   * @param getFieldDecorator - field decorator
   * @param {string} comment - comment value from store
   * @returns {*}
   * @private
   */
  _renderCommentField = (getFieldDecorator, comment) => {
    const fieldName = 'message';

    return (
      <Form.Item wrapperCol={{ span: 24 }}>
        {getFieldDecorator(fieldName, {
          initialValue: comment
        })(
          <Input.TextArea
            autoSize={{ minRows: 3 }}
            maxLength={255}
            placeholder={`${this.props.t('massage')} (${this.props.t(
              'common:optional'
            )})`}
            onFocus={this._checkChangingStreetAddress}
            onKeyPress={(e) => this.onKeyPress(e, fieldName)}
            data-testid="comment-input-basket"
          />
        )}
      </Form.Item>
    );
  };

  /**
   * Method to render desktop/mobile footer buttons
   * @param getFieldsError
   * @returns {*}
   * @private
   */
  renderFooterButtons = (getFieldsError) => (
    <>
      <div className="btn-address-success desktop-only">
        <ButtonOrder
          label={this.props.t('checkout')}
          disabled={hasErrors(getFieldsError())}
          type="submit"
          dataTestId="basket-order-btn"
        />
      </div>
      <div className="tablet-and-phone">
        <ButtonOrder
          label={`${this.props.t('checkout')} (2/3)`}
          disabled={hasErrors(getFieldsError())}
          type="submit"
          dataTestId="basket-order-btn"
        />
      </div>
      <div className="btn-address-cancel">
        <ButtonStandard
          onClick={this.props.onCancelClick}
          label={this.props.t('common:cmnBack')}
          dataTestId="back-btn-basket"
        />
      </div>
    </>
  );

  /**
   * Method to render company field
   * @param getFieldDecorator - field decorator
   * @param {string} company - company value from store
   * @returns {*}
   * @private
   */
  _renderCompanyField = (getFieldDecorator, company) => {
    const fieldName = 'company';

    return (
      <Form.Item wrapperCol={{ span: 24 }}>
        {getFieldDecorator(fieldName, {
          initialValue: company
        })(
          <Input
            placeholder={`${this.props.t('company')} (${this.props.t(
              'common:optional'
            )})`}
            autoComplete="chrome-off"
            onFocus={this._checkChangingStreetAddress}
            onKeyPress={(e) => this.onKeyPress(e, fieldName)}
            data-testid="firm-input-basket"
          />
        )}
      </Form.Item>
    );
  };

  /**
   * Method to render shell footer buttons
   * @param getFieldsError
   * @returns {*}
   * @private
   */
  renderShellFooterButton = (getFieldsError) => (
    <div className="footer-buttons-wrapper">
      <FooterShellButton
        label={`${this.props.t('checkout')} (2/3)`}
        colored
        smallText
        disabled={hasErrors(getFieldsError())}
        type="submit"
        buttonOrderStyle={this.props.store.themesStore.orderButtonStyle()}
        buttonStandardStyle={this.props.store.themesStore.standardButtonStyle()}
        buttonDisabledStyle={this.props.store.themesStore.standardButtonDisabledStyle()}
        dataTestId="basket-order-btn"
      />
      <FooterShellButton
        label={this.props.t('common:cmnBack')}
        colored={false}
        smallText
        action={this.props.onCancelClick}
        buttonOrderStyle={this.props.store.themesStore.standardButtonStyle()}
        buttonStandardStyle={this.props.store.themesStore.standardButtonStyle()}
        buttonDisabledStyle={this.props.store.themesStore.standardButtonDisabledStyle()}
        dataTestId="back-btn-basket"
      />
    </div>
  );

  /**
   * Method to render field to choosing area
   * @param {boolean} isShellThemeActive - is shell
   * @returns {*}
   * @private
   */
  _renderZipChoosing = (isShellThemeActive) =>
    isShellThemeActive
      ? this._renderZipDeliveryShell()
      : this._renderZipDelivery();

  /**
   * Method to handle submit address form
   * @param e
   * @private
   */
  handleSubmit = (e) => {
    e.preventDefault();

    const {
      isDelivery,
      getOptionalFieldsInAddressForm,
      address
    } = this.props.store.deliveryAddressStore;

    const { areaCode } = this.props.store.restaurantStore;

    if (!this._checkChangingStreetAddress()) {
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err && !this.state.error) {
          const {
            useCalculationTypeByDeliveryArea
          } = this.props.store.restaurantStore;

          this._changeFirstName(values.first_name);

          this._changeLastName(values.last_name);

          (!useCalculationTypeByDeliveryArea || !isDelivery) &&
            this._changeStreet(values.street);

          (!useCalculationTypeByDeliveryArea || !isDelivery) &&
            this._changeStreetNo(values.street_no);

          this._changeCompany(values.company);

          this._changeEmail(values.email);

          this._changePhone(values.phone);

          this._changeMessage(values.message);

          this._changeCity(values.city);

          if (isDelivery && !values.zip) {
            if (useCalculationTypeByDeliveryArea) {
              this.props.store.deliveryAddressStore.updateAddressField(
                ORDER_FIELDS.zip,
                address.zip
              );
            } else {
              this.props.store.deliveryAddressStore.updateAddressField(
                ORDER_FIELDS.zip,
                areaCode
              );
            }
          } else {
            this._changeZip(
              this.props.store.deliveryAddressStore.zipToChange || values.zip
            );
          }

          // Save additional fields values
          getOptionalFieldsInAddressForm.forEach((field) => {
            this.props.store.deliveryAddressStore.updateAddressField(
              field,
              values[field]
            );
          });

          this.props.onSubmitClick();
        }
      });
    }
  };

  /**
   * Method to handle changing of current zip code
   * @param zip - current zip code
   * @private
   */
  _handleChangeZip = (zip) => {
    const isAddressAutocomplete = !!this.props.store.restaurantStore.branch
      .isAutocomplete;

    if (
      isAddressAutocomplete &&
      this.props.store.deliveryAddressStore.address.zip?.toString() !== zip.zip
    ) {
      this.props.form.setFieldsValue({
        street: undefined,
        street_no: undefined
      });

      this.props.store.thirdPartyServicesStore.clearPlacesPredictions();
    }

    this.props.store.deliveryAddressStore.saveZipToChange(zip.zip);

    this.props.store.deliveryAddressStore.saveCityToChange(zip.sublocality);

    if (this.props.store.restaurantStore.isLocationChosen) {
      if (!this.props.store.restaurantStore.currentAreaEqualsChangedArea) {
        this.props.store.deliveryAddressStore.showChangingZipConfirmationModal(
          true
        );

        const changeZipUrl = openModal(ModalsRouteType.CHANGE_ADDRESS_MODAL);

        this.props.history.push(changeZipUrl);
      } else {
        this.props.store.deliveryAddressStore.handleChangeZip(zip);
      }
    } else {
      this.props.store.deliveryAddressStore.handleChangeZip(zip);
    }
  };

  /**
   * Method to handle fetching street address
   */
  _handleChangeStreet = (value) => {
    this.props.store.thirdPartyServicesStore.getPlacesByPostalCode(value);
  };

  /**
   * Method to handle fetching street address
   */
  _handleChangeStreetAddress = (value, meta) => {
    if (meta.action === 'input-change') {
      this.setState((prevState) => ({
        addressInputValue: value,
        selectedAddress: null,
        isHouseAddress: false,
        googlePlaceId: '',
        searchExecuted: true,
        error: value
          ? prevState.error
          : this.props.t('address_form:enterPleaseStreetAddress')
      }));

      !value &&
        this.props.store.thirdPartyServicesStore.clearPlacesPredictions();

      value.length >=
        this.props.store.deliveryAddressStore.COUNT_SYMBOLS_TO_SEARCH_ADDRESS &&
        this._getPlacesPredictions(value);
    }
  };

  /**
   * Function-wrapper of request for google places
   */
  _getPlacesPredictions = (value) => {
    this.props.store.thirdPartyServicesStore.getPlacesByText(value);
  };

  /**
   * Method to handle selecting street number in form for advanced calc type
   */
  _handleSelectStreetAddress = (address) => {
    const {
      useCalculationTypeByDeliveryArea
    } = this.props.store.restaurantStore;

    if (useCalculationTypeByDeliveryArea) {
      const inputValue = address
        ? `${address.label}${address.value.isHouseAddress ? '' : ' '}`
        : '';

      if (!address) {
        this.setState({
          error: this.props.t('address_form:enterPleaseStreetAddress')
        });
      } else {
        this.setState({
          addressInputValue: inputValue,
          selectedAddress: address,
          error: address.value.isHouseAddress
            ? null
            : this.props.t('address_form:enterPleaseStreetnumber'),
          isLoading: address.value.isHouseAddress
        });

        this.props.store.thirdPartyServicesStore.clearPlacesPredictions();

        this.props.store.thirdPartyServicesStore.checkDeliveryAvailabilityByBranch(
          address.value,
          false,
          (success, placeInfo) => {
            if (success) {
              this.props.store.deliveryAddressStore.changeAddressId(
                address.value.placeId
              );

              this.setState({
                street: placeInfo.streetName,
                streetNumber: placeInfo.streetNumber,
                city: placeInfo.city,
                zip: placeInfo.postalCode,
                addressInputValue: this._getFormattedAddress(
                  placeInfo.streetName,
                  placeInfo.streetNumber
                ),
                error: null,
                isLoading: false
              });
            } else {
              this.setState({
                error: this.props.t(
                  'delivery_info:addressIsOutsideDeliveryArea'
                ),
                isLoading: false
              });
            }
          }
        );
      }
    }
  };

  /**
   * Method to check changing street and/or street number for advanced calc type
   */
  _checkChangingStreetAddress = () => {
    const {
      useCalculationTypeByDeliveryArea
    } = this.props.store.restaurantStore;

    const { isDelivery } = this.props.store.deliveryAddressStore;

    if (useCalculationTypeByDeliveryArea && isDelivery) {
      const {
        street,
        street_no,
        address_id,
        city,
        zip
      } = this.props.store.deliveryAddressStore.address;

      const addressChanged =
        street !== this.state.street ||
        street_no !== this.state.streetNumber ||
        city !== this.state.city ||
        zip !== this.state.zip;

      if (addressChanged && !this.state.modalShowed) {
        this.props.store.deliveryAddressStore.saveStreetToChange(
          this.state.street
        );

        this.props.store.deliveryAddressStore.saveStreetNumberToChange(
          this.state.streetNumber
        );

        this.props.store.deliveryAddressStore.saveCityToChange(this.state.city);

        this.props.store.deliveryAddressStore.saveZipToChange(this.state.zip);

        this.props.store.deliveryAddressStore.showChangingZipConfirmationModal(
          true
        );

        const changeZipUrl = openModal(ModalsRouteType.CHANGE_ADDRESS_MODAL);

        this.props.history.push(changeZipUrl);

        this.setState({
          modalShowed: true
        });

        return true;
      }

      if (addressChanged && this.state.modalShowed) {
        this.setState({
          street,
          streetNumber: street_no,
          city,
          zip,
          addressInputValue: this._getFormattedAddress(street, street_no),
          googlePlaceId: address_id,
          isHouseAddress: street && !!street_no,
          modalShowed: false
        });

        return false;
      }

      return false;
    }

    return false;
  };

  /**
   * Action to handle chosen zip
   * @param e
   * @private
   */
  _prepareChangeZip = (value) => {
    const zip = this.props.store.restaurantStore.branch.parsedAreaCodes.find(
      (area) => area.value === value
    );

    if (zip) {
      this._handleChangeZip(zip);

      if (!this.props.store.restaurantStore.branch.isAutocomplete) {
        this.props.form.setFieldsValue({
          city: zip.sublocality
        });
      }
    }
  };

  /**
   * Method to update zip value in store
   * @param {string} value - zip value
   */
  _changeZip(value = '') {
    this.props.store.deliveryAddressStore.changeZip(value);
  }

  /**
   * Method to update first name value in store
   * @param {string} value - first name value
   */
  _changeFirstName(value) {
    this.props.store.deliveryAddressStore.changeFirstName(value);
  }

  /**
   * Method to update last name value in store
   * @param {string} value - last name value
   */
  _changeLastName(value) {
    this.props.store.deliveryAddressStore.changeLastName(value);
  }

  /**
   * Method to update street value in store
   * @param {string} value - street number value
   */
  _changeStreet(value = '') {
    this.props.store.deliveryAddressStore.changeStreet(value);
  }

  /**
   * Method to update street number value in store
   * @param {string} value - street number value
   */
  _changeStreetNo(value = '') {
    this.props.store.deliveryAddressStore.changeStreetNo(value);
  }

  /**
   * Method to update company name value in store
   * @param {string} value - company name value
   */
  _changeCompany(value = '') {
    this.props.store.deliveryAddressStore.changeCompany(value);
  }

  /**
   * Method to update city value in store
   * @param {string} value - city value
   */
  _changeCity(value = '') {
    this.props.store.deliveryAddressStore.changeCity(value);
  }

  /**
   * Method to update email value in store
   * @param {string} value - email value
   */
  _changeEmail(value) {
    this.props.store.deliveryAddressStore.changeEmail(value);
  }

  /**
   * Method to update phone value in store
   * @param {string} value - phone value
   */
  _changePhone(value = '') {
    this.props.store.deliveryAddressStore.changePhone(value);
  }

  /**
   * Method to update message value in store
   * @param {string} value - message value
   */
  _changeMessage(value) {
    this.props.store.deliveryAddressStore.changeMessage(value);
  }

  /**
   * Method to handle zip opening
   * @private
   */
  _handleZipOpen = () => {
    this.setState({ isZipOpen: true });
  };

  /**
   * Method to handle zip closing
   * @private
   */
  _handleZipClose = () => {
    this.setState({ isZipOpen: false });
  };

  /**
   * Method to get value of address field by key
   * @param {string} key - address field key
   * @returns {*}
   * @private
   */
  _getAddressFieldValue = (key) =>
    this.props.store.deliveryAddressStore.getAddressFieldValue(key);

  /**
   * Method to get formatting string of address
   * @param {string} streetName
   * @param {string} streetNumber
   */
  _getFormattedAddress(streetName, streetNumber) {
    return `${streetName} ${streetNumber}`;
  }

  /**
   * Method for render fields of order for filling in delivery mode
   */
  renderDeliveryFields = () => {
    const {
      getOptionalFieldsInAddressForm
    } = this.props.store.deliveryAddressStore;

    const { getFieldDecorator } = this.props.form;

    const {
      branch,
      useCalculationTypeByDeliveryArea
    } = this.props.store.restaurantStore;

    const isAddressAutocomplete = !!branch.isAutocomplete;

    const {
      first_name,
      last_name,
      street,
      street_no,
      company,
      email,
      phone,
      comment
    } = this.props.store.deliveryAddressStore.address;

    const { isShellThemeActive } = this.props.store.themesStore;

    return (
      <>
        {this._renderFirstNameField(getFieldDecorator, first_name)}
        {this._renderLastNameField(getFieldDecorator, last_name)}
        {this._renderCompanyField(getFieldDecorator, company)}
        {useCalculationTypeByDeliveryArea ? (
          this._renderStreetAddress()
        ) : (
          <>
            {this._renderStreetField(
              getFieldDecorator,
              street,
              isAddressAutocomplete
            )}
            {this._renderStreetNumberField(
              getFieldDecorator,
              street_no,
              isAddressAutocomplete
            )}
          </>
        )}
        <AdditionalFields
          fields={getOptionalFieldsInAddressForm}
          fieldDecorator={getFieldDecorator}
          getAddressFieldValue={(key) => this._getAddressFieldValue(key)}
          onFocusField={this._checkChangingStreetAddress}
          t={this.props.t}
        />
        {!useCalculationTypeByDeliveryArea &&
          this._renderZipChoosing(isShellThemeActive)}
        {this._renderCityField(true)}
        {this._renderEmailField(getFieldDecorator, email)}
        {this._renderPhoneField(getFieldDecorator, phone)}
        {this._renderCommentField(getFieldDecorator, comment)}
      </>
    );
  };

  /**
   * Method for render fields of order for filling in pickup mode
   */
  renderPickupFields = () => {
    const { getFieldDecorator } = this.props.form;

    const {
      getAllowedFieldsInOrderForm,
      isSimplePickUpForm
    } = this.props.store.restaurantStore.branch;

    const {
      first_name,
      last_name,
      street,
      street_no,
      company,
      email,
      phone,
      comment
    } = this.props.store.deliveryAddressStore.address;

    const companyFieldEnabled = getAllowedFieldsInOrderForm.includes(
      ORDER_FIELDS.company
    );

    const phoneFieldEnabled = getAllowedFieldsInOrderForm.includes(
      ORDER_FIELDS.phone
    );

    return (
      <>
        {this._renderFirstNameField(getFieldDecorator, first_name)}
        {this._renderLastNameField(getFieldDecorator, last_name)}
        {!isSimplePickUpForm && (
          <>
            {companyFieldEnabled
              ? this._renderCompanyField(getFieldDecorator, company)
              : null}
            {this._renderStreetField(getFieldDecorator, street, false)}
            {this._renderStreetNumberField(getFieldDecorator, street_no)}
            {this._renderZipPickup()}
            {this._renderCityField(false)}
          </>
        )}
        {this._renderEmailField(getFieldDecorator, email)}
        {phoneFieldEnabled
          ? this._renderPhoneField(getFieldDecorator, phone)
          : null}
        {this._renderCommentField(getFieldDecorator, comment)}
      </>
    );
  };

  renderOldForm = () => {
    const {
      form: { getFieldsError },
      store
    } = this.props;

    const {
      deliveryAddressStore: { isDelivery },
      orderPaymentMethodsStore: { isShowContactLessMessage },
      themesStore: { isShellThemeActive }
    } = store;

    return (
      <Form
        onSubmit={this.handleSubmit}
        className={classNames('order-form', {
          shell: isShellThemeActive
        })}
        action="#"
      >
        {isShowContactLessMessage && <ContactlessDeliveryComponent />}
        <hr className="hr__top" />
        {isDelivery ? this.renderDeliveryFields() : this.renderPickupFields()}

        {isShellThemeActive
          ? this.renderShellFooterButton(getFieldsError)
          : this.renderFooterButtons(getFieldsError)}
      </Form>
    );
  };

  render() {
    const { store, history } = this.props;

    const {
      deliveryAddressStore: { isDelivery },
      orderPaymentMethodsStore: { isShowContactLessMessage },
      restaurantStore: {
        useCalculationTypeByDeliveryArea,
        isLocationChosen,
        isGermanRestaurant,
        branch: {
          isBasicGpsCalculationType,
          isAdvancedDeliveryCalculationType,
          branchId
        }
      },
      themesStore: { isShellThemeActive }
    } = store;

    /* If need recalculate fee depend on address,
    redirect to basket for fill in */
    if (isDelivery && useCalculationTypeByDeliveryArea && !isLocationChosen) {
      history.push(generateLinkFor(states.basket, this.props, {}, true));
    }

    const advancedBranchIds = [
      '5700',
      '4554',
      '7614',
      '5802',
      '6972',
      '8898',
      '5032',
      '7753',
      '6351',
      '5062'
    ];

    const simpleBranchIds = [
      '3284',
      '2938',
      '2137',
      '1385',
      '6975',
      '316',
      '3203',
      '5',
      '2414',
      '1134'
    ];

    let experimentId = '';
    let targetedId = false;

    if (isAdvancedDeliveryCalculationType) {
      targetedId = advancedBranchIds.includes(branchId);

      if (isShellThemeActive) {
        experimentId = isProduction
          ? 'jlhC5EyQQZSeGz2rhkFpMA'
          : 'PhOFjHnYRCmMmceoCzeLdg';
      } else {
        experimentId = isProduction
          ? 'bgyWxG4aR9aVZ0SMbH3-Cw'
          : 'WAw9atYHS8qbmVWEpyu1wQ';
      }
    } else if (!useCalculationTypeByDeliveryArea) {
      targetedId = simpleBranchIds.includes(branchId);

      if (isShellThemeActive) {
        experimentId = isProduction
          ? 'va8M40wgSVmOFhh0V78d6g'
          : 'uw98wGXuTcSsgkD_Rn9MTg';
      } else {
        experimentId = isProduction
          ? 'ssEHSRrNTX2ED1m3-VjByg'
          : 'M4hcFfGpRBClfZljH6WpFA';
      }
    }

    return isGermanRestaurant && !isBasicGpsCalculationType && targetedId ? (
      <GoogleOptimize id={experimentId}>
        {this.renderOldForm()}

        <div className="order-form">
          {isShowContactLessMessage && <ContactlessDeliveryComponent />}
          <hr />
          <ShippingFormsController />
        </div>
      </GoogleOptimize>
    ) : (
      this.renderOldForm()
    );
  }
}

export default withTranslation(['address_form'])(AddressForm);
