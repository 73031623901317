import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Components
import SubcategoryMenuDesktop from './_subcategory_menu_desktop/SubcategoryMenuDesktop';

// CSS
import './SubcategoryMenu.scss';

/**
 * Subcategory menu component is used to show
 * category list in header.
 * @name ReactSubcategoryMenu
 * @property {string} category - category ID.
 * @property {function} onCategoryClick - custom callback for onCategoryClick handler.
 */
@inject('store')
@withRouter
@observer
class SubcategoryMenu extends Component {
  static propTypes = {
    category: PropTypes.string
  };
  static defaultProps = {
    loading: true,
    logo: 'some/url'
  };

  _onCategoryClick(e, subId) {
    this.props.store.categoryMenuStore.setActiveSubcategory(subId);
  }

  render() {
    return this.props.store.categoryMenuStore.activeCategory &&
      this.props.store.categoryMenuStore.activeCategory.subCategories &&
      this.props.store.categoryMenuStore.activeCategory.subCategories.length !==
        0 ? (
      <div id="subcategory-list-wrapper">
        <SubcategoryMenuDesktop
          categoryId={
            this.props.subcategory && parseInt(this.props.subcategory.id, 10)
          }
          onCategoryClick={this._onCategoryClick.bind(this)}
        />
      </div>
    ) : null;
  }
}
export default withTranslation(['basket'])(SubcategoryMenu);
