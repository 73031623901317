import { IS_SERVER } from 'config';

import { BROWSER_SUPPORT } from '../enums/browser.enum';

/**
 * Function to convert string from HTTP to HTTPS protocol
 * @param {string} url - value of any url with "http://".
 * @memberof commonFunctions
 */
export function convertHttpToHttps(url) {
  url = url || '';

  return url.replace('http://', 'https://');
}

/**
 * Function to round prices to 2 decimals.
 * @param {string || number} value - value to round.
 * @memberof commonFunctions
 */
export function roundTo2Decimals(value) {
  return parseFloat(parseFloat(value).toFixed(2));
}

/**
 * Function to round prices to 3 decimals.
 * @param {string || number} value - value to round.
 * @memberof commonFunctions
 */
export function roundTo3Decimals(value) {
  return parseFloat(parseFloat(value).toFixed(3));
}

/**
 * Function to check every third number.
 * @param {number} index - floor's index.
 * @param {number} breakInterval - interval to back to the start
 * @return {boolean} checked floor's index (true every third number / else false).
 * @memberof commonFunctions
 */
export function isOdd(index, breakInterval) {
  return !(Math.floor(index / breakInterval) % 2);
}

/**
 * Detect safari
 *
 * @export
 * @param {string} userAgent
 * @returns
 */
export function detectSafari(userAgent) {
  return userAgent.toLowerCase().indexOf('safari') !== -1;
}

/** Detect iOS
 *
 * @export
 * @param {string} platform
 * @returns
 */
export function detectIOS(platform) {
  return /iPad|iPhone|iPod/.test(platform);
}

/**
 * Cuts off country from lang
 * @param {string} lang - language id
 * @memberof commonFunctions
 * @returns {string}
 */
export function stripCountry(lang) {
  if (lang.indexOf('-') > -1) {
    return lang.substring(0, lang.indexOf('-'));
  }

  return lang;
}

/**
 * Generate Guid
 * @returns {string}
 */
export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
}

/**
 * Method to create error response
 * @param {string} status (redirect, fail)
 * @param {string} redirectUrl without slug
 * @param {string} msg info message
 * @param err {string}
 * @returns {object} result string
 */
export function handleError(
  status,
  redirectUrl = '/error',
  msg = 'Undefined error',
  err = 'Undefined error'
) {
  return new Error({
    status,
    redirectUrl,
    msg,
    err
  });
}

/**
 * Method to filter price
 * @param {number} price - The price of anything.
 * @return {string} filtered price to be used in UI
 */
export function filterPrice(price) {
  return parseFloat(price).toFixed(2).replace('.', ',');
}

/**
 * Method to get price string based on currency and locale
 * @param {number} price - The price of anything.
 * @param {string} language - active language.
 * @param {string} currency - restaurant's currency.
 * @param maximumFractionDigits
 * @return {string} filtered price to be used in UI
 */
export function intlPrice(
  price,
  language,
  currency,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2
) {
  if (IS_SERVER) {
    return serverIntlPrice(
      price,
      language,
      currency,
      maximumFractionDigits,
      minimumFractionDigits
    );
  }

  return clientIntlPrice(
    price,
    language,
    currency,
    maximumFractionDigits,
    minimumFractionDigits
  );
}

/**
 * Method to get price string based on currency and locale for server side
 * @param {number} price - The price of anything.
 * @param {string} language - active language.
 * @param {string} currency - restaurant's currency.
 * @return {string} filtered price to be used in UI
 */
export function serverIntlPrice(
  price,
  language,
  currency,
  maximumFractionDigits,
  minimumFractionDigits
) {
  const formatConfig = {
    maximumFractionDigits,
    style: 'currency',
    currency,
    minimumFractionDigits
  };

  return new Intl.NumberFormat(language, formatConfig).format(
    parseFloat(price)
  );
}

/**
 * Method to get price string based on currency and locale for client side without polyfills (browser side)
 * @param {number} price - The price of anything.
 * @param {string} language - active language.
 * @param {string} currency - restaurant's currency.
 * @return {string} filtered price to be used in UI
 */
export function clientIntlPrice(
  price,
  language,
  currency,
  maximumFractionDigits,
  minimumFractionDigits
) {
  const formatConfig = {
    maximumFractionDigits,
    style: 'currency',
    currency,
    minimumFractionDigits
  };

  return new Intl.NumberFormat(language, formatConfig).format(
    parseFloat(price)
  );
}

/**
 * Method to generate structured data worktimes
 * @param pickupDays
 * @returns {*[]}
 */
export function generateStructuredData(pickupDays) {
  return [
    {
      '@type': 'OpeningHoursSpecification',
      opens: pickupDays[0].start,
      closes: pickupDays[0].end,
      dayOfWeek: 'http://schema.org/Sunday'
    },
    {
      '@type': 'OpeningHoursSpecification',
      opens: pickupDays[1].start,
      closes: pickupDays[1].end,
      dayOfWeek: 'http://schema.org/Monday'
    },
    {
      '@type': 'OpeningHoursSpecification',
      opens: pickupDays[2].start,
      closes: pickupDays[3].end,
      dayOfWeek: 'http://schema.org/Tuesday'
    },
    {
      '@type': 'OpeningHoursSpecification',
      opens: pickupDays[3].start,
      closes: pickupDays[3].end,
      dayOfWeek: 'http://schema.org/Wednesday'
    },
    {
      '@type': 'OpeningHoursSpecification',
      opens: pickupDays[4].start,
      closes: pickupDays[4].end,
      dayOfWeek: 'http://schema.org/Thursday'
    },
    {
      '@type': 'OpeningHoursSpecification',
      opens: pickupDays[5].start,
      closes: pickupDays[5].end,
      dayOfWeek: 'http://schema.org/Friday'
    },
    {
      '@type': 'OpeningHoursSpecification',
      opens: pickupDays[6].start,
      closes: pickupDays[6].end,
      dayOfWeek: 'http://schema.org/Saturday'
    }
  ];
}

/**
 * Method go get fill template
 * @returns {*[]}
 */
export function getDaysTemplate() {
  return [
    {
      day: 0,
      daySort: 6,
      days: [''],
      start: '',
      end: '',
      breakStart: undefined,
      breakEnd: undefined
    },
    {
      day: 1,
      daySort: 0,
      days: [''],
      start: '',
      end: '',
      breakStart: undefined,
      breakEnd: undefined
    },
    {
      day: 2,
      daySort: 1,
      days: [''],
      start: '',
      end: '',
      breakStart: undefined,
      breakEnd: undefined
    },
    {
      day: 3,
      daySort: 2,
      days: [''],
      start: '',
      end: '',
      breakStart: undefined,
      breakEnd: undefined
    },
    {
      day: 4,
      daySort: 3,
      days: [''],
      start: '',
      end: '',
      breakStart: undefined,
      breakEnd: undefined
    },
    {
      day: 5,
      daySort: 4,
      days: [''],
      start: '',
      end: '',
      breakStart: undefined,
      breakEnd: undefined
    },
    {
      day: 6,
      daySort: 5,
      days: [''],
      start: '',
      end: '',
      breakStart: undefined,
      breakEnd: undefined
    },
    {
      day: 7,
      daySort: 7,
      days: [''],
      start: '',
      end: '',
      breakStart: undefined,
      breakEnd: undefined
    }
  ];
}

/**
 * Method to get full array of weekdays like on api
 * @return {array} weekdays array
 */
export function getWeekDaysArray() {
  return [
    {
      dayNo: 0,
      daySort: 0,
      endT: '23:59:00',
      show: true,
      startT: '00:00:00'
    },
    {
      dayNo: 1,
      daySort: 1,
      endT: '23:59:00',
      show: true,
      startT: '00:00:00'
    },
    {
      dayNo: 2,
      daySort: 2,
      endT: '23:59:00',
      show: true,
      startT: '00:00:00'
    },
    {
      dayNo: 3,
      daySort: 3,
      endT: '23:59:00',
      show: true,
      startT: '00:00:00'
    },
    {
      dayNo: 4,
      daySort: 4,
      endT: '23:59:00',
      show: true,
      startT: '00:00:00'
    },
    {
      dayNo: 5,
      daySort: 5,
      endT: '23:59:00',
      show: true,
      startT: '00:00:00'
    },
    {
      dayNo: 6,
      daySort: 6,
      endT: '23:59:00',
      show: true,
      startT: '00:00:00'
    },
    {
      dayNo: 7,
      daySort: 7,
      endT: '23:59:00',
      show: true,
      startT: '00:00:00'
    }
  ];
}

/**
 * Check supported browser version
 * @export
 * @param {string} userAgent
 * @param {object} store
 * @returns {boolean}
 */
export function isBrowserSupported(userAgent, store) {
  /* eslint-disable @typescript-eslint/no-var-requires, global-require */
  const useragent = require('useragent');

  /* eslint-disable global-require */
  require('useragent/features');

  const browserData = useragent.lookup(userAgent);
  const browserName = browserData.family;

  store.themesStore.setBrowser(browserName);

  switch (browserName) {
    case BROWSER_SUPPORT.chrome.name:
      return browserData.satisfies(BROWSER_SUPPORT.chrome.version);
    case BROWSER_SUPPORT.chromeWebView.name:
      return browserData.satisfies(BROWSER_SUPPORT.chromeWebView.version);
    case BROWSER_SUPPORT.safari.name:
      return browserData.satisfies(BROWSER_SUPPORT.safari.version);
    case BROWSER_SUPPORT.safariMobile.name:
      return browserData.satisfies(BROWSER_SUPPORT.safariMobile.version);
    case BROWSER_SUPPORT.ie.name:
      return browserData.satisfies(BROWSER_SUPPORT.ie.version);
    case BROWSER_SUPPORT.opera.name:
      return browserData.satisfies(BROWSER_SUPPORT.opera.version);
    case BROWSER_SUPPORT.firefox.name:
      return browserData.satisfies(BROWSER_SUPPORT.firefox.version);
    default:
      return true;
  }
}

/**
 * Method to remove whitespaces and return empty string if value not available
 * @param {string} value - source string
 * @returns {string} - trimmed string
 */
export function trimValue(value = '') {
  return value ? value.trim() : '';
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export const shuffle = <T>(array: T[]) => array.sort(() => Math.random() - 0.5);

/**
 * Generate image in png from text
 */
export const generateImageFromText = (
  string: string,
  startParseObject: { string: string; offset: number },
  endParseObject: { string: string; offset: number },
  size: { width: number; height: number; fontSize: number }
) => {
  let result = string;

  const parseStart = string.indexOf(startParseObject.string);
  const parseEnd = string.indexOf(endParseObject.string);

  if (parseStart >= 0 && parseEnd >= 0) {
    const targetString = string.substring(
      parseStart + startParseObject.string.length + startParseObject.offset,
      parseEnd - endParseObject.offset
    );

    if (targetString) {
      const canvas = document.createElement('canvas');

      canvas.width = size.width;

      canvas.height = size.height;

      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.font = `${size.fontSize}px Roboto, sans-serif`;

        ctx.fillText(targetString, 0, size.fontSize);
      }

      const image = canvas.toDataURL('image/png');

      result = [
        string.slice(0, parseStart),
        image,
        string.slice(parseEnd + endParseObject.string.length)
      ].join('');
    }
  }

  return result;
};

/**
 * Is light color
 * @param color
 * @returns {boolean}
 */
export function isLightColor(color) {
  // Variables for red, green, blue values
  let r;
  let g;
  let b;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    const [color0, color1, color2, color3] = color;

    r = color1;

    g = color2;

    b = color3;
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

    r = color >> 16;

    g = (color >> 8) & 255;

    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  // Original value:  return hsp > 127.5;
  return hsp > 160;
}

/**
 * Function to calculate distance between two points (longitude, latitude) in meters
 * @param {lat: number, lon: number} firstPoint
 * @param {lat: number, lon: number} secondPoint
 */
export function calculateDistance(firstPoint, secondPoint) {
  const R = 6371; // km
  const dLat = ((secondPoint.lat - firstPoint.lat) * Math.PI) / 180;
  const dLon = ((secondPoint.lon - firstPoint.lon) * Math.PI) / 180;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((firstPoint.lat * Math.PI) / 180) *
      Math.cos((secondPoint.lat * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  return Math.round(d * 1000);
}

/**
 * Create uniq anchor link
 * @param {array} page
 */
export function createHomepageHrefLink(page) {
  const pageElemets = page.slice();

  pageElemets.unshift('homepage');

  return pageElemets.join('-');
}

export function mapQueryParamsToObject(queryParameters: string) {
  const result: { [key: string]: string } = {};
  const urlSearchParams = new URLSearchParams(queryParameters);

  for (const [key, prop] of urlSearchParams.entries()) {
    result[key] = prop;
  }

  return result;
}
