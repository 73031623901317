import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

import './styles.scss';
import { BranchWorkStatus } from '../../../../../enums/branch_work_status.enum';

/**
 * Status bar which displayed in homepage under logo
 */
class ShellOpenStatus extends PureComponent {
  static propTypes = {
    status: PropTypes.object.isRequired,
    style: PropTypes.object
  };

  render() {
    let style;

    if (this.props.style) {
      style =
        this.props.status.state === BranchWorkStatus.OPEN
          ? this.props.style.positive
          : this.props.style.negative;
    }

    return (
      <div className={`shell-open-status ${this.props.status.state}`}>
        <span style={style} data-testid="shell-opened-closed">
          {this.props.status.value}
        </span>
      </div>
    );
  }
}

export default withTranslation(['opening_hours'])(ShellOpenStatus);
