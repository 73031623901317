import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { generateLinkFor } from '../../../../../../utils/routing';

/**
 * Component to render menu item
 */
@withRouter
@inject('store')
export default class MenuItemShell extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    style: PropTypes.object,
    dataTestId: PropTypes.string,
    dataTestIdIcon: PropTypes.string,
    dataTestIdLink: PropTypes.string
  };

  static defaultProps = {};

  render() {
    const {
      title,
      icon,
      state,
      dataTestId,
      dataTestIdIcon,
      dataTestIdLink
    } = this.props;

    const props = { ...this.props, location: { ...this.props.location } };

    props.location.search = '';

    const link = generateLinkFor(state, props, {}, true);

    return (
      <Link to={link} onClick={this.props.action} data-testid={dataTestIdLink}>
        <div className="menu-item" style={this.props.style}>
          <i className={`fas ${icon}`} data-testid={dataTestIdIcon} />
          <div className="title" data-testid={dataTestId}>
            {title}
          </div>
        </div>
      </Link>
    );
  }
}
