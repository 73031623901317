import React, { PureComponent } from 'react';
// styles
import './styles.scss';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { createHomepageHrefLink } from '../../../../../../../utils/functions';
import { ELEMENTS_TYPES } from '../../../../../../../enums/homepage_elements_types.enum';

class HomepageDeliveryMobile extends PureComponent {
  static propTypes = {
    areas: PropTypes.array.isRequired,
    isHidePostalCodes: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired
  };

  /**
   * Method to render single area
   * @param item - area object
   * @param key - index of an element
   * @returns {*}
   * @private
   */
  _renderItem = (item, key) => {
    return (
      <div
        className="item"
        key={key}
      >{`${item.areaCode} ${item.sublocality}`}</div>
    );
  };

  /**
   * Method to render area with postal code
   * @private
   */
  _renderWithPostalCode = () => {
    return this.props.areas.map((item, index) => {
      return this._renderItem(item, index);
    });
  };

  /**
   * Method to render area without postal code
   * @private
   */
  _renderWithoutPostalCode = () => {
    const sublocalities = this.props.areas.map((area) => area.sublocality);

    // Remove duplicates of sublocalities
    return Array.from(new Set(sublocalities)).map((item, index) => {
      return (
        <div className="item" key={index}>
          {item}
        </div>
      );
    });
  };

  render() {
    return (
      <section
        className="mobile-homepage-section mobile-homepage-delivery"
        id={createHomepageHrefLink([
          ELEMENTS_TYPES.AREA_CODE,
          this.props.index
        ])}
      >
        <h2>{this.props.t('home:deliveryAreasTitle')}</h2>
        <div className="list">
          {this.props.isHidePostalCodes
            ? this._renderWithoutPostalCode()
            : this._renderWithPostalCode()}
        </div>
      </section>
    );
  }
}
export default withTranslation(['home'])(HomepageDeliveryMobile);
