import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

// Components
import BasketControlButtonShell from '../../../buttons-shell/basket-controls-button';
import FooterShellButton from '../../../buttons-shell/footer-button';

import './styles.scss';

/**
 * Free bonus item component which displayed on the top of the shell basket
 */
export default class BasketShellBonusProduct extends PureComponent {
  static propTypes = {
    rule: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonAction: PropTypes.func,
    isFreeItemAdded: PropTypes.bool,
    productList: PropTypes.string,
    isValid: PropTypes.bool,
    freeItemTitle: PropTypes.string,
    freeItemPrice: PropTypes.string,
    removeFreeItem: PropTypes.func,
    orderButtonStyle: PropTypes.object,
    standardButtonStyle: PropTypes.object,
    negativeTextStyle: PropTypes.object
  };

  static defaultProps = {};

  /**
   * Method to render free item rule
   * @returns {*}
   * @private
   */
  _renderRule = () => (
    <div
      className="rule"
      dangerouslySetInnerHTML={{ __html: this.props.rule }}
      style={this.props.negativeTextStyle}
    />
  );

  /**
   * Method to render add free item button
   * or free item if its already added
   * @returns {*}
   * @private
   */
  _renderFreeItem = () => {
    const { isFreeItemAdded } = this.props;

    return isFreeItemAdded ? this._renderItem() : this._renderButton();
  };

  /**
   * Method to render free item layout
   * @returns {*}
   * @private
   */
  _renderItem = () => (
    <div className="item" onClick={this.props.buttonAction}>
      <div className="title" data-testid="ch-bonus-name-fbs">
        {this.props.freeItemTitle}
      </div>
      <div className="price" data-testid="ch-bonus-price-fbs">
        {this.props.freeItemPrice}
      </div>
    </div>
  );

  /**
   * Method to render add free item button
   * should be displayed if order reached mbv
   * and there is no bonus product in basket already
   * @returns {*}
   * @private
   */
  _renderButton = () => (
    <div className="button-wrapper">
      <FooterShellButton
        label={this.props.buttonLabel}
        smallText
        action={this.props.buttonAction}
        buttonOrderStyle={this.props.orderButtonStyle}
        buttonStandardStyle={this.props.standardButtonStyle}
        dataTestId="bonus-btn-fbs"
      />
    </div>
  );

  /**
   * Method to render remove button for bonus product
   * @returns {null}
   * @private
   */
  _renderRemoveButton = () =>
    this.props.isFreeItemAdded ? (
      <div className="remove-button-wrapper">
        <BasketControlButtonShell
          mdiClass="mdi-close"
          black
          action={this.props.removeFreeItem}
        />
      </div>
    ) : null;

  render() {
    return (
      <div className="shell-bonus-product">
        <div className="product-name">
          <div className="name-wrapper">
            <div className="icon mdi mdi-gift" />
            <span data-testid="bonus-title-fbs">{this.props.title}</span>
          </div>
          {this._renderRemoveButton()}
        </div>
        {!this.props.isFreeItemAdded ? (
          <div className="list" data-testid="bonuses-list-fbs">
            {this.props.productList}
          </div>
        ) : null}
        {this.props.isValid ? this._renderFreeItem() : this._renderRule()}
      </div>
    );
  }
}
