export default {
  MAINTENANCE_MODE: 'MAINTENANCE_MODE',
  SHOP_MOVED: 'SHOP_MOVED',
  ACTIVE_CATEGORY: 'ACTIVE_CATEGORY',
  ACTIVE_SUBCATEGORY: 'ACTIVE_SUBCATEGORY',
  ERROR_PAGE: 'ERROR_PAGE',
  TERMS_PAGE: 'TERMS_PAGE',
  IMPRINT_PAGE: 'IMPRINT_PAGE',
  PRIVACY_POLICY_PAGE: 'PRIVACY_POLICY_PAGE',
  LOADING_PAGE: 'LOADING_PAGE',
  START_PAGE: 'START_PAGE',
  TABLE_RESERVATION: 'TABLE_RESERVATION',
  CONTACT_FORM: 'CONTACT_FORM',
  ADDITIVE_LIST: 'ADDITIVE_LIST'
};
