import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { RootContext } from '../../../../../stores';
import SearchInputField from '../../../components/search-input-field';

import HeaderShellProps from './HeaderShell.types';

import classNames from 'classnames';
import './HeaderShell.scss';

const routesWithSearch = [
  '/:slug/:branchId/cat',
  '/:slug/:branchId/cat/:catId',
  '/:branchId/cat',
  '/:branchId/cat/:catId'
];

const HeaderShell = ({
  title,
  backAction,
  style,
  hideSideMenu,
  isPortalAppThemeActive,
  branchName
}: HeaderShellProps) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { t } = useTranslation('product_view');
  const { path } = useRouteMatch();
  const isSearchEnabled = routesWithSearch.includes(path);
  const { themesStore, productsStore } = useContext(RootContext);

  const [searchInputValue, setSearchInputValue] = useState(
    productsStore.searchingRequest || ''
  );

  const setSearchingRequest = () => {
    productsStore.setSearchingRequest(searchInputValue);
  };

  const debouncedSetSearchingRequest = debounce(setSearchingRequest, 350);

  useEffect(() => {
    debouncedSetSearchingRequest();
  }, [searchInputValue]);

  const openSidebarMenu = () => {
    themesStore.showSidebar();
  };

  const onChangeSearchInput = (value: string) => {
    setSearchInputValue(value);
  };

  const onOpenSearchField = (isOpen: boolean) => {
    setIsSearchOpen(isOpen);

    if (isOpen && !productsStore.isBranchProductListGot) {
      productsStore.loadProductsByBranch();
    }
  };

  const titleText = isPortalAppThemeActive && branchName ? branchName : title;

  return (
    <header
      id="header-shell"
      className={classNames('sticky-header-mobile', 'app-header', {
        'is-mobile-search': productsStore.isSearchEnabled
      })}
      style={style}
    >
      <button
        onClick={backAction}
        className={classNames('back-icon', 'header-shell-button', {
          hidden: !backAction || isSearchOpen
        })}
        data-testid="shell-back-top-btn"
      >
        <i className="mdi mdi-chevron-left" />
      </button>
      <div
        className={classNames('spacer', {
          hidden: backAction
        })}
      />

      <div
        className={classNames('title', {
          hide: isSearchOpen
        })}
      >
        <span data-testid="top-bar-title">{titleText}</span>
      </div>

      <div
        className={classNames('header-shell__right-content', {
          open: isSearchOpen
        })}
      >
        {isSearchEnabled && (
          <SearchInputField
            placeholder={t('productName')}
            onChange={onChangeSearchInput}
            onOpen={onOpenSearchField}
            value={searchInputValue}
            style={style}
            isMobile={themesStore.isMobile}
          />
        )}

        <button
          onClick={openSidebarMenu}
          className={classNames('menu-icon', 'header-shell-button', {
            hidden: hideSideMenu
          })}
          data-testid="shell-side-menu-btn"
        >
          <i className="mdi mdi-menu" />
        </button>
      </div>
    </header>
  );
};

export default observer(HeaderShell);
