import { Icon } from 'antd';
import classnames from 'classnames';
import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useSearch } from 'client/hooks';
import logo from 'client/images/powered-by-google.webp';
import googleService, {
  AutocompletePrediction,
  loadGoogleMaps
} from 'client/services/googleService';
import Autocomplete from 'client/ui/Autocomplete';

import css from './GoogleAddress.module.scss';
import { GoogleAddressProps } from './GoogleAddress.type';

const GoogleAddress = ({
  className,
  country,
  defaultValue = '',
  disabled,
  error,
  isValidating,
  // TODO if placeholder is going to be the same all the time
  // than it needs to be removed from props and hard coded via
  // TextField props
  placeholder,
  onChange,
  clearErrors,
  dataTestId
}: GoogleAddressProps) => {
  const { t } = useTranslation(['errors']);

  const fetchGooglePlaces = async (input: string) => {
    const places = (await googleService.getPlacesPredictions(input)) ?? [];

    return places;
  };

  const {
    inputValue,
    options,
    handleInputChange,
    setInputValue,
    loader
  } = useSearch(fetchGooglePlaces);

  const getOptionLabel = useCallback((option: AutocompletePrediction) => {
    const { description } = option;
    const [street, city] = description.split(', ');
    const value = `${city}, ${street}`;

    return value;
  }, []);

  const getActiveOption = useCallback(() => false, []);

  const getPlaceInfo = async (placeDescription: string) => {
    const [place] = await googleService.findPlaceFromQuery(placeDescription);
    const placeId = place.place_id ?? '';
    const [response] = await googleService.getPlaceInfo(placeId);

    onChange(response);
  };

  const handleClick = (option: AutocompletePrediction) => {
    const value = getOptionLabel(option);
    const isHouseAddress = googleService.verifyAddressType(option.types);

    if (isHouseAddress) {
      setInputValue(value);
    } else {
      setInputValue(`${value} `);
    }

    getPlaceInfo(option.description);
  };

  const suffix = isValidating ? <Icon type="loading" spin /> : loader;

  const googleLogo = (
    <div className={css.poweredByGoogle}>
      <img src={logo} alt="Powered By Google" />
    </div>
  );

  useEffect(() => {
    loadGoogleMaps(country);

    const changeEvent = { target: { value: defaultValue } };

    handleInputChange(changeEvent as any);
  }, []);

  const handleChangeGooglePlaceValue = useCallback(
    (value) => {
      clearErrors && clearErrors('googleAddress');

      handleInputChange(value);
    },
    [inputValue, clearErrors]
  );

  return (
    <Autocomplete
      className={classnames(css.GoogleAddress, className)}
      dropdownFooter={googleLogo}
      disabled={disabled}
      error={error}
      getOptionLabel={getOptionLabel}
      getActiveOption={getActiveOption}
      noOptionLabel={t('errors:noResults')}
      placeholder={placeholder}
      onClick={handleClick}
      onChange={handleChangeGooglePlaceValue}
      options={options}
      suffix={suffix}
      value={inputValue}
      dataTestId={dataTestId}
    />
  );
};

export { GoogleAddress };

export default memo(GoogleAddress);
