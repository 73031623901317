import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

/**
 * Component to show edit button in basket
 */
export default class EditButton extends PureComponent {
  static propTypes = {
    link: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired
  };

  static defaultProps = {
    onClick: () => {}
  };

  handleClick = (event) => {
    event.stopPropagation();

    this.props.onClick();
  };

  render() {
    const { link, onClick, title } = this.props;

    return (
      <Link className="change-button" to={link} onClick={this.handleClick}>
        {title}
      </Link>
    );
  }
}
