import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import LegalAppsmartInfo from './legal-appsmart-info';
import LegalRestaurantInfo from './legal-restaurant-info/index';
import './HeaderImprint.scss';

/**
 * Component to render legal header
 */
export default class HeaderImprint extends PureComponent {
  static propTypes = {
    branch: PropTypes.object.isRequired,
    restaurant: PropTypes.object,
    logo: PropTypes.string,
    appsmartCredentials: PropTypes.object
  };

  static defaultProps = {
    isHideMarketingData: false
  };

  /**
   * Method for render app smart logo
   * @param {boolean} isHideLogoFromImprint - visibility state of app smart logo
   * @returns {*}
   * @private
   */
  _renderLegalInfo = (isHideLogoFromImprint) =>
    isHideLogoFromImprint ? <div className="spacer" /> : <LegalAppsmartInfo />;

  render() {
    const { logo, branch, restaurant, appsmartCredentials } = this.props;

    return (
      <div id="imprint-header">
        <LegalRestaurantInfo
          restaurant={restaurant}
          branch={branch}
          appsmartCredentials={appsmartCredentials}
        />

        {logo ? (
          <div className="logo">
            <img src={logo} alt="logo" />
            {this._renderLegalInfo(branch.isHideLogoFromImprint)}
          </div>
        ) : null}
      </div>
    );
  }
}
