import { inject, observer } from 'mobx-react';
import React, { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import Measure from 'react-measure';
import { withRouter } from 'react-router-dom';

import { IS_CLIENT } from 'config';

import MetadataTypes from '../../enums/metadata.enums';
import states from '../../enums/states.enum';
import MobileRoute from '../../routes/mobile-route';
import CategoryFullList from '../../themes/theme-hermes/components/category_menu/category_full-list/mobile/category_full_list';
import MobileFooter from '../../themes/theme-hermes/components/footer/mobile';
import NewHeaderMobile from '../../themes/theme-hermes/components/header/mobile';
import ShoppingCartPanel from '../../themes/theme-hermes/components/shopping_cart_panel';
import WidgetWrapper from '../../themes/theme-hermes/components/widgets';
import { generateLinkFor } from '../../utils/routing';
import Metadata from '../meta_data';
import { SearchModalMobile } from '../Search/index';
import './AppWrapper.scss';

@withRouter
/**
 * Component for render mobile wrapper
 */
@inject('store')
@observer
class AppWrapperMobileComponent extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = createRef();

    this.props.store.categoryMenuStore.fillProductsCategories();

    this.state = {
      footerHeight: 0
    };
  }

  componentDidMount() {
    this.props.store.categoryMenuStore.setScrollableContainer(this.wrapperRef);

    // this block for disabling user zooming of the Hermes shop
    if (IS_CLIENT) {
      document.addEventListener('touchstart', this.preventZooming, {
        passive: false
      });

      this.props.store.basketStore.loadBasketFromStorage();
    }
  }

  componentDidUpdate(prevProps) {
    const rootRoutePath = generateLinkFor(states.app, this.props, null, true);
    const slashPosition = this.props.location.pathname.length - 1;

    const currentRoutePath =
      this.props.location.pathname[slashPosition] === '/'
        ? this.props.location.pathname
        : `${this.props.location.pathname}/`;

    const prevRoutePath =
      prevProps.location.pathname[slashPosition] === '/'
        ? prevProps.location.pathname
        : `${prevProps.location.pathname}/`;

    const legalPages = [
      `${rootRoutePath.pathname}${states.imprint}/`,
      `${rootRoutePath.pathname}${states.privacyPolicy}/`,
      `${rootRoutePath.pathname}${states.termsOfUse}/`
    ];

    if (currentRoutePath !== prevRoutePath) {
      if (currentRoutePath === rootRoutePath.pathname) {
        this.props.store.categoryMenuStore.scrollContainerElement();
      } else if (legalPages.includes(currentRoutePath)) {
        this.props.store.categoryMenuStore.scrollContainerElement(true);
      }
    }
  }

  componentWillUnmount() {
    if (IS_CLIENT) {
      document.removeEventListener('touchstart', this.preventZooming);
    }
  }

  preventZooming = (event) => {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  };

  /**
   * Method to render homepage or offers/products lists
   * @return {*}
   */
  _pageContent = () => {
    const { isShowOffersForMobile } = this.props.store.categoryMenuStore;

    return this._categoriesList(isShowOffersForMobile);
  };

  /**
   * Method to render offers or products lists
   * @return {*}
   */
  _categoriesList = (isShowOffersForMobile) => {
    const excludeCategoryIds = isShowOffersForMobile ? [-2] : [-2, -1];

    return (
      <CategoryFullList
        excludeCategoryIds={excludeCategoryIds}
        footerElement={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Measure
            key="category-full-list-footer-element"
            bounds
            onResize={(contentRect) => {
              this.state.footerHeight !== contentRect.bounds.height &&
                this.setState({
                  footerHeight: contentRect.bounds.height
                });
            }}
          >
            {({ measureRef }) => (
              <div ref={measureRef} style={{ overflow: 'auto' }}>
                <WidgetWrapper />
                <MobileFooter
                  restaurant={this.props.store.restaurantStore.restaurant}
                  isStartPage={false}
                  branch={this.props.store.restaurantStore.branch}
                />
              </div>
            )}
          </Measure>
        }
        footerHeight={this.state.footerHeight}
      />
    );
  };

  renderShoppingCartPanel = () => <ShoppingCartPanel mobile />;

  /**
   * Method to mobile wrapper content
   * @return {*}
   */
  _renderProductList = (shoppingCart) => {
    this.props.store.themesStore.changeMobileState(states.app);

    return IS_CLIENT ? (
      <div id="main-content-mobile">
        <NewHeaderMobile showSearch />
        {this._pageContent()}
        {shoppingCart}
      </div>
    ) : null;
  };

  render() {
    const { store } = this.props;
    const { themesStore, productsStore } = store;
    const shoppingCart = this.renderShoppingCartPanel();

    return (
      <div
        id="app-state-mobile-wrapper"
        className={themesStore.isFixedStyleActive ? 'fixed' : null}
        ref={this.wrapperRef}
      >
        <MobileRoute
          mainComponent={() => this._renderProductList(shoppingCart)}
          hostName={this.props.store.restaurantStore.hostName}
        />
        <Metadata metadataType={MetadataTypes.ACTIVE_CATEGORY} />
        {productsStore.isSearchEnabled && <SearchModalMobile />}
      </div>
    );
  }
}

export default withTranslation(['seo'])(AppWrapperMobileComponent);
