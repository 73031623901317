import PropTypes from 'prop-types';
import React, { PureComponent, createRef } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import styled from 'styled-components';
import classNames from 'classnames';
import { v1 } from 'uuid';

import animationConstants from '../../../../enums/animation.enum';
import images from '../../../../enums/images_enums/hermes_images.enum';
import './ButtonProductPriceAnimation.scss';

/**
 * Component to render button for adding to basket
 */
class ButtonProductPriceAnimation extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    isShell: PropTypes.bool,
    type: PropTypes.string,
    isHasSeveralSizes: PropTypes.bool,
    animated: PropTypes.bool,
    animation: PropTypes.shape({
      type: PropTypes.string,
      basketCoordinates: PropTypes.object,
      mobileView: PropTypes.bool
    }),
    style: PropTypes.object,
    onClick: PropTypes.func,
    hidePlus: PropTypes.bool,
    bigButton: PropTypes.bool,
    itemCount: PropTypes.number,
    isSoldOut: PropTypes.bool
  };

  static defaultProps = {
    isShell: false,
    disabled: false,
    type: 'button',
    isSoldOut: false
  };

  constructor(props) {
    super(props);

    this.buttonPriceRef = createRef();

    this.state = {
      animationBlobs: [],
      showBasketIcon: false
    };

    this.timer;
  }

  componentDidMount() {
    this.executeAnimation();
  }

  componentDidUpdate(props) {
    this.executeAnimation(props);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  executeAnimation = (props) => {
    const { animated, animation, itemCount } = this.props;
    const buttonPosition = this.buttonPriceRef.current.getBoundingClientRect();

    if (
      animated &&
      (!props || (props && animated !== props.animated)) &&
      animation &&
      animation.type === 'blobToBasket' &&
      animation.basketCoordinates
    ) {
      let offsetX =
        animation.basketCoordinates.left - buttonPosition.left - pageXOffset;

      offsetX = animation.mobileView
        ? offsetX +
          (animation.basketCoordinates.width - buttonPosition.width) / 2
        : offsetX - buttonPosition.width / 2;

      let offsetY =
        animation.basketCoordinates.top -
        buttonPosition.top -
        pageYOffset +
        (animation.offsetY || 0);

      offsetY = animation.mobileView ? offsetY + pageYOffset : offsetY;

      const key = v1();

      const Blob = styled.div`
        ${animation.mobileView ? 'margin: 0 !important;' : ''};
        z-index: 14;
        position: absolute;
        ${animation.mobileView ? `width: ${buttonPosition.width}` : ''};
        animation: moving-${key} ${animationConstants.time.buttonToBuyProduct}ms
          linear normal;
        opacity: 0;
        border-top-left-radius: 50% !important;
        border-top-right-radius: 50% !important;
        min-width: auto !important;
        @keyframes moving-${key} {
          0% {
            opacity: 1;
          }
          100% {
            transform: translate(${offsetX}px, ${offsetY}px);
          }
        }
      `;

      this.setState({
        animationBlobs: [
          ...this.state.animationBlobs,
          {
            id: key,
            elem: (
              <Blob
                key={key}
                className={classNames({
                  'button-product-price': true,
                  added: true,
                  disabled: false,
                  'same-item-in-basket': !!itemCount
                })}
                onAnimationEnd={() => this.dropAnimationBlob(key)}
              />
            )
          }
        ],
        showBasketIcon: true
      });
    } else if (animated) {
      this.setState(
        {
          showBasketIcon: true
        },
        () => {
          this.timer = setTimeout(() => {
            this.setState({
              showBasketIcon: false
            });
          }, animationConstants.time.buttonToBuyProduct);
        }
      );
    }
  };

  dropAnimationBlob = (key) => {
    const animationBlobs = this.state.animationBlobs.filter(
      (blob) => blob.id !== key
    );

    this.setState({
      animationBlobs,
      showBasketIcon: !!animationBlobs.length
    });
  };

  renderClosedStatusButton = (onClick, isShell, bigButton, style) => (
    <button
      ref={this.buttonPriceRef}
      onClick={onClick}
      className={classNames({
        'button-product-price': true,
        shell: isShell,
        disabled: true,
        'shop-closed': true,
        'big-button': bigButton
      })}
      style={{
        ...style,
        borderColor: style ? `${style.color}1A` : ''
      }}
    >
      <span style={{ opacity: style ? 0.3 : 1 }}>
        {this.props.t('product_view:shopIsClosed')}
      </span>
    </button>
  );

  render() {
    const {
      isHasSeveralSizes,
      isShell,
      onClick,
      style,
      t,
      children,
      hidePlus,
      bigButton,
      itemCount,
      disabled,
      isSoldOut,
      isClosed
    } = this.props;

    if (isClosed) {
      return this.renderClosedStatusButton(onClick, isShell, bigButton, style);
    }

    const { showBasketIcon } = this.state;

    let itemPrefix = '+';

    if (itemCount) {
      itemPrefix = `${itemCount}x`;
    } else if (isSoldOut) {
      itemPrefix = (
        <ReactSVG
          className="product-standard-button-icon-sold-out"
          src={images.iconSoldOut}
          beforeInjection={(svg) => {
            svg.setAttribute(
              'class',
              `${svg.getAttribute(
                'class'
              )} product-standard-button-icon-sold-out-image`
            );
          }}
        />
      );
    }

    return (
      <button
        ref={this.buttonPriceRef}
        onClick={onClick}
        className={classNames({
          'button-product-price': true,
          added: showBasketIcon,
          shell: isShell,
          disabled: disabled || isSoldOut,
          'big-button': bigButton,
          'same-item-in-basket': !!itemCount,
          'sold-out': isSoldOut
        })}
        style={style}
      >
        {!hidePlus || !bigButton ? (
          <i
            className={classNames({
              plus: true,
              'item-counter': !!itemCount,
              'sold-out': isSoldOut
            })}
            style={{ borderRightColor: style ? `${style.color}1A` : undefined }}
          >
            {itemPrefix}
          </i>
        ) : null}
        {isSoldOut ? (
          <span className="price">
            {bigButton ? itemPrefix : null}
            {t('product_view:soldOut')}
          </span>
        ) : (
          <span className="price">
            {isHasSeveralSizes && !bigButton
              ? `${t('product_view:lblFrom')} `
              : null}
            {children}
          </span>
        )}
        <i className="hover-layer fas fa-shopping-cart" />
        {bigButton && itemCount ? (
          <div className="big-item-counter">
            {`${itemCount}x`}
            <i className="hover-layer fas fa-shopping-cart" />
          </div>
        ) : null}
        {this.state.animationBlobs.map((blob) => blob.elem)}
      </button>
    );
  }
}

export default withTranslation(['product_view'])(ButtonProductPriceAnimation);
