import RootStore from 'client/stores';
import { action, toJS } from 'mobx';
import SlideModel from './slide.model';
import { ELEMENTS_TYPES } from '../enums/homepage_elements_types.enum';
import HomepageCategoryModel from './homepage_category.model';
import HomepageProductModel from './homepage_product.model';
import HomepageContentModel from './homepage_content.model';
import HomepageOfferModel from './homepage_offer.model';

/**
 * Homepage Element model
 */
export default class HomepageElementModel {
  root: RootStore;

  constructor(element, root) {
    Object.assign(this, element);
    this.root = root;
    this.id = element.id;
    this.type = element.type;
    this.hidePostalCodes = element.hidePostalCodes;

    switch (this.type) {
      case ELEMENTS_TYPES.SLIDER:
        this.slides = this.prepareSlides(element.slides || element.items || []);
        break;
      case ELEMENTS_TYPES.CATEGORIES:
        this.categories = this.prepareCategories(
          element.categories || element.items || []
        );
        break;
      case ELEMENTS_TYPES.PRODUCTS:
        this.products = this.prepareProducts(
          element.products || element.items || []
        );
        break;
      case ELEMENTS_TYPES.OFFERS:
        this.offers = this.prepareOffers(element.offers || element.items || []);
        break;
      case ELEMENTS_TYPES.TEXT_IMAGE:
        this.contentArray = this.prepareContentArray(
          element.contentArray || element.items || []
        );
        break;
    }
  }

  id;
  type = '';
  slides = [];
  categories = [];
  contentArray = [];
  products = [];
  offers = [];
  hidePostalCodes = false;

  /**
   * Mapping slides array to SlideModel
   * @param slides
   */
  @action prepareSlides(slides = []) {
    return slides.length > 0
      ? slides.map((slide) => new SlideModel(slide))
      : [];
  }

  /**
   * Mapping categories array to HomepageCategoryModel
   * @param categories
   */
  @action prepareCategories(categories = []) {
    return categories.length > 0
      ? categories.map((category) => new HomepageCategoryModel(category))
      : [];
  }

  /**
   * Mapping content array to HomepageContentModel
   * @param contentArray
   */
  @action prepareContentArray(contentArray = []) {
    return contentArray.length > 0
      ? contentArray.map((content) => new HomepageContentModel(content))
      : [];
  }

  /**
   * Mapping products array to HomepageProductModel
   * @param products
   */
  @action prepareProducts(products = []) {
    return products.length > 0
      ? products.map((product) => new HomepageProductModel(product))
      : [];
  }

  /**
   * Mapping content array to HomepageOfferModel
   * @param offers
   */
  @action prepareOffers(offers = []) {
    return offers.length > 0
      ? offers.map((offer) => new HomepageOfferModel(offer))
      : [];
  }

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  getToJS() {
    return Object.assign(toJS(Object.assign({}, this, { root: {} })), {
      slides: this.slides.map((slide) => slide.getToJS()),
      categories: this.categories.map((category) => category.getToJS()),
      contentArray: this.contentArray.map((contentItem) =>
        contentItem.getToJS()
      ),
      products: this.products.map((product) => product.getToJS()),
      offers: this.offers.map((offer) => offer.getToJS())
    });
  }
}
