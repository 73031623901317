import loadable from '@loadable/component';
import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';

import {
  MobileModalsRouteType,
  Modals
} from 'client/routes/MobileModalsRoute/MobileModalsRoute.type';

const AddressModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/mobile/AddressModal'
    )
);

const AllergensModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/mobile/AllergensModal'
    )
);

const CertificateModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/mobile/CertificateModal'
    )
);

const ContactFormModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/mobile/ContactFormModal'
    )
);

const DeleteConfirmationModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/mobile/DeleteConfirmationModal'
    )
);

const ImprintModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/mobile/ImprintModal'
    )
);

const OfferModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/OfferModal'
    )
);

const PrivacyModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/mobile/PrivacyModal'
    )
);

const ProductModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/ProductModal'
    )
);

const ProductExtrasModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/ProductExtrasModal'
    )
);

const TableReservationModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/mobile/TableReservationFormModal'
    )
);

const TermsModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/mobile/TermsModal'
    )
);

const ChangeAddressModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/mobile/ChangeAddressModal'
    )
);

const CookieModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/CookieModal'
    )
);

const modals: Modals = {
  addressModal: AddressModal,
  allergensModal: AllergensModal,
  certificateModal: CertificateModal,
  contactModal: ContactFormModal,
  deleteConfirm: DeleteConfirmationModal,
  extrasModal: ProductExtrasModal,
  imprintModal: ImprintModal,
  privacyModal: PrivacyModal,
  productModal: ProductModal,
  offerModal: OfferModal,
  termsModal: TermsModal,
  changeAddressModal: ChangeAddressModal,
  tableReservation: TableReservationModal,
  cookieModal: CookieModal
};

const MobileModalsRoute = () => {
  // TODO The app has a bug with useLocation
  // switched to window location object instead
  // Uncomment this line when routing is fixed and remove use of location from window
  // const { search } = useLocation();
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch(window.location.search);
  });

  const params = new URLSearchParams(search);
  const props: Record<string, any> = {};

  if (!params.has('modal')) {
    return null;
  }

  const modalType = params.get('modal') as MobileModalsRouteType;
  const Modal = modals[modalType];

  for (const [key, value] of params.entries()) {
    props[key] = value;
  }

  if (!Modal) {
    return null;
  }

  return <Modal {...props} />;
};

export default React.memo(MobileModalsRoute);
