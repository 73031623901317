import { Styles } from 'react-select';

const getStyles = (error?: string): Styles<{}, false> => ({
  control: (base) => ({
    ...base,
    height: '40px',
    borderRadius: '3px',
    border: error ? 'solid 1px #e00e00' : 'solid 1px #d9d9d9',
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    position: 'relative',
    zIndex: 1,

    '&:hover': {
      borderColor: error ? 'solid 1px #e00e00' : 'solid 1px #d9d9d9'
    }
  }),
  clearIndicator: (base) => ({
    ...base
  }),
  dropdownIndicator: (base) => ({
    ...base
  }),
  indicatorsContainer: (base) => ({
    ...base,
    marginRight: 5
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  input: (base) => ({
    ...base,
    opacity: 0.7,
    fontFamily: 'Roboto, sans-serif',
    fontSize: 'inherit',
    fontWeight: 'normal',
    fonStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#1b1b1b',
    width: '100%'
  }),
  menu: (base) => ({
    ...base,
    zIndex: 0,
    position: 'absolute',
    top: 'calc(100% - 15px)',
    paddingTop: '10px',
    width: 'calc(100% - 2px)',
    left: '1px'
  }),
  option: (base, props) => ({
    ...base,
    opacity: 0.7,
    fontFamily: 'Roboto, sans-serif',
    fontSize: 'inherit',
    fontWeight: 'normal',
    fonStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#1b1b1b',
    backgroundColor: props.isFocused ? '#f6f6f6' : '#ffffff'
  }),
  placeholder: (base) => ({
    ...base,
    opacity: 0.3,
    fontFamily: 'Roboto, sans-serif',
    fontSize: 'inherit',
    fontWeight: 'normal',
    fonStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#1b1b1b'
  }),
  singleValue: (base) => ({
    ...base,
    opacity: 0.7,
    fontFamily: 'Roboto, sans-serif',
    fontSize: 'inherit',
    fontWeight: 'normal',
    fonStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#1b1b1b'
  })
});

export { getStyles };
