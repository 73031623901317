import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

// Components
import DiscountPercentageMobile from '../../../../../components/discount_block';

import './styles.scss';
import Coupons from '../../../../../components/coupons';

/**
 * Basket info shell component,
 * placed in bottom part of the basket
 */
class BasketShellInfo extends PureComponent {
  static propTypes = {
    isShell: PropTypes.bool,
    mbvReached: PropTypes.bool,
    isDelivery: PropTypes.bool,
    discount: PropTypes.number.isRequired,
    discountValue: PropTypes.string,
    mbv: PropTypes.string,
    total: PropTypes.string,
    valueWithoutFee: PropTypes.string,
    deliveryFee: PropTypes.number,
    deliveryFreeFrom: PropTypes.string,
    fees: PropTypes.string,
    orderSum: PropTypes.string,
    hasMinPrice: PropTypes.bool,
    discountStyle: PropTypes.object
  };

  static defaultProps = {};

  /**
   * Method to render discount bar in shell basket
   * @returns {null}
   * @private
   */
  _renderDiscount = () =>
    this.props.discount > 0 ? (
      <DiscountPercentageMobile
        discount={this.props.discount}
        discountValue={this.props.discountValue}
        isShell={this.props.isShell}
        style={this.props.discountStyle}
      />
    ) : null;

  /**
   * Method to render mbv
   * should be displayed when order price lower then mbv and branch got mbv on its orders
   * @returns {null}
   * @private
   */
  _renderMbv = () =>
    !this.props.mbvReached ? (
      <div className="line mbv" data-testid="mbv-reached-text-basket">
        {this.props.t('minOrderValueNotReached', { amount: this.props.mbv })}
      </div>
    ) : (
      <div className="line mbv reached" data-testid="min-free-from-basket">
        <i className="fas fa-check" />
        {this.props.t('minOrderValueIsReached', { amount: this.props.mbv })}
      </div>
    );

  /**
   * Method to render total order price
   * @returns {*}
   * @private
   */
  _renderTotal = () => (
    <div className="line total">
      <div className="name" data-testid="fin-amount-title-basket">
        {this.props.t('total')}
      </div>
      <div className="price" data-testid="final-amount-basket">
        {this.props.total}
      </div>
    </div>
  );

  /**
   * Method to render order price with no fees
   * @returns {*}
   * @private
   */
  _renderValueWithoutFee = () => (
    <div className="line order-value">
      <div className="name" data-testid="amount-with-dis-title-basket">
        {this.props.t('orderPrice')}
      </div>
      <div className="price" data-testid="amount-with-discount-value-basket">
        {this.props.valueWithoutFee}
      </div>
    </div>
  );

  /**
   * Method to render fees in bottom part of the shell basket
   * should be displayed only if user picked delivery
   * @returns {null}
   * @private
   */
  _renderFees = () => {
    const { deliveryFee, fees, isDelivery, deliveryFreeFrom, t } = this.props;

    const freeFromText = deliveryFreeFrom
      ? t('delivery_info:freeFrom', {
          cost: deliveryFreeFrom
        })
      : '';

    const feeText = t('deliveryFeeSplit', {
      freeFromText
    });

    return isDelivery && deliveryFee > 0 ? (
      <div className="line fees">
        <div className="name" data-testid="min-free-from-basket">
          {feeText}
        </div>
        <div className="price" data-testid="delivry-price-basket">
          {fees}
        </div>
      </div>
    ) : null;
  };

  /**
   * Method to render order sum with no discount or fee applied
   * @returns {*}
   * @private
   */
  _renderOrderSum = () => (
    <div className="line order-sum">
      <div className="name">{this.props.t('orderPrice')}</div>
      <div className="price">{this.props.orderSum}</div>
    </div>
  );

  /**
   * Method to render basket info in shell basket
   * @returns {*}
   * @private
   */
  _renderInfo = () => {
    const { deliveryFee, discount, isDelivery, hasMinPrice } = this.props;

    return (
      <div className="info">
        {discount || (isDelivery && deliveryFee)
          ? this._renderValueWithoutFee()
          : null}
        {hasMinPrice ? this._renderMbv() : null}
        {this._renderFees()}
        {this._renderTotal()}
      </div>
    );
  };

  render() {
    const { deliveryFee, discount, isDelivery } = this.props;

    return (
      <div className="shell-basket-info">
        {discount || (isDelivery && deliveryFee)
          ? null
          : this._renderOrderSum()}
        {this._renderDiscount()}
        <Coupons />
        {this._renderInfo()}
      </div>
    );
  }
}

export default withTranslation(['basket', 'coupons', 'common'])(
  BasketShellInfo
);
