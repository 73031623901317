/* eslint-disable import/no-cycle */
import { action, observable } from 'mobx';

import RootStore from 'client/stores';
import {
  getFavoritesState,
  setFavoritesState
} from 'client/utils/native-apps-communication';

export default class FavoritesStore {
  private api: any;

  private root: RootStore;

  constructor(root: RootStore, state: FavoritesStore, api: any) {
    Object.assign(this, state, { api });

    this.api = api;

    this.root = root;
  }

  @observable public isAddedToFavorites = false;

  @action public setInitialFavoritesState(state: boolean) {
    this.isAddedToFavorites = state;
  }

  @action public inverseFavoritesState() {
    this.isAddedToFavorites = !this.isAddedToFavorites;

    this.setFavoritesStateOnDevice(this.isAddedToFavorites);
  }

  public getFavoritesStateFromDevice = (branchId: string) => {
    getFavoritesState(branchId);
  };

  private setFavoritesStateOnDevice = (state: boolean) => {
    setFavoritesState(this.root.restaurantStore.branch.branchId, state);
  };
}
