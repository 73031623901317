import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { Component, MouseEvent, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Truncate from 'react-truncate';

import AdditiveIconList from 'client/components/AdditiveIconList';
import { ReactComponent as Info } from 'client/icons/info.svg';
import { ReactComponent as NoDiscount } from 'client/icons/no-discount.svg';
import { ReactComponent as NoMbv } from 'client/icons/no-mbv.svg';
import Heading from 'client/ui/Heading';
import ProductNumber from 'client/ui/ProductNumber';

import BigProductButton from '../../../../buttons/BigProductButton/BigProductButton';
import ProductDefaultImage from '../product-default-image/ProductDefaultImage';
import ProductMetaData from '../product-seo';
import { IProductBigPictures } from '../ProductCell.props.interface';
import './ProductBigPictureVariant1.scss';
import ProductNewBadge from '../ProductNewBadge/product-new-badge';

interface IState {
  width: number;
  imageLoaded: boolean;
}

@observer
class ProductBigPictureVariant1 extends Component<IProductBigPictures, IState> {
  constructor(props: IProductBigPictures) {
    super(props);

    this.state = {
      width: -1,
      imageLoaded: false
    };
  }

  containerRef = createRef<HTMLDivElement>();

  componentDidMount() {
    if (this.containerRef.current && this.props.setSize) {
      const { marginBottom, marginTop } = getComputedStyle(
        this.containerRef.current
      );

      this.props.setSize(
        this.containerRef.current.getBoundingClientRect().height +
          parseFloat(marginBottom) +
          parseFloat(marginTop)
      );
    }
  }

  onButtonClick = (event: MouseEvent) => {
    this.props.onButtonClick(event, this.props.product);
  };

  onCellClick = (event: MouseEvent) => {
    this.props.onCellClick(event, this.props.product);
  };

  updateImageLoadingStatus = (success: boolean) => {
    this.setState({
      imageLoaded: success
    });
  };

  handleInfoClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    const { openProductModal, product } = this.props;

    openProductModal(event, product);
  };

  render() {
    const {
      isShopClosed,
      product,
      isNew,
      foodIcons,
      isUseAdvancedProductView,
      animated,
      productCount,
      animationTargetBounds,
      showHalalCertificate,
      isMobile,
      isShell,
      currency,
      buttonStyle,
      productCellStyle
    } = this.props;

    const showMoreButton = isUseAdvancedProductView && !!product.description;
    const moreButtonText = this.props.t && this.props.t('product_view:lblMore');

    let buttonText = '';

    if (product.isValidForOrder) {
      buttonText = `${
        this.props.t &&
        this.props.t('product_view:toAdd', {
          from: product.hasSeveralSizes
            ? `${this.props.t && this.props.t('product_view:lblFrom')} `
            : '',
          price: product.getIntlPrice
        })
      }`;
    } else {
      buttonText = this.props.t
        ? this.props.t('common:productNotAvailable')
        : '';
    }

    const isResponsiveView = isMobile && !isShell;

    const templatingStyle = productCellStyle
      ? {
          headerTextColor: productCellStyle.headerTextColor,
          descriptionOpacity: productCellStyle.descriptionOpacity,
          defaultPictureIconColor: productCellStyle.defaultPictureIconColor,
          defaultPictureBackgroundColor:
            productCellStyle.defaultPictureBackgroundColor
        }
      : {};

    const defaultImage = (
      <ProductDefaultImage
        iconColor={templatingStyle.defaultPictureIconColor}
      />
    );

    return (
      <div
        ref={this.containerRef}
        className={classNames({
          'big-picture-variant1-container': true,
          'is-mobile': isResponsiveView,
          'is-closed': isShopClosed
        })}
        onClick={this.onCellClick}
      >
        <div className="big-picture-variant1-content-wrapper">
          <div
            className="big-picture-variant1-image-container"
            data-testid="product"
          >
            {isNew && isNew.value && (
              <ProductNewBadge
                text={this.props.t && this.props.t('product_view:lblNew')}
                style={isNew.style}
              />
            )}
            <div
              className={classNames({
                'big-picture-variant1-image-wrapper': true,
                loading: !this.state.imageLoaded,
                'default-image': !product.pic_url
              })}
              style={{
                backgroundColor: templatingStyle.defaultPictureBackgroundColor
              }}
            >
              <LazyLoadComponent
                placeholder={defaultImage}
                afterLoad={() => this.updateImageLoadingStatus(true)}
              >
                {product.pic_url ? (
                  <img
                    src={product.pic_url}
                    alt={product.name}
                    data-testid="product-image"
                  />
                ) : (
                  defaultImage
                )}
              </LazyLoadComponent>
            </div>
            {foodIcons && !!Object.keys(foodIcons).length && (
              <AdditiveIconList
                className="food-icons-wrapper"
                additives={foodIcons}
                onHalalClick={showHalalCertificate}
              />
            )}
          </div>
          <div
            className="big-picture-variant1-text-container"
            style={{ color: templatingStyle.headerTextColor }}
          >
            <div className="productInfo">
              <ProductNumber value={product.no} className="number" />
              {product.count_mbw !== 1 && <NoMbv />}
              {!product.isHideDiscountBadge && <NoDiscount />}
              <Heading
                variant="h5"
                className="productName"
                data-testid="product-title"
              >
                {product.name}
              </Heading>
              <Info className="infoIcon" onClick={this.handleInfoClick} />
            </div>
            <div
              className="product-description"
              style={{ opacity: templatingStyle.descriptionOpacity }}
              data-testid="product-description"
            >
              {isResponsiveView ? (
                product.description
              ) : (
                <Truncate
                  lines={2}
                  trimWhitespace
                  ellipsis={
                    showMoreButton ? (
                      <span>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        … <a className="more-button">{`(${moreButtonText})`}</a>
                      </span>
                    ) : (
                      '…'
                    )
                  }
                >
                  {product.description}
                </Truncate>
              )}
            </div>
          </div>
        </div>
        <BigProductButton
          isClosed={isShopClosed}
          onClick={this.onButtonClick}
          text={buttonText}
          animated={animated}
          productCount={productCount}
          animationTargetBounds={animationTargetBounds}
          isSoldOut={product.soldOut}
          disabled={!product.isValidForOrder}
          mobileView={isMobile}
          buttonStyle={buttonStyle}
          isShell={isShell}
        />
        <ProductMetaData product={product} currency={currency} />
      </div>
    );
  }
}

export default withTranslation('product_view')(ProductBigPictureVariant1);
