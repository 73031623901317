import { action, computed, observable, toJS } from 'mobx';

import { FoodIcons, ProductPreview } from 'client/api/types';
import RootStore from 'client/stores';

import { isProduction } from '../../config';
import { convertHttpToHttps } from '../utils/functions';

import { IAdditives } from './category_menu.model.type';
import { ProductModel } from './product.model';

/**
 * Category model class. Used to generate categories.
 * @constructor
 * @param {object} category - category object.
 * @property {number} id - Unique identifier.
 * @property {string} name - Name.
 * @property {string} description - Description.
 * @property {string} picurl - URL of the product's picture with HTTP protocol.
 * @property {string} picurl_preview - URL of the product's picture preview with HTTP protocol.
 * @property {number} pos - Index position of the category.
 * @property {boolean} active - Observable active category.
 * @property {array} subCategories - Observable sub categories.
 * @property {array} products - Products of category.
 * @property {array} seo_categories - Array of common related categories.
 * @property {bool} loaded - If category products has been already loaded.
 * @property {bool} loading - If category products id loading now
 */
export class CategoryModel {
  root: RootStore;

  id: number;

  name: string;

  description: string | null;

  picurl: string;

  picurl_preview: string;

  pos = 0;

  width = -1;

  subCategories: CategoryModel[] = [];

  seo_categories: unknown[] = [];

  previewProducts: ProductPreview[];

  food_icons: FoodIcons = {};

  showPicture = false;

  constructor(category, root) {
    Object.assign(this, category);

    this.root = root;

    this.id = category.id;

    this.name = category.name;

    this.description = category.description ? category.description.trim() : '';

    this.picurl = isProduction
      ? convertHttpToHttps(category.picurl)
      : category.picurl;

    this.picurl_preview = category.picurl_preview;

    this.pos = category.pos;

    this.seo_categories = category.seo_categories;

    this.active = category.active;

    this.previewProducts = category.preview_products;

    this.food_icons = category.food_icons;

    if (category.subCategories && category.subCategories.length > 0) {
      this.subCategories = category.subCategories.map(
        (subCategory) => new CategoryModel(subCategory, root)
      );
    } else if (category.sub_categories && category.sub_categories.length) {
      this.subCategories = category.sub_categories.map(
        (subCategory) => new CategoryModel(subCategory, root)
      );
    }

    if (category.products && category.products.length > 0) {
      this.products = category.products.map(
        (p) => new ProductModel(p.id, p, this.root)
      );
    }

    this.showPicture = category.is_show_picture;
  }

  @observable products = [];

  @observable active = false;

  @observable loaded = false;

  @observable loading = false;

  /**
   * Metod to set category loading state
   * @param {bool} flag - flag state
   * @memberof CategoryModel#
   * @method setLoading
   */
  @action setLoading(flag) {
    this.loading = flag;
  }

  /**
   * Metod to set category loaded state
   * @param {bool} flag - flag state
   * @memberof CategoryModel#
   * @method setLoaded
   */
  @action setLoaded(flag) {
    this.loaded = flag;
  }

  /**
   * Method to get active category
   * @return {string} get seo category description
   * @memberof CategoryModel#
   * @method seoCategory
   */
  @computed get seoCategory() {
    return this.seo_categories && this.seo_categories.length > 0
      ? this.seo_categories[0]
      : '';
  }

  /**
   * Method to check if category has products
   * @return {bool} check state
   * @memberof CategoryModel#
   * @method hasProducts
   */
  @computed get hasProducts() {
    return this.products.length > 0;
  }

  /**
   * Method to get category additives
   * @return {object}
   * @method categoryAdditives
   */
  @computed get categoryAdditives() {
    const currentAdditives = this.root.additivesStore.additivesCategories.find(
      (additivesCategory) => additivesCategory.id === this.id
    );

    if (currentAdditives) {
      const { products, ...additives } = currentAdditives;

      return additives;
    }

    return undefined;
  }

  @computed get hasCategoryImage() {
    return this.showPicture;
  }

  @computed get someProductHasImage() {
    return this.products?.some((product) => !!product.pic_url);
  }

  /**
   * Getter for checking is  subcategories exists
   * @return {boolean}
   */
  @computed get hasSubCategories() {
    return !!this.subCategories.length;
  }

  /**
   * Method to serialize category to pure JS object.
   * @return {object} - serialized category.
   */
  getToJS() {
    return Object.assign(toJS({ ...this, root: {} }), {
      subCategories: this.subCategories.map((subCategory) =>
        subCategory.getToJS()
      ),
      products: this.products.map((product) => product.getToJS())
    });
  }
}
