import React from 'react';
import ReactSVG from 'react-svg';

// Enums
import images from '../../../../../../../enums/images_enums/hermes_images.enum';

interface IProps {
  iconColor?: string;
}

const ProductDefaultImage = (props: IProps) => (
  <ReactSVG
    className="default-image-wrapper"
    src={images.napkinIcon}
    beforeInjection={(svg) => {
      if (props.iconColor) {
        const iconParts = svg.getElementsByTagName('path');

        for (let i = 0; i < iconParts.length; i++) {
          iconParts[i].setAttribute('style', `fill: ${props.iconColor};`);
        }
      }
    }}
  />
);

export default ProductDefaultImage;
