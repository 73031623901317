/* eslint-disable global-require */

/**
 * Payment id to image matching
 */
export default [
  {
    id: 1,
    image: require('../../assets/images/payment_methods/bar.png'),
    title: 'Bar'
  },
  {
    id: 2,
    image: require('../../assets/images/payment_methods/electronic-cash.png'),
    title: 'EC-Karte (vor Ort)'
  },
  {
    id: 3,
    image: require('../../assets/images/payment_methods/cards.png'),
    title: 'Online-Zahlung'
  },
  {
    id: 4,
    image: require('../../assets/images/payment_methods/cards.png'),
    title: 'Instore (bar, EC- und Kreditkarte)'
  },
  {
    id: 5,
    image: require('../../assets/images/payment_methods/paypal.png'),
    title: 'PayPal'
  },
  {
    id: 6,
    image: require('../../assets/images/payment_methods/electronic-cash.png'),
    title: 'EC-Karte'
  },
  {
    id: 7,
    image: require('../../assets/images/payment_methods/electronic-cash.png'),
    title: 'EC-Karte (nur bei Lieferung!)'
  },
  {
    id: 8,
    image: require('../../assets/images/payment_methods/payu.png'),
    title: 'Online-Zahlung'
  },
  {
    id: 10,
    image: require('../../assets/images/payment_methods/restaurant-card.svg'),
    title: 'Ticket Restaurant'
  },
  {
    id: 11,
    image: require('../../assets/images/payment_methods/cards.png'),
    title: 'Kreditkarte'
  },
  {
    id: 12,
    image: require('../../assets/images/payment_methods/cards.png'),
    title: 'Kreditkarte bei Lieferung'
  },
  {
    id: 13,
    image: require('../../assets/images/payment_methods/cards.png'),
    title: 'Kreditkarte bei Abholung'
  },
  {
    id: 16,
    image: require('../../assets/images/payment_methods/paypal.png'),
    title: 'PayPal2'
  }
];
