/**
 * Enum contains template variables types
 * @type {{COMPLEMENTARY3: string, POSITIVE: string, COMPLEMENTARY2: string, SHADE2: string, SHADE1: string, ANALOGOUS: string, NEGATIVE: string, COMPLEMENTARY1: string, BRAND: string}}
 */
module.exports = {
  BRAND: 'brandColor',
  BRAND2: 'brand2Color',
  SHADE1: 'shade1Color',
  SHADE2: 'shade2Color',
  SHADE3: 'shade3Color',
  ANALOGOUS: 'analogousColorValue',
  NEGATIVE: 'negativeColor',
  POSITIVE: 'positiveColor',
  POSITIVE2: 'positive2Color',
  COMPLEMENTARY1: 'complementaryColor1',
  COMPLEMENTARY2: 'complementaryColor2',
  COMPLEMENTARY3: 'complementaryColor3',
  SHELLAPPLOGOSIZE: 'shellAppLogoSize',
  SHELLAPPBACKGROUNDSTARTSIZE: 'shellAppBackgroundStartSize',
  SHELLAPPBACKGROUNDCATEGORIESSIZE: 'shellAppBackgroundCategoriesSize',
  SHELLAPPLOGOMARGIN: 'shellAppLogoMargin',
  SHELLAPPLOGOPADDING: 'shellAppLogoPadding',
  SHELLAPPLOGO: 'ShellAppLogo',
  SHELLAPPCATEGORIESBACKGROUND: 'ShellAppCategoriesBackground',
  SHELLAPPHOMEBACKGROUND: 'ShellAppHomeBackground',
  SHELLCATEGORIESTYPE: 'shellCategoriesType'
};
