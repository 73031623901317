import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

// CSS
import './_coupons.scss';

// Components
import ButtonStandard from '../buttons/ButtonStandard';

import CouponBasketItem from './_coupon_basket_item';
import CouponForm from './_coupon_form';

/**
 * Coupons component is used to show
 * interface for entering of coupon
 * @name ReactCouponsComponent
 */
@inject('store')
@observer
class Coupons extends Component {
  constructor(props) {
    super(props);

    if (!this.props.store.couponsStore.isCouponValid) {
      this.props.store.couponsStore.isReedemCouponActive = false;
    }
  }

  changeCouponCode(val) {
    this.props.store.couponsStore.changeCouponCode(val);
  }

  validateCouponAction(val) {
    this.props.store.couponsStore.validateCouponAction(val);
  }

  removeCouponAction() {
    this.props.store.couponsStore.removeCouponAction();
  }

  /**
   * Render "Reedem Coupon" button
   * @returns {*}
   * @private
   */
  _renderReedemButton() {
    const standardButtonStyle = this.props.store.themesStore.standardButtonStyle();

    const buttonStyle = standardButtonStyle
      ? {
          ...standardButtonStyle,
          border: `1px solid ${standardButtonStyle.color}1A`
        }
      : undefined;

    return (
      <div id="coupon-reedem-component">
        <ButtonStandard
          id="reedem-button"
          primary
          isShell={this.props.store.themesStore.isShellThemeActive}
          onClick={() => this.props.store.couponsStore.activateEnterCoupon()}
          label={this.props.t('btnRedeemCoupon')}
          style={buttonStyle}
          dataTestId="basket-coupon-btn"
        />
      </div>
    );
  }

  /**
   * Render coupon enter field or coupon info
   * @returns {*}
   * @private
   */
  _renderCoupon() {
    const {
      isShowInvalidCoupon,
      loading,
      couponCode,
      isCouponValid,
      coupon,
      isMbvNotAvailable,
      isValidationPassed
    } = this.props.store.couponsStore;

    const mbvPrice = this.props.store.basketStore.getIntlPrice(coupon.mbv);
    const { isShellThemeActive } = this.props.store.themesStore;

    return isCouponValid && isValidationPassed ? (
      <CouponBasketItem
        filteredCouponValue={this.props.store.couponsStore.filteredCouponValue}
        calculateCouponSaleValueWithOffers={
          this.props.store.couponsStore.calculateCouponSaleValueWithOffers
        }
        coupon={coupon}
        removeCouponAction={this.removeCouponAction.bind(this)}
        getIntlPrice={(price) =>
          this.props.store.basketStore.getIntlPrice(price)
        }
      />
    ) : (
      <CouponForm
        clearCoupon={() => this.props.store.couponsStore.clearCoupon()}
        isShowInvalidCoupon={isShowInvalidCoupon}
        loading={loading}
        couponCode={couponCode}
        changeCouponCode={this.changeCouponCode.bind(this)}
        validateCouponAction={this.validateCouponAction.bind(this)}
        removeCouponAction={this.removeCouponAction.bind(this)}
        isShellThemeActive={isShellThemeActive}
        isMbvNotAvailable={isMbvNotAvailable}
        couponMbv={mbvPrice}
      />
    );
  }

  _renderFeature() {
    return this.props.store.couponsStore.isReedemCouponActive
      ? this._renderCoupon()
      : this._renderReedemButton();
  }

  render() {
    return this.props.store.restaurantStore.branch.branchHasVouchers
      ? this._renderFeature()
      : null;
  }
}

export default withTranslation(['coupons'])(Coupons);
