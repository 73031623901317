// Imports
import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import SlickCarousel from '../../../../../../../components/SlickCarousel';
// Styles
import './styles.scss';
import ButtonProductPriceAnimation from '../../../../buttons/ButtonProductPriceAnimation';
import { withTranslation } from 'react-i18next';
import { ELEMENTS_TYPES } from '../../../../../../../enums/homepage_elements_types.enum';
import { createHomepageHrefLink } from '../../../../../../../utils/functions';

/**
 * Component to render categories on mobile homepage
 */
@inject('store')
@withRouter
@observer
class HomepageProductsMobile extends React.Component {
  /**
   * Method to add product to basket
   * @param {number} productId
   */
  _onProductClick = (productId, disabled) => {
    const { history } = this.props;

    !disabled &&
      this.props.store.productsStore.addProductById(productId, history);
  };

  /**
   * Method for getting product price button label
   * @param product
   * @returns {*}
   */
  _productPriceLabel = (product) => {
    const { currencyCode } = this.props.store.restaurantStore.branch;
    const isDelivery = this.props.store.deliveryAddressStore.isDelivery;
    const language = this.props.store.restaurantStore.restaurant.getLang;
    return `${this.props.t('product_view:lblFrom')} ${product.priceLabel(
      isDelivery,
      language,
      currencyCode
    )}`;
  };

  /**
   * Method to render single product
   * @param item - product object
   * @param key
   * @param {number} animateId - animated product id
   * @returns {*}
   * @private
   */
  _renderItem = (item, key, animateId) => {
    const { isDelivery } = this.props.store.deliveryAddressStore;
    const { isShopClosed } = this.props.store.restaurantStore;
    const productCount =
      this.props.store.basketStore.products
        .filter((productItem) => productItem.product.id === item.product_id)
        .reduce((count, productItem) => count + productItem.count, 0) || null;
    const disabled = item.price(isDelivery) === 0;
    return (
      <div
        className="item"
        key={key}
        onClick={this._onProductClick.bind(this, item.product_id, disabled)}
      >
        <div className="item-header">
          <div className="item-title">{item.name}</div>
        </div>
        <div className="img-wrapper">
          {item.picUrl && <img src={item.picUrl} alt={item.name} />}
        </div>
        <div className="item-footer">
          <ButtonProductPriceAnimation
            animated={animateId === item.product_id}
            disabled={disabled}
            itemCount={productCount}
            isClosed={isShopClosed}
          >
            {this._productPriceLabel(item)}
          </ButtonProductPriceAnimation>
        </div>
      </div>
    );
  };

  render() {
    const animateId = this.props.store.productsStore.animateId;

    const settings = {
      infinite: true,
      slidesToScroll: 1,
      arrows: false,
      variableWidth: true,
      slide: '.item'
    };

    return (
      <section
        className="mobile-homepage-section mobile-homepage-products"
        id={createHomepageHrefLink([ELEMENTS_TYPES.PRODUCTS, this.props.index])}
      >
        <h2>{this.props.title}</h2>
        <SlickCarousel settings={settings}>
          {this.props.products.map((item, index) => {
            return this._renderItem(item, index, animateId);
          })}
        </SlickCarousel>
      </section>
    );
  }
}
export default withTranslation(['product_view', 'common'])(
  HomepageProductsMobile
);
