import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class BranchInfoDiscountMobile extends PureComponent {
  static propTypes = {
    discountPercentage: PropTypes.number.isRequired
  };

  render() {
    const { discountPercentage } = this.props;
    const discountAmount = discountPercentage.toString().replace('.', ',');
    return (
      <div className="discount">
        <i className="mdi mdi-octagram"></i>
        <span>{this.props.t('discount', { amount: discountAmount })}</span>
      </div>
    );
  }
}
export default withTranslation(['basket'])(BranchInfoDiscountMobile);
