import classnames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { cantAddProductsToBasket } from 'client/utils/routing';

import OfferCell from '../_item_cell/OffersCellHermesTheme';

const HermesThemeOfferList = ({
  offers,
  onOfferClick,
  currency,
  animateId,
  currentOrderTypeHasPreorder,
  language,
  buttonProductPriceStyle,
  separatorStyle,
  isShell = false
}) => {
  const location = useLocation();
  const isBlocked = cantAddProductsToBasket({ location });

  return (
    <div
      className={classnames('hermes-products-wrapper', isBlocked && 'blocked')}
    >
      {offers.map((offer) => (
        <OfferCell
          key={offer.id}
          offer={offer}
          onOfferClick={onOfferClick}
          currentOrderTypeHasPreorder={currentOrderTypeHasPreorder}
          currency={currency}
          animateId={animateId}
          isShell={isShell}
          language={language}
          buttonProductPriceStyle={buttonProductPriceStyle}
          separatorStyle={separatorStyle}
        />
      ))}
    </div>
  );
};

export default React.memo(HermesThemeOfferList);
