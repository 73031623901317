import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import App from './app';

@withRouter
export default class MainRoute extends React.Component {
  render() {
    return <Route path="/" component={App} />;
  }
}
