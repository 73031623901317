import { BasketRequestModel } from './basket_request.model';

export class OfferRequestModel {
  o;

  xt = 0;

  q;

  p = [];

  price: number;

  v;

  vh;

  v2;

  v2h;

  constructor(offerGroup, root) {
    const { offer, count } = offerGroup;

    this.o = offer.id;

    this.q = count;

    let products = [];

    if (offer.isFreeOffer) {
      products = offer.products
        .filter((product) => product.count > 0)
        .map(
          (product) => new BasketRequestModel({ product, count: product.count })
        );
    } else {
      offer.groups.forEach((group) => {
        group.cachedProducts.forEach((product) => {
          if (product.count > 0) {
            products.push(
              new BasketRequestModel({ product, count: product.count })
            );
          }
        });
      });
    }

    this.p = products;

    this.price = offer.totalPrice;

    const int_part = Math.floor(offer.totalPrice);

    const fraction_part =
      parseFloat((offer.totalPrice - int_part).toFixed(2)) * 100;

    const int_encrypted = this.c8e2Ag1(
      int_part,
      root.restaurantStore.restaurant.getToken
    );

    const fruction_encrypted = this.c8e2Ag1(
      fraction_part,
      root.restaurantStore.restaurant.getToken
    );

    this.v = int_encrypted.n;

    this.vh = int_encrypted.h;

    this.v2 = fruction_encrypted.n;

    this.v2h = fruction_encrypted.h;
  }

  c12v8(char: string) {
    return char.charCodeAt(0);
  }

  c8e2Ag1(w8pFb, t8gB3a) {
    let wu765e = w8pFb;
    let h2o = 0;

    for (let p = 0; p < t8gB3a.length; p++) {
      wu765e ^= this.c12v8(t8gB3a[p]);

      if (p === 4) h2o = wu765e << this.c12v8(t8gB3a[p]);
    }

    return { n: wu765e, h: h2o };
  }
}
