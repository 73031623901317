import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import classNames from 'classnames';
import ReactSVG from 'react-svg';

import images from '../../../../../enums/images_enums/hermes_images.enum';
import './ModalHeader.scss';

/**
 * Common modal's header component
 */
@observer
export default class ModalHeader extends Component {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    closeAction: PropTypes.func,
    isHideCloseButton: PropTypes.bool,
    isHideBackButton: PropTypes.bool,
    backButtonAction: PropTypes.func,
    isMobile: PropTypes.bool,
    isShowHouseIcon: PropTypes.bool,
    cssClass: PropTypes.string,
    style: PropTypes.object,
    dataTestIdDescription: PropTypes.string
  };

  static defaultProps = {
    title: '',
    description: '',
    isHideCloseButton: false,
    isHideBackButton: false,
    isMobile: false,
    isShowHouseIcon: false
  };

  /**
   *  Method to render back button in modal
   * */
  showBackButton() {
    const { isHideBackButton, backButtonAction } = this.props;

    return !isHideBackButton && backButtonAction ? (
      <i className="back-button" onClick={backButtonAction} />
    ) : (
      <div className="spacer left" />
    );
  }

  /**
   *  Method to render close button in modal
   * */
  showCloseButton() {
    const { isHideCloseButton, closeAction } = this.props;

    return !isHideCloseButton && closeAction ? (
      <i className="close-button" onClick={closeAction} />
    ) : (
      <div className="spacer" />
    );
  }

  /**
   *  Method to render title in modal
   * */
  showTitle() {
    const { title } = this.props;

    return title ? (
      <h2
        className="modal-title"
        style={this.props.style ? this.props.style.title : undefined}
      >
        <div dangerouslySetInnerHTML={{ __html: title }} />
      </h2>
    ) : null;
  }

  /**
   *  Method to render house icon in modal
   * */
  showHouseIcon() {
    return this.props.isShowHouseIcon ? (
      <ReactSVG
        src={images.houseIcon}
        beforeInjection={(svg) => {
          svg.setAttribute('class', `${svg.getAttribute('class')} house-icon`);
        }}
      />
    ) : null;
  }

  /**
   *  Method to render description in modal
   * */
  showDescription() {
    const { description, dataTestIdDescription } = this.props;

    return description ? (
      <div
        className="modal-header-description"
        style={this.props.style?.title}
        data-testid={dataTestIdDescription}
      >
        {description}
      </div>
    ) : null;
  }

  render() {
    const { isMobile } = this.props;

    return (
      <>
        <header
          className={classNames({
            'modal-header': true,
            desktop: !isMobile
          })}
          style={this.props.style?.title}
        >
          <div className={`modal-controls ${this.props.cssClass || ''}`}>
            {this.showBackButton()}
            {this.showHouseIcon()}
            {this.showTitle()}
            {this.showCloseButton()}
          </div>
        </header>
        {this.showDescription()}
      </>
    );
  }
}
