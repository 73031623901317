import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';

// CSS
import './ButtonShoppingCartAnimated.scss';

//Enums
import states from '../../../../enums/states.enum';
import {IS_CLIENT} from 'config';

@inject('store')
@observer
class ButtonShoppingCartAnimated extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    openBasketAction: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.basketButtonRef = createRef();
  }

  componentDidMount() {
    this.setComponentCoordinates();
    IS_CLIENT &&
      window.addEventListener('resize', this.setComponentCoordinates);
  }

  componentWillUnmount() {
    IS_CLIENT &&
      window.removeEventListener('resize', this.setComponentCoordinates);
  }

  setComponentCoordinates = () => {
    if (this.basketButtonRef.current) {
      const bounds = this.basketButtonRef.current.getBoundingClientRect();
      this.props.store.basketStore.updateBounds({
        id: states.basket,
        type: states.basket,
        bounds: {
          top: bounds.top + pageYOffset,
          left: bounds.left + pageXOffset,
          width: bounds.width
        }
      });
    }
  };

  render() {
    return (
      <div
        className="shopping-cart-panel-wrapper mobile"
        onClick={this.props.openBasketAction}
      >
        <div id="shopping-cart-panel" ref={this.basketButtonRef}>
          <div className="info">
            <div className="icon mdi mdi-cart"></div>
            <span>{this.props.t('shoppingCart')}</span>
          </div>
          <div className="price">{this.props.label}</div>
        </div>
      </div>
    );
  }
}
export default withTranslation(['basket'])(ButtonShoppingCartAnimated);
