type Errors<T> = {
  [P in keyof T]?: any;
};

const getValidFormData = <T>(data: T, errors: Errors<T>, defaultValues: T) => {
  const validData = { ...data };

  (Object.keys(errors) as Array<keyof T>).forEach((key) => {
    validData[key] = defaultValues[key];
  });

  return validData;
};

export default getValidFormData;
