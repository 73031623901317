/**
 * List with types of homepage elements
 */
export enum ELEMENTS_TYPES {
  SLIDER = 'slider',
  OFFERS = 'offers',
  PRODUCTS = 'products',
  CATEGORIES = 'categories',
  TEXT_IMAGE = 'textImage',
  AREA_CODE = 'areaCodes',
  SEO_TEXT = 'seoText',
  OPENING_TIMES = 'openingTimes'
}
