import { action, toJS } from 'mobx';
import SliderLinkModel from './slider_link_type.model';

/**
 * Slide model
 */
export default class SlideModel {
  titleTextColor: string;
  titleBackgroundColor: string;

  constructor(slider) {
    Object.assign(this, slider);
    this.id = slider.id;
    this.title = slider.title;
    this.description = slider.description;
    this.discountText = slider.discountText;
    this.picUrl = slider.picUrl;
    this.titleTextColor = slider.titleTextColor;
    this.titleBackgroundColor = slider.titleBackgroundColor;
    this.link = this.prepareLink(slider.link);
  }

  id;

  title = '';

  description = '';

  discountText = '';

  link = null;

  picUrl = '';

  /**
   * Mapping link to SliderLinkModel object
   * @param link
   */
  @action prepareLink(link = {}) {
    return new SliderLinkModel(link);
  }

  /**
   * Method to serialize offer instance to pure JS object.
   * @return {object} - serialized object.
   */
  getToJS() {
    return toJS(Object.assign({}, this, { root: {} }));
  }
}
