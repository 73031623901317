import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames';
import React, { memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import AddressChangeConfirmationModal from 'client/components/AddressChangeConfirmationModal';
import Input from 'client/components/Input';
import StreetAutocomplete from 'client/components/StreetAutocomplete';
import TextArea from 'client/components/TextArea';
import Button from 'client/ui/Button';
import ButtonGroup from 'client/ui/ButtonGroup';
import SelectField from 'client/ui/SelectField';
import {
  createDeliveryFormDeValidation,
  rules
} from 'client/utils/formValidation';
import getValidFormData from 'client/utils/getValidFormData';

import css from './DeliveryFormDe.module.scss';
import {
  DeliveryDeFormData,
  DeliveryFormDeProps,
  ZipCode
} from './DeliveryFormDe.type';

const DeliveryFormDe = ({
  className,
  defaultValues,
  lang,
  t,
  onSubmit,
  useStreetAutocomplete,
  zipCodes,
  isMobile
}: DeliveryFormDeProps) => {
  const {
    register,
    handleSubmit,
    formState,
    watch,
    setValue,
    getValues
  } = useForm<DeliveryDeFormData>({
    defaultValues,
    context: {
      lang
    },
    resolver: yupResolver(createDeliveryFormDeValidation()),
    mode: 'onTouched'
  });

  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);

  const [preSelectedZip, setPreSelectedZip] = useState<ZipCode | undefined>(
    undefined
  );

  const { errors } = formState;
  const zip = watch('zip');

  const getOptionLabel = useCallback((zipcode?: ZipCode) => {
    if (!zipcode) {
      return '';
    }

    return `${zipcode?.areaCode} - ${zipcode?.sublocality}`;
  }, []);

  const getActiveOption = useCallback(
    (zipcode?: ZipCode) =>
      zip?.areaCode === zipcode?.areaCode &&
      zip?.sublocality === zipcode?.sublocality,
    [zip]
  );

  const handleZipChange = useCallback(
    (option: ZipCode) => {
      if (
        zip?.mbv === option.mbv &&
        zip?.deliveryCosts === option.deliveryCosts
      ) {
        setValue('zip', option, { shouldValidate: true });
      } else {
        setPreSelectedZip(option);

        setOpenModal(true);
      }
    },
    [setValue, zip]
  );

  const handleStreetChange = useCallback(
    (street: string) => {
      setValue('street', street, { shouldValidate: true });
    },
    [setValue]
  );

  const redirectTo = useCallback(
    (path: string) => {
      const { pathname } = history.location;
      const newPath = pathname.replace('address', path);

      history.replace(newPath);
    },
    [history]
  );

  const handleConfirmClick = useCallback(() => {
    setValue('zip', preSelectedZip, { shouldValidate: true });

    onSubmit(getValues());

    redirectTo('basket');
  }, [getValues, onSubmit, preSelectedZip, redirectTo, setValue]);

  const handleModalCancelClick = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleFormSubmit = (data: DeliveryDeFormData) => {
    onSubmit(data);

    redirectTo('checkout');
  };

  const handleCancelClick = useCallback(() => {
    const data = getValues();
    const validData = getValidFormData(data, errors, defaultValues);

    onSubmit(validData);

    redirectTo('basket');
  }, [defaultValues, errors, getValues, onSubmit, redirectTo]);

  return (
    <>
      <form
        className={classnames(css.DeliveryFormDe, className)}
        onSubmit={handleSubmit(handleFormSubmit)}
        data-testid="DeliveryFormDe"
      >
        <Input
          {...register('firstName')}
          autoComplete="given-name"
          placeholder={t('address_form:firstName')}
          error={errors.firstName?.message}
          pattern={rules.firstNameValidChars}
          dataTestId="name-input-basket"
        />
        <Input
          {...register('lastName')}
          autoComplete="family-name"
          placeholder={t('address_form:lastName')}
          error={errors.lastName?.message}
          pattern={rules.lastNameValidChars}
          dataTestId="sec-name-input-basket"
        />
        <Input
          {...register('company')}
          placeholder={t('address_form:company')}
          error={errors.company?.message}
          pattern={rules.companyValidChars}
          dataTestId="firm-input-basket"
        />
        {useStreetAutocomplete ? (
          <StreetAutocomplete
            defaultValue={defaultValues.street}
            onSelect={handleStreetChange}
            placeholder={t('address_form:street')}
            error={errors.street?.message}
            dataTestId="street-select-basket"
          />
        ) : (
          <Input
            {...register('street')}
            placeholder={t('address_form:street')}
            error={errors.street?.message}
            pattern={rules.streetValidChars}
            dataTestId="street-input-basket"
          />
        )}
        <Input
          {...register('houseNumber')}
          placeholder={t('address_form:number')}
          error={errors.houseNumber?.message}
          pattern={rules.houseValidChars}
          dataTestId="house-input-basket"
        />
        <SelectField
          options={zipCodes}
          getOptionLabel={getOptionLabel}
          getActiveOption={getActiveOption}
          onChange={handleZipChange}
          value={zip}
          error={errors?.zip?.areaCode?.message}
          placeholder={t('address_form:zip')}
          dataTestId="zip-select-basket"
        />
        <Input
          {...register('email')}
          autoComplete="email"
          placeholder={t('address_form:emailAddress')}
          error={errors.email?.message}
          pattern={rules.emailValidChars}
          dataTestId="email-input-basket"
        />
        <Input
          {...register('phone')}
          autoComplete="tel"
          placeholder={t('address_form:phone')}
          error={errors.phone?.message}
          pattern={rules.phoneValidChars}
          valuePattern={rules.phoneValidValue}
          inputMode={isMobile ? 'tel' : 'text'}
          dataTestId="phone-input-basket"
        />
        <TextArea
          {...register('comment')}
          placeholder={t('address_form:massage')}
          error={errors.comment?.message}
          pattern={rules.commentValidChars}
          dataTestId="comment-input-basket"
        />
        <ButtonGroup vertical>
          <Button onClick={handleCancelClick} dataTestId="back-btn-basket">
            {t('address_form:cmnBack')}
          </Button>
          <Button type="submit" variant="submit" dataTestId="basket-order-btn">
            {t('address_form:checkout')}
          </Button>
        </ButtonGroup>
      </form>
      {openModal && (
        <AddressChangeConfirmationModal
          onConfirm={handleConfirmClick}
          onCancel={handleModalCancelClick}
        />
      )}
    </>
  );
};

export { DeliveryFormDe };

export default memo(DeliveryFormDe);
