import loadable from '@loadable/component';
import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  MobileModalsRouteType,
  Modals
} from 'client/routes/MobileModalsRoute/MobileModalsRoute.type';

const AddressModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/shell/AddressModal'
    )
);

const AllergensModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/shell/AllergensModal'
    )
);

const CertificateModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/shell/CertificateModal'
    )
);

const ContactFormModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/shell/ContactFormModal'
    )
);

const DeleteConfirmationModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/shell/DeleteConfirmationModal'
    )
);

const ImprintModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/shell/ImprintModal'
    )
);

const OfferModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/OfferModal'
    )
);

const PrivacyModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/shell/PrivacyModal'
    )
);

const ProductModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/ProductModal'
    )
);

const ProductExtrasModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/desktop/ProductExtrasModal'
    )
);

const TableReservationModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/shell/TableReservationFormModal'
    )
);

const TermsModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/shell/TermsModal'
    )
);

const ChangeAddressModal = loadable(
  () =>
    import(
      /* webpackChunkName: "productModal", webpackPrefetch: true */ 'client/pages/shell/ChangeAddressModal'
    )
);

const modals: Modals = {
  addressModal: AddressModal,
  allergensModal: AllergensModal,
  certificateModal: CertificateModal,
  contactModal: ContactFormModal,
  deleteConfirm: DeleteConfirmationModal,
  extrasModal: ProductExtrasModal,
  imprintModal: ImprintModal,
  privacyModal: PrivacyModal,
  productModal: ProductModal,
  offerModal: OfferModal,
  termsModal: TermsModal,
  changeAddressModal: ChangeAddressModal,
  tableReservation: TableReservationModal
};

const ShellModalsRoute = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const props: Record<string, any> = {};

  if (!params.has('modal')) {
    return null;
  }

  const modalType = params.get('modal') as MobileModalsRouteType;
  const Modal = modals[modalType];

  for (const [key, value] of params.entries()) {
    props[key] = value;
  }

  if (!Modal) {
    return null;
  }

  return <Modal {...props} />;
};

export default React.memo(ShellModalsRoute);
