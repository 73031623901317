import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

/**
 * Component  to create row with open/closed/holiday/maintenance info
 * @param phone
 * @returns {null}
 */
class BranchWorkTimeRow extends PureComponent {
  static propTypes = {
    isMaintenance: PropTypes.bool.isRequired,
    isHolidayMode: PropTypes.bool.isRequired,
    pickupClosed: PropTypes.bool.isRequired,
    style: PropTypes.object
  };

  static defaultProps = {};

  render() {
    const { isMaintenance, isHolidayMode, pickupClosed, style } = this.props;

    return isMaintenance || isHolidayMode ? (
      <div
        className="branch-time branch-closed"
        style={style.closed}
        data-testid="branch-time-brselector"
      >
        <i className="fas fa-clock" />
        {isMaintenance && this.props.t('maintenance_mode:maintenanceIsActive')}
        {isHolidayMode && this.props.t('opening_hours:openingHoursHolidays')}
      </div>
    ) : (
      <div
        className={classNames({
          'branch-time': true,
          'branch-open': !pickupClosed && !isHolidayMode && !isMaintenance,
          'branch-closed': pickupClosed
        })}
        style={pickupClosed ? style.closed : style.open}
      >
        <i className="fas fa-clock" />
        {pickupClosed ? this.props.t('lblClosed') : this.props.t('lblOpen')}
      </div>
    );
  }
}

export default withTranslation(['opening_hours'])(BranchWorkTimeRow);
