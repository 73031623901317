/* eslint-disable react/forbid-prop-types */
import { Divider } from 'antd';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import openModal from 'client/utils/openModal';
// Components

import { getIsSlugSupported } from '../../../../../../../utils/routing';

import BranchPhoneRow from './branch-phone-row';
import BranchWorkTimeRow from './branch-worktime-row';

/**
 * Component to render list of branches
 * @returns {*}
 */
@withRouter
@inject('store')
@observer
class BranchRow extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    branch: PropTypes.object.isRequired,
    isBranchSelectingSkipped: PropTypes.bool.isRequired,
    changeBranchAction: PropTypes.func.isRequired,
    currentBranch: PropTypes.object.isRequired,
    hostName: PropTypes.string.isRequired,
    style: PropTypes.object
  };

  static defaultProps = {};

  handleClick = (event) => {
    const {
      branch,
      currentBranch,
      history,
      changeBranchAction,
      store
    } = this.props;

    const { deliveryAddressStore, basketStore, productsStore } = store;
    const lastProductId = basketStore.lastProductClickedId;
    const product = productsStore.getProduct(basketStore.lastProductClickedId);

    if (!product && lastProductId) {
      const extrasUrl = openModal('extrasModal', {
        productId: lastProductId.toString()
      });

      const url = {
        ...extrasUrl,
        pathname: extrasUrl.pathname.replace('/branch-list', '')
      };

      history.push(url);
    } else {
      basketStore.addProductToBasket(product, 1);
    }

    if (branch.id === currentBranch.id) {
      event.preventDefault();

      deliveryAddressStore.setBranchSelectedStatus();

      if (product) {
        history.goBack();
      }

      return;
    }

    changeBranchAction();
  };

  render() {
    const {
      branch,
      isBranchSelectingSkipped,
      changeBranchAction,
      currentBranch,
      hostName,
      style
    } = this.props;

    const branchId = currentBranch.id;

    const route = getIsSlugSupported(hostName)
      ? `/${branch.slug}/${branch.id}`
      : `/${branch.id}`;

    const isBranchSelected =
      branch.id === branchId && !isBranchSelectingSkipped;

    const dividerStyle = this.props.store.themesStore.isTemplateAvailable
      ? {
          background: this.props.store.themesStore.separatorStyle()
            .borderBottomColor
        }
      : {};

    return (
      <>
        <Link
          onClick={this.handleClick}
          to={route}
          className={classNames({
            branch: true
          })}
        >
          <div
            className="branch-content"
            style={style.branch}
            data-testid="branch-card-brselector"
          >
            <div className="branch-name" data-testid="branch-name-brselector">
              {branch.title}
            </div>
            <div
              className="branch-address"
              data-testid="branch-addr-brselector"
            >
              <i className="fas fa-map-marker" />
              {branch.address}
            </div>

            <BranchWorkTimeRow
              isMaintenance={branch.isMaintenance || false}
              isHolidayMode={branch.isHolidayMode}
              pickupClosed={branch.pickupClosed}
              style={style}
            />

            <BranchPhoneRow phone={branch.phone} />
          </div>
          <div
            className="current-branch-label"
            style={isBranchSelected ? style.active : undefined}
          >
            <i
              className={classNames({
                'fas fa-check-circle': isBranchSelected
              })}
            />
          </div>
        </Link>
        <Divider style={dividerStyle} />
      </>
    );
  }
}

export default withTranslation(['opening_hours'])(BranchRow);
