import qs from 'qs';
import React, { Children, ReactElement } from 'react';
import { Experiment, ExperimentProps, Variant } from 'react-optimize';
import { useLocation } from 'react-router-dom';

const GoogleOptimize = ({
  children,
  id,
  loader
}: ExperimentProps): ReactElement => {
  const { search } = useLocation();
  const childrenArr = Children.toArray(children);
  const possibleIndexes = childrenArr.map((_, index) => index);

  const { experimentVariantId } = qs.parse(search, {
    plainObjects: true,
    ignoreQueryPrefix: true
  });

  const isVariantIdValid =
    experimentVariantId &&
    possibleIndexes.includes(Number(experimentVariantId));

  if (isVariantIdValid) {
    return (
      <>
        {childrenArr.filter(
          (_, index) => index.toString() === experimentVariantId
        )}
      </>
    );
  }

  return (
    <Experiment id={id} timeout={0} loader={loader}>
      {Children.map(children, (child, index) => (
        <Variant id={index.toString()}>{child}</Variant>
      ))}
    </Experiment>
  );
};

export default GoogleOptimize;
