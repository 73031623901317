import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Input from 'client/ui/Input';
import Text from 'client/ui/Text';
import mergeRefs from 'client/utils/mergeRefs';

import css from './TextField.module.scss';
import { TextFieldProps } from './TextField.type';

/**
 - Component for handling user's input
 */
const TextField = ({
  className,
  errorClassName,
  disabled,
  error = '',
  forbidServiceKeys = [],
  label,
  prefix,
  suffix,
  inputRef,
  pattern,
  valuePattern,
  dataTestId,
  ...inputProps
}: TextFieldProps) => {
  const [hasFocus, setHasFocus] = useState(false);
  const ref = useRef<HTMLInputElement | null>(null);

  const classes = classnames(css.container, className, {
    [css.hasPrefix]: !!prefix,
    [css.hasSuffix]: !!suffix,
    [css.hasFocus]: hasFocus,
    [css.hasError]: !!error,
    [css.disabled]: disabled
  });

  const toggleFocus = () => {
    const isFocused = document.activeElement === ref.current;

    setHasFocus(isFocused);
  };

  const handleKeyDown = useCallback<
    React.KeyboardEventHandler<HTMLInputElement>
  >(
    (event) => {
      const { key } = event;

      if (forbidServiceKeys?.includes(key)) {
        event.preventDefault();
      }
    },
    [forbidServiceKeys]
  );

  const handleKeyPress = useCallback<
    React.KeyboardEventHandler<HTMLInputElement>
  >(
    (event) => {
      const { key, currentTarget } = event;
      const { value } = currentTarget;
      const isSymbol = key.length === 1; // key.length avoid matching service keys (Control, Backspace, Tab, etc)
      const isValidSymbol = pattern?.test(key);
      const cursorPosition = ref.current?.selectionStart;

      if (pattern && isSymbol && !isValidSymbol) {
        event.preventDefault();
      }

      if (valuePattern && isValidSymbol && cursorPosition !== null) {
        const fullValue =
          value.slice(0, cursorPosition) + key + value.slice(cursorPosition);

        const isMatch = valuePattern?.test(fullValue);

        if (isMatch) {
          event.preventDefault();
        }
      }
    },
    [pattern, valuePattern]
  );

  useEffect(() => {
    window.addEventListener('focusin', toggleFocus);

    window.addEventListener('focusout', toggleFocus);

    return () => {
      window.removeEventListener('focusin', toggleFocus);

      window.removeEventListener('focusout', toggleFocus);
    };
  }, []);

  return (
    <label className={css.TextField} data-testid="TextField">
      {label && <Text className={css.label}>{label}</Text>}
      <div className={classes}>
        {prefix && <div className={css.prefix}>{prefix}</div>}
        <Input
          className={css.input}
          disabled={disabled}
          inputRef={mergeRefs(ref, inputRef)}
          onKeyDownCapture={handleKeyDown}
          onKeyPress={handleKeyPress}
          data-testid={dataTestId}
          {...inputProps}
        />
        {suffix && <div className={css.suffix}>{suffix}</div>}
      </div>
      <Text className={classnames(css.error, errorClassName)}>{error}</Text>
    </label>
  );
};

export { TextField };

export default TextField;
