import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import { withTranslation } from 'react-i18next';

// CSS
import './_closed-mobile.scss';

// Assets
import images from '../../../../enums/images_enums/hermes_images.enum';

/**
 * Error component is used to show
 * error if branch is currently closed.
 * @name ReactErrorComponent
 */
class ClosedMobile extends PureComponent {
  static propTypes = {
    isShopClosed: PropTypes.bool.isRequired
  };

  render() {
    return this.props.isShopClosed ? (
      <div id="error-container">
        <div id="error-component">
          <div id="error-component-text">
            <ReactSVG
              src={images.warningIcon}
              className="error-component-img"
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 18px; height: 18px;');
              }}
            />
            {this.props.t('store_is_closed_modal:modalErrorClosed')}
            <br />
            {this.props.t('store_is_closed_modal:modalErrorTryAgain')}
          </div>
        </div>
      </div>
    ) : null;
  }
}
export default withTranslation(['store_is_closed_modal'])(ClosedMobile);
