import classnames from 'classnames';
import React from 'react';

import css from './Text.module.scss';
import { TextProps } from './Text.type';

const Text = ({ className, children, dataTestId, ...props }: TextProps) => (
  <p
    className={classnames(css.Text, className)}
    data-testid={dataTestId}
    {...props}
  >
    {children}
  </p>
);

export { Text };

export default Text;
