/* eslint-disable import/no-cycle */
import dayjs from 'dayjs';
import { History } from 'history';
import findIndex from 'lodash/findIndex';
import intersection from 'lodash/intersection';
import sum from 'lodash/sum';
import { action, computed, observable } from 'mobx';
import { v1 } from 'uuid';

import animationConstants from 'client/enums/animation.enum';
import states from 'client/enums/states.enum';
import STORAGE_KEYS from 'client/enums/storage_keys.enum';
import { BasketRequestModel } from 'client/models/basket_request.model';
import { OfferModel } from 'client/models/offer.model';
import { IDayInterface } from 'client/models/offer.model.type';
import { OfferRequestModel } from 'client/models/offer_request.model';
import { OrderRequestModel } from 'client/models/orderRequest.model';
import { PayPalRequestModel } from 'client/models/paypal_request.model';
import { ProductModel } from 'client/models/product.model';
import RootStore from 'client/stores';
import {
  IAdvancedCalcTypeData,
  IAdvancedCalcTypeDataResponse,
  IBasketBounds,
  IBasketOffer,
  IBasketProduct,
  IUpdateBounds
} from 'client/stores/basket/basket.store.types';
import { intlPrice } from 'client/utils/functions';
import openModal from 'client/utils/openModal';
import { generateLinkFor } from 'client/utils/routing';
import { getWeekday } from 'client/utils/time';

/**
 * Basket store class. Used to work with basket.
 * @constructor
 * @param {instance} api - {@link API} instance.
 * @param {instance} storage - {@link Storage} instance.
 * @property {string} lastUpdatedKey - Unique storage key to store data.
 * @property {string} localStorageKey - Unique storage key to store data.
 * @property {{ product: ProductModel;count: number; }[]} products - Observable array of products
 * @property {ProductModel} lastBasketItemForRemove - Last product to remove
 * @property {*} offersTimesBySelectedDay - filter work days by offers in basket
 * @property {*} offersDays - parsed offer weeks by offers in basket
 * @property {boolean} isShowConfirmDelete - is delete confirmation action should be showed
 * @property {boolean} loading - is loading active
 * @property {object} bounds - bounds of basket item elements on html page
 */
class BasketStore {
  constructor(root: RootStore, state: BasketStore, storage: any) {
    Object.assign(this, state);

    this.root = root;

    this.storage = storage;
  }

  public readonly root: RootStore;

  public storage: any;

  lastUpdatedKey = STORAGE_KEYS.BASKET_LAST_UPDATED;

  localStorageKey = STORAGE_KEYS.BASKET;

  @observable products: IBasketProduct[] = [];

  @observable offers: IBasketOffer[] = [];

  @observable lastBasketItemForRemove: ProductModel | OfferModel | undefined;

  /**
   * Last clicked product id
   */
  @observable lastProductClickedId: number | null = null;

  @observable offersTimesBySelectedDay: IDayInterface[] = [];

  @observable offersDays: IDayInterface['dayNo'][] | null = null;

  @observable isShowConfirmDelete = false;

  @observable loading = false;

  @observable animation = false;

  @observable bounds: IBasketBounds = {
    product: {},
    offer: {},
    basket: {}
  };

  /**
   * Contains demo basket generator activation state
   * @type {boolean}
   */
  @observable isDemoModeActive = false;

  @observable uniqStringLastAddedProduct = '';

  @observable uniqStringLastAddedOffer = '';

  @observable advancedCalcTypeData: IAdvancedCalcTypeData = {
    fee: null,
    mbv: null,
    availability: false,
    freeFeeFrom: null
  };

  // #region Basket Actions
  /**
   * Set loading state
   * @param {boolean} flag
   * @memberof BasketStore
   */
  @action setLoading(flag: boolean) {
    this.loading = flag;
  }

  /**
   * Action to set delete conformation state
   * @param {boolean} flag - true if show confirm delete
   */
  @action toggleConfirmDelete(flag: boolean) {
    this.isShowConfirmDelete = flag;
  }

  /**
   * Method to toggle animation state
   * @param state
   */
  @action toggleAnimation(state = !this.animation) {
    this.animation = state;

    // timeout should be more then .28s cause showing animation is .28s
    const timer = setTimeout(() => {
      this.animation = !this.animation;
    }, 500);
  }

  /**
   * Method to set or remove basket item node bounds
   * @param id
   * @param type
   * @param bounds
   * @param clearBounds
   */
  @action updateBounds(
    { id, type, bounds }: IUpdateBounds,
    clearBounds: boolean
  ) {
    if (clearBounds) {
      delete this.bounds[type][id];
    } else {
      this.bounds = {
        ...this.bounds,
        [type]: {
          ...this.bounds[type],
          [id]: {
            top: bounds.top,
            left: bounds.left,
            width: bounds.width,
            height: bounds.height
          }
        }
      };
    }
  }

  /**
   * Action to handle basket products
   * @param props
   * @returns {*}
   */
  // TODO GET RID OF PROPS ARGUMENT
  @action handleBasketComplete(props: { history: History }) {
    const { deliveryAddressStore, restaurantStore } = this.root;

    if (!this.isAvailableToAdd) return console.warn('Can not order now!');

    if (deliveryAddressStore.isDelivery && !restaurantStore.isLocationChosen) {
      const addressModalUrl = openModal('addressModal');

      props.history.push(addressModalUrl);

      return;
    }

    // Check if free-item added to basket and mbv level achieved
    if (this.root.offerStore.freeItem) {
      if (
        this.root.offerStore.freeItem.isValid ||
        this.root.offerStore.freeItem.isValidForPreorderNow
      ) {
        // show modal with free-items before order
        if (!this.isFreeOfferInBasket) {
          if (
            !this.root.offerStore.isFreeItemModalShowedBeforeOrder &&
            !this.root.restaurantStore.branch.getOfferIsSimplified
          ) {
            this.root.offerStore.isFreeItemModalShowedBeforeOrder = true;

            this.root.offerStore.showFreeItemModalIfNeed(props);

            return;
          }
        }
      }
    }

    this.offersWeekdays();

    this.root.openingHoursStore.generatePreorderDays();

    this.root.orderPaymentMethodsStore.setActivePaymentMethod();

    this.root.orderPaymentMethodsStore.resetOrderPaymentInformation();

    props.history.push(generateLinkFor(states.address, props, {}, true));
  }

  /**
   * Method to add instance of product to the basket.
   * @return {object} the product object to add.
   * @throws {Error} if product is observable or an instance of the ProductModel
   * @param product {ProductModel}
   * @param productCount {number} - count of products
   * @param fromBasket {boolean} flag if product added from basket - should not toggle animation
   * @param fromModal {boolean} flag if basket added from modal - should cause timeout cause we need to wait product list to render
   * @param {boolean} isDemoModeActive - is demo mode active and products can be added without conditions
   * @memberof BasketStore#
   * @method add
   */
  @action async addProductToBasket(
    product: ProductModel,
    productCount = 1,
    fromBasket = false,
    fromModal = false,
    isDemoModeActive = false
  ) {
    if (!isDemoModeActive) {
      if (
        !this.isAvailableToAdd ||
        this.root.restaurantStore.cantAddProductsToBasket
      ) {
        return console.warn('Product can not be added to the basket', product);
      }
    }

    const productToAdd = new ProductModel(product.id, product, this.root);

    const findProductInBasket = this.products.find(
      (product) => product.product.uniqString === productToAdd.uniqString
    );

    // Disable animation for demo mode
    if (!isDemoModeActive) {
      this.root.productsStore.animateProduct(product.id);

      // show animation layer
      if (!fromBasket) {
        if (fromModal) {
          const timer = setTimeout(() => {
            this.toggleAnimation();
          }, 300);
        } else {
          this.toggleAnimation();
        }
      }
    }

    this.uniqStringLastAddedProduct = product.uniqString;

    if (findProductInBasket) {
      const { isShellThemeActive } = this.root.themesStore;

      if (isShellThemeActive) {
        // eslint-disable-next-line array-callback-return
        this.products.map((product, index) => {
          if (
            product.product.uniqString ===
            findProductInBasket.product.uniqString
          ) {
            this.products[index].count += productCount;
          }
        });
      } else {
        await Promise.all(
          this.products.map(async (product, index) => {
            if (
              product.product.uniqString ===
              findProductInBasket.product.uniqString
            ) {
              await new Promise((resolve) =>
                setTimeout(() => {
                  this.products[index].count += productCount;

                  resolve(null);
                }, animationConstants.time.buttonToBuyProduct)
              );
            }
          })
        );
      }
    } else {
      this.products = [
        ...this.products,
        { product: productToAdd, count: productCount }
      ];
    }

    // Clean last product id, because it was successful added
    this.setLastProductClicked();

    this.root.analyticsStore.sendAddToBasket(product);

    this.subscribeStorageToStore();
  }

  /**
   * Method for replacing product in products array
   * @param {ProductModel} product - product item
   * @param {int} count - quantity of products
   * @param {int} replaceIndex - index of changed product
   */
  @action replaceProduct(
    product: ProductModel,
    count: number,
    replaceIndex: number
  ) {
    if (this.products.length - 1 >= replaceIndex) {
      this.products[replaceIndex] = { product, count };
    }
  }

  /**
   * Method for checking if offer can be added to basket because offers quantity limits
   * @param {OfferModel} offer - offer item
   */
  isOffersCanBeAddedToBasket(offer?: OfferModel) {
    if (!offer) {
      return false;
    }

    const addedOffers = this.offers.filter(
      (basketOffer) => basketOffer.offer.id === offer.id
    );

    const offersQuantity = +sum(
      addedOffers.map((basketOffer) => basketOffer.count)
    );

    return offersQuantity < offer.maxQty;
  }

  /**
   * Action to add offer to basket
   * @param {OfferModel} offer -  offer item
   * @param {number} count - count of offer for adding
   * @param fromBasket {boolean} flag if offer added from basket - should not toggle animation
   * @param fromModal {boolean} flag if basket added from modal - should cause timeout cause we need to wait offer list to render
   * @param props
   */
  @action addOffer(
    offer: OfferModel,
    count = 1,
    fromBasket = false,
    fromModal = false
  ) {
    if (
      ((!this.isAvailableToAdd || !offer.isValid || offer.totalPrice < 0) &&
        !offer.isValidForPreorderNow) ||
      this.root.restaurantStore.cantAddProductsToBasket
    )
      return console.warn('Offer can not be added to the basket', offer);

    // show animation layer
    if (!fromBasket) {
      if (fromModal) {
        const timer = setTimeout(() => {
          this.toggleAnimation();
        }, 300);
      } else {
        this.toggleAnimation();
      }
    }

    this.root.offerStore.animateOffer(offer.id);

    this.root.ingredientsSelectStore.setActiveIngredient(); // clear active ingredient group

    const findOfferInBasket = this.offers.find(
      (offerGroup) => offerGroup.offer.uniqString === offer.uniqString
    );

    if (this.isFreeOfferInBasket && offer.isFreeOffer) {
      this.removeOfferFromBasket(offer, 1);
    }

    if (!offer.isFreeOffer) {
      if (this.root.offerStore.offerChange) {
        this.replaceOffer(offer, this.root.offerStore.editingIndex);

        this.root.offerStore.setOfferChange(false);

        this.root.offerStore.resetOfferChange();

        return;
      }

      if (findOfferInBasket) {
        this.offers.map((offer) => {
          if (offer.offer.uniqString === findOfferInBasket.offer.uniqString) {
            return { ...offer, count: findOfferInBasket.count++ };
          }

          return offer;
        });

        this.offersWeekdays();

        this.root.openingHoursStore.generatePreorderDays();

        this.uniqStringLastAddedOffer = offer.uniqString;

        // Clean active offer, because it was successful added
        this.root.offerStore.setOffer();

        this.subscribeStorageToStore();

        return;
      }
    }

    offer.uuid = v1();

    this.offers = [...this.offers, { offer, count }];

    this.offersWeekdays();

    this.root.openingHoursStore.generatePreorderDays();

    this.uniqStringLastAddedOffer = offer.uniqString;

    // Clean active offer, because it was successful added
    this.root.offerStore.setOffer();

    this.subscribeStorageToStore();
  }

  /**
   * Method for replacing offer in basket after changing
   * @param {OfferModel} offer - updated offer item
   * @param {number} index - index of offer in offers array
   */
  @action replaceOffer(offer: OfferModel, index: number) {
    if (this.offers[index]) {
      this.offers[index].offer = offer;

      this.offersWeekdays();

      this.root.openingHoursStore.generatePreorderDays();

      this.subscribeStorageToStore();
    }
  }

  /**
   * Action to remove offer from basket
   * @param {OfferModel} offer - updated offer item
   * @param {number} count - count of offer for removing
   */
  @action removeOfferFromBasket(offer: OfferModel, count = 1) {
    const index = findIndex(
      this.offers,
      (offerGroup) => offerGroup.offer.uniqString === offer.uniqString
    );

    if (offer?.isFreeOffer) {
      this.offers.splice(index, 1);

      this.offersWeekdays();

      this.root.openingHoursStore.generatePreorderDays();

      this.subscribeStorageToStore();

      return;
    }

    if (index >= 0) {
      if (this.offers[index].count > 1 && this.offers[index].count !== count) {
        this.offers.map((offerGroup) => {
          if (
            offerGroup.offer.uniqString === this.offers[index].offer.uniqString
          ) {
            return { ...offerGroup, count: offerGroup.count-- };
          }

          return offerGroup;
        });
      } else {
        this.offers.splice(index, 1);
      }
    }

    this.offersWeekdays();

    this.root.openingHoursStore.generatePreorderDays();

    this.subscribeStorageToStore();
  }

  /**
   * Method to removeProductFromBasket product from basket by _uuid and warn if product wasn't removed.
   * @param {ProductModel} product - instance of product.
   * @param {number} count - count of items
   * @memberof BasketStore#
   * @method removeProductFromBasket
   */
  @action removeProductFromBasket(product: ProductModel, count = 1) {
    const productUuid = product._uuid;
    const productToDelete = new ProductModel(product.id, product, this.root);

    const index = findIndex(
      this.products,
      (product) => product.product.uniqString === productToDelete.uniqString
    );

    if (index >= 0) {
      if (
        this.products[index].count > 1 &&
        this.products[index].count !== count
      ) {
        this.products.map((product) => {
          if (
            product.product.uniqString ===
            this.products[index].product.uniqString
          ) {
            return { ...product, count: product.count-- };
          }

          return product;
        });
      } else {
        this.products.splice(index, 1);
      }
    } else {
      console.warn("Product wasn't removed", product, productUuid);
    }

    this.subscribeStorageToStore();

    this.root.ingredientsSelectStore.isEditing = false;
  }

  /**
   * Method to set last product to confirm deleting
   * @param {instance} productId - id of the product
   * @memberof BasketStore#
   * @method basketItemToRemove
   */
  @action basketItemToRemove(productId: string) {
    const lastItem = this.offers.find(
      (offerGroup) => offerGroup.offer.uniqString === productId
    );

    if (lastItem) {
      this.lastBasketItemForRemove = lastItem.offer;

      return;
    }

    this.lastBasketItemForRemove = this.products.find(
      (product) => product.product.uniqString === productId
    )?.product;
  }

  /**
   * Method to empty products in basket.
   * @memberof BasketStore#
   * @method resetBasket
   */
  @action resetBasket() {
    this.products = [];

    this.offers = [];

    this.root.couponsStore.removeCouponAction();

    this.root.orderPaymentMethodsStore.changeOrderId();

    this.subscribeStorageToStore();
  }

  /**
   * Method to load cached basket from storage.
   * @memberof BasketStore#
   * @method loadBasketFromStorage
   */
  @action
  async loadBasketFromStorage() {
    const { restaurantTime, branch } = this.root.restaurantStore;

    const [lastUpdated, cachedItems] = (await this.storage.loadFromStorage(
      [this.lastUpdatedKey, this.localStorageKey],
      branch.branchId
    )) as [string, { products: IBasketProduct[]; offers: IBasketOffer[] }];

    const dateLastUpdated = dayjs(lastUpdated);
    const diffInHours = restaurantTime.diff(dateLastUpdated, 'hour');

    if (lastUpdated && diffInHours <= 2) {
      const cachedProducts = cachedItems?.products || [];
      const cachedOffers = cachedItems?.offers || [];

      this.products = cachedProducts.map((product) => ({
        ...product,
        product: new ProductModel(
          product.product.id,
          product.product,
          this.root
        )
      }));

      this.offers = cachedOffers.map((offer) => {
        offer.offer.uuid = v1();

        return {
          ...offer,
          offer: new OfferModel(offer.offer.id, offer.offer, this.root)
        };
      });
    } else {
      this.resetBasket();
    }
  }

  /**
   * Action to calculate week days when offers in basket available
   */
  @action offersWeekdays() {
    const availabilityTime = this._calculateOffersAvailabilityTime(this.offers);

    this.offersDays = availabilityTime.parseOfferWeekdaysSet;

    this.offersTimesBySelectedDay = availabilityTime.parsedOfferDaysTimes;
  }

  /**
   * Method for calculation offers availability time. It returns object contains offers weekdays and offers days times
   * @param offers
   * @returns {{parsedOfferDaysTimes: *, parseOfferWeekdaysSet: *}}
   * @private
   */
  _calculateOffersAvailabilityTime = (offers: IBasketOffer[]) => {
    /**
     * Get weekdays when offers available
     */
    const offersAvailabilityWeekDays =
      offers.length > 0
        ? offers.map((offerGroup) =>
            offerGroup.offer.getOpeningWeekDays.length > 0
              ? offerGroup.offer.getOpeningWeekDays.map((i) =>
                  // eslint-disable-next-line radix
                  typeof i.dayNo === 'string' ? parseInt(i.dayNo) : i.dayNo
                )
              : []
          )
        : [];

    /**
     * Get time when offers available
     */
    const offersAvailabilityTimes =
      offers.length > 0
        ? offers.map((offerGroup) => offerGroup.offer.getOpeningWeekDays)
        : [];

    /**
     * Get offers weekdays set
     */
    const parseOfferWeekdaysSet =
      offersAvailabilityWeekDays && offersAvailabilityWeekDays.length > 0
        ? offersAvailabilityWeekDays.reduce((a, c) => intersection(a, c))
        : null;

    /**
     * Get offers time set
     * @type {T[]}
     */
    const parsedOfferDaysTimes = offersAvailabilityTimes
      .reduce((a, c) => [...a, ...c], [])
      .filter((i) =>
        parseOfferWeekdaysSet?.includes(
          // eslint-disable-next-line radix
          typeof i.dayNo === 'string' ? parseInt(i.dayNo) : i.dayNo
        )
      );

    return {
      parseOfferWeekdaysSet,
      parsedOfferDaysTimes
    };
  };

  /**
   * Method to control demo mode of basket
   * @param demoModeState
   */
  @action generateProductsInBasket(demoModeState = false) {
    this.isDemoModeActive = demoModeState;
  }

  /**
   * Method to init loading demo products
   */
  @action initLoadingDemoProducts() {
    if (this.isDemoModeActive) {
      this.generateProductsInBasket(false);

      this.root.categoryMenuStore.loadCategories(true);
    }
  }

  /**
   * Method to set comment for existing product
   */
  @action setProductComment(uniqString: string, comment: string) {
    const productIndexForSaveComment = this.products.findIndex(
      (productEntity) => productEntity.product.uniqString === uniqString
    );

    if (productIndexForSaveComment >= 0) {
      this.products[productIndexForSaveComment].product.comment = comment;

      this.products[productIndexForSaveComment].product.makeThisProductUnique();
    }

    if (this.bounds.product && this.bounds.product[uniqString]) {
      this.bounds.product[
        this.products[productIndexForSaveComment].product.uniqString
      ] = {
        ...this.bounds.product[uniqString]
      };

      delete this.bounds.product[uniqString];
    }

    this.subscribeStorageToStore();
  }

  /**
   * Method to fill data about advanced calculation type of delivery fee
   */
  @action setAdvancedCalculationTypeData(data?: IAdvancedCalcTypeDataResponse) {
    if (data) {
      const feeExists = Object.prototype.hasOwnProperty.call(data, 'fee');
      const mbvExists = Object.prototype.hasOwnProperty.call(data, 'mbv');

      const freeFeeFromExists = Object.prototype.hasOwnProperty.call(
        data,
        'freeFrom'
      );

      this.advancedCalcTypeData = {
        availability: true,
        fee: feeExists && data.fee !== undefined ? data.fee : null,
        mbv: mbvExists && data.mbv !== undefined ? data.mbv : null,
        freeFeeFrom:
          freeFeeFromExists && data.freeFrom !== undefined
            ? data.freeFrom
            : null
      };
    } else {
      this.advancedCalcTypeData = {
        availability: false,
        fee: null,
        mbv: null,
        freeFeeFrom: null
      };
    }
  }

  /**
   * Method to set last clicked product
   * @param {number | null} id - product id
   * @method setLastProductClicked
   */
  @action setLastProductClicked(id = null) {
    this.lastProductClickedId = id;
  }

  // #endregion Basket Actions

  // #region Preparing order data
  /**
   * Method to prepare order data for Paypal
   * @returns {*[]}
   */
  toPayPalRequest() {
    return [
      ...this.products
        .filter(
          (groupedProducts) => groupedProducts.product.priceWithIngredients > 0
        )
        .map(
          (groupedProducts) =>
            new PayPalRequestModel(
              groupedProducts.product,
              groupedProducts.count,
              this.root
            )
        ),
      ...this.offers.map(
        (offerGroup) =>
          new PayPalRequestModel(offerGroup.offer, offerGroup.count, this.root)
      )
    ];
  }

  /**
   * Method to prepare order to the backend request
   */
  dataToBackendRequest() {
    const request = new OrderRequestModel(this.root);

    return request;
  }

  /**
   * Method to prepare basket to the backend request
   * @return serialized array of products
   */
  basketToBackendRequest() {
    return [
      ...this.products
        .filter(
          (groupedProducts) => groupedProducts.product.priceWithIngredients >= 0
        )
        .map((groupedProducts) => new BasketRequestModel(groupedProducts)),
      ...this.offers.map(
        (offerGroup) => new OfferRequestModel(offerGroup, this.root)
      )
    ];
  }

  // #endregion Preparing order data

  // #region Basket logic methods

  /**
   * Method to check is basket unavailable to order
   */
  @computed get basketUnavailableToOrder() {
    const { isDelivery } = this.root.deliveryAddressStore;
    const { restaurantTime } = this.root.restaurantStore;

    const hasInvalidProduct = this.products.some(
      ({ product }) => !product.isValidForOrder
    );

    const {
      todayWeekDay,
      todayDeliveryHours,
      todayOpeningHours,
      activeDay,
      preorderTimes,
      filterDays
    } = this.root.openingHoursStore;

    const workingHoursAfterMidnightExist =
      getWeekday(restaurantTime, activeDay) === todayWeekDay &&
      (isDelivery
        ? dayjs(todayDeliveryHours.end, 'HH:mm:ss') <
          dayjs(todayDeliveryHours.start, 'HH:mm:ss')
        : dayjs(todayOpeningHours.end, 'HH:mm:ss') <
          dayjs(todayOpeningHours.start, 'HH:mm:ss'));

    return (
      !this.isAvailableToAdd ||
      !this.hasProducts ||
      !this.isMinValueReached ||
      this.offerInBasketNotValid ||
      this.orderPriceWithOffers === 0 ||
      filterDays.length === 0 ||
      (preorderTimes.length === 0 && !workingHoursAfterMidnightExist) ||
      hasInvalidProduct
    );
  }

  /**
   * Method to check is checkout unavailable to order
   * @returns {boolean}
   */
  @computed get checkoutUnavailableToOrder() {
    return (
      this.basketUnavailableToOrder ||
      // Contactless delivery method active and online payment method not chosen
      (this.root.orderPaymentMethodsStore.isContactLessMethodAvailable &&
        !this.root.orderPaymentMethodsStore.activePaymentMethod.isOnline)
    );
  }

  /**
   * Check if offer in basket not valid
   * @returns {boolean}
   */
  @computed get offerInBasketNotValid() {
    return !!this.offers.find(
      (offerGroup) =>
        !offerGroup.offer.isFreeOffer &&
        !offerGroup.offer.isValid &&
        !offerGroup.offer.isValidForPreorderNow
    );
  }

  /**
   * Check if basket in free item
   */
  @computed get isFreeOfferInBasket() {
    return !!this.offers.find((offerGroup) => offerGroup.offer.isFreeOffer);
  }

  /**
   * Method to get free item from basket
   */
  @computed get basketFreeItem() {
    return this.isFreeOfferInBasket
      ? this.offers.find((offerGroup) => offerGroup.offer.isFreeOffer)?.offer
      : undefined;
  }

  /**
   * Method to determine if there is some products in basket.
   * @return existence of products in basket
   */
  @computed get hasProducts() {
    return !!this.productsCount || !!this.offersCount;
  }

  /**
   * Method that returns count of products in basket.
   * @return {number} products count
   * @memberof BasketStore#
   * @method productsCount
   */
  @computed get productsCount() {
    return +sum(this.products.map((product) => product.count));
  }

  /**
   * Method to get count of offer in basket
   * @returns {number}
   */
  @computed get offersCount() {
    return +sum(this.offers.map((offer) => offer.count));
  }

  /**
   * Method to check if delivery is possible
   */
  @computed get calculatedDeliveryFee() {
    return this.root.deliveryAddressStore.isDelivery ? this.deliveryFee : 0;
  }

  /**
   * Method to get delivery cost for current area code.
   * @return {number} delivery cost.
   * @memberof BasketStore#
   * @method deliveryFee
   */
  @computed get deliveryFee() {
    if (
      this.root.restaurantStore.useCalculationTypeByDeliveryArea &&
      this.advancedCalcTypeData.fee !== null
    ) {
      return this.orderPriceWithOffers >=
        (this.advancedCalcTypeData.freeFeeFrom || 0)
        ? 0
        : this.advancedCalcTypeData.fee;
    }

    const { currentAreaCode } = this.root.restaurantStore;

    if (currentAreaCode) {
      if (!this.deliveryFreeFrom) return currentAreaCode.deliveryCosts;

      return this.orderPriceWithOffers >= this.deliveryFreeFrom
        ? 0
        : currentAreaCode.deliveryCosts;
    }

    return 0;
  }

  @computed get deliveryFreeFrom() {
    let deliveryCostsThreshold;

    if (this.root.restaurantStore.useCalculationTypeByDeliveryArea) {
      deliveryCostsThreshold =
        this.advancedCalcTypeData.freeFeeFrom || undefined;
    } else {
      const { currentAreaCode } = this.root.restaurantStore;

      deliveryCostsThreshold = currentAreaCode?.deliveryCostsThreshold;
    }

    return deliveryCostsThreshold < 500 ? deliveryCostsThreshold : undefined;
  }

  @computed get intlDeliveryFreeFrom() {
    const countDigits =
      this.deliveryFreeFrom - Math.floor(this.deliveryFreeFrom) > 0 ? 2 : 0;

    return this.deliveryFreeFrom
      ? this.getIntlPrice(this.deliveryFreeFrom, countDigits, countDigits)
      : '';
  }

  /**
   * Method to check if minimum price value reached.
   * @return {boolean} check result.
   * @memberof BasketStore#
   * @method isMinValueReached
   */
  @computed get isMinValueReached() {
    return +this.orderPriceForMbvWithDiscount.toFixed(2) >= this.minPrice;
  }

  /**
   * Method to determine if it's available to add product to shopping card.
   * @return {boolean} check result.
   * @memberof BasketStore#
   * @method isAvailableToAdd
   */
  @computed get isAvailableToAdd() {
    if (this.root.deliveryAddressStore.isDelivery) {
      return (
        this.root.openingHoursStore.isOpenForDelivery ||
        this.root.restaurantStore.isPreorderAlways
      );
    }

    return (
      this.root.openingHoursStore.isOpenForPickup ||
      this.root.restaurantStore.isPreorderAlways
    );
  }

  // #endregion Basket logic methods

  // #region International strings

  /**
   * Method to create international string from price
   * @param price
   * @param maximumFractionDigits
   * @returns {string}
   */
  getIntlPrice(
    price: number,
    maximumFractionDigits = 2,
    minimumFractionDigits = 2
  ) {
    const language = this.root.restaurantStore.restaurant.getLang;
    const { currencyCode } = this.root.restaurantStore.branch;

    return intlPrice(
      price,
      language,
      currencyCode,
      maximumFractionDigits,
      minimumFractionDigits
    );
  }

  /**
   * Method to check delivery fee value and return string based on currency and locale.
   * @return {string} delivery fee.
   * @memberof BasketStore#
   * @method intlDeliveryFee
   */
  @computed get intlDeliveryFee() {
    return this.getIntlPrice(this.deliveryFee);
  }

  /**
   * Method to check max delivery fee value and return string based on currency and locale.
   * @return {string} delivery fee.
   * @memberof BasketStore#
   * @method intlMaxDeliveryFee
   */
  @computed get intlMaxDeliveryFee() {
    return this.getIntlPrice(this.root.restaurantStore.branch.maxDeliveryFee);
  }

  /**
   * Method to check min delivery fee value and return string based on currency and locale.
   * @return {string} delivery fee.
   * @memberof BasketStore#
   * @method intlMinDeliveryFee
   */
  @computed get intlMinDeliveryFee() {
    return this.getIntlPrice(this.root.restaurantStore.branch.minDeliveryFee);
  }

  /**
   * Method to get minimum allowed order price to be payed.
   * @return {number} minimum order price.
   * @memberof BasketStore#
   * @method minPrice
   */
  @computed get minPrice() {
    if (this.root.deliveryAddressStore.isDelivery) {
      if (
        this.root.restaurantStore.useCalculationTypeByDeliveryArea &&
        this.advancedCalcTypeData.mbv !== null
      ) {
        return this.advancedCalcTypeData.mbv;
      }

      return this.root.restaurantStore.currentAreaCode
        ? this.root.restaurantStore.currentAreaCode.mbv
        : null;
    }

    return this.root.restaurantStore.branch.getMbw;
  }

  /**
   * International min price
   * @returns {string}
   */
  @computed get intlMinPrice() {
    const { useCalculationTypeByDeliveryArea } = this.root.restaurantStore;
    const { isDelivery } = this.root.deliveryAddressStore;

    return this.getIntlPrice(
      useCalculationTypeByDeliveryArea &&
        isDelivery &&
        this.advancedCalcTypeData.mbv !== null &&
        this.advancedCalcTypeData.mbv !== undefined
        ? this.advancedCalcTypeData.mbv
        : this.minPrice
    );
  }

  /**
   * International min price
   * @returns {string}
   */
  @computed get mbwMinPrice() {
    return this.getIntlPrice(this.root.restaurantStore.branch.minMbw);
  }

  /**
   * International max price
   * @returns {string}
   */
  @computed get mbwMaxPrice() {
    return this.getIntlPrice(this.root.restaurantStore.branch.maxMbw);
  }

  // #endregion International strings

  // #region Offers Price

  /**
   * Method to get full offers price
   * @returns {number}
   */
  @computed get offersPrice() {
    return +sum(
      this.offers.map((offer) => offer.offer.totalPrice * offer.count)
    );
  }

  /**
   * Method to get offers price with discount
   * @returns {number}
   */
  @computed get offersPriceWithDiscountEnabled() {
    return this.root.deliveryAddressStore.isDelivery
      ? +sum(
          this.offers.map((offer) =>
            offer.offer.availableForDeliveryDiscount
              ? offer.offer.totalPrice * offer.count
              : 0
          )
        )
      : +sum(
          this.offers.map((offer) =>
            offer.offer.availableForSelfcollectDiscount
              ? offer.offer.totalPrice * offer.count
              : 0
          )
        );
  }

  // #endregion Offers Price

  // #region Order Price

  /**
   * Method to get full price for all products (without offers)
   * @return {number} order full price.
   * @memberof BasketStore#
   * @method orderPrice
   */
  @computed get orderPrice() {
    return +sum(
      this.products.map(
        (product) => product.product.priceWithIngredients * product.count
      )
    );
  }

  /**
   * Method to calculate price with discount for products and offers
   * @returns {*}
   */
  @computed get orderPriceForDiscountWithOffers() {
    return (
      this.productsPriceWithDiscountEnabled +
      this.offersPriceWithDiscountEnabled
    );
  }

  /**
   * Full price with offers
   * @returns {*}
   */
  @computed get orderPriceWithOffers() {
    return this.orderPrice + this.offersPrice;
  }

  /**
   * total price with discount and without delivery fee and coupon
   * @readonly
   * @memberof BasketStore
   */
  @computed get totalPriceWithoutDeliveryFeeWithOffers() {
    return this.orderPriceWithOffers - this.discountPriceWithOffers;
  }

  /**
   * Total price with delivery fee and discount and without coupon
   * @readonly
   * @memberof BasketStore
   */
  @computed get totalPriceWithOffers() {
    return (
      this.totalPriceWithoutDeliveryFeeWithOffers + this.calculatedDeliveryFee
    );
  }

  /**
   * Total price with no fee included but with discount and coupon
   * @returns {number}
   */
  @computed get priceWithoutFee() {
    return this.root.couponsStore.isCouponValid
      ? this.totalPriceWithoutDeliveryFeeWithOffers -
          this.root.couponsStore.calculateCouponSaleValueWithOffers
      : this.totalPriceWithoutDeliveryFeeWithOffers;
  }

  /**
   * Offer final price with coupon if included
   * @readonly
   * @memberof BasketStore
   */
  @computed get finalPriceWithOffers() {
    if (this.root.couponsStore.isCouponValid) {
      if (this.priceWithoutFee <= 0) {
        return this.calculatedDeliveryFee;
      }

      return (
        this.totalPriceWithOffers -
        this.root.couponsStore.calculateCouponSaleValueWithOffers
      );
    }

    return this.totalPriceWithOffers;
  }

  /** Method to get products price which should be counted in mbw calculation
   * @return {number} products price.
   * @memberof BasketStore#
   * @method productsPriceWithRespectToMbwCheck
   */
  @computed get productsPriceWithRespectToMbwCheck() {
    return +sum(
      this.products.map((product) =>
        product.product.countMbv
          ? product.product.priceWithIngredients * product.count
          : 0
      )
    );
  }

  /**
   * Offers sum price depending on isCountForMbw
   * @readonly
   * @memberof BasketStore
   */
  @computed get offersPriceWithRespectToMbwCheck() {
    return +sum(
      this.offers.map((offer) =>
        offer.offer.isCountForMbw ? offer.offer.totalPrice * offer.count : 0
      )
    );
  }

  /**
   * Offer and product price in basket that mbv affected
   * @readonly
   * @memberof BasketStore
   */
  @computed get orderPriceWithRespectToMbwCheckWithOffers() {
    return (
      this.productsPriceWithRespectToMbwCheck +
      this.offersPriceWithRespectToMbwCheck
    );
  }

  /**
   * Price to compare with mbv
   * @readonly
   * @memberof BasketStore
   */
  @computed get orderPriceForMbvWithDiscount() {
    return (
      this.orderPriceWithRespectToMbwCheckWithOffers -
      this.discountPriceWithOffers
    );
  }

  // #endregion Order Price

  // #region Discount

  /**
   * Discount with offers
   * @returns {number}
   */
  @computed get discountPriceWithOffers() {
    return this.orderPriceForDiscountWithOffers === 0
      ? 0
      : parseFloat(
          (
            this.orderPriceForDiscountWithOffers *
            (this.discountPercentage / 100)
          ).toFixed(2)
        );
  }

  @computed get useHungerDiscount() {
    return (
      this.root.themesStore.isHunger &&
      !this.root.favoritesStore.isAddedToFavorites
    );
  }

  /**
   * Method to get discount percentage
   */
  @computed get discountPercentage() {
    const { isDelivery } = this.root.deliveryAddressStore;
    const { isHunger } = this.root.themesStore;

    const {
      branch: {
        getDiscountDelivery,
        getDiscountPickUp,
        getDiscountHungerDelivery,
        getDiscountHungerPickup,
        getDiscountDeliveryApp,
        getDiscountPickupApp,
        getDiscountDeliveryWeb,
        getDiscountPickupWeb
      },
      restaurant: { hungerCustomer }
    } = this.root.restaurantStore;

    if (isHunger) {
      if (this.useHungerDiscount) {
        return isDelivery ? getDiscountHungerDelivery : getDiscountHungerPickup;
      }

      if (hungerCustomer) {
        return isDelivery ? getDiscountDeliveryApp : getDiscountPickupApp;
      }

      return isDelivery ? getDiscountDeliveryWeb : getDiscountPickupWeb;
    }

    return isDelivery ? getDiscountDelivery : getDiscountPickUp;
  }

  /**
   * Method to get products price with enabled discount.
   * @return {number} products price.
   * @memberof BasketStore#
   * @method productsPriceWithDiscountEnabled
   */
  @computed get productsPriceWithDiscountEnabled() {
    return this.root.deliveryAddressStore.isDelivery
      ? +sum(
          this.products.map((product) =>
            product.product.countDeliveryDiscount
              ? product.product.priceWithIngredients * product.count
              : 0
          )
        )
      : +sum(
          this.products.map((product) =>
            product.product.countPickupDiscount
              ? product.product.priceWithIngredients * product.count
              : 0
          )
        );
  }

  // #endregion Discount

  // #region Basket system methods

  /**
   * Method to automatically save serialized products into storage
   */
  subscribeStorageToStore() {
    const { restaurantTime, branch } = this.root.restaurantStore;
    const items = this.getToJS();

    this.root.orderPaymentMethodsStore.changeOrderId();

    this.storage.saveToStorage(this.localStorageKey, items, branch.branchId);

    this.storage.saveToStorage(
      this.lastUpdatedKey,
      restaurantTime,
      branch.branchId
    );
  }

  /**
   * Method to serialize every product in products list
   */
  getToJS() {
    return {
      products: this.products.map((product) => ({
        ...product,
        product: product.product.getToJS()
      })),
      offers: this.offers.map((offer) => ({
        ...offer,
        offer: offer.offer.getToJS()
      }))
    };
  }

  // #endregion Basket system
}

export default BasketStore;
