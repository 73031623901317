import classnames from 'classnames';
import React from 'react';

import Skeleton from 'client/ui/Skeleton';

import { ImageSkeletonProps } from './ImageSkeleton.type';

const ImageSkeleton = ({ backgroundColor, className }: ImageSkeletonProps) => (
  <Skeleton
    speed={2}
    width="100%"
    height="100%"
    backgroundColor={backgroundColor}
    className={classnames(className)}
  >
    <rect x="0" y="0" rx="0" ry="0" width="100vw" height="100%" />;
  </Skeleton>
);

export { ImageSkeleton };

export default React.memo(ImageSkeleton);
