/* eslint-disable camelcase */
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import sum from 'lodash/sum';
import { action, computed, toJS } from 'mobx';

import { IngredientModel } from './ingredient.model';

/**
 * Ingredients Group Model class. Used to sort and group ingredients.
 * @constructor
 * @param {number} id - unique identifier.
 * @param {object} ingredientsGroup - object of ingredients group.
 * @param {boolean} isExtraGroup - is ingredients belong to extra group or basic one
 * @param {object} product - link to the product
 * @property {number} id - Unique identifier.
 * @property {string} description - Description.
 * @property {number} pos - Position of the category.
 * @property {number} min_quan - Minimal quantity that user has to select.
 * @property {number} max_quan - Maximal quantity that user can select.
 * @property {number} free_quan - Quantity of ingredient, that user can take for free with 0 price.
 * @property {number} option_select - Define if the ingredient group should behave like a radio button.
 * @property {boolean} isExtraGroup - Is it extra_ingredient_group or basic_ingredient_group.
 * @property {boolean} expanded - Observable define if the panel is expanded.
 * @property {array <object>} ingredients - Observable ingredients array.
 * @property {boolean} isOfferProduct - is this ingredients group model from offer
 */
export class IngredientsGroupModel {
  public id: number;

  public pos: number;

  public description: string;

  public ingredients: IngredientModel[];

  public free_quan: number;

  public min_quan: number;

  public max_quan: number;

  public isExtraGroup: boolean;

  public option_select: number;

  public isOfferProduct: boolean;

  public isNegative: boolean;

  public calcNegativeExtrasPrice: boolean;

  constructor(
    id: string,
    ingredientsGroup: any,
    isExtraGroup = false,
    product: any,
    isOfferProduct = false,
    isNegative?: boolean,
    calcNegativeExtrasPrice?: boolean
  ) {
    ingredientsGroup.id = parseInt(id, 10);

    if (!isArray(ingredientsGroup.ingredients)) {
      ingredientsGroup.ingredients = map(
        ingredientsGroup.ingredients,
        (val, key) => {
          val.id = key;

          val.price_diff = val.price_diff || val.priceDiff;

          return val;
        }
      );
    }

    this.id = parseInt(ingredientsGroup.id, 10);

    this.pos = ingredientsGroup.pos || 0;

    this.description = ingredientsGroup.description || '';

    this.ingredients = ingredientsGroup.ingredients.map(
      (i: IngredientModel) =>
        new IngredientModel(
          i.id,
          i,
          product,
          ingredientsGroup.isNegative || isNegative
        )
    );

    this.free_quan =
      product.free_quan ||
      ingredientsGroup.free_quan ||
      ingredientsGroup.freeQty ||
      0;

    this.min_quan =
      product.min_quan ||
      ingredientsGroup.min_quan ||
      ingredientsGroup.minQty ||
      0;

    this.max_quan =
      product.max_quan ||
      ingredientsGroup.max_quan ||
      ingredientsGroup.maxQty ||
      0;

    this.isExtraGroup = isExtraGroup;

    this.isNegative = isNegative || ingredientsGroup.isNegative || false;

    this.option_select =
      this.max_quan === 1 && !this.isNegative
        ? 1
        : ingredientsGroup.option_select || ingredientsGroup.optionSelect;

    this.isOfferProduct = isOfferProduct;

    this.calcNegativeExtrasPrice =
      calcNegativeExtrasPrice ||
      ingredientsGroup.calcNegativeExtrasPrice ||
      false;
  }

  /**
   * Method to select only one ingredient and deselect others.
   * @param {number} id - The unique identifier of ingredient to select.
   */
  @action public selectOnlyOneIngredient(id: number): void {
    this.ingredients.forEach((ingredient: IngredientModel) => {
      if (ingredient.id === id) {
        ingredient.count = ingredient.count === 1 ? 0 : 1;
      } else {
        ingredient.count = 0;
      }
    });
  }

  /**
   * Method to get serialized data about ingredients.
   * @return {array} serialized ingredients data.
   */
  private getToJS() {
    return Object.assign(toJS({ ...this, root: {} }), {
      ingredients: this.ingredients.map((ingredient) => ingredient.getToJS())
    });
  }

  /**
   * Method to get selected ingredients.
   * @return {array} array of selected ingredients.
   */
  @computed get selectedIngredients(): IngredientModel[] {
    return (this.isOfferProduct
      ? this.ingredients.filter((ingredient) => ingredient.count > 0)
      : this.ingredients
          .filter((i) => !i.isExcluded) // filter unavailable ingredients to current size
          .filter((ingredient) => ingredient.count > 0)
          .sort((a, b) => a.addingTime.valueOf() - b.addingTime.valueOf())
    ).map((ingredient) => {
      ingredient.groudId = this.id;

      return ingredient;
    });
  }

  /**
   * Method to get count of selected ingredients.
   * @return {number} count of selected ingredients.
   */
  @computed get selectedIngredientsCount(): number {
    return +sum(this.selectedIngredients.map((ingredient) => ingredient.count));
  }

  /**
   * Method to get price of selected ingredients.
   * @return {number} price of selected ingredients.
   */
  @computed get selectedIngredientsPrice(): number {
    let count = this.free_quan;

    return count === -1
      ? 0
      : +sum(
          // eslint-disable-next-line array-callback-return
          this.selectedIngredients.map((ingredient) => {
            if (ingredient.count <= count) {
              ingredient.changeFree(ingredient.count);

              count -= ingredient.count;

              return 0;
            }

            if (ingredient.count > count) {
              const ingredientCount = ingredient.count - count;

              ingredient.changeFree(count);

              count = 0;

              let negativeRatio = 1;

              if (this.isNegative) {
                negativeRatio = this.calcNegativeExtrasPrice ? -1 : 0;
              }

              return ingredient.price * ingredientCount * negativeRatio;
            }
          })
        );
  }

  /**
   * Method to check free ingredient.
   * @return {boolean} is there free ingredient left or no.
   */
  @computed get isFreeIngredient(): boolean {
    return (
      this.selectedIngredientsCount < this.free_quan || this.free_quan === -1
    );
  }

  /**
   * Method to check is there selected required ingredient.
   * @return {boolean} is there selected required ingredient.
   */
  @computed get isRequiredIngredientsSelected(): boolean {
    return this.selectedIngredientsCount >= this.min_quan;
  }

  /**
   * Method to check ingredients existence.
   * @return {boolean} check result.
   */
  @computed get hasIngredients(): boolean {
    return !!this.ingredients.length;
  }

  /**
   * Method to checking is all ingredients in ingredient's group excluded
   * @returns {boolean}
   */
  @computed get isExcluded(): boolean {
    return this.ingredients.filter((i) => !i.isExcluded).length === 0;
  }

  /**
   * Method to check is current group required/mandatory (has one or more mandatory items)
   * @returns {boolean}
   */
  @computed get isMandatoryGroup(): boolean {
    return this.min_quan > 0;
  }
}
