/* eslint-disable global-require */
/**
 * @namespace
 * @property {object} defaults - Hermes theme id to image matching
 */
const images = {
  loading: require('../../assets/images/hermes_theme/animation2.svg'),
  payment_loading: require('../../assets/images/hermes_theme/animation.svg'),
  orderSmartLogo: require('../../assets/images/order_smart_logos/order-smart-logo.svg'),
  orderSmartWhiteLogo: require('../../assets/images/order_smart_logos/order-smart-logo-monocolor.svg'),
  iconWithoutDiscount: require('../../assets/images/hermes_theme/icons_without_discount.svg'),
  iconWithoutMinOrder: require('../../assets/images/hermes_theme/icons_without_min_order.svg'),
  mobileAppsPlaceholder: require('../../assets/images/hermes_theme/hand.png'),
  errorPageImage: require('../../assets/images/hermes_theme/404-chef.svg'),
  houseIcon: require('../../assets/images/hermes_theme/postcode_icon.svg'),
  warningIcon: require('../../assets/images/hermes_theme/error.svg'),
  iconDelivery: require('../../assets/images/hermes_theme/icon_delivery.svg'),
  iconDeliveryPreorder: require('../../assets/images/hermes_theme/icon_delivery_preorder.svg'),
  iconPickup: require('../../assets/images/hermes_theme/icon_pickup.svg'),
  iconPickupPreorder: require('../../assets/images/hermes_theme/icon_pickup_preorder.svg'),
  iconMail: require('../../assets/images/hermes_theme/icon_mail.svg'),
  iconPhone: require('../../assets/images/hermes_theme/icon_phone.svg'),
  iconUser: require('../../assets/images/hermes_theme/icon_user.svg'),
  iconCalendar: require('../../assets/images/hermes_theme/icon_calendar.svg'),
  iconTime: require('../../assets/images/hermes_theme/icon_time.svg'),
  iconSoldOut: require('../../assets/images/hermes_theme/icon_sold_out.svg'),
  iconPayPal: require('../../assets/images/hermes_theme/icon_paypal.svg'),
  iconWait: require('../../assets/images/hermes_theme/icon_wait.svg'),
  greyBg: require('../../assets/images/hermes_theme/grey-square.svg'),
  searchIcon: require('../../assets/images/hermes_theme/search.svg'),
  clearFieldIcon: require('../../assets/images/hermes_theme/clear-field.svg'),
  clearFieldBoldIcon: require('../../assets/images/hermes_theme/clear-field-bold.svg'),
  PBG: require('../../assets/images/hermes_theme/powered_by_google_on_white.png'),
  upIcon: require('../../assets/images/hermes_theme/up.svg'),
  checkMarkIcon: require('../../assets/images/hermes_theme/icon_check_mark.svg'),
  GPSIcon: require('../../assets/images/hermes_theme/icon_gps.svg'),
  boldCrossIcon: require('../../assets/images/hermes_theme/icon_bold_cross.svg'),
  bigCheckmarkIcon: require('../../assets/images/hermes_theme/icon_big_checkmark.svg'),
  napkinIcon: require('../../assets/images/hermes_theme/napkin.svg'),
  courierIcon: require('../../assets/images/hermes_theme/courier_icon.svg'),
  waiterIcon: require('../../assets/images/hermes_theme/waiter_icon.svg'),
  crossIcon: require('../../assets/images/hermes_theme/icons-x.svg'),
  addedToFavorites: require('../../assets/images/hermes_theme/icon_added_to_favorites.svg'),
  removedFromFavorites: require('../../assets/images/hermes_theme/icon_removed_from_favorites.svg')
};

export default images;
