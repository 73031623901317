import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { generateWorktimeForDay, getDayShort } from '../../../../utils/time';

class FullHours extends PureComponent {
  handleClick = () => {
    this.props.onClickTimePanel(true);
  };

  render() {
    const { days, t, isDelivery } = this.props;
    return (
      <div
        className="opening-hours-component white-block desktop-only"
        onClick={this.handleClick}
      >
        <div className="times-block">
          {isDelivery ? (
            <span className="title">
              <i className="fas fa-motorcycle" />
              {t('deliveryHours')}
            </span>
          ) : (
            <span className="title">
              <i className="fas fa-clock" />
              {t('openingHours')}
            </span>
          )}
          {days.map((sameDays, i) => {
            const day = sameDays[0];
            return (
              <div className="times" key={i}>
                {sameDays.map((day, index) => {
                  return (
                    <span key={day.day}>
                      {getDayShort(day.day) +
                        (index === sameDays.length - 1 ? ': ' : ', ')}
                    </span>
                  );
                })}
                {generateWorktimeForDay(day)}
              </div>
            );
          })}
        </div>
        <div className="chevron-block desktop-only">
          <span className="fas fa-chevron-up" />
        </div>
      </div>
    );
  }
}

export default withTranslation(['opening_hours'])(FullHours);
