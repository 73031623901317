/**
 * Ingredient Request Model class.
 * Used for prepare ingredient to the backend request.
 * @constructor
 * @param {number} groupId - Id of the group
 * @param {number} ingredientId - Id of the ingredient
 * @property {number} gi - group id.
 * @property {number} id - ingredient id
 */
export class IngredientRequestModel {
  /**
   * Group Id
   */
  public gi: number;

  /**
   * Ingredient Id
   */
  public id: number;

  /**
   * Ingredient price
   */
  public price: number;

  constructor(groupId: number, ingredientId: number, price: number) {
    this.gi = groupId;

    this.id = ingredientId;

    this.price = Number(price) || 0;
  }
}
