import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Measure from 'react-measure';
import { withRouter } from 'react-router';

import './mobile-styles.scss';
import { IS_CLIENT } from 'config';

import states from '../../../../../enums/states.enum';
import { goBackInHermes } from '../../../../../utils/native-apps-communication';
import { generateLinkFor } from '../../../../../utils/routing';
import { CategoryMenuSlider } from '../../category_menu/_category_menu_mobile/category_menu_slider/CategoryMenuSlider';

import BranchInfoMobile from './branch-info';
import BranchInfoDiscountMobile from './branch-info-discount';
import BranchNameMobile from './branch-name';
import StickyHeaderMobile from './sticky-header';

@inject('store')
@withRouter
@observer
export default class NewHeaderMobile extends Component {
  static propTypes = {
    showSearch: PropTypes.bool
  };

  /**
   * logo click action
   */
  handleLogoClick() {
    this.props.history.push(
      generateLinkFor(states.kundeninformation, this.props, {}, true)
    );
  }

  render() {
    const { showSearch, history, match } = this.props;

    const {
      basketStore,
      restaurantStore,
      seoDataStore,
      categoryMenuStore,
      themesStore,
      orderPaymentMethodsStore,
      deliveryAddressStore,
      openingHoursStore,
      maintenanceModeModalStore
    } = this.props.store;

    if (!restaurantStore.branch.id) {
      return null;
    }

    const altText = seoDataStore.makeSeoBranchTitle('');
    const { activeCategory, categories, categorySlideId } = categoryMenuStore;
    const { clickedProductTopOffset } = themesStore;
    const { isSlugSupported, branch } = restaurantStore;
    const postHeaderHeight = clickedProductTopOffset ? 0 : 'initial';
    const isDiscount = basketStore.discountPercentage !== 0;

    const handleOnResize = (contentRect) => {
      const isPhoneHide = branch.getHideTel;

      const headerHeight = isPhoneHide
        ? contentRect.offset.height
        : contentRect.offset.height + 44;

      contentRect.offset.height !==
        themesStore.postHeaderBranchInfoMobileHeight &&
        themesStore.setPostHeaderHeight(headerHeight);
    };

    return (
      <>
        <StickyHeaderMobile
          branchName={restaurantStore.branch.branchName}
          setFixedStyleState={(state) => themesStore.setFixedStyleState(state)}
          isHideMarketingData={restaurantStore.restaurant.isHideMarketingData}
          isTableReservationAvailable={
            restaurantStore.branch.branchIsTableReservation
          }
          setDefaultCategory={categoryMenuStore.setFirstCategoryActive}
          showSearch={showSearch}
          isPortalWebThemeActive={themesStore.isPortalWebThemeActive}
          backAction={() => goBackInHermes(history, match, isSlugSupported)}
        />
        <Measure offset onResize={handleOnResize}>
          {({ measureRef }) => (
            <div id="post-header-mobile" style={{ height: postHeaderHeight }}>
              <BranchNameMobile
                isShowPhone={!restaurantStore.branch.getHideTel}
                phone={restaurantStore.branch.getWebsitePhone}
              />

              <header
                ref={measureRef}
                id="branch-information"
                className="mobile-header"
              >
                <BranchInfoMobile
                  paymentMethods={
                    orderPaymentMethodsStore.paymentMethodsFilteredByOrderAmount
                  }
                  logo={restaurantStore.restaurant.logo}
                  areaCodes={restaurantStore.branch.areaCodes}
                  altText={altText}
                  // eslint-disable-next-line react/jsx-no-bind
                  onLogoClick={this.handleLogoClick.bind(this)}
                  locationChosen={restaurantStore.isLocationChosen}
                  minDeliveryFee={restaurantStore.branch.minDeliveryFee}
                  maxDeliveryFee={restaurantStore.branch.maxDeliveryFee}
                  intlMinDeliveryFee={basketStore.intlMinDeliveryFee}
                  intlMaxDeliveryFee={basketStore.intlMaxDeliveryFee}
                  deliveryFee={basketStore.deliveryFee}
                  intlDeliveryFee={basketStore.intlDeliveryFee}
                  currentAreaCode={restaurantStore.currentAreaCode}
                  isDelivery={deliveryAddressStore.isDelivery}
                  isLocationChosen={restaurantStore.isLocationChosen}
                  mbvMaxPrice={basketStore.mbwMaxPrice}
                  mbvMinPrice={basketStore.mbwMinPrice}
                  minPrice={basketStore.intlMinPrice}
                  activeOrderType={deliveryAddressStore.activeOrderType?.value}
                  deliveryDay={openingHoursStore.todayDeliveryHours}
                  pickupDay={openingHoursStore.todayOpeningHours}
                  hasPreorder={openingHoursStore.currentOrderTypeHasPreorder}
                  holidayModeTime={
                    restaurantStore.branch.secondsUntilOpenPickUp
                  }
                  isFirstHalfTime={openingHoursStore.isFirstHalfTime}
                  isHolidayMode={restaurantStore.branch.isHolidayMode}
                  isMaintenanceModeActive={
                    maintenanceModeModalStore.maintenanceIsActive
                  }
                  isOpenNow={openingHoursStore.isOpenNow}
                  isTodayDeliveryHasBreakTime={
                    openingHoursStore.isTodayDeliveryHasBreakTime
                  }
                  isTodayOpeningsHasBreakTime={
                    openingHoursStore.isTodayOpeningsHasBreakTime
                  }
                  isDeliveryAvailable={deliveryAddressStore.hasDelivery}
                  deliveryFreeFrom={basketStore.intlDeliveryFreeFrom}
                  averageDeliveryTime={
                    restaurantStore.branch.averageDeliveryTime
                  }
                  maxSelfcollectTime={restaurantStore.branch.maxSelfcollectTime}
                />

                {isDiscount && (
                  <BranchInfoDiscountMobile
                    discountPercentage={basketStore.discountPercentage}
                  />
                )}
              </header>
            </div>
          )}
        </Measure>

        {activeCategory && IS_CLIENT ? (
          <CategoryMenuSlider
            useHashes
            smoothScrollhashKey="cat-item-"
            excludeCategoryIds={[-2]}
            scrollOffset={-100}
            categories={categories}
            categorySlideId={categorySlideId}
            activeCategoryId={activeCategory.id}
          />
        ) : null}
      </>
    );
  }
}
