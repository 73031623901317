/* eslint-disable padding-line-between-statements */
declare let opr: any;
declare let InstallTrigger: unknown;
declare let safari: any;

const getCurrentBrowser = () => {
  const isOpera =
    (!!(window as any).opr && !!opr.addons) ||
    !!(window as any).opera ||
    navigator.userAgent.indexOf(' OPR/') >= 0;

  // Firefox 1.0+
  const isFirefox = typeof InstallTrigger !== 'undefined';

  // Safari 3.0+ "[object HTMLElementConstructor]"
  // const isSafari =
  //   /constructor/i.test((window as any).HTMLElement) ||
  //   ((p) => p.toString() === '[object SafariRemoteNotification]')(
  //     !(window as any)['safari'] || (typeof safari !== 'undefined' && safari.pushNotification)
  //   );

  // Internet Explorer 6-11
  const isIE = /* @cc_on!@ */ false || !!(document as any).documentMode;
  // Edge 20+
  const isEdge = !isIE && !!window.StyleMedia;

  // Chrome 1 - 79
  const isChrome =
    !!(window as any).chrome &&
    (!!(window as any).chrome.webstore || !!(window as any).chrome.runtime);

  // Edge (based on chromium) detection
  const isEdgeChromium = isChrome && navigator.userAgent.indexOf('Edg') !== -1;
  // eslint-disable-next-line no-useless-escape
  const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const browsers = {
    isOpera,
    isFirefox,
    isIE,
    isEdge,
    isEdgeChromium,
    isSafari,
    iOS
  };

  return browsers;
};

export default getCurrentBrowser;
