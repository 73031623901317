import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './ButtonsBar.scss';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Utils
import states from '../../../../../../enums/states.enum';
import { ShellModalsRouteType } from '../../../../../../routes/ShellModalsRoute/ShellModalsRoute.type';
import { share } from '../../../../../../utils/native-apps-communication';
import openModal from '../../../../../../utils/openModal/openModal';
import { generateLinkFor } from '../../../../../../utils/routing';
// Config
import RoundShellButton from '../../buttons-shell/round-button';
import WideHomepageButton from '../../buttons-shell/wide-homepage-button';

@withRouter
@inject('store')
@observer
class DefaultButtonsBar extends Component {
  buttons = [];

  static propTypes = {
    areaCode: PropTypes.string,
    changeOrderType: PropTypes.func.isRequired,
    isBranchSelectingSkipped: PropTypes.bool.isRequired,
    isPickupAvailable: PropTypes.bool.isRequired,
    isDeliveryAvailable: PropTypes.bool.isRequired,
    isTableReservationAvailable: PropTypes.bool.isRequired,
    isCallAvailable: PropTypes.bool.isRequired,
    isShareAvailable: PropTypes.bool.isRequired,
    isOpeningsTimeAvailable: PropTypes.bool.isRequired,
    isNewsAvailable: PropTypes.bool.isRequired,
    hasOneBranch: PropTypes.bool.isRequired,
    phone: PropTypes.string,
    shareContent: PropTypes.object.isRequired,
    style: PropTypes.object,
    showPhoneNumber: PropTypes.bool
  };

  static defaultProps = {};

  /**
   * Categories action in shell home screen
   */
  _categoriesAction = () => {
    this.props.history.push(generateLinkFor(states.cat, this.props, {}, true));
  };

  /**
   * Method to creating route by state name
   * @param {states} stateName - routes state name
   * @private
   */
  _pushAction = (stateName) => {
    const state = generateLinkFor(stateName, this.props, {}, true);

    this.props.history.push(state);
  };

  /**
   * Action on share button
   * @private
   */
  _shareAction = () => {
    share(this.props.shareContent);
  };

  /**
   * Method to render bottom buttons
   * @returns {*[]}
   * @private
   */
  _buttonsToShow = () => {
    const {
      t,
      isTableReservationAvailable,
      isCallAvailable,
      isOpeningsTimeAvailable,
      isShareAvailable,
      phone,
      showPhoneNumber,
      isNewsAvailable,
      store
    } = this.props;

    const { analyticsStore } = store;

    this.buttons = [];

    const categoriesButton = (
      <RoundShellButton
        key={t('common:lblMenu')}
        label={t('common:lblMenu')}
        icon="mdi-cart"
        isCenter
        action={this._categoriesAction}
        style={this.props.style}
        dataTestId="shell-open-cats"
      />
    );

    if (isCallAvailable && phone && showPhoneNumber) {
      this.buttons.push(
        <RoundShellButton
          key={t('common:lblCallUs')}
          label={t('common:lblCallUs')}
          icon="mdi-phone"
          link={`tel:${phone}`}
          style={this.props.style}
          action={() => analyticsStore.sendPhoneNumberAppMainPage()}
        />
      );
    }

    const openTableReservationModal = (event) => {
      event.stopPropagation();

      const { history } = this.props;
      const { TABLE_RESERVATION } = ShellModalsRouteType;
      const path = openModal(TABLE_RESERVATION);

      history.push(path);
    };

    if (isTableReservationAvailable) {
      this.buttons.push(
        <RoundShellButton
          key={t('footer:btnTableReservation')}
          label={t('footer:btnTableReservation')}
          icon="mdi-format-list-bulleted"
          action={openTableReservationModal}
          style={this.props.style}
        />
      );
    }

    if (isOpeningsTimeAvailable) {
      this.buttons.push(
        <RoundShellButton
          key={t('opening_hours:lblWorkingHours')}
          label={t('opening_hours:lblWorkingHours')}
          icon="mdi-door"
          action={() => this._pushAction(states.openingTimes)}
          style={this.props.style}
        />
      );
    }

    if (isShareAvailable) {
      this.buttons.push(
        <RoundShellButton
          key={t('common:lblShare')}
          label={t('common:lblShare')}
          icon="mdi-share-variant"
          action={() => this._shareAction()}
          style={this.props.style}
        />
      );
    }

    if (isNewsAvailable) {
      this.buttons.push(
        <RoundShellButton
          key={t('title:lblNews')}
          label={t('title:lblNews')}
          icon="mdi-newspaper"
          action={() => this._pushAction(states.news)}
          style={this.props.style}
        />
      );
    }

    this.buttons = this.buttons.slice(0, 2);

    // Adding categories button to center or end of buttons array
    this.buttons.length === 2
      ? this.buttons.splice(1, 0, categoriesButton)
      : this.buttons.push(categoriesButton);

    return this.buttons.length === 1
      ? this._renderSingleButton()
      : this.buttons;
  };

  /**
   * Method to render single cart button if there is no other buttons available
   * @returns {*}
   * @private
   */
  _renderSingleButton = () => {
    const { t } = this.props;

    return (
      <WideHomepageButton
        action={this._categoriesAction}
        label={t('common:lblMenu')}
        style={this.props.store.themesStore.buttonsWideBarStyle()}
      />
    );
  };

  render() {
    const view = this._buttonsToShow();
    const isSingle = !Array.isArray(view);

    return (
      <div
        className={classNames({
          'buttons-bar': true,
          single: isSingle
        })}
        style={this.props.style}
      >
        {view}
      </div>
    );
  }
}

export default withTranslation([
  'enter_address_modal',
  'footer',
  'common',
  'opening_hours'
])(DefaultButtonsBar);
