import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { generateImageFromText } from '../../../../../utils/functions';

import './LegalContent.scss';

/**
 * Component to render legal info content
 */
export default class LegalContent extends PureComponent {
  static propTypes = {
    content: PropTypes.string
  };
  static defaultProps = {};

  render() {
    const startParseObject = {
      string: '{{',
      offset: 1
    };
    const endParseObject = {
      string: '}}',
      offset: 1
    };
    const size = {
      width: 400,
      height: 20,
      fontSize: 14
    };
    const parsedHTML =
      this.props.content &&
      generateImageFromText(
        this.props.content,
        startParseObject,
        endParseObject,
        size
      );
    const htmlContent = { __html: parsedHTML };
    return (
      <div className="imprint-text">
        <div dangerouslySetInnerHTML={htmlContent}></div>
      </div>
    );
  }
}
