import cn from 'classnames';
import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { supportedLanguages } from 'config';

import i18n from '../../../../../../i18n';
import useOutsideClick from '../../../../../hooks/useOutsideClick';
import languageService from '../../../../../services/LanguageService';
import FakeButton from '../../../../../ui/FakeButton/FakeButton';

import style from './LanguageSwitcherShell.module.scss';

const classes = {
  enter: style.enter,
  enterActive: style.enterActive,
  exit: style.exit,
  exitActive: style.exitActive
};

type LanguageSwitcherMobileProps = {
  className?: string;
  inlineStyle?: React.CSSProperties;
};

const LanguageSwitcherShell = ({
  className,
  inlineStyle
}: LanguageSwitcherMobileProps) => {
  const listRef = useRef<HTMLUListElement>(null);
  const headRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<null | NodeJS.Timeout>(null);
  const [open, setOpen] = useState<boolean>(false);

  useOutsideClick([listRef, headRef], () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setOpen(false);
  });

  const selectLanguage = (langCode: AppLang) => () => {
    languageService.selectLanguage(langCode);

    timerRef.current = setTimeout(() => setOpen(false), 190);
  };

  const openMenu = (e: React.MouseEvent) => {
    e.stopPropagation();

    setOpen(!open);
  };

  return (
    <div className={style.LanguageSwitcherShell} data-testid="langpicker-menu">
      <FakeButton
        className={className}
        onClick={openMenu}
        ref={headRef}
        style={inlineStyle}
      >
        <i className="fas fa-globe" data-testid="langpicker-icon-menu" />
        <div className="title" data-testid="langpicker-title-menu">
          {i18n.t('title:languageSelection')}
        </div>
      </FakeButton>
      <CSSTransition in={open} timeout={200} classNames={classes} unmountOnExit>
        <ul className={style.list} ref={listRef}>
          {supportedLanguages.map((lang) => (
            <li
              key={lang.code}
              className={cn(style.item, className)}
              onClick={selectLanguage(lang.code)}
              style={inlineStyle}
              data-testid={`${lang.code}-lang-menu`}
            >
              <span
                className={style.langCode}
                data-testid={`${lang.code}-lang-icon-menu`}
              >
                {lang.code.toUpperCase()}
              </span>
              <span data-testid={`${lang.code}-lang-title-menu`}>
                {lang.title}
              </span>
            </li>
          ))}
        </ul>
      </CSSTransition>
    </div>
  );
};

export default LanguageSwitcherShell;
