import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';

import states from '../../../../../../enums/states.enum';
import { goBackInHermes } from '../../../../../../utils/native-apps-communication';
import { generateLinkFor } from '../../../../../../utils/routing';
import BranchNameMobile from '../branch-name';
import StickyHeaderMobile from '../sticky-header';

@withRouter
@inject('store')
export default class CommonHeaderMobile extends Component {
  static propTypes = {
    showSearch: PropTypes.bool,
    showBranch: PropTypes.bool
  };

  static defaultProps = {
    showBranch: true
  };

  goBack = () => {
    const { history, match } = this.props;
    const { orderPaymentMethodsStore, restaurantStore } = this.props.store;
    const { isSlugSupported } = restaurantStore;

    const slug = isSlugSupported
      ? '/:slug/:branchId/checkout'
      : '/:branchId/checkout';

    const isCheckoutPage = match.path === slug;

    const isOrderSuccess =
      orderPaymentMethodsStore.isOrderSent && !orderPaymentMethodsStore.isError;

    const isOnlinePaymentSuccess = new URLSearchParams(
      history.location.search
    ).get('success');

    if (isOnlinePaymentSuccess || (isOrderSuccess && isCheckoutPage)) {
      this.props.history.push(
        generateLinkFor(states.app, this.props, {}, true)
      );
    } else {
      goBackInHermes(history, match, isSlugSupported);
    }
  };

  render() {
    const {
      themesStore,
      restaurantStore,
      categoryMenuStore
    } = this.props.store;

    const { showSearch, showBranch } = this.props;

    return (
      <>
        <StickyHeaderMobile
          branchName={restaurantStore.branch.branchName}
          setFixedStyleState={(state) => themesStore.setFixedStyleState(state)}
          isHideMarketingData={restaurantStore.restaurant.isHideMarketingData}
          isTableReservationAvailable={
            restaurantStore.branch.branchIsTableReservation
          }
          setDefaultCategory={categoryMenuStore.setFirstCategoryActive}
          isPortalWebThemeActive={themesStore.isPortalWebThemeActive}
          backAction={this.goBack}
          showSearch={showSearch}
        />
        {showBranch && (
          <BranchNameMobile
            isShowPhone={!restaurantStore.branch.getHideTel}
            phone={restaurantStore.branch.getWebsitePhone}
          />
        )}
      </>
    );
  }
}
