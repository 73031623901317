import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';

import { loadGoogleMaps } from 'client/services/googleService';
import { IS_CLIENT } from 'config';

import CookieConfirmation from './components/CookieConfirmation/CookieConfirmation';
import ErrorBoundary from './components/ErrorBoundary';
import DEVICES from './enums/device.enum';
import statesEnum from './enums/states.enum';
import { mainRoute } from './routes/main';
import ReactRouterDataSwitchInternal from './routes/react-router-switch';
import RootStore from './stores';
import 'antd/dist/antd.css';

@inject('store')
@observer
@(withRouter as any)
export default class App extends Component<
  { store: RootStore } & RouteComponentProps
> {
  state = {
    viewShopEventSent: false
  };

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions = () => {
    this.props.store.themesStore.setDevice(
      window.innerWidth < 1024 ? DEVICES.MOBILE : DEVICES.DESKTOP
    );
  };

  componentDidMount() {
    this.props.store.analyticsStore.init();

    this.sendViewShopEvent();

    this.updateDimensions();

    window.addEventListener('resize', this.updateDimensions);

    this.loadGoogleService();

    const queryParams = new URLSearchParams(this.props.location.search);

    if (
      queryParams.has(statesEnum.queryParameters.basketData) ||
      queryParams.has(statesEnum.queryParameters.dynToken)
    ) {
      queryParams.delete(statesEnum.queryParameters.basketData);

      queryParams.delete(statesEnum.queryParameters.dynToken);

      this.props.history.replace({
        search: queryParams.toString()
      });
    }
  }

  componentDidUpdate() {
    this.loadGoogleService();

    this.sendViewShopEvent();
  }

  componentWillUnmount() {
    if (IS_CLIENT) {
      window.removeEventListener('resize', this.updateDimensions);
    }
  }

  loadGoogleService() {
    const { restaurantStore } = this.props.store;
    const lang = restaurantStore.restaurantLanguage;

    if (lang) {
      loadGoogleMaps(lang);
    }
  }

  sendViewShopEvent = () => {
    const {
      restaurantStore: { restaurant },
      analyticsStore
    } = this.props.store;

    const { viewShopEventSent } = this.state;

    if (!viewShopEventSent && restaurant.getTitle) {
      analyticsStore.sendViewShop();

      this.setState({
        viewShopEventSent: true
      });
    }
  };

  render() {
    return (
      <ErrorBoundary>
        <ReactRouterDataSwitchInternal>
          {mainRoute(
            this.props.store.restaurantStore.hostName,
            this.props.store.aggregatorStore.aggregator.getAggregatorMode
          ).map((route) => (
            <Route key={route.path} {...route} />
          ))}
        </ReactRouterDataSwitchInternal>
        {this.props.store.themesStore.isShellThemeActive || (
          <CookieConfirmation />
        )}
      </ErrorBoundary>
    );
  }
}
