import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ReactSVG from 'react-svg';

import { ModalsRouteType } from 'client/routes/ModalsRoute/ModalsRoute.type';
import openModal from 'client/utils/openModal';

import hermes_images from '../../../../../../../enums/images_enums/hermes_images.enum';
import { cantAddProductsToBasket } from '../../../../../../../utils/routing';
import CommentForm from '../../../../../components/basket/comment-form/CommentForm';
import EditButton from '../../../../../components/basket/edit-button';
import BasketControlButtonShell from '../../../buttons-shell/basket-controls-button';

import './styles.scss';

/**
 * Component to render item in shell basket and shell order page
 */
@withRouter
@inject('store')
@observer
class BasketShellItem extends Component {
  static propTypes = {
    plusAction: PropTypes.func.isRequired,
    minusAction: PropTypes.func.isRequired,
    getPrice: PropTypes.func.isRequired,
    prepareProductsForEditing: PropTypes.func,
    setChangeIndex: PropTypes.func.isRequired,
    indexOfProduct: PropTypes.number.isRequired,
    isReadOnly: PropTypes.bool,
    disablePlusButton: PropTypes.bool
  };

  static defaultProps = {
    isReadOnly: false,
    disablePlusButton: false
  };

  saveProductCommentIntoStore = (uniqString, commentText) => {
    this.props.store.basketStore.setProductComment(uniqString, commentText);
  };

  /**
   * Method to handle click on plus button
   */
  _handlePlus = () => {
    const isOffer = !!this.props.group.offer;
    const product = isOffer ? this.props.group.offer : this.props.group.product;

    this.props.plusAction(product);
  };

  /**
   * Method to handle click on minus button
   */
  _handleMinus = () => {
    const { count } = this.props.group;
    const isOffer = !!this.props.group.offer;
    const product = isOffer ? this.props.group.offer : this.props.group.product;

    this.props.minusAction(product, count);
  };

  /**
   * Method to render name of product size extra
   * @param product
   * @returns {string}
   * @private
   */
  _getBasePriceLabel = (product) =>
    product.activeSize.name
      ? this.props.t('basicPriceWithSize', { size: product.activeSize.name })
      : this.props.t('basicPrice');

  /**
   * Method to render size info of the product in shell basket
   * @param label
   * @param price
   * @returns {*}
   * @private
   */
  _renderSize = (label, price) => (
    <div className="extra size">
      <div className="extra-name" data-testid="ingr-name">
        {label}
      </div>
      <div className="extra-price" data-testid="ingr-price">
        {price}
      </div>
    </div>
  );

  /**
   * Method to render product extras list in shell basket
   * @param product
   * @param count
   * @returns {*}
   * @private
   */
  _renderProductExtrasList = (product, count) => {
    const productExtraModalLink = openModal('extrasModal', {
      productId: product.id
    });

    return (
      <div className="product-extras-list">
        {!this.props.group.offer && (
          <CommentForm
            group={this.props.group}
            saveProductComment={this.saveProductCommentIntoStore}
            wrapperStyle={this.props.store.themesStore.wrapperStyle()}
            dataTestId="comment-of-product"
          />
        )}
        {this._renderSize(
          this._getBasePriceLabel(product),
          product.getIntlPrice
        )}
        {product.ingredientGroups.map((extra) =>
          this._renderSingleExtra(extra)
        )}

        <div className="extra edit">
          <div className="edit-button" data-testid="edit-product-btn">
            {!cantAddProductsToBasket(this.props) && !product.is_fixed ? (
              <EditButton
                title={this.props.t('basket:basketChange')}
                link={productExtraModalLink}
                onClick={
                  () =>
                    this.props.prepareProductsForEditing(
                      this.props.group,
                      this.props.indexOfProduct
                    )
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            ) : null}
          </div>
          <div className="total-price" data-testid="total-product-price-basket">
            {count > 1 ? `${count} x ` : null}
            {product.intlPriceWithIngredients}
          </div>
        </div>
      </div>
    );
  };

  /**
   * Method for render offers limits info in basket
   * @returns {null}
   * @private
   */
  _renderOfferLimitsMessage = (product) =>
    product.maxQty ? (
      <div className="extra size">
        {this.props.t('offers:offerLimits', { value: product.maxQty })}
      </div>
    ) : null;

  /**
   * Method to render offer extras list in shell basket
   * @param offer
   * @param count
   * @returns {*}
   * @private
   */
  _renderOfferExtrasList = (offer, count) => {
    const offerModalUrl = openModal(ModalsRouteType.OFFER_MODAL, {
      offerType: offer.offerType,
      offerUniqString: offer.uniqString
    });

    return (
      <div className="product-extras-list">
        {this._renderOfferLimitsMessage(offer)}
        {this._renderSize(
          this.props.t('offerPrice'),
          this.props.getPrice(offer.price)
        )}
        {offer.groups
          ? offer.groups.map((extra) =>
              extra && extra.cachedProducts
                ? this._renderSingleOfferExtra(extra)
                : null
            )
          : null}

        <div className="extra edit">
          <div className="edit-button">
            {!cantAddProductsToBasket(this.props) ? (
              <EditButton
                onClick={this.props.setChangeIndex(this.props.indexOfProduct)}
                title={this.props.t('basket:basketChange')}
                link={
                  offer.isValid || offer.isValidForPreorderNow
                    ? offerModalUrl
                    : '#'
                }
              />
            ) : null}
          </div>
          <div className="total-price" data-testid="total-product-price-basket">
            {count > 1 ? `${count} x ` : null}
            {this.props.getPrice(offer.totalPrice)}
          </div>
        </div>
      </div>
    );
  };

  /**
   * Method to render single extra line for offers in shell basket
   * @param extra
   * @returns {null[]}
   * @private
   */
  _renderSingleOfferExtra = (extra) =>
    extra.cachedProducts.map((product) =>
      product.count > 0 ? this._renderSingleExtra(product) : null
    );

  /**
   * Method to render single extra line in shell basket
   * @param extra
   * @returns {*}
   * @private
   */
  _renderSingleExtra = (extra) =>
    sortBy(extra.selectedIngredients, (ingredient) => ingredient.name).map(
      (ingredient) => {
        const editSymbol = ingredient.isNegative ? '-' : '+';

        let price = (ingredient.count - ingredient.free) * ingredient.price;

        if (extra.free_quan < 0) {
          price = 0;
        }

        return (
          <div className="extra" key={ingredient.id}>
            <div className="extra-name" data-testid="ingr-name">
              {ingredient.count > 1
                ? `${editSymbol} ${ingredient.count}x ${ingredient.name}`
                : `${editSymbol} ${ingredient.name}`}
              {ingredient.free > 0 &&
                ` (${this.props.t('basket:freeIngredients', {
                  count: ingredient.free
                })})`}
            </div>
            <div className="extra-price" data-testid="ingr-price">
              {this.props.getPrice(price)}
            </div>
          </div>
        );
      }
    );

  /**
   * Method to render product condition icon
   * @param isShow - is icon should be shown
   * @param iconClass - class of icon
   * @param path - path to image
   * @returns {null}
   * @private
   */
  _renderProductConditionIcon = (isShow, iconClass, path) =>
    isShow ? (
      <ReactSVG
        src={path}
        beforeInjection={(svg) => {
          svg.setAttribute(
            'class',
            `${svg.getAttribute('class')} ${iconClass}`
          );

          svg.setAttribute('style', 'width: 20px; height: 30px;');
        }}
      />
    ) : null;

  /**
   * Method to render product's count
   * @param {boolean} isReadOnly - is hide minus/plus buttons
   * @param {number} count - count of product
   * @returns {*}
   * @private
   */
  _renderProductCount = (isReadOnly, count, product) => {
    const { disablePlusButton } = this.props;

    const buttonStyle = this.props.store.themesStore.isTemplateAvailable
      ? {
          borderColor: `${this.props.store.themesStore.wrapperStyle().color}1A`
        }
      : undefined;

    return isReadOnly ? (
      <div className="controls">
        <div className="product-count" data-testid="product-count-basket">
          {`x ${count}`}
        </div>
      </div>
    ) : (
      <div className="controls">
        <BasketControlButtonShell
          mdiClass="mdi-minus"
          action={this._handleMinus}
          dataTestId="basket-minus"
          style={buttonStyle}
        />
        <div className="product-count" data-testid="product-count-basket">
          {count}
        </div>
        <BasketControlButtonShell
          disabled={disablePlusButton || !product.isValidForOrder}
          mdiClass="mdi-plus"
          colored
          action={this._handlePlus}
          dataTestId="basket-plus"
          style={buttonStyle}
        />
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const { count } = this.props.group;
    const isOffer = !!this.props.group.offer;
    const product = isOffer ? this.props.group.offer : this.props.group.product;
    const notAvailableError = t('errors:notAvailableForThisOrderType');

    return (
      <div
        className={classNames({
          'shell-basket-product': true
        })}
        data-testid="product-name-basket"
      >
        <div className="header">
          <div className="title" data-testid="product-name-basket">
            {this._renderProductConditionIcon(
              !product.isHideDiscountBadge,
              'icon-without-discount',
              hermes_images.iconWithoutDiscount
            )}
            {this._renderProductConditionIcon(
              product.count_mbw !== 1,
              'icon-without-mbv',
              hermes_images.iconWithoutMinOrder
            )}
            {isOffer ? product.title : product.name}
          </div>
          {this._renderProductCount(this.props.isReadOnly, count, product)}
        </div>
        {!product.isValidForOrder && (
          <div className="error">
            <p>{notAvailableError}</p>
          </div>
        )}
        {isOffer
          ? this._renderOfferExtrasList(product, count)
          : this._renderProductExtrasList(product, count)}
      </div>
    );
  }
}

export default withTranslation(['basket', 'common', 'coupons', 'error'])(
  BasketShellItem
);
