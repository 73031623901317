import { action, computed, observable } from 'mobx';

import HomepageElementModel from 'client/models/homepage_element.model';
import RootStore from 'client/stores';

/**
 * Homepage store class.
 * @constructor
 * @param {instance} api - {@link API} instance.
 * @param {instance} storage - {@link storage} instance.
 */
export default class HomePageStore {
  api: any;

  root: RootStore;

  loading: boolean;

  constructor(root, state, api) {
    Object.assign(this, state);

    this.api = api;

    this.root = root;

    if (state && state.homepageElements && state.homepageElements.length > 0) {
      this.prepareHomepageElements(state.homepageElements);
    }
  }

  /**
   * identifier of homepage block for
   * @type {number}
   */
  @observable previewId = 0;

  /**
   * Array of homepage elements
   * @type {Array}
   */
  @observable homepageElements = [];

  /**
   * Instance of homepage category
   * @type {{picurl: string, pos: number, name: string, description: string, id: number}}
   */
  @observable homepageCategory = {
    id: -2,
    name: 'Home',
    pos: -2,
    description: '',
    picurl: '',
    discount: 0,
    discount_all: 0,
    mbw: 0,
    picurl_preview: '',
    product_display_type: '',
    cat_display_type: '',
    count_mbw: 0,
    seo_kitchens: [],
    seo_categories: [],
    name_css_position: '',
    is_show_picture: false,
    is_big_picture: false,
    productsPreview: []
  };

  /**
   * Method to get availability of Home Page
   * @return {boolean} availability state
   */
  @computed get isHomepageAvailable() {
    return this.root.restaurantStore.branch.isHomepageAvailable;
  }

  /**
   * Method to load sliders.
   * @return {promise} information about categories
   * @memberof HomePageStore#
   * @method loadCategories
   */
  @action loadSliders() {
    this.loading = true;

    return this.api
      .getSliders()
      .then((data) => {
        this.prepareHomepageElements(data);

        return data;
      })
      .catch((err) =>
        Promise.reject(err || 'Something went wrong to load slider')
      );
  }

  /**
   * Mapping homepage array to Homepage Elements objects
   * @param elements
   */
  @action prepareHomepageElements(elements = []) {
    this.homepageElements = elements
      .filter((element) => !element.hideOpeningTimes)
      .map((element) => new HomepageElementModel(element, this.root));
  }

  /**
   * Method to set preview id for homepage preview
   * @param {number} previewId - id of homepage block
   */
  @action setPreviewId(previewId) {
    this.previewId = previewId;
  }
}
