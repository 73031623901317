import classNames from 'classnames';
import React, { Component, MouseEvent, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import styled from 'styled-components';
import { v1 } from 'uuid';

import animationConstants from '../../../../../enums/animation.enum';
import images from '../../../../../enums/images_enums/hermes_images.enum';

import { IBigProductButton } from './props.interface';
import './BigProductButton.scss';

interface State {
  animationBlobs: {
    id: string;
    elem: JSX.Element;
  }[];
}

class BigProductButton extends Component<IBigProductButton, State> {
  constructor(props: IBigProductButton) {
    super(props);

    this.state = {
      animationBlobs: []
    };
  }

  buttonPriceRef = createRef<HTMLDivElement>();

  componentDidMount() {
    this.executeAnimation();
  }

  componentDidUpdate(props: IBigProductButton) {
    this.executeAnimation(props);
  }

  executeAnimation = (props?: IBigProductButton) => {
    const {
      animated,
      productCount,
      animationTargetBounds,
      mobileView
    } = this.props;

    const buttonPosition =
      this.buttonPriceRef.current &&
      this.buttonPriceRef.current.getBoundingClientRect();

    if (
      animated &&
      (!props || (props && animated !== props.animated)) &&
      animationTargetBounds &&
      buttonPosition
    ) {
      // 80 - offset from the right edge of the basket button, where the circle must fly
      const widthOffset =
        !this.props.isShell && animationTargetBounds.width
          ? animationTargetBounds.width / 2
          : 80;

      let offsetX =
        // eslint-disable-next-line no-restricted-globals
        (animationTargetBounds.left || 0) - buttonPosition.left - pageXOffset;

      offsetX = mobileView
        ? offsetX +
          (animationTargetBounds.width - widthOffset) -
          buttonPosition.width / 2
        : offsetX - buttonPosition.width / 2;

      let offsetY = animationTargetBounds.top - buttonPosition.top;

      if (mobileView) {
        offsetY = !props
          ? offsetY +
            (animationTargetBounds.offsetY || 0) -
            buttonPosition.height
          : offsetY;
      } else {
        offsetY -= animationTargetBounds.offsetY || 0;
      }

      const key = v1();

      const Blob = styled.div`
        ${mobileView ? 'margin: 0 !important;' : ''};
        z-index: 14;
        position: absolute;
        animation: moving-${key} ${animationConstants.time.buttonToBuyProduct}ms
          linear normal;
        opacity: 0;
        border-radius: 50% !important;
        min-width: 30px !important;
        @keyframes moving-${key} {
          0% {
            opacity: 1;
          }
          100% {
            transform: translate(${offsetX}px, ${offsetY}px);
          }
        }
      `;

      this.setState({
        animationBlobs: [
          // eslint-disable-next-line react/no-access-state-in-setstate
          ...this.state.animationBlobs,
          {
            id: key,
            elem: (
              <Blob
                key={key}
                className={classNames({
                  'big-product-button-container': true,
                  'same-item-in-basket': productCount
                })}
                style={this.props.buttonStyle}
                onAnimationEnd={() => this.dropAnimationBlob(key)}
              />
            )
          }
        ]
      });
    }
  };

  dropAnimationBlob = (key: string) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const animationBlobs = this.state.animationBlobs.filter(
      (blob) => blob.id !== key
    );

    this.setState({
      animationBlobs
    });
  };

  onSoldOutProductClick = (event: MouseEvent) => {
    event.stopPropagation();
  };

  renderSoldOutMode = () => {
    const soldOutText = this.props.t
      ? this.props.t('product_view:soldOut')
      : '';

    return (
      <div
        className="big-product-button-container disabled"
        onClick={this.onSoldOutProductClick}
      >
        <ReactSVG
          className="big-product-button-icon-sold-out"
          src={images.iconSoldOut}
          beforeInjection={(svg) => {
            svg.setAttribute(
              'class',
              `${svg.getAttribute(
                'class'
              )} big-product-button-icon-sold-out-image`
            );
          }}
        />
        <span className="big-product-button-text">{soldOutText}</span>
      </div>
    );
  };

  renderClosedStatusMode = () => (
    <div className="big-product-button-container disabled closed-shop">
      <span className="big-product-button-text">
        {this.props.t && this.props.t('product_view:shopIsClosed')}
      </span>
    </div>
  );

  render() {
    const {
      onClick,
      text,
      animated,
      productCount,
      isSoldOut,
      isClosed,
      disabled,
      buttonStyle
    } = this.props;

    if (isClosed) {
      return this.renderClosedStatusMode();
    }

    if (isSoldOut) {
      return this.renderSoldOutMode();
    }

    return (
      <div
        ref={this.buttonPriceRef}
        className={classNames({
          'big-product-button-container': true,
          'same-item-in-basket': productCount,
          disabled: disabled || isSoldOut
        })}
        style={buttonStyle}
        onClick={onClick}
        data-testid="basket-button"
      >
        <span
          className={classNames({
            'big-product-button-text': true,
            animated
          })}
        >
          {text}
        </span>
        <i
          className={classNames({
            'big-product-button-icon': true,
            'fas fa-shopping-cart': true,
            animated: !animated
          })}
        />
        {productCount && (
          <span className="big-product-button-counter">
            {`${productCount}x`}
            <i className="fas fa-shopping-cart" />
          </span>
        )}
        {this.state.animationBlobs.map((blob) => blob.elem)}
      </div>
    );
  }
}

export default withTranslation(['product_view'])(BigProductButton);
