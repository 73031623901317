/* eslint-disable camelcase */
import dayjs from 'dayjs';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import { action, computed, observable, toJS } from 'mobx';

import { PriceDiffModel } from './price_diff.model';

/**
 * Ingredient model class. Used to work with single ingredient.
 * @constructor
 * @param {number} id - ingredient id.
 * @param {object} ingredient - ingredient.
 * @param {object} product - link to the product
 * @param {instance} api - {@link API} instance.
 * @property {number} id - Unique id.
 * @property {string} name - Ingredient name.
 * @property {object} _product - Link to the product.
 * @property {number} count - Observable count variable.
 * @property {[PriceDiffModel]} priceDiffs - Observable array of price difference.
 * @property {boolean} isNegative - negative extra
 */
export class IngredientModel {
  public id: string;

  public name: string;

  public free: number;

  public priceDiffs: PriceDiffModel[];

  public isNegative: boolean;

  private product: any;

  groudId?: number;

  @observable public count = 0;

  @observable public addingTime = 0;

  constructor(id: number, ingredient: any, product: any, isNegative: boolean) {
    const prices = ingredient.price_diff || ingredient.priceDiffs || [];

    this.id = ingredient.id;

    this.name = ingredient.name;

    this.count = ingredient.count || 0;

    this.free = 0;

    this.product = product;

    this.addingTime = ingredient.addingTime || 0;

    this.isNegative = isNegative || false;

    if (isArray(ingredient.priceDiffs)) {
      this.priceDiffs = prices.map(
        (diff: PriceDiffModel) => new PriceDiffModel(diff.id, diff)
      );
    } else {
      this.priceDiffs = map(
        prices,
        (val, key) => new PriceDiffModel(parseInt(key, 10), val)
      );
    }
  }

  /**
   * Method to increase ingredients count
   */
  @action public increaseIngredientCount() {
    this.count++;

    this.addingTime = dayjs().unix();
  }

  /**
   * Method to decrease ingredients count
   */
  @action public decreaseIngredientCount() {
    if (this.count >= 0) this.count--;
  }

  @action public changeFree(free: number) {
    this.free = free;
  }

  /**
   * Method to serialize properties of this object
   * @return {array} serialized array of properties.
   */
  public getToJS() {
    const ingredient = toJS({ ...this, root: {} });

    ingredient.product = undefined;

    return Object.assign(ingredient, {
      priceDiffs: this.priceDiffs.map((priceDiff) => priceDiff.getToJS())
    });
  }

  /**
   * Method to check if ingredient count is more than 0
   * @return {boolean} is count more than 0
   */
  @computed get isActive() {
    return !!this.count;
  }

  /**
   * Method to get active size of the ingredient.
   * @return {number} active size of the ingredient.
   */
  @computed get activeSize() {
    return this.product.activeSize;
  }

  /**
   * Method to get active price difference or throw an error if there is no active price difference.
   * @return {object} active price difference.
   */
  @computed get activePriceDiff() {
    return this.activeSize
      ? this.priceDiffs.find((priceDiff) => priceDiff.id === this.activeSize.id)
      : undefined;
  }

  /**
   * Method to get price of price difference.
   * @return {number} price
   */
  @computed get price() {
    if (!this.activePriceDiff) {
      return 0;
    }

    const { is_fixed, price, old_price } = this.activePriceDiff;

    return this.activePriceDiff && is_fixed ? price : old_price + price;
  }

  /**
   * Method to check if ingredient model not available to size
   * @returns {boolean} - true if ingredient not available to size
   */
  @computed get isExcluded() {
    return this.activePriceDiff ? this.activePriceDiff.is_excluded : true;
  }

  /**
   * Method to get internationalized price of ingredient
   * @returns {*}
   */
  @computed get getIntlPrice() {
    return this.price ? this.product.intlPrice(this.price) : '';
  }
}
