import { action, observable } from 'mobx';

import NewsModel from 'client/models/news.model';

/**
 * Class for additives store.
 * @constructor
 * @param {instance} api - {@link API} instance.
 * @param {instance} storage - {@link Storage} instance.
 * @property {array} news - news array
 * @property {bool} loading - loading
 * @property {bool} loaded - loaded
 */
export default class NewsStore {
  api: any;

  constructor(state, api) {
    Object.assign(this, state, { api });

    this.api = api;
  }

  @observable news = [];

  @observable loading = false;

  @observable loaded = false;

  @observable openNews = false;

  /**
   * Action for set new page opening flag
   * @param state
   */
  @action openNewsPage(state) {
    this.openNews = state;
  }

  /**
   * Method to get list of news
   * @return {promise} news
   */
  @action loadNews() {
    this.loading = true;

    this.loaded = false;

    return this.api
      .getNews()
      .then((data) => {
        this.prepareData(data.news);

        this.loading = false;

        this.loaded = true;

        return data;
      })
      .catch((err) => {
        console.error('Something went wrong to load news', err);

        this.loading = false;

        this.loaded = true;

        return err;
      });
  }

  /**
   * Method for convert json to models
   * @param news - News array
   */
  @action prepareData(news = []) {
    this.news = news.map((newsItem) => new NewsModel(newsItem));
  }
}
