/* eslint-disable import/no-cycle */
import { action, observable } from 'mobx';

import { fetchAdditives } from 'client/api/queries';
import { Additive, AdditivesCategory, Allergen } from 'client/api/types';
import AdditiveModel from 'client/models/additive.model';
import AdditiveCategoryModel from 'client/models/additive_category.model';
import AdditiveProductModel from 'client/models/additive_product.model';
import AdditiveResponseModel from 'client/models/additive_response.model';
import RootStore from 'client/stores/index';
import { api31 } from 'client/utils/api';

/**
 * Class for additives store.
 */
class AdditivesStore {
  root: RootStore;

  constructor(root: RootStore, state: AdditivesStore) {
    Object.assign(this, state);

    this.root = root;
  }

  /**
   * Additives categories array
   */
  @observable additivesCategories: AdditiveCategoryModel[] = [];

  /**
   * Additives products array
   */
  @observable additivesProducts: AdditiveProductModel[] = [];

  /**
   * Additives array
   */
  @observable additives: AdditiveModel[] = [];

  /**
   * Allergens array
   */
  @observable allergens: AdditiveModel[] = [];

  /**
   * Loading state
   */
  @observable loading = false;

  /**
   * Method to get additional information about products and allergens.
   */
  @action
  async loadAdditives(language: string) {
    const { branchId } = this.root.restaurantStore.branch;

    try {
      this.loading = true;

      const { content } = await fetchAdditives(branchId);
      const { additives, allergens, categories } = content;

      this.prepareData(allergens, additives, categories, language);

      return content;
    } catch (e) {
      console.error('Something went wrong to load additives', e);

      return e;
    } finally {
      this.loading = false;
    }
  }

  /**
   * Method for converting json to models
   */
  @action prepareData(
    allergens: Allergen[] = [],
    additives: Additive[] = [],
    additivesCategories: AdditivesCategory[] = [],
    language = 'de'
  ) {
    this.additives = additives
      .map((additive) => new AdditiveModel(additive))
      .sort((a, b) =>
        a.prefix.localeCompare(b.prefix, language, { numeric: true })
      );

    this.allergens = allergens
      .map((allergen) => new AdditiveModel(allergen))
      .sort((a, b) =>
        a.prefix.localeCompare(b.prefix, language, { numeric: true })
      );

    this.additivesCategories = additivesCategories.map(
      (additiveCategory) =>
        new AdditiveCategoryModel(additiveCategory, this.root)
    );

    this.additivesProducts = this.additivesCategories
      .flatMap(({ products }) => products)
      // TODO ask why?
      .filter(
        (product) =>
          product.additives.length > 0 ||
          product.allergens.length > 0 ||
          product.isBio ||
          product.isHalal ||
          product.isVegetarian ||
          product.isVegan ||
          product.isNew ||
          product.hot ||
          product.isLactoseFree ||
          product.isGlutenFree ||
          product.isSugarFree ||
          product.isAdult
      );
  }

  /**
   * get additives model by product id
   */
  getAdditivesModel(productId: number) {
    return this.additivesProducts.find(({ id }) => id === productId);
  }
}

export default AdditivesStore;
