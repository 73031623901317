import { PropTypes, observer } from 'mobx-react';
import ReactPropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

/**
 * Component to render time selector in address form
 */
@observer
class TimeSelectAddressFormShell extends React.Component {
  static propTypes = {
    isDelivery: ReactPropTypes.bool.isRequired,
    preorderTimes: PropTypes.arrayOrObservableArray.isRequired,
    currentPreorderValue: ReactPropTypes.string,
    changeDeliveryTime: ReactPropTypes.func.isRequired
  };

  static defaultProps = {};

  /**
   * Method to convert time value to string
   * @param e
   * @private
   */
  _convertValueToTime = (e) => {
    const { value } = e.target;

    this.props.changeDeliveryTime(value);
  };

  render() {
    const { isDelivery, currentPreorderValue, preorderTimes } = this.props;

    return (
      <div className="select-wrapper-shell time-select-shell">
        <label htmlFor="time-select" data-testid="time-select-title-basket">
          {isDelivery ? this.props.t('timeName') : this.props.t('pickupTime')}
        </label>
        <select
          value={currentPreorderValue}
          id="time-select"
          name="time-select"
          onChange={this._convertValueToTime}
          data-testid="time-selector-basket"
        >
          {preorderTimes.map((time, index) => (
            <option
              key={index}
              value={time.value}
              data-testid="time-in-list-basket"
            >
              {time.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default withTranslation(['address_form'])(TimeSelectAddressFormShell);
