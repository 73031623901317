import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { ModalsRouteType } from 'client/routes/ModalsRoute/ModalsRoute.type';
import openModal from 'client/utils/openModal';
import { IS_CLIENT } from 'config';

import states from '../../../../../enums/states.enum';
import { ShellModalsRouteType } from '../../../../../routes/ShellModalsRoute/ShellModalsRoute.type';
import {
  cantAddProductsToBasket,
  generateLinkFor
} from '../../../../../utils/routing';
import FooterShellButton from '../buttons-shell/footer-button';
import DeliveryAreaSwitch from '../delivery-area-switch';
import HeaderShell from '../header-shell';
import ShellLayout from '../shell-layout';

import BasketShellInfo from './pure/basket-info';
import BasketShellWithProducts from './pure/basket-with-products';
import BasketShellBonusProduct from './pure/bonus-product';
import BasketShellEmpty from './pure/empty-basket';
import './styles.scss';

/**
 * Shell basket component
 */
@withRouter
@inject('store')
@observer
class ShellBasket extends Component {
  constructor(props) {
    super(props);

    this._handleFooterButtonClick = this._handleFooterButtonClick.bind(this);

    this._showFreeItemModal = this._showFreeItemModal.bind(this);

    if (IS_CLIENT) {
      this.props.store.themesStore.changeMobileState(states.basket);

      this.props.store.offerStore.loadFreeItem();

      this.props.store.themesStore.setShellTitle(
        this.props.t('shoppingCart'),
        this._backAction
      );
    }
  }

  componentDidMount() {
    if (IS_CLIENT) {
      this.props.store.basketStore.initLoadingDemoProducts();
    }
  }

  /**
   * Action to click back button
   */
  _backAction = () => {
    if (this.props.location.prevPath) {
      const lastRoute = this.props.location.prevPath.split('/').pop();

      if (lastRoute !== states.checkout) {
        // This checking is used because of complex logic of back routing in Checkout component
        this.props.history.goBack();
      } else {
        this.props.history.push(
          generateLinkFor(states.app, this.props, {}, true)
        );
      }
    } else {
      this.props.history.push(
        generateLinkFor(states.app, this.props, {}, true)
      );
    }
  };

  /**
   * Method to render page header
   * @returns {*}
   * @private
   */
  _renderHeader = () => {
    const { shellBackButtonAction, shellTitle } = this.props.store.themesStore;

    return (
      <HeaderShell
        title={shellTitle}
        backAction={shellBackButtonAction}
        style={this.props.store.themesStore.headerStyle()}
        isPortalAppThemeActive={
          this.props.store.themesStore.isPortalAppThemeActive
        }
        branchName={this.props.store.restaurantStore.branch.branchName}
      />
    );
  };

  /**
   * Method to handle change order type button action
   * @param {string} type
   * @private
   */
  _changeOrderType = (type) => {
    this.props.store.deliveryAddressStore.changeOrderType(type);
  };

  /**
   * Method to handle area button action
   * @private
   */
  _areaButtonAction = () => {
    if (this.props.store.themesStore.isPortalAppThemeActive) {
      return;
    }

    const addressModalUrl = openModal(ShellModalsRouteType.ADDRESS_MODAL);

    this.props.store.basketStore.setLastProductClicked();

    this.props.history.push(addressModalUrl);
  };

  /**
   * Method to render empty basket
   * @returns {*}
   * @private
   */
  _renderEmptyBasket = () => <BasketShellEmpty />;

  /**
   * Method that handle click on minus button in basket item on shell
   * @param item
   * @param count
   * @private
   */
  // TODO: I WILL REFACTOR IT LATER YOU KNOW - it needs to be placed into store
  _removeOneProduct = (item, count) => {
    const confirmDeleteUrl = openModal(ModalsRouteType.DELETE_PRODUCT_MODAL);

    if (item.offerType) {
      if (count > 1) {
        this.props.store.basketStore.removeOfferFromBasket(item, 1, this.props);
      } else {
        this.props.history.push(confirmDeleteUrl);

        this.props.store.basketStore.basketItemToRemove(item.uniqString);
      }
    } else if (count > 1) {
      this.props.store.basketStore.removeProductFromBasket(item);
    } else {
      this.props.history.push(confirmDeleteUrl);

      this.props.store.basketStore.basketItemToRemove(item.uniqString);
    }
  };

  /**
   * Method that handle click on plus button in basket item on shell
   * @param item
   * @private
   */
  _increaseProductByOne = (item) => {
    if (this.props.store.restaurantStore.isShopClosed) {
      return console.warn('shop is closed');
    }

    if (item.offerType) {
      this.props.store.basketStore.addOffer(item, 1);
    } else {
      this.props.store.basketStore.addProductToBasket(item, 1);
    }
  };

  /**
   * Method to render basket with products
   * @returns {*}
   * @private
   */
  _renderBasketWithProducts = () => (
    <>
      <BasketShellWithProducts
        props={this.props}
        offers={this.props.store.basketStore.offers}
        products={this.props.store.basketStore.products}
        cantAddProductsToBasket={cantAddProductsToBasket(this.props)}
        removeAction={(item, count) => this._removeOneProduct(item, count)}
        addAction={(item, count) => this._increaseProductByOne(item, count)}
        getPrice={(price) => this.props.store.basketStore.getIntlPrice(price)}
        setOfferChangeIndex={
          (index) => this.props.store.offerStore.setChangeIndex(index)
          // eslint-disable-next-line react/jsx-curly-newline
        }
        prepareProductsForEditing={
          (product, index) =>
            this.props.store.ingredientsSelectStore.prepareProductsForEditing(
              product,
              index
            )
          // eslint-disable-next-line react/jsx-curly-newline
        }
        isReadOnly={false}
        isOffersCanBeAddedToBasket={this.props.store.basketStore.isOffersCanBeAddedToBasket(
          this.props.store.offerStore.offer
        )}
      />
    </>
  );

  /**
   * Method to display free item modal
   * @private
   */
  _showFreeItemModal() {
    const { history } = this.props;

    this.props.store.offerStore.freeItemButtonClick(history);
  }

  /**
   * Method to render bonus product
   * @returns {null}
   * @private
   */
  _renderBonusProduct = () => {
    const {
      store: {
        offerStore: {
          freeItem: { mbvValue }
        },
        basketStore: { basketFreeItem }
      }
    } = this.props;

    const chosenItem = basketFreeItem?.chosenFreeItemProduct;

    return (
      <BasketShellBonusProduct
        title={this.props.t('lblGift')}
        isFreeItemAdded={this.props.store.basketStore.isFreeOfferInBasket}
        freeItemTitle={chosenItem ? chosenItem.name : null}
        freeItemPrice={this.props.store.basketStore.getIntlPrice(
          this.props.store.basketStore.basketFreeItem?.price
        )}
        buttonLabel={this.props.t('basket:btnChooseFreeItem')}
        buttonAction={this._showFreeItemModal}
        removeFreeItem={
          () =>
            this.props.store.basketStore.removeOfferFromBasket(
              this.props.store.offerStore.freeItem,
              1,
              this.props
            )
          // eslint-disable-next-line react/jsx-curly-newline
        }
        isValid={this.props.store.offerStore.freeItem.isValidForMbw}
        rule={this.props
          .t('basket:basketFreeItemRuleBr', {
            price: this.props.store.basketStore.getIntlPrice(mbvValue)
          })
          .replace(/style=".+?"/g, '')}
        productList={this.props.store.offerStore.freeItem.freeItemProducts}
        negativeTextStyle={this.props.store.themesStore.negativeText()}
        orderButtonStyle={this.props.store.themesStore.orderButtonStyle()}
        standardButtonStyle={this.props.store.themesStore.standardButtonStyle()}
      />
    );
  };

  /**
   * Method to render entire page content
   * @returns {*}
   * @private
   */
  _renderContent = () => {
    const { hasProducts } = this.props.store.basketStore;

    const {
      useCalculationTypeByDeliveryArea
    } = this.props.store.restaurantStore;

    const { discountPercentage } = this.props.store.basketStore;

    const {
      isDelivery,
      hasDelivery,
      hasPickup,
      address,
      fullStreetAddressExists,
      addressPostalCode
    } = this.props.store.deliveryAddressStore;

    const { isShellThemeActive, wrapperStyle } = this.props.store.themesStore;
    const { freeItem } = this.props.store.offerStore;
    const { id } = this.props.store.languagesStore.activeLanguage;

    return (
      <>
        <div className="basket-shell-layout" style={wrapperStyle()}>
          <DeliveryAreaSwitch
            langKey={id}
            areaCode={addressPostalCode}
            currentAddress={fullStreetAddressExists ? address.city : undefined}
            isAddressNeeded={useCalculationTypeByDeliveryArea}
            isDelivery={isDelivery}
            changeOrderTypeAction={this._changeOrderType}
            areaButtonAction={this._areaButtonAction}
            isDeliveryEnabled={hasDelivery}
            isPickupEnabled={hasPickup}
            deliverySwitchStyle={this.props.store.themesStore.deliverySwitchStyle()}
            postalCodeStyle={this.props.store.themesStore.postalCodeStyle()}
          />
          {freeItem && freeItem.validNow && this._renderBonusProduct()}
          {hasProducts
            ? this._renderBasketWithProducts()
            : this._renderEmptyBasket()}
          <BasketShellInfo
            fees={this.props.store.basketStore.getIntlPrice(
              this.props.store.basketStore.deliveryFee
            )}
            deliveryFee={this.props.store.basketStore.deliveryFee}
            isDelivery={this.props.store.deliveryAddressStore.isDelivery}
            orderSum={this.props.store.basketStore.getIntlPrice(
              this.props.store.basketStore.orderPriceWithOffers
            )}
            total={this.props.store.basketStore.getIntlPrice(
              this.props.store.basketStore.finalPriceWithOffers
            )}
            valueWithoutFee={this.props.store.basketStore.getIntlPrice(
              this.props.store.basketStore.priceWithoutFee
            )}
            deliveryFreeFrom={this.props.store.basketStore.intlDeliveryFreeFrom}
            discount={discountPercentage}
            discountValue={this.props.store.basketStore.getIntlPrice(
              this.props.store.basketStore.discountPriceWithOffers
            )}
            mbvReached={this.props.store.basketStore.isMinValueReached}
            hasMinPrice={this.props.store.basketStore.minPrice > 0}
            mbv={this.props.store.basketStore.intlMinPrice}
            isShell={isShellThemeActive}
            discountStyle={this.props.store.themesStore.discountStyle()}
          />
          {this._renderFooter()}
        </div>
      </>
    );
  };

  /**
   * Method to handle click on To Order button
   * @private
   */
  _handleFooterButtonClick = () => {
    this.props.store.basketStore.handleBasketComplete(this.props);

    this.props.store.analyticsStore.sendStartCheckout();
  };

  /**
   * Method to handle basket next button label
   * @param {boolean} basketHasProducts - is basket cart has products
   * @param {string} localizedPrice - localized price with currency
   * @param {boolean} isPreorder - is shop closed but preorder active
   * @returns {string}
   * @private
   */
  _footerNextButtonLabel = (basketHasProducts, localizedPrice, isPreorder) => {
    if (!basketHasProducts) return this.props.t('basket:cartIsEmpty');

    return isPreorder
      ? this.props.t('toPreorder')
      : this.props.t('order_payment_methods:orderPriceWithPrice', {
          price: localizedPrice
        });
  };

  /**
   * Method to render to order button
   * @returns {*}
   * @private
   */
  _renderFooter = () => {
    const basketHasProducts = this.props.store.basketStore.hasProducts;
    const totalPrice = this.props.store.basketStore.finalPriceWithOffers;

    const localizedPrice = this.props.store.basketStore.getIntlPrice(
      totalPrice
    );

    const isOrderButtonDisabled = this.props.store.basketStore
      .basketUnavailableToOrder;

    const { isPreorder } = this.props.store.deliveryAddressStore;

    return (
      <div className="footer-buttons-wrapper">
        <FooterShellButton
          label={this._footerNextButtonLabel(
            basketHasProducts,
            localizedPrice,
            isPreorder
          )}
          colored
          disabled={isOrderButtonDisabled}
          action={this._handleFooterButtonClick}
          buttonOrderStyle={this.props.store.themesStore.orderButtonStyle()}
          buttonStandardStyle={this.props.store.themesStore.standardButtonStyle()}
          buttonDisabledStyle={this.props.store.themesStore.standardButtonDisabledStyle()}
          dataTestId="basket-order-btn"
        />
      </div>
    );
  };

  render() {
    return (
      <ShellLayout
        shellHeader={this._renderHeader()}
        shellContent={this._renderContent()}
      />
    );
  }
}

export default withTranslation([
  'basket',
  'common',
  'coupons',
  'order_payment_methods'
])(ShellBasket);
