import { observer } from 'mobx-react';
import React, { MouseEvent } from 'react';

import PICTURES_MODES from '../../../../../../../enums/product_images.enum';
import { ProductModel } from '../../../../../../../models/product.model';
import ProductBigPictureShell from '../../../../../theme-hermes-shell/components/full-list-products/product-big-picture/ProductBigPictureShell';
import ProductSmallPictureShell from '../../../../../theme-hermes-shell/components/full-list-products/product-small-picture/ProductSmallPictureShell';
import ProductBigPictureVariant1 from '../../cell/product-big-picture-variant-1/ProductBigPictureVariant1';
import ProductBigPictureVariant2 from '../../cell/product-big-picture-variant-2/ProductBigPictureVariant2';
import ProductSmallPicture from '../../cell/product-small-picture/ProductSmallPicture';

import { IProductCell } from './props.interface';

const ProductCell = ({
  isShopClosed,
  product,
  animatedId,
  basketBounds,
  basketProducts,
  styles,
  isMobile,
  additivesProducts,
  hideProductArticles,
  onCellClick,
  onButtonClick,
  activeLanguage,
  isUseAdvancedProductView,
  showHalalCertificate,
  productCellStyle,
  buttonStyle,
  activeButtonStyle,
  separatorStyle,
  horizontalMenuProductStyle,
  fullProductListDesign,
  picturesMode,
  isShell,
  setSize,
  currency,
  productLoadingColor,
  openProductModal
}: IProductCell) => {
  const productAdditives = product.allergens || {};
  const { additives } = product;
  const { isNew } = additives;
  const animated = animatedId === product.id && !!basketBounds;
  const productCount = basketProducts[product.id] || undefined;

  const isNewInfo = {
    value: !!isNew,
    style: styles && styles.newBadgeStyle
  };

  const additivesNames =
    isMobile && additivesProducts
      ? additivesProducts.find((someProduct) => product.id === someProduct.id)
      : undefined;

  const handleOnCellClick = (event: MouseEvent, product: ProductModel) => {
    if (!isShopClosed) {
      onCellClick(event, product);
    }
  };

  const cellProps = {
    key: product.id,
    isShopClosed,
    onButtonClick,
    onCellClick: handleOnCellClick,
    product,
    isNew: isNewInfo,
    foodIcons: { ...additives },
    language: activeLanguage,
    hideProductArticles,
    isUseAdvancedProductView,
    animated,
    productCount,
    animationTargetBounds: basketBounds,
    showHalalCertificate,
    isMobile,
    additivesNames,
    productCellStyle,
    buttonStyle: productCount ? activeButtonStyle : buttonStyle,
    separatorStyle,
    horizontalMenuProductStyle,
    setSize,
    currency,
    productLoadingColor,
    openProductModal
  };

  if (fullProductListDesign) {
    // eslint-disable-next-line default-case
    switch (picturesMode) {
      case PICTURES_MODES.BIG_PICTURES_VARIANT_1:
      case PICTURES_MODES.BIG_PICTURES_VARIANT_2: {
        return <ProductBigPictureShell {...(cellProps as any)} />;
      }
      case PICTURES_MODES.SMALL_PRODUCT_PICTURES: {
        return (
          <ProductSmallPictureShell
            imagesEnabled
            isShell={isShell}
            {...(cellProps as any)}
          />
        );
      }
      case PICTURES_MODES.NO_PICTURES: {
        return (
          <ProductSmallPicture
            imagesEnabled={false}
            isShell={isShell}
            fullProductListDesign
            {...(cellProps as any)}
          />
        );
      }
    }
  }

  // eslint-disable-next-line default-case
  switch (picturesMode) {
    case PICTURES_MODES.BIG_PICTURES_VARIANT_1: {
      return (
        <ProductBigPictureVariant1
          isShell={!!isShell}
          {...(cellProps as any)}
        />
      );
    }
    case PICTURES_MODES.BIG_PICTURES_VARIANT_2: {
      return (
        <ProductBigPictureVariant2
          isShell={!!isShell}
          {...(cellProps as any)}
        />
      );
    }
    case PICTURES_MODES.SMALL_PRODUCT_PICTURES:
    case PICTURES_MODES.NO_PICTURES: {
      return (
        <ProductSmallPicture
          imagesEnabled={picturesMode === PICTURES_MODES.SMALL_PRODUCT_PICTURES}
          isShell={isShell}
          {...(cellProps as any)}
        />
      );
    }
  }
};

export default observer(ProductCell);
