// States
import states from '../enums/states.enum';

/** @module routingFunctions */
/**
 * Function to generate link for the app with appropriate query parameters
 * @param {object} state - base state of the app
 * @param {object} props - React's props with react-router injected
 * @param {object} search - Extra query parameters
 * @param {boolean} branchPage
 * @return {object} new router state
 * @memberof routingFunctions
 */
export function generateLinkFor(
  state,
  props = {},
  search = {},
  branchPage = false
) {
  const queryParams = new URLSearchParams(props.location?.search);

  Object.entries(search || {}).forEach(([key, prop]) => {
    queryParams.set(key, prop);
  });

  const nullishFilteredQueryParams = new URLSearchParams();

  queryParams.forEach((prop, key) => {
    if (prop !== 'null' && prop !== 'undefined') {
      nullishFilteredQueryParams.set(key, prop);
    }
  });

  const searchString = nullishFilteredQueryParams.toString();

  const exactState = !getIsSlugSupported(
    props.store?.restaurantStore?.hostName
  );

  const pathname = branchPage
    ? `${props.match?.params?.branchId}/${state}`
    : `${state}`;

  return {
    pathname: exactState
      ? `/${pathname}`
      : `/${props.match?.params?.slug}/${pathname}`,
    search: searchString,
    prevPath: props.location?.pathname
  };
}

/**
 * Method to generate route path with/without slug
 * @param {string} path - url path
 * @param {string} hostName - host
 * @returns {string}
 */
export function generateRoutePath(
  path,
  hostName,
  aggregator = {
    isAggregator: false,
    isAggregatorStartPage: false
  }
) {
  const { isAggregator, isAggregatorStartPage } = aggregator;
  const isSlugSupported = getIsSlugSupported(hostName);

  let routePath = isAggregator && isSlugSupported ? '/:aggregator' : '';

  routePath +=
    (isSlugSupported || isAggregator) && !isAggregatorStartPage
      ? `/:slug/${path}`
      : `/${path}`;

  return routePath;
}

export function getIsSlugSupported(hostName = '') {
  const host = hostName;

  const mainHosts = [
    // prod
    'vs959.mymanaged.host',
    'b.ordersmart.de',
    'hermes.ordersmart.de',
    'hermes-prod.devteam.win',
    'hermes-shell-prod.devteam.win',

    // staging
    'hermes-st.devteam.win',
    'hermes-shell-st.devteam.win',
    'hermes.stage.ordersmart.app',
    'hermes-shell.stage.ordersmart.app',

    // dev
    'hermes-dev.devteam.win',
    'hermes-shell-dev.devteam.win',
    'hermes.dev.ordersmart.app',
    'hermes-shell.dev.ordersmart.app',

    // feature
    'hermes-feature.devteam.win',
    'hermes-shell-feature.devteam.win',

    // local
    'localhost:3000',
    'localhost:4000',
    '0.0.0.0:3000'
  ];

  return mainHosts.find((h) => h === host);
}

/**
 * Function to delete nulls from the object hash
 * @param {object} obj - dirty object
 * @return {object} clean object
 * @memberof routingFunctions
 */
export function objectWithDeletedKeys(obj) {
  Object.keys(obj).forEach((k) => obj[k] === null && delete obj[k]);

  return obj;
}

/**
 * Function to delete nulls from the object hash
 * @param {object} props - React's props with react-router injected
 * @return {boolean} is user can add products to the basket
 * @memberof routingFunctions
 */
export function cantAddProductsToBasket(props = {}) {
  return (
    props.location.pathname.indexOf(states.address) !== -1 ||
    props.location.pathname.indexOf(states.checkout) !== -1
  );
}
