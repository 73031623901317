import { inject } from 'mobx-react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Measure from 'react-measure';

import { IS_CLIENT } from 'config';

import HomepageDesktop from '../homepage/desktop/index.js';

interface IHomepagePreviewProps {
  t: (key: string) => string;
  store: any;
}

interface IHomepagePreviewNavProps {
  t: (key: string) => string;
}

/**
 * Default HomepagePreview State interface
 */
interface IHomepagePreviewState {
  dimensions: {
    width: number;
    height: number;
  };
}

/**
 * Component to render Homepage preview
 */
@inject('store')
export default class HomepagePreview extends React.Component<
  IHomepagePreviewProps,
  Record<string, any>,
  IHomepagePreviewNavProps
> {
  public readonly state: Readonly<IHomepagePreviewState> = {
    dimensions: {
      width: -1,
      height: -1
    }
  };

  constructor(props: IHomepagePreviewProps) {
    super(props);

    if (IS_CLIENT) {
      (window as any).getHeightHomepageElements = this.calculateHomepageHeight.bind(
        this
      );
    }
  }

  /**
   * Method to return height of homepage block
   * @returns {number}
   */
  public calculateHomepageHeight() {
    return this.state.dimensions.height || 0;
  }

  /**
   * Method to send message with height of block
   * @param {number} previewId
   */
  public sendPostMessageWithHeight(previewId: number) {
    if (IS_CLIENT) {
      const message = {
        id: previewId,
        height: this.state.dimensions.height,
        width: this.state.dimensions.width
      };

      window.parent.postMessage(message, '*');
    }
  }

  public render() {
    const { previewId } = this.props.store.homepageStore;

    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          this.setState({ dimensions: contentRect.bounds });

          this.sendPostMessageWithHeight(previewId);
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef}>
            <HomepageDesktop previewId={Number(previewId)} />
          </div>
        )}
      </Measure>
    );
  }
}
