// Imports
import { inject, observer } from 'mobx-react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

// Components
import { ELEMENTS_TYPES } from '../../../../../../../enums/homepage_elements_types.enum';
import OfferTypes from '../../../../../../../enums/offer_types.enum';
import { createHomepageHrefLink } from '../../../../../../../utils/functions';
import ButtonProductPriceAnimation from '../../../../buttons/ButtonProductPriceAnimation';
import ButtonStandard from '../../../../buttons/ButtonStandard';
// Utils
// Styles
import './styles.scss';

/**
 * Homepage mobile component to render offers section
 */
@withRouter
@inject('store')
@observer
class HomepageOffersDesktop extends React.Component {
  /**
   * Action to show offer modal
   * @param offer
   * @returns {*}
   * @private
   */
  handleOfferClick = (offer) => (event) => {
    event.stopPropagation();

    if (!offer.isValid) {
      return;
    }

    if (
      !this.props.store.offerStore.isOffersArrayHasOfferWithId(offer.offer_id)
    ) {
      this.props.store.offerStore
        .loadSingleOfferById(offer.offer_id)
        .then((loadedOffer) => {
          this.props.store.offerStore.addSingleOfferToCommonArray(loadedOffer);

          this.props.store.offerStore.addOffer(
            loadedOffer.id,
            OfferTypes.KOMBI,
            this.props.history
          );
        });
    } else {
      const loadedOffer = this.props.store.offerStore.getOfferById(
        offer.offer_id
      );

      this.props.store.offerStore.addOffer(
        loadedOffer.id,
        OfferTypes.KOMBI,
        this.props.history
      );
    }
  };

  /**
   * Method to render single offer slide
   * @param offer -{object} offer object
   * @param {number} animateId - animated product id
   * @param key
   * @returns {*}
   * @private
   */
  _renderItem = (offer, key, animateId) => {
    const { isDelivery } = this.props.store.deliveryAddressStore;
    const language = this.props.store.restaurantStore.restaurant.getLang;
    const { currencyCode } = this.props.store.restaurantStore.branch;
    const { isShopClosed } = this.props.store.restaurantStore;

    const offerCount =
      this.props.store.basketStore.offers
        .filter((offerItem) => offerItem.offer.id === offer.offer_id)
        .reduce((count, offerItem) => count + offerItem.count, 0) || null;

    return (
      <div className="column" key={key}>
        <div className="item" onClick={this.handleOfferClick(offer)}>
          <div className="title">{offer.name}</div>
          <div className="ingredients-list">
            <div className="ingredient">{offer.description}</div>
          </div>
          <div className="item-footer">
            <ButtonProductPriceAnimation
              disabled={!offer.isValid}
              animated={offer.offer_id === animateId}
              itemCount={offerCount}
              isClosed={isShopClosed}
            >
              {offer.priceLabel(isDelivery, language, currencyCode)}
            </ButtonProductPriceAnimation>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { offers } = this.props;
    const { animateId } = this.props.store.offerStore;

    return (
      <section
        className="desktop-homepage-section desktop-homepage-offers"
        id={createHomepageHrefLink([ELEMENTS_TYPES.OFFERS, this.props.index])}
      >
        <h2>{this.props.title}</h2>
        <div className="offer-list">
          {offers.map((offer, index) =>
            this._renderItem(offer, index, animateId)
          )}
        </div>
        <div className="controls">
          <div className="column">
            <ButtonStandard
              onClick={this.props.setActiveCategory.bind(this)}
              className="more"
              label={this.props.buttonLabel}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation(['common'])(HomepageOffersDesktop);
