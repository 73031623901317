/* eslint-disable import/no-cycle,camelcase */
import dayjs, { Dayjs } from 'dayjs';
import { action, computed, observable, toJS } from 'mobx';

import { ORDER_TYPES } from 'client/enums/order_types.enum';
import STORAGE_KEYS from 'client/enums/storage_keys.enum';
import { IAddress, IIndexable } from 'client/models/address.model';
import { IArea } from 'client/models/area.model';
import { AreaCodeModel } from 'client/models/area_code.model';
import { IOrderType } from 'client/models/order_type.model';
import { GeocoderResult } from 'client/services/googleService';
import {
  DeliveryAvailability,
  DeliveryAvailabilityCallback
} from 'client/stores/deliveryAddress';
import RootStore from 'client/stores/index';
import { GeoInfo } from 'client/stores/thirdPartyService';
import { IS_CLIENT } from 'config';

import i18n from '../../../i18n';

/**
 * Days description:
 * First day is Sunday. [0]
 * Last day is Holiday [7]
 */

/**
 * Address store class. Used to work with delivery in store.
 */
class DeliveryAddressStore {
  public readonly root: RootStore;

  public storage: any;

  constructor(root: RootStore, state: DeliveryAddressStore, storage: any) {
    Object.assign(this, state);

    this.root = root;

    this.storage = storage;

    if (state.address?.delivery_time) {
      this.address = {
        ...this.address,
        delivery_time: dayjs(state.address.delivery_time)
      };
    }

    if (state.address?.selfcollect_time) {
      this.address = {
        ...this.address,
        selfcollect_time: dayjs(state.address.selfcollect_time)
      };
    }

    this.loadBranchStatus();
  }

  private _localStorageAddressKey = STORAGE_KEYS.ADDRESS;

  private _localStoragePlaceDataKey = STORAGE_KEYS.DELIVERY_PLACE_DATA;

  private _localStorageOrderTypeKey = STORAGE_KEYS.ORDER_TYPE;

  private lastUpdatedKey = STORAGE_KEYS.LAST_UPDATED_ADDRESS;

  private _deliveryMethod = { value: ORDER_TYPES.DELIVERY, active: false };

  private _pickupMethod = { value: ORDER_TYPES.PICKUP, active: false };

  private _activeOrderTypeFromQuery: ORDER_TYPES | undefined;

  public COUNT_SYMBOLS_TO_SEARCH_ADDRESS = 3;

  @observable branchWasSelected = false;

  @observable public address: IAddress = {
    delivery_time: dayjs(),
    selfcollect_time: dayjs(),
    first_name: '',
    last_name: '',
    zip: '',
    company: '',
    email: '',
    phone: '',
    street: '',
    street_no: '',
    city: '',
    floor: '',
    apartment_num: '',
    apartment_title: '',
    staircase: '',
    comment: '',
    hunger_de: 0,
    address_id: '',
    latitude: 0,
    longitude: 0,
    selectedGoogleAddress: null
  };

  @observable public orderTypes: IOrderType[] = [];

  @observable public zipToChange: string | Record<string, any> = '';

  @observable public streetToChange = '';

  @observable public streetNumberToChange = '';

  @observable public cityToChange = '';

  @observable public apartment_title = '';

  @observable public isShowConfirmChangeZip = false;

  @observable public isLoadingDeliveryAvailability = false;

  @observable public isPreselectedOrderType = false;

  @observable public isLoadAddressFromStorage = false;

  /**
   * Method to toggle confirm change zip modal
   * @param {boolean} state
   * @method toggleConfirmChangeZipModal
   */
  @action
  public showChangingZipConfirmationModal(state: boolean) {
    this.isShowConfirmChangeZip = state;
  }

  @action
  saveOrderTypeToStorage(value: ORDER_TYPES, branchId: string) {
    this.storage.saveToStorage(this._localStorageOrderTypeKey, value, branchId);
  }

  /**
   * Method to change delivery method.
   * @param {string} type - method id
   * @method changeOrderType
   */
  @action
  public changeOrderType(type?: ORDER_TYPES) {
    const {
      orderPaymentMethodsStore,
      restaurantStore,
      openingHoursStore
    } = this.root;

    const { branch, useCalculationTypeByDeliveryArea } = restaurantStore;

    this.setActiveOrderType(type);

    if (IS_CLIENT && this.activeOrderType) {
      this.storage.saveToStorage(
        this._localStorageOrderTypeKey,
        this.activeOrderType.value,
        branch.branchId
      );
    }

    orderPaymentMethodsStore.setActivePaymentMethod();

    openingHoursStore.getHolidayWeeks(this.isDelivery);

    if (this.isDelivery) {
      if (!useCalculationTypeByDeliveryArea && restaurantStore.sublocality) {
        this.changeCity(restaurantStore.sublocality);
      }
    }

    this.root.openingHoursStore.generatePreorderDays();

    if (
      !this.isDelivery &&
      useCalculationTypeByDeliveryArea &&
      !this.address.zip
    ) {
      this.chooseFirstBranch();
    }
  }

  /**
   * Set active order type [delivery|pickup] from query params
   * @param type
   */
  @action
  public setActiveOrderTypeFromQuery(type: ORDER_TYPES) {
    this._activeOrderTypeFromQuery = type;

    this.setPreselectedOrderType(true);
  }

  /**
   * Method to choose the first branch
   */
  @action
  public chooseFirstBranch() {
    const { getBranches } = this.root.restaurantStore.restaurant;
    const firstBranch = getBranches.length === 1 ? getBranches[0] : undefined;

    if (firstBranch && firstBranch.postalCode) {
      this.handleChangeZip({
        zip: firstBranch.postalCode
      } as IArea);

      this.saveAddressAndOrderType();

      if (this.root.restaurantStore.isBranchSelectingSkipped) {
        this.root.restaurantStore.setSkipBranchSelectingState(false);
      }
    }
  }

  /**
   * Set order type
   * @param {string} type
   */
  @action public setActiveOrderType = (type?: ORDER_TYPES) => {
    this.orderTypes.forEach((i) => {
      i.active = i.value === type;
    });
  };

  /**
   * Method to load address from storage.
   * @method loadAddressFromStorage
   */
  @action
  public async loadAddressFromStorage() {
    const { branch } = this.root.restaurantStore;

    const [cachedAddress] = await this.storage.loadFromStorage(
      [this._localStorageAddressKey],
      branch.branchId
    );

    const [placeData] = await this.storage.loadFromStorage(
      [this._localStoragePlaceDataKey],
      branch.branchId
    );

    this.address =
      cachedAddress !== null
        ? {
            ...cachedAddress,
            email:
              typeof cachedAddress.email === 'string'
                ? cachedAddress.email.trim()
                : '',
            delivery_time: cachedAddress.delivery_time
              ? dayjs(cachedAddress.delivery_time)
              : null,
            selfcollect_time: cachedAddress.selfcollect_time
              ? dayjs(cachedAddress.selfcollect_time)
              : null,
            company: cachedAddress.company || '',
            floor: cachedAddress.floor || '',
            apartment_num: cachedAddress.apartment_num || '',
            staircase: cachedAddress.staircase || '',
            comment: cachedAddress.comment || '',
            hunger_de: 0,
            selectedGoogleAddress:
              placeData ?? cachedAddress.selectedGoogleAddress
          }
        : this.address;

    this.root.basketStore.offersWeekdays();

    this.root.openingHoursStore.generatePreorderDays();

    this.setIsLoadAddressFromStorage(true);

    return cachedAddress;
  }

  /**
   * Method to generate information about delivery and pick up method based on order type.
   * @param orderTypes
   */
  @action
  public generateWithTimeRespect(orderTypes: ORDER_TYPES[]): void {
    !this.orderTypes.length && this.generateOrderTypes(orderTypes);

    const orderTypeValue = this.orderTypes.length
      ? this.orderTypes.find((type) => type.active)?.value
      : undefined;

    this.changeOrderType(orderTypeValue);
  }

  /**
   * Method to load order type from storage.
   */
  @action
  public async loadOrderTypeFromStorage(ignoreCache = false) {
    const { branch } = this.root.restaurantStore;
    const orderTypes = this.root.restaurantStore.branch.getOrderTypes;

    if (ignoreCache) {
      this.generateWithTimeRespect(orderTypes);

      await this.storage.deleteFromStorage(this._localStorageOrderTypeKey);

      return this.activeOrderType;
    }

    let [storedOrderType] = await this.storage.loadFromStorage(
      [this._localStorageOrderTypeKey],
      branch.branchId
    );

    if (this._activeOrderTypeFromQuery) {
      storedOrderType = this._activeOrderTypeFromQuery;

      this._activeOrderTypeFromQuery = undefined;
    }

    this.generateWithTimeRespect(orderTypes);

    const {
      closedForDelivery,
      closedForPickUp
    } = this.root.restaurantStore.branch;

    if (this.orderTypes.length > 1) {
      if (
        // case when delivery is not available now, but user chose it on start page
        storedOrderType === ORDER_TYPES.DELIVERY &&
        this.activeOrderType?.value !== ORDER_TYPES.DELIVERY &&
        this.isPreselectedOrderType
      ) {
        this.changeOrderType(storedOrderType);
      } else if (
        // case when both types has the same availability, but before user chose pickup on last session or he chose pickup on start page
        storedOrderType === ORDER_TYPES.PICKUP &&
        (closedForDelivery === closedForPickUp || this.isPreselectedOrderType)
      ) {
        this.changeOrderType(storedOrderType);
      }
    }

    return this.activeOrderType;
  }

  /**
   * Method to change areaCode and sub locality.
   */
  @action
  public handleChangeZip(area: Pick<IArea, 'zip' | 'sublocality'>) {
    this.root.restaurantStore.setAreaCode(area.zip, area.sublocality);

    if (this.address.zip && this.address.zip !== area.zip) {
      this.root.categoryMenuStore.wholeProductListLoaded = false;
    }

    this.changeZip(area.zip);

    this.changeCity(area.sublocality);
  }

  /**
   * Method to change areaCode and sub locality.
   */
  @action
  public onZipChange(area?: Pick<AreaCodeModel, 'areaCode' | 'sublocality'>) {
    if (area) {
      this.root.restaurantStore.setAreaCode(area.areaCode, area.sublocality);

      if (this.address.zip && this.address.zip !== area.areaCode) {
        this.root.categoryMenuStore.wholeProductListLoaded = false;
      }
    }
  }

  /**
   * Method to save zip that should be set.
   * @param {string} zip - areaCode and sub locality
   */
  @action
  public saveZipToChange(zip: string) {
    this.zipToChange = zip;
  }

  /**
   * Method to save street name that should be set.
   * @param {string} street - street name
   * @method saveStreetToChange
   */
  @action
  public saveStreetToChange(street: string) {
    this.streetToChange = street;
  }

  /**
   * Method to save street number that should be set.
   * @param {string} streetNumber - street number
   * @method saveStreetNumberToChange
   */
  @action
  public saveStreetNumberToChange(streetNumber: string) {
    this.streetNumberToChange = streetNumber;
  }

  /**
   * Method to save city that should be set.
   * @param {string} city - city
   * @method saveCityToChange
   */
  @action
  public saveCityToChange(city: string) {
    this.cityToChange = city;
  }

  /**
   * Method to set checking the place
   * @param {boolean} state - new state of checking
   */
  @action
  public setCheckingThePlace(state: boolean) {
    this.isLoadingDeliveryAvailability = state;
  }

  /**
   * Method for clearing saved address
   */
  @action
  public clearAddress() {
    this.address = {
      delivery_time: dayjs(),
      selfcollect_time: dayjs(),
      first_name: '',
      last_name: '',
      zip: '',
      company: '',
      email: '',
      phone: '',
      street: '',
      street_no: '',
      city: '',
      floor: '',
      apartment_num: '',
      apartment_title: '',
      staircase: '',
      comment: '',
      hunger_de: 0,
      address_id: '',
      latitude: 0,
      longitude: 0,
      selectedGoogleAddress: null
    };
  }

  @action
  updateAddress(newValues: Partial<IAddress>) {
    this.address = { ...this.address, ...newValues };

    this.saveAddressAndOrderType();
  }

  /**
   * Method save address and order type to the storage
   */
  public saveAddressAndOrderType(branchId?: string) {
    const { restaurantTime, branch } = this.root.restaurantStore;
    const restaurantBranchId = branchId ?? branch.id;

    if (IS_CLIENT) {
      this.storage.saveToStorage(
        this._localStorageAddressKey,
        this.getToJS(),
        restaurantBranchId
      );

      if (this.activeOrderType) {
        this.storage.saveToStorage(
          this._localStorageOrderTypeKey,
          this.activeOrderType.value,
          restaurantBranchId
        );
      }

      this.storage.saveToStorage(
        this.lastUpdatedKey,
        restaurantTime,
        restaurantBranchId
      );
    }
  }

  public savePlaceData(branchId: string, address) {
    if (IS_CLIENT) {
      this.storage.saveToStorage(
        this._localStoragePlaceDataKey,
        address,
        branchId
      );
    }
  }

  /**
   * Method to serialize address information.k
   */
  public getToJS(): IAddress {
    return toJS(this.address);
  }

  /**
   * Method to get serialized address data.
   */
  public toBackendRequest() {
    const offset =
      this.root.restaurantStore.currentUtcOffset -
      this.root.restaurantStore.restaurant.getUtcOffset;

    const address = this.getToJS();
    const times: { delivery_time?: number; selfcollect_time?: number } = {};

    if (address.delivery_time) {
      times.delivery_time = dayjs(address.delivery_time)
        .add(offset || 0, 'minute')
        .unix();
    }

    if (address.selfcollect_time) {
      times.selfcollect_time = dayjs(address.selfcollect_time)
        .add(offset || 0, 'minute')
        .unix();
    }

    return {
      ...address,
      ...times
    };
  }

  /**
   * Method to change Delivery time value
   * @param value - new Delivery time value
   */
  @action
  public changeDeliveryTime(value: string | null) {
    if (value === null) {
      this.address.delivery_time = null;

      this.address.selfcollect_time = null;

      return;
    }

    const date = dayjs(this.root.openingHoursStore.activeDay).format(
      'DD-MM-YYYY'
    );

    this.address = {
      ...this.address,
      delivery_time: dayjs(`${value} ${date}`, 'HH:mm DD-MM-YYYY'),
      selfcollect_time: dayjs(`${value} ${date}`, 'HH:mm DD-MM-YYYY')
    };
  }

  /**
   * Action to update address field in store and localstorage
   * @param key - key of address field
   * @param value - value of address field
   */
  @action
  public updateAddressField(
    key: keyof IAddress,
    value: IAddress[keyof IAddress]
  ) {
    (this.address as IIndexable)[key] = value;
  }

  /**
   * Method to get address field from store
   * @param key - key of address field
   */
  public getAddressFieldValue(key: keyof IAddress): string {
    return (this.address as IIndexable)[key] || '';
  }

  /**
   * Method to prepare additional field to order request
   */
  // TODO: Move translations to the form component
  public getAdditionalFieldsData(): string {
    let result = '';

    // Add contact less method message
    if (this.root.orderPaymentMethodsStore.isContactLessMethodAvailable) {
      result += this.root.orderPaymentMethodsStore.contactLessTitle.toUpperCase();
    }

    // eslint-disable-next-line array-callback-return
    this.getOptionalFieldsInAddressForm.map((field) => {
      if ((this.address as IIndexable)[field]) {
        const translateField = `address_form:${field}`;

        result += `${i18n.t(translateField)}: ${
          (this.address as IIndexable)[field]
        } \n`;
      }
    });

    result += `\n${this.address.comment}`;

    return result;
  }

  /**
   * Method to get optional fields for polish customers
   */
  get getOptionalFieldsInAddressForm() {
    if (this.root.restaurantStore.isPolishRestaurant) {
      return ['apartment_num', 'floor', 'staircase'];
    }

    return [];
  }

  @action
  async getDeliveryAvailability(
    geoInfo: GeoInfo,
    branchId: string,
    saveResults = false,
    callback?: DeliveryAvailabilityCallback
  ) {
    this.setCheckingThePlace(true);

    try {
      const { lat, lng } = geoInfo;
      const params = { lat, lng, branchId };

      const data: DeliveryAvailability = await this.root.api.getDeliveryAvailability(
        params
      );

      const { status, fee, freeFrom, mbv } = data;

      if (data.status >= 400) {
        this.root.basketStore.setAdvancedCalculationTypeData();

        if (callback) {
          callback(false);
        }
      } else {
        saveResults && this.saveAddress(geoInfo);

        this.root.basketStore.setAdvancedCalculationTypeData({
          fee,
          mbv,
          freeFrom
        });

        const { street, streetNo, city, postalCode, subLocality } = geoInfo;

        const place = {
          streetName: street.long_name,
          streetNumber: streetNo.long_name,
          city: subLocality
            ? `${subLocality.long_name}, ${city.long_name}`
            : city.long_name,
          postalCode: postalCode.long_name
        };

        const placeParam = saveResults ? undefined : place;

        callback && callback(true, placeParam);
      }
    } catch (e) {
      this.root.basketStore.setAdvancedCalculationTypeData();
    } finally {
      this.setCheckingThePlace(false);
    }
  }

  @action
  saveAddress(geoData: GeoInfo) {
    const { addressId, city, street, streetNo, postalCode } = geoData;

    this.root.deliveryAddressStore.changeStreet(street.long_name);

    this.root.deliveryAddressStore.changeStreetNo(streetNo.long_name);

    this.root.deliveryAddressStore.changeCity(city.long_name);

    this.root.deliveryAddressStore.changeZip(postalCode.long_name);

    this.root.deliveryAddressStore.changeAddressId(addressId);
  }

  @action
  checkAddressForPortal() {
    const { branchId } = this.root.restaurantStore.branch;
    const { useCalculationTypeByDeliveryArea } = this.root.restaurantStore;

    if (useCalculationTypeByDeliveryArea) {
      const geoInfo: GeoInfo = {
        street: this.address.street,
        streetNo: this.address.street_no,
        city: this.address.city,
        postalCode: this.address.zip,
        lat: this.address.latitude,
        lng: this.address.longitude,
        addressId: this.address.address_id
      };

      this.root.thirdPartyServicesStore.applyAddressFromQuery(
        geoInfo,
        branchId
      );
    }
  }

  @action setPreselectedOrderType(state: boolean) {
    this.isPreselectedOrderType = state;
  }

  /**
   * Method to change Street value
   * @param {string} value - new Street value
   */
  public changeStreet(value: string) {
    this.address.street = value;
  }

  /**
   * Method to change City value
   * @param {string} value - new City value
   */
  public changeCity(value: string) {
    this.address.city = value;
  }

  /**
   * Method to change Zip value
   * @param {string} value - new Zip value
   */
  public changeZip(value: string) {
    this.address.zip = value;
  }

  /**
   * Method to change First_name value
   * @param {string} value - new First name value
   */
  public changeFirstName(value: string) {
    this.address.first_name = value;
  }

  /**
   * Method to change Last_name value
   * @param {string} value - new Last name value
   */
  public changeLastName(value: string) {
    this.address.last_name = value;
  }

  /**
   * Method to change StreetNo value
   * @param {string} value - new Street number value
   */
  public changeStreetNo(value: string) {
    this.address.street_no = value;
  }

  /**
   * Method to change Email value
   * @param {string} value - new Email value
   */
  public changeEmail(value: string) {
    this.address.email = value;
  }

  /**
   * Method to change Phone value
   * @param {string} value - new Phone value
   */
  public changePhone(value: string) {
    this.address.phone = value;
  }

  /**
   * Method to change Company value
   * @param {string} value - new Company value
   */
  public changeCompany(value: string) {
    this.address.company = value;
  }

  /**
   * Method to change Floor value
   * @param {string} value - new Floor value
   */
  public changeFloor(value: string) {
    this.address.floor = value;
  }

  /**
   * Method to change Apartment value
   * @param {string} value - new apartment value
   * @param {string} apartment - apartment localization string
   */
  public changeApartmentNum(value: string, apartment: string) {
    this.address.apartment_num = value;

    this.address.apartment_title = apartment;
  }

  /**
   * Method to change Message value
   * @param {string} value - new Message value
   */
  public changeMessage(value: string) {
    this.address.comment = value;
  }

  /**
   * Method to change hunger_de value
   * @param {boolean} value - new hunger_de value
   */
  public changePortal(value: boolean) {
    this.address.hunger_de = Number(value);
  }

  /**
   * Method to change address id
   * @param {string} value
   */
  public changeAddressId(value: string) {
    this.address.address_id = value;
  }

  /**
   * Method to change coordinates
   * @param latitude
   * @param longitude
   */
  public changeCoordinates(latitude: number, longitude: number) {
    this.address.latitude = latitude;

    this.address.longitude = longitude;
  }

  /**
   * Method to generate information about delivery and pick up method based on order type.
   * @param {number} value - order type id.
   */
  public generateOrderTypes(value: ORDER_TYPES[]) {
    const availableOrderTypes = [];

    const {
      closedForDelivery,
      closedForPickUp
    } = this.root.restaurantStore.branch;

    if (value.includes(ORDER_TYPES.DELIVERY)) {
      this._deliveryMethod.active =
        !closedForDelivery || // True, when delivery is available and restaurant is opened
        !value.includes(ORDER_TYPES.PICKUP) || // True, when pickup is unavailable
        (closedForDelivery && closedForPickUp); // True, when both types are available, but restaurant is fully closed

      availableOrderTypes.push(this._deliveryMethod);
    }

    if (value.includes(ORDER_TYPES.PICKUP)) {
      if (!this._deliveryMethod.active) {
        // True, when only pickup is available or both types are available, but restaurant is closed for delivery
        this._pickupMethod.active = true;
      }

      availableOrderTypes.push(this._pickupMethod);
    }

    this.orderTypes = availableOrderTypes;
  }

  public setIsLoadAddressFromStorage(value: boolean): void {
    this.isLoadAddressFromStorage = value;
  }

  /**
   * Method to check delivery availability
   */
  @computed get hasDelivery(): boolean {
    return !!this.orderTypes.find((i) => i.value === ORDER_TYPES.DELIVERY);
  }

  /**
   * Method to check pickup availability
   */
  @computed get hasPickup(): boolean {
    return !!this.orderTypes.find((i) => i.value === ORDER_TYPES.PICKUP);
  }

  /**
   * Checks if restaurant has only one delivery type available.
   * If there's only one delivery type, returns that type otherwise
   * returns undefined
   */
  @computed get hasOnlyOneDelivery() {
    if (this.orderTypes.length > 1 || !this.orderTypes.length) {
      return;
    }

    const [orderType] = this.orderTypes;

    return orderType;
  }

  /**
   * Get selected preorder value
   */
  @computed get currentPreorderValue(): string | null {
    return this.address.delivery_time !== null
      ? this.address.delivery_time.format('HH:mm')
      : null;
  }

  /**
   * Method to return nearest pickup time of restaurant
   */
  @computed get nearestPickupTime(): Dayjs {
    const pickupTime = this.address.selfcollect_time;

    const {
      secondsUntilOpenPickUp,
      getMaxSelfcollectTime
    } = this.root.restaurantStore.branch;

    const duration = dayjs.duration(
      secondsUntilOpenPickUp + getMaxSelfcollectTime,
      'seconds'
    );

    const durationTime = dayjs().add(duration);

    if (pickupTime) {
      return pickupTime > durationTime ? pickupTime : durationTime;
    }

    return durationTime;
  }

  /**
   * Method to check if entered address is full.
   * @method isFullAddressEntered
   */
  @computed get isFullAddressEntered(): boolean {
    return !!(
      this.address.first_name &&
      this.address.last_name &&
      this.address.city &&
      this.address.email &&
      this.address.phone &&
      this.address.street &&
      this.address.street_no
    );
  }

  /**
   * Method to check if entered address in Simple PickUp is full.
   * @method isFullAddressEntered
   */
  @computed get isSimplePickUpAddressEntered(): boolean {
    return !!(
      this.address.first_name &&
      this.address.last_name &&
      this.address.email &&
      this.address.phone
    );
  }

  /**
   * Method to receive information about active type of order.
   * @return {object} active order type.
   * @method activeOrderType
   */
  @computed get activeOrderType() {
    return this.orderTypes.find((i) => i.active);
  }

  /**
   * Method to determine if order type is delivery.
   * @method isDelivery
   */
  @computed get isDelivery() {
    return !!(
      this.activeOrderType &&
      this.activeOrderType.value === this._deliveryMethod.value
    );
  }

  /**
   * True in case when shop closed but preorder always active
   */
  @computed get isPreorder() {
    return (
      ((this.isDelivery &&
        this.root.restaurantStore.branch.closedForDelivery) ||
        (!this.isDelivery &&
          this.root.restaurantStore.branch.closedForPickUp)) &&
      this.root.restaurantStore.isPreorderAlways
    );
  }

  @computed get addressPostalCode() {
    return (
      (this.root.restaurantStore.useCalculationTypeByDeliveryArea
        ? this.address.zip
        : this.root.restaurantStore.currentAreaCode?.areaCode) || ''
    );
  }

  @computed get fullStreetAddressExists() {
    return (
      this.address.street && this.address.street_no && this.address.address_id
    );
  }

  setBranchSelectedStatus() {
    const key = 'branchWasSelected';
    const status = true;

    this.storage.saveToStorage(key, status);

    this.branchWasSelected = status;
  }

  @computed get branchSelectedStatus() {
    return this.branchWasSelected;
  }

  async loadBranchStatus() {
    if (IS_CLIENT) {
      const key = 'branchWasSelected';
      const [status] = await this.storage.loadFromStorage([key]);

      this.branchWasSelected = !!status;
    }
  }

  @action
  saveGoogleAddress(googleAddress: GeocoderResult) {
    this.address.selectedGoogleAddress = googleAddress;
  }

  @action
  getOrderTypes() {
    const orderTypes = (this.orderTypes as any).toJS() as IOrderType[];

    return orderTypes;
  }
}

export default DeliveryAddressStore;
