import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames';
import React, { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Input from 'client/components/Input';
import TextArea from 'client/components/TextArea';
import Button from 'client/ui/Button';
import ButtonGroup from 'client/ui/ButtonGroup';
import {
  createPickUpFormValidation,
  createSimplePickUpFormValidation,
  rules
} from 'client/utils/formValidation';
import getValidFormData from 'client/utils/getValidFormData';

// @ts-ignore
import css from './PickUpForm.module.scss';
import { PickUpFormData, PickUpFormProps } from './PickUpForm.type';

const PickUpForm = ({
  className,
  defaultValues,
  lang,
  onSubmit,
  isMobile,
  isGermanRestaurant,
  isPolishRestaurant,
  isSimplePickUpForm,
  t
}: PickUpFormProps) => {
  const {
    register,
    handleSubmit,
    formState,
    getValues
  } = useForm<PickUpFormData>({
    defaultValues,
    context: {
      lang,
      isGermanRestaurant,
      isPolishRestaurant
    },
    resolver: yupResolver(
      isSimplePickUpForm
        ? createSimplePickUpFormValidation()
        : createPickUpFormValidation()
    ),
    mode: 'onTouched'
  });

  const history = useHistory();
  const { errors } = formState;
  const zipPattern = rules.getZipValidationByLang(lang);

  const redirectTo = useCallback(
    (path: string) => {
      const { pathname } = history.location;
      const newPath = pathname.replace('address', path);

      history.replace(newPath);
    },
    [history]
  );

  const handleFormSubmit = (data: PickUpFormData) => {
    onSubmit(data);

    redirectTo('checkout');
  };

  const handleCancelClick = useCallback(() => {
    const data = getValues();
    const validData = getValidFormData(data, errors, defaultValues);

    onSubmit(validData);

    redirectTo('basket');
  }, [defaultValues, errors, getValues, onSubmit, redirectTo]);

  return (
    <form
      className={classnames(css.PickUpForm, className)}
      onSubmit={handleSubmit(handleFormSubmit)}
      data-testid="PickUpForm"
    >
      <Input
        {...register('firstName')}
        autoComplete="given-name"
        placeholder={t('address_form:firstName')}
        error={errors.firstName?.message}
        pattern={rules.firstNameValidChars}
        dataTestId="name-input-basket"
      />
      <Input
        {...register('lastName')}
        autoComplete="family-name"
        placeholder={t('address_form:lastName')}
        error={errors.lastName?.message}
        pattern={rules.lastNameValidChars}
        dataTestId="sec-name-input-basket"
      />
      {!isSimplePickUpForm && (
        <>
          <Input
            {...register('company')}
            placeholder={t('address_form:company')}
            error={errors.company?.message}
            pattern={rules.companyValidChars}
            dataTestId="firm-input-basket"
          />
          <Input
            {...register('street')}
            placeholder={t('address_form:street')}
            error={errors.street?.message}
            pattern={rules.streetValidChars}
            dataTestId="street-input-basket"
          />
          <Input
            {...register('houseNumber')}
            placeholder={t('address_form:number')}
            error={errors.houseNumber?.message}
            pattern={rules.houseValidChars}
            dataTestId="house-input-basket"
          />
          <Input
            {...register('zip')}
            autoComplete="postal-code"
            placeholder={t('address_form:zip')}
            error={errors.zip?.message}
            pattern={zipPattern}
            dataTestId="zip-input-basket"
            inputMode={isGermanRestaurant ? 'tel' : 'text'}
          />
          <Input
            {...register('city')}
            placeholder={t('address_form:city')}
            error={errors.city?.message}
            pattern={rules.cityValidChars}
            dataTestId="city-input-basket"
          />
        </>
      )}
      <Input
        {...register('email')}
        autoComplete="email"
        placeholder={t('address_form:emailAddress')}
        error={errors.email?.message}
        pattern={rules.emailValidChars}
        dataTestId="email-input-basket"
      />
      <Input
        {...register('phone')}
        autoComplete="tel"
        placeholder={t('address_form:phone')}
        error={errors.phone?.message}
        pattern={rules.phoneValidChars}
        valuePattern={rules.phoneValidValue}
        inputMode={isMobile ? 'tel' : 'text'}
        dataTestId="phone-input-basket"
      />
      <TextArea
        {...register('comment')}
        placeholder={t('address_form:massage')}
        error={errors.comment?.message}
        pattern={rules.commentValidChars}
        dataTestId="comment-input-basket"
      />
      <ButtonGroup vertical>
        <Button onClick={handleCancelClick} dataTestId="back-btn-basket">
          {t('address_form:cmnBack')}
        </Button>
        <Button type="submit" variant="submit" dataTestId="basket-order-btn">
          {t('address_form:checkout')}
        </Button>
      </ButtonGroup>
    </form>
  );
};

export { PickUpForm };

export default memo(PickUpForm);
