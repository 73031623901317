import { parseInt } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import './preparation_time.scss';

type Props = {
  isDelivery: boolean;
  averageDeliveryTime: number | string;
  averagePickUpTime: number | string;
  isOpenNow: boolean;
  t: (key: string, value: Record<string, unknown>) => string;
};

const convertNumber = (value: string | number): number | string =>
  typeof value === 'string' ? parseInt(value, 10) : value;

const PreparationTime: React.FC<Props> = ({
  isDelivery,
  averagePickUpTime,
  averageDeliveryTime,
  isOpenNow,
  t
}) => {
  const deliveryTime = convertNumber(averageDeliveryTime);
  const pickupTime = convertNumber(averagePickUpTime);

  return (
    <>
      {isDelivery && deliveryTime && isOpenNow ? (
        <div className="preparation-row">
          <i className="fas fa-biking type-icon" />
          <span className="delivery-info-text">
            {t('AverageDeliveryTime', { time: averageDeliveryTime })}
          </span>
        </div>
      ) : null}

      {!isDelivery && pickupTime && isOpenNow ? (
        <div className="preparation-row">
          <i className="center fas fa-utensils type-icon" />
          <span className="delivery-info-text">
            {t('AveragePickUpTime', { time: averagePickUpTime })}
          </span>
        </div>
      ) : null}
    </>
  );
};

export default withTranslation(['delivery_info'])(PreparationTime);
