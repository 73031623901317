import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DeliverySwitchShell from '../delivery-switch';

import './DeliveryAreaSwitch.scss';
import { withTranslation } from 'react-i18next';
/**
 * Component to render delivery switch with area
 * @returns {null}
 */
@inject('store')
@observer
class DeliveryAreaSwitch extends Component {
  static propTypes = {
    areaCode: PropTypes.string,
    currentAddress: PropTypes.string,
    isDelivery: PropTypes.bool,
    changeOrderTypeAction: PropTypes.func.isRequired,
    areaButtonAction: PropTypes.func.isRequired,
    isDeliveryEnabled: PropTypes.bool.isRequired,
    isPickupEnabled: PropTypes.bool.isRequired,
    isAddressNeeded: PropTypes.bool,
    langKey: PropTypes.string,
    deliverySwitchStyle: PropTypes.object,
    postalCodeStyle: PropTypes.object
  };

  static defaultProps = {
    areaCode: ''
  };

  renderCurrentLocation = () => {
    const {
      isDelivery,
      areaCode,
      currentAddress,
      areaButtonAction,
      isAddressNeeded,
      store
    } = this.props;

    let currentLocation = '';

    if (isDelivery && isAddressNeeded) {
      currentLocation =
        currentAddress || this.props.t('enter_address_modal:zip');
    } else if (!isDelivery) {
      currentLocation = store.restaurantStore.branch.cityCode;
    } else {
      currentLocation = areaCode || this.props.t('enter_address_modal:zip');
    }

    return (
      <div
        onClick={areaButtonAction}
        className={`postal-code ${this.props.langKey}`}
        style={this.props.postalCodeStyle}
      >
        <span data-testid="shell-postal">{currentLocation}</span>
        <i className="mdi mdi-map-marker" />
      </div>
    );
  };

  render() {
    const {
      isDelivery,
      changeOrderTypeAction,
      isDeliveryEnabled,
      isPickupEnabled
    } = this.props;

    return (
      <div id="delivery-info-container">
        <DeliverySwitchShell
          isDeliveryChosen={isDelivery}
          changeOrderType={changeOrderTypeAction}
          isDeliveryEnabled={isDeliveryEnabled}
          isPickupEnabled={isPickupEnabled}
          style={this.props.deliverySwitchStyle}
        />
        {this.renderCurrentLocation()}
      </div>
    );
  }
}

export default withTranslation(['enter_address_modal'])(DeliveryAreaSwitch);
