/**
 * Supported versions of browsers families
 *
 * @link https://www.npmjs.com/package/useragent
 */
export const BROWSER_SUPPORT = {
  ie: {
    name: 'IE',
    version: '>=11',
    update_link:
      'https://www.microsoft.com/en-us/download/internet-explorer.aspx'
  },
  chrome: {
    name: 'Chrome',
    version: '>=38',
    update_link: 'https://www.google.com/chrome/'
  },
  chromeWebView: {
    name: 'Chrome Mobile WebView',
    version: '>=38',
    update_link: 'https://www.google.com/chrome/'
  },
  firefox: {
    name: 'Firefox',
    version: '>=37',
    update_link: 'https://www.mozilla.org/en-US/firefox/new/'
  },
  safari: {
    name: 'Safari',
    version: '>=10',
    update_link: 'https://support.apple.com/downloads/safari'
  },
  safariMobile: {
    name: 'Mobile Safari',
    version: '>=10',
    update_link: 'https://support.apple.com/downloads/safari'
  },
  opera: {
    name: 'Opera',
    version: '>=25',
    update_link: 'https://www.opera.com/'
  }
};
