import classNames from 'classnames';
import flattenDeep from 'lodash/flattenDeep';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { CategoryModel } from 'client/models/category_menu.model';

import SwiperSlider from '../../../../../../components/SwiperSlider';
import states from '../../../../../../enums/states.enum';
import { generateLinkFor } from '../../../../../../utils/routing';

import { ICategoryMenuSliderProps } from './CategoryMenu.inteface';
import './CategoryMenuSlider.scss';

@inject('store')
@(withRouter as any)
@observer
export class CategoryMenuSlider extends Component<
  ICategoryMenuSliderProps,
  { usedCategoryIds?: number[] }
> {
  private slider: any;

  private currentSlideIndex: number =
    this.props.store.themesStore.lastScrolledSliderElementIndex || 0;

  private readonly params = {
    slidesPerView: 'auto',
    centeredSlides: false,
    spaceBetween: 0,
    preventClicks: true,
    speed: 300,
    freeMode: true,
    slideToClickedSlide: false,
    touchStartForcePreventDefault: true,
    rebuildOnUpdate: false,
    containerClass: 'category-slider',
    initialSlide: this.currentSlideIndex
  };

  constructor(props: ICategoryMenuSliderProps) {
    super(props);

    this.state = {
      usedCategoryIds: []
    };
  }

  public componentDidMount(): void {
    this.initState();
  }

  public componentDidUpdate(): void {
    this.slideTo(this.props.categorySlideId as number);
  }

  public componentWillUnmount() {
    this.props.store.themesStore.setLastScrolledSliderElementIndex(
      this.currentSlideIndex
    );
  }

  /**
   * create state with exiting category ids (without excluded)
   */
  private initState() {
    const excludeCategoryIds = this.props.excludeCategoryIds || [];

    if (excludeCategoryIds.length) {
      const usedCategoryIds: number[] = flattenDeep<number>(
        this.props.categories
          .filter((category) => !excludeCategoryIds.includes(category.id))
          .map((category) =>
            category.hasSubCategories
              ? category.subCategories.map((subCategory) => subCategory.id)
              : category.id
          )
      );

      this.setState({ usedCategoryIds });
    }
  }

  /**
   * slide to selected category
   * @param {number} categoryId
   */
  private slideTo(categoryId: number) {
    const categoryIdx = this.state.usedCategoryIds.indexOf(categoryId);

    if (categoryIdx > -1) {
      this.currentSlideIndex = categoryIdx;

      this.slider.slideTo(categoryIdx);
    }
  }

  renderScrollLink = (
    category: CategoryModel,
    parentCategory?: CategoryModel
  ) => {
    const name = parentCategory
      ? `${parentCategory.name} | ${category.name}`
      : category.name;

    return (
      <div
        key={category.id}
        className={classNames({
          'slider-link': true,
          // eslint-disable-next-line eqeqeq
          'slider-link-active': category.id == this.props.categorySlideId
        })}
        onClick={
          () =>
            this.props.store.categoryMenuStore.setCategorySlide(
              category.id,
              'menu'
            )
          // eslint-disable-next-line react/jsx-curly-newline
        }
      >
        {name}
      </div>
    );
  };

  /**
   * render category slides with router navigation
   * @return {any[]}
   */
  private renderHashedLinks(categories: CategoryModel[]): JSX.Element[] {
    const categoriesList = categories
      .filter(
        (category: CategoryModel) =>
          !this.props.excludeCategoryIds.includes(category.id)
      )
      .map((category: CategoryModel) =>
        category.hasSubCategories
          ? category.subCategories.map((subcategory) =>
              this.renderScrollLink(subcategory, category)
            )
          : this.renderScrollLink(category)
      );

    return flattenDeep<JSX.Element>(categoriesList);
  }

  renderLink = (category: CategoryModel, parentCategory?: CategoryModel) => {
    const name = parentCategory
      ? `${parentCategory.name} | ${category.name}`
      : category.name;

    const path = generateLinkFor(
      `${states.cat}/${category.id}`,
      this.props,
      {},
      true
    );

    return (
      <Link
        key={category.id}
        className={classNames({
          'slider-link': true,
          'slider-link-active': category.id === this.props.categorySlideId
        })}
        to={path}
      >
        {name}
      </Link>
    );
  };

  /**
   * render category slides without router navigation
   * @param {CategoryModel[]} categories
   * @return {[]}
   */
  private renderHrefLinks(categories: CategoryModel[]): JSX.Element[] {
    const categoriesList = categories
      .filter(
        (category: CategoryModel) =>
          !this.props.excludeCategoryIds.includes(category.id)
      )
      .map((category: CategoryModel) =>
        category.hasSubCategories
          ? category.subCategories.map((subcategory) =>
              this.renderLink(subcategory, category)
            )
          : this.renderLink(category)
      );

    return flattenDeep<JSX.Element>(categoriesList);
  }

  render() {
    const { useHashes, categories } = this.props;

    return (
      <div id="category-slider">
        <SwiperSlider
          {...this.params}
          getSwiper={(event) => {
            this.slider = event;
          }}
        >
          {useHashes
            ? this.renderHashedLinks(categories)
            : this.renderHrefLinks(categories)}
        </SwiperSlider>
      </div>
    );
  }
}
