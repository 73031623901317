import dayjs, { Dayjs } from 'dayjs';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import takeWhile from 'lodash/takeWhile';
import values from 'lodash/values';

import { SpecialTimes } from 'client/models/branch.model.type';
import { IDay, ISpecialDay } from 'client/models/day.model';
import { IOfferDay } from 'client/models/day_offer.model';
import { IPreorderTime } from 'client/models/preorder_time.model';

import i18n from '../../i18n';
import { DAYJS_FORMAT } from '../enums/dayjs.enum';

const MINUTES_PERIOD = 15;

/**
 * Method to check is day has break
 * @param day
 * @returns {boolean}
 */
export function isDayHasBreak(day: IDay): boolean {
  return !!(day.start && day.end && day.breakStart && day.breakEnd);
}

/**
 * Get weekday number by date
 * @param {number} restaurantTime
 * @param {object} day - today
 */
export function getWeekday(restaurantTime: Dayjs, day: Dayjs): number {
  return restaurantTime.localeData().firstDayOfWeek() === 1
    ? day.weekday() + 1
    : day.weekday();
}

/**
 * Function to generation opening hours.
 * @param {string} arr - day of the week.
 */
export function groupHours(arr: IDay[]) {
  return values(
    groupBy(arr, (i) => i.start + i.end + i.breakStart + i.breakEnd)
  );
}

/**
 * Function to generation offer hours.
 * @param {string} arr - days of the week.
 */
export function generateOfferHours(arr: IOfferDay[]) {
  return values(groupBy(arr, (i) => i.startT + i.endT));
}

/**
 * Get a short name of the day
 * @param {number} dayNumber - index of the dayjs's week day
 * @param {boolean} isShortHoliday - is show short holiday label
 * @returns {string}
 */
export function getDayShort(dayNumber: number, isShortHoliday = false): string {
  if (dayNumber >= 0 && dayNumber <= 6) {
    return dayjs.weekdaysMin()[dayNumber];
  }

  return isShortHoliday
    ? i18n.t('opening_hours:openingHoursHolidays').substring(0, 3)
    : i18n.t('opening_hours:openingHoursHolidays');
}

/**
 * Get a long name of the day
 * @param {number} dayNumber - index of the dayjs's week day
 * @returns {string}
 */
export function getDayLong(dayNumber: number): string {
  if (dayNumber >= 0 && dayNumber <= 6) {
    return dayjs.weekdays()[dayNumber];
  }

  return i18n.t('opening_hours:openingHoursHolidays');
}

/**
 * Get a long name of the day
 * @param {number} dayNumber - index of the dayjs's week day
 * @returns {boolean}
 */
export function isHolidayDay(dayNumber: number): boolean {
  return dayNumber === 7;
}

/**
 * Method to check is restaurant hasn't work time
 * @param day - day model
 * @returns {boolean}
 */
export function isClosed(day: IDay): boolean {
  return !(day.start && day.end);
}

/**
 * Method to create work time to day
 * @param day - day model
 * @returns {*}
 */
export function generateWorktimeForDay(day: IDay): string {
  if (isClosed(day)) {
    return i18n.t('opening_hours:closed');
  }

  return isDayHasBreak(day)
    ? generateForDayWithBreak(day)
    : generateForDayWithoutBreak(day);
}

/**
 * Method to generate work time without break
 * @param day - day model
 * @returns {string}
 */
function generateForDayWithoutBreak(day: IDay): string {
  return i18n.t('opening_hours:workingHours', {
    start: dayjs(day.start, DAYJS_FORMAT.timeFormat),
    end: dayjs(day.end, DAYJS_FORMAT.timeFormat)
  });
}

/**
 * Method to generate work time with break
 * @param day - day model
 * @returns {string}
 */
function generateForDayWithBreak(day: IDay): string {
  return `${i18n.t('opening_hours:workingHoursWithBreak', {
    start1: dayjs(day.start, DAYJS_FORMAT.timeFormat),
    end1: dayjs(day.breakStart, DAYJS_FORMAT.timeFormat),
    start2: dayjs(day.breakEnd, DAYJS_FORMAT.timeFormat),
    end2: dayjs(day.end, DAYJS_FORMAT.timeFormat)
  })}`;
}

/**
 * Method to check is time === midnight.
 * There is another method, like compare sourceTime === 00:00:00. But it can return wrong result
 * @param {string} sourceTime - time from backend
 * @returns {boolean}
 */
export function isMidnight(sourceTime: string): boolean {
  const time = dayjs(sourceTime, 'HH:mm:ss');

  return time.hour() === 0 && time.minute() === 0 && time.second() === 0;
}

/**
 * Method to sort work time by first day - monday, last day - holiday
 * @param {array} sourceArray - week days array
 * @param {object} holiday - holiday day
 * @returns {*[]}
 */
export function sortAndSetMondayFirst(
  sourceArray: IDay[],
  holiday: IDay
): IDay[] {
  if (sourceArray && sourceArray.length !== 0) {
    const newArray = [...sourceArray.map((day) => ({ ...day }))];
    const sunday = newArray.find((day) => day.day === 0);

    if (sunday) {
      sunday.day = 0;

      sunday.daySort = 7;
    }

    newArray.sort((a, b) => a.daySort - b.daySort);

    return [...newArray, holiday];
  }
}

/**
 * Method for calculation preorder days
 * @param {boolean} isClosedForToday
 * @param {dayjs.Dayjs[]} filterDays
 * @param {dayjs.Dayjs} restaurantTime
 * @returns {dayjs.Dayjs}
 */
export function calculateTodayDay(
  isClosedForToday: boolean,
  filterDays: Dayjs[],
  restaurantTime: Dayjs
) {
  if (!filterDays.length) {
    return undefined;
  }

  const isDayExist = filterDays.find(
    (i) => i.weekday() === restaurantTime.clone().weekday()
  );

  return !isClosedForToday && isDayExist
    ? restaurantTime.clone()
    : filterDays[0];
}

/**
 * Method for calculation preorder days
 * @param {dayjs.Dayjs} restaurantTime
 * @param customerTime
 * @param {number} todayWeekDay
 * @param {boolean} dayIsOver
 * @param {number[]} holidayWeeks
 * @param {dayjs.Dayjs | undefined} upcomingHolidayStart
 * @param offersTimesBySelectedDay
 * @param worktimeSchedule
 * @returns {dayjs.Dayjs[]}
 */
export function calculateAvailableForOrderDays(
  restaurantTime: Dayjs,
  customerTime: Dayjs,
  todayWeekDay: number,
  dayIsOver: boolean,
  holidayWeeks: number[],
  upcomingHolidayStart: Dayjs | undefined,
  offersTimesBySelectedDay: IOfferDay[],
  worktimeSchedule: IDay[]
): Dayjs[] {
  /**
   * Range between time now and now + 24 hours
   */
  const range = [restaurantTime, restaurantTime.clone().add(1, 'day')];

  let days = range.filter((j) => {
    const weekday = getWeekday(restaurantTime, j);

    if ((todayWeekDay === weekday && !dayIsOver) || todayWeekDay !== weekday) {
      return !holidayWeeks.includes(weekday);
    }

    return false;
  });

  /**
   * Check upcoming holiday
   */
  if (upcomingHolidayStart) {
    days = takeWhile(days, (i) => i < upcomingHolidayStart);
  }

  /**
   * Filter days when has pre-calculated times
   */
  return days.filter((day) => {
    const weekday = getWeekday(restaurantTime, dayjs(day));

    const availableTimes = filterPastAndOfferTime(
      offersTimesBySelectedDay,
      weekday,
      worktimeSchedule,
      0,
      customerTime,
      todayWeekDay,
      false
    );

    return availableTimes.length > 0;
  });
}

/**
 * Calculates available time for day before current day
 */

export function calculateAvailableTimesForDayBefore(dayBeforeData: {
  timeNow: Dayjs;
  workHours: IDay;
  processingTime: number;
  isToday: boolean;
  currentDayStartTime: string;
}): Dayjs[] {
  const {
    timeNow,
    workHours,
    processingTime,
    isToday,
    currentDayStartTime
  } = dayBeforeData;

  if (
    dayjs(workHours.end, 'HH:mm:ss') < dayjs(workHours.start, 'HH:mm:ss') &&
    (timeNow.clone().add(processingTime, 'minute') <=
      dayjs(workHours.end, 'HH:mm:ss') ||
      !isToday)
  ) {
    let result = [];
    let startTimePreviousDay = timeNow.clone();
    let addProcessingTimeToStartTime = true;
    let breakStartPrevDay;
    let breakEndPrevDay;

    if (!isToday) {
      startTimePreviousDay = dayjs('00:00', 'HH:mm').add(1, 'day');

      const difference = startTimePreviousDay.diff(timeNow, 'minute');

      if (difference < processingTime) {
        startTimePreviousDay = startTimePreviousDay
          .clone()
          .add(difference, 'minute');
      }

      addProcessingTimeToStartTime = false;
    }

    if (workHours.breakStart && workHours.breakEnd) {
      breakStartPrevDay = dayjs(workHours.breakStart, 'HH:mm:ss');

      breakEndPrevDay = dayjs(workHours.breakEnd, 'HH:mm:ss');

      if (!isToday) {
        if (
          dayjs(workHours.breakStart, 'HH:mm:ss') <
          dayjs(workHours.end, 'HH:mm:ss')
        ) {
          breakStartPrevDay = breakStartPrevDay.clone().add(1, 'day');
        }

        if (
          dayjs(workHours.breakEnd, 'HH:mm:ss') <
          dayjs(workHours.end, 'HH:mm:ss')
        ) {
          breakEndPrevDay = breakEndPrevDay.clone().add(1, 'day');
        }
      } else {
        if (
          dayjs(workHours.end, 'HH:mm:ss') <
          dayjs(workHours.breakStart, 'HH:mm:ss')
        ) {
          breakStartPrevDay = breakStartPrevDay.clone().subtract(1, 'day');
        }

        if (
          dayjs(workHours.end, 'HH:mm:ss') <
          dayjs(workHours.breakEnd, 'HH:mm:ss')
        ) {
          breakEndPrevDay = breakEndPrevDay.clone().subtract(1, 'day');
        }
      }
    }

    if (
      breakStartPrevDay &&
      breakEndPrevDay &&
      breakStartPrevDay <= startTimePreviousDay &&
      startTimePreviousDay < breakEndPrevDay
    ) {
      startTimePreviousDay = breakEndPrevDay.clone();

      addProcessingTimeToStartTime = true;
    }

    startTimePreviousDay = addProcessingTimeToStartTime
      ? startTimePreviousDay.clone().add(processingTime, 'minute')
      : startTimePreviousDay;

    // Calculating end time for times after midnight from previous day
    let endTimePrevDay =
      dayjs(workHours.end, 'HH:mm:ss') < dayjs(currentDayStartTime, 'HH:mm:ss')
        ? dayjs(workHours.end, 'HH:mm:ss')
        : dayjs(currentDayStartTime, 'HH:mm:ss');

    if (!isToday) {
      endTimePrevDay = endTimePrevDay.clone().add(1, 'day');
    }

    if (
      breakStartPrevDay &&
      breakEndPrevDay &&
      startTimePreviousDay < breakStartPrevDay &&
      breakStartPrevDay < breakEndPrevDay
    ) {
      result = getTimes(startTimePreviousDay, breakStartPrevDay);

      result = concat(
        result,
        getTimes(
          breakEndPrevDay.clone().add(processingTime, 'minute'),
          endTimePrevDay
        )
      );
    } else {
      result = getTimes(startTimePreviousDay, endTimePrevDay, 0);
    }

    return result;
  }

  return [];
}

/**
 * Calculates available time for current day
 */

export function calculateAvailableTimesForCurrentDay(currentDay: {
  todayWeekDay: number;
  weekday: number;
  timeNow: Dayjs;
  workHours: IDay;
  processingTime: number;
}): Dayjs[] {
  const {
    todayWeekDay,
    weekday,
    timeNow,
    workHours,
    processingTime = 0
  } = currentDay;

  const { start, end, breakStart, breakEnd, day } = workHours;

  let result: Dayjs[] = [];
  let startTime;

  if (
    todayWeekDay === weekday &&
    timeNow > dayjs(start, 'HH:mm:ss').weekday(day)
  ) {
    startTime = timeNow.clone().add(processingTime, 'minute');
  } else {
    startTime = getDayjsDateTimeFromString(day, start, todayWeekDay)
      .clone()
      .add(processingTime, 'minute');
  }

  let endTime = getDayjsDateTimeFromString(day, end, todayWeekDay);

  if (dayjs(start, 'HH:mm:ss') > dayjs(end, 'HH:mm:ss')) {
    endTime = getDayjsDateTimeFromString(day, '23:59:59', todayWeekDay);
  }

  let breakStartTime;
  let breakEndTime;

  if (breakStart && breakEnd) {
    breakStartTime = getDayjsDateTimeFromString(day, breakStart, todayWeekDay);

    breakEndTime = getDayjsDateTimeFromString(day, breakEnd, todayWeekDay);

    if (breakEndTime < breakStartTime) {
      breakEndTime = breakEndTime.clone().add(1, 'day');
    }
  }

  if (
    breakStartTime &&
    breakEndTime &&
    breakStartTime < startTime &&
    startTime <= breakEndTime.clone().add(processingTime, 'minute')
  ) {
    startTime = breakEndTime.clone().add(processingTime, 'minute');
  }

  if (
    breakStartTime &&
    breakEndTime &&
    startTime <= breakStartTime &&
    breakStartTime < breakEndTime
  ) {
    const timeBeforeBreak = getTimes(startTime, breakStartTime, 0);

    const breakEndTimeWithProcessingTime = breakEndTime
      .clone()
      .add(processingTime, 'minute');

    const timeAfterBreak =
      breakEndTimeWithProcessingTime <= endTime
        ? getTimes(breakEndTimeWithProcessingTime, endTime, 0)
        : [];

    result = concat(result, concat(timeBeforeBreak, timeAfterBreak));
  } else {
    result = concat(result, getTimes(startTime, endTime, 0));
  }

  return result;
}

/**
 * Calculating all array available times with breaks
 * @param {number} weekday
 * @param {dayjs.Dayjs} customerTime
 * @param {IDay} todayHours
 * @param {number} processingTime
 * @param {number} todayWeekDay
 * @param {boolean} isTodayDeliveryHasBreakTime
 * @returns {any | dayjs.Dayjs[]}
 */
export function calculateAvailableTimes(
  weekday: number,
  customerTime: Dayjs,
  workingHours: {
    currentDayHours: IDay;
    previousDayHours?: IDay;
  },
  processingTime: number,
  todayWeekDay: number
) {
  let result: dayjs.Dayjs[] = [];

  const timeNow = customerTime.clone();
  const { currentDayHours, previousDayHours } = workingHours;
  const { start } = currentDayHours;

  if (previousDayHours) {
    result = calculateAvailableTimesForDayBefore({
      timeNow,
      workHours: previousDayHours,
      processingTime,
      isToday: weekday === todayWeekDay,
      currentDayStartTime: start
    });
  }

  result = concat(
    result,
    calculateAvailableTimesForCurrentDay({
      todayWeekDay,
      weekday,
      timeNow,
      workHours: currentDayHours,
      processingTime
    })
  );

  return result;
}

/**
 * Creating array of times (Dayjs) between start and end time
 * @param {object} start - dayjs start time
 * @param {number} end - dayjs end time
 * @param {number} minutesAdd - minutes to add
 * @param {array} arr - list of times
 * @return {array} - list of times
 */
export function getTimes(
  start: Dayjs,
  end: Dayjs,
  minutesAdd = 0,
  arr: Dayjs[] = []
): Dayjs[] {
  if (start.isAfter(end)) {
    return [];
  }

  const roundedUp = Math.ceil(start.minute() / 5) * 5;
  const startTime = start.clone().minute(roundedUp).second(0);
  const minutesLeft = getMinutesLeft(start, end);

  arr.push(
    minutesAdd === 0 ? startTime : startTime.clone().add(minutesAdd, 'minute')
  );

  minutesAdd += MINUTES_PERIOD;

  if (minutesAdd < minutesLeft) {
    return getTimes(start, end, minutesAdd, arr);
  }

  // Add end time to time array
  const lastTime = arr.slice(-1).pop();

  if (lastTime) {
    const diff = lastTime.diff(end, 'minute');

    if (diff !== 0) {
      // Remove last added time if it is more than end time
      if (diff > 0) {
        arr.pop();
      }

      arr.push(end);
    }
  }

  return arr;
}

/**
 * Method to receive the remaining minutes
 * @param {object} startTime - dayjs start time
 * @param {object} endTime - dayjs end time
 * @return {number} - remaining minutes
 */
export function getMinutesLeft(startTime: Dayjs, endTime: Dayjs): number {
  return Math.round(endTime.diff(startTime, 'millisecond') / 1000 / 60);
}

/**
 * Get filtered time for offers availability
 * @param {dayjs.Dayjs[]} filteredTimes
 * @param {number} weekday
 * @param {IOfferDay[]} offersTimeRange
 * @returns {dayjs.Dayjs[]}
 */
export function filterTimeForOffers(
  filteredTimes: Dayjs[],
  weekday: number,
  offersTimeRange: IOfferDay[]
) {
  // Filter only selected weekday offer time range
  offersTimeRange = offersTimeRange.filter((range) => range.dayNo === weekday);

  // Filter time for all time ranges
  // eslint-disable-next-line array-callback-return
  offersTimeRange.map((range) => {
    const startTime = dayjs(range.startT, 'HH:mm:ss').weekday(weekday);
    const endTime = dayjs(range.endT, 'HH:mm:ss').weekday(weekday);

    filteredTimes = filter(
      filteredTimes,
      (i) => i >= startTime && i <= endTime
    );
  });

  return filteredTimes;
}

/**
 * Filtering past and offers availability time
 * @param {IOfferDay[]} offersTimesBySelectedDay
 * @param {number} weekday
 * @param worktimeSchedule
 * @param {number} processingTime
 * @param {dayjs.Dayjs} restaurantTime
 * @param customerTime
 * @param todayWeekDay
 * @param isTodayDeliveryHasBreakTime
 * @param isPreorderActive
 * @returns {any}
 */
export function filterPastAndOfferTime(
  offersTimesBySelectedDay: IOfferDay[],
  weekday: number,
  worktimeSchedule: IDay[],
  processingTime: number,
  customerTime: Dayjs,
  todayWeekDay: number,
  isPreorderActive: boolean,
  specialWorktimeSchedule: Partial<SpecialTimes> = {}
): Dayjs[] {
  let currentDayRange = worktimeSchedule.find((i) => i.day === weekday);

  const previousDayNumber = weekday === 0 ? 6 : weekday - 1;

  let previousDayRange = worktimeSchedule.find(
    (i) => i.day === previousDayNumber
  );

  // If special days hours exist, use it, else regular time
  if (weekday === todayWeekDay && specialWorktimeSchedule.yesterday) {
    previousDayRange = specialWorktimeSchedule.yesterday.closed
      ? undefined
      : {
          day: previousDayNumber,
          daySort: previousDayNumber,
          days: [''],
          start: specialWorktimeSchedule.yesterday.start_time || '',
          end: specialWorktimeSchedule.yesterday.end_time || '',
          breakStart: specialWorktimeSchedule.yesterday.break_start,
          breakEnd: specialWorktimeSchedule.yesterday.break_end
        };
  }

  if (weekday !== todayWeekDay && specialWorktimeSchedule.tomorrow) {
    currentDayRange = specialWorktimeSchedule.tomorrow.closed
      ? undefined
      : {
          day: todayWeekDay,
          daySort: todayWeekDay,
          days: [''],
          start: specialWorktimeSchedule.tomorrow.start_time || '',
          end: specialWorktimeSchedule.tomorrow.end_time || '',
          breakStart: specialWorktimeSchedule.tomorrow.break_start,
          breakEnd: specialWorktimeSchedule.tomorrow.break_end
        };
  }

  if (currentDayRange) {
    /**
     * Generate possible times
     */
    let generatedTimes = calculateAvailableTimes(
      weekday,
      customerTime,
      {
        currentDayHours: currentDayRange,
        previousDayHours: previousDayRange
      },
      processingTime,
      todayWeekDay
    );

    /**
     * Filter time for offer availability
     */
    const offersTimeRange = offersTimesBySelectedDay.filter(
      (i) => i.dayNo === weekday
    );

    if (offersTimeRange && offersTimeRange.length > 0) {
      generatedTimes = filterTimeForOffers(
        generatedTimes,
        weekday,
        offersTimeRange
      );
    }

    generatedTimes = addPreorderTime(generatedTimes, isPreorderActive);

    return generatedTimes;
  }

  return [];
}

/**
 * Add 5 minutes to first preorder time (OSSHOP-4330)
 * @param {dayjs.Dayjs[]} times
 * @param {boolean} isPreorderActive
 * @returns {dayjs.Dayjs[]}
 */
export function addPreorderTime(times: Dayjs[], isPreorderActive: boolean) {
  if (isPreorderActive && times.length > 0) {
    const firstTime = times[0].clone().add(5, 'minute');

    if (firstTime > times[1]) {
      times.shift();
    } else {
      times[0] = firstTime;
    }
  }

  return times;
}

/**
 * Get Dayjs time by string with corrections for Sunday
 * @param {number} weekday
 * @param {string} time
 * @param {number | undefined} todayWeekDay
 * @returns {dayjs.Dayjs}
 */
export function getDayjsDateTimeFromString(
  weekday: number,
  time: string,
  todayWeekDay?: number | undefined
): Dayjs {
  const result = dayjs(time, 'HH:mm:ss').weekday(weekday);

  if (todayWeekDay) {
    return correctSundayIfNeed(result, weekday, todayWeekDay);
  }

  return result;
}

/**
 * Fix case when preorder to sunday but today not sunday
 * @param {dayjs.Dayjs} time - Initial time in dayjs
 * @param {number} weekday - selected day weekday
 * @param {number} todayWeekDay - today weekday
 * @returns {dayjs.Dayjs}
 */
function correctSundayIfNeed(
  time: Dayjs,
  weekday: number,
  todayWeekDay: number
): Dayjs {
  return weekday === 0 && todayWeekDay !== 0
    ? time.clone().add(7, 'day')
    : time;
}

/**
 * Filter preorders days by offer availability
 * @param preorderDays - array of days available for order
 * @param offerAvailabilityWeekdays - array of days (only numbers), when offers available
 * @param offersTimesBySelectedDay - array of filtered working times
 * @returns {*}
 */
export function filterDaysForOffers(
  preorderDays: Dayjs[],
  offerAvailabilityWeekdays: number[],
  offersTimesBySelectedDay: IOfferDay[]
): Dayjs[] {
  const todayWeekday = dayjs().weekday();

  return preorderDays.filter((i) => {
    const currentWeekday = i.weekday();

    // if offer weekdays contains preorder weekdays
    if (offerAvailabilityWeekdays.includes(currentWeekday)) {
      // if today
      if (i.weekday() === todayWeekday) {
        // Offer availability for today
        const todayOfferTime = offersTimesBySelectedDay.find(
          (offer) => offer.dayNo === currentWeekday
        );

        if (todayOfferTime) {
          // If current time more then offer end time then exclude date
          if (
            dayjs() >
            dayjs(todayOfferTime.endT, 'HH:mm:ss').weekday(todayOfferTime.dayNo)
          ) {
            return false;
          }
        }
      }

      return true;
    }

    return false;
  });
}

/**
 * Method for calculating array of preorder times (for dropdown menu)
 * @param {dayjs.Dayjs[]} availableTimes
 * @param {boolean} isActiveDayToday
 * @param {boolean} isClosedForToday
 */
export function addNowTimeItem(
  availableTimes: Dayjs[],
  isActiveDayToday: boolean,
  isClosedForToday: boolean,
  isOpenNow: boolean
) {
  const result: IPreorderTime[] = [];

  // Add the label "Now"
  if (isActiveDayToday && !isClosedForToday && isOpenNow) {
    const orderNow: IPreorderTime = {
      label: i18n.t('address_form:deliveryTime'),
      value: null
    };

    result.push(orderNow);
  }

  const preorderTimes: IPreorderTime[] = availableTimes.map((time) => ({
    label: time.format('HH:mm'),
    value: time.format('HH:mm')
  }));

  return [...result, ...preorderTimes];
}

export function isBreakTimeNow(
  today: IDay,
  restaurantTime: Dayjs,
  isTodayHasBreakTimeForChosenOrderType: boolean
) {
  if (!today.breakStart || !today.breakEnd) {
    return false;
  }

  const breakEndTime =
    getUnix(today.day, today.breakStart) < getUnix(today.day, today.breakEnd)
      ? getUnix(today.day, today.breakEnd)
      : getUnix(today.day, '23:59:59');

  return (
    isTodayHasBreakTimeForChosenOrderType &&
    getUnix(today.day, today.breakStart) < restaurantTime &&
    breakEndTime > restaurantTime
  );
}

/**
 * Return dayjs object
 * @param {number} day - weekday
 * @param {string} time - time in HH:mm:ss format
 * @return {object} dayjs object
 */
function getUnix(day: number, time: string) {
  return dayjs(time, 'HH:mm:ss').weekday(day);
}
