// Imports
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
// Utils
import { createHomepageHrefLink } from '../../../../../utils/functions';
import { ELEMENTS_TYPES } from '../../../../../enums/homepage_elements_types.enum';
// Components
import HomepageOffersMobile from './pure/HomepageOffersMobile';
import HomepageCategoriesMobile from './pure/HomepageCategoriesMobile';
import HomepageProductsMobile from './pure/HomepageProductsMobile';
import HomepageDeliveryMobile from './pure/HomepageDeliveryMobile';
import HomepageTextMobile from './pure/HomepageTextMobile';
import HomepageMobileBanners from './pure/HomepageBannersMobile';
import HomepageSeoText from '../HomepageSeoText';
import HomepageOpeningHours from '../HomepageOpeningHours/HomepageOpeningHours';
// Styles
import './HomepageMobile.scss';
import Footer from '../../footer';
import CommonHeaderMobile from '../../header/mobile/common';
/**
 * Component to render home page but mobile version
 */
@inject('store')
@withRouter
@observer
class HomepageMobile extends Component {
  /**
   * Method to render banners slider
   * @param slides - array of slides objects
   * @returns {*}
   * @private
   */
  _renderBannerSlider = (slides, key) => {
    return <HomepageMobileBanners slides={slides} key={key} />;
  };

  /**
   * Method to render offers slider
   * @param offers - array of offers objects
   * @param title - section title
   * @returns {*}
   * @private
   */
  _renderOffers = (title, offers, key, index) => {
    return (
      <HomepageOffersMobile
        title={title}
        offers={offers}
        key={key}
        index={index}
      />
    );
  };

  /**
   * Method to render discounts slider
   * @param products - array of products objects
   * @param title - section title
   * @returns {*}
   * @private
   */
  _renderProducts = (title, products, key, index) => {
    return (
      <HomepageProductsMobile
        title={title}
        products={products}
        key={key}
        index={index}
      />
    );
  };

  /**
   * Method to render categories displayed as a grid
   * @param categories - array of categories objects
   * @param title - section title
   * @returns {*}
   * @private
   */
  _renderCategories = (title, categories, key, index) => {
    return (
      <HomepageCategoriesMobile
        title={title}
        categories={categories}
        key={key}
        index={index}
      />
    );
  };

  /**
   * Method to render delivery info
   * @param areas - array of areas objects
   * @param title - section title
   * @param {boolean} isHidePostalCodes - is hide postal codes from delivery area
   * @returns {*}
   * @private
   */
  _renderDelivery = (title, areas, isHidePostalCodes, key, index) => {
    return (
      <HomepageDeliveryMobile
        title={title}
        areas={areas}
        key={key}
        isHidePostalCodes={isHidePostalCodes}
        index={index}
      />
    );
  };

  /**
   * Method to render text/image section
   * @param content - array of text/image blocks
   * @returns {*}
   * @private
   */
  _renderTextBlock = (content, key) => {
    return <HomepageTextMobile content={content} key={key} />;
  };

  /**
   * Method to render seo text section
   * @param content - array of text blocks
   * @returns {*}
   * @private
   */
  _renderSeoTextBlock = (content, key) => {
    const {
      seoRestaurantTitle,
      seoCategoriesTitle
    } = this.props.store.seoDataStore;
    const { homepageAnchors } = this.props.store.seoDataStore;
    const anchors = homepageAnchors.map((elem, index) => {
      return {
        label: elem.title,
        hash: elem.isFirst
          ? 'homepage-mobile'
          : createHomepageHrefLink([elem.type, index])
      };
    });
    return (
      <HomepageSeoText
        content={content}
        key={key}
        headerText={seoRestaurantTitle}
        subHeaderText={seoCategoriesTitle}
        anchors={anchors}
        isMobile={true}
      />
    );
  };

  /**
   * Method to render opening hours section
   * @private
   */
  _renderOpeningHoursBlock = (key, index) => {
    const { sortedDelivery, sortedPickup } = this.props.store.openingHoursStore;

    return (
      <HomepageOpeningHours
        key={key}
        deliverySchedule={sortedDelivery}
        pickupSchedule={sortedPickup}
        index={index}
        isMobile={true}
      />
    );
  };

  /**
   * Method to render single element
   * @param element - element object
   * @param key
   * @returns {*}
   * @private
   */
  _renderElement = (element) => {
    const areas = this.props.store.restaurantStore.branch.areaCodes;
    const { homepageAnchors } = this.props.store.seoDataStore;
    const index = homepageAnchors.findIndex(
      (anchor) => anchor.id === element.id
    );
    switch (element.type) {
      case ELEMENTS_TYPES.SLIDER:
        return this._renderBannerSlider(element.slides, element.id);
      case ELEMENTS_TYPES.CATEGORIES:
        return this._renderCategories(
          element.title,
          element.categories,
          element.id,
          index
        );
      case ELEMENTS_TYPES.PRODUCTS:
        return this._renderProducts(
          element.title,
          element.products,
          element.id,
          index
        );
      case ELEMENTS_TYPES.OFFERS:
        return this._renderOffers(
          element.title,
          element.offers,
          element.id,
          index
        );
      case ELEMENTS_TYPES.TEXT_IMAGE:
        return this._renderTextBlock(element.contentArray, element.id);
      case ELEMENTS_TYPES.AREA_CODE:
        return this._renderDelivery(
          element.title,
          areas,
          element.hidePostalCodes,
          element.id,
          index
        );
      case ELEMENTS_TYPES.SEO_TEXT:
        return this._renderSeoTextBlock(element.items[0], element.id);
      case ELEMENTS_TYPES.OPENING_TIMES:
        return this._renderOpeningHoursBlock(element.id, index);
      default:
        console.log('new element.type', element.type);
    }
  };

  render() {
    const {
      homepageElements,
      homepageCategory
    } = this.props.store.homepageStore;
    const { restaurant, branch } = this.props.store.restaurantStore;
    return (
      <div className="homepage-mobile" id="homepage-mobile">
        <CommonHeaderMobile />
        <main className="mobile-content">
          <div
            className="category-observable-list-item"
            data-categoryid={homepageCategory.id}
          >
            {homepageElements.map((item) => {
              return this._renderElement(item);
            })}
          </div>
        </main>
        <Footer restaurant={restaurant} isStartPage={false} branch={branch} />
      </div>
    );
  }
}

export default withTranslation(['home', 'common'])(HomepageMobile);
