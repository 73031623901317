import { History } from 'history';
import { action, computed, observable } from 'mobx';
import qs from 'qs';

import animation from 'client/enums/animation.enum';
import OFFER_TYPES from 'client/enums/offer_types.enum';
import states from 'client/enums/states.enum';
import { OfferModel } from 'client/models/offer.model';
import { IOfferCategory } from 'client/models/offer_category.model';
import { IBasketOffer } from 'client/stores/basket/basket.store.types';
import RootStore from 'client/stores/index';
import openModal from 'client/utils/openModal';
import { generateLinkFor } from 'client/utils/routing';
import { IS_CLIENT } from 'config';

import i18n from '../../../i18n';
import { getUtmParamsFromSearch } from '../../utils/helpers';

/**
 * Offers list class. Used to work with offers.
 * @constructor
 * @param {instance} api - {@link API} instance.
 * @param {instance} storage - {@link storage} instance.
 * @property {array <object>} offers - Observable array of offers.
 * @property {boolean} isFreeItemModalAvailableForShowing - True if FreeItemModal not shown
 * @property {boolean} isFreeItemModalShowedBeforeOrder - True if FreeItemModal not shown before order
 */
export default class OffersStore {
  public api: any;

  public readonly root: RootStore;

  constructor(root: RootStore, state: OffersStore, api: any) {
    Object.assign(this, state);

    this.api = api;

    this.root = root;

    if (state.offers && state.offers.length > 0) {
      this.prepareOffers(state.offers);
    }
  }

  @observable offers: OfferModel[] = [];

  @observable offerToEdit: Partial<IBasketOffer> = {};

  @observable offer: Partial<OfferModel> | null = null;

  @observable freeItem: OfferModel | null = null;

  @observable offerChange = false;

  @observable isFreeItemModalAvailableForShowing = true;

  @observable isFreeItemModalShowedBeforeOrder = false;

  @observable editingIndex = null;

  @observable animateId = 0;

  @observable offerCategory = {
    id: -1,
    name: '',
    pos: -1,
    description: '',
    picurl: '',
    discount: 0,
    discount_all: 0,
    mbw: 0,
    picurl_preview: '',
    product_display_type: '',
    cat_display_type: '',
    count_mbw: 0,
    seo_kitchens: [],
    seo_categories: [],
    name_css_position: '',
    is_show_picture: false,
    is_big_picture: false,
    productsPreview: []
  };

  @observable offersBranchId = null;

  /**
   * Set active offer
   * @param offer
   */
  @action setOffer(offer: OfferModel = null) {
    this.offer = offer;
  }

  /**
   * Method to set animation after adding offer to basket
   * @method animateOffer
   */
  @action animateOffer(offerId: number) {
    if (!this.root.restaurantStore.cantAddProductsToBasket) {
      this.animateId = offerId;

      setTimeout(() => {
        this.animateId = 0;
      }, animation.time.buttonToBuyProduct);
    }
  }

  /**
   * Action to clear all offers
   */
  @action clearOffersList() {
    this.offers = [];

    this.freeItem = null;
  }

  /**
   * Set custom offer category
   * @memberof OffersStore
   */
  @action setOfferCategory(category: IOfferCategory) {
    this.offerCategory = {
      ...this.offerCategory,
      name: category.title,
      description: category.description,
      picurl: category.picurl
    };
  }

  /**
   * Action by offer click
   * Opens modal and load first group products
   * @memberof OffersStore
   */
  @action loadSingleOffer(offerId: string) {
    let offer: Record<string, any> = this.offers.find(
      (i) => i.id === parseInt(offerId, 10)
    );

    offer = offer ? offer.getToJS() : null;

    this.setOffer(
      offer ? new OfferModel(offer.id, offer as any, this.root) : null
    );

    return this.loadCurrentGroupProducts();
  }

  /**
   * Load products of current product group
   * @memberof OffersStore
   */
  loadCurrentGroupProducts(): Promise<any> {
    if (
      !this.offer ||
      !this.offer.currentGroup ||
      this.offer.currentGroup.hasProducts
    )
      return Promise.resolve();

    this.offer.currentGroup.setLoading(true);

    return this.api
      .getProductsByGroupId(this.offer.id, this.offer.currentGroup.id)
      .then((products) => {
        this.offer.currentGroup.setProducts(products);

        this.offer.currentGroup.setLoading(false);

        return Promise.resolve();
      })
      .catch((err) =>
        Promise.reject(err || 'Offers products could not be loaded')
      );
  }

  /**
   * Handle close action in offer modal
   * @memberof OffersStore
   */
  @action closeOffer(history: History) {
    const { search } = history.location;
    const utmParams = getUtmParamsFromSearch(search);
    const searchWithUtm = new URLSearchParams(utmParams);

    history.replace({ search: searchWithUtm.toString() });
  }

  /**
   * Handle previous step in offer modal
   * @memberof OffersStore
   */
  @action offerModalStepBack(history: History) {
    const { search } = history.location;
    const params = qs.parse(search, { ignoreQueryPrefix: true });
    const utmParams = getUtmParamsFromSearch(search);
    const searchWithUtm = new URLSearchParams({ ...params, ...utmParams });
    const offerType = params?.offerType;

    if (offerType === OFFER_TYPES.FREE) {
      this.resetOfferChange();

      history.replace({
        search: new URLSearchParams({ ...utmParams }).toString()
      });

      return;
    }

    if (this.offer?.firstStep && !this.offer.currentGroup?.step.showProduct) {
      if (this.offerChange) {
        history.push({ search: searchWithUtm.toString() });
      } else {
        history.goBack();
      }

      this.resetOfferChange();
    } else {
      if (this.offer?.currentGroup?.step.finished) {
        if (!this.offer.currentGroup.choosedProduct?.availableForOptions) {
          this.offer.reduceStep?.();
        } else {
          this.offer.currentGroup.step.finished = false;
        }

        return;
      }

      if (this.offer.currentGroup?.step.showProduct) {
        this.offer.currentGroup.step.showProduct = false;

        return;
      }

      this.offer?.reduceStep?.();
    }
  }

  /**
   * Handle next step in offer modal
   * @memberof OffersStore
   */
  @action offerModalStepNext(props, fromBasket = false, fromModal = false) {
    const { search } = props.history.location;
    const utmParams = getUtmParamsFromSearch(search);
    const searchWithUtm = new URLSearchParams(utmParams);

    if (!this.root.basketStore.isAvailableToAdd)
      return console.warn('Offer can not be added to the basket', this.offer);

    if (this.offer.lastStep) {
      if (this.root.restaurantStore.cantAddProductsToBasket) {
        return console.warn('shop closed');
      }

      if (
        !this.root.basketStore.isOffersCanBeAddedToBasket(this.offer) &&
        !this.offerChange
      ) {
        return console.warn('offer can`t be added');
      }

      if (this.offerChange) {
        const link = generateLinkFor(states.basket, props, {}, true);

        props.history.replace({ ...link, search: searchWithUtm.toString() });
      } else if (
        this.root.themesStore.isMobile &&
        !this.root.themesStore.isShellThemeActive
      ) {
        const link = generateLinkFor(states.app, props, {}, true);

        props.history.replace({ ...link, search: searchWithUtm.toString() });
      } else if (props.store.themesStore.isShellCategoryHorizontalType) {
        props.history.push({
          search: searchWithUtm.toString()
        });
      } else {
        const link = generateLinkFor(`${states.cat}/-1`, props, {}, true);

        props.history.replace({ ...link, search: searchWithUtm.toString() });
      }

      this.root.basketStore.addOffer(this.offer, 1, fromBasket, fromModal);
    } else if (this.offer.currentGroup.choosedProduct) {
      if (!this.offer.currentGroup.choosedProduct.availableForOptions) {
        this.offer.increaseStep();

        return this.loadCurrentGroupProducts();
      }

      if (!this.offer.currentGroup.step.showProduct) {
        this.offer.currentGroup.choosedProduct.setSize(
          this.offer.currentGroup.choosedProduct.sizes[0].id
        );

        this.offer.currentGroup.step.showProduct = true;
      } else {
        this.offer.currentGroup.step.finished = true;

        this.offer.increaseStep();

        return this.loadCurrentGroupProducts();
      }
    }
  }

  /**
   * Handle adding free item to basket
   * @param {object} props
   * @returns
   * @memberof OffersStore
   */
  @action addFreeItemToBasket(props: { history: History; store: RootStore }) {
    const { history } = props;
    const { search, pathname } = history.location;
    const utmParams = getUtmParamsFromSearch(search);
    const searchWithUtm = new URLSearchParams(utmParams);

    if (this.root.restaurantStore.cantAddProductsToBasket) {
      return console.warn('shop is closed');
    }

    this.root.basketStore.addOffer(this.freeItem, 1);

    history.push({ pathname, search: searchWithUtm.toString() });
  }

  /**
   * Handle free item click
   * @param {object} props
   * @memberof OffersStore
   */
  @action freeItemButtonClick(history: History) {
    const { basketStore } = this.root;

    if (
      !basketStore.isFreeOfferInBasket &&
      (this.freeItem.isValid || this.freeItem.isValidForPreorderNow)
    ) {
      this.showFreeItemModal(history);
    }

    if (
      basketStore.isFreeOfferInBasket &&
      (this.freeItem.isValid || this.freeItem.isValidForPreorderNow)
    ) {
      const freeItem = basketStore.offers.find(
        (offerGroup) => offerGroup.offer.isFreeOffer
      ).offer;

      const { offerType, uniqString } = this.freeItem;

      const link = openModal('offerModal', {
        offerType,
        offerUniqString: uniqString
      });

      this.setFreeItemOffer(freeItem);

      history.replace(link);
    }
  }

  /**
   * Method to prepare offers after loading
   * @memberof OffersStore#
   * @method prepareOffers
   */
  @action prepareOffers(offers: OfferModel[]) {
    this.offersBranchId = this.root.restaurantStore.branch.id;

    this.offers = offers.map(
      (offer) => new OfferModel(offer.id, offer, this.root)
    );
  }

  /**
   * Prepare loaded free item
   * @param {object} freeItem
   * @memberof OffersStore
   */
  @action prepareFreeItem(freeItem: OfferModel) {
    let freeItemModel = null;

    if (freeItem && Object.keys(freeItem).length > 0) {
      freeItemModel = new OfferModel(freeItem.id, freeItem, this.root);
    }

    this.setFreeItemOffer(freeItemModel);
  }

  /**
   * Method to get offers by category.
   * @memberof OffersStore#
   * @method loadOffers
   */
  @action loadOffers() {
    return !this.offersLoaded
      ? this.api
          .getOffers()
          .then((offers) => this.prepareOffers(offers))
          .catch((err) => Promise.reject(err || 'Offers could not be loaded'))
      : Promise.resolve();
  }

  /**
   * Get free item from api
   */
  @action loadFreeItem() {
    if (!this.freeItemIsLoaded && IS_CLIENT) {
      this.root.basketStore.setLoading(true);

      return this.api
        .getFreeItem()
        .then((freeItem) => this.prepareFreeItem(freeItem))
        .then(() => this.root.basketStore.setLoading(false))
        .catch((err) => Promise.reject(err || 'Free item could not be loaded'));
    }

    return Promise.resolve();
  }

  /**
   * Method for setting free item offer
   * @memberof OffersStore#
   * @method showFreeItemModalIfNeed
   */
  @action setFreeItemOffer(freeOffer: OfferModel) {
    this.freeItem = freeOffer;
  }

  /**
   * Method for setting offer to change
   * @memberof OffersStore#
   * @method showFreeItemModalIfNeed
   */
  @action setOfferToChange(offerUniqString: string) {
    const offerInBasket = this.root.basketStore.offers.find(
      (i) => i.offer.uniqString === offerUniqString
    );

    if (offerInBasket) {
      this.offerToEdit = {
        offer: offerInBasket.offer,
        count: offerInBasket.count
      };

      this.offerChange = true;

      const offer = offerInBasket.offer.getToJS();

      this.offer = new OfferModel(offer.id, offer, this.root);
    }
  }

  /**
   * Method for setting offer changing index
   * @param {number} index - index of offer in offer array
   * @memberof OffersStore#
   * @method setChangeIndex
   */
  @action setChangeIndex(index: number) {
    this.editingIndex = index;
  }

  /**
   * Set state if offer is changing now
   * @memberof OffersStore
   */
  @action setOfferChange(flag: boolean) {
    this.offerChange = flag;
  }

  /**
   * Reset offer change state after changing
   * @memberof OffersStore
   */
  @action resetOfferChange() {
    this.offerChange = false;

    this.offerToEdit = {};
  }

  /**
   * Method for showing free item modal view
   * @param {object} props - React props
   * @memberof OffersStore#
   * @method showFreeItemModalIfNeed
   */
  @action showFreeItemModal(history: History) {
    const { offerType, id } = this.freeItem;

    this.isFreeItemModalAvailableForShowing = false;

    const freeOfferUrl = openModal('offerModal', {
      offerId: id,
      offerType
    });

    history.replace(freeOfferUrl);
  }

  /**
   * Method for checking that free item available and showing modal if need
   * @memberof OffersStore#
   * @method showFreeItemModalIfNeed
   */
  @action showFreeItemModalIfNeed(props: {
    history: History;
    store: RootStore;
  }) {
    if (this.root.basketStore.isFreeOfferInBasket) return false;

    if (this.root.restaurantStore.branch.getOfferIsSimplified) return false;

    return this.freeItem && this.isFreeItemAvailable
      ? this.showFreeItemModal(props)
      : null;
  }

  /**
   * Method for sorting offers list by availability parameter
   */
  @computed get sortedOffersList(): OfferModel[] {
    return this.offers.sort((x, y) => {
      if (x.isValidForOrder === y.isValidForOrder) {
        return 0;
      }

      return x.isValidForOrder ? -1 : 1;
    });
  }

  /**
   * Method for checking that free item available for purchasing
   * @memberof OffersStore#
   * @method isFreeItemAvailable
   */
  @computed get isFreeItemAvailable() {
    return this.isFreeItemEnabled;
  }

  /**
   * Method for checking that free feature enabled
   * @memberof OffersStore#
   * @method isFreeItemAvailable
   */
  @computed get isFreeItemEnabled() {
    if (this.freeItem === null) return false;

    if (this.freeItem && !this.freeItem.isValid) return false;

    return (
      this.freeItem &&
      this.freeItem.products !== null &&
      this.freeItem.products.length > 0
    );
  }

  /**
   * Method to serialize every offer in offer list
   */
  getToJS() {
    return {
      offers: this.offers.map((offer) => offer.getToJS()),
      freeItem: this.freeItem.getToJS()
    };
  }

  /**
   * Method to determine if there is some offers in store
   * @memberof OffersStore#
   * @method hasOffers
   */
  @computed get hasOffers(): boolean {
    return !!this.offers.length;
  }

  /**
   * Methods to recognize if offers loaded for current branch
   */

  @computed get offersLoaded(): boolean {
    return this.offersBranchId === this.root.restaurantStore.branch.id;
  }

  /**
   * Check if free item is already loaded
   * @readonly
   * @memberof OffersStore
   */
  @computed get freeItemIsLoaded(): boolean {
    return this.freeItem && Object.keys(this.freeItem).length > 0;
  }

  /**
   * Method for calculating confirm button text in offers modal
   */
  @computed get confirmButtonText() {
    const canAddOffer =
      this.root.basketStore.isOffersCanBeAddedToBasket(this.offer) ||
      this.offerChange;

    if (!canAddOffer && this.offer?.lastStep) {
      return i18n.t('offers:offerCantAdd');
    }

    if (
      this.offer?.lastStep &&
      (this.offer?.currentGroup.step.finished ||
        this.offer?.currentGroup.step.showProduct)
    ) {
      return `${i18n.t(
        'offers:confirmModal'
      )} ${this.root.basketStore.getIntlPrice(this.offer?.totalPrice)}`;
    }

    return i18n.t('offers:nextModalStep');
  }

  /**
   * Method to check offer's availability in offers array
   * @memberof OffersStore#
   * @method isOffersArrayHasOfferWithId
   */
  isOffersArrayHasOfferWithId(offerId: number) {
    return this.offers.find((offer) => offer.id === offerId);
  }

  /**
   * Method to add single offer to offers array
   * @param {OfferModel} singleOffer - offer object.
   * @memberof OffersStore#
   * @method addSingleOfferToCommonArray
   */
  @action addSingleOfferToCommonArray(singleOffer: OfferModel) {
    const offerId = singleOffer.id;

    if (!this.isOffersArrayHasOfferWithId(offerId)) {
      this.offers.push(singleOffer);
    }
  }

  /**
   * Method to get one offer form the offers list by id
   * @param {number} offerId - unique identifier of the offer
   */
  getOfferById(offerId: number) {
    return this.offers.find((offer) => offer.id === offerId);
  }

  /**
   * Method to load single offer item
   * @param {number} offer_id - unique identifier of offer.
   * @memberof OffersStore#
   * @method loadSingleOfferById
   */
  @action loadSingleOfferById(offer_id: number) {
    return this.api
      .getSingleOfferById(offer_id)
      .then((data) => this.offerFromJS(offer_id, data))
      .catch(() =>
        Promise.reject('Something went wrong in method loadSingleOfferById')
      );
  }

  /**
   * Method to map json to Product model
   * @param offer_id - id
   * @param offer - json
   */
  @action offerFromJS(offer_id: number, offer: OfferModel) {
    return new OfferModel(offer_id, offer, this.root);
  }

  /**
   * Method to open shell theme
   * @param props
   * @private
   */
  _openAddressModal = (history: History) => {
    const addressUrl = openModal('addressModal');

    history.replace(addressUrl);
  };

  /**
   * Method add handle click to offer
   * @param offerId
   * @param offerType
   * @param props
   */
  @action addOffer(offerId: number, offerType: string, history: History) {
    const { isLocationChosen } = this.root.restaurantStore;
    const { isDelivery } = this.root.deliveryAddressStore;

    if (isLocationChosen || !isDelivery) {
      const offerUrl = openModal('offerModal', {
        offerType,
        offerId: offerId.toString()
      });

      history.push(offerUrl);
    } else {
      const offer = this.offers.find(({ id }) => id === offerId);

      this.setOffer(offer);

      this._openAddressModal(history);
    }
  }

  isOfferValidForSelectedDelivery(offer: OfferModel) {
    const { deliveryAddressStore } = this.root;
    const { isDelivery } = deliveryAddressStore;

    if (isDelivery) {
      return offer.validForDelivery;
    }

    return offer.validForPickup;
  }
}
