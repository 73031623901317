import { Loader } from '@googlemaps/js-api-loader';

import {
  AutocompletePrediction,
  AutocompleteService,
  Geocoder,
  GeocoderResult,
  GoogleSessionToken,
  PlaceResult,
  PlacesService
} from 'client/services/googleService/googleService.type';
import { googlePlacesAPIKey, googlePlacesSupportedCountryCodes } from 'config';

class GoogleService {
  autocomplete: null | AutocompleteService = null;

  #geocoder: null | Geocoder = null;

  #places: null | PlacesService = null;

  #sessionToken: null | GoogleSessionToken = null;

  #timerId: NodeJS.Timeout | null = null;

  init = (locale: string) => {
    if (this.autocomplete) {
      return;
    }

    const element = document.createElement('div');

    this.autocomplete = new google.maps.places.AutocompleteService();

    this.#geocoder = new google.maps.Geocoder();

    this.#places = new google.maps.places.PlacesService(element);

    console.info(`Google Maps Initialized with ${locale} locale`);
  };

  #setSessionToken = () => {
    const timeout = 2 * 60 * 1000; // 2 minutes

    this.#sessionToken = new google.maps.places.AutocompleteSessionToken();

    this.#timerId = setTimeout(() => {
      this.clearSessionToken();
    }, timeout);
  };

  clearSessionToken = () => {
    this.#timerId && clearTimeout(this.#timerId);

    this.#sessionToken = null;

    this.#timerId = null;
  };

  getPlacesPredictions = (
    input: string,
    country: string | string[] = googlePlacesSupportedCountryCodes
  ) => {
    if (!this.#sessionToken) {
      this.#setSessionToken();
    }

    return new Promise<AutocompletePrediction[] | null>((resolve, reject) => {
      const { PlacesServiceStatus } = google.maps.places;

      const request = {
        input,
        types: ['address'],
        componentRestrictions: {
          country
        },
        sessionToken: this.#sessionToken!
      };

      this.autocomplete?.getPlacePredictions(request, (result, status) => {
        if (
          status === PlacesServiceStatus.OK ||
          status === PlacesServiceStatus.ZERO_RESULTS
        ) {
          return resolve(result);
        }

        reject(result);
      });
    });
  };

  getPlaceInfo = (placeId: string) => {
    if (!this.#sessionToken) {
      this.#setSessionToken();
    }

    return new Promise<GeocoderResult[]>((resolve, reject) => {
      const { GeocoderStatus } = google.maps;
      const request = { placeId };

      this.#geocoder?.geocode(request, (results, status) => {
        this.clearSessionToken();

        if (
          status === GeocoderStatus.OK ||
          status === GeocoderStatus.ZERO_RESULTS
        ) {
          return resolve(results);
        }

        reject(results);
      });
    });
  };

  findPlaceFromQuery = (query: string) =>
    new Promise<Pick<PlaceResult, 'place_id'>[]>((resolve, reject) => {
      const { PlacesServiceStatus } = google.maps.places;
      const request = { query, fields: ['place_id'] };

      this.#places?.findPlaceFromQuery(request, (results, status) => {
        this.clearSessionToken();

        if (
          status === PlacesServiceStatus.OK ||
          status === PlacesServiceStatus.ZERO_RESULTS
        ) {
          return resolve(results || []);
        }

        reject(results);
      });
    });

  verifyAddressType = (addressTypes: string[]) => {
    const regex = /street_address|street_number|premise/;

    return addressTypes.some((type) => regex.test(type));
  };

  getAddressFromGeoInfo = (geocodeResult?: GeocoderResult) => {
    const address = {
      country: '',
      city: '',
      street: '',
      streetNumber: '',
      zip: ''
    };

    geocodeResult?.address_components?.forEach(({ types, long_name }) => {
      if (types.toString() === 'country,political') {
        address.country = long_name;
      }

      if (types.includes('sublocality')) {
        address.city = long_name;
      }

      if (types.toString() === 'locality,political') {
        address.city = address.city
          ? `${address.city}, ${long_name}`
          : long_name;
      }

      if (types.toString() === 'route') {
        address.street = long_name;
      }

      if (types.toString() === 'street_number') {
        address.streetNumber = long_name;
      }

      if (types.toString() === 'postal_code') {
        address.zip = long_name;
      }
    });

    return address;
  };
}

const googleService = new GoogleService();

const loadGoogleMaps = (language = 'de') => {
  if (googleService.autocomplete) {
    return;
  }

  const loader = new Loader({
    apiKey: googlePlacesAPIKey || '',
    version: 'quarterly',
    libraries: ['places'],
    language
  });

  loader
    .load()
    .then(() => {
      googleService.init(language);
    })
    .catch((err) => {
      console.error(err);

      console.error('Failed to load Google Maps');
    });
};

export { loadGoogleMaps, GoogleService };

export default googleService;
