import { inject, observer } from 'mobx-react';
import React from 'react';
import { Switch, withRouter } from 'react-router-dom';

import { IS_CLIENT, IS_SERVER } from 'config';

import { matchRoute } from '../../server/matchRoute';
import states from '../enums/states.enum';
import GlobalAppLoader from '../themes/theme-hermes/components/loaders/global_app_loader';
import { loadBranchData, loadData, prepareDataInStores } from '../utils/boot';
import { mapQueryParamsToObject } from '../utils/functions';
import { getIsSlugSupported } from '../utils/routing';

@inject('store')
@observer
@withRouter
export default class ReactRouterDataSwitchInternal extends React.Component {
  constructor(props) {
    super(props);

    if (IS_CLIENT) {
      this.state = {
        loading: this.props.store.loading
      };
    }
  }

  componentDidMount() {
    const matchedRoute = matchRoute(
      this.props.location.pathname,
      this.props.store.restaurantStore.hostName,
      this.props.store.aggregatorStore.aggregator.getAggregatorMode
    );

    if (this.getCurrentRouteState(this.props) === states.branchList) {
      this._openCategoriesIfBranchChanged(matchedRoute);
    }
  }

  /**
   * Method to check if categories screen should be open
   * @param matchedRoute - new route params
   * @private
   */
  _openCategoriesIfBranchChanged = (matchedRoute) => {
    if (this.props.store.categoryMenuStore.isShouldOpenCategories) {
      this.props.store.categoryMenuStore.setShouldOpenCategoriesState(false);

      this.props.history.push(
        `/${matchedRoute.params.slug}/${matchedRoute.params.branchId}/${states.cat}`
      );
    }
  };

  _updateLoadingStatus(status) {
    const newState = this.props.store.loading || status;

    if (newState !== this.state.loading) {
      this.setState({
        loading: this.props.store.loading || status
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (IS_SERVER) {
      return;
    }

    const { location } = this.props;
    const params = location ? mapQueryParamsToObject(location.search) : {};

    const matchedRoute = matchRoute(
      nextProps.location.pathname,
      this.props.store.restaurantStore.hostName,
      this.props.store.aggregatorStore.aggregator.getAggregatorMode
    );

    prepareDataInStores(this.props.store, params);

    if (
      nextProps.store.themesStore.isShellThemeActive &&
      matchedRoute.params &&
      !matchedRoute.params.aggregator &&
      matchedRoute.key === 'slug'
    ) {
      this._updateLoadingStatus(true);

      this.props.store.themesStore.setTemplateLoadingStatus(false);

      return loadData(matchedRoute, this.props.store).then((data) => {
        if (data && data.maintenance) {
          this.props.store.themesStore.clearTemplate();
        }

        loadBranchData(
          {
            branchId: this.props.store.restaurantStore.restaurant.firstBranchId
          },
          this.props.store
        ).then(() => {
          if (data && data.maintenance) {
            const url = `${this.routePrefix(matchedRoute)}/maintenance-mode`;

            this.props.history.replace(url);
          } else {
            this.redirectToCategoriesList(
              this.props.store.restaurantStore.branch.defaultThemeHomepage,
              matchedRoute
            );
          }

          this._updateLoadingStatus(false);

          return this.props.store.restaurantStore.setIsFetching(false);
        });
      });
    }

    if (matchedRoute.key === states.maintenanceMode || matchedRoute.key === states.shopMoved) {
      this._updateLoadingStatus(false);

      return this.props.store.restaurantStore.setIsFetching(false);
    }

    if (matchedRoute.key === 'category' || matchedRoute.key === 'subcategory') {
      this._updateLoadingStatus(false);

      const currentRoute = matchRoute(
        this.props.location.pathname,
        this.props.store.restaurantStore.hostName,
        this.props.store.aggregatorStore.aggregator.getAggregatorMode
      );

      if (
        currentRoute.params?.catId !== matchedRoute.params.catId ||
        currentRoute.params?.subId !== matchedRoute.params.subId
      ) {
        return this.props.store.categoryMenuStore.setActiveCategory(
          parseInt(matchedRoute.params.catId, 10),
          parseInt(matchedRoute.params.subId, 10)
        );
      }

      return;
    }

    if (
      matchedRoute.key === 'branch' &&
      Object.keys(this.props.store.restaurantStore.restaurant).length !== 0 &&
      matchedRoute.params.branchId !==
        this.props.store.restaurantStore.branch.branchId
    ) {
      this._updateLoadingStatus(true);

      return loadBranchData(matchedRoute.params, this.props.store).then(() => {
        this._openCategoriesIfBranchChanged(matchedRoute);

        this._updateLoadingStatus(false);

        return this.props.store.restaurantStore.setIsFetching(false);
      });
    }

    if (matchedRoute.key === 'branch') {
      // Route to categories in case isPortalAppThemeActive and opened homepage route
      if (
        this.props.store.themesStore.isPortalAppThemeActive &&
        (!this.getCurrentRouteState(nextProps) ||
          this.getCurrentRouteState(nextProps) === matchedRoute.params.branchId)
      ) {
        this.redirectToCategoriesList(
          this.props.store.themesStore.isPortalAppThemeActive,
          matchedRoute
        );
      }
    }

    this._updateLoadingStatus(false);
  }

  redirectToCategoriesList = (isRedirect, matchedRoute) => {
    const redirectToCategories = isRedirect ? `/${states.cat}` : '';

    const url = `${this.routePrefix(matchedRoute)}/${
      this.props.store.restaurantStore.branch.branchId
    }${redirectToCategories}`;

    this.props.history.replace(url);
  };

  routePrefix = (matchedRoute) =>
    getIsSlugSupported(this.props.store.restaurantStore.hostName)
      ? `/${matchedRoute.params.slug}`
      : '';

  getCurrentRouteState(props) {
    const routeParams = props.location.pathname.split('/');

    return routeParams.length && routeParams[routeParams.length - 1];
  }

  render() {
    if (IS_SERVER) {
      return <Switch>{this.props.children}</Switch>;
    }

    if (this.state.loading) {
      return <GlobalAppLoader fullPage />;
    }

    return <Switch>{this.props.children}</Switch>;
  }
}
