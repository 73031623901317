import qs from 'qs';

import { IS_CLIENT } from 'config';

const delay = (millis: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(null), millis);
  });

const isHungerDomain = () => {
  if (IS_CLIENT) {
    const { host } = window.location;
    const regex = /hunger\.de|restaurant-hunger.*devteam\.win|localhost:4000/;
    const result = regex.test(host);

    return result;
  }

  return false;
};

//TODO: rework this function, it should get array of search params like the second parameter which aren't had to be removed. Now it works only for utm codes and experimentVariantId. Don't forget about function naming.
const getUtmParamsFromSearch = (search: Location['search']) => {
  const params = qs.parse(search, { ignoreQueryPrefix: true });

  return Object.keys(params).reduce((accum, key: string) => {
    const isStayedParam =
      key.startsWith('utm') || key.startsWith('experimentVariantId');

    if (isStayedParam) {
      return { ...accum, [key]: params[key] };
    }

    return accum;
  }, {});
};

export { delay, isHungerDomain, getUtmParamsFromSearch };
