import { inject, observer } from 'mobx-react';
import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import MobileModalsRoute from 'client/routes/MobileModalsRoute';

import Loadable from '../components/AsyncLoading';
import BranchListMobile from '../components/BranchSelector/components/BranchListMobile';
import states from '../enums/states.enum';
import CategoriesList from '../themes/theme-hermes/components/category_menu/_category_menu_mobile/categories_list/CategoriesList';
import HomepageMobile from '../themes/theme-hermes/components/homepage/mobile';
import OpeningHours from '../themes/theme-hermes/components/opening_hours/mobile/OpeningHoursMobile';
import { generateRoutePath } from '../utils/routing';

const AsyncBasket = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "basket", webpackPrefetch: true */ 'client/themes/theme-hermes/components/basket'
    )
});

const AsyncAddress = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "address", webpackPrefetch: true */ '../themes/theme-hermes/components/address'
    )
});

const AsyncCheckout = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "checkout", webpackPrefetch: true */ '../themes/theme-hermes/components/checkout'
    )
});

const AsyncProductCertificate = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "productcertificate", webpackPrefetch: true */ '../themes/theme-hermes/components/product_certificate/'
    )
});

@withRouter
@inject('store')
@observer
export default class MobileRoute extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route
            path={`${generateRoutePath(
              `:branchId/${states.cat}/:catId/:subId`,
              this.props.store.restaurantStore.hostName
            )}`}
            render={() => this.props.mainComponent()}
          />
          <Route
            path={`${generateRoutePath(
              `:branchId/${states.cat}/:catId`,
              this.props.store.restaurantStore.hostName
            )}`}
            render={() => this.props.mainComponent()}
          />
          <Route
            path={`${generateRoutePath(
              `:branchId/${states.cat}`,
              this.props.store.restaurantStore.hostName
            )}`}
            component={CategoriesList}
          />
          <Route
            path={`${generateRoutePath(
              `:branchId/${states.address}`,
              this.props.store.restaurantStore.hostName
            )}`}
            component={AsyncAddress}
          />
          <Route
            path={`${generateRoutePath(
              `:branchId/${states.basket}`,
              this.props.store.restaurantStore.hostName
            )}`}
            component={AsyncBasket}
          />
          <Route
            path={`${generateRoutePath(
              `:branchId/${states.checkout}`,
              this.props.store.restaurantStore.hostName
            )}`}
            component={AsyncCheckout}
          />
          <Route
            path={`${generateRoutePath(
              `:branchId/${states.openingTimes}`,
              this.props.store.restaurantStore.hostName
            )}`}
            component={OpeningHours}
          />
          <Route
            path={`${generateRoutePath(
              `:branchId/${states.productCertificate}`,
              this.props.store.restaurantStore.hostName
            )}`}
            component={AsyncProductCertificate}
          />
          <Route
            path={`${generateRoutePath(
              `:branchId/${states.branchList}`,
              this.props.store.restaurantStore.hostName
            )}`}
            component={BranchListMobile}
          />
          <Route
            exact
            path={`${generateRoutePath(
              ':branchId',
              this.props.store.restaurantStore.hostName
            )}`}
            render={() => this.props.mainComponent()}
          />
          <Route
            exact
            path={`${generateRoutePath(
              // TODO what is this?
              ':branchId/kundeninformation',
              this.props.store.restaurantStore.hostName
            )}`}
            component={HomepageMobile}
          />
        </Switch>
        <Route
          path={`${generateRoutePath(':branchId', this.props.hostName)}`}
          component={MobileModalsRoute}
        />
      </>
    );
  }
}
