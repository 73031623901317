// Imports
import { inject, observer } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';

import SlickCarousel from '../../../../../../../components/SlickCarousel';
// Styles
import './styles.scss';
import { ELEMENTS_TYPES } from '../../../../../../../enums/homepage_elements_types.enum';
import { createHomepageHrefLink } from '../../../../../../../utils/functions';
import ButtonProductPriceAnimation from '../../../../buttons/ButtonProductPriceAnimation';

import { withTranslation } from 'react-i18next';

/**
 * Component to render categories on mobile homepage
 */
@inject('store')
@withRouter
@observer
class HomepageProductsDesktop extends React.Component {
  /**
   * Method to add product to basket
   * @param {number} productId
   */
  _onProductClick = (productId, disabled) => {
    const { history } = this.props;

    !disabled &&
      this.props.store.productsStore.addProductById(productId, history);
  };

  /**
   * Method for getting product price button label
   * @param {HomepageProductModel} product
   * @returns {*}
   */
  _productPriceLabel = (product) => {
    const { currencyCode } = this.props.store.restaurantStore.branch;
    const { isDelivery } = this.props.store.deliveryAddressStore;
    const language = this.props.store.restaurantStore.restaurant.getLang;

    return `${this.props.t('product_view:lblFrom')} ${product.priceLabel(
      isDelivery,
      language,
      currencyCode
    )}`;
  };

  /**
   * Method to render single product
   * @param item - product object
   * @param {number} animateId - animated product id
   * @param key
   * @returns {*}
   * @private
   */
  _renderItem = (item, key, animateId) => {
    const { isDelivery } = this.props.store.deliveryAddressStore;
    const { isShopClosed } = this.props.store.restaurantStore;

    const productCount =
      this.props.store.basketStore.products
        .filter((productItem) => productItem.product.id === item.product_id)
        .reduce((count, productItem) => count + productItem.count, 0) || null;

    const disabled = item.price(isDelivery) === 0;
    const purchasingBlocked = disabled || item.soldOut;

    return (
      <div
        className="item"
        key={key}
        onClick={this._onProductClick.bind(
          this,
          item.product_id,
          purchasingBlocked
        )}
      >
        <div className="item-header">
          <div className="item-title">{item.name}</div>
        </div>
        <div className="img-wrapper">
          {item.picUrl && <img src={item.picUrl} alt={item.name} />}
        </div>
        <div className="item-footer">
          <ButtonProductPriceAnimation
            animated={animateId === item.product_id}
            disabled={disabled}
            itemCount={productCount}
            isSoldOut={item.soldOut}
            isClosed={isShopClosed}
          >
            {this._productPriceLabel(item)}
          </ButtonProductPriceAnimation>
        </div>
      </div>
    );
  };

  next = () => this.slider.slickNext();

  prev = () => this.slider.slickPrev();

  render() {
    const { t, products, index, store } = this.props;

    const {
      productsStore: { animateId }
    } = store;

    const settings = {
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: 2,
      arrows: false,
      variableWidth: false,
      slide: '.item'
    };

    return (
      <section
        className="desktop-homepage-section desktop-homepage-products"
        id={createHomepageHrefLink([ELEMENTS_TYPES.PRODUCTS, index])}
      >
        <h2>{t('common:products')}</h2>
        <SlickCarousel
          ref={(ref) => {
            this.slider = ref;
          }}
          settings={settings}
        >
          {products.map((item, index) =>
            this._renderItem(item, index, animateId)
          )}
        </SlickCarousel>
        <div className="slider-controls">
          <button className="slider-control prev" onClick={this.prev}>
            <i className="mdi mdi-arrow-left" />
          </button>
          <button className="slider-control next" onClick={this.next}>
            <i className="mdi mdi-arrow-right" />
          </button>
        </div>
      </section>
    );
  }
}

export default withTranslation(['product_view', 'common'])(
  HomepageProductsDesktop
);
