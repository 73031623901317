import { observer } from 'mobx-react';
import React, { MouseEvent, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import PICTURE_MODES from '../../../enums/product_images.enum';
import states from '../../../enums/states.enum';
import { ProductModel } from '../../../models/product.model';
import { ModalsRouteType } from '../../../routes/ModalsRoute/ModalsRoute.type';
import { RootContext } from '../../../stores';
import ProductListCategoryHeader from '../../../themes/theme-hermes/components/products_list/common/header/ProductListCategoryHeader';
import ProductEmptyList from '../../../themes/theme-hermes/components/products_list/common/product-empty-list';
import ProductCell from '../../../themes/theme-hermes/components/products_list/common/products-list/product-cell/ProductCell';
import openModal from '../../../utils/openModal';

import './Mobile.scss';
import { searchMobileProps } from './types';

const SearchMobile = observer(({ style = {} }: searchMobileProps) => {
  const { t } = useTranslation(['product_view', 'not_found']);

  const {
    productsStore,
    restaurantStore,
    additivesStore,
    languagesStore,
    basketStore,
    categoryMenuStore
  } = useContext(RootContext);

  const history = useHistory();
  const createProductKey = useCallback((id: number) => `product-${id}`, []);
  const emptyListKey = useMemo(() => 'searching-empty-list', []);

  const pictureMode = restaurantStore.branch.showNoPicture
    ? PICTURE_MODES.NO_PICTURES
    : PICTURE_MODES.SMALL_PRODUCT_PICTURES;

  const openProductModal = (event: MouseEvent, product: ProductModel) => {
    event.stopPropagation();

    const productUrl = openModal('productModal', {
      productId: `${product.id}`
    });

    history.push(productUrl);
  };

  const onAddProductClick = (event: MouseEvent, product: ProductModel) => {
    event.stopPropagation();

    if (!product.soldOut) {
      categoryMenuStore.setContainerScrollPosition();

      basketStore.setLastProductClicked(product.id);

      productsStore.addProduct(product, history);
    }
  };

  const showHalalCertificate = (event: MouseEvent) => {
    event.stopPropagation();

    const certificateUrl = openModal(ModalsRouteType.CERTIFICATE_MODAL);

    history.push(certificateUrl);
  };

  const title = t('product_view:searchResultsFor', {
    request: productsStore.searchingRequest
  });

  const clearSearch = () => {
    productsStore.clearSearchingRequest();
  };

  const basketBounds = {
    ...basketStore.bounds[states.basket][states.basket],
    offsetY: categoryMenuStore.scrollableContainerPosition
  };

  const basketProducts: {
    [key: string]: number;
  } = {};

  basketStore.products.forEach(
    (productItem: { count: number; product: ProductModel }) => {
      if (!basketProducts[productItem.product.id]) {
        basketProducts[productItem.product.id] = productItem.count;
      } else {
        basketProducts[productItem.product.id] += productItem.count;
      }
    },
    {}
  );

  if (!productsStore.searchProducts.length) {
    style = {
      ...style,
      justifyContent: 'space-between'
    };
  }

  return (
    <div className="mobile-search" style={style}>
      <div className="mobile-search__title">
        <ProductListCategoryHeader
          title={title}
          bottomSpace
          additionalClass="is-mobile-search"
        />
        {!productsStore.searchProducts.length && (
          <div className="product-empty-list-message is-mobile-search">
            {t('product_view:noSearchResults')}
          </div>
        )}
      </div>
      <div className="mobile-search__content">
        {productsStore.searchProducts.length > 0 &&
          productsStore.searchProducts.map((product: ProductModel) => {
            const key = createProductKey(product.id);

            return (
              <ProductCell
                key={key}
                isShopClosed={restaurantStore.isShopClosed}
                product={product}
                animatedId={productsStore.animateId}
                isMobile
                additivesProducts={additivesStore.additivesProducts}
                hideProductArticles={
                  restaurantStore.branch.isHideArticleNumbers
                }
                onCellClick={
                  restaurantStore.branch.isUseAdvancedProductView
                    ? openProductModal
                    : onAddProductClick
                }
                onButtonClick={onAddProductClick}
                showHalalCertificate={showHalalCertificate}
                basketBounds={basketBounds}
                openProductModal={openProductModal}
                activeLanguage={languagesStore.activeLanguage.id}
                isUseAdvancedProductView={
                  restaurantStore.branch.isUseAdvancedProductView
                }
                fullProductListDesign={false}
                picturesMode={pictureMode}
                isShell={false}
                basketProducts={basketProducts}
                currency={restaurantStore.branch.currencyCode}
              />
            );
          })}

        {!productsStore.searchProducts.length && (
          <ProductEmptyList
            key={emptyListKey}
            message={t('product_view:noSearchResults')}
            buttonLabel={t('not_found:resetSearch')}
            onButtonClick={clearSearch}
            additionalClass="is-mobile-search"
            loadingAnimation={
              productsStore.isSearching
                ? restaurantStore.restaurant.loadingAnimation.url
                : null
            }
          />
        )}
      </div>
    </div>
  );
});

export { SearchMobile };
