import { inject, observer } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { IS_CLIENT } from 'config';

import states from '../enums/states.enum';
import SessionStorage from '../modules/storage/session_storage';
import ErrorPage from '../themes/theme-hermes/components/error/error-page';
import { generateLinkFor } from '../utils/routing';

@withRouter
@inject('store')
@observer
export default class ErrorBoundary extends React.Component {
  componentDidCatch(error) {
    if (IS_CLIENT) {
      if (!SessionStorage.getItem('reload')) {
        SessionStorage.setItem('reload', false);

        if (
          /Loading chunk [\d]+ failed/.test(error.message) &&
          !SessionStorage.getItem('reload')
        ) {
          const { slug, branch } = this.props.store.restaurantStore;

          SessionStorage.setItem('reload', true);

          window.location.href = generateLinkFor(
            states.app,
            {
              ...this.props,
              match: {
                params: {
                  slug,
                  branchId: branch.branchId
                }
              }
            },
            null,
            !!branch.branchId
          ).pathname;
        }
      } else {
        const {
          store: { errorStore }
        } = this.props;

        errorStore.setError({
          type: 'FAILURE',
          message: error.message,
          errorUrl: window.location.href,
          redirectUrl: '',
          statusCode: 200
        });
      }
    }
  }

  render() {
    const {
      children,
      store: { errorStore }
    } = this.props;

    if (errorStore.getErrorMessage) {
      return <ErrorPage />;
    }

    return children;
  }
}
