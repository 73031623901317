export default {
  appsmart: {
    branch: {
      branchName: 'app smart GmbH',
      getStreet: 'Abraham-Lincoln-Straße',
      getStreetNo: '7',
      getCityCode: '65189',
      getCity: 'Wiesbaden',
      getWebsitePhone: '0611 / 23 63 50',
      getVatin: 'DE293823874',
      getRegistrationNumber: 'HRB 27525, AG Wiesbaden',
      getOwnername: 'Matthias Thom und Boris Pomeranets',
      getOwneremail: 'service@app-smart.de'
    },
    restaurant: {
      getCountryPrefix: 'D',
      getOwneremail: 'service@ordersmart.de'
    }
  }
};
