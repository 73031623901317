import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface IMbvInfoRowProps extends WithTranslation {
  isDelivery: boolean;
  isLocationChosen: boolean;
  mbvMinPrice: number;
  mbvMaxPrice: number;
  minPrice: number;
}

interface IMbvInfoRowNavProps {
  isDelivery: boolean;
  isLocationChosen: boolean;
  mbvMinPrice: number;
  mbvMaxPrice: number;
  minPrice: number;

  t: (key: string, value: Record<string, any>) => string;
}

/**
 * Component to render MBV Info
 */

class MbvInfoRow extends React.PureComponent<
  IMbvInfoRowProps,
  Record<string, any>,
  IMbvInfoRowNavProps
> {
  render() {
    const {
      isLocationChosen,
      mbvMinPrice,
      mbvMaxPrice,
      minPrice,
      isDelivery
    } = this.props;

    const isShowRangeMBV =
      (minPrice !== 0 && isLocationChosen) ||
      (minPrice !== 0 && !isDelivery) ||
      (!isLocationChosen && isDelivery);

    return isShowRangeMBV
      ? this.renderRangeMBV(
          isLocationChosen,
          isDelivery,
          mbvMinPrice,
          mbvMaxPrice,
          minPrice
        )
      : null;
  }

  /**
   * Method to render range mbv
   * @param {boolean} isLocationChosen - if location was chosen
   * @param {boolean} isDelivery - if delivery type active
   * @param {number} mbvMinPrice - min basket value price
   * @param {number} mbvMaxPrice - max basket value price
   * @param {number} minPrice - min price
   * @private
   */
  private renderRangeMBV = (
    isLocationChosen: boolean,
    isDelivery: boolean,
    mbvMinPrice: number,
    mbvMaxPrice: number,
    minPrice: number
  ) => (
    <div className="mbv-info">
      <i className="mdi mdi-cart type-icon" />
      <span className="delivery-info-text">
        {(isLocationChosen || !isDelivery) &&
          this.props.t('minOrder', { price: minPrice })}
        {!isLocationChosen &&
          isDelivery &&
          this.props.t('mbwRange', {
            priceRange:
              mbvMinPrice === mbvMaxPrice
                ? mbvMinPrice
                : `(${mbvMinPrice} - ${mbvMaxPrice})`
          })}
      </span>
    </div>
  );
}

export default withTranslation(['delivery_info'])(MbvInfoRow);
