import classnames from 'classnames';
import React, { memo, useCallback, useRef } from 'react';

import Text from 'client/ui/Text';
import TextArea from 'client/ui/TextArea';
import mergeRefs from 'client/utils/mergeRefs';

import css from './TextAreaField.module.scss';
import { TextAreaFieldProps } from './TextAreaField.type';

const TextAreaField = ({
  className,
  disabled,
  error = '',
  label,
  textAreaRef,
  onChange,
  ...textAreaProps
}: TextAreaFieldProps) => {
  const initialRows = 4;
  const ref = useRef<HTMLTextAreaElement | null>(null);

  const handleChange = useCallback(
    (event: React.ChangeEvent) => {
      const { current } = ref;

      if (current) {
        current.setAttribute('rows', '2');

        const { lineHeight } = window.getComputedStyle(current);
        const { scrollHeight } = current;
        const lines = Math.floor(scrollHeight / parseInt(lineHeight, 10));
        const rows = Math.max(lines, initialRows);

        current.setAttribute('rows', `${rows}`);
      }

      onChange?.(event);
    },
    [onChange]
  );

  return (
    <label
      className={classnames(css.TextAreaField, className)}
      data-testid="TextAreaField"
    >
      {label && <Text className={css.label}>{label}</Text>}
      <TextArea
        className={classnames(css.textArea, error && css.hasError)}
        disabled={disabled}
        rows={initialRows}
        textAreaRef={mergeRefs(ref, textAreaRef)}
        onChange={handleChange}
        {...textAreaProps}
      />
      <Text className={css.error}>{error}</Text>
    </label>
  );
};

export { TextAreaField };

export default memo(TextAreaField);
