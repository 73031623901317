import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { getUtmParamsFromSearch } from '../utils/helpers';

const useModal = (params: Record<string, string> = {}) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const utmParams = getUtmParamsFromSearch(search);

  const openModal = useCallback(() => {
    const openSearch = new URLSearchParams({ ...params, ...utmParams });

    history.replace({ pathname, search: openSearch.toString() });
  }, [history]);

  const closeModal = useCallback(() => {
    const closeSearch = new URLSearchParams(utmParams);

    history.replace({ pathname, search: closeSearch.toString() });
  }, [history, pathname]);

  return { closeModal, openModal };
};

export default useModal;
