import React, { CSSProperties } from 'react';

import './ProductNewBadge.scss';

interface IProductNewBadgeProps {
  text?: string;
  style?: CSSProperties;
}

const ProductNewBadge = (props: IProductNewBadgeProps) => (
  <div className="product-badge" style={props.style}>
    {props.text}
  </div>
);

export default ProductNewBadge;
