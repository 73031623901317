import { Button, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

const FormItem = Form.Item;

@Form.create()
class CouponFormField extends Component {
  static propTypes = {
    isShowInvalidCoupon: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    couponCode: PropTypes.string,
    validateCouponAction: PropTypes.func.isRequired,
    removeCouponAction: PropTypes.func.isRequired,
    clearCoupon: PropTypes.func.isRequired
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.validateCouponAction(values.coupon);
      }
    });
  };

  clearCouponField() {
    this.props.form.resetFields();

    this.props.clearCoupon();
  }

  componentDidMount() {
    this.props.form.validateFields();

    this.props.clearCoupon();
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator, isFieldTouched, getFieldError } = form;
    const { t } = this.props;
    const { isShowInvalidCoupon, loading } = this.props;
    // Only show error after a field is touched.
    const couponError = isFieldTouched('coupon') && getFieldError('coupon');

    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem
          validateStatus={couponError || isShowInvalidCoupon ? 'error' : ''}
          help={couponError || ''}
        >
          {getFieldDecorator('coupon', {
            rules: [{ required: false, message: '' }]
          })(
            <Input
              onChange={() => this.clearCouponField()}
              placeholder={t('fldCouponPlaceholder')}
              data-testid="coupon-input"
            />
          )}
          <i
            className="fas fa-times-circle clear-coupon"
            onClick={this.clearCouponField.bind(this)}
            data-testid="coupon-reset-btn"
          />
        </FormItem>
        <Button
          id="verify-button"
          type={isShowInvalidCoupon ? 'danger' : 'default'}
          htmlType="submit"
          disabled={loading}
          data-testid="coupon-validate-btn"
        >
          {isShowInvalidCoupon
            ? this.props.t('btnInvalidCoupon')
            : this.props.t('btnVerifyCoupon')}
        </Button>
      </Form>
    );
  }
}

export default withTranslation(['coupons'])(CouponFormField);
