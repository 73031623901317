import React from 'react';
import {Cookies} from 'react-cookie-consent';
import {useTranslation} from "react-i18next";

import Button from 'client/ui/Button';
import Heading from 'client/ui/Heading';
import Modal from 'client/ui/Modal';
import Text from 'client/ui/Text';
import {cookieName} from "config";

import './CookiesModal.scss';

const CookiesModal = ({ closeModal, openPrivacyModal }: { closeModal: () => void; openPrivacyModal: () => void; }) => {
  const {t} = useTranslation()

  const handleAcceptAll = () => {
    Cookies.set(cookieName, JSON.stringify(true));
    closeModal();
  }

  const handleAcceptOnlyEssentials = () => {
    Cookies.set(
      cookieName,
      JSON.stringify({'Shopping Cart': true, 'Order Place': true, 'Basic Tracking': true, 'Basic Analytics': true})
    );
    closeModal();
  }

  return <Modal>
    <div className="CookieModal">
      <div className="cookieModalContent">
        <div className="cookieTitle">
          <Heading variant="h3" dataTestId="name-advprview">{t('cookie:cookieSettings')}</Heading>
        </div>

        <div className="cookieDescription" data-testid="description-advprview">
          <Text dataTestId="description-advprview">{t('cookie:settingsDescription')}</Text>
        </div>

        <div className="columnWrapper">
          <div className="column">
            <div className="columnTitle"><Heading variant="h6">
              {t('cookie:essential')}
            </Heading></div>
            <div className="columnDescription"><Text>{t('cookie:essentialDescription')}</Text>
            </div>
            <div className="btnWrapper">
              <Button onClick={handleAcceptOnlyEssentials}
                      className='columnBtn essentialBtn'>{t('cookie:essentialBtn')}</Button>
            </div>
          </div>
          <div className="column">
            <div className="columnTitle"><Heading variant="h6">
              {t('cookie:allCookie')}
            </Heading></div>
            <div className="columnDescription"><Text>{t('cookie:allDescription')}</Text>
            </div>
            <div className="btnWrapper">
              <Button onClick={handleAcceptAll} className='columnBtn'>
                {t('cookie:acceptAll')}
              </Button>
            </div>
          </div>
        </div>

        <div className="cookieDescription">
          <Text>{t('cookie:moreInfoPrivacy')} <a onClick={openPrivacyModal} className="privacy">{t('cookie:privacyPolicy')}</a>.
          </Text>
        </div>

      </div>
    </div>
  </Modal>
}

export default React.memo(CookiesModal);
