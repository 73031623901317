import React, { PureComponent } from 'react';

import './styles.scss';
import ReactSVG from 'react-svg';
import { withTranslation } from 'react-i18next';
// Assets
const assets = {
  chef: require('../../../../../../../assets/images/hermes_theme/empty_basket.svg')
};

/**
 * Empty basket component for shell
 */
class BasketShellEmpty extends PureComponent {
  render() {
    return (
      <div className="shell-basket-empty">
        <div className="icon-wrapper">
          <ReactSVG
            path={assets.chef}
            src="chef"
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 60px; height: 45;');
            }}
          />
        </div>
        <div className="title">{this.props.t('cartIsEmpty')}</div>
      </div>
    );
  }
}
export default withTranslation(['basket', 'common'])(BasketShellEmpty);
