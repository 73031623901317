import React, { Ref, forwardRef, memo } from 'react';
import Slider, { Settings } from 'react-slick';

import './SlickCarousel.scss';
import './SlickCarousel.theme.scss';

interface SlickCarouselProps {
  settings: Settings;
  children?: React.ReactNode;
}

const SlickCarousel = (
  { settings, children }: SlickCarouselProps,
  ref: Ref<Slider>
) => (
  <Slider ref={ref} {...settings}>
    {children}
  </Slider>
);

export default memo(forwardRef(SlickCarousel));
